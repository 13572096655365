const userSchema = [
  `#graphql
schema {
  query: query_root
  mutation: mutation_root
  subscription: subscription_root
}

directive @cached(ttl: Int! = 60, refresh: Boolean! = false) on QUERY

type ACModel {
  brandName: String!
  description: String
  id: ID!
  identifier: String!
  modelName: String!
  status: GenericStatus!
}

input ACModelFilterInput {
  brandName: String
  modelName: String
}

input ACModelInput {
  brandName: String!
  description: String
  identifier: String!
  modelName: String!
}

input ACModelSortInput {
  brandName: SortOptions
  modelName: SortOptions
}

type ACModelsResponse {
  ACModels: [ACModel!]!
  pagination: GenericPagination!
}

input ActivateComfortPlusInput {
  checkInAt: String!
  checkOutAt: String!
  emails: [String!]!
  keyId: String!
}

scalar bigint

input bigint_comparison_exp {
  _eq: bigint
  _gt: bigint
  _gte: bigint
  _in: [bigint!]
  _is_null: Boolean
  _lt: bigint
  _lte: bigint
  _neq: bigint
  _nin: [bigint!]
}

input Boolean_comparison_exp {
  _eq: Boolean
  _gt: Boolean
  _gte: Boolean
  _in: [Boolean!]
  _is_null: Boolean
  _lt: Boolean
  _lte: Boolean
  _neq: Boolean
  _nin: [Boolean!]
}

type CancelPrecoolCommand {
  canceledCommands: [PrecoolCommand!]
  uncancelableRooms: [UncancelablePrecoolRooms!]
}

type ChangeBatteryReponse {
  creationDate: String
  id: Int
  nodeMacId: String
}

type Compressor {
  compressorId: ID!
  compressorName: String!
}

input CreateRMAAssetInput {
  assetUri: String!
  type: String!
}

input CreateRMARequestInput {
  assets: [CreateRMAAssetInput!]!
  comment: String
  firmwareVersion: String
  gatewayId: ID
  nodeId: ID
  positionId: String!
  rootCauseId: Int!
}

enum cursor_ordering {
  ASC
  DESC
}

scalar date

input date_comparison_exp {
  _eq: date
  _gt: date
  _gte: date
  _in: [date!]
  _is_null: Boolean
  _lt: date
  _lte: date
  _neq: date
  _nin: [date!]
}

input EmbeddedAnalyticsFilter {
  column: String!
  operator: EmbeddedAnalyticsFilterOperator!
  values: [String!]
}

enum EmbeddedAnalyticsFilterOperator {
  EQUALS
  GREATER_THAN
  GREAT_THAN_EQUALS_TO
  IN
  LESS_THAN
  LESS_THAN_EQUALS_TO
  NOT_EQUALS
  NOT_IN
}

type EmbeddedAnalyticsInfo {
  token: String!
  url: String!
}

input EmbeddedAnalyticsInfoInput {
  filters: [EmbeddedAnalyticsFilter!]
  type: EmbeddedAnalyticsType!
}

enum EmbeddedAnalyticsType {
  CARD
  DASHBOARD
}

type EmonMapInputInfo {
  currentMapInput: Int
  unavailableMapInputs: [Int!]!
}

type FilterOption {
  ceilingValue: Float
  count: Int!
  equalValue: Float
  field: String!
  floorValue: Float
  groupName: String
  label: String!
  options: [String!]
  type: FilterOptionType
  value: String!
}

enum FilterOptionType {
  Checkbox
  FloorCeilingValue
  InputValue
  Select
  TimePicker
}

input Float_comparison_exp {
  _eq: Float
  _gt: Float
  _gte: Float
  _in: [Float!]
  _is_null: Boolean
  _lt: Float
  _lte: Float
  _neq: Float
  _nin: [Float!]
}

scalar float8

input float8_comparison_exp {
  _eq: float8
  _gt: float8
  _gte: float8
  _in: [float8!]
  _is_null: Boolean
  _lt: float8
  _lte: float8
  _neq: float8
  _nin: [float8!]
}

type Gateway {
  error: GatewayHealthError
  gatewayId: ID!
  gatewayMac: Int!
  gatewayName: String!
  hardwareType: GatewayHardwareType
  healthStatus: GatewayHealthStatus
  locationId: String!
}

enum GatewayHardwareType {
  multi_as_single_channel
  multi_channel
  single_as_multi_channel
  single_channel
}

enum GatewayHealthError {
  ERROR_INITIALISING_LORA
  UNKNOWN_ERROR
}

enum GatewayHealthStatus {
  ERROR
  ERROR_PROVISIONING_IMAGE
  HEALTHY
  LORA_ERROR
  NOT_BOOTED
  OFFLINE
  ONLINE
  PREPARING_IMAGE
  VERIFYING
}

input GatewaysCreateInput {
  gatewayName: String!
  locationId: String!
}

type GenerateRMAAssetUploadLinkResponse {
  assetUri: String!
  expiredInSecs: Float!
  url: String!
}

type GenericPagination {
  count: Int
  limit: Int
  offset: Int
}

input GenericPaginationInput {
  limit: Int
  offset: Int
}

type GenericResponse {
  message: String
}

enum GenericStatus {
  ACTIVE
  DISABLED
}

type GetRMAAssetDownloadLinkResponse {
  expiredInSecs: Float!
  url: String!
}

type Group {
  groupId: ID!
  groupName: String!
  groupType: String!
}

input GuestPrecoolScheduleInput {
  endedAt: String
  id: Int
  startedAt: String
  time: Int!
  type: String!
  weekDay: String
}

type Infrastructure {
  id: ID!
  name: String!
}

input Int_comparison_exp {
  _eq: Int
  _gt: Int
  _gte: Int
  _in: [Int!]
  _is_null: Boolean
  _lt: Int
  _lte: Int
  _neq: Int
  _nin: [Int!]
}

scalar json

input json_array_comparison_exp {
  _contained_in: [json!]
  _contains: [json!]
  _eq: [json!]
  _gt: [json!]
  _gte: [json!]
  _in: [[json!]!]
  _is_null: Boolean
  _lt: [json!]
  _lte: [json!]
  _neq: [json!]
  _nin: [[json!]!]
}

input json_comparison_exp {
  _eq: json
  _gt: json
  _gte: json
  _in: [json!]
  _is_null: Boolean
  _lt: json
  _lte: json
  _neq: json
  _nin: [json!]
}

type Key {
  categoryName: String!
  currentRapidMappingRoom: Room
  group: Group
  keyId: ID!
  keyName: String!
  keyStats: KeyMappingStats!
  locationId: String!
  nextRapidMappingRoom: Room
  possitionType: String!
  rooms: [Room!]!
  skippedRapidMappingSlot: Slot
  taskDueDate: String
}

input KeyDetailsFilterInput {
  mappingStatus: MappingStatus
}

type keyEntries {
  automaticTestRun: Boolean!
  comment: String
  commentStatus: String
  endedAt: timestamptz
  expiredAt: timestamptz
  isMeterPosition: Boolean!
  isResumedEntry: Boolean!
  keyEntryId: Int!
  keyPositionId: String!
  lastNotifyAt: timestamptz
  position: sensorflow_positions
  positionFunction: String!
  startedAt: timestamptz
  status: String
  test_runs(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): [testRuns!]!
  test_runs_aggregate(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): testRuns_aggregate!
  user: sensorflow_users
  userId: String
}

type keyEntries_aggregate {
  aggregate: keyEntries_aggregate_fields
  nodes: [keyEntries!]!
}

type keyEntries_aggregate_fields {
  avg: keyEntries_avg_fields
  count(columns: [keyEntries_select_column!], distinct: Boolean): Int!
  max: keyEntries_max_fields
  min: keyEntries_min_fields
  stddev: keyEntries_stddev_fields
  stddev_pop: keyEntries_stddev_pop_fields
  stddev_samp: keyEntries_stddev_samp_fields
  sum: keyEntries_sum_fields
  var_pop: keyEntries_var_pop_fields
  var_samp: keyEntries_var_samp_fields
  variance: keyEntries_variance_fields
}

type keyEntries_avg_fields {
  keyEntryId: Float
}

input keyEntries_bool_exp {
  _and: [keyEntries_bool_exp!]
  _not: keyEntries_bool_exp
  _or: [keyEntries_bool_exp!]
  automaticTestRun: Boolean_comparison_exp
  comment: String_comparison_exp
  commentStatus: String_comparison_exp
  endedAt: timestamptz_comparison_exp
  expiredAt: timestamptz_comparison_exp
  isMeterPosition: Boolean_comparison_exp
  isResumedEntry: Boolean_comparison_exp
  keyEntryId: Int_comparison_exp
  keyPositionId: String_comparison_exp
  lastNotifyAt: timestamptz_comparison_exp
  position: sensorflow_positions_bool_exp
  positionFunction: String_comparison_exp
  startedAt: timestamptz_comparison_exp
  status: String_comparison_exp
  test_runs: testRuns_bool_exp
  test_runs_aggregate: testRuns_aggregate_bool_exp
  user: sensorflow_users_bool_exp
  userId: String_comparison_exp
}

enum keyEntries_constraint {
  key_entries_pkey
  unique_key_position_id
}

input keyEntries_inc_input {
  keyEntryId: Int
}

input keyEntries_insert_input {
  automaticTestRun: Boolean
  comment: String
  commentStatus: String
  endedAt: timestamptz
  expiredAt: timestamptz
  isMeterPosition: Boolean
  isResumedEntry: Boolean
  keyEntryId: Int
  keyPositionId: String
  lastNotifyAt: timestamptz
  position: sensorflow_positions_obj_rel_insert_input
  positionFunction: String
  startedAt: timestamptz
  status: String
  test_runs: testRuns_arr_rel_insert_input
  user: sensorflow_users_obj_rel_insert_input
  userId: String
}

type keyEntries_max_fields {
  comment: String
  commentStatus: String
  endedAt: timestamptz
  expiredAt: timestamptz
  keyEntryId: Int
  keyPositionId: String
  lastNotifyAt: timestamptz
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

type keyEntries_min_fields {
  comment: String
  commentStatus: String
  endedAt: timestamptz
  expiredAt: timestamptz
  keyEntryId: Int
  keyPositionId: String
  lastNotifyAt: timestamptz
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

type keyEntries_mutation_response {
  affected_rows: Int!
  returning: [keyEntries!]!
}

input keyEntries_obj_rel_insert_input {
  data: keyEntries_insert_input!
  on_conflict: keyEntries_on_conflict
}

input keyEntries_on_conflict {
  constraint: keyEntries_constraint!
  update_columns: [keyEntries_update_column!]! = []
  where: keyEntries_bool_exp
}

input keyEntries_order_by {
  automaticTestRun: order_by
  comment: order_by
  commentStatus: order_by
  endedAt: order_by
  expiredAt: order_by
  isMeterPosition: order_by
  isResumedEntry: order_by
  keyEntryId: order_by
  keyPositionId: order_by
  lastNotifyAt: order_by
  position: sensorflow_positions_order_by
  positionFunction: order_by
  startedAt: order_by
  status: order_by
  test_runs_aggregate: testRuns_aggregate_order_by
  user: sensorflow_users_order_by
  userId: order_by
}

input keyEntries_pk_columns_input {
  keyEntryId: Int!
}

enum keyEntries_select_column {
  automaticTestRun
  comment
  commentStatus
  endedAt
  expiredAt
  isMeterPosition
  isResumedEntry
  keyEntryId
  keyPositionId
  lastNotifyAt
  positionFunction
  startedAt
  status
  userId
}

input keyEntries_set_input {
  automaticTestRun: Boolean
  comment: String
  commentStatus: String
  endedAt: timestamptz
  expiredAt: timestamptz
  isMeterPosition: Boolean
  isResumedEntry: Boolean
  keyEntryId: Int
  keyPositionId: String
  lastNotifyAt: timestamptz
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

type keyEntries_stddev_fields {
  keyEntryId: Float
}

type keyEntries_stddev_pop_fields {
  keyEntryId: Float
}

type keyEntries_stddev_samp_fields {
  keyEntryId: Float
}

input keyEntries_stream_cursor_input {
  initial_value: keyEntries_stream_cursor_value_input!
  ordering: cursor_ordering
}

input keyEntries_stream_cursor_value_input {
  automaticTestRun: Boolean
  comment: String
  commentStatus: String
  endedAt: timestamptz
  expiredAt: timestamptz
  isMeterPosition: Boolean
  isResumedEntry: Boolean
  keyEntryId: Int
  keyPositionId: String
  lastNotifyAt: timestamptz
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

type keyEntries_sum_fields {
  keyEntryId: Int
}

enum keyEntries_update_column {
  automaticTestRun
  comment
  commentStatus
  endedAt
  expiredAt
  isMeterPosition
  isResumedEntry
  keyEntryId
  keyPositionId
  lastNotifyAt
  positionFunction
  startedAt
  status
  userId
}

input keyEntries_updates {
  _inc: keyEntries_inc_input
  _set: keyEntries_set_input
  where: keyEntries_bool_exp!
}

type keyEntries_var_pop_fields {
  keyEntryId: Float
}

type keyEntries_var_samp_fields {
  keyEntryId: Float
}

type keyEntries_variance_fields {
  keyEntryId: Float
}

type KeyEntry {
  endedAt: String
  isResumedEntry: Boolean!
  keyEntryId: Int!
  keyPositionId: ID!
  lastNotifyAt: String
  positionFunction: String!
  startedAt: String
  userId: ID
}

input KeyFilterInput {
  categoryName: [String!]
  groupIds: [String!]
  keyIds: [ID!]
  keyName: String
  locationId: String!
  mappingStatus: [MappingStatus!]
}

type KeyMappingStats {
  keyId: ID!
  mappingStatus: MappingStatus!
  nodeHealthStats: NodeHealthStats!
  nodesStats: MappingStats!
  roomsStats: MappingStats!
  tasksStats: TasksStats!
}

input KeySortInput {
  categoryName: SortOptions
  groupName: SortOptions
  keyName: SortOptions
  mappingStatus: SortOptions
}

type KeysResponse {
  filterOptions: [FilterOption!]!
  keys: [Key!]!
  pagination: GenericPagination!
}

input KeyTaskFilterInput {
  dueDateEndTime: String
  dueDateStartTime: String
  emails: [String!]
  groupIDs: [ID!]
  keyName: String
  locationId: String!
  taskStatus: TaskStatus
}

type KeyWithThermostatOff {
  keyId: ID!
  keyName: String
  rooms: [RoomWithThermostatOff]
}

type Location {
  ACModelId: String
  beingExportedToClickup: Boolean
  clickupExportDisabled: Boolean
  clickupListId: String
  currency: String
  enabledFeatures: [String]
  id: ID!
  keysResponse(pagination: GenericPaginationInput, sort: KeySortInput): KeysResponse!
  language: String
  locationMetadata: LocationMetadata
  locationMode: LocationMode!
  locationName: String!
  locationStats: LocationStats!
  organisation: Organisation!
  timezone: String!
}

input LocationEmbeddedAnalyticsInput {
  embedId: String!
}

input LocationFilterInput {
  locationMode: LocationMode
  locationName: String
  mappingStatus: [MappingStatus!]
  organisationId: String
}

type LocationMetadata {
  isAutomationModeDisabled: Boolean
  locationId: ID!
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  showEnergyConsumption: Boolean
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

enum LocationMode {
  ACTIVE
  DISABLED
}

input LocationSortInput {
  locationName: SortOptions
  mappingStatus: SortOptions
}

type LocationsResponse {
  filterOptions: [FilterOption!]!
  locations: [Location!]!
  pagination: GenericPagination!
}

type LocationStats {
  gatewaysStats: MappingStats!
  keysStats: MappingStats!
  locationId: ID!
  mappingStatus: MappingStatus!
  nodeHealthOverallStats: [NodeHealthOverallStats!]!
  nodeHealthStats: NodeHealthStats!
  nodeOverallStats: [NodeTypeStats!]!
  tasksStats: TasksStats!
}

type MappedNode {
  mappedNode: Node!
  mappedTime: String!
  nodeType: NodeType!
  roomId: ID!
  slotName: String!
}

type MappingStats {
  mappedCount: Int!
  totalCount: Int!
}

enum MappingStatus {
  COMPLETED
  IN_PROGRESS
  NOT_STARTED
}

type mutation_root {
  ACModelCreate(ACModel: ACModelInput!): ACModel!
  ACModelDisable(ACModelId: String!): GenericResponse!
  ACModelUpdate(ACModel: ACModelInput!, ACModelId: String!): ACModel!
  activateComfortPlus(input: ActivateComfortPlusInput!): Boolean!
  addLocationDomoEmbed(object: sensorflow_location_domo_embeds_insert_input!, on_conflict: sensorflow_location_domo_embeds_on_conflict): sensorflow_location_domo_embeds
  addPrecoolCommands(mode: Int!, positionIds: [ID!]!, startedAt: String, temperature: Int!, totalMinutes: Int!): [KeyWithThermostatOff!]
  bulkAssignCompressorPositions(compressorIds: [String!], positionId: String): Boolean!
  bulkUpdateDefaultPositionConfigurations(positionConfigurations: [PositionConfigurationInput!]!): [PositionConfigurationWithThermostatStatus!]!
  bulkUpdatePositionConfigurations(positionConfigurations: [PositionConfigurationInput!]!): [PositionConfigurationWithThermostatStatus!]!
  cancelPrecoolCommand(roomIds: [ID!]!): CancelPrecoolCommand
  cancelTimerAndResetToDefaultConfig(roomIds: [ID!]!, userId: ID): [PositionConfiguration!]!
  changeBattery(comment: String, nodeMacId: String!): ChangeBatteryReponse!
  changeDisabledAutomationModeStatus(isAutomationModeDisabled: Boolean!, locationId: ID!): LocationMetadata!
  changeInfraType(coolingSystemId: String, hvacSystemType: String, infraId: ID!, newType: String!, oldType: String!): Infrastructure!
  claimKey(isMeterPosition: Boolean, keyPositionId: ID!, positionFunction: String!, userId: ID!): KeyEntry!
  createACSettingCommands(objects: [sensorflow_ac_setting_commands_insert_input!]!, on_conflict: sensorflow_ac_setting_commands_on_conflict): sensorflow_ac_setting_commands_mutation_response
  createChiller(object: sensorflow_chillers_insert_input!, on_conflict: sensorflow_chillers_on_conflict): sensorflow_chillers
  createCompressor(object: sensorflow_compressors_insert_input!, on_conflict: sensorflow_compressors_on_conflict): sensorflow_compressors
  createCompressorPositionMappings(objects: [sensorflow_compressor_mapping_insert_input!]!, on_conflict: sensorflow_compressor_mapping_on_conflict): sensorflow_compressor_mapping_mutation_response
  createCompressors(objects: [sensorflow_compressors_insert_input!]!, on_conflict: sensorflow_compressors_on_conflict): sensorflow_compressors_mutation_response
  createCoolingTower(object: sensorflow_cooling_towers_insert_input!, on_conflict: sensorflow_cooling_towers_on_conflict): sensorflow_cooling_towers
  createDomoEmbed(object: sensorflow_domo_embeds_insert_input!, on_conflict: sensorflow_domo_embeds_on_conflict): sensorflow_domo_embeds
  createFirmwareRelease(object: sensorflow_firmware_releases_insert_input!, on_conflict: sensorflow_firmware_releases_on_conflict): sensorflow_firmware_releases
  createGatewayToPositionMapping(object: sensorflow_gateway_to_position_mapping_insert_input!, on_conflict: sensorflow_gateway_to_position_mapping_on_conflict): sensorflow_gateway_to_position_mapping
  createInfra(compressorId: ID, ctMultiplier: Float, hvacSystemType: String, locationId: ID!, name: String!, numberOfPhases: Int, type: String!): Infrastructure!
  createKeyCategory(object: sensorflow_key_categories_insert_input!, on_conflict: sensorflow_key_categories_on_conflict): sensorflow_key_categories
  createKeyCategoryTemplates(objects: [sensorflow_key_category_templates_insert_input!]!, on_conflict: sensorflow_key_category_templates_on_conflict): sensorflow_key_category_templates_mutation_response
  createKeyCategoryToKeyMappings(objects: [sensorflow_key_category_to_key_position_insert_input!]!, on_conflict: sensorflow_key_category_to_key_position_on_conflict): sensorflow_key_category_to_key_position_mutation_response
  createLocation(object: sensorflow_locations_insert_input!, on_conflict: sensorflow_locations_on_conflict): sensorflow_locations
  createLocationMetadata(object: sensorflow_location_metadata_insert_input!, on_conflict: sensorflow_location_metadata_on_conflict): sensorflow_location_metadata
  createNodeLifeCycleEvent(comment: String, creationDate: String, eventType: Int!, nodeMacId: String!, nodeTypeCode: String): NodeLifeCycleEvent!
  createNodeSpareSet(locationId: ID!, nodeMacId: String!, nodeSubType: NodeSubType, nodeType: NodeType!): NodeSpareSetReponse!
  createOrganisation(object: sensorflow_organisations_insert_input!, on_conflict: sensorflow_organisations_on_conflict): sensorflow_organisations
  createPipe(object: sensorflow_pipes_insert_input!, on_conflict: sensorflow_pipes_on_conflict): sensorflow_pipes
  createPosition(object: sensorflow_positions_insert_input!, on_conflict: sensorflow_positions_on_conflict): sensorflow_positions
  createPositions(objects: [sensorflow_positions_insert_input!]!, on_conflict: sensorflow_positions_on_conflict): sensorflow_positions_mutation_response
  createPrecoolCommands(objects: [sensorflow_precool_commands_insert_input!]!, on_conflict: sensorflow_precool_commands_on_conflict): sensorflow_precool_commands_mutation_response
  createPump(object: sensorflow_pumps_insert_input!, on_conflict: sensorflow_pumps_on_conflict): sensorflow_pumps
  createRMARequest(input: CreateRMARequestInput!): RMAJobResponse!
  createRoom(acModelId: String, isDeviatedFromDefaultConfig: Boolean, locationId: ID!, parentPositionId: ID!, positionName: String!, slots: [SlotCreateRoomInput]): Position!
  createRoomCompressors(compressors: [RoomCompressorCreateInput!]!): [Compressor!]!
  createSlot(object: sensorflow_node_slots_insert_input!, on_conflict: sensorflow_node_slots_on_conflict): sensorflow_node_slots
  createSlots(slots: [SlotCreateInput!]!): [Slot!]!
  createSmartRemValidationHistory(object: sensorflow_smart_rem_validation_history_insert_input!, on_conflict: sensorflow_smart_rem_validation_history_on_conflict): sensorflow_smart_rem_validation_history
  createTestJig(object: sf_testjig_test_jig_insert_input!, on_conflict: sf_testjig_test_jig_on_conflict): sf_testjig_test_jig
  createTestJigMaintenanceReport(object: sf_testjig_test_jig_maintenance_report_insert_input!, on_conflict: sf_testjig_test_jig_maintenance_report_on_conflict): sf_testjig_test_jig_maintenance_report
  createTestJigMaintenanceReports(objects: [sf_testjig_test_jig_maintenance_report_insert_input!]!, on_conflict: sf_testjig_test_jig_maintenance_report_on_conflict): sf_testjig_test_jig_maintenance_report_mutation_response
  createTestJigTestReport(object: sf_testjig_test_jig_test_report_insert_input!, on_conflict: sf_testjig_test_jig_test_report_on_conflict): sf_testjig_test_jig_test_report
  createTestJigTestReports(objects: [sf_testjig_test_jig_test_report_insert_input!]!, on_conflict: sf_testjig_test_jig_test_report_on_conflict): sf_testjig_test_jig_test_report_mutation_response
  createTestJigType(object: sf_testjig_test_jig_type_insert_input!, on_conflict: sf_testjig_test_jig_type_on_conflict): sf_testjig_test_jig_type
  createTestJigTypes(objects: [sf_testjig_test_jig_type_insert_input!]!, on_conflict: sf_testjig_test_jig_type_on_conflict): sf_testjig_test_jig_type_mutation_response
  createTestJigs(objects: [sf_testjig_test_jig_insert_input!]!, on_conflict: sf_testjig_test_jig_on_conflict): sf_testjig_test_jig_mutation_response
  deactivateComfortPlus(comment: String!, reservationId: Int!): Boolean!
  deleteCompressor(compressorId: uuid!): sensorflow_compressors
  deleteCompressorPositionMappings(where: sensorflow_compressor_mapping_bool_exp!): sensorflow_compressor_mapping_mutation_response
  deleteDomoEmbed(embed_id: String!): sensorflow_domo_embeds
  deleteFirmwareRelease(id: Int!): sensorflow_firmware_releases
  deleteGateway(gatewayId: ID!): Gateway!
  deleteGroup(groupId: String!): Boolean!
  deleteGuestPrecoolSchedule(scheduleId: Int!): Boolean!
  deleteInfra(infraId: ID!): Boolean!
  deleteKeyCategory(id: uuid!): sensorflow_key_categories
  deleteKeyCategoryTemplates(where: sensorflow_key_category_templates_bool_exp!): sensorflow_key_category_templates_mutation_response
  deletePosition(positionId: ID!): Position!
  deletePositions(positionIds: [ID!]!): [Position!]!
  deleteReservastionGuest(reservationId: bigint!, userId: String!): sensorflow_reservation_guests
  deleteSlot(id: ID!): Slot!
  delete_keyEntries(where: keyEntries_bool_exp!): keyEntries_mutation_response
  delete_keyEntries_by_pk(keyEntryId: Int!): keyEntries
  delete_positionConfigurations(where: positionConfigurations_bool_exp!): positionConfigurations_mutation_response
  delete_positionConfigurations_by_pk(creationDate: timestamptz!, id: uuid!): positionConfigurations
  delete_sensorflow_ac_command_event_stream(where: sensorflow_ac_command_event_stream_bool_exp!): sensorflow_ac_command_event_stream_mutation_response
  delete_sensorflow_ac_models(where: sensorflow_ac_models_bool_exp!): sensorflow_ac_models_mutation_response
  delete_sensorflow_ac_models_by_pk(id: uuid!): sensorflow_ac_models
  delete_sensorflow_ac_setting_commands(where: sensorflow_ac_setting_commands_bool_exp!): sensorflow_ac_setting_commands_mutation_response
  delete_sensorflow_ac_setting_commands_by_pk(id: uuid!): sensorflow_ac_setting_commands
  delete_sensorflow_ai_dt_ac_event_stream(where: sensorflow_ai_dt_ac_event_stream_bool_exp!): sensorflow_ai_dt_ac_event_stream_mutation_response
  delete_sensorflow_ai_dt_config_event_stream(where: sensorflow_ai_dt_config_event_stream_bool_exp!): sensorflow_ai_dt_config_event_stream_mutation_response
  delete_sensorflow_beacon_slots(where: sensorflow_beacon_slots_bool_exp!): sensorflow_beacon_slots_mutation_response
  delete_sensorflow_beacon_slots_by_pk(id: uuid!): sensorflow_beacon_slots
  delete_sensorflow_checklist_items(where: sensorflow_checklist_items_bool_exp!): sensorflow_checklist_items_mutation_response
  delete_sensorflow_checklists(where: sensorflow_checklists_bool_exp!): sensorflow_checklists_mutation_response
  delete_sensorflow_checklists_by_pk(id: String!): sensorflow_checklists
  delete_sensorflow_chillers(where: sensorflow_chillers_bool_exp!): sensorflow_chillers_mutation_response
  delete_sensorflow_chillers_by_pk(id: uuid!): sensorflow_chillers
  delete_sensorflow_clickup_webhook_events(where: sensorflow_clickup_webhook_events_bool_exp!): sensorflow_clickup_webhook_events_mutation_response
  delete_sensorflow_clickup_webhook_events_by_pk(id: uuid!): sensorflow_clickup_webhook_events
  delete_sensorflow_comfortplus_activations(where: sensorflow_comfortplus_activations_bool_exp!): sensorflow_comfortplus_activations_mutation_response
  delete_sensorflow_comfortplus_activations_by_pk(id: uuid!): sensorflow_comfortplus_activations
  delete_sensorflow_compressor_mapping_by_pk(compressorId: uuid!, positionId: uuid!): sensorflow_compressor_mapping
  delete_sensorflow_compressors(where: sensorflow_compressors_bool_exp!): sensorflow_compressors_mutation_response
  delete_sensorflow_config_event_stream(where: sensorflow_config_event_stream_bool_exp!): sensorflow_config_event_stream_mutation_response
  delete_sensorflow_cooling_towers(where: sensorflow_cooling_towers_bool_exp!): sensorflow_cooling_towers_mutation_response
  delete_sensorflow_cooling_towers_by_pk(id: uuid!): sensorflow_cooling_towers
  delete_sensorflow_currencies(where: sensorflow_currencies_bool_exp!): sensorflow_currencies_mutation_response
  delete_sensorflow_currencies_by_pk(id: Int!): sensorflow_currencies
  delete_sensorflow_domo_embeds(where: sensorflow_domo_embeds_bool_exp!): sensorflow_domo_embeds_mutation_response
  delete_sensorflow_firmware_releases(where: sensorflow_firmware_releases_bool_exp!): sensorflow_firmware_releases_mutation_response
  delete_sensorflow_gateway_frequency(where: sensorflow_gateway_frequency_bool_exp!): sensorflow_gateway_frequency_mutation_response
  delete_sensorflow_gateway_frequency_by_pk(balena_id: String!, reserved_frequency: bigint!): sensorflow_gateway_frequency
  delete_sensorflow_gateway_health_data(where: sensorflow_gateway_health_data_bool_exp!): sensorflow_gateway_health_data_mutation_response
  delete_sensorflow_gateway_to_position_mapping(where: sensorflow_gateway_to_position_mapping_bool_exp!): sensorflow_gateway_to_position_mapping_mutation_response
  delete_sensorflow_gateway_to_position_mapping_by_pk(positionId: uuid!): sensorflow_gateway_to_position_mapping
  delete_sensorflow_gateways(where: sensorflow_gateways_bool_exp!): sensorflow_gateways_mutation_response
  delete_sensorflow_gateways_by_pk(gatewayId: String!): sensorflow_gateways
  delete_sensorflow_get_event_stream_view(where: sensorflow_get_event_stream_view_bool_exp!): sensorflow_get_event_stream_view_mutation_response
  delete_sensorflow_key_categories(where: sensorflow_key_categories_bool_exp!): sensorflow_key_categories_mutation_response
  delete_sensorflow_key_category_room_templates(where: sensorflow_key_category_room_templates_bool_exp!): sensorflow_key_category_room_templates_mutation_response
  delete_sensorflow_key_category_room_templates_by_pk(id: uuid!): sensorflow_key_category_room_templates
  delete_sensorflow_key_category_slot_templates(where: sensorflow_key_category_slot_templates_bool_exp!): sensorflow_key_category_slot_templates_mutation_response
  delete_sensorflow_key_category_slot_templates_by_pk(id: uuid!): sensorflow_key_category_slot_templates
  delete_sensorflow_key_category_templates_by_pk(id: uuid!): sensorflow_key_category_templates
  delete_sensorflow_key_category_to_key_position(where: sensorflow_key_category_to_key_position_bool_exp!): sensorflow_key_category_to_key_position_mutation_response
  delete_sensorflow_key_category_to_key_position_by_pk(categoryName: String!, locationName: String!, positionId: uuid!): sensorflow_key_category_to_key_position
  delete_sensorflow_location_domo_embeds(where: sensorflow_location_domo_embeds_bool_exp!): sensorflow_location_domo_embeds_mutation_response
  delete_sensorflow_location_metadata(where: sensorflow_location_metadata_bool_exp!): sensorflow_location_metadata_mutation_response
  delete_sensorflow_location_metadata_by_pk(locationId: uuid!): sensorflow_location_metadata
  delete_sensorflow_location_to_user_mapping(where: sensorflow_location_to_user_mapping_bool_exp!): sensorflow_location_to_user_mapping_mutation_response
  delete_sensorflow_location_to_user_mapping_by_pk(id: uuid!): sensorflow_location_to_user_mapping
  delete_sensorflow_locations(where: sensorflow_locations_bool_exp!): sensorflow_locations_mutation_response
  delete_sensorflow_locations_by_pk(locationId: uuid!): sensorflow_locations
  delete_sensorflow_mapping_coordinates(where: sensorflow_mapping_coordinates_bool_exp!): sensorflow_mapping_coordinates_mutation_response
  delete_sensorflow_node_exception(where: sensorflow_node_exception_bool_exp!): sensorflow_node_exception_mutation_response
  delete_sensorflow_node_exception_by_pk(id: Int!): sensorflow_node_exception
  delete_sensorflow_node_exceptions(where: sensorflow_node_exceptions_bool_exp!): sensorflow_node_exceptions_mutation_response
  delete_sensorflow_node_exceptions_by_pk(id: Int!): sensorflow_node_exceptions
  delete_sensorflow_node_join_data(where: sensorflow_node_join_data_bool_exp!): sensorflow_node_join_data_mutation_response
  delete_sensorflow_node_life_cycle_event_types(where: sensorflow_node_life_cycle_event_types_bool_exp!): sensorflow_node_life_cycle_event_types_mutation_response
  delete_sensorflow_node_life_cycle_event_types_by_pk(id: Int!): sensorflow_node_life_cycle_event_types
  delete_sensorflow_node_life_cycle_events(where: sensorflow_node_life_cycle_events_bool_exp!): sensorflow_node_life_cycle_events_mutation_response
  delete_sensorflow_node_life_cycle_events_by_pk(id: Int!): sensorflow_node_life_cycle_events
  delete_sensorflow_node_measurements(where: sensorflow_node_measurements_bool_exp!): sensorflow_node_measurements_mutation_response
  delete_sensorflow_node_meta_data(where: sensorflow_node_meta_data_bool_exp!): sensorflow_node_meta_data_mutation_response
  delete_sensorflow_node_meta_data_by_pk(measurementType: String!, nodeMacId: String!, time: timestamptz!): sensorflow_node_meta_data
  delete_sensorflow_node_slots(where: sensorflow_node_slots_bool_exp!): sensorflow_node_slots_mutation_response
  delete_sensorflow_node_slots_by_pk(id: uuid!): sensorflow_node_slots
  delete_sensorflow_node_spare_set(where: sensorflow_node_spare_set_bool_exp!): sensorflow_node_spare_set_mutation_response
  delete_sensorflow_node_spare_set_by_pk(locationId: uuid!, nodeMacId: String!): sensorflow_node_spare_set
  delete_sensorflow_node_status_history(where: sensorflow_node_status_history_bool_exp!): sensorflow_node_status_history_mutation_response
  delete_sensorflow_node_status_history_by_pk(id: Int!): sensorflow_node_status_history
  delete_sensorflow_node_statuses(where: sensorflow_node_statuses_bool_exp!): sensorflow_node_statuses_mutation_response
  delete_sensorflow_node_statuses_by_pk(id: Int!): sensorflow_node_statuses
  delete_sensorflow_node_system_states(where: sensorflow_node_system_states_bool_exp!): sensorflow_node_system_states_mutation_response
  delete_sensorflow_node_system_states_by_pk(nodeMacId: String!, time: timestamptz!): sensorflow_node_system_states
  delete_sensorflow_node_to_position_mapping(where: sensorflow_node_to_position_mapping_bool_exp!): sensorflow_node_to_position_mapping_mutation_response
  delete_sensorflow_node_to_slot_mapping(where: sensorflow_node_to_slot_mapping_bool_exp!): sensorflow_node_to_slot_mapping_mutation_response
  delete_sensorflow_node_to_slot_mapping_by_pk(id: Int!): sensorflow_node_to_slot_mapping
  delete_sensorflow_nodes(where: sensorflow_nodes_bool_exp!): sensorflow_nodes_mutation_response
  delete_sensorflow_nodes_by_pk(node_mac_id: String!): sensorflow_nodes
  delete_sensorflow_organisations(where: sensorflow_organisations_bool_exp!): sensorflow_organisations_mutation_response
  delete_sensorflow_organisations_by_pk(id: uuid!): sensorflow_organisations
  delete_sensorflow_ota_queues(where: sensorflow_ota_queues_bool_exp!): sensorflow_ota_queues_mutation_response
  delete_sensorflow_ota_queues_by_pk(ota_queue_id: Int!): sensorflow_ota_queues
  delete_sensorflow_ota_runs(where: sensorflow_ota_runs_bool_exp!): sensorflow_ota_runs_mutation_response
  delete_sensorflow_ota_runs_by_pk(current_firmware_release_id: Int!, node_mac_id: String!, ota_queue_id: Int!, target_firmware_release_id: Int!): sensorflow_ota_runs
  delete_sensorflow_pipes(where: sensorflow_pipes_bool_exp!): sensorflow_pipes_mutation_response
  delete_sensorflow_pipes_by_pk(id: uuid!): sensorflow_pipes
  delete_sensorflow_position_signal_stats(where: sensorflow_position_signal_stats_bool_exp!): sensorflow_position_signal_stats_mutation_response
  delete_sensorflow_position_signal_stats_by_pk(balena_id: String!, position_id: uuid!): sensorflow_position_signal_stats
  delete_sensorflow_positions(where: sensorflow_positions_bool_exp!): sensorflow_positions_mutation_response
  delete_sensorflow_positions_by_pk(positionId: uuid!): sensorflow_positions
  delete_sensorflow_precool_commands(where: sensorflow_precool_commands_bool_exp!): sensorflow_precool_commands_mutation_response
  delete_sensorflow_precool_commands_by_pk(id: uuid!): sensorflow_precool_commands
  delete_sensorflow_precool_schedules(where: sensorflow_precool_schedules_bool_exp!): sensorflow_precool_schedules_mutation_response
  delete_sensorflow_precool_schedules_by_pk(id: bigint!): sensorflow_precool_schedules
  delete_sensorflow_pumps(where: sensorflow_pumps_bool_exp!): sensorflow_pumps_mutation_response
  delete_sensorflow_pumps_by_pk(id: uuid!): sensorflow_pumps
  delete_sensorflow_reservation_guests(where: sensorflow_reservation_guests_bool_exp!): sensorflow_reservation_guests_mutation_response
  delete_sensorflow_reservations(where: sensorflow_reservations_bool_exp!): sensorflow_reservations_mutation_response
  delete_sensorflow_reservations_by_pk(id: bigint!): sensorflow_reservations
  delete_sensorflow_roles(where: sensorflow_roles_bool_exp!): sensorflow_roles_mutation_response
  delete_sensorflow_roles_by_pk(id: uuid!): sensorflow_roles
  delete_sensorflow_smart_rem_validation_history(where: sensorflow_smart_rem_validation_history_bool_exp!): sensorflow_smart_rem_validation_history_mutation_response
  delete_sensorflow_smart_rem_validation_history_by_pk(id: uuid!): sensorflow_smart_rem_validation_history
  delete_sensorflow_task_status_history(where: sensorflow_task_status_history_bool_exp!): sensorflow_task_status_history_mutation_response
  delete_sensorflow_task_status_history_by_pk(id: Int!): sensorflow_task_status_history
  delete_sensorflow_tasks(where: sensorflow_tasks_bool_exp!): sensorflow_tasks_mutation_response
  delete_sensorflow_tasks_by_pk(id: uuid!): sensorflow_tasks
  delete_sensorflow_tests(where: sensorflow_tests_bool_exp!): sensorflow_tests_mutation_response
  delete_sensorflow_tests_by_pk(testId: Int!): sensorflow_tests
  delete_sensorflow_timezones(where: sensorflow_timezones_bool_exp!): sensorflow_timezones_mutation_response
  delete_sensorflow_timezones_by_pk(id: Int!): sensorflow_timezones
  delete_sensorflow_user_to_permission_mapping(where: sensorflow_user_to_permission_mapping_bool_exp!): sensorflow_user_to_permission_mapping_mutation_response
  delete_sensorflow_users(where: sensorflow_users_bool_exp!): sensorflow_users_mutation_response
  delete_sensorflow_users_by_pk(userId: String!): sensorflow_users
  delete_sensorflow_warehouse_kitting_process_metadata(where: sensorflow_warehouse_kitting_process_metadata_bool_exp!): sensorflow_warehouse_kitting_process_metadata_mutation_response
  delete_sensorflow_warehouse_kitting_process_metadata_by_pk(balenaId: String!): sensorflow_warehouse_kitting_process_metadata
  delete_sf_firmware_dist_pipeline_firmware_build(where: sf_firmware_dist_pipeline_firmware_build_bool_exp!): sf_firmware_dist_pipeline_firmware_build_mutation_response
  delete_sf_firmware_dist_pipeline_firmware_build_by_pk(buildId: Int!): sf_firmware_dist_pipeline_firmware_build
  delete_sf_firmware_dist_pipeline_firmware_numbers(where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp!): sf_firmware_dist_pipeline_firmware_numbers_mutation_response
  delete_sf_firmware_dist_pipeline_firmware_numbers_by_pk(id: Int!): sf_firmware_dist_pipeline_firmware_numbers
  delete_sf_pms_pms_pmslist(where: sf_pms_pms_pmslist_bool_exp!): sf_pms_pms_pmslist_mutation_response
  delete_sf_pms_pms_pmslist_by_pk(pms_id: Int!): sf_pms_pms_pmslist
  delete_sf_pms_pms_properties(where: sf_pms_pms_properties_bool_exp!): sf_pms_pms_properties_mutation_response
  delete_sf_pms_pms_properties_by_pk(pms_id: Int!, property_id: String!): sf_pms_pms_properties
  delete_sf_pms_pms_property_eod_surveys(where: sf_pms_pms_property_eod_surveys_bool_exp!): sf_pms_pms_property_eod_surveys_mutation_response
  delete_sf_pms_pms_property_eod_surveys_by_pk(pms_id: Int!, property_id: String!, survey_monkey_id: String!): sf_pms_pms_property_eod_surveys
  delete_sf_pms_pms_reservations(where: sf_pms_pms_reservations_bool_exp!): sf_pms_pms_reservations_mutation_response
  delete_sf_pms_pms_room_categories(where: sf_pms_pms_room_categories_bool_exp!): sf_pms_pms_room_categories_mutation_response
  delete_sf_pms_pms_rooms(where: sf_pms_pms_rooms_bool_exp!): sf_pms_pms_rooms_mutation_response
  delete_sf_pms_smartalloc_report(where: sf_pms_smartalloc_report_bool_exp!): sf_pms_smartalloc_report_mutation_response
  delete_sf_pms_smartalloc_report_by_pk(date: date!, locationId: uuid!, type: String!): sf_pms_smartalloc_report
  delete_sf_simulation_instrumentation_commands(where: sf_simulation_instrumentation_commands_bool_exp!): sf_simulation_instrumentation_commands_mutation_response
  delete_sf_simulation_instrumentation_commands_by_pk(id: Int!): sf_simulation_instrumentation_commands
  delete_sf_support_actions(where: sf_support_actions_bool_exp!): sf_support_actions_mutation_response
  delete_sf_support_actions_by_pk(id: Int!): sf_support_actions
  delete_sf_support_active_property_alerts(where: sf_support_active_property_alerts_bool_exp!): sf_support_active_property_alerts_mutation_response
  delete_sf_support_active_property_alerts_by_pk(locationId: uuid!, ticketClassId: Int!): sf_support_active_property_alerts
  delete_sf_support_analytics_ticket_jobs_running_histories(where: sf_support_analytics_ticket_jobs_running_histories_bool_exp!): sf_support_analytics_ticket_jobs_running_histories_mutation_response
  delete_sf_support_analytics_ticket_jobs_running_histories_by_pk(id: Int!): sf_support_analytics_ticket_jobs_running_histories
  delete_sf_support_root_causes(where: sf_support_root_causes_bool_exp!): sf_support_root_causes_mutation_response
  delete_sf_support_root_causes_by_pk(id: Int!): sf_support_root_causes
  delete_sf_support_ticket_classes(where: sf_support_ticket_classes_bool_exp!): sf_support_ticket_classes_mutation_response
  delete_sf_support_ticket_classes_by_pk(id: Int!): sf_support_ticket_classes
  delete_sf_support_ticket_status(where: sf_support_ticket_status_bool_exp!): sf_support_ticket_status_mutation_response
  delete_sf_support_ticket_status_by_pk(id: Int!): sf_support_ticket_status
  delete_sf_support_tickets(where: sf_support_tickets_bool_exp!): sf_support_tickets_mutation_response
  delete_sf_support_tickets_by_pk(ticket_id: Int!): sf_support_tickets
  delete_sf_testjig_test_jig(where: sf_testjig_test_jig_bool_exp!): sf_testjig_test_jig_mutation_response
  delete_sf_testjig_test_jig_by_pk(testJigId: String!): sf_testjig_test_jig
  delete_sf_testjig_test_jig_maintenance_report(where: sf_testjig_test_jig_maintenance_report_bool_exp!): sf_testjig_test_jig_maintenance_report_mutation_response
  delete_sf_testjig_test_jig_maintenance_report_by_pk(id: uuid!): sf_testjig_test_jig_maintenance_report
  delete_sf_testjig_test_jig_report_metadata(where: sf_testjig_test_jig_report_metadata_bool_exp!): sf_testjig_test_jig_report_metadata_mutation_response
  delete_sf_testjig_test_jig_report_metadata_by_pk(id: uuid!): sf_testjig_test_jig_report_metadata
  delete_sf_testjig_test_jig_report_metadatas(where: sf_testjig_test_jig_report_metadatas_bool_exp!): sf_testjig_test_jig_report_metadatas_mutation_response
  delete_sf_testjig_test_jig_report_metadatas_by_pk(id: Int!): sf_testjig_test_jig_report_metadatas
  delete_sf_testjig_test_jig_test_report(where: sf_testjig_test_jig_test_report_bool_exp!): sf_testjig_test_jig_test_report_mutation_response
  delete_sf_testjig_test_jig_test_report_by_pk(id: uuid!): sf_testjig_test_jig_test_report
  delete_sf_testjig_test_jig_type(where: sf_testjig_test_jig_type_bool_exp!): sf_testjig_test_jig_type_mutation_response
  delete_sf_testjig_test_jig_type_by_pk(id: Int!): sf_testjig_test_jig_type
  delete_testRuns(where: testRuns_bool_exp!): testRuns_mutation_response
  delete_testRuns_by_pk(testRunId: Int!): testRuns
  editComfortPlusDetail(addedEmails: [String!], checkInAt: String, checkOutAt: String, reservationId: Int!): Boolean!
  editGroupOfGateways(gatewayIds: [String!], groupId: String): Boolean!
  editGroupOfKeys(groupId: String, keyIds: [String!]): Boolean!
  exitInstallationMode(comment: String, commentStatus: String, keyEntryId: Int!, status: String): KeyEntry
  gatewaysCreate(gateways: [GatewaysCreateInput!]!): [Gateway!]!
  initClickupExportProgress(clickupListId: String!, locationId: ID!): Boolean!
  insertActivePropertyAlerts(objects: [sf_support_active_property_alerts_insert_input!]!, on_conflict: sf_support_active_property_alerts_on_conflict): sf_support_active_property_alerts_mutation_response
  insertKeyEntry(object: keyEntries_insert_input!, on_conflict: keyEntries_on_conflict): keyEntries
  insertPositionConfiguration(object: positionConfigurations_insert_input!, on_conflict: positionConfigurations_on_conflict): positionConfigurations
  insertTestRun(object: testRuns_insert_input!, on_conflict: testRuns_on_conflict): testRuns
  insertTestRuns(objects: [testRuns_insert_input!]!, on_conflict: testRuns_on_conflict): testRuns_mutation_response
  insert_keyEntries(objects: [keyEntries_insert_input!]!, on_conflict: keyEntries_on_conflict): keyEntries_mutation_response
  insert_positionConfigurations(objects: [positionConfigurations_insert_input!]!, on_conflict: positionConfigurations_on_conflict): positionConfigurations_mutation_response
  insert_sensorflow_ac_command_event_stream(objects: [sensorflow_ac_command_event_stream_insert_input!]!): sensorflow_ac_command_event_stream_mutation_response
  insert_sensorflow_ac_command_event_stream_one(object: sensorflow_ac_command_event_stream_insert_input!): sensorflow_ac_command_event_stream
  insert_sensorflow_ac_models(objects: [sensorflow_ac_models_insert_input!]!, on_conflict: sensorflow_ac_models_on_conflict): sensorflow_ac_models_mutation_response
  insert_sensorflow_ac_models_one(object: sensorflow_ac_models_insert_input!, on_conflict: sensorflow_ac_models_on_conflict): sensorflow_ac_models
  insert_sensorflow_ac_setting_commands_one(object: sensorflow_ac_setting_commands_insert_input!, on_conflict: sensorflow_ac_setting_commands_on_conflict): sensorflow_ac_setting_commands
  insert_sensorflow_ai_dt_ac_event_stream(objects: [sensorflow_ai_dt_ac_event_stream_insert_input!]!): sensorflow_ai_dt_ac_event_stream_mutation_response
  insert_sensorflow_ai_dt_ac_event_stream_one(object: sensorflow_ai_dt_ac_event_stream_insert_input!): sensorflow_ai_dt_ac_event_stream
  insert_sensorflow_ai_dt_config_event_stream(objects: [sensorflow_ai_dt_config_event_stream_insert_input!]!): sensorflow_ai_dt_config_event_stream_mutation_response
  insert_sensorflow_ai_dt_config_event_stream_one(object: sensorflow_ai_dt_config_event_stream_insert_input!): sensorflow_ai_dt_config_event_stream
  insert_sensorflow_beacon_slots(objects: [sensorflow_beacon_slots_insert_input!]!, on_conflict: sensorflow_beacon_slots_on_conflict): sensorflow_beacon_slots_mutation_response
  insert_sensorflow_beacon_slots_one(object: sensorflow_beacon_slots_insert_input!, on_conflict: sensorflow_beacon_slots_on_conflict): sensorflow_beacon_slots
  insert_sensorflow_checklist_items(objects: [sensorflow_checklist_items_insert_input!]!): sensorflow_checklist_items_mutation_response
  insert_sensorflow_checklist_items_one(object: sensorflow_checklist_items_insert_input!): sensorflow_checklist_items
  insert_sensorflow_checklists(objects: [sensorflow_checklists_insert_input!]!, on_conflict: sensorflow_checklists_on_conflict): sensorflow_checklists_mutation_response
  insert_sensorflow_checklists_one(object: sensorflow_checklists_insert_input!, on_conflict: sensorflow_checklists_on_conflict): sensorflow_checklists
  insert_sensorflow_chillers(objects: [sensorflow_chillers_insert_input!]!, on_conflict: sensorflow_chillers_on_conflict): sensorflow_chillers_mutation_response
  insert_sensorflow_clickup_webhook_events(objects: [sensorflow_clickup_webhook_events_insert_input!]!, on_conflict: sensorflow_clickup_webhook_events_on_conflict): sensorflow_clickup_webhook_events_mutation_response
  insert_sensorflow_clickup_webhook_events_one(object: sensorflow_clickup_webhook_events_insert_input!, on_conflict: sensorflow_clickup_webhook_events_on_conflict): sensorflow_clickup_webhook_events
  insert_sensorflow_comfortplus_activations(objects: [sensorflow_comfortplus_activations_insert_input!]!, on_conflict: sensorflow_comfortplus_activations_on_conflict): sensorflow_comfortplus_activations_mutation_response
  insert_sensorflow_comfortplus_activations_one(object: sensorflow_comfortplus_activations_insert_input!, on_conflict: sensorflow_comfortplus_activations_on_conflict): sensorflow_comfortplus_activations
  insert_sensorflow_compressor_mapping_one(object: sensorflow_compressor_mapping_insert_input!, on_conflict: sensorflow_compressor_mapping_on_conflict): sensorflow_compressor_mapping
  insert_sensorflow_config_event_stream(objects: [sensorflow_config_event_stream_insert_input!]!): sensorflow_config_event_stream_mutation_response
  insert_sensorflow_config_event_stream_one(object: sensorflow_config_event_stream_insert_input!): sensorflow_config_event_stream
  insert_sensorflow_cooling_towers(objects: [sensorflow_cooling_towers_insert_input!]!, on_conflict: sensorflow_cooling_towers_on_conflict): sensorflow_cooling_towers_mutation_response
  insert_sensorflow_currencies(objects: [sensorflow_currencies_insert_input!]!, on_conflict: sensorflow_currencies_on_conflict): sensorflow_currencies_mutation_response
  insert_sensorflow_currencies_one(object: sensorflow_currencies_insert_input!, on_conflict: sensorflow_currencies_on_conflict): sensorflow_currencies
  insert_sensorflow_domo_embeds(objects: [sensorflow_domo_embeds_insert_input!]!, on_conflict: sensorflow_domo_embeds_on_conflict): sensorflow_domo_embeds_mutation_response
  insert_sensorflow_firmware_releases(objects: [sensorflow_firmware_releases_insert_input!]!, on_conflict: sensorflow_firmware_releases_on_conflict): sensorflow_firmware_releases_mutation_response
  insert_sensorflow_gateway_frequency(objects: [sensorflow_gateway_frequency_insert_input!]!, on_conflict: sensorflow_gateway_frequency_on_conflict): sensorflow_gateway_frequency_mutation_response
  insert_sensorflow_gateway_frequency_one(object: sensorflow_gateway_frequency_insert_input!, on_conflict: sensorflow_gateway_frequency_on_conflict): sensorflow_gateway_frequency
  insert_sensorflow_gateway_health_data(objects: [sensorflow_gateway_health_data_insert_input!]!, on_conflict: sensorflow_gateway_health_data_on_conflict): sensorflow_gateway_health_data_mutation_response
  insert_sensorflow_gateway_health_data_one(object: sensorflow_gateway_health_data_insert_input!, on_conflict: sensorflow_gateway_health_data_on_conflict): sensorflow_gateway_health_data
  insert_sensorflow_gateway_to_position_mapping(objects: [sensorflow_gateway_to_position_mapping_insert_input!]!, on_conflict: sensorflow_gateway_to_position_mapping_on_conflict): sensorflow_gateway_to_position_mapping_mutation_response
  insert_sensorflow_gateways(objects: [sensorflow_gateways_insert_input!]!, on_conflict: sensorflow_gateways_on_conflict): sensorflow_gateways_mutation_response
  insert_sensorflow_gateways_one(object: sensorflow_gateways_insert_input!, on_conflict: sensorflow_gateways_on_conflict): sensorflow_gateways
  insert_sensorflow_get_event_stream_view(objects: [sensorflow_get_event_stream_view_insert_input!]!): sensorflow_get_event_stream_view_mutation_response
  insert_sensorflow_get_event_stream_view_one(object: sensorflow_get_event_stream_view_insert_input!): sensorflow_get_event_stream_view
  insert_sensorflow_key_categories(objects: [sensorflow_key_categories_insert_input!]!, on_conflict: sensorflow_key_categories_on_conflict): sensorflow_key_categories_mutation_response
  insert_sensorflow_key_category_room_templates(objects: [sensorflow_key_category_room_templates_insert_input!]!, on_conflict: sensorflow_key_category_room_templates_on_conflict): sensorflow_key_category_room_templates_mutation_response
  insert_sensorflow_key_category_room_templates_one(object: sensorflow_key_category_room_templates_insert_input!, on_conflict: sensorflow_key_category_room_templates_on_conflict): sensorflow_key_category_room_templates
  insert_sensorflow_key_category_slot_templates(objects: [sensorflow_key_category_slot_templates_insert_input!]!, on_conflict: sensorflow_key_category_slot_templates_on_conflict): sensorflow_key_category_slot_templates_mutation_response
  insert_sensorflow_key_category_slot_templates_one(object: sensorflow_key_category_slot_templates_insert_input!, on_conflict: sensorflow_key_category_slot_templates_on_conflict): sensorflow_key_category_slot_templates
  insert_sensorflow_key_category_templates_one(object: sensorflow_key_category_templates_insert_input!, on_conflict: sensorflow_key_category_templates_on_conflict): sensorflow_key_category_templates
  insert_sensorflow_key_category_to_key_position_one(object: sensorflow_key_category_to_key_position_insert_input!, on_conflict: sensorflow_key_category_to_key_position_on_conflict): sensorflow_key_category_to_key_position
  insert_sensorflow_location_domo_embeds(objects: [sensorflow_location_domo_embeds_insert_input!]!, on_conflict: sensorflow_location_domo_embeds_on_conflict): sensorflow_location_domo_embeds_mutation_response
  insert_sensorflow_location_metadata(objects: [sensorflow_location_metadata_insert_input!]!, on_conflict: sensorflow_location_metadata_on_conflict): sensorflow_location_metadata_mutation_response
  insert_sensorflow_location_to_user_mapping(objects: [sensorflow_location_to_user_mapping_insert_input!]!, on_conflict: sensorflow_location_to_user_mapping_on_conflict): sensorflow_location_to_user_mapping_mutation_response
  insert_sensorflow_location_to_user_mapping_one(object: sensorflow_location_to_user_mapping_insert_input!, on_conflict: sensorflow_location_to_user_mapping_on_conflict): sensorflow_location_to_user_mapping
  insert_sensorflow_locations(objects: [sensorflow_locations_insert_input!]!, on_conflict: sensorflow_locations_on_conflict): sensorflow_locations_mutation_response
  insert_sensorflow_mapping_coordinates(objects: [sensorflow_mapping_coordinates_insert_input!]!, on_conflict: sensorflow_mapping_coordinates_on_conflict): sensorflow_mapping_coordinates_mutation_response
  insert_sensorflow_mapping_coordinates_one(object: sensorflow_mapping_coordinates_insert_input!, on_conflict: sensorflow_mapping_coordinates_on_conflict): sensorflow_mapping_coordinates
  insert_sensorflow_node_exception(objects: [sensorflow_node_exception_insert_input!]!, on_conflict: sensorflow_node_exception_on_conflict): sensorflow_node_exception_mutation_response
  insert_sensorflow_node_exception_one(object: sensorflow_node_exception_insert_input!, on_conflict: sensorflow_node_exception_on_conflict): sensorflow_node_exception
  insert_sensorflow_node_exceptions(objects: [sensorflow_node_exceptions_insert_input!]!, on_conflict: sensorflow_node_exceptions_on_conflict): sensorflow_node_exceptions_mutation_response
  insert_sensorflow_node_exceptions_one(object: sensorflow_node_exceptions_insert_input!, on_conflict: sensorflow_node_exceptions_on_conflict): sensorflow_node_exceptions
  insert_sensorflow_node_join_data(objects: [sensorflow_node_join_data_insert_input!]!, on_conflict: sensorflow_node_join_data_on_conflict): sensorflow_node_join_data_mutation_response
  insert_sensorflow_node_join_data_one(object: sensorflow_node_join_data_insert_input!, on_conflict: sensorflow_node_join_data_on_conflict): sensorflow_node_join_data
  insert_sensorflow_node_life_cycle_event_types(objects: [sensorflow_node_life_cycle_event_types_insert_input!]!, on_conflict: sensorflow_node_life_cycle_event_types_on_conflict): sensorflow_node_life_cycle_event_types_mutation_response
  insert_sensorflow_node_life_cycle_event_types_one(object: sensorflow_node_life_cycle_event_types_insert_input!, on_conflict: sensorflow_node_life_cycle_event_types_on_conflict): sensorflow_node_life_cycle_event_types
  insert_sensorflow_node_life_cycle_events(objects: [sensorflow_node_life_cycle_events_insert_input!]!, on_conflict: sensorflow_node_life_cycle_events_on_conflict): sensorflow_node_life_cycle_events_mutation_response
  insert_sensorflow_node_life_cycle_events_one(object: sensorflow_node_life_cycle_events_insert_input!, on_conflict: sensorflow_node_life_cycle_events_on_conflict): sensorflow_node_life_cycle_events
  insert_sensorflow_node_measurements(objects: [sensorflow_node_measurements_insert_input!]!, on_conflict: sensorflow_node_measurements_on_conflict): sensorflow_node_measurements_mutation_response
  insert_sensorflow_node_measurements_one(object: sensorflow_node_measurements_insert_input!, on_conflict: sensorflow_node_measurements_on_conflict): sensorflow_node_measurements
  insert_sensorflow_node_meta_data(objects: [sensorflow_node_meta_data_insert_input!]!, on_conflict: sensorflow_node_meta_data_on_conflict): sensorflow_node_meta_data_mutation_response
  insert_sensorflow_node_meta_data_one(object: sensorflow_node_meta_data_insert_input!, on_conflict: sensorflow_node_meta_data_on_conflict): sensorflow_node_meta_data
  insert_sensorflow_node_slots(objects: [sensorflow_node_slots_insert_input!]!, on_conflict: sensorflow_node_slots_on_conflict): sensorflow_node_slots_mutation_response
  insert_sensorflow_node_spare_set(objects: [sensorflow_node_spare_set_insert_input!]!, on_conflict: sensorflow_node_spare_set_on_conflict): sensorflow_node_spare_set_mutation_response
  insert_sensorflow_node_spare_set_one(object: sensorflow_node_spare_set_insert_input!, on_conflict: sensorflow_node_spare_set_on_conflict): sensorflow_node_spare_set
  insert_sensorflow_node_status_history(objects: [sensorflow_node_status_history_insert_input!]!, on_conflict: sensorflow_node_status_history_on_conflict): sensorflow_node_status_history_mutation_response
  insert_sensorflow_node_status_history_one(object: sensorflow_node_status_history_insert_input!, on_conflict: sensorflow_node_status_history_on_conflict): sensorflow_node_status_history
  insert_sensorflow_node_statuses(objects: [sensorflow_node_statuses_insert_input!]!, on_conflict: sensorflow_node_statuses_on_conflict): sensorflow_node_statuses_mutation_response
  insert_sensorflow_node_statuses_one(object: sensorflow_node_statuses_insert_input!, on_conflict: sensorflow_node_statuses_on_conflict): sensorflow_node_statuses
  insert_sensorflow_node_system_states(objects: [sensorflow_node_system_states_insert_input!]!, on_conflict: sensorflow_node_system_states_on_conflict): sensorflow_node_system_states_mutation_response
  insert_sensorflow_node_system_states_one(object: sensorflow_node_system_states_insert_input!, on_conflict: sensorflow_node_system_states_on_conflict): sensorflow_node_system_states
  insert_sensorflow_node_to_position_mapping(objects: [sensorflow_node_to_position_mapping_insert_input!]!): sensorflow_node_to_position_mapping_mutation_response
  insert_sensorflow_node_to_position_mapping_one(object: sensorflow_node_to_position_mapping_insert_input!): sensorflow_node_to_position_mapping
  insert_sensorflow_node_to_slot_mapping(objects: [sensorflow_node_to_slot_mapping_insert_input!]!, on_conflict: sensorflow_node_to_slot_mapping_on_conflict): sensorflow_node_to_slot_mapping_mutation_response
  insert_sensorflow_node_to_slot_mapping_one(object: sensorflow_node_to_slot_mapping_insert_input!, on_conflict: sensorflow_node_to_slot_mapping_on_conflict): sensorflow_node_to_slot_mapping
  insert_sensorflow_nodes(objects: [sensorflow_nodes_insert_input!]!, on_conflict: sensorflow_nodes_on_conflict): sensorflow_nodes_mutation_response
  insert_sensorflow_nodes_one(object: sensorflow_nodes_insert_input!, on_conflict: sensorflow_nodes_on_conflict): sensorflow_nodes
  insert_sensorflow_organisations(objects: [sensorflow_organisations_insert_input!]!, on_conflict: sensorflow_organisations_on_conflict): sensorflow_organisations_mutation_response
  insert_sensorflow_ota_queues(objects: [sensorflow_ota_queues_insert_input!]!, on_conflict: sensorflow_ota_queues_on_conflict): sensorflow_ota_queues_mutation_response
  insert_sensorflow_ota_queues_one(object: sensorflow_ota_queues_insert_input!, on_conflict: sensorflow_ota_queues_on_conflict): sensorflow_ota_queues
  insert_sensorflow_ota_runs(objects: [sensorflow_ota_runs_insert_input!]!, on_conflict: sensorflow_ota_runs_on_conflict): sensorflow_ota_runs_mutation_response
  insert_sensorflow_ota_runs_one(object: sensorflow_ota_runs_insert_input!, on_conflict: sensorflow_ota_runs_on_conflict): sensorflow_ota_runs
  insert_sensorflow_pipes(objects: [sensorflow_pipes_insert_input!]!, on_conflict: sensorflow_pipes_on_conflict): sensorflow_pipes_mutation_response
  insert_sensorflow_position_signal_stats(objects: [sensorflow_position_signal_stats_insert_input!]!, on_conflict: sensorflow_position_signal_stats_on_conflict): sensorflow_position_signal_stats_mutation_response
  insert_sensorflow_position_signal_stats_one(object: sensorflow_position_signal_stats_insert_input!, on_conflict: sensorflow_position_signal_stats_on_conflict): sensorflow_position_signal_stats
  insert_sensorflow_precool_commands_one(object: sensorflow_precool_commands_insert_input!, on_conflict: sensorflow_precool_commands_on_conflict): sensorflow_precool_commands
  insert_sensorflow_precool_schedules(objects: [sensorflow_precool_schedules_insert_input!]!, on_conflict: sensorflow_precool_schedules_on_conflict): sensorflow_precool_schedules_mutation_response
  insert_sensorflow_precool_schedules_one(object: sensorflow_precool_schedules_insert_input!, on_conflict: sensorflow_precool_schedules_on_conflict): sensorflow_precool_schedules
  insert_sensorflow_pumps(objects: [sensorflow_pumps_insert_input!]!, on_conflict: sensorflow_pumps_on_conflict): sensorflow_pumps_mutation_response
  insert_sensorflow_reservation_guests(objects: [sensorflow_reservation_guests_insert_input!]!, on_conflict: sensorflow_reservation_guests_on_conflict): sensorflow_reservation_guests_mutation_response
  insert_sensorflow_reservation_guests_one(object: sensorflow_reservation_guests_insert_input!, on_conflict: sensorflow_reservation_guests_on_conflict): sensorflow_reservation_guests
  insert_sensorflow_reservations(objects: [sensorflow_reservations_insert_input!]!, on_conflict: sensorflow_reservations_on_conflict): sensorflow_reservations_mutation_response
  insert_sensorflow_reservations_one(object: sensorflow_reservations_insert_input!, on_conflict: sensorflow_reservations_on_conflict): sensorflow_reservations
  insert_sensorflow_roles(objects: [sensorflow_roles_insert_input!]!, on_conflict: sensorflow_roles_on_conflict): sensorflow_roles_mutation_response
  insert_sensorflow_roles_one(object: sensorflow_roles_insert_input!, on_conflict: sensorflow_roles_on_conflict): sensorflow_roles
  insert_sensorflow_smart_rem_validation_history(objects: [sensorflow_smart_rem_validation_history_insert_input!]!, on_conflict: sensorflow_smart_rem_validation_history_on_conflict): sensorflow_smart_rem_validation_history_mutation_response
  insert_sensorflow_task_status_history(objects: [sensorflow_task_status_history_insert_input!]!, on_conflict: sensorflow_task_status_history_on_conflict): sensorflow_task_status_history_mutation_response
  insert_sensorflow_task_status_history_one(object: sensorflow_task_status_history_insert_input!, on_conflict: sensorflow_task_status_history_on_conflict): sensorflow_task_status_history
  insert_sensorflow_tasks(objects: [sensorflow_tasks_insert_input!]!, on_conflict: sensorflow_tasks_on_conflict): sensorflow_tasks_mutation_response
  insert_sensorflow_tasks_one(object: sensorflow_tasks_insert_input!, on_conflict: sensorflow_tasks_on_conflict): sensorflow_tasks
  insert_sensorflow_tests(objects: [sensorflow_tests_insert_input!]!, on_conflict: sensorflow_tests_on_conflict): sensorflow_tests_mutation_response
  insert_sensorflow_tests_one(object: sensorflow_tests_insert_input!, on_conflict: sensorflow_tests_on_conflict): sensorflow_tests
  insert_sensorflow_timezones(objects: [sensorflow_timezones_insert_input!]!, on_conflict: sensorflow_timezones_on_conflict): sensorflow_timezones_mutation_response
  insert_sensorflow_timezones_one(object: sensorflow_timezones_insert_input!, on_conflict: sensorflow_timezones_on_conflict): sensorflow_timezones
  insert_sensorflow_user_to_permission_mapping(objects: [sensorflow_user_to_permission_mapping_insert_input!]!): sensorflow_user_to_permission_mapping_mutation_response
  insert_sensorflow_user_to_permission_mapping_one(object: sensorflow_user_to_permission_mapping_insert_input!): sensorflow_user_to_permission_mapping
  insert_sensorflow_users(objects: [sensorflow_users_insert_input!]!, on_conflict: sensorflow_users_on_conflict): sensorflow_users_mutation_response
  insert_sensorflow_users_one(object: sensorflow_users_insert_input!, on_conflict: sensorflow_users_on_conflict): sensorflow_users
  insert_sensorflow_warehouse_kitting_process_metadata(objects: [sensorflow_warehouse_kitting_process_metadata_insert_input!]!, on_conflict: sensorflow_warehouse_kitting_process_metadata_on_conflict): sensorflow_warehouse_kitting_process_metadata_mutation_response
  insert_sensorflow_warehouse_kitting_process_metadata_one(object: sensorflow_warehouse_kitting_process_metadata_insert_input!, on_conflict: sensorflow_warehouse_kitting_process_metadata_on_conflict): sensorflow_warehouse_kitting_process_metadata
  insert_sf_firmware_dist_pipeline_firmware_build(objects: [sf_firmware_dist_pipeline_firmware_build_insert_input!]!, on_conflict: sf_firmware_dist_pipeline_firmware_build_on_conflict): sf_firmware_dist_pipeline_firmware_build_mutation_response
  insert_sf_firmware_dist_pipeline_firmware_build_one(object: sf_firmware_dist_pipeline_firmware_build_insert_input!, on_conflict: sf_firmware_dist_pipeline_firmware_build_on_conflict): sf_firmware_dist_pipeline_firmware_build
  insert_sf_firmware_dist_pipeline_firmware_numbers(objects: [sf_firmware_dist_pipeline_firmware_numbers_insert_input!]!, on_conflict: sf_firmware_dist_pipeline_firmware_numbers_on_conflict): sf_firmware_dist_pipeline_firmware_numbers_mutation_response
  insert_sf_firmware_dist_pipeline_firmware_numbers_one(object: sf_firmware_dist_pipeline_firmware_numbers_insert_input!, on_conflict: sf_firmware_dist_pipeline_firmware_numbers_on_conflict): sf_firmware_dist_pipeline_firmware_numbers
  insert_sf_pms_pms_pmslist(objects: [sf_pms_pms_pmslist_insert_input!]!, on_conflict: sf_pms_pms_pmslist_on_conflict): sf_pms_pms_pmslist_mutation_response
  insert_sf_pms_pms_pmslist_one(object: sf_pms_pms_pmslist_insert_input!, on_conflict: sf_pms_pms_pmslist_on_conflict): sf_pms_pms_pmslist
  insert_sf_pms_pms_properties(objects: [sf_pms_pms_properties_insert_input!]!, on_conflict: sf_pms_pms_properties_on_conflict): sf_pms_pms_properties_mutation_response
  insert_sf_pms_pms_properties_one(object: sf_pms_pms_properties_insert_input!, on_conflict: sf_pms_pms_properties_on_conflict): sf_pms_pms_properties
  insert_sf_pms_pms_property_eod_surveys(objects: [sf_pms_pms_property_eod_surveys_insert_input!]!, on_conflict: sf_pms_pms_property_eod_surveys_on_conflict): sf_pms_pms_property_eod_surveys_mutation_response
  insert_sf_pms_pms_property_eod_surveys_one(object: sf_pms_pms_property_eod_surveys_insert_input!, on_conflict: sf_pms_pms_property_eod_surveys_on_conflict): sf_pms_pms_property_eod_surveys
  insert_sf_pms_pms_reservations(objects: [sf_pms_pms_reservations_insert_input!]!, on_conflict: sf_pms_pms_reservations_on_conflict): sf_pms_pms_reservations_mutation_response
  insert_sf_pms_pms_reservations_one(object: sf_pms_pms_reservations_insert_input!, on_conflict: sf_pms_pms_reservations_on_conflict): sf_pms_pms_reservations
  insert_sf_pms_pms_room_categories(objects: [sf_pms_pms_room_categories_insert_input!]!, on_conflict: sf_pms_pms_room_categories_on_conflict): sf_pms_pms_room_categories_mutation_response
  insert_sf_pms_pms_room_categories_one(object: sf_pms_pms_room_categories_insert_input!, on_conflict: sf_pms_pms_room_categories_on_conflict): sf_pms_pms_room_categories
  insert_sf_pms_pms_rooms(objects: [sf_pms_pms_rooms_insert_input!]!, on_conflict: sf_pms_pms_rooms_on_conflict): sf_pms_pms_rooms_mutation_response
  insert_sf_pms_pms_rooms_one(object: sf_pms_pms_rooms_insert_input!, on_conflict: sf_pms_pms_rooms_on_conflict): sf_pms_pms_rooms
  insert_sf_pms_smartalloc_report(objects: [sf_pms_smartalloc_report_insert_input!]!, on_conflict: sf_pms_smartalloc_report_on_conflict): sf_pms_smartalloc_report_mutation_response
  insert_sf_pms_smartalloc_report_one(object: sf_pms_smartalloc_report_insert_input!, on_conflict: sf_pms_smartalloc_report_on_conflict): sf_pms_smartalloc_report
  insert_sf_simulation_instrumentation_commands(objects: [sf_simulation_instrumentation_commands_insert_input!]!, on_conflict: sf_simulation_instrumentation_commands_on_conflict): sf_simulation_instrumentation_commands_mutation_response
  insert_sf_simulation_instrumentation_commands_one(object: sf_simulation_instrumentation_commands_insert_input!, on_conflict: sf_simulation_instrumentation_commands_on_conflict): sf_simulation_instrumentation_commands
  insert_sf_support_actions(objects: [sf_support_actions_insert_input!]!, on_conflict: sf_support_actions_on_conflict): sf_support_actions_mutation_response
  insert_sf_support_actions_one(object: sf_support_actions_insert_input!, on_conflict: sf_support_actions_on_conflict): sf_support_actions
  insert_sf_support_active_property_alerts_one(object: sf_support_active_property_alerts_insert_input!, on_conflict: sf_support_active_property_alerts_on_conflict): sf_support_active_property_alerts
  insert_sf_support_analytics_ticket_jobs_running_histories(objects: [sf_support_analytics_ticket_jobs_running_histories_insert_input!]!, on_conflict: sf_support_analytics_ticket_jobs_running_histories_on_conflict): sf_support_analytics_ticket_jobs_running_histories_mutation_response
  insert_sf_support_analytics_ticket_jobs_running_histories_one(object: sf_support_analytics_ticket_jobs_running_histories_insert_input!, on_conflict: sf_support_analytics_ticket_jobs_running_histories_on_conflict): sf_support_analytics_ticket_jobs_running_histories
  insert_sf_support_root_causes(objects: [sf_support_root_causes_insert_input!]!, on_conflict: sf_support_root_causes_on_conflict): sf_support_root_causes_mutation_response
  insert_sf_support_root_causes_one(object: sf_support_root_causes_insert_input!, on_conflict: sf_support_root_causes_on_conflict): sf_support_root_causes
  insert_sf_support_ticket_classes(objects: [sf_support_ticket_classes_insert_input!]!, on_conflict: sf_support_ticket_classes_on_conflict): sf_support_ticket_classes_mutation_response
  insert_sf_support_ticket_classes_one(object: sf_support_ticket_classes_insert_input!, on_conflict: sf_support_ticket_classes_on_conflict): sf_support_ticket_classes
  insert_sf_support_ticket_status(objects: [sf_support_ticket_status_insert_input!]!, on_conflict: sf_support_ticket_status_on_conflict): sf_support_ticket_status_mutation_response
  insert_sf_support_ticket_status_one(object: sf_support_ticket_status_insert_input!, on_conflict: sf_support_ticket_status_on_conflict): sf_support_ticket_status
  insert_sf_support_tickets(objects: [sf_support_tickets_insert_input!]!, on_conflict: sf_support_tickets_on_conflict): sf_support_tickets_mutation_response
  insert_sf_support_tickets_one(object: sf_support_tickets_insert_input!, on_conflict: sf_support_tickets_on_conflict): sf_support_tickets
  insert_sf_testjig_test_jig_report_metadata(objects: [sf_testjig_test_jig_report_metadata_insert_input!]!, on_conflict: sf_testjig_test_jig_report_metadata_on_conflict): sf_testjig_test_jig_report_metadata_mutation_response
  insert_sf_testjig_test_jig_report_metadata_one(object: sf_testjig_test_jig_report_metadata_insert_input!, on_conflict: sf_testjig_test_jig_report_metadata_on_conflict): sf_testjig_test_jig_report_metadata
  insert_sf_testjig_test_jig_report_metadatas(objects: [sf_testjig_test_jig_report_metadatas_insert_input!]!, on_conflict: sf_testjig_test_jig_report_metadatas_on_conflict): sf_testjig_test_jig_report_metadatas_mutation_response
  insert_sf_testjig_test_jig_report_metadatas_one(object: sf_testjig_test_jig_report_metadatas_insert_input!, on_conflict: sf_testjig_test_jig_report_metadatas_on_conflict): sf_testjig_test_jig_report_metadatas
  mapInfraNode(emonSetting: Int, infraId: ID!, mapInput: Int, nodeMacInput: String!, nodeSubType: String, nodeType: NodeType!, slotName: String!): MappedNode!
  mapNode(nodeMacInput: String!, nodeSubType: String, nodeType: NodeType!, roomId: ID!, slotName: String!): NodeMappingResponse!
  markKeyAsDnD(comment: String, isMeterPosition: Boolean, keyPositionId: ID!): KeyEntry!
  precoolComfortPlusRoom(roomIds: [ID!]!): Boolean!
  removeLocationDomoEmbed(embed_id: String!, location_id: uuid!): sensorflow_location_domo_embeds
  removeNodeSpareSet(nodeMacId: String!): Boolean!
  renameGateway(gatewayId: ID!, gatewayName: String!): Gateway!
  resetPositionConfigurations(positionIds: [String!]!): [PositionConfiguration!]!
  root: String
  saveGuestPrecoolSchedule(input: GuestPrecoolScheduleInput!): Boolean!
  sensorflow_ai_f_send_ac_command(args: sensorflow_ai_f_send_ac_command_args!, distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): [sensorflow_ac_setting_commands!]!
  setupTestData: String
  skipRapidMapping(nodeType: NodeType!, roomId: ID!, slotName: String!): NodeMappingResponse!
  startInstallationMode(automaticTestRun: Boolean, isMeterPosition: Boolean, isResumedEntry: Boolean!, keyPositionId: ID!, positionFunction: String!, userId: ID!): KeyEntry!
  startTesting(isMeterPosition: Boolean, keyPositionId: ID!, positionFunction: String!, resumePreviousSession: Boolean): Boolean!
  swapChannels(destinationCompressorId: ID!, startingCompressorId: ID!): Boolean!
  teardownTestData: String
  triggerToUploadGatewayImages(gatewayIds: [String!]!): Boolean!
  unClaimKey(keyPositionId: ID!, userId: ID!): KeyEntry
  unmapInfraNode(comment: String, infraId: ID!, nodeMacId: String!, rootCauseId: Int, scannedMacId: String): MappedNode!
  unmapNode(comment: String, nodeMacInput: String!, nodeSubType: String, nodeType: NodeType!, roomId: ID!, rootCauseId: Int, scannedMacId: String, slotName: String!): NodeMappingResponse!
  updateChiller(_inc: sensorflow_chillers_inc_input, _set: sensorflow_chillers_set_input, pk_columns: sensorflow_chillers_pk_columns_input!): sensorflow_chillers
  updateComfortPlusActivations(_inc: sensorflow_comfortplus_activations_inc_input, _set: sensorflow_comfortplus_activations_set_input, where: sensorflow_comfortplus_activations_bool_exp!): sensorflow_comfortplus_activations_mutation_response
  updateCompressor(_inc: sensorflow_compressors_inc_input, _set: sensorflow_compressors_set_input, pk_columns: sensorflow_compressors_pk_columns_input!): sensorflow_compressors
  updateCompressors(updates: [sensorflow_compressors_updates!]!): [sensorflow_compressors_mutation_response]
  updateCoolingTower(_inc: sensorflow_cooling_towers_inc_input, _set: sensorflow_cooling_towers_set_input, pk_columns: sensorflow_cooling_towers_pk_columns_input!): sensorflow_cooling_towers
  updateDefaultPositionConfiguration(positionConfiguration: PositionConfigurationInput!): PositionConfigurationWithThermostatStatus!
  updateDomoEmbed(_set: sensorflow_domo_embeds_set_input, pk_columns: sensorflow_domo_embeds_pk_columns_input!): sensorflow_domo_embeds
  updateFirmwareRelease(_inc: sensorflow_firmware_releases_inc_input, _set: sensorflow_firmware_releases_set_input, pk_columns: sensorflow_firmware_releases_pk_columns_input!): sensorflow_firmware_releases
  updateGatewayHardwareType(gatewayId: ID!, type: GatewayHardwareType!): Gateway!
  updateGroup(groupId: ID!, groupName: String!, groupType: String!): Boolean!
  updateInfraNode(emonSetting: Int, infraId: ID!, mapInput: Int, nodeMacId: String!, slotName: String!): Boolean!
  updateKeyCategory(_set: sensorflow_key_categories_set_input, pk_columns: sensorflow_key_categories_pk_columns_input!): sensorflow_key_categories
  updateKeyCategoryToKeyMappings(_set: sensorflow_key_category_to_key_position_set_input, where: sensorflow_key_category_to_key_position_bool_exp!): sensorflow_key_category_to_key_position_mutation_response
  updateKeyEntries(_inc: keyEntries_inc_input, _set: keyEntries_set_input, where: keyEntries_bool_exp!): keyEntries_mutation_response
  updateLocation(_inc: sensorflow_locations_inc_input, _set: sensorflow_locations_set_input, pk_columns: sensorflow_locations_pk_columns_input!): sensorflow_locations
  updateLocationBeaconSlotManagement(beaconSlotManagementEnabled: Boolean!, locationId: ID!): Boolean!
  updateLocationGatewayHardwareType(locationId: ID!, type: GatewayHardwareType!): Boolean!
  updateLocationGatewayPromiscuousMode(locationId: ID!, promiscuousModeEnabled: Boolean!): Boolean!
  updateLocationMetadata(_inc: sensorflow_location_metadata_inc_input, _set: sensorflow_location_metadata_set_input, where: sensorflow_location_metadata_bool_exp!): sensorflow_location_metadata_mutation_response
  updateLocationSetEnableNoKeyCardState(enableNoKeyCardState: Boolean!, locationId: ID!): Boolean!
  updateNodeToSlotMappings(_inc: sensorflow_node_to_slot_mapping_inc_input, _set: sensorflow_node_to_slot_mapping_set_input, where: sensorflow_node_to_slot_mapping_bool_exp!): sensorflow_node_to_slot_mapping_mutation_response
  updatePipe(_set: sensorflow_pipes_set_input, pk_columns: sensorflow_pipes_pk_columns_input!): sensorflow_pipes
  updatePosition(_set: sensorflow_positions_set_input, pk_columns: sensorflow_positions_pk_columns_input!): sensorflow_positions
  updatePositionConfiguration(positionConfiguration: PositionConfigurationInput!): PositionConfigurationWithThermostatStatus!
  updatePositionConfigurations(_inc: positionConfigurations_inc_input, _set: positionConfigurations_set_input, where: positionConfigurations_bool_exp!): positionConfigurations_mutation_response
  updatePositionConfigurationsAutomationMode(automationMode: String, heatingUnoccupiedTemp: Int, positionIds: [String!]!, unoccupiedTemp: Int): [PositionConfiguration!]!
  updatePositions(_set: sensorflow_positions_set_input, where: sensorflow_positions_bool_exp!): sensorflow_positions_mutation_response
  updatePump(_inc: sensorflow_pumps_inc_input, _set: sensorflow_pumps_set_input, pk_columns: sensorflow_pumps_pk_columns_input!): sensorflow_pumps
  updateSlot(_set: sensorflow_node_slots_set_input, pk_columns: sensorflow_node_slots_pk_columns_input!): sensorflow_node_slots
  updateSlotTasksToPending(roomId: ID!): Boolean!
  updateTestJig(_inc: sf_testjig_test_jig_inc_input, _set: sf_testjig_test_jig_set_input, pk_columns: sf_testjig_test_jig_pk_columns_input!): sf_testjig_test_jig
  updateTestJigMaintenanceReport(_set: sf_testjig_test_jig_maintenance_report_set_input, pk_columns: sf_testjig_test_jig_maintenance_report_pk_columns_input!): sf_testjig_test_jig_maintenance_report
  updateTestJigMaintenanceReports(_set: sf_testjig_test_jig_maintenance_report_set_input, where: sf_testjig_test_jig_maintenance_report_bool_exp!): sf_testjig_test_jig_maintenance_report_mutation_response
  updateTestJigTestReport(_inc: sf_testjig_test_jig_test_report_inc_input, _set: sf_testjig_test_jig_test_report_set_input, pk_columns: sf_testjig_test_jig_test_report_pk_columns_input!): sf_testjig_test_jig_test_report
  updateTestJigTestReports(_inc: sf_testjig_test_jig_test_report_inc_input, _set: sf_testjig_test_jig_test_report_set_input, where: sf_testjig_test_jig_test_report_bool_exp!): sf_testjig_test_jig_test_report_mutation_response
  updateTestJigType(_inc: sf_testjig_test_jig_type_inc_input, _set: sf_testjig_test_jig_type_set_input, pk_columns: sf_testjig_test_jig_type_pk_columns_input!): sf_testjig_test_jig_type
  updateTestJigTypes(_inc: sf_testjig_test_jig_type_inc_input, _set: sf_testjig_test_jig_type_set_input, where: sf_testjig_test_jig_type_bool_exp!): sf_testjig_test_jig_type_mutation_response
  updateTestJigs(_inc: sf_testjig_test_jig_inc_input, _set: sf_testjig_test_jig_set_input, where: sf_testjig_test_jig_bool_exp!): sf_testjig_test_jig_mutation_response
  updateTestRuns(_inc: testRuns_inc_input, _set: testRuns_set_input, where: testRuns_bool_exp!): testRuns_mutation_response
  update_keyEntries_by_pk(_inc: keyEntries_inc_input, _set: keyEntries_set_input, pk_columns: keyEntries_pk_columns_input!): keyEntries
  update_keyEntries_many(updates: [keyEntries_updates!]!): [keyEntries_mutation_response]
  update_positionConfigurations_by_pk(_inc: positionConfigurations_inc_input, _set: positionConfigurations_set_input, pk_columns: positionConfigurations_pk_columns_input!): positionConfigurations
  update_positionConfigurations_many(updates: [positionConfigurations_updates!]!): [positionConfigurations_mutation_response]
  update_sensorflow_ac_command_event_stream(_set: sensorflow_ac_command_event_stream_set_input, where: sensorflow_ac_command_event_stream_bool_exp!): sensorflow_ac_command_event_stream_mutation_response
  update_sensorflow_ac_command_event_stream_many(updates: [sensorflow_ac_command_event_stream_updates!]!): [sensorflow_ac_command_event_stream_mutation_response]
  update_sensorflow_ac_models(_inc: sensorflow_ac_models_inc_input, _set: sensorflow_ac_models_set_input, where: sensorflow_ac_models_bool_exp!): sensorflow_ac_models_mutation_response
  update_sensorflow_ac_models_by_pk(_inc: sensorflow_ac_models_inc_input, _set: sensorflow_ac_models_set_input, pk_columns: sensorflow_ac_models_pk_columns_input!): sensorflow_ac_models
  update_sensorflow_ac_models_many(updates: [sensorflow_ac_models_updates!]!): [sensorflow_ac_models_mutation_response]
  update_sensorflow_ac_setting_commands(_inc: sensorflow_ac_setting_commands_inc_input, _set: sensorflow_ac_setting_commands_set_input, where: sensorflow_ac_setting_commands_bool_exp!): sensorflow_ac_setting_commands_mutation_response
  update_sensorflow_ac_setting_commands_by_pk(_inc: sensorflow_ac_setting_commands_inc_input, _set: sensorflow_ac_setting_commands_set_input, pk_columns: sensorflow_ac_setting_commands_pk_columns_input!): sensorflow_ac_setting_commands
  update_sensorflow_ac_setting_commands_many(updates: [sensorflow_ac_setting_commands_updates!]!): [sensorflow_ac_setting_commands_mutation_response]
  update_sensorflow_ai_dt_ac_event_stream(_set: sensorflow_ai_dt_ac_event_stream_set_input, where: sensorflow_ai_dt_ac_event_stream_bool_exp!): sensorflow_ai_dt_ac_event_stream_mutation_response
  update_sensorflow_ai_dt_ac_event_stream_many(updates: [sensorflow_ai_dt_ac_event_stream_updates!]!): [sensorflow_ai_dt_ac_event_stream_mutation_response]
  update_sensorflow_ai_dt_config_event_stream(_set: sensorflow_ai_dt_config_event_stream_set_input, where: sensorflow_ai_dt_config_event_stream_bool_exp!): sensorflow_ai_dt_config_event_stream_mutation_response
  update_sensorflow_ai_dt_config_event_stream_many(updates: [sensorflow_ai_dt_config_event_stream_updates!]!): [sensorflow_ai_dt_config_event_stream_mutation_response]
  update_sensorflow_beacon_slots(_inc: sensorflow_beacon_slots_inc_input, _set: sensorflow_beacon_slots_set_input, where: sensorflow_beacon_slots_bool_exp!): sensorflow_beacon_slots_mutation_response
  update_sensorflow_beacon_slots_by_pk(_inc: sensorflow_beacon_slots_inc_input, _set: sensorflow_beacon_slots_set_input, pk_columns: sensorflow_beacon_slots_pk_columns_input!): sensorflow_beacon_slots
  update_sensorflow_beacon_slots_many(updates: [sensorflow_beacon_slots_updates!]!): [sensorflow_beacon_slots_mutation_response]
  update_sensorflow_checklist_items(_set: sensorflow_checklist_items_set_input, where: sensorflow_checklist_items_bool_exp!): sensorflow_checklist_items_mutation_response
  update_sensorflow_checklist_items_many(updates: [sensorflow_checklist_items_updates!]!): [sensorflow_checklist_items_mutation_response]
  update_sensorflow_checklists(_set: sensorflow_checklists_set_input, where: sensorflow_checklists_bool_exp!): sensorflow_checklists_mutation_response
  update_sensorflow_checklists_by_pk(_set: sensorflow_checklists_set_input, pk_columns: sensorflow_checklists_pk_columns_input!): sensorflow_checklists
  update_sensorflow_checklists_many(updates: [sensorflow_checklists_updates!]!): [sensorflow_checklists_mutation_response]
  update_sensorflow_chillers(_inc: sensorflow_chillers_inc_input, _set: sensorflow_chillers_set_input, where: sensorflow_chillers_bool_exp!): sensorflow_chillers_mutation_response
  update_sensorflow_chillers_many(updates: [sensorflow_chillers_updates!]!): [sensorflow_chillers_mutation_response]
  update_sensorflow_clickup_webhook_events(_inc: sensorflow_clickup_webhook_events_inc_input, _set: sensorflow_clickup_webhook_events_set_input, where: sensorflow_clickup_webhook_events_bool_exp!): sensorflow_clickup_webhook_events_mutation_response
  update_sensorflow_clickup_webhook_events_by_pk(_inc: sensorflow_clickup_webhook_events_inc_input, _set: sensorflow_clickup_webhook_events_set_input, pk_columns: sensorflow_clickup_webhook_events_pk_columns_input!): sensorflow_clickup_webhook_events
  update_sensorflow_clickup_webhook_events_many(updates: [sensorflow_clickup_webhook_events_updates!]!): [sensorflow_clickup_webhook_events_mutation_response]
  update_sensorflow_comfortplus_activations_by_pk(_inc: sensorflow_comfortplus_activations_inc_input, _set: sensorflow_comfortplus_activations_set_input, pk_columns: sensorflow_comfortplus_activations_pk_columns_input!): sensorflow_comfortplus_activations
  update_sensorflow_comfortplus_activations_many(updates: [sensorflow_comfortplus_activations_updates!]!): [sensorflow_comfortplus_activations_mutation_response]
  update_sensorflow_compressor_mapping(_set: sensorflow_compressor_mapping_set_input, where: sensorflow_compressor_mapping_bool_exp!): sensorflow_compressor_mapping_mutation_response
  update_sensorflow_compressor_mapping_by_pk(_set: sensorflow_compressor_mapping_set_input, pk_columns: sensorflow_compressor_mapping_pk_columns_input!): sensorflow_compressor_mapping
  update_sensorflow_compressor_mapping_many(updates: [sensorflow_compressor_mapping_updates!]!): [sensorflow_compressor_mapping_mutation_response]
  update_sensorflow_compressors(_inc: sensorflow_compressors_inc_input, _set: sensorflow_compressors_set_input, where: sensorflow_compressors_bool_exp!): sensorflow_compressors_mutation_response
  update_sensorflow_config_event_stream(_set: sensorflow_config_event_stream_set_input, where: sensorflow_config_event_stream_bool_exp!): sensorflow_config_event_stream_mutation_response
  update_sensorflow_config_event_stream_many(updates: [sensorflow_config_event_stream_updates!]!): [sensorflow_config_event_stream_mutation_response]
  update_sensorflow_cooling_towers(_inc: sensorflow_cooling_towers_inc_input, _set: sensorflow_cooling_towers_set_input, where: sensorflow_cooling_towers_bool_exp!): sensorflow_cooling_towers_mutation_response
  update_sensorflow_cooling_towers_many(updates: [sensorflow_cooling_towers_updates!]!): [sensorflow_cooling_towers_mutation_response]
  update_sensorflow_currencies(_inc: sensorflow_currencies_inc_input, _set: sensorflow_currencies_set_input, where: sensorflow_currencies_bool_exp!): sensorflow_currencies_mutation_response
  update_sensorflow_currencies_by_pk(_inc: sensorflow_currencies_inc_input, _set: sensorflow_currencies_set_input, pk_columns: sensorflow_currencies_pk_columns_input!): sensorflow_currencies
  update_sensorflow_currencies_many(updates: [sensorflow_currencies_updates!]!): [sensorflow_currencies_mutation_response]
  update_sensorflow_domo_embeds(_set: sensorflow_domo_embeds_set_input, where: sensorflow_domo_embeds_bool_exp!): sensorflow_domo_embeds_mutation_response
  update_sensorflow_domo_embeds_many(updates: [sensorflow_domo_embeds_updates!]!): [sensorflow_domo_embeds_mutation_response]
  update_sensorflow_firmware_releases(_inc: sensorflow_firmware_releases_inc_input, _set: sensorflow_firmware_releases_set_input, where: sensorflow_firmware_releases_bool_exp!): sensorflow_firmware_releases_mutation_response
  update_sensorflow_firmware_releases_many(updates: [sensorflow_firmware_releases_updates!]!): [sensorflow_firmware_releases_mutation_response]
  update_sensorflow_gateway_frequency(_inc: sensorflow_gateway_frequency_inc_input, _set: sensorflow_gateway_frequency_set_input, where: sensorflow_gateway_frequency_bool_exp!): sensorflow_gateway_frequency_mutation_response
  update_sensorflow_gateway_frequency_by_pk(_inc: sensorflow_gateway_frequency_inc_input, _set: sensorflow_gateway_frequency_set_input, pk_columns: sensorflow_gateway_frequency_pk_columns_input!): sensorflow_gateway_frequency
  update_sensorflow_gateway_frequency_many(updates: [sensorflow_gateway_frequency_updates!]!): [sensorflow_gateway_frequency_mutation_response]
  update_sensorflow_gateway_health_data(_inc: sensorflow_gateway_health_data_inc_input, _set: sensorflow_gateway_health_data_set_input, where: sensorflow_gateway_health_data_bool_exp!): sensorflow_gateway_health_data_mutation_response
  update_sensorflow_gateway_health_data_many(updates: [sensorflow_gateway_health_data_updates!]!): [sensorflow_gateway_health_data_mutation_response]
  update_sensorflow_gateway_to_position_mapping(_set: sensorflow_gateway_to_position_mapping_set_input, where: sensorflow_gateway_to_position_mapping_bool_exp!): sensorflow_gateway_to_position_mapping_mutation_response
  update_sensorflow_gateway_to_position_mapping_by_pk(_set: sensorflow_gateway_to_position_mapping_set_input, pk_columns: sensorflow_gateway_to_position_mapping_pk_columns_input!): sensorflow_gateway_to_position_mapping
  update_sensorflow_gateway_to_position_mapping_many(updates: [sensorflow_gateway_to_position_mapping_updates!]!): [sensorflow_gateway_to_position_mapping_mutation_response]
  update_sensorflow_gateways(_inc: sensorflow_gateways_inc_input, _set: sensorflow_gateways_set_input, where: sensorflow_gateways_bool_exp!): sensorflow_gateways_mutation_response
  update_sensorflow_gateways_by_pk(_inc: sensorflow_gateways_inc_input, _set: sensorflow_gateways_set_input, pk_columns: sensorflow_gateways_pk_columns_input!): sensorflow_gateways
  update_sensorflow_gateways_many(updates: [sensorflow_gateways_updates!]!): [sensorflow_gateways_mutation_response]
  update_sensorflow_get_event_stream_view(_inc: sensorflow_get_event_stream_view_inc_input, _set: sensorflow_get_event_stream_view_set_input, where: sensorflow_get_event_stream_view_bool_exp!): sensorflow_get_event_stream_view_mutation_response
  update_sensorflow_get_event_stream_view_many(updates: [sensorflow_get_event_stream_view_updates!]!): [sensorflow_get_event_stream_view_mutation_response]
  update_sensorflow_key_categories(_set: sensorflow_key_categories_set_input, where: sensorflow_key_categories_bool_exp!): sensorflow_key_categories_mutation_response
  update_sensorflow_key_categories_many(updates: [sensorflow_key_categories_updates!]!): [sensorflow_key_categories_mutation_response]
  update_sensorflow_key_category_room_templates(_set: sensorflow_key_category_room_templates_set_input, where: sensorflow_key_category_room_templates_bool_exp!): sensorflow_key_category_room_templates_mutation_response
  update_sensorflow_key_category_room_templates_by_pk(_set: sensorflow_key_category_room_templates_set_input, pk_columns: sensorflow_key_category_room_templates_pk_columns_input!): sensorflow_key_category_room_templates
  update_sensorflow_key_category_room_templates_many(updates: [sensorflow_key_category_room_templates_updates!]!): [sensorflow_key_category_room_templates_mutation_response]
  update_sensorflow_key_category_slot_templates(_set: sensorflow_key_category_slot_templates_set_input, where: sensorflow_key_category_slot_templates_bool_exp!): sensorflow_key_category_slot_templates_mutation_response
  update_sensorflow_key_category_slot_templates_by_pk(_set: sensorflow_key_category_slot_templates_set_input, pk_columns: sensorflow_key_category_slot_templates_pk_columns_input!): sensorflow_key_category_slot_templates
  update_sensorflow_key_category_slot_templates_many(updates: [sensorflow_key_category_slot_templates_updates!]!): [sensorflow_key_category_slot_templates_mutation_response]
  update_sensorflow_key_category_templates(_set: sensorflow_key_category_templates_set_input, where: sensorflow_key_category_templates_bool_exp!): sensorflow_key_category_templates_mutation_response
  update_sensorflow_key_category_templates_by_pk(_set: sensorflow_key_category_templates_set_input, pk_columns: sensorflow_key_category_templates_pk_columns_input!): sensorflow_key_category_templates
  update_sensorflow_key_category_templates_many(updates: [sensorflow_key_category_templates_updates!]!): [sensorflow_key_category_templates_mutation_response]
  update_sensorflow_key_category_to_key_position_by_pk(_set: sensorflow_key_category_to_key_position_set_input, pk_columns: sensorflow_key_category_to_key_position_pk_columns_input!): sensorflow_key_category_to_key_position
  update_sensorflow_key_category_to_key_position_many(updates: [sensorflow_key_category_to_key_position_updates!]!): [sensorflow_key_category_to_key_position_mutation_response]
  update_sensorflow_location_domo_embeds(_set: sensorflow_location_domo_embeds_set_input, where: sensorflow_location_domo_embeds_bool_exp!): sensorflow_location_domo_embeds_mutation_response
  update_sensorflow_location_domo_embeds_by_pk(_set: sensorflow_location_domo_embeds_set_input, pk_columns: sensorflow_location_domo_embeds_pk_columns_input!): sensorflow_location_domo_embeds
  update_sensorflow_location_domo_embeds_many(updates: [sensorflow_location_domo_embeds_updates!]!): [sensorflow_location_domo_embeds_mutation_response]
  update_sensorflow_location_metadata_by_pk(_inc: sensorflow_location_metadata_inc_input, _set: sensorflow_location_metadata_set_input, pk_columns: sensorflow_location_metadata_pk_columns_input!): sensorflow_location_metadata
  update_sensorflow_location_metadata_many(updates: [sensorflow_location_metadata_updates!]!): [sensorflow_location_metadata_mutation_response]
  update_sensorflow_location_to_user_mapping(_set: sensorflow_location_to_user_mapping_set_input, where: sensorflow_location_to_user_mapping_bool_exp!): sensorflow_location_to_user_mapping_mutation_response
  update_sensorflow_location_to_user_mapping_by_pk(_set: sensorflow_location_to_user_mapping_set_input, pk_columns: sensorflow_location_to_user_mapping_pk_columns_input!): sensorflow_location_to_user_mapping
  update_sensorflow_location_to_user_mapping_many(updates: [sensorflow_location_to_user_mapping_updates!]!): [sensorflow_location_to_user_mapping_mutation_response]
  update_sensorflow_locations(_inc: sensorflow_locations_inc_input, _set: sensorflow_locations_set_input, where: sensorflow_locations_bool_exp!): sensorflow_locations_mutation_response
  update_sensorflow_locations_many(updates: [sensorflow_locations_updates!]!): [sensorflow_locations_mutation_response]
  update_sensorflow_mapping_coordinates(_set: sensorflow_mapping_coordinates_set_input, where: sensorflow_mapping_coordinates_bool_exp!): sensorflow_mapping_coordinates_mutation_response
  update_sensorflow_mapping_coordinates_many(updates: [sensorflow_mapping_coordinates_updates!]!): [sensorflow_mapping_coordinates_mutation_response]
  update_sensorflow_node_exception(_inc: sensorflow_node_exception_inc_input, _set: sensorflow_node_exception_set_input, where: sensorflow_node_exception_bool_exp!): sensorflow_node_exception_mutation_response
  update_sensorflow_node_exception_by_pk(_inc: sensorflow_node_exception_inc_input, _set: sensorflow_node_exception_set_input, pk_columns: sensorflow_node_exception_pk_columns_input!): sensorflow_node_exception
  update_sensorflow_node_exception_many(updates: [sensorflow_node_exception_updates!]!): [sensorflow_node_exception_mutation_response]
  update_sensorflow_node_exceptions(_inc: sensorflow_node_exceptions_inc_input, _set: sensorflow_node_exceptions_set_input, where: sensorflow_node_exceptions_bool_exp!): sensorflow_node_exceptions_mutation_response
  update_sensorflow_node_exceptions_by_pk(_inc: sensorflow_node_exceptions_inc_input, _set: sensorflow_node_exceptions_set_input, pk_columns: sensorflow_node_exceptions_pk_columns_input!): sensorflow_node_exceptions
  update_sensorflow_node_exceptions_many(updates: [sensorflow_node_exceptions_updates!]!): [sensorflow_node_exceptions_mutation_response]
  update_sensorflow_node_join_data(_inc: sensorflow_node_join_data_inc_input, _set: sensorflow_node_join_data_set_input, where: sensorflow_node_join_data_bool_exp!): sensorflow_node_join_data_mutation_response
  update_sensorflow_node_join_data_many(updates: [sensorflow_node_join_data_updates!]!): [sensorflow_node_join_data_mutation_response]
  update_sensorflow_node_life_cycle_event_types(_inc: sensorflow_node_life_cycle_event_types_inc_input, _set: sensorflow_node_life_cycle_event_types_set_input, where: sensorflow_node_life_cycle_event_types_bool_exp!): sensorflow_node_life_cycle_event_types_mutation_response
  update_sensorflow_node_life_cycle_event_types_by_pk(_inc: sensorflow_node_life_cycle_event_types_inc_input, _set: sensorflow_node_life_cycle_event_types_set_input, pk_columns: sensorflow_node_life_cycle_event_types_pk_columns_input!): sensorflow_node_life_cycle_event_types
  update_sensorflow_node_life_cycle_event_types_many(updates: [sensorflow_node_life_cycle_event_types_updates!]!): [sensorflow_node_life_cycle_event_types_mutation_response]
  update_sensorflow_node_life_cycle_events(_inc: sensorflow_node_life_cycle_events_inc_input, _set: sensorflow_node_life_cycle_events_set_input, where: sensorflow_node_life_cycle_events_bool_exp!): sensorflow_node_life_cycle_events_mutation_response
  update_sensorflow_node_life_cycle_events_by_pk(_inc: sensorflow_node_life_cycle_events_inc_input, _set: sensorflow_node_life_cycle_events_set_input, pk_columns: sensorflow_node_life_cycle_events_pk_columns_input!): sensorflow_node_life_cycle_events
  update_sensorflow_node_life_cycle_events_many(updates: [sensorflow_node_life_cycle_events_updates!]!): [sensorflow_node_life_cycle_events_mutation_response]
  update_sensorflow_node_measurements(_inc: sensorflow_node_measurements_inc_input, _set: sensorflow_node_measurements_set_input, where: sensorflow_node_measurements_bool_exp!): sensorflow_node_measurements_mutation_response
  update_sensorflow_node_measurements_many(updates: [sensorflow_node_measurements_updates!]!): [sensorflow_node_measurements_mutation_response]
  update_sensorflow_node_meta_data(_inc: sensorflow_node_meta_data_inc_input, _set: sensorflow_node_meta_data_set_input, where: sensorflow_node_meta_data_bool_exp!): sensorflow_node_meta_data_mutation_response
  update_sensorflow_node_meta_data_by_pk(_inc: sensorflow_node_meta_data_inc_input, _set: sensorflow_node_meta_data_set_input, pk_columns: sensorflow_node_meta_data_pk_columns_input!): sensorflow_node_meta_data
  update_sensorflow_node_meta_data_many(updates: [sensorflow_node_meta_data_updates!]!): [sensorflow_node_meta_data_mutation_response]
  update_sensorflow_node_slots(_set: sensorflow_node_slots_set_input, where: sensorflow_node_slots_bool_exp!): sensorflow_node_slots_mutation_response
  update_sensorflow_node_slots_many(updates: [sensorflow_node_slots_updates!]!): [sensorflow_node_slots_mutation_response]
  update_sensorflow_node_spare_set(_set: sensorflow_node_spare_set_set_input, where: sensorflow_node_spare_set_bool_exp!): sensorflow_node_spare_set_mutation_response
  update_sensorflow_node_spare_set_by_pk(_set: sensorflow_node_spare_set_set_input, pk_columns: sensorflow_node_spare_set_pk_columns_input!): sensorflow_node_spare_set
  update_sensorflow_node_spare_set_many(updates: [sensorflow_node_spare_set_updates!]!): [sensorflow_node_spare_set_mutation_response]
  update_sensorflow_node_status_history(_inc: sensorflow_node_status_history_inc_input, _set: sensorflow_node_status_history_set_input, where: sensorflow_node_status_history_bool_exp!): sensorflow_node_status_history_mutation_response
  update_sensorflow_node_status_history_by_pk(_inc: sensorflow_node_status_history_inc_input, _set: sensorflow_node_status_history_set_input, pk_columns: sensorflow_node_status_history_pk_columns_input!): sensorflow_node_status_history
  update_sensorflow_node_status_history_many(updates: [sensorflow_node_status_history_updates!]!): [sensorflow_node_status_history_mutation_response]
  update_sensorflow_node_statuses(_inc: sensorflow_node_statuses_inc_input, _set: sensorflow_node_statuses_set_input, where: sensorflow_node_statuses_bool_exp!): sensorflow_node_statuses_mutation_response
  update_sensorflow_node_statuses_by_pk(_inc: sensorflow_node_statuses_inc_input, _set: sensorflow_node_statuses_set_input, pk_columns: sensorflow_node_statuses_pk_columns_input!): sensorflow_node_statuses
  update_sensorflow_node_statuses_many(updates: [sensorflow_node_statuses_updates!]!): [sensorflow_node_statuses_mutation_response]
  update_sensorflow_node_system_states(_inc: sensorflow_node_system_states_inc_input, _set: sensorflow_node_system_states_set_input, where: sensorflow_node_system_states_bool_exp!): sensorflow_node_system_states_mutation_response
  update_sensorflow_node_system_states_by_pk(_inc: sensorflow_node_system_states_inc_input, _set: sensorflow_node_system_states_set_input, pk_columns: sensorflow_node_system_states_pk_columns_input!): sensorflow_node_system_states
  update_sensorflow_node_system_states_many(updates: [sensorflow_node_system_states_updates!]!): [sensorflow_node_system_states_mutation_response]
  update_sensorflow_node_to_position_mapping(_set: sensorflow_node_to_position_mapping_set_input, where: sensorflow_node_to_position_mapping_bool_exp!): sensorflow_node_to_position_mapping_mutation_response
  update_sensorflow_node_to_position_mapping_many(updates: [sensorflow_node_to_position_mapping_updates!]!): [sensorflow_node_to_position_mapping_mutation_response]
  update_sensorflow_node_to_slot_mapping_by_pk(_inc: sensorflow_node_to_slot_mapping_inc_input, _set: sensorflow_node_to_slot_mapping_set_input, pk_columns: sensorflow_node_to_slot_mapping_pk_columns_input!): sensorflow_node_to_slot_mapping
  update_sensorflow_node_to_slot_mapping_many(updates: [sensorflow_node_to_slot_mapping_updates!]!): [sensorflow_node_to_slot_mapping_mutation_response]
  update_sensorflow_nodes(_inc: sensorflow_nodes_inc_input, _set: sensorflow_nodes_set_input, where: sensorflow_nodes_bool_exp!): sensorflow_nodes_mutation_response
  update_sensorflow_nodes_by_pk(_inc: sensorflow_nodes_inc_input, _set: sensorflow_nodes_set_input, pk_columns: sensorflow_nodes_pk_columns_input!): sensorflow_nodes
  update_sensorflow_nodes_many(updates: [sensorflow_nodes_updates!]!): [sensorflow_nodes_mutation_response]
  update_sensorflow_organisations(_set: sensorflow_organisations_set_input, where: sensorflow_organisations_bool_exp!): sensorflow_organisations_mutation_response
  update_sensorflow_organisations_by_pk(_set: sensorflow_organisations_set_input, pk_columns: sensorflow_organisations_pk_columns_input!): sensorflow_organisations
  update_sensorflow_organisations_many(updates: [sensorflow_organisations_updates!]!): [sensorflow_organisations_mutation_response]
  update_sensorflow_ota_queues(_inc: sensorflow_ota_queues_inc_input, _set: sensorflow_ota_queues_set_input, where: sensorflow_ota_queues_bool_exp!): sensorflow_ota_queues_mutation_response
  update_sensorflow_ota_queues_by_pk(_inc: sensorflow_ota_queues_inc_input, _set: sensorflow_ota_queues_set_input, pk_columns: sensorflow_ota_queues_pk_columns_input!): sensorflow_ota_queues
  update_sensorflow_ota_queues_many(updates: [sensorflow_ota_queues_updates!]!): [sensorflow_ota_queues_mutation_response]
  update_sensorflow_ota_runs(_inc: sensorflow_ota_runs_inc_input, _set: sensorflow_ota_runs_set_input, where: sensorflow_ota_runs_bool_exp!): sensorflow_ota_runs_mutation_response
  update_sensorflow_ota_runs_by_pk(_inc: sensorflow_ota_runs_inc_input, _set: sensorflow_ota_runs_set_input, pk_columns: sensorflow_ota_runs_pk_columns_input!): sensorflow_ota_runs
  update_sensorflow_ota_runs_many(updates: [sensorflow_ota_runs_updates!]!): [sensorflow_ota_runs_mutation_response]
  update_sensorflow_pipes(_set: sensorflow_pipes_set_input, where: sensorflow_pipes_bool_exp!): sensorflow_pipes_mutation_response
  update_sensorflow_pipes_many(updates: [sensorflow_pipes_updates!]!): [sensorflow_pipes_mutation_response]
  update_sensorflow_position_signal_stats(_inc: sensorflow_position_signal_stats_inc_input, _set: sensorflow_position_signal_stats_set_input, where: sensorflow_position_signal_stats_bool_exp!): sensorflow_position_signal_stats_mutation_response
  update_sensorflow_position_signal_stats_by_pk(_inc: sensorflow_position_signal_stats_inc_input, _set: sensorflow_position_signal_stats_set_input, pk_columns: sensorflow_position_signal_stats_pk_columns_input!): sensorflow_position_signal_stats
  update_sensorflow_position_signal_stats_many(updates: [sensorflow_position_signal_stats_updates!]!): [sensorflow_position_signal_stats_mutation_response]
  update_sensorflow_positions_many(updates: [sensorflow_positions_updates!]!): [sensorflow_positions_mutation_response]
  update_sensorflow_precool_commands(_inc: sensorflow_precool_commands_inc_input, _set: sensorflow_precool_commands_set_input, where: sensorflow_precool_commands_bool_exp!): sensorflow_precool_commands_mutation_response
  update_sensorflow_precool_commands_by_pk(_inc: sensorflow_precool_commands_inc_input, _set: sensorflow_precool_commands_set_input, pk_columns: sensorflow_precool_commands_pk_columns_input!): sensorflow_precool_commands
  update_sensorflow_precool_commands_many(updates: [sensorflow_precool_commands_updates!]!): [sensorflow_precool_commands_mutation_response]
  update_sensorflow_precool_schedules(_inc: sensorflow_precool_schedules_inc_input, _set: sensorflow_precool_schedules_set_input, where: sensorflow_precool_schedules_bool_exp!): sensorflow_precool_schedules_mutation_response
  update_sensorflow_precool_schedules_by_pk(_inc: sensorflow_precool_schedules_inc_input, _set: sensorflow_precool_schedules_set_input, pk_columns: sensorflow_precool_schedules_pk_columns_input!): sensorflow_precool_schedules
  update_sensorflow_precool_schedules_many(updates: [sensorflow_precool_schedules_updates!]!): [sensorflow_precool_schedules_mutation_response]
  update_sensorflow_pumps(_inc: sensorflow_pumps_inc_input, _set: sensorflow_pumps_set_input, where: sensorflow_pumps_bool_exp!): sensorflow_pumps_mutation_response
  update_sensorflow_pumps_many(updates: [sensorflow_pumps_updates!]!): [sensorflow_pumps_mutation_response]
  update_sensorflow_reservation_guests(_inc: sensorflow_reservation_guests_inc_input, _set: sensorflow_reservation_guests_set_input, where: sensorflow_reservation_guests_bool_exp!): sensorflow_reservation_guests_mutation_response
  update_sensorflow_reservation_guests_by_pk(_inc: sensorflow_reservation_guests_inc_input, _set: sensorflow_reservation_guests_set_input, pk_columns: sensorflow_reservation_guests_pk_columns_input!): sensorflow_reservation_guests
  update_sensorflow_reservation_guests_many(updates: [sensorflow_reservation_guests_updates!]!): [sensorflow_reservation_guests_mutation_response]
  update_sensorflow_reservations(_inc: sensorflow_reservations_inc_input, _set: sensorflow_reservations_set_input, where: sensorflow_reservations_bool_exp!): sensorflow_reservations_mutation_response
  update_sensorflow_reservations_by_pk(_inc: sensorflow_reservations_inc_input, _set: sensorflow_reservations_set_input, pk_columns: sensorflow_reservations_pk_columns_input!): sensorflow_reservations
  update_sensorflow_reservations_many(updates: [sensorflow_reservations_updates!]!): [sensorflow_reservations_mutation_response]
  update_sensorflow_roles(_set: sensorflow_roles_set_input, where: sensorflow_roles_bool_exp!): sensorflow_roles_mutation_response
  update_sensorflow_roles_by_pk(_set: sensorflow_roles_set_input, pk_columns: sensorflow_roles_pk_columns_input!): sensorflow_roles
  update_sensorflow_roles_many(updates: [sensorflow_roles_updates!]!): [sensorflow_roles_mutation_response]
  update_sensorflow_smart_rem_validation_history(_inc: sensorflow_smart_rem_validation_history_inc_input, _set: sensorflow_smart_rem_validation_history_set_input, where: sensorflow_smart_rem_validation_history_bool_exp!): sensorflow_smart_rem_validation_history_mutation_response
  update_sensorflow_smart_rem_validation_history_by_pk(_inc: sensorflow_smart_rem_validation_history_inc_input, _set: sensorflow_smart_rem_validation_history_set_input, pk_columns: sensorflow_smart_rem_validation_history_pk_columns_input!): sensorflow_smart_rem_validation_history
  update_sensorflow_smart_rem_validation_history_many(updates: [sensorflow_smart_rem_validation_history_updates!]!): [sensorflow_smart_rem_validation_history_mutation_response]
  update_sensorflow_task_status_history(_inc: sensorflow_task_status_history_inc_input, _set: sensorflow_task_status_history_set_input, where: sensorflow_task_status_history_bool_exp!): sensorflow_task_status_history_mutation_response
  update_sensorflow_task_status_history_by_pk(_inc: sensorflow_task_status_history_inc_input, _set: sensorflow_task_status_history_set_input, pk_columns: sensorflow_task_status_history_pk_columns_input!): sensorflow_task_status_history
  update_sensorflow_task_status_history_many(updates: [sensorflow_task_status_history_updates!]!): [sensorflow_task_status_history_mutation_response]
  update_sensorflow_tasks(_inc: sensorflow_tasks_inc_input, _set: sensorflow_tasks_set_input, where: sensorflow_tasks_bool_exp!): sensorflow_tasks_mutation_response
  update_sensorflow_tasks_by_pk(_inc: sensorflow_tasks_inc_input, _set: sensorflow_tasks_set_input, pk_columns: sensorflow_tasks_pk_columns_input!): sensorflow_tasks
  update_sensorflow_tasks_many(updates: [sensorflow_tasks_updates!]!): [sensorflow_tasks_mutation_response]
  update_sensorflow_tests(_inc: sensorflow_tests_inc_input, _set: sensorflow_tests_set_input, where: sensorflow_tests_bool_exp!): sensorflow_tests_mutation_response
  update_sensorflow_tests_by_pk(_inc: sensorflow_tests_inc_input, _set: sensorflow_tests_set_input, pk_columns: sensorflow_tests_pk_columns_input!): sensorflow_tests
  update_sensorflow_tests_many(updates: [sensorflow_tests_updates!]!): [sensorflow_tests_mutation_response]
  update_sensorflow_timezones(_inc: sensorflow_timezones_inc_input, _set: sensorflow_timezones_set_input, where: sensorflow_timezones_bool_exp!): sensorflow_timezones_mutation_response
  update_sensorflow_timezones_by_pk(_inc: sensorflow_timezones_inc_input, _set: sensorflow_timezones_set_input, pk_columns: sensorflow_timezones_pk_columns_input!): sensorflow_timezones
  update_sensorflow_timezones_many(updates: [sensorflow_timezones_updates!]!): [sensorflow_timezones_mutation_response]
  update_sensorflow_user_to_permission_mapping(_set: sensorflow_user_to_permission_mapping_set_input, where: sensorflow_user_to_permission_mapping_bool_exp!): sensorflow_user_to_permission_mapping_mutation_response
  update_sensorflow_user_to_permission_mapping_many(updates: [sensorflow_user_to_permission_mapping_updates!]!): [sensorflow_user_to_permission_mapping_mutation_response]
  update_sensorflow_users(_set: sensorflow_users_set_input, where: sensorflow_users_bool_exp!): sensorflow_users_mutation_response
  update_sensorflow_users_by_pk(_set: sensorflow_users_set_input, pk_columns: sensorflow_users_pk_columns_input!): sensorflow_users
  update_sensorflow_users_many(updates: [sensorflow_users_updates!]!): [sensorflow_users_mutation_response]
  update_sensorflow_warehouse_kitting_process_metadata(_set: sensorflow_warehouse_kitting_process_metadata_set_input, where: sensorflow_warehouse_kitting_process_metadata_bool_exp!): sensorflow_warehouse_kitting_process_metadata_mutation_response
  update_sensorflow_warehouse_kitting_process_metadata_by_pk(_set: sensorflow_warehouse_kitting_process_metadata_set_input, pk_columns: sensorflow_warehouse_kitting_process_metadata_pk_columns_input!): sensorflow_warehouse_kitting_process_metadata
  update_sensorflow_warehouse_kitting_process_metadata_many(updates: [sensorflow_warehouse_kitting_process_metadata_updates!]!): [sensorflow_warehouse_kitting_process_metadata_mutation_response]
  update_sf_firmware_dist_pipeline_firmware_build(_inc: sf_firmware_dist_pipeline_firmware_build_inc_input, _set: sf_firmware_dist_pipeline_firmware_build_set_input, where: sf_firmware_dist_pipeline_firmware_build_bool_exp!): sf_firmware_dist_pipeline_firmware_build_mutation_response
  update_sf_firmware_dist_pipeline_firmware_build_by_pk(_inc: sf_firmware_dist_pipeline_firmware_build_inc_input, _set: sf_firmware_dist_pipeline_firmware_build_set_input, pk_columns: sf_firmware_dist_pipeline_firmware_build_pk_columns_input!): sf_firmware_dist_pipeline_firmware_build
  update_sf_firmware_dist_pipeline_firmware_build_many(updates: [sf_firmware_dist_pipeline_firmware_build_updates!]!): [sf_firmware_dist_pipeline_firmware_build_mutation_response]
  update_sf_firmware_dist_pipeline_firmware_numbers(_inc: sf_firmware_dist_pipeline_firmware_numbers_inc_input, _set: sf_firmware_dist_pipeline_firmware_numbers_set_input, where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp!): sf_firmware_dist_pipeline_firmware_numbers_mutation_response
  update_sf_firmware_dist_pipeline_firmware_numbers_by_pk(_inc: sf_firmware_dist_pipeline_firmware_numbers_inc_input, _set: sf_firmware_dist_pipeline_firmware_numbers_set_input, pk_columns: sf_firmware_dist_pipeline_firmware_numbers_pk_columns_input!): sf_firmware_dist_pipeline_firmware_numbers
  update_sf_firmware_dist_pipeline_firmware_numbers_many(updates: [sf_firmware_dist_pipeline_firmware_numbers_updates!]!): [sf_firmware_dist_pipeline_firmware_numbers_mutation_response]
  update_sf_pms_pms_pmslist(_inc: sf_pms_pms_pmslist_inc_input, _set: sf_pms_pms_pmslist_set_input, where: sf_pms_pms_pmslist_bool_exp!): sf_pms_pms_pmslist_mutation_response
  update_sf_pms_pms_pmslist_by_pk(_inc: sf_pms_pms_pmslist_inc_input, _set: sf_pms_pms_pmslist_set_input, pk_columns: sf_pms_pms_pmslist_pk_columns_input!): sf_pms_pms_pmslist
  update_sf_pms_pms_pmslist_many(updates: [sf_pms_pms_pmslist_updates!]!): [sf_pms_pms_pmslist_mutation_response]
  update_sf_pms_pms_properties(_inc: sf_pms_pms_properties_inc_input, _set: sf_pms_pms_properties_set_input, where: sf_pms_pms_properties_bool_exp!): sf_pms_pms_properties_mutation_response
  update_sf_pms_pms_properties_by_pk(_inc: sf_pms_pms_properties_inc_input, _set: sf_pms_pms_properties_set_input, pk_columns: sf_pms_pms_properties_pk_columns_input!): sf_pms_pms_properties
  update_sf_pms_pms_properties_many(updates: [sf_pms_pms_properties_updates!]!): [sf_pms_pms_properties_mutation_response]
  update_sf_pms_pms_property_eod_surveys(_inc: sf_pms_pms_property_eod_surveys_inc_input, _set: sf_pms_pms_property_eod_surveys_set_input, where: sf_pms_pms_property_eod_surveys_bool_exp!): sf_pms_pms_property_eod_surveys_mutation_response
  update_sf_pms_pms_property_eod_surveys_by_pk(_inc: sf_pms_pms_property_eod_surveys_inc_input, _set: sf_pms_pms_property_eod_surveys_set_input, pk_columns: sf_pms_pms_property_eod_surveys_pk_columns_input!): sf_pms_pms_property_eod_surveys
  update_sf_pms_pms_property_eod_surveys_many(updates: [sf_pms_pms_property_eod_surveys_updates!]!): [sf_pms_pms_property_eod_surveys_mutation_response]
  update_sf_pms_pms_reservations(_inc: sf_pms_pms_reservations_inc_input, _set: sf_pms_pms_reservations_set_input, where: sf_pms_pms_reservations_bool_exp!): sf_pms_pms_reservations_mutation_response
  update_sf_pms_pms_reservations_many(updates: [sf_pms_pms_reservations_updates!]!): [sf_pms_pms_reservations_mutation_response]
  update_sf_pms_pms_room_categories(_inc: sf_pms_pms_room_categories_inc_input, _set: sf_pms_pms_room_categories_set_input, where: sf_pms_pms_room_categories_bool_exp!): sf_pms_pms_room_categories_mutation_response
  update_sf_pms_pms_room_categories_many(updates: [sf_pms_pms_room_categories_updates!]!): [sf_pms_pms_room_categories_mutation_response]
  update_sf_pms_pms_rooms(_inc: sf_pms_pms_rooms_inc_input, _set: sf_pms_pms_rooms_set_input, where: sf_pms_pms_rooms_bool_exp!): sf_pms_pms_rooms_mutation_response
  update_sf_pms_pms_rooms_many(updates: [sf_pms_pms_rooms_updates!]!): [sf_pms_pms_rooms_mutation_response]
  update_sf_pms_smartalloc_report(_inc: sf_pms_smartalloc_report_inc_input, _set: sf_pms_smartalloc_report_set_input, where: sf_pms_smartalloc_report_bool_exp!): sf_pms_smartalloc_report_mutation_response
  update_sf_pms_smartalloc_report_by_pk(_inc: sf_pms_smartalloc_report_inc_input, _set: sf_pms_smartalloc_report_set_input, pk_columns: sf_pms_smartalloc_report_pk_columns_input!): sf_pms_smartalloc_report
  update_sf_pms_smartalloc_report_many(updates: [sf_pms_smartalloc_report_updates!]!): [sf_pms_smartalloc_report_mutation_response]
  update_sf_simulation_instrumentation_commands(_inc: sf_simulation_instrumentation_commands_inc_input, _set: sf_simulation_instrumentation_commands_set_input, where: sf_simulation_instrumentation_commands_bool_exp!): sf_simulation_instrumentation_commands_mutation_response
  update_sf_simulation_instrumentation_commands_by_pk(_inc: sf_simulation_instrumentation_commands_inc_input, _set: sf_simulation_instrumentation_commands_set_input, pk_columns: sf_simulation_instrumentation_commands_pk_columns_input!): sf_simulation_instrumentation_commands
  update_sf_simulation_instrumentation_commands_many(updates: [sf_simulation_instrumentation_commands_updates!]!): [sf_simulation_instrumentation_commands_mutation_response]
  update_sf_support_actions(_inc: sf_support_actions_inc_input, _set: sf_support_actions_set_input, where: sf_support_actions_bool_exp!): sf_support_actions_mutation_response
  update_sf_support_actions_by_pk(_inc: sf_support_actions_inc_input, _set: sf_support_actions_set_input, pk_columns: sf_support_actions_pk_columns_input!): sf_support_actions
  update_sf_support_actions_many(updates: [sf_support_actions_updates!]!): [sf_support_actions_mutation_response]
  update_sf_support_active_property_alerts(_inc: sf_support_active_property_alerts_inc_input, _set: sf_support_active_property_alerts_set_input, where: sf_support_active_property_alerts_bool_exp!): sf_support_active_property_alerts_mutation_response
  update_sf_support_active_property_alerts_by_pk(_inc: sf_support_active_property_alerts_inc_input, _set: sf_support_active_property_alerts_set_input, pk_columns: sf_support_active_property_alerts_pk_columns_input!): sf_support_active_property_alerts
  update_sf_support_active_property_alerts_many(updates: [sf_support_active_property_alerts_updates!]!): [sf_support_active_property_alerts_mutation_response]
  update_sf_support_analytics_ticket_jobs_running_histories(_inc: sf_support_analytics_ticket_jobs_running_histories_inc_input, _set: sf_support_analytics_ticket_jobs_running_histories_set_input, where: sf_support_analytics_ticket_jobs_running_histories_bool_exp!): sf_support_analytics_ticket_jobs_running_histories_mutation_response
  update_sf_support_analytics_ticket_jobs_running_histories_by_pk(_inc: sf_support_analytics_ticket_jobs_running_histories_inc_input, _set: sf_support_analytics_ticket_jobs_running_histories_set_input, pk_columns: sf_support_analytics_ticket_jobs_running_histories_pk_columns_input!): sf_support_analytics_ticket_jobs_running_histories
  update_sf_support_analytics_ticket_jobs_running_histories_many(updates: [sf_support_analytics_ticket_jobs_running_histories_updates!]!): [sf_support_analytics_ticket_jobs_running_histories_mutation_response]
  update_sf_support_root_causes(_inc: sf_support_root_causes_inc_input, _set: sf_support_root_causes_set_input, where: sf_support_root_causes_bool_exp!): sf_support_root_causes_mutation_response
  update_sf_support_root_causes_by_pk(_inc: sf_support_root_causes_inc_input, _set: sf_support_root_causes_set_input, pk_columns: sf_support_root_causes_pk_columns_input!): sf_support_root_causes
  update_sf_support_root_causes_many(updates: [sf_support_root_causes_updates!]!): [sf_support_root_causes_mutation_response]
  update_sf_support_ticket_classes(_inc: sf_support_ticket_classes_inc_input, _set: sf_support_ticket_classes_set_input, where: sf_support_ticket_classes_bool_exp!): sf_support_ticket_classes_mutation_response
  update_sf_support_ticket_classes_by_pk(_inc: sf_support_ticket_classes_inc_input, _set: sf_support_ticket_classes_set_input, pk_columns: sf_support_ticket_classes_pk_columns_input!): sf_support_ticket_classes
  update_sf_support_ticket_classes_many(updates: [sf_support_ticket_classes_updates!]!): [sf_support_ticket_classes_mutation_response]
  update_sf_support_ticket_status(_inc: sf_support_ticket_status_inc_input, _set: sf_support_ticket_status_set_input, where: sf_support_ticket_status_bool_exp!): sf_support_ticket_status_mutation_response
  update_sf_support_ticket_status_by_pk(_inc: sf_support_ticket_status_inc_input, _set: sf_support_ticket_status_set_input, pk_columns: sf_support_ticket_status_pk_columns_input!): sf_support_ticket_status
  update_sf_support_ticket_status_many(updates: [sf_support_ticket_status_updates!]!): [sf_support_ticket_status_mutation_response]
  update_sf_support_tickets(_inc: sf_support_tickets_inc_input, _set: sf_support_tickets_set_input, where: sf_support_tickets_bool_exp!): sf_support_tickets_mutation_response
  update_sf_support_tickets_by_pk(_inc: sf_support_tickets_inc_input, _set: sf_support_tickets_set_input, pk_columns: sf_support_tickets_pk_columns_input!): sf_support_tickets
  update_sf_support_tickets_many(updates: [sf_support_tickets_updates!]!): [sf_support_tickets_mutation_response]
  update_sf_testjig_test_jig_maintenance_report_many(updates: [sf_testjig_test_jig_maintenance_report_updates!]!): [sf_testjig_test_jig_maintenance_report_mutation_response]
  update_sf_testjig_test_jig_many(updates: [sf_testjig_test_jig_updates!]!): [sf_testjig_test_jig_mutation_response]
  update_sf_testjig_test_jig_report_metadata(_inc: sf_testjig_test_jig_report_metadata_inc_input, _set: sf_testjig_test_jig_report_metadata_set_input, where: sf_testjig_test_jig_report_metadata_bool_exp!): sf_testjig_test_jig_report_metadata_mutation_response
  update_sf_testjig_test_jig_report_metadata_by_pk(_inc: sf_testjig_test_jig_report_metadata_inc_input, _set: sf_testjig_test_jig_report_metadata_set_input, pk_columns: sf_testjig_test_jig_report_metadata_pk_columns_input!): sf_testjig_test_jig_report_metadata
  update_sf_testjig_test_jig_report_metadata_many(updates: [sf_testjig_test_jig_report_metadata_updates!]!): [sf_testjig_test_jig_report_metadata_mutation_response]
  update_sf_testjig_test_jig_report_metadatas(_inc: sf_testjig_test_jig_report_metadatas_inc_input, _set: sf_testjig_test_jig_report_metadatas_set_input, where: sf_testjig_test_jig_report_metadatas_bool_exp!): sf_testjig_test_jig_report_metadatas_mutation_response
  update_sf_testjig_test_jig_report_metadatas_by_pk(_inc: sf_testjig_test_jig_report_metadatas_inc_input, _set: sf_testjig_test_jig_report_metadatas_set_input, pk_columns: sf_testjig_test_jig_report_metadatas_pk_columns_input!): sf_testjig_test_jig_report_metadatas
  update_sf_testjig_test_jig_report_metadatas_many(updates: [sf_testjig_test_jig_report_metadatas_updates!]!): [sf_testjig_test_jig_report_metadatas_mutation_response]
  update_sf_testjig_test_jig_test_report_many(updates: [sf_testjig_test_jig_test_report_updates!]!): [sf_testjig_test_jig_test_report_mutation_response]
  update_sf_testjig_test_jig_type_many(updates: [sf_testjig_test_jig_type_updates!]!): [sf_testjig_test_jig_type_mutation_response]
  update_testRuns_by_pk(_inc: testRuns_inc_input, _set: testRuns_set_input, pk_columns: testRuns_pk_columns_input!): testRuns
  update_testRuns_many(updates: [testRuns_updates!]!): [testRuns_mutation_response]
  userCreate(email: String!, user: UserInput!): User!
  userDisable(userId: String!): GenericResponse!
  userResendWelcomeEmail(userId: String!): GenericResponse!
  userResetPassword(email: String!): GenericResponse!
  userUpdate(user: UserInput!, userId: String!): User!
}

type Node {
  isClimateSensor: Boolean!
  nodeMacId: String!
  subType: String
  type: NodeType!
}

type NodeHealthOverallStats {
  nodeHealthStats: NodeHealthStats!
  nodeType: NodeType!
}

type NodeHealthStats {
  bootedCount: Int!
  offlineCount: Int!
  onlineCount: Int!
  totalCount: Int!
}

type NodeLifeCycleEvent {
  comment: String
  creationDate: String!
  eventType: Int!
  id: Int!
  nodeMacId: String!
}

type NodeMappedPreviously {
  desiredEmonSwitchPosition: Int
  nodeMappedPreviously: String!
  nodeType: String!
  phaseStreamIndex: Int
  slotName: String!
}

type NodeMappingResponse {
  currentKey: Key
  currentRapidMappingKey: Key
  keysStats: MappingStats!
  locationId: ID!
  locationName: String!
  mappedOrUnmappedKeyId: String
  mappedOrUnmappedKeyName: String
  nextRapidMappingKey: Key
  skippedSlot: Slot
}

type NodeSpareSetReponse {
  consumedAt: String
  locationId: ID
  mappedAt: String
  nodeMacId: String
}

enum NodeSubType {
  aircon_2pfc
  aircon_2pfc_aicool
  aircon_2pfc_plc
  aircon_4pfc
  aircon_acir
  aircon_daikin
  door_laser
  door_magnetic
  energy_switchless
  flow_meter
  occupancy
  occupancy_ceiling
  occupancy_wall
  pipe_temp
}

enum NodeType {
  aircon
  door
  energy
  flow_meter
  occupancy
  pipe_temp
  undefined
}

enum NodeTypeCodeIdentifier {
  NODE_TYPE_CODE_00
  NODE_TYPE_CODE_01
  NODE_TYPE_CODE_02
  NODE_TYPE_CODE_03
  NODE_TYPE_CODE_04
  NODE_TYPE_CODE_05
  NODE_TYPE_CODE_06
  NODE_TYPE_CODE_07
  NODE_TYPE_CODE_08
  NODE_TYPE_CODE_23
}

type NodeTypeStats {
  mappingStats: MappingStats
  nodeType: NodeType!
}

scalar numeric

input numeric_comparison_exp {
  _eq: numeric
  _gt: numeric
  _gte: numeric
  _in: [numeric!]
  _is_null: Boolean
  _lt: numeric
  _lte: numeric
  _neq: numeric
  _nin: [numeric!]
}

enum order_by {
  asc
  asc_nulls_first
  asc_nulls_last
  desc
  desc_nulls_first
  desc_nulls_last
}

type Organisation {
  id: ID!
  name: String!
  status: GenericStatus!
}

input OrganisationFilterInput {
  name: String
}

input OrganisationSortInput {
  name: SortOptions
}

type OrganisationsResponse {
  organisations: [Organisation!]!
  pagination: GenericPagination!
}

type Permission {
  displayName: String!
  id: ID!
  permissionKey: String!
}

type Position {
  locationId: String!
  positionId: ID!
  positionName: String!
}

type PositionConfiguration {
  acModelId: ID
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: String
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: Int
  hysteresisOnDegrees: Int
  id: ID!
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: ID!
  recordType: String!
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

input PositionConfigurationInput {
  acModelId: ID
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  enableDoorSensor: Boolean
  expiredAt: String
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: Int
  hysteresisOnDegrees: Int
  id: ID
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nightTimeSetpointDOW: Int
  nightTimeSetpointDelay: Int
  nightTimeSetpointEnabled: Boolean
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: ID!
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

type positionConfigurations {
  acModel: sensorflow_ac_models
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz!
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  dotEnabled: Boolean!
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid!
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUser: sensorflow_users
  modifiedByUserId: String
  nightTimeSetpointDOW: smallint!
  nightTimeSetpointDelay: Int!
  nightTimeSetpointEnabled: Boolean!
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int!
  nightTimeSetpointOffset: Int!
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String!
  position: sensorflow_positions!
  positionId: uuid!
  recordType: String!
  softstartWaitTime: Int
  specialInstalltionMode: Boolean
  unoccupiedTemp: Int
  updated_at: timestamptz
}

type positionConfigurations_aggregate {
  aggregate: positionConfigurations_aggregate_fields
  nodes: [positionConfigurations!]!
}

input positionConfigurations_aggregate_bool_exp {
  avg: positionConfigurations_aggregate_bool_exp_avg
  bool_and: positionConfigurations_aggregate_bool_exp_bool_and
  bool_or: positionConfigurations_aggregate_bool_exp_bool_or
  corr: positionConfigurations_aggregate_bool_exp_corr
  count: positionConfigurations_aggregate_bool_exp_count
  covar_samp: positionConfigurations_aggregate_bool_exp_covar_samp
  max: positionConfigurations_aggregate_bool_exp_max
  min: positionConfigurations_aggregate_bool_exp_min
  stddev_samp: positionConfigurations_aggregate_bool_exp_stddev_samp
  sum: positionConfigurations_aggregate_bool_exp_sum
  var_samp: positionConfigurations_aggregate_bool_exp_var_samp
}

input positionConfigurations_aggregate_bool_exp_avg {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_avg_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_bool_and {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: Boolean_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_bool_or {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: Boolean_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_corr {
  arguments: positionConfigurations_aggregate_bool_exp_corr_arguments!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_corr_arguments {
  X: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_corr_arguments_columns!
  Y: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_corr_arguments_columns!
}

input positionConfigurations_aggregate_bool_exp_count {
  arguments: [positionConfigurations_select_column!]
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: Int_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_covar_samp {
  arguments: positionConfigurations_aggregate_bool_exp_covar_samp_arguments!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_covar_samp_arguments {
  X: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_covar_samp_arguments_columns!
  Y: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_covar_samp_arguments_columns!
}

input positionConfigurations_aggregate_bool_exp_max {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_max_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_min {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_min_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_stddev_samp {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_stddev_samp_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_sum {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_sum_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurations_aggregate_bool_exp_var_samp {
  arguments: positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_var_samp_arguments_columns!
  distinct: Boolean
  filter: positionConfigurations_bool_exp
  predicate: float8_comparison_exp!
}

type positionConfigurations_aggregate_fields {
  avg: positionConfigurations_avg_fields
  count(columns: [positionConfigurations_select_column!], distinct: Boolean): Int!
  max: positionConfigurations_max_fields
  min: positionConfigurations_min_fields
  stddev: positionConfigurations_stddev_fields
  stddev_pop: positionConfigurations_stddev_pop_fields
  stddev_samp: positionConfigurations_stddev_samp_fields
  sum: positionConfigurations_sum_fields
  var_pop: positionConfigurations_var_pop_fields
  var_samp: positionConfigurations_var_samp_fields
  variance: positionConfigurations_variance_fields
}

input positionConfigurations_aggregate_order_by {
  avg: positionConfigurations_avg_order_by
  count: order_by
  max: positionConfigurations_max_order_by
  min: positionConfigurations_min_order_by
  stddev: positionConfigurations_stddev_order_by
  stddev_pop: positionConfigurations_stddev_pop_order_by
  stddev_samp: positionConfigurations_stddev_samp_order_by
  sum: positionConfigurations_sum_order_by
  var_pop: positionConfigurations_var_pop_order_by
  var_samp: positionConfigurations_var_samp_order_by
  variance: positionConfigurations_variance_order_by
}

input positionConfigurations_arr_rel_insert_input {
  data: [positionConfigurations_insert_input!]!
  on_conflict: positionConfigurations_on_conflict
}

type positionConfigurations_avg_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_avg_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

input positionConfigurations_bool_exp {
  _and: [positionConfigurations_bool_exp!]
  _not: positionConfigurations_bool_exp
  _or: [positionConfigurations_bool_exp!]
  acModel: sensorflow_ac_models_bool_exp
  acModelId: uuid_comparison_exp
  acNightEnd: String_comparison_exp
  acNightStart: String_comparison_exp
  actingMode: String_comparison_exp
  actuationTimeoutSeconds: Int_comparison_exp
  automationMode: String_comparison_exp
  bridgeAdaptorSetting: Int_comparison_exp
  creationDate: timestamptz_comparison_exp
  desiredEmonSwitchPosition: Int_comparison_exp
  doorSensorTimeoutMins: Int_comparison_exp
  dotEnabled: Boolean_comparison_exp
  enableDoorSensor: Boolean_comparison_exp
  expiredAt: timestamptz_comparison_exp
  forceOccupancyAutomation: Boolean_comparison_exp
  freezeProtectionTemp: Int_comparison_exp
  heatingHideSetpoint: Boolean_comparison_exp
  heatingMaxTemp: Int_comparison_exp
  heatingMinTemp: Int_comparison_exp
  heatingUnoccupiedTemp: Int_comparison_exp
  hideSetpoint: Boolean_comparison_exp
  hysteresisOffDegrees: float8_comparison_exp
  hysteresisOnDegrees: float8_comparison_exp
  id: uuid_comparison_exp
  isDeviatedFromDefaultConfig: Boolean_comparison_exp
  maxTemp: Int_comparison_exp
  minTemp: Int_comparison_exp
  modifiedByUser: sensorflow_users_bool_exp
  modifiedByUserId: String_comparison_exp
  nightTimeSetpointDOW: smallint_comparison_exp
  nightTimeSetpointDelay: Int_comparison_exp
  nightTimeSetpointEnabled: Boolean_comparison_exp
  nightTimeSetpointEnd: String_comparison_exp
  nightTimeSetpointMaxSetpoint: Int_comparison_exp
  nightTimeSetpointOffset: Int_comparison_exp
  nightTimeSetpointStart: String_comparison_exp
  occNightEnd: String_comparison_exp
  occNightStart: String_comparison_exp
  occTimeoutDayMins: Int_comparison_exp
  occTimeoutNightMins: Int_comparison_exp
  occupancySensitivity: Int_comparison_exp
  operationalMode: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  recordType: String_comparison_exp
  softstartWaitTime: Int_comparison_exp
  specialInstalltionMode: Boolean_comparison_exp
  unoccupiedTemp: Int_comparison_exp
  updated_at: timestamptz_comparison_exp
}

enum positionConfigurations_constraint {
  position_configurations_pkey
}

input positionConfigurations_inc_input {
  actuationTimeoutSeconds: Int
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  maxTemp: Int
  minTemp: Int
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

input positionConfigurations_insert_input {
  acModel: sensorflow_ac_models_obj_rel_insert_input
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  dotEnabled: Boolean
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUser: sensorflow_users_obj_rel_insert_input
  modifiedByUserId: String
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointEnabled: Boolean
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstalltionMode: Boolean
  unoccupiedTemp: Int
  updated_at: timestamptz
}

type positionConfigurations_max_fields {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
  updated_at: timestamptz
}

input positionConfigurations_max_order_by {
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  expiredAt: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUserId: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointEnd: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  nightTimeSetpointStart: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
  updated_at: order_by
}

type positionConfigurations_min_fields {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
  updated_at: timestamptz
}

input positionConfigurations_min_order_by {
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  expiredAt: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUserId: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointEnd: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  nightTimeSetpointStart: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
  updated_at: order_by
}

type positionConfigurations_mutation_response {
  affected_rows: Int!
  returning: [positionConfigurations!]!
}

input positionConfigurations_on_conflict {
  constraint: positionConfigurations_constraint!
  update_columns: [positionConfigurations_update_column!]! = []
  where: positionConfigurations_bool_exp
}

input positionConfigurations_order_by {
  acModel: sensorflow_ac_models_order_by
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  dotEnabled: order_by
  enableDoorSensor: order_by
  expiredAt: order_by
  forceOccupancyAutomation: order_by
  freezeProtectionTemp: order_by
  heatingHideSetpoint: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hideSetpoint: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  isDeviatedFromDefaultConfig: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUser: sensorflow_users_order_by
  modifiedByUserId: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointEnabled: order_by
  nightTimeSetpointEnd: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  nightTimeSetpointStart: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  specialInstalltionMode: order_by
  unoccupiedTemp: order_by
  updated_at: order_by
}

input positionConfigurations_pk_columns_input {
  creationDate: timestamptz!
  id: uuid!
}

enum positionConfigurations_select_column {
  acModelId
  acNightEnd
  acNightStart
  actingMode
  actuationTimeoutSeconds
  automationMode
  bridgeAdaptorSetting
  creationDate
  desiredEmonSwitchPosition
  doorSensorTimeoutMins
  dotEnabled
  enableDoorSensor
  expiredAt
  forceOccupancyAutomation
  freezeProtectionTemp
  heatingHideSetpoint
  heatingMaxTemp
  heatingMinTemp
  heatingUnoccupiedTemp
  hideSetpoint
  hysteresisOffDegrees
  hysteresisOnDegrees
  id
  isDeviatedFromDefaultConfig
  maxTemp
  minTemp
  modifiedByUserId
  nightTimeSetpointDOW
  nightTimeSetpointDelay
  nightTimeSetpointEnabled
  nightTimeSetpointEnd
  nightTimeSetpointMaxSetpoint
  nightTimeSetpointOffset
  nightTimeSetpointStart
  occNightEnd
  occNightStart
  occTimeoutDayMins
  occTimeoutNightMins
  occupancySensitivity
  operationalMode
  positionId
  recordType
  softstartWaitTime
  specialInstalltionMode
  unoccupiedTemp
  updated_at
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_avg_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_bool_and_arguments_columns {
  dotEnabled
  enableDoorSensor
  forceOccupancyAutomation
  heatingHideSetpoint
  hideSetpoint
  isDeviatedFromDefaultConfig
  nightTimeSetpointEnabled
  specialInstalltionMode
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_bool_or_arguments_columns {
  dotEnabled
  enableDoorSensor
  forceOccupancyAutomation
  heatingHideSetpoint
  hideSetpoint
  isDeviatedFromDefaultConfig
  nightTimeSetpointEnabled
  specialInstalltionMode
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_corr_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_covar_samp_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_max_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_min_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_stddev_samp_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_sum_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurations_select_column_positionConfigurations_aggregate_bool_exp_var_samp_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

input positionConfigurations_set_input {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  dotEnabled: Boolean
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointEnabled: Boolean
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstalltionMode: Boolean
  unoccupiedTemp: Int
  updated_at: timestamptz
}

type positionConfigurations_stddev_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_stddev_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurations_stddev_pop_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_stddev_pop_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurations_stddev_samp_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_stddev_samp_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

input positionConfigurations_stream_cursor_input {
  initial_value: positionConfigurations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input positionConfigurations_stream_cursor_value_input {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  dotEnabled: Boolean
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointEnabled: Boolean
  nightTimeSetpointEnd: String
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  nightTimeSetpointStart: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstalltionMode: Boolean
  unoccupiedTemp: Int
  updated_at: timestamptz
}

type positionConfigurations_sum_fields {
  actuationTimeoutSeconds: Int
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  maxTemp: Int
  minTemp: Int
  nightTimeSetpointDOW: smallint
  nightTimeSetpointDelay: Int
  nightTimeSetpointMaxSetpoint: Int
  nightTimeSetpointOffset: Int
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

input positionConfigurations_sum_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

enum positionConfigurations_update_column {
  acModelId
  acNightEnd
  acNightStart
  actingMode
  actuationTimeoutSeconds
  automationMode
  bridgeAdaptorSetting
  creationDate
  desiredEmonSwitchPosition
  doorSensorTimeoutMins
  dotEnabled
  enableDoorSensor
  expiredAt
  forceOccupancyAutomation
  freezeProtectionTemp
  heatingHideSetpoint
  heatingMaxTemp
  heatingMinTemp
  heatingUnoccupiedTemp
  hideSetpoint
  hysteresisOffDegrees
  hysteresisOnDegrees
  id
  isDeviatedFromDefaultConfig
  maxTemp
  minTemp
  modifiedByUserId
  nightTimeSetpointDOW
  nightTimeSetpointDelay
  nightTimeSetpointEnabled
  nightTimeSetpointEnd
  nightTimeSetpointMaxSetpoint
  nightTimeSetpointOffset
  nightTimeSetpointStart
  occNightEnd
  occNightStart
  occTimeoutDayMins
  occTimeoutNightMins
  occupancySensitivity
  operationalMode
  positionId
  recordType
  softstartWaitTime
  specialInstalltionMode
  unoccupiedTemp
  updated_at
}

input positionConfigurations_updates {
  _inc: positionConfigurations_inc_input
  _set: positionConfigurations_set_input
  where: positionConfigurations_bool_exp!
}

type positionConfigurations_var_pop_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_var_pop_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurations_var_samp_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_var_samp_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurations_variance_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  nightTimeSetpointDOW: Float
  nightTimeSetpointDelay: Float
  nightTimeSetpointMaxSetpoint: Float
  nightTimeSetpointOffset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurations_variance_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  nightTimeSetpointDOW: order_by
  nightTimeSetpointDelay: order_by
  nightTimeSetpointMaxSetpoint: order_by
  nightTimeSetpointOffset: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurationsForActivityLog {
  acModel: sensorflow_ac_models
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  force_occupancy_automation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUser: sensorflow_users
  modifiedByUserId: String
  nextPosConfigCreationDate: timestamptz
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  position: sensorflow_positions
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
  updatedAt: timestamptz
}

type positionConfigurationsForActivityLog_aggregate {
  aggregate: positionConfigurationsForActivityLog_aggregate_fields
  nodes: [positionConfigurationsForActivityLog!]!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp {
  avg: positionConfigurationsForActivityLog_aggregate_bool_exp_avg
  bool_and: positionConfigurationsForActivityLog_aggregate_bool_exp_bool_and
  bool_or: positionConfigurationsForActivityLog_aggregate_bool_exp_bool_or
  corr: positionConfigurationsForActivityLog_aggregate_bool_exp_corr
  count: positionConfigurationsForActivityLog_aggregate_bool_exp_count
  covar_samp: positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp
  max: positionConfigurationsForActivityLog_aggregate_bool_exp_max
  min: positionConfigurationsForActivityLog_aggregate_bool_exp_min
  stddev_samp: positionConfigurationsForActivityLog_aggregate_bool_exp_stddev_samp
  sum: positionConfigurationsForActivityLog_aggregate_bool_exp_sum
  var_samp: positionConfigurationsForActivityLog_aggregate_bool_exp_var_samp
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_avg {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_avg_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_bool_and {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: Boolean_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_bool_or {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: Boolean_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_corr {
  arguments: positionConfigurationsForActivityLog_aggregate_bool_exp_corr_arguments!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_corr_arguments {
  X: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_corr_arguments_columns!
  Y: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_corr_arguments_columns!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_count {
  arguments: [positionConfigurationsForActivityLog_select_column!]
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: Int_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp {
  arguments: positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp_arguments!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp_arguments {
  X: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp_arguments_columns!
  Y: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp_arguments_columns!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_max {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_max_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_min {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_min_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_stddev_samp {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_stddev_samp_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_sum {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_sum_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

input positionConfigurationsForActivityLog_aggregate_bool_exp_var_samp {
  arguments: positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_var_samp_arguments_columns!
  distinct: Boolean
  filter: positionConfigurationsForActivityLog_bool_exp
  predicate: float8_comparison_exp!
}

type positionConfigurationsForActivityLog_aggregate_fields {
  avg: positionConfigurationsForActivityLog_avg_fields
  count(columns: [positionConfigurationsForActivityLog_select_column!], distinct: Boolean): Int!
  max: positionConfigurationsForActivityLog_max_fields
  min: positionConfigurationsForActivityLog_min_fields
  stddev: positionConfigurationsForActivityLog_stddev_fields
  stddev_pop: positionConfigurationsForActivityLog_stddev_pop_fields
  stddev_samp: positionConfigurationsForActivityLog_stddev_samp_fields
  sum: positionConfigurationsForActivityLog_sum_fields
  var_pop: positionConfigurationsForActivityLog_var_pop_fields
  var_samp: positionConfigurationsForActivityLog_var_samp_fields
  variance: positionConfigurationsForActivityLog_variance_fields
}

input positionConfigurationsForActivityLog_aggregate_order_by {
  avg: positionConfigurationsForActivityLog_avg_order_by
  count: order_by
  max: positionConfigurationsForActivityLog_max_order_by
  min: positionConfigurationsForActivityLog_min_order_by
  stddev: positionConfigurationsForActivityLog_stddev_order_by
  stddev_pop: positionConfigurationsForActivityLog_stddev_pop_order_by
  stddev_samp: positionConfigurationsForActivityLog_stddev_samp_order_by
  sum: positionConfigurationsForActivityLog_sum_order_by
  var_pop: positionConfigurationsForActivityLog_var_pop_order_by
  var_samp: positionConfigurationsForActivityLog_var_samp_order_by
  variance: positionConfigurationsForActivityLog_variance_order_by
}

input positionConfigurationsForActivityLog_arr_rel_insert_input {
  data: [positionConfigurationsForActivityLog_insert_input!]!
}

type positionConfigurationsForActivityLog_avg_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_avg_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

input positionConfigurationsForActivityLog_bool_exp {
  _and: [positionConfigurationsForActivityLog_bool_exp!]
  _not: positionConfigurationsForActivityLog_bool_exp
  _or: [positionConfigurationsForActivityLog_bool_exp!]
  acModel: sensorflow_ac_models_bool_exp
  acModelId: uuid_comparison_exp
  acNightEnd: String_comparison_exp
  acNightStart: String_comparison_exp
  actingMode: String_comparison_exp
  actuationTimeoutSeconds: Int_comparison_exp
  automationMode: String_comparison_exp
  bridgeAdaptorSetting: Int_comparison_exp
  creationDate: timestamptz_comparison_exp
  desiredEmonSwitchPosition: Int_comparison_exp
  doorSensorTimeoutMins: Int_comparison_exp
  enableDoorSensor: Boolean_comparison_exp
  expiredAt: timestamptz_comparison_exp
  force_occupancy_automation: Boolean_comparison_exp
  freezeProtectionTemp: Int_comparison_exp
  heatingHideSetpoint: Boolean_comparison_exp
  heatingMaxTemp: Int_comparison_exp
  heatingMinTemp: Int_comparison_exp
  heatingUnoccupiedTemp: Int_comparison_exp
  hideSetpoint: Boolean_comparison_exp
  hysteresisOffDegrees: float8_comparison_exp
  hysteresisOnDegrees: float8_comparison_exp
  id: uuid_comparison_exp
  isDeviatedFromDefaultConfig: Boolean_comparison_exp
  maxTemp: Int_comparison_exp
  minTemp: Int_comparison_exp
  modifiedByUser: sensorflow_users_bool_exp
  modifiedByUserId: String_comparison_exp
  nextPosConfigCreationDate: timestamptz_comparison_exp
  occNightEnd: String_comparison_exp
  occNightStart: String_comparison_exp
  occTimeoutDayMins: Int_comparison_exp
  occTimeoutNightMins: Int_comparison_exp
  occupancySensitivity: Int_comparison_exp
  operationalMode: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  recordType: String_comparison_exp
  softstartWaitTime: Int_comparison_exp
  specialInstallationMode: Boolean_comparison_exp
  unoccupiedTemp: Int_comparison_exp
  updatedAt: timestamptz_comparison_exp
}

input positionConfigurationsForActivityLog_insert_input {
  acModel: sensorflow_ac_models_obj_rel_insert_input
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  force_occupancy_automation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUser: sensorflow_users_obj_rel_insert_input
  modifiedByUserId: String
  nextPosConfigCreationDate: timestamptz
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
  updatedAt: timestamptz
}

type positionConfigurationsForActivityLog_max_fields {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nextPosConfigCreationDate: timestamptz
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
  updatedAt: timestamptz
}

input positionConfigurationsForActivityLog_max_order_by {
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  expiredAt: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUserId: order_by
  nextPosConfigCreationDate: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
  updatedAt: order_by
}

type positionConfigurationsForActivityLog_min_fields {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nextPosConfigCreationDate: timestamptz
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
  updatedAt: timestamptz
}

input positionConfigurationsForActivityLog_min_order_by {
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  expiredAt: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUserId: order_by
  nextPosConfigCreationDate: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
  updatedAt: order_by
}

input positionConfigurationsForActivityLog_order_by {
  acModel: sensorflow_ac_models_order_by
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  enableDoorSensor: order_by
  expiredAt: order_by
  force_occupancy_automation: order_by
  freezeProtectionTemp: order_by
  heatingHideSetpoint: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hideSetpoint: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  isDeviatedFromDefaultConfig: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUser: sensorflow_users_order_by
  modifiedByUserId: order_by
  nextPosConfigCreationDate: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  specialInstallationMode: order_by
  unoccupiedTemp: order_by
  updatedAt: order_by
}

enum positionConfigurationsForActivityLog_select_column {
  acModelId
  acNightEnd
  acNightStart
  actingMode
  actuationTimeoutSeconds
  automationMode
  bridgeAdaptorSetting
  creationDate
  desiredEmonSwitchPosition
  doorSensorTimeoutMins
  enableDoorSensor
  expiredAt
  force_occupancy_automation
  freezeProtectionTemp
  heatingHideSetpoint
  heatingMaxTemp
  heatingMinTemp
  heatingUnoccupiedTemp
  hideSetpoint
  hysteresisOffDegrees
  hysteresisOnDegrees
  id
  isDeviatedFromDefaultConfig
  maxTemp
  minTemp
  modifiedByUserId
  nextPosConfigCreationDate
  occNightEnd
  occNightStart
  occTimeoutDayMins
  occTimeoutNightMins
  occupancySensitivity
  operationalMode
  positionId
  recordType
  softstartWaitTime
  specialInstallationMode
  unoccupiedTemp
  updatedAt
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_avg_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_bool_and_arguments_columns {
  enableDoorSensor
  force_occupancy_automation
  heatingHideSetpoint
  hideSetpoint
  isDeviatedFromDefaultConfig
  specialInstallationMode
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_bool_or_arguments_columns {
  enableDoorSensor
  force_occupancy_automation
  heatingHideSetpoint
  hideSetpoint
  isDeviatedFromDefaultConfig
  specialInstallationMode
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_corr_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_covar_samp_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_max_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_min_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_stddev_samp_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_sum_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

enum positionConfigurationsForActivityLog_select_column_positionConfigurationsForActivityLog_aggregate_bool_exp_var_samp_arguments_columns {
  hysteresisOffDegrees
  hysteresisOnDegrees
}

type positionConfigurationsForActivityLog_stddev_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_stddev_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurationsForActivityLog_stddev_pop_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_stddev_pop_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurationsForActivityLog_stddev_samp_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_stddev_samp_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

input positionConfigurationsForActivityLog_stream_cursor_input {
  initial_value: positionConfigurationsForActivityLog_stream_cursor_value_input!
  ordering: cursor_ordering
}

input positionConfigurationsForActivityLog_stream_cursor_value_input {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  force_occupancy_automation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nextPosConfigCreationDate: timestamptz
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
  updatedAt: timestamptz
}

type positionConfigurationsForActivityLog_sum_fields {
  actuationTimeoutSeconds: Int
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  maxTemp: Int
  minTemp: Int
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

input positionConfigurationsForActivityLog_sum_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurationsForActivityLog_var_pop_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_var_pop_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurationsForActivityLog_var_samp_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_var_samp_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type positionConfigurationsForActivityLog_variance_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input positionConfigurationsForActivityLog_variance_order_by {
  actuationTimeoutSeconds: order_by
  bridgeAdaptorSetting: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  freezeProtectionTemp: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  maxTemp: order_by
  minTemp: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  softstartWaitTime: order_by
  unoccupiedTemp: order_by
}

type PositionConfigurationWithThermostatStatus {
  parentPositionId: ID!
  positionId: ID!
  positionName: String!
  thermostatOfflineStatus: Boolean
}

input PositionInput {
  locationId: ID!
  parentPositionId: ID!
  positionName: String!
}

type PrecoolCommand {
  createdBy: String
  createdDate: String!
  failMessage: String
  id: ID!
  mode: Int
  positionId: ID!
  source: String!
  startedAt: String
  status: String!
  temperature: Int
  totalMinutes: Int!
  updatedAt: String
}

type query_root {
  ACModel(ACModelId: String): ACModel!
  ACModels(filter: ACModelFilterInput, pagination: GenericPaginationInput, sort: ACModelSortInput): ACModelsResponse
  TestJig(testJigId: String!): sf_testjig_test_jig
  TestJigMaintenanceReport(id: uuid!): sf_testjig_test_jig_maintenance_report
  TestJigMaintenanceReports(distinct_on: [sf_testjig_test_jig_maintenance_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_maintenance_report_order_by!], where: sf_testjig_test_jig_maintenance_report_bool_exp): [sf_testjig_test_jig_maintenance_report!]!
  TestJigReportMetadata(distinct_on: [sf_testjig_test_jig_report_metadata_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadata_order_by!], where: sf_testjig_test_jig_report_metadata_bool_exp): [sf_testjig_test_jig_report_metadata!]!
  TestJigReportMetadatas(distinct_on: [sf_testjig_test_jig_report_metadatas_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadatas_order_by!], where: sf_testjig_test_jig_report_metadatas_bool_exp): [sf_testjig_test_jig_report_metadatas!]!
  TestJigTestReport(id: uuid!): sf_testjig_test_jig_test_report
  TestJigTestReports(distinct_on: [sf_testjig_test_jig_test_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_test_report_order_by!], where: sf_testjig_test_jig_test_report_bool_exp): [sf_testjig_test_jig_test_report!]!
  TestJigType(id: Int!): sf_testjig_test_jig_type
  TestJigTypes(distinct_on: [sf_testjig_test_jig_type_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_type_order_by!], where: sf_testjig_test_jig_type_bool_exp): [sf_testjig_test_jig_type!]!
  TestJigs(distinct_on: [sf_testjig_test_jig_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_order_by!], where: sf_testjig_test_jig_bool_exp): [sf_testjig_test_jig!]!
  commonRoomNames(keyIds: [ID!]!): [String!]!
  currentPositionConfiguration(distinct_on: [sensorflow_v_current_position_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_current_position_configurations_order_by!], where: sensorflow_v_current_position_configurations_bool_exp): [sensorflow_v_current_position_configurations!]!
  domoEmbed(embed_id: String!): sensorflow_domo_embeds
  domoEmbeds(distinct_on: [sensorflow_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_domo_embeds_order_by!], where: sensorflow_domo_embeds_bool_exp): [sensorflow_domo_embeds!]!
  embeddedAnalyticsInfo(embedId: ID!, input: EmbeddedAnalyticsInfoInput!): EmbeddedAnalyticsInfo!
  emonMapInputInfo(infraId: ID!, nodeMacId: String!): EmonMapInputInfo!
  firmwareReleases(distinct_on: [sensorflow_firmware_releases_select_column!], limit: Int, offset: Int, order_by: [sensorflow_firmware_releases_order_by!], where: sensorflow_firmware_releases_bool_exp): [sensorflow_firmware_releases!]!
  firmwareUploadLink(fileName: String!): String!
  gatewayImageDownloadUrl(gatewayId: String!): String!
  gatewayKittingProcessMetadata(distinct_on: [sensorflow_warehouse_kitting_process_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_kitting_process_metadata_order_by!], where: sensorflow_warehouse_kitting_process_metadata_bool_exp): [sensorflow_warehouse_kitting_process_metadata!]!
  gatewayToPositionMappings(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): [sensorflow_gateway_to_position_mapping!]!
  gateways(distinct_on: [sensorflow_gateways_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateways_order_by!], where: sensorflow_gateways_bool_exp): [sensorflow_gateways!]!
  generateRMAAssetUploadLink(extension: String!, type: String!): GenerateRMAAssetUploadLinkResponse!
  getNodeMappedPreviously(infraId: ID!): NodeMappedPreviously
  getRMAAssetDownloadLink(jobId: String!, type: String!): GetRMAAssetDownloadLinkResponse!
  getSmartRemValidationHistory(distinct_on: [sensorflow_smart_rem_validation_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_smart_rem_validation_history_order_by!], where: sensorflow_smart_rem_validation_history_bool_exp): [sensorflow_smart_rem_validation_history!]!
  infrastructureEmonPowerStatus(distinct_on: [sensorflow_v_infrastructure_emon_power_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructure_emon_power_status_order_by!], where: sensorflow_v_infrastructure_emon_power_status_bool_exp): [sensorflow_v_infrastructure_emon_power_status!]!
  infrastructures(distinct_on: [sensorflow_v_infrastructures_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructures_order_by!], where: sensorflow_v_infrastructures_bool_exp): [sensorflow_v_infrastructures!]!
  key(filter: KeyDetailsFilterInput, keyId: String!): Key!
  keyCategories(distinct_on: [sensorflow_key_categories_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_categories_order_by!], where: sensorflow_key_categories_bool_exp): [sensorflow_key_categories!]!
  keyEntries(distinct_on: [keyEntries_select_column!], limit: Int, offset: Int, order_by: [keyEntries_order_by!], where: keyEntries_bool_exp): [keyEntries!]!
  keyEntries_aggregate(distinct_on: [keyEntries_select_column!], limit: Int, offset: Int, order_by: [keyEntries_order_by!], where: keyEntries_bool_exp): keyEntries_aggregate!
  keyEntries_by_pk(keyEntryId: Int!): keyEntries
  keys(filter: KeyFilterInput!, pagination: GenericPaginationInput, sort: KeySortInput): KeysResponse!
  keysInLocation(locationId: String!, pagination: GenericPaginationInput, sort: KeySortInput): Location!
  keysTasks(filter: KeyTaskFilterInput!, pagination: GenericPaginationInput): KeysResponse!
  location(locationId: String!): Location
  locationDomoEmbed(embed_id: String!, location_id: uuid!): sensorflow_location_domo_embeds
  locationDomoEmbeds(distinct_on: [sensorflow_location_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_domo_embeds_order_by!], where: sensorflow_location_domo_embeds_bool_exp): [sensorflow_location_domo_embeds!]!
  locationEmbeddedAnalyticsInfo(input: LocationEmbeddedAnalyticsInput!, locationId: ID!): EmbeddedAnalyticsInfo!
  locationList(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): [sensorflow_locations!]!
  locationMetadata(locationId: uuid!): sensorflow_location_metadata
  locationOne(locationId: uuid!): sensorflow_locations
  locations(filter: LocationFilterInput, pagination: GenericPaginationInput, sort: LocationSortInput): LocationsResponse!
  locationsAssignedToUser(filter: LocationFilterInput, pagination: GenericPaginationInput, sort: LocationSortInput): LocationsResponse!
  nodeException(distinct_on: [sensorflow_node_exception_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exception_order_by!], where: sensorflow_node_exception_bool_exp): [sensorflow_node_exception!]!
  nodeExceptionAggregate(distinct_on: [sensorflow_node_exception_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exception_order_by!], where: sensorflow_node_exception_bool_exp): sensorflow_node_exception_aggregate!
  nodeExceptionByPk(id: Int!): sensorflow_node_exception
  nodeExceptions(distinct_on: [sensorflow_node_exceptions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exceptions_order_by!], where: sensorflow_node_exceptions_bool_exp): [sensorflow_node_exceptions!]!
  nodeExceptionsAggregate(distinct_on: [sensorflow_node_exceptions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exceptions_order_by!], where: sensorflow_node_exceptions_bool_exp): sensorflow_node_exceptions_aggregate!
  nodeExceptionsByPk(id: Int!): sensorflow_node_exceptions
  nodeJoinStatuses(distinct_on: [sensorflow_node_join_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_status_order_by!], where: sensorflow_node_join_status_bool_exp): [sensorflow_node_join_status!]!
  nodeOnlineStatuses(distinct_on: [sensorflow_node_online_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_online_status_order_by!], where: sensorflow_node_online_status_bool_exp): [sensorflow_node_online_status!]!
  organisation(organisationId: String): Organisation!
  organisations(filter: OrganisationFilterInput, pagination: GenericPaginationInput, sort: OrganisationSortInput): OrganisationsResponse!
  position(positionId: uuid!): sensorflow_positions
  positionConfigurations(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): [positionConfigurations!]!
  positionConfigurationsForActivityLog(distinct_on: [positionConfigurationsForActivityLog_select_column!], limit: Int, offset: Int, order_by: [positionConfigurationsForActivityLog_order_by!], where: positionConfigurationsForActivityLog_bool_exp): [positionConfigurationsForActivityLog!]!
  positionConfigurationsForActivityLog_aggregate(distinct_on: [positionConfigurationsForActivityLog_select_column!], limit: Int, offset: Int, order_by: [positionConfigurationsForActivityLog_order_by!], where: positionConfigurationsForActivityLog_bool_exp): positionConfigurationsForActivityLog_aggregate!
  positionConfigurations_aggregate(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): positionConfigurations_aggregate!
  positionConfigurations_by_pk(creationDate: timestamptz!, id: uuid!): positionConfigurations
  positionPrecoolStatus(distinct_on: [sensorflow_position_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_precool_status_order_by!], where: sensorflow_position_precool_status_bool_exp): [sensorflow_position_precool_status!]!
  positions(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): [sensorflow_positions!]!
  positionsAggregate(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): sensorflow_positions_aggregate!
  rapidMapping(locationId: String!): NodeMappingResponse!
  roles: [Role!]!
  room(filter: RoomDetailsFilterInput, roomId: String!): Room!
  root: String
  sensorflow_ac_command_event_stream(distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_ac_command_event_stream_aggregate(distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): sensorflow_ac_command_event_stream_aggregate!
  sensorflow_ac_models(distinct_on: [sensorflow_ac_models_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_models_order_by!], where: sensorflow_ac_models_bool_exp): [sensorflow_ac_models!]!
  sensorflow_ac_models_aggregate(distinct_on: [sensorflow_ac_models_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_models_order_by!], where: sensorflow_ac_models_bool_exp): sensorflow_ac_models_aggregate!
  sensorflow_ac_models_by_pk(id: uuid!): sensorflow_ac_models
  sensorflow_ac_setting_commands(distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): [sensorflow_ac_setting_commands!]!
  sensorflow_ac_setting_commands_aggregate(distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): sensorflow_ac_setting_commands_aggregate!
  sensorflow_ac_setting_commands_by_pk(id: uuid!): sensorflow_ac_setting_commands
  sensorflow_ai_dt_ac_event_stream(distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_dt_ac_event_stream_aggregate(distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): sensorflow_ai_dt_ac_event_stream_aggregate!
  sensorflow_ai_dt_config_event_stream(distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): [sensorflow_ai_dt_config_event_stream!]!
  sensorflow_ai_dt_config_event_stream_aggregate(distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): sensorflow_ai_dt_config_event_stream_aggregate!
  sensorflow_ai_dt_event_stream(distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): [sensorflow_ai_dt_event_stream!]!
  sensorflow_ai_dt_event_stream_aggregate(distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): sensorflow_ai_dt_event_stream_aggregate!
  sensorflow_ai_dt_room_comfort_report(distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): [sensorflow_ai_dt_room_comfort_report!]!
  sensorflow_ai_dt_room_comfort_report_aggregate(distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): sensorflow_ai_dt_room_comfort_report_aggregate!
  sensorflow_ai_dt_synced_raw_data(distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): [sensorflow_ai_dt_synced_raw_data!]!
  sensorflow_ai_dt_synced_raw_data_aggregate(distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): sensorflow_ai_dt_synced_raw_data_aggregate!
  sensorflow_ai_f_get_ac_command_event_stream(args: sensorflow_ai_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_f_get_ac_command_event_stream_aggregate(args: sensorflow_ai_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): sensorflow_ai_dt_ac_event_stream_aggregate!
  sensorflow_ai_f_get_all_event_stream(args: sensorflow_ai_f_get_all_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_f_get_all_event_stream_aggregate(args: sensorflow_ai_f_get_all_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): sensorflow_ai_dt_ac_event_stream_aggregate!
  sensorflow_ai_f_get_config_event_stream(args: sensorflow_ai_f_get_config_event_stream_args!, distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): [sensorflow_ai_dt_config_event_stream!]!
  sensorflow_ai_f_get_config_event_stream_aggregate(args: sensorflow_ai_f_get_config_event_stream_args!, distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): sensorflow_ai_dt_config_event_stream_aggregate!
  sensorflow_ai_f_get_event_stream(args: sensorflow_ai_f_get_event_stream_args!, distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): [sensorflow_ai_dt_event_stream!]!
  sensorflow_ai_f_get_event_stream_aggregate(args: sensorflow_ai_f_get_event_stream_args!, distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): sensorflow_ai_dt_event_stream_aggregate!
  sensorflow_ai_f_get_id_info(args: sensorflow_ai_f_get_id_info_args!, distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): [sensorflow_autosets_with_keys!]!
  sensorflow_ai_f_get_id_info_aggregate(args: sensorflow_ai_f_get_id_info_args!, distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): sensorflow_autosets_with_keys_aggregate!
  sensorflow_ai_f_get_synced_raw_data(args: sensorflow_ai_f_get_synced_raw_data_args!, distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): [sensorflow_ai_dt_synced_raw_data!]!
  sensorflow_ai_f_get_synced_raw_data_aggregate(args: sensorflow_ai_f_get_synced_raw_data_args!, distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): sensorflow_ai_dt_synced_raw_data_aggregate!
  sensorflow_ai_f_room_comfort_analysis(args: sensorflow_ai_f_room_comfort_analysis_args!, distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): [sensorflow_ai_dt_room_comfort_report!]!
  sensorflow_ai_f_room_comfort_analysis_aggregate(args: sensorflow_ai_f_room_comfort_analysis_args!, distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): sensorflow_ai_dt_room_comfort_report_aggregate!
  sensorflow_ai_v_get_precool_command_stream(distinct_on: [sensorflow_ai_v_get_precool_command_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_get_precool_command_stream_order_by!], where: sensorflow_ai_v_get_precool_command_stream_bool_exp): [sensorflow_ai_v_get_precool_command_stream!]!
  sensorflow_ai_v_get_precool_command_stream_aggregate(distinct_on: [sensorflow_ai_v_get_precool_command_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_get_precool_command_stream_order_by!], where: sensorflow_ai_v_get_precool_command_stream_bool_exp): sensorflow_ai_v_get_precool_command_stream_aggregate!
  sensorflow_ai_v_room_status(distinct_on: [sensorflow_ai_v_room_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_room_status_order_by!], where: sensorflow_ai_v_room_status_bool_exp): [sensorflow_ai_v_room_status!]!
  sensorflow_ai_v_room_status_aggregate(distinct_on: [sensorflow_ai_v_room_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_room_status_order_by!], where: sensorflow_ai_v_room_status_bool_exp): sensorflow_ai_v_room_status_aggregate!
  sensorflow_autosets_with_keys(distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): [sensorflow_autosets_with_keys!]!
  sensorflow_autosets_with_keys_aggregate(distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): sensorflow_autosets_with_keys_aggregate!
  sensorflow_beacon_slots(distinct_on: [sensorflow_beacon_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_beacon_slots_order_by!], where: sensorflow_beacon_slots_bool_exp): [sensorflow_beacon_slots!]!
  sensorflow_beacon_slots_aggregate(distinct_on: [sensorflow_beacon_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_beacon_slots_order_by!], where: sensorflow_beacon_slots_bool_exp): sensorflow_beacon_slots_aggregate!
  sensorflow_beacon_slots_by_pk(id: uuid!): sensorflow_beacon_slots
  sensorflow_checklist_items(distinct_on: [sensorflow_checklist_items_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklist_items_order_by!], where: sensorflow_checklist_items_bool_exp): [sensorflow_checklist_items!]!
  sensorflow_checklist_items_aggregate(distinct_on: [sensorflow_checklist_items_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklist_items_order_by!], where: sensorflow_checklist_items_bool_exp): sensorflow_checklist_items_aggregate!
  sensorflow_checklists(distinct_on: [sensorflow_checklists_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklists_order_by!], where: sensorflow_checklists_bool_exp): [sensorflow_checklists!]!
  sensorflow_checklists_aggregate(distinct_on: [sensorflow_checklists_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklists_order_by!], where: sensorflow_checklists_bool_exp): sensorflow_checklists_aggregate!
  sensorflow_checklists_by_pk(id: String!): sensorflow_checklists
  sensorflow_chillers(distinct_on: [sensorflow_chillers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_chillers_order_by!], where: sensorflow_chillers_bool_exp): [sensorflow_chillers!]!
  sensorflow_chillers_aggregate(distinct_on: [sensorflow_chillers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_chillers_order_by!], where: sensorflow_chillers_bool_exp): sensorflow_chillers_aggregate!
  sensorflow_chillers_by_pk(id: uuid!): sensorflow_chillers
  sensorflow_clickup_webhook_events(distinct_on: [sensorflow_clickup_webhook_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_clickup_webhook_events_order_by!], where: sensorflow_clickup_webhook_events_bool_exp): [sensorflow_clickup_webhook_events!]!
  sensorflow_clickup_webhook_events_aggregate(distinct_on: [sensorflow_clickup_webhook_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_clickup_webhook_events_order_by!], where: sensorflow_clickup_webhook_events_bool_exp): sensorflow_clickup_webhook_events_aggregate!
  sensorflow_clickup_webhook_events_by_pk(id: uuid!): sensorflow_clickup_webhook_events
  sensorflow_comfortplus_activations(distinct_on: [sensorflow_comfortplus_activations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_comfortplus_activations_order_by!], where: sensorflow_comfortplus_activations_bool_exp): [sensorflow_comfortplus_activations!]!
  sensorflow_comfortplus_activations_aggregate(distinct_on: [sensorflow_comfortplus_activations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_comfortplus_activations_order_by!], where: sensorflow_comfortplus_activations_bool_exp): sensorflow_comfortplus_activations_aggregate!
  sensorflow_comfortplus_activations_by_pk(id: uuid!): sensorflow_comfortplus_activations
  sensorflow_compressor_mapping(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): [sensorflow_compressor_mapping!]!
  sensorflow_compressor_mapping_aggregate(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): sensorflow_compressor_mapping_aggregate!
  sensorflow_compressor_mapping_by_pk(compressorId: uuid!, positionId: uuid!): sensorflow_compressor_mapping
  sensorflow_compressors(distinct_on: [sensorflow_compressors_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressors_order_by!], where: sensorflow_compressors_bool_exp): [sensorflow_compressors!]!
  sensorflow_compressors_aggregate(distinct_on: [sensorflow_compressors_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressors_order_by!], where: sensorflow_compressors_bool_exp): sensorflow_compressors_aggregate!
  sensorflow_compressors_by_pk(compressorId: uuid!): sensorflow_compressors
  sensorflow_config_event_stream(distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): [sensorflow_config_event_stream!]!
  sensorflow_config_event_stream_aggregate(distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): sensorflow_config_event_stream_aggregate!
  sensorflow_cooling_towers(distinct_on: [sensorflow_cooling_towers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_cooling_towers_order_by!], where: sensorflow_cooling_towers_bool_exp): [sensorflow_cooling_towers!]!
  sensorflow_cooling_towers_aggregate(distinct_on: [sensorflow_cooling_towers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_cooling_towers_order_by!], where: sensorflow_cooling_towers_bool_exp): sensorflow_cooling_towers_aggregate!
  sensorflow_cooling_towers_by_pk(id: uuid!): sensorflow_cooling_towers
  sensorflow_currencies(distinct_on: [sensorflow_currencies_select_column!], limit: Int, offset: Int, order_by: [sensorflow_currencies_order_by!], where: sensorflow_currencies_bool_exp): [sensorflow_currencies!]!
  sensorflow_currencies_aggregate(distinct_on: [sensorflow_currencies_select_column!], limit: Int, offset: Int, order_by: [sensorflow_currencies_order_by!], where: sensorflow_currencies_bool_exp): sensorflow_currencies_aggregate!
  sensorflow_currencies_by_pk(id: Int!): sensorflow_currencies
  sensorflow_domo_embeds_aggregate(distinct_on: [sensorflow_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_domo_embeds_order_by!], where: sensorflow_domo_embeds_bool_exp): sensorflow_domo_embeds_aggregate!
  sensorflow_events_stream(distinct_on: [sensorflow_events_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_events_stream_order_by!], where: sensorflow_events_stream_bool_exp): [sensorflow_events_stream!]!
  sensorflow_events_stream_aggregate(distinct_on: [sensorflow_events_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_events_stream_order_by!], where: sensorflow_events_stream_bool_exp): sensorflow_events_stream_aggregate!
  sensorflow_f_get_ac_command_event_stream(args: sensorflow_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_f_get_ac_command_event_stream_aggregate(args: sensorflow_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): sensorflow_ac_command_event_stream_aggregate!
  sensorflow_f_get_all_event_stream(args: sensorflow_f_get_all_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_f_get_all_event_stream_aggregate(args: sensorflow_f_get_all_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): sensorflow_ac_command_event_stream_aggregate!
  sensorflow_f_get_config_event_stream(args: sensorflow_f_get_config_event_stream_args!, distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): [sensorflow_config_event_stream!]!
  sensorflow_f_get_config_event_stream_aggregate(args: sensorflow_f_get_config_event_stream_args!, distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): sensorflow_config_event_stream_aggregate!
  sensorflow_f_get_event_stream(args: sensorflow_f_get_event_stream_args!, distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): [sensorflow_get_event_stream_view!]!
  sensorflow_f_get_event_stream_aggregate(args: sensorflow_f_get_event_stream_args!, distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): sensorflow_get_event_stream_view_aggregate!
  sensorflow_f_get_synced_raw_data(args: sensorflow_f_get_synced_raw_data_args!, distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): [sensorflow_synced_raw_data!]!
  sensorflow_f_get_synced_raw_data_aggregate(args: sensorflow_f_get_synced_raw_data_args!, distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): sensorflow_synced_raw_data_aggregate!
  sensorflow_firmware_releases_aggregate(distinct_on: [sensorflow_firmware_releases_select_column!], limit: Int, offset: Int, order_by: [sensorflow_firmware_releases_order_by!], where: sensorflow_firmware_releases_bool_exp): sensorflow_firmware_releases_aggregate!
  sensorflow_firmware_releases_by_pk(id: Int!): sensorflow_firmware_releases
  sensorflow_gateway_frequency(distinct_on: [sensorflow_gateway_frequency_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_frequency_order_by!], where: sensorflow_gateway_frequency_bool_exp): [sensorflow_gateway_frequency!]!
  sensorflow_gateway_frequency_aggregate(distinct_on: [sensorflow_gateway_frequency_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_frequency_order_by!], where: sensorflow_gateway_frequency_bool_exp): sensorflow_gateway_frequency_aggregate!
  sensorflow_gateway_frequency_by_pk(balena_id: String!, reserved_frequency: bigint!): sensorflow_gateway_frequency
  sensorflow_gateway_health_data(distinct_on: [sensorflow_gateway_health_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_health_data_order_by!], where: sensorflow_gateway_health_data_bool_exp): [sensorflow_gateway_health_data!]!
  sensorflow_gateway_health_data_aggregate(distinct_on: [sensorflow_gateway_health_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_health_data_order_by!], where: sensorflow_gateway_health_data_bool_exp): sensorflow_gateway_health_data_aggregate!
  sensorflow_gateway_to_position_mapping_aggregate(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): sensorflow_gateway_to_position_mapping_aggregate!
  sensorflow_gateway_to_position_mapping_by_pk(positionId: uuid!): sensorflow_gateway_to_position_mapping
  sensorflow_gateways_aggregate(distinct_on: [sensorflow_gateways_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateways_order_by!], where: sensorflow_gateways_bool_exp): sensorflow_gateways_aggregate!
  sensorflow_gateways_by_pk(gatewayId: String!): sensorflow_gateways
  sensorflow_get_event_stream_view(distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): [sensorflow_get_event_stream_view!]!
  sensorflow_get_event_stream_view_aggregate(distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): sensorflow_get_event_stream_view_aggregate!
  sensorflow_get_precool_command_streams(distinct_on: [sensorflow_get_precool_command_streams_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_precool_command_streams_order_by!], where: sensorflow_get_precool_command_streams_bool_exp): [sensorflow_get_precool_command_streams!]!
  sensorflow_get_precool_command_streams_aggregate(distinct_on: [sensorflow_get_precool_command_streams_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_precool_command_streams_order_by!], where: sensorflow_get_precool_command_streams_bool_exp): sensorflow_get_precool_command_streams_aggregate!
  sensorflow_key_categories_aggregate(distinct_on: [sensorflow_key_categories_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_categories_order_by!], where: sensorflow_key_categories_bool_exp): sensorflow_key_categories_aggregate!
  sensorflow_key_categories_by_pk(id: uuid!): sensorflow_key_categories
  sensorflow_key_category_room_templates(distinct_on: [sensorflow_key_category_room_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_room_templates_order_by!], where: sensorflow_key_category_room_templates_bool_exp): [sensorflow_key_category_room_templates!]!
  sensorflow_key_category_room_templates_aggregate(distinct_on: [sensorflow_key_category_room_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_room_templates_order_by!], where: sensorflow_key_category_room_templates_bool_exp): sensorflow_key_category_room_templates_aggregate!
  sensorflow_key_category_room_templates_by_pk(id: uuid!): sensorflow_key_category_room_templates
  sensorflow_key_category_slot_templates(distinct_on: [sensorflow_key_category_slot_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_slot_templates_order_by!], where: sensorflow_key_category_slot_templates_bool_exp): [sensorflow_key_category_slot_templates!]!
  sensorflow_key_category_slot_templates_aggregate(distinct_on: [sensorflow_key_category_slot_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_slot_templates_order_by!], where: sensorflow_key_category_slot_templates_bool_exp): sensorflow_key_category_slot_templates_aggregate!
  sensorflow_key_category_slot_templates_by_pk(id: uuid!): sensorflow_key_category_slot_templates
  sensorflow_key_category_templates(distinct_on: [sensorflow_key_category_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_templates_order_by!], where: sensorflow_key_category_templates_bool_exp): [sensorflow_key_category_templates!]!
  sensorflow_key_category_templates_aggregate(distinct_on: [sensorflow_key_category_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_templates_order_by!], where: sensorflow_key_category_templates_bool_exp): sensorflow_key_category_templates_aggregate!
  sensorflow_key_category_templates_by_pk(id: uuid!): sensorflow_key_category_templates
  sensorflow_key_category_to_key_position(distinct_on: [sensorflow_key_category_to_key_position_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_to_key_position_order_by!], where: sensorflow_key_category_to_key_position_bool_exp): [sensorflow_key_category_to_key_position!]!
  sensorflow_key_category_to_key_position_aggregate(distinct_on: [sensorflow_key_category_to_key_position_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_to_key_position_order_by!], where: sensorflow_key_category_to_key_position_bool_exp): sensorflow_key_category_to_key_position_aggregate!
  sensorflow_key_category_to_key_position_by_pk(categoryName: String!, locationName: String!, positionId: uuid!): sensorflow_key_category_to_key_position
  sensorflow_location_domo_embeds_aggregate(distinct_on: [sensorflow_location_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_domo_embeds_order_by!], where: sensorflow_location_domo_embeds_bool_exp): sensorflow_location_domo_embeds_aggregate!
  sensorflow_location_metadata(distinct_on: [sensorflow_location_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_metadata_order_by!], where: sensorflow_location_metadata_bool_exp): [sensorflow_location_metadata!]!
  sensorflow_location_metadata_aggregate(distinct_on: [sensorflow_location_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_metadata_order_by!], where: sensorflow_location_metadata_bool_exp): sensorflow_location_metadata_aggregate!
  sensorflow_location_to_user_mapping(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  sensorflow_location_to_user_mapping_aggregate(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): sensorflow_location_to_user_mapping_aggregate!
  sensorflow_location_to_user_mapping_by_pk(id: uuid!): sensorflow_location_to_user_mapping
  sensorflow_locations_aggregate(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): sensorflow_locations_aggregate!
  sensorflow_mapping_coordinates(distinct_on: [sensorflow_mapping_coordinates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_mapping_coordinates_order_by!], where: sensorflow_mapping_coordinates_bool_exp): [sensorflow_mapping_coordinates!]!
  sensorflow_mapping_coordinates_aggregate(distinct_on: [sensorflow_mapping_coordinates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_mapping_coordinates_order_by!], where: sensorflow_mapping_coordinates_bool_exp): sensorflow_mapping_coordinates_aggregate!
  sensorflow_node_firmware(distinct_on: [sensorflow_node_firmware_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_firmware_order_by!], where: sensorflow_node_firmware_bool_exp): [sensorflow_node_firmware!]!
  sensorflow_node_firmware_aggregate(distinct_on: [sensorflow_node_firmware_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_firmware_order_by!], where: sensorflow_node_firmware_bool_exp): sensorflow_node_firmware_aggregate!
  sensorflow_node_join_data(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): [sensorflow_node_join_data!]!
  sensorflow_node_join_data_aggregate(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): sensorflow_node_join_data_aggregate!
  sensorflow_node_join_status_aggregate(distinct_on: [sensorflow_node_join_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_status_order_by!], where: sensorflow_node_join_status_bool_exp): sensorflow_node_join_status_aggregate!
  sensorflow_node_join_strength_live(distinct_on: [sensorflow_node_join_strength_live_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_strength_live_order_by!], where: sensorflow_node_join_strength_live_bool_exp): [sensorflow_node_join_strength_live!]!
  sensorflow_node_join_strength_live_aggregate(distinct_on: [sensorflow_node_join_strength_live_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_strength_live_order_by!], where: sensorflow_node_join_strength_live_bool_exp): sensorflow_node_join_strength_live_aggregate!
  sensorflow_node_life_cycle_event_types(distinct_on: [sensorflow_node_life_cycle_event_types_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_event_types_order_by!], where: sensorflow_node_life_cycle_event_types_bool_exp): [sensorflow_node_life_cycle_event_types!]!
  sensorflow_node_life_cycle_event_types_aggregate(distinct_on: [sensorflow_node_life_cycle_event_types_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_event_types_order_by!], where: sensorflow_node_life_cycle_event_types_bool_exp): sensorflow_node_life_cycle_event_types_aggregate!
  sensorflow_node_life_cycle_event_types_by_pk(id: Int!): sensorflow_node_life_cycle_event_types
  sensorflow_node_life_cycle_events(distinct_on: [sensorflow_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_events_order_by!], where: sensorflow_node_life_cycle_events_bool_exp): [sensorflow_node_life_cycle_events!]!
  sensorflow_node_life_cycle_events_aggregate(distinct_on: [sensorflow_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_events_order_by!], where: sensorflow_node_life_cycle_events_bool_exp): sensorflow_node_life_cycle_events_aggregate!
  sensorflow_node_life_cycle_events_by_pk(id: Int!): sensorflow_node_life_cycle_events
  sensorflow_node_measurement_view(distinct_on: [sensorflow_node_measurement_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurement_view_order_by!], where: sensorflow_node_measurement_view_bool_exp): [sensorflow_node_measurement_view!]!
  sensorflow_node_measurement_view_aggregate(distinct_on: [sensorflow_node_measurement_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurement_view_order_by!], where: sensorflow_node_measurement_view_bool_exp): sensorflow_node_measurement_view_aggregate!
  sensorflow_node_measurements(distinct_on: [sensorflow_node_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurements_order_by!], where: sensorflow_node_measurements_bool_exp): [sensorflow_node_measurements!]!
  sensorflow_node_measurements_aggregate(distinct_on: [sensorflow_node_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurements_order_by!], where: sensorflow_node_measurements_bool_exp): sensorflow_node_measurements_aggregate!
  sensorflow_node_meta_data(distinct_on: [sensorflow_node_meta_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_data_order_by!], where: sensorflow_node_meta_data_bool_exp): [sensorflow_node_meta_data!]!
  sensorflow_node_meta_data_aggregate(distinct_on: [sensorflow_node_meta_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_data_order_by!], where: sensorflow_node_meta_data_bool_exp): sensorflow_node_meta_data_aggregate!
  sensorflow_node_meta_data_by_pk(measurementType: String!, nodeMacId: String!, time: timestamptz!): sensorflow_node_meta_data
  sensorflow_node_meta_status(distinct_on: [sensorflow_node_meta_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_status_order_by!], where: sensorflow_node_meta_status_bool_exp): [sensorflow_node_meta_status!]!
  sensorflow_node_meta_status_aggregate(distinct_on: [sensorflow_node_meta_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_status_order_by!], where: sensorflow_node_meta_status_bool_exp): sensorflow_node_meta_status_aggregate!
  sensorflow_node_online_status_aggregate(distinct_on: [sensorflow_node_online_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_online_status_order_by!], where: sensorflow_node_online_status_bool_exp): sensorflow_node_online_status_aggregate!
  sensorflow_node_slots(distinct_on: [sensorflow_node_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_slots_order_by!], where: sensorflow_node_slots_bool_exp): [sensorflow_node_slots!]!
  sensorflow_node_slots_aggregate(distinct_on: [sensorflow_node_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_slots_order_by!], where: sensorflow_node_slots_bool_exp): sensorflow_node_slots_aggregate!
  sensorflow_node_slots_by_pk(id: uuid!): sensorflow_node_slots
  sensorflow_node_spare_set(distinct_on: [sensorflow_node_spare_set_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_spare_set_order_by!], where: sensorflow_node_spare_set_bool_exp): [sensorflow_node_spare_set!]!
  sensorflow_node_spare_set_aggregate(distinct_on: [sensorflow_node_spare_set_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_spare_set_order_by!], where: sensorflow_node_spare_set_bool_exp): sensorflow_node_spare_set_aggregate!
  sensorflow_node_spare_set_by_pk(locationId: uuid!, nodeMacId: String!): sensorflow_node_spare_set
  sensorflow_node_status_history(distinct_on: [sensorflow_node_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_status_history_order_by!], where: sensorflow_node_status_history_bool_exp): [sensorflow_node_status_history!]!
  sensorflow_node_status_history_aggregate(distinct_on: [sensorflow_node_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_status_history_order_by!], where: sensorflow_node_status_history_bool_exp): sensorflow_node_status_history_aggregate!
  sensorflow_node_status_history_by_pk(id: Int!): sensorflow_node_status_history
  sensorflow_node_statuses(distinct_on: [sensorflow_node_statuses_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_statuses_order_by!], where: sensorflow_node_statuses_bool_exp): [sensorflow_node_statuses!]!
  sensorflow_node_statuses_aggregate(distinct_on: [sensorflow_node_statuses_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_statuses_order_by!], where: sensorflow_node_statuses_bool_exp): sensorflow_node_statuses_aggregate!
  sensorflow_node_statuses_by_pk(id: Int!): sensorflow_node_statuses
  sensorflow_node_system_states(distinct_on: [sensorflow_node_system_states_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_system_states_order_by!], where: sensorflow_node_system_states_bool_exp): [sensorflow_node_system_states!]!
  sensorflow_node_system_states_aggregate(distinct_on: [sensorflow_node_system_states_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_system_states_order_by!], where: sensorflow_node_system_states_bool_exp): sensorflow_node_system_states_aggregate!
  sensorflow_node_system_states_by_pk(nodeMacId: String!, time: timestamptz!): sensorflow_node_system_states
  sensorflow_node_to_position_mapping(distinct_on: [sensorflow_node_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_position_mapping_order_by!], where: sensorflow_node_to_position_mapping_bool_exp): [sensorflow_node_to_position_mapping!]!
  sensorflow_node_to_position_mapping_aggregate(distinct_on: [sensorflow_node_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_position_mapping_order_by!], where: sensorflow_node_to_position_mapping_bool_exp): sensorflow_node_to_position_mapping_aggregate!
  sensorflow_node_to_slot_mapping(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): [sensorflow_node_to_slot_mapping!]!
  sensorflow_node_to_slot_mapping_aggregate(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): sensorflow_node_to_slot_mapping_aggregate!
  sensorflow_node_to_slot_mapping_by_pk(id: Int!): sensorflow_node_to_slot_mapping
  sensorflow_nodes(distinct_on: [sensorflow_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_nodes_order_by!], where: sensorflow_nodes_bool_exp): [sensorflow_nodes!]!
  sensorflow_nodes_aggregate(distinct_on: [sensorflow_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_nodes_order_by!], where: sensorflow_nodes_bool_exp): sensorflow_nodes_aggregate!
  sensorflow_nodes_by_pk(node_mac_id: String!): sensorflow_nodes
  sensorflow_organisations(distinct_on: [sensorflow_organisations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_organisations_order_by!], where: sensorflow_organisations_bool_exp): [sensorflow_organisations!]!
  sensorflow_organisations_aggregate(distinct_on: [sensorflow_organisations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_organisations_order_by!], where: sensorflow_organisations_bool_exp): sensorflow_organisations_aggregate!
  sensorflow_organisations_by_pk(id: uuid!): sensorflow_organisations
  sensorflow_ota_queues(distinct_on: [sensorflow_ota_queues_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_queues_order_by!], where: sensorflow_ota_queues_bool_exp): [sensorflow_ota_queues!]!
  sensorflow_ota_queues_aggregate(distinct_on: [sensorflow_ota_queues_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_queues_order_by!], where: sensorflow_ota_queues_bool_exp): sensorflow_ota_queues_aggregate!
  sensorflow_ota_queues_by_pk(ota_queue_id: Int!): sensorflow_ota_queues
  sensorflow_ota_runs(distinct_on: [sensorflow_ota_runs_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_runs_order_by!], where: sensorflow_ota_runs_bool_exp): [sensorflow_ota_runs!]!
  sensorflow_ota_runs_aggregate(distinct_on: [sensorflow_ota_runs_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_runs_order_by!], where: sensorflow_ota_runs_bool_exp): sensorflow_ota_runs_aggregate!
  sensorflow_ota_runs_by_pk(current_firmware_release_id: Int!, node_mac_id: String!, ota_queue_id: Int!, target_firmware_release_id: Int!): sensorflow_ota_runs
  sensorflow_pending_ac_settings_commands_for_gateway(distinct_on: [sensorflow_pending_ac_settings_commands_for_gateway_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pending_ac_settings_commands_for_gateway_order_by!], where: sensorflow_pending_ac_settings_commands_for_gateway_bool_exp): [sensorflow_pending_ac_settings_commands_for_gateway!]!
  sensorflow_pending_ac_settings_commands_for_gateway_aggregate(distinct_on: [sensorflow_pending_ac_settings_commands_for_gateway_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pending_ac_settings_commands_for_gateway_order_by!], where: sensorflow_pending_ac_settings_commands_for_gateway_bool_exp): sensorflow_pending_ac_settings_commands_for_gateway_aggregate!
  sensorflow_pipes(distinct_on: [sensorflow_pipes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pipes_order_by!], where: sensorflow_pipes_bool_exp): [sensorflow_pipes!]!
  sensorflow_pipes_aggregate(distinct_on: [sensorflow_pipes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pipes_order_by!], where: sensorflow_pipes_bool_exp): sensorflow_pipes_aggregate!
  sensorflow_pipes_by_pk(id: uuid!): sensorflow_pipes
  sensorflow_position_precool_status_aggregate(distinct_on: [sensorflow_position_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_precool_status_order_by!], where: sensorflow_position_precool_status_bool_exp): sensorflow_position_precool_status_aggregate!
  sensorflow_position_signal_stats(distinct_on: [sensorflow_position_signal_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_signal_stats_order_by!], where: sensorflow_position_signal_stats_bool_exp): [sensorflow_position_signal_stats!]!
  sensorflow_position_signal_stats_aggregate(distinct_on: [sensorflow_position_signal_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_signal_stats_order_by!], where: sensorflow_position_signal_stats_bool_exp): sensorflow_position_signal_stats_aggregate!
  sensorflow_position_signal_stats_by_pk(balena_id: String!, position_id: uuid!): sensorflow_position_signal_stats
  sensorflow_precool_commands(distinct_on: [sensorflow_precool_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_commands_order_by!], where: sensorflow_precool_commands_bool_exp): [sensorflow_precool_commands!]!
  sensorflow_precool_commands_aggregate(distinct_on: [sensorflow_precool_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_commands_order_by!], where: sensorflow_precool_commands_bool_exp): sensorflow_precool_commands_aggregate!
  sensorflow_precool_commands_by_pk(id: uuid!): sensorflow_precool_commands
  sensorflow_precool_schedules(distinct_on: [sensorflow_precool_schedules_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_schedules_order_by!], where: sensorflow_precool_schedules_bool_exp): [sensorflow_precool_schedules!]!
  sensorflow_precool_schedules_aggregate(distinct_on: [sensorflow_precool_schedules_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_schedules_order_by!], where: sensorflow_precool_schedules_bool_exp): sensorflow_precool_schedules_aggregate!
  sensorflow_precool_schedules_by_pk(id: bigint!): sensorflow_precool_schedules
  sensorflow_pumps(distinct_on: [sensorflow_pumps_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pumps_order_by!], where: sensorflow_pumps_bool_exp): [sensorflow_pumps!]!
  sensorflow_pumps_aggregate(distinct_on: [sensorflow_pumps_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pumps_order_by!], where: sensorflow_pumps_bool_exp): sensorflow_pumps_aggregate!
  sensorflow_pumps_by_pk(id: uuid!): sensorflow_pumps
  sensorflow_reservation_guests(distinct_on: [sensorflow_reservation_guests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservation_guests_order_by!], where: sensorflow_reservation_guests_bool_exp): [sensorflow_reservation_guests!]!
  sensorflow_reservation_guests_aggregate(distinct_on: [sensorflow_reservation_guests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservation_guests_order_by!], where: sensorflow_reservation_guests_bool_exp): sensorflow_reservation_guests_aggregate!
  sensorflow_reservation_guests_by_pk(reservationId: bigint!, userId: String!): sensorflow_reservation_guests
  sensorflow_reservations(distinct_on: [sensorflow_reservations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservations_order_by!], where: sensorflow_reservations_bool_exp): [sensorflow_reservations!]!
  sensorflow_reservations_aggregate(distinct_on: [sensorflow_reservations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservations_order_by!], where: sensorflow_reservations_bool_exp): sensorflow_reservations_aggregate!
  sensorflow_reservations_by_pk(id: bigint!): sensorflow_reservations
  sensorflow_roles(distinct_on: [sensorflow_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_roles_order_by!], where: sensorflow_roles_bool_exp): [sensorflow_roles!]!
  sensorflow_roles_aggregate(distinct_on: [sensorflow_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_roles_order_by!], where: sensorflow_roles_bool_exp): sensorflow_roles_aggregate!
  sensorflow_roles_by_pk(id: uuid!): sensorflow_roles
  sensorflow_smart_rem_validation_history_aggregate(distinct_on: [sensorflow_smart_rem_validation_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_smart_rem_validation_history_order_by!], where: sensorflow_smart_rem_validation_history_bool_exp): sensorflow_smart_rem_validation_history_aggregate!
  sensorflow_smart_rem_validation_history_by_pk(id: uuid!): sensorflow_smart_rem_validation_history
  sensorflow_synced_raw_data(distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): [sensorflow_synced_raw_data!]!
  sensorflow_synced_raw_data_aggregate(distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): sensorflow_synced_raw_data_aggregate!
  sensorflow_task_status_history(distinct_on: [sensorflow_task_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_task_status_history_order_by!], where: sensorflow_task_status_history_bool_exp): [sensorflow_task_status_history!]!
  sensorflow_task_status_history_aggregate(distinct_on: [sensorflow_task_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_task_status_history_order_by!], where: sensorflow_task_status_history_bool_exp): sensorflow_task_status_history_aggregate!
  sensorflow_task_status_history_by_pk(id: Int!): sensorflow_task_status_history
  sensorflow_tasks(distinct_on: [sensorflow_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tasks_order_by!], where: sensorflow_tasks_bool_exp): [sensorflow_tasks!]!
  sensorflow_tasks_aggregate(distinct_on: [sensorflow_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tasks_order_by!], where: sensorflow_tasks_bool_exp): sensorflow_tasks_aggregate!
  sensorflow_tasks_by_pk(id: uuid!): sensorflow_tasks
  sensorflow_tests(distinct_on: [sensorflow_tests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tests_order_by!], where: sensorflow_tests_bool_exp): [sensorflow_tests!]!
  sensorflow_tests_aggregate(distinct_on: [sensorflow_tests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tests_order_by!], where: sensorflow_tests_bool_exp): sensorflow_tests_aggregate!
  sensorflow_tests_by_pk(testId: Int!): sensorflow_tests
  sensorflow_timezones(distinct_on: [sensorflow_timezones_select_column!], limit: Int, offset: Int, order_by: [sensorflow_timezones_order_by!], where: sensorflow_timezones_bool_exp): [sensorflow_timezones!]!
  sensorflow_timezones_aggregate(distinct_on: [sensorflow_timezones_select_column!], limit: Int, offset: Int, order_by: [sensorflow_timezones_order_by!], where: sensorflow_timezones_bool_exp): sensorflow_timezones_aggregate!
  sensorflow_timezones_by_pk(id: Int!): sensorflow_timezones
  sensorflow_updatable_nodes(distinct_on: [sensorflow_updatable_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_updatable_nodes_order_by!], where: sensorflow_updatable_nodes_bool_exp): [sensorflow_updatable_nodes!]!
  sensorflow_updatable_nodes_aggregate(distinct_on: [sensorflow_updatable_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_updatable_nodes_order_by!], where: sensorflow_updatable_nodes_bool_exp): sensorflow_updatable_nodes_aggregate!
  sensorflow_user_to_permission_mapping(distinct_on: [sensorflow_user_to_permission_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_user_to_permission_mapping_order_by!], where: sensorflow_user_to_permission_mapping_bool_exp): [sensorflow_user_to_permission_mapping!]!
  sensorflow_user_to_permission_mapping_aggregate(distinct_on: [sensorflow_user_to_permission_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_user_to_permission_mapping_order_by!], where: sensorflow_user_to_permission_mapping_bool_exp): sensorflow_user_to_permission_mapping_aggregate!
  sensorflow_users(distinct_on: [sensorflow_users_select_column!], limit: Int, offset: Int, order_by: [sensorflow_users_order_by!], where: sensorflow_users_bool_exp): [sensorflow_users!]!
  sensorflow_users_aggregate(distinct_on: [sensorflow_users_select_column!], limit: Int, offset: Int, order_by: [sensorflow_users_order_by!], where: sensorflow_users_bool_exp): sensorflow_users_aggregate!
  sensorflow_users_by_pk(userId: String!): sensorflow_users
  sensorflow_v_all_latest_node_configurations(distinct_on: [sensorflow_v_all_latest_node_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_all_latest_node_configurations_order_by!], where: sensorflow_v_all_latest_node_configurations_bool_exp): [sensorflow_v_all_latest_node_configurations!]!
  sensorflow_v_all_latest_node_configurations_aggregate(distinct_on: [sensorflow_v_all_latest_node_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_all_latest_node_configurations_order_by!], where: sensorflow_v_all_latest_node_configurations_bool_exp): sensorflow_v_all_latest_node_configurations_aggregate!
  sensorflow_v_combined_user_roles(distinct_on: [sensorflow_v_combined_user_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_combined_user_roles_order_by!], where: sensorflow_v_combined_user_roles_bool_exp): [sensorflow_v_combined_user_roles!]!
  sensorflow_v_combined_user_roles_aggregate(distinct_on: [sensorflow_v_combined_user_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_combined_user_roles_order_by!], where: sensorflow_v_combined_user_roles_bool_exp): sensorflow_v_combined_user_roles_aggregate!
  sensorflow_v_comfortplus_key_details(distinct_on: [sensorflow_v_comfortplus_key_details_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_details_order_by!], where: sensorflow_v_comfortplus_key_details_bool_exp): [sensorflow_v_comfortplus_key_details!]!
  sensorflow_v_comfortplus_key_details_aggregate(distinct_on: [sensorflow_v_comfortplus_key_details_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_details_order_by!], where: sensorflow_v_comfortplus_key_details_bool_exp): sensorflow_v_comfortplus_key_details_aggregate!
  sensorflow_v_comfortplus_key_measurements(distinct_on: [sensorflow_v_comfortplus_key_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_measurements_order_by!], where: sensorflow_v_comfortplus_key_measurements_bool_exp): [sensorflow_v_comfortplus_key_measurements!]!
  sensorflow_v_comfortplus_key_measurements_aggregate(distinct_on: [sensorflow_v_comfortplus_key_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_measurements_order_by!], where: sensorflow_v_comfortplus_key_measurements_bool_exp): sensorflow_v_comfortplus_key_measurements_aggregate!
  sensorflow_v_comfortplus_precool_status(distinct_on: [sensorflow_v_comfortplus_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_precool_status_order_by!], where: sensorflow_v_comfortplus_precool_status_bool_exp): [sensorflow_v_comfortplus_precool_status!]!
  sensorflow_v_comfortplus_precool_status_aggregate(distinct_on: [sensorflow_v_comfortplus_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_precool_status_order_by!], where: sensorflow_v_comfortplus_precool_status_bool_exp): sensorflow_v_comfortplus_precool_status_aggregate!
  sensorflow_v_current_position_configurations_aggregate(distinct_on: [sensorflow_v_current_position_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_current_position_configurations_order_by!], where: sensorflow_v_current_position_configurations_bool_exp): sensorflow_v_current_position_configurations_aggregate!
  sensorflow_v_group_tasks(distinct_on: [sensorflow_v_group_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_tasks_order_by!], where: sensorflow_v_group_tasks_bool_exp): [sensorflow_v_group_tasks!]!
  sensorflow_v_group_tasks_aggregate(distinct_on: [sensorflow_v_group_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_tasks_order_by!], where: sensorflow_v_group_tasks_bool_exp): sensorflow_v_group_tasks_aggregate!
  sensorflow_v_group_with_stats(distinct_on: [sensorflow_v_group_with_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_with_stats_order_by!], where: sensorflow_v_group_with_stats_bool_exp): [sensorflow_v_group_with_stats!]!
  sensorflow_v_group_with_stats_aggregate(distinct_on: [sensorflow_v_group_with_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_with_stats_order_by!], where: sensorflow_v_group_with_stats_bool_exp): sensorflow_v_group_with_stats_aggregate!
  sensorflow_v_infrastructure_emon_power_status_aggregate(distinct_on: [sensorflow_v_infrastructure_emon_power_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructure_emon_power_status_order_by!], where: sensorflow_v_infrastructure_emon_power_status_bool_exp): sensorflow_v_infrastructure_emon_power_status_aggregate!
  sensorflow_v_infrastructures_aggregate(distinct_on: [sensorflow_v_infrastructures_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructures_order_by!], where: sensorflow_v_infrastructures_bool_exp): sensorflow_v_infrastructures_aggregate!
  sensorflow_v_key_emon_power_reading(distinct_on: [sensorflow_v_key_emon_power_reading_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_emon_power_reading_order_by!], where: sensorflow_v_key_emon_power_reading_bool_exp): [sensorflow_v_key_emon_power_reading!]!
  sensorflow_v_key_emon_power_reading_aggregate(distinct_on: [sensorflow_v_key_emon_power_reading_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_emon_power_reading_order_by!], where: sensorflow_v_key_emon_power_reading_bool_exp): sensorflow_v_key_emon_power_reading_aggregate!
  sensorflow_v_key_position_latest_entry(distinct_on: [sensorflow_v_key_position_latest_entry_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_position_latest_entry_order_by!], where: sensorflow_v_key_position_latest_entry_bool_exp): [sensorflow_v_key_position_latest_entry!]!
  sensorflow_v_key_position_latest_entry_aggregate(distinct_on: [sensorflow_v_key_position_latest_entry_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_position_latest_entry_order_by!], where: sensorflow_v_key_position_latest_entry_bool_exp): sensorflow_v_key_position_latest_entry_aggregate!
  sensorflow_v_key_task_min_duedate(distinct_on: [sensorflow_v_key_task_min_duedate_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_task_min_duedate_order_by!], where: sensorflow_v_key_task_min_duedate_bool_exp): [sensorflow_v_key_task_min_duedate!]!
  sensorflow_v_key_task_min_duedate_aggregate(distinct_on: [sensorflow_v_key_task_min_duedate_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_task_min_duedate_order_by!], where: sensorflow_v_key_task_min_duedate_bool_exp): sensorflow_v_key_task_min_duedate_aggregate!
  sensorflow_v_location_last_update(distinct_on: [sensorflow_v_location_last_update_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_location_last_update_order_by!], where: sensorflow_v_location_last_update_bool_exp): [sensorflow_v_location_last_update!]!
  sensorflow_v_location_last_update_aggregate(distinct_on: [sensorflow_v_location_last_update_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_location_last_update_order_by!], where: sensorflow_v_location_last_update_bool_exp): sensorflow_v_location_last_update_aggregate!
  sensorflow_v_node_life_cycle_events(distinct_on: [sensorflow_v_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_node_life_cycle_events_order_by!], where: sensorflow_v_node_life_cycle_events_bool_exp): [sensorflow_v_node_life_cycle_events!]!
  sensorflow_v_node_life_cycle_events_aggregate(distinct_on: [sensorflow_v_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_node_life_cycle_events_order_by!], where: sensorflow_v_node_life_cycle_events_bool_exp): sensorflow_v_node_life_cycle_events_aggregate!
  sensorflow_v_task_with_key_status_history(distinct_on: [sensorflow_v_task_with_key_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_task_with_key_status_history_order_by!], where: sensorflow_v_task_with_key_status_history_bool_exp): [sensorflow_v_task_with_key_status_history!]!
  sensorflow_v_task_with_key_status_history_aggregate(distinct_on: [sensorflow_v_task_with_key_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_task_with_key_status_history_order_by!], where: sensorflow_v_task_with_key_status_history_bool_exp): sensorflow_v_task_with_key_status_history_aggregate!
  sensorflow_v_tasks_with_keys_aggregate(distinct_on: [sensorflow_v_tasks_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_tasks_with_keys_order_by!], where: sensorflow_v_tasks_with_keys_bool_exp): sensorflow_v_tasks_with_keys_aggregate!
  sensorflow_warehouse_kitting_process_metadata_aggregate(distinct_on: [sensorflow_warehouse_kitting_process_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_kitting_process_metadata_order_by!], where: sensorflow_warehouse_kitting_process_metadata_bool_exp): sensorflow_warehouse_kitting_process_metadata_aggregate!
  sensorflow_warehouse_kitting_process_metadata_by_pk(balenaId: String!): sensorflow_warehouse_kitting_process_metadata
  sensorflow_warehouse_mapping_status_of_slots(distinct_on: [sensorflow_warehouse_mapping_status_of_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_mapping_status_of_slots_order_by!], where: sensorflow_warehouse_mapping_status_of_slots_bool_exp): [sensorflow_warehouse_mapping_status_of_slots!]!
  sensorflow_warehouse_mapping_status_of_slots_aggregate(distinct_on: [sensorflow_warehouse_mapping_status_of_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_mapping_status_of_slots_order_by!], where: sensorflow_warehouse_mapping_status_of_slots_bool_exp): sensorflow_warehouse_mapping_status_of_slots_aggregate!
  sf_firmware_dist_pipeline_firmware_build(distinct_on: [sf_firmware_dist_pipeline_firmware_build_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_build_order_by!], where: sf_firmware_dist_pipeline_firmware_build_bool_exp): [sf_firmware_dist_pipeline_firmware_build!]!
  sf_firmware_dist_pipeline_firmware_build_aggregate(distinct_on: [sf_firmware_dist_pipeline_firmware_build_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_build_order_by!], where: sf_firmware_dist_pipeline_firmware_build_bool_exp): sf_firmware_dist_pipeline_firmware_build_aggregate!
  sf_firmware_dist_pipeline_firmware_build_by_pk(buildId: Int!): sf_firmware_dist_pipeline_firmware_build
  sf_firmware_dist_pipeline_firmware_numbers(distinct_on: [sf_firmware_dist_pipeline_firmware_numbers_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_numbers_order_by!], where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp): [sf_firmware_dist_pipeline_firmware_numbers!]!
  sf_firmware_dist_pipeline_firmware_numbers_aggregate(distinct_on: [sf_firmware_dist_pipeline_firmware_numbers_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_numbers_order_by!], where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp): sf_firmware_dist_pipeline_firmware_numbers_aggregate!
  sf_firmware_dist_pipeline_firmware_numbers_by_pk(id: Int!): sf_firmware_dist_pipeline_firmware_numbers
  sf_pms_pms_pmslist(distinct_on: [sf_pms_pms_pmslist_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_pmslist_order_by!], where: sf_pms_pms_pmslist_bool_exp): [sf_pms_pms_pmslist!]!
  sf_pms_pms_pmslist_aggregate(distinct_on: [sf_pms_pms_pmslist_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_pmslist_order_by!], where: sf_pms_pms_pmslist_bool_exp): sf_pms_pms_pmslist_aggregate!
  sf_pms_pms_pmslist_by_pk(pms_id: Int!): sf_pms_pms_pmslist
  sf_pms_pms_properties(distinct_on: [sf_pms_pms_properties_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_properties_order_by!], where: sf_pms_pms_properties_bool_exp): [sf_pms_pms_properties!]!
  sf_pms_pms_properties_aggregate(distinct_on: [sf_pms_pms_properties_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_properties_order_by!], where: sf_pms_pms_properties_bool_exp): sf_pms_pms_properties_aggregate!
  sf_pms_pms_properties_by_pk(pms_id: Int!, property_id: String!): sf_pms_pms_properties
  sf_pms_pms_property_eod_surveys(distinct_on: [sf_pms_pms_property_eod_surveys_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_property_eod_surveys_order_by!], where: sf_pms_pms_property_eod_surveys_bool_exp): [sf_pms_pms_property_eod_surveys!]!
  sf_pms_pms_property_eod_surveys_aggregate(distinct_on: [sf_pms_pms_property_eod_surveys_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_property_eod_surveys_order_by!], where: sf_pms_pms_property_eod_surveys_bool_exp): sf_pms_pms_property_eod_surveys_aggregate!
  sf_pms_pms_property_eod_surveys_by_pk(pms_id: Int!, property_id: String!, survey_monkey_id: String!): sf_pms_pms_property_eod_surveys
  sf_pms_pms_reservations(distinct_on: [sf_pms_pms_reservations_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_reservations_order_by!], where: sf_pms_pms_reservations_bool_exp): [sf_pms_pms_reservations!]!
  sf_pms_pms_reservations_aggregate(distinct_on: [sf_pms_pms_reservations_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_reservations_order_by!], where: sf_pms_pms_reservations_bool_exp): sf_pms_pms_reservations_aggregate!
  sf_pms_pms_room_categories(distinct_on: [sf_pms_pms_room_categories_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_room_categories_order_by!], where: sf_pms_pms_room_categories_bool_exp): [sf_pms_pms_room_categories!]!
  sf_pms_pms_room_categories_aggregate(distinct_on: [sf_pms_pms_room_categories_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_room_categories_order_by!], where: sf_pms_pms_room_categories_bool_exp): sf_pms_pms_room_categories_aggregate!
  sf_pms_pms_rooms(distinct_on: [sf_pms_pms_rooms_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_rooms_order_by!], where: sf_pms_pms_rooms_bool_exp): [sf_pms_pms_rooms!]!
  sf_pms_pms_rooms_aggregate(distinct_on: [sf_pms_pms_rooms_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_rooms_order_by!], where: sf_pms_pms_rooms_bool_exp): sf_pms_pms_rooms_aggregate!
  sf_pms_smartalloc_report(distinct_on: [sf_pms_smartalloc_report_select_column!], limit: Int, offset: Int, order_by: [sf_pms_smartalloc_report_order_by!], where: sf_pms_smartalloc_report_bool_exp): [sf_pms_smartalloc_report!]!
  sf_pms_smartalloc_report_aggregate(distinct_on: [sf_pms_smartalloc_report_select_column!], limit: Int, offset: Int, order_by: [sf_pms_smartalloc_report_order_by!], where: sf_pms_smartalloc_report_bool_exp): sf_pms_smartalloc_report_aggregate!
  sf_pms_smartalloc_report_by_pk(date: date!, locationId: uuid!, type: String!): sf_pms_smartalloc_report
  sf_simulation_instrumentation_commands(distinct_on: [sf_simulation_instrumentation_commands_select_column!], limit: Int, offset: Int, order_by: [sf_simulation_instrumentation_commands_order_by!], where: sf_simulation_instrumentation_commands_bool_exp): [sf_simulation_instrumentation_commands!]!
  sf_simulation_instrumentation_commands_aggregate(distinct_on: [sf_simulation_instrumentation_commands_select_column!], limit: Int, offset: Int, order_by: [sf_simulation_instrumentation_commands_order_by!], where: sf_simulation_instrumentation_commands_bool_exp): sf_simulation_instrumentation_commands_aggregate!
  sf_simulation_instrumentation_commands_by_pk(id: Int!): sf_simulation_instrumentation_commands
  sf_support_actions(distinct_on: [sf_support_actions_select_column!], limit: Int, offset: Int, order_by: [sf_support_actions_order_by!], where: sf_support_actions_bool_exp): [sf_support_actions!]!
  sf_support_actions_aggregate(distinct_on: [sf_support_actions_select_column!], limit: Int, offset: Int, order_by: [sf_support_actions_order_by!], where: sf_support_actions_bool_exp): sf_support_actions_aggregate!
  sf_support_actions_by_pk(id: Int!): sf_support_actions
  sf_support_active_property_alerts(distinct_on: [sf_support_active_property_alerts_select_column!], limit: Int, offset: Int, order_by: [sf_support_active_property_alerts_order_by!], where: sf_support_active_property_alerts_bool_exp): [sf_support_active_property_alerts!]!
  sf_support_active_property_alerts_aggregate(distinct_on: [sf_support_active_property_alerts_select_column!], limit: Int, offset: Int, order_by: [sf_support_active_property_alerts_order_by!], where: sf_support_active_property_alerts_bool_exp): sf_support_active_property_alerts_aggregate!
  sf_support_active_property_alerts_by_pk(locationId: uuid!, ticketClassId: Int!): sf_support_active_property_alerts
  sf_support_analytics_ticket_jobs_running_histories(distinct_on: [sf_support_analytics_ticket_jobs_running_histories_select_column!], limit: Int, offset: Int, order_by: [sf_support_analytics_ticket_jobs_running_histories_order_by!], where: sf_support_analytics_ticket_jobs_running_histories_bool_exp): [sf_support_analytics_ticket_jobs_running_histories!]!
  sf_support_analytics_ticket_jobs_running_histories_aggregate(distinct_on: [sf_support_analytics_ticket_jobs_running_histories_select_column!], limit: Int, offset: Int, order_by: [sf_support_analytics_ticket_jobs_running_histories_order_by!], where: sf_support_analytics_ticket_jobs_running_histories_bool_exp): sf_support_analytics_ticket_jobs_running_histories_aggregate!
  sf_support_analytics_ticket_jobs_running_histories_by_pk(id: Int!): sf_support_analytics_ticket_jobs_running_histories
  sf_support_root_causes(distinct_on: [sf_support_root_causes_select_column!], limit: Int, offset: Int, order_by: [sf_support_root_causes_order_by!], where: sf_support_root_causes_bool_exp): [sf_support_root_causes!]!
  sf_support_root_causes_aggregate(distinct_on: [sf_support_root_causes_select_column!], limit: Int, offset: Int, order_by: [sf_support_root_causes_order_by!], where: sf_support_root_causes_bool_exp): sf_support_root_causes_aggregate!
  sf_support_root_causes_by_pk(id: Int!): sf_support_root_causes
  sf_support_ticket_classes_aggregate(distinct_on: [sf_support_ticket_classes_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_classes_order_by!], where: sf_support_ticket_classes_bool_exp): sf_support_ticket_classes_aggregate!
  sf_support_ticket_classes_by_pk(id: Int!): sf_support_ticket_classes
  sf_support_ticket_status(distinct_on: [sf_support_ticket_status_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_status_order_by!], where: sf_support_ticket_status_bool_exp): [sf_support_ticket_status!]!
  sf_support_ticket_status_aggregate(distinct_on: [sf_support_ticket_status_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_status_order_by!], where: sf_support_ticket_status_bool_exp): sf_support_ticket_status_aggregate!
  sf_support_ticket_status_by_pk(id: Int!): sf_support_ticket_status
  sf_support_tickets(distinct_on: [sf_support_tickets_select_column!], limit: Int, offset: Int, order_by: [sf_support_tickets_order_by!], where: sf_support_tickets_bool_exp): [sf_support_tickets!]!
  sf_support_tickets_aggregate(distinct_on: [sf_support_tickets_select_column!], limit: Int, offset: Int, order_by: [sf_support_tickets_order_by!], where: sf_support_tickets_bool_exp): sf_support_tickets_aggregate!
  sf_support_tickets_by_pk(ticket_id: Int!): sf_support_tickets
  sf_testjig_test_jig_aggregate(distinct_on: [sf_testjig_test_jig_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_order_by!], where: sf_testjig_test_jig_bool_exp): sf_testjig_test_jig_aggregate!
  sf_testjig_test_jig_maintenance_report_aggregate(distinct_on: [sf_testjig_test_jig_maintenance_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_maintenance_report_order_by!], where: sf_testjig_test_jig_maintenance_report_bool_exp): sf_testjig_test_jig_maintenance_report_aggregate!
  sf_testjig_test_jig_report_metadata_aggregate(distinct_on: [sf_testjig_test_jig_report_metadata_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadata_order_by!], where: sf_testjig_test_jig_report_metadata_bool_exp): sf_testjig_test_jig_report_metadata_aggregate!
  sf_testjig_test_jig_report_metadata_by_pk(id: uuid!): sf_testjig_test_jig_report_metadata
  sf_testjig_test_jig_report_metadatas_aggregate(distinct_on: [sf_testjig_test_jig_report_metadatas_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadatas_order_by!], where: sf_testjig_test_jig_report_metadatas_bool_exp): sf_testjig_test_jig_report_metadatas_aggregate!
  sf_testjig_test_jig_report_metadatas_by_pk(id: Int!): sf_testjig_test_jig_report_metadatas
  sf_testjig_test_jig_test_report_aggregate(distinct_on: [sf_testjig_test_jig_test_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_test_report_order_by!], where: sf_testjig_test_jig_test_report_bool_exp): sf_testjig_test_jig_test_report_aggregate!
  sf_testjig_test_jig_type_aggregate(distinct_on: [sf_testjig_test_jig_type_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_type_order_by!], where: sf_testjig_test_jig_type_bool_exp): sf_testjig_test_jig_type_aggregate!
  sf_testjig_v_latest_report_rma_nodes(distinct_on: [sf_testjig_v_latest_report_rma_nodes_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_v_latest_report_rma_nodes_order_by!], where: sf_testjig_v_latest_report_rma_nodes_bool_exp): [sf_testjig_v_latest_report_rma_nodes!]!
  sf_testjig_v_latest_report_rma_nodes_aggregate(distinct_on: [sf_testjig_v_latest_report_rma_nodes_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_v_latest_report_rma_nodes_order_by!], where: sf_testjig_v_latest_report_rma_nodes_bool_exp): sf_testjig_v_latest_report_rma_nodes_aggregate!
  tasksWithKeys(distinct_on: [sensorflow_v_tasks_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_tasks_with_keys_order_by!], where: sensorflow_v_tasks_with_keys_bool_exp): [sensorflow_v_tasks_with_keys!]!
  testRuns(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): [testRuns!]!
  testRuns_aggregate(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): testRuns_aggregate!
  testRuns_by_pk(testRunId: Int!): testRuns
  ticketClasses(distinct_on: [sf_support_ticket_classes_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_classes_order_by!], where: sf_support_ticket_classes_bool_exp): [sf_support_ticket_classes!]!
  user(userId: String!): User!
  userWithAuthToken: User
  users(filter: UserFilterInput, pagination: GenericPaginationInput, sort: UserSortInput): UsersResponse!
  validateClickupListId(clickupListId: String!): Boolean!
  validateInfraNodeMapping(infraId: ID!, nodeMacInput: String!, nodeSubType: String, nodeType: NodeType!, slotName: String!): Boolean!
  validateNodeBeforeMappingToSlot(nodeMacInput: String!, nodeSubType: String, nodeType: NodeType!, roomId: ID!, slotName: String!): Boolean!
}

type RMAJobResponse {
  id: ID!
}

type Role {
  defaultRoute: String
  id: ID!
  name: String!
  permissions: [Permission!]!
  userCount: Int
}

type Room {
  energyConsumption(fromDate: String!, toDate: String!): Float
  positionConfiguration: PositionConfiguration
  roomId: ID!
  roomName: String!
  skippedRapidMappingSlot: Slot
  slots: [Slot!]!
}

input RoomCompressorCreateInput {
  compressorName: String!
  compressorNumPhases: Int!
  ctMultiplier: Float!
  hvacSystemType: String!
  position: PositionInput!
  positionMappingId: ID!
}

input RoomDetailsFilterInput {
  mappingStatus: MappingStatus
}

type RoomWithThermostatOff {
  roomId: ID!
  roomName: String
}

input searchPositions_sensorflow_locations_args {
  searchbykeyname: String
  searchbyroomname: String
}

input searchRooms_sensorflow_positions_args {
  searchautosetname: String
  searchkeyname: String
}

type sensorflow_ac_command_event_stream {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ac_command_event_stream_aggregate {
  aggregate: sensorflow_ac_command_event_stream_aggregate_fields
  nodes: [sensorflow_ac_command_event_stream!]!
}

type sensorflow_ac_command_event_stream_aggregate_fields {
  count(columns: [sensorflow_ac_command_event_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_ac_command_event_stream_max_fields
  min: sensorflow_ac_command_event_stream_min_fields
}

input sensorflow_ac_command_event_stream_bool_exp {
  _and: [sensorflow_ac_command_event_stream_bool_exp!]
  _not: sensorflow_ac_command_event_stream_bool_exp
  _or: [sensorflow_ac_command_event_stream_bool_exp!]
  ac_settings_type_changed: String_comparison_exp
  event_type: String_comparison_exp
  modified_by_user: String_comparison_exp
  new_ac_settings_value: String_comparison_exp
  previous_ac_settings_value: String_comparison_exp
  time: timestamptz_comparison_exp
  user_email: String_comparison_exp
}

input sensorflow_ac_command_event_stream_insert_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ac_command_event_stream_max_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ac_command_event_stream_min_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ac_command_event_stream_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ac_command_event_stream!]!
}

input sensorflow_ac_command_event_stream_order_by {
  ac_settings_type_changed: order_by
  event_type: order_by
  modified_by_user: order_by
  new_ac_settings_value: order_by
  previous_ac_settings_value: order_by
  time: order_by
  user_email: order_by
}

enum sensorflow_ac_command_event_stream_select_column {
  ac_settings_type_changed
  event_type
  modified_by_user
  new_ac_settings_value
  previous_ac_settings_value
  time
  user_email
}

input sensorflow_ac_command_event_stream_set_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_ac_command_event_stream_stream_cursor_input {
  initial_value: sensorflow_ac_command_event_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ac_command_event_stream_stream_cursor_value_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_ac_command_event_stream_updates {
  _set: sensorflow_ac_command_event_stream_set_input
  where: sensorflow_ac_command_event_stream_bool_exp!
}

type sensorflow_ac_models {
  brandName: String!
  description: String
  id: uuid!
  identifier: Int!
  modelName: String!
  positionConfigurations(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): [positionConfigurations!]!
  positionConfigurations_aggregate(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): positionConfigurations_aggregate!
  status: String!
}

type sensorflow_ac_models_aggregate {
  aggregate: sensorflow_ac_models_aggregate_fields
  nodes: [sensorflow_ac_models!]!
}

type sensorflow_ac_models_aggregate_fields {
  avg: sensorflow_ac_models_avg_fields
  count(columns: [sensorflow_ac_models_select_column!], distinct: Boolean): Int!
  max: sensorflow_ac_models_max_fields
  min: sensorflow_ac_models_min_fields
  stddev: sensorflow_ac_models_stddev_fields
  stddev_pop: sensorflow_ac_models_stddev_pop_fields
  stddev_samp: sensorflow_ac_models_stddev_samp_fields
  sum: sensorflow_ac_models_sum_fields
  var_pop: sensorflow_ac_models_var_pop_fields
  var_samp: sensorflow_ac_models_var_samp_fields
  variance: sensorflow_ac_models_variance_fields
}

type sensorflow_ac_models_avg_fields {
  identifier: Float
}

input sensorflow_ac_models_bool_exp {
  _and: [sensorflow_ac_models_bool_exp!]
  _not: sensorflow_ac_models_bool_exp
  _or: [sensorflow_ac_models_bool_exp!]
  brandName: String_comparison_exp
  description: String_comparison_exp
  id: uuid_comparison_exp
  identifier: Int_comparison_exp
  modelName: String_comparison_exp
  positionConfigurations: positionConfigurations_bool_exp
  positionConfigurations_aggregate: positionConfigurations_aggregate_bool_exp
  status: String_comparison_exp
}

enum sensorflow_ac_models_constraint {
  ac_models_pkey
}

input sensorflow_ac_models_inc_input {
  identifier: Int
}

input sensorflow_ac_models_insert_input {
  brandName: String
  description: String
  id: uuid
  identifier: Int
  modelName: String
  positionConfigurations: positionConfigurations_arr_rel_insert_input
  status: String
}

type sensorflow_ac_models_max_fields {
  brandName: String
  description: String
  id: uuid
  identifier: Int
  modelName: String
  status: String
}

type sensorflow_ac_models_min_fields {
  brandName: String
  description: String
  id: uuid
  identifier: Int
  modelName: String
  status: String
}

type sensorflow_ac_models_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ac_models!]!
}

input sensorflow_ac_models_obj_rel_insert_input {
  data: sensorflow_ac_models_insert_input!
  on_conflict: sensorflow_ac_models_on_conflict
}

input sensorflow_ac_models_on_conflict {
  constraint: sensorflow_ac_models_constraint!
  update_columns: [sensorflow_ac_models_update_column!]! = []
  where: sensorflow_ac_models_bool_exp
}

input sensorflow_ac_models_order_by {
  brandName: order_by
  description: order_by
  id: order_by
  identifier: order_by
  modelName: order_by
  positionConfigurations_aggregate: positionConfigurations_aggregate_order_by
  status: order_by
}

input sensorflow_ac_models_pk_columns_input {
  id: uuid!
}

enum sensorflow_ac_models_select_column {
  brandName
  description
  id
  identifier
  modelName
  status
}

input sensorflow_ac_models_set_input {
  brandName: String
  description: String
  id: uuid
  identifier: Int
  modelName: String
  status: String
}

type sensorflow_ac_models_stddev_fields {
  identifier: Float
}

type sensorflow_ac_models_stddev_pop_fields {
  identifier: Float
}

type sensorflow_ac_models_stddev_samp_fields {
  identifier: Float
}

input sensorflow_ac_models_stream_cursor_input {
  initial_value: sensorflow_ac_models_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ac_models_stream_cursor_value_input {
  brandName: String
  description: String
  id: uuid
  identifier: Int
  modelName: String
  status: String
}

type sensorflow_ac_models_sum_fields {
  identifier: Int
}

enum sensorflow_ac_models_update_column {
  brandName
  description
  id
  identifier
  modelName
  status
}

input sensorflow_ac_models_updates {
  _inc: sensorflow_ac_models_inc_input
  _set: sensorflow_ac_models_set_input
  where: sensorflow_ac_models_bool_exp!
}

type sensorflow_ac_models_var_pop_fields {
  identifier: Float
}

type sensorflow_ac_models_var_samp_fields {
  identifier: Float
}

type sensorflow_ac_models_variance_fields {
  identifier: Float
}

type sensorflow_ac_setting_commands {
  createdBy: String
  createdByUser: sensorflow_users
  creationDate: timestamptz!
  failMessage: String
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  id: uuid!
  position: sensorflow_positions!
  positionId: uuid!
  source: String!
  status: String!
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
  updatedAt: timestamptz
}

type sensorflow_ac_setting_commands_aggregate {
  aggregate: sensorflow_ac_setting_commands_aggregate_fields
  nodes: [sensorflow_ac_setting_commands!]!
}

input sensorflow_ac_setting_commands_aggregate_bool_exp {
  count: sensorflow_ac_setting_commands_aggregate_bool_exp_count
}

input sensorflow_ac_setting_commands_aggregate_bool_exp_count {
  arguments: [sensorflow_ac_setting_commands_select_column!]
  distinct: Boolean
  filter: sensorflow_ac_setting_commands_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_ac_setting_commands_aggregate_fields {
  avg: sensorflow_ac_setting_commands_avg_fields
  count(columns: [sensorflow_ac_setting_commands_select_column!], distinct: Boolean): Int!
  max: sensorflow_ac_setting_commands_max_fields
  min: sensorflow_ac_setting_commands_min_fields
  stddev: sensorflow_ac_setting_commands_stddev_fields
  stddev_pop: sensorflow_ac_setting_commands_stddev_pop_fields
  stddev_samp: sensorflow_ac_setting_commands_stddev_samp_fields
  sum: sensorflow_ac_setting_commands_sum_fields
  var_pop: sensorflow_ac_setting_commands_var_pop_fields
  var_samp: sensorflow_ac_setting_commands_var_samp_fields
  variance: sensorflow_ac_setting_commands_variance_fields
}

input sensorflow_ac_setting_commands_aggregate_order_by {
  avg: sensorflow_ac_setting_commands_avg_order_by
  count: order_by
  max: sensorflow_ac_setting_commands_max_order_by
  min: sensorflow_ac_setting_commands_min_order_by
  stddev: sensorflow_ac_setting_commands_stddev_order_by
  stddev_pop: sensorflow_ac_setting_commands_stddev_pop_order_by
  stddev_samp: sensorflow_ac_setting_commands_stddev_samp_order_by
  sum: sensorflow_ac_setting_commands_sum_order_by
  var_pop: sensorflow_ac_setting_commands_var_pop_order_by
  var_samp: sensorflow_ac_setting_commands_var_samp_order_by
  variance: sensorflow_ac_setting_commands_variance_order_by
}

input sensorflow_ac_setting_commands_arr_rel_insert_input {
  data: [sensorflow_ac_setting_commands_insert_input!]!
  on_conflict: sensorflow_ac_setting_commands_on_conflict
}

type sensorflow_ac_setting_commands_avg_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_avg_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

input sensorflow_ac_setting_commands_bool_exp {
  _and: [sensorflow_ac_setting_commands_bool_exp!]
  _not: sensorflow_ac_setting_commands_bool_exp
  _or: [sensorflow_ac_setting_commands_bool_exp!]
  createdBy: String_comparison_exp
  createdByUser: sensorflow_users_bool_exp
  creationDate: timestamptz_comparison_exp
  failMessage: String_comparison_exp
  fromACMode: Int_comparison_exp
  fromACSetPoint: Int_comparison_exp
  fromACStatus: Int_comparison_exp
  fromFanSpeed: Int_comparison_exp
  id: uuid_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  source: String_comparison_exp
  status: String_comparison_exp
  toACMode: Int_comparison_exp
  toACSetPoint: Int_comparison_exp
  toACStatus: Int_comparison_exp
  toFanSpeed: Int_comparison_exp
  updatedAt: timestamptz_comparison_exp
}

enum sensorflow_ac_setting_commands_constraint {
  ac_setting_commands_pkey
}

input sensorflow_ac_setting_commands_inc_input {
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
}

input sensorflow_ac_setting_commands_insert_input {
  createdBy: String
  createdByUser: sensorflow_users_obj_rel_insert_input
  creationDate: timestamptz
  failMessage: String
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  id: uuid
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  source: String
  status: String
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
  updatedAt: timestamptz
}

type sensorflow_ac_setting_commands_max_fields {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  id: uuid
  positionId: uuid
  source: String
  status: String
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
  updatedAt: timestamptz
}

input sensorflow_ac_setting_commands_max_order_by {
  createdBy: order_by
  creationDate: order_by
  failMessage: order_by
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  id: order_by
  positionId: order_by
  source: order_by
  status: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
  updatedAt: order_by
}

type sensorflow_ac_setting_commands_min_fields {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  id: uuid
  positionId: uuid
  source: String
  status: String
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
  updatedAt: timestamptz
}

input sensorflow_ac_setting_commands_min_order_by {
  createdBy: order_by
  creationDate: order_by
  failMessage: order_by
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  id: order_by
  positionId: order_by
  source: order_by
  status: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
  updatedAt: order_by
}

type sensorflow_ac_setting_commands_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ac_setting_commands!]!
}

input sensorflow_ac_setting_commands_on_conflict {
  constraint: sensorflow_ac_setting_commands_constraint!
  update_columns: [sensorflow_ac_setting_commands_update_column!]! = []
  where: sensorflow_ac_setting_commands_bool_exp
}

input sensorflow_ac_setting_commands_order_by {
  createdBy: order_by
  createdByUser: sensorflow_users_order_by
  creationDate: order_by
  failMessage: order_by
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  id: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  source: order_by
  status: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
  updatedAt: order_by
}

input sensorflow_ac_setting_commands_pk_columns_input {
  id: uuid!
}

enum sensorflow_ac_setting_commands_select_column {
  createdBy
  creationDate
  failMessage
  fromACMode
  fromACSetPoint
  fromACStatus
  fromFanSpeed
  id
  positionId
  source
  status
  toACMode
  toACSetPoint
  toACStatus
  toFanSpeed
  updatedAt
}

input sensorflow_ac_setting_commands_set_input {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  id: uuid
  positionId: uuid
  source: String
  status: String
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
  updatedAt: timestamptz
}

type sensorflow_ac_setting_commands_stddev_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_stddev_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

type sensorflow_ac_setting_commands_stddev_pop_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_stddev_pop_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

type sensorflow_ac_setting_commands_stddev_samp_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_stddev_samp_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

input sensorflow_ac_setting_commands_stream_cursor_input {
  initial_value: sensorflow_ac_setting_commands_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ac_setting_commands_stream_cursor_value_input {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  id: uuid
  positionId: uuid
  source: String
  status: String
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
  updatedAt: timestamptz
}

type sensorflow_ac_setting_commands_sum_fields {
  fromACMode: Int
  fromACSetPoint: Int
  fromACStatus: Int
  fromFanSpeed: Int
  toACMode: Int
  toACSetPoint: Int
  toACStatus: Int
  toFanSpeed: Int
}

input sensorflow_ac_setting_commands_sum_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

enum sensorflow_ac_setting_commands_update_column {
  createdBy
  creationDate
  failMessage
  fromACMode
  fromACSetPoint
  fromACStatus
  fromFanSpeed
  id
  positionId
  source
  status
  toACMode
  toACSetPoint
  toACStatus
  toFanSpeed
  updatedAt
}

input sensorflow_ac_setting_commands_updates {
  _inc: sensorflow_ac_setting_commands_inc_input
  _set: sensorflow_ac_setting_commands_set_input
  where: sensorflow_ac_setting_commands_bool_exp!
}

type sensorflow_ac_setting_commands_var_pop_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_var_pop_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

type sensorflow_ac_setting_commands_var_samp_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_var_samp_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

type sensorflow_ac_setting_commands_variance_fields {
  fromACMode: Float
  fromACSetPoint: Float
  fromACStatus: Float
  fromFanSpeed: Float
  toACMode: Float
  toACSetPoint: Float
  toACStatus: Float
  toFanSpeed: Float
}

input sensorflow_ac_setting_commands_variance_order_by {
  fromACMode: order_by
  fromACSetPoint: order_by
  fromACStatus: order_by
  fromFanSpeed: order_by
  toACMode: order_by
  toACSetPoint: order_by
  toACStatus: order_by
  toFanSpeed: order_by
}

type sensorflow_ai_dt_ac_event_stream {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_ac_event_stream_aggregate {
  aggregate: sensorflow_ai_dt_ac_event_stream_aggregate_fields
  nodes: [sensorflow_ai_dt_ac_event_stream!]!
}

type sensorflow_ai_dt_ac_event_stream_aggregate_fields {
  count(columns: [sensorflow_ai_dt_ac_event_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_dt_ac_event_stream_max_fields
  min: sensorflow_ai_dt_ac_event_stream_min_fields
}

input sensorflow_ai_dt_ac_event_stream_bool_exp {
  _and: [sensorflow_ai_dt_ac_event_stream_bool_exp!]
  _not: sensorflow_ai_dt_ac_event_stream_bool_exp
  _or: [sensorflow_ai_dt_ac_event_stream_bool_exp!]
  ac_settings_type_changed: String_comparison_exp
  event_type: String_comparison_exp
  modified_by_user: String_comparison_exp
  new_ac_settings_value: String_comparison_exp
  previous_ac_settings_value: String_comparison_exp
  time: timestamptz_comparison_exp
  user_email: String_comparison_exp
}

input sensorflow_ai_dt_ac_event_stream_insert_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_ac_event_stream_max_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_ac_event_stream_min_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_ac_event_stream_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ai_dt_ac_event_stream!]!
}

input sensorflow_ai_dt_ac_event_stream_order_by {
  ac_settings_type_changed: order_by
  event_type: order_by
  modified_by_user: order_by
  new_ac_settings_value: order_by
  previous_ac_settings_value: order_by
  time: order_by
  user_email: order_by
}

enum sensorflow_ai_dt_ac_event_stream_select_column {
  ac_settings_type_changed
  event_type
  modified_by_user
  new_ac_settings_value
  previous_ac_settings_value
  time
  user_email
}

input sensorflow_ai_dt_ac_event_stream_set_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_ai_dt_ac_event_stream_stream_cursor_input {
  initial_value: sensorflow_ai_dt_ac_event_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_dt_ac_event_stream_stream_cursor_value_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_ai_dt_ac_event_stream_updates {
  _set: sensorflow_ai_dt_ac_event_stream_set_input
  where: sensorflow_ai_dt_ac_event_stream_bool_exp!
}

type sensorflow_ai_dt_config_event_stream {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_config_event_stream_aggregate {
  aggregate: sensorflow_ai_dt_config_event_stream_aggregate_fields
  nodes: [sensorflow_ai_dt_config_event_stream!]!
}

type sensorflow_ai_dt_config_event_stream_aggregate_fields {
  count(columns: [sensorflow_ai_dt_config_event_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_dt_config_event_stream_max_fields
  min: sensorflow_ai_dt_config_event_stream_min_fields
}

input sensorflow_ai_dt_config_event_stream_bool_exp {
  _and: [sensorflow_ai_dt_config_event_stream_bool_exp!]
  _not: sensorflow_ai_dt_config_event_stream_bool_exp
  _or: [sensorflow_ai_dt_config_event_stream_bool_exp!]
  config_type_changed: String_comparison_exp
  event_type: String_comparison_exp
  modified_by_user: String_comparison_exp
  new_config_value: String_comparison_exp
  previous_config_value: String_comparison_exp
  time: timestamptz_comparison_exp
  user_email: String_comparison_exp
}

input sensorflow_ai_dt_config_event_stream_insert_input {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_config_event_stream_max_fields {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_config_event_stream_min_fields {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_ai_dt_config_event_stream_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ai_dt_config_event_stream!]!
}

input sensorflow_ai_dt_config_event_stream_order_by {
  config_type_changed: order_by
  event_type: order_by
  modified_by_user: order_by
  new_config_value: order_by
  previous_config_value: order_by
  time: order_by
  user_email: order_by
}

enum sensorflow_ai_dt_config_event_stream_select_column {
  config_type_changed
  event_type
  modified_by_user
  new_config_value
  previous_config_value
  time
  user_email
}

input sensorflow_ai_dt_config_event_stream_set_input {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_ai_dt_config_event_stream_stream_cursor_input {
  initial_value: sensorflow_ai_dt_config_event_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_dt_config_event_stream_stream_cursor_value_input {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_ai_dt_config_event_stream_updates {
  _set: sensorflow_ai_dt_config_event_stream_set_input
  where: sensorflow_ai_dt_config_event_stream_bool_exp!
}

type sensorflow_ai_dt_event_stream {
  changed_by: String
  current_value: Int
  event_type: String
  has_changed: Int
  previous_value: Int
  slot_name: String
  src_mac_id: String
  time: timestamptz
  uuid: uuid
}

type sensorflow_ai_dt_event_stream_aggregate {
  aggregate: sensorflow_ai_dt_event_stream_aggregate_fields
  nodes: [sensorflow_ai_dt_event_stream!]!
}

type sensorflow_ai_dt_event_stream_aggregate_fields {
  avg: sensorflow_ai_dt_event_stream_avg_fields
  count(columns: [sensorflow_ai_dt_event_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_dt_event_stream_max_fields
  min: sensorflow_ai_dt_event_stream_min_fields
  stddev: sensorflow_ai_dt_event_stream_stddev_fields
  stddev_pop: sensorflow_ai_dt_event_stream_stddev_pop_fields
  stddev_samp: sensorflow_ai_dt_event_stream_stddev_samp_fields
  sum: sensorflow_ai_dt_event_stream_sum_fields
  var_pop: sensorflow_ai_dt_event_stream_var_pop_fields
  var_samp: sensorflow_ai_dt_event_stream_var_samp_fields
  variance: sensorflow_ai_dt_event_stream_variance_fields
}

type sensorflow_ai_dt_event_stream_avg_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

input sensorflow_ai_dt_event_stream_bool_exp {
  _and: [sensorflow_ai_dt_event_stream_bool_exp!]
  _not: sensorflow_ai_dt_event_stream_bool_exp
  _or: [sensorflow_ai_dt_event_stream_bool_exp!]
  changed_by: String_comparison_exp
  current_value: Int_comparison_exp
  event_type: String_comparison_exp
  has_changed: Int_comparison_exp
  previous_value: Int_comparison_exp
  slot_name: String_comparison_exp
  src_mac_id: String_comparison_exp
  time: timestamptz_comparison_exp
  uuid: uuid_comparison_exp
}

type sensorflow_ai_dt_event_stream_max_fields {
  changed_by: String
  current_value: Int
  event_type: String
  has_changed: Int
  previous_value: Int
  slot_name: String
  src_mac_id: String
  time: timestamptz
  uuid: uuid
}

type sensorflow_ai_dt_event_stream_min_fields {
  changed_by: String
  current_value: Int
  event_type: String
  has_changed: Int
  previous_value: Int
  slot_name: String
  src_mac_id: String
  time: timestamptz
  uuid: uuid
}

input sensorflow_ai_dt_event_stream_order_by {
  changed_by: order_by
  current_value: order_by
  event_type: order_by
  has_changed: order_by
  previous_value: order_by
  slot_name: order_by
  src_mac_id: order_by
  time: order_by
  uuid: order_by
}

enum sensorflow_ai_dt_event_stream_select_column {
  changed_by
  current_value
  event_type
  has_changed
  previous_value
  slot_name
  src_mac_id
  time
  uuid
}

type sensorflow_ai_dt_event_stream_stddev_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

type sensorflow_ai_dt_event_stream_stddev_pop_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

type sensorflow_ai_dt_event_stream_stddev_samp_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

input sensorflow_ai_dt_event_stream_stream_cursor_input {
  initial_value: sensorflow_ai_dt_event_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_dt_event_stream_stream_cursor_value_input {
  changed_by: String
  current_value: Int
  event_type: String
  has_changed: Int
  previous_value: Int
  slot_name: String
  src_mac_id: String
  time: timestamptz
  uuid: uuid
}

type sensorflow_ai_dt_event_stream_sum_fields {
  current_value: Int
  has_changed: Int
  previous_value: Int
}

type sensorflow_ai_dt_event_stream_var_pop_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

type sensorflow_ai_dt_event_stream_var_samp_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

type sensorflow_ai_dt_event_stream_variance_fields {
  current_value: Float
  has_changed: Float
  previous_value: Float
}

type sensorflow_ai_dt_room_comfort_report {
  average_humidity_when_cooling: numeric
  average_temperature_when_cooling: numeric
  daily_high_discomfort_hours: numeric
  daily_overcooling_hours: numeric
  daily_slight_discomfort_hours: numeric
  daily_steady_high_discomfort_hours: numeric
  daily_transient_discomfort_hours: numeric
  median_discomfort_percentage: numeric
  most_frequent_fan_speed: String
  most_frequent_mode: String
  predominant_setpoint: numeric
  total_days: numeric
  total_minutes_guest_chose_to_be_slightly_uncomfortable: numeric
  total_minutes_guest_chose_to_be_very_uncomfortable: numeric
  total_minutes_of_transient_discomfort_caused_by_sensorflow: numeric
  total_minutes_overcooling: numeric
  total_minutes_slightly_uncomfortable: numeric
  total_minutes_very_uncomfortable: numeric
}

type sensorflow_ai_dt_room_comfort_report_aggregate {
  aggregate: sensorflow_ai_dt_room_comfort_report_aggregate_fields
  nodes: [sensorflow_ai_dt_room_comfort_report!]!
}

type sensorflow_ai_dt_room_comfort_report_aggregate_fields {
  avg: sensorflow_ai_dt_room_comfort_report_avg_fields
  count(columns: [sensorflow_ai_dt_room_comfort_report_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_dt_room_comfort_report_max_fields
  min: sensorflow_ai_dt_room_comfort_report_min_fields
  stddev: sensorflow_ai_dt_room_comfort_report_stddev_fields
  stddev_pop: sensorflow_ai_dt_room_comfort_report_stddev_pop_fields
  stddev_samp: sensorflow_ai_dt_room_comfort_report_stddev_samp_fields
  sum: sensorflow_ai_dt_room_comfort_report_sum_fields
  var_pop: sensorflow_ai_dt_room_comfort_report_var_pop_fields
  var_samp: sensorflow_ai_dt_room_comfort_report_var_samp_fields
  variance: sensorflow_ai_dt_room_comfort_report_variance_fields
}

type sensorflow_ai_dt_room_comfort_report_avg_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

input sensorflow_ai_dt_room_comfort_report_bool_exp {
  _and: [sensorflow_ai_dt_room_comfort_report_bool_exp!]
  _not: sensorflow_ai_dt_room_comfort_report_bool_exp
  _or: [sensorflow_ai_dt_room_comfort_report_bool_exp!]
  average_humidity_when_cooling: numeric_comparison_exp
  average_temperature_when_cooling: numeric_comparison_exp
  daily_high_discomfort_hours: numeric_comparison_exp
  daily_overcooling_hours: numeric_comparison_exp
  daily_slight_discomfort_hours: numeric_comparison_exp
  daily_steady_high_discomfort_hours: numeric_comparison_exp
  daily_transient_discomfort_hours: numeric_comparison_exp
  median_discomfort_percentage: numeric_comparison_exp
  most_frequent_fan_speed: String_comparison_exp
  most_frequent_mode: String_comparison_exp
  predominant_setpoint: numeric_comparison_exp
  total_days: numeric_comparison_exp
  total_minutes_guest_chose_to_be_slightly_uncomfortable: numeric_comparison_exp
  total_minutes_guest_chose_to_be_very_uncomfortable: numeric_comparison_exp
  total_minutes_of_transient_discomfort_caused_by_sensorflow: numeric_comparison_exp
  total_minutes_overcooling: numeric_comparison_exp
  total_minutes_slightly_uncomfortable: numeric_comparison_exp
  total_minutes_very_uncomfortable: numeric_comparison_exp
}

type sensorflow_ai_dt_room_comfort_report_max_fields {
  average_humidity_when_cooling: numeric
  average_temperature_when_cooling: numeric
  daily_high_discomfort_hours: numeric
  daily_overcooling_hours: numeric
  daily_slight_discomfort_hours: numeric
  daily_steady_high_discomfort_hours: numeric
  daily_transient_discomfort_hours: numeric
  median_discomfort_percentage: numeric
  most_frequent_fan_speed: String
  most_frequent_mode: String
  predominant_setpoint: numeric
  total_days: numeric
  total_minutes_guest_chose_to_be_slightly_uncomfortable: numeric
  total_minutes_guest_chose_to_be_very_uncomfortable: numeric
  total_minutes_of_transient_discomfort_caused_by_sensorflow: numeric
  total_minutes_overcooling: numeric
  total_minutes_slightly_uncomfortable: numeric
  total_minutes_very_uncomfortable: numeric
}

type sensorflow_ai_dt_room_comfort_report_min_fields {
  average_humidity_when_cooling: numeric
  average_temperature_when_cooling: numeric
  daily_high_discomfort_hours: numeric
  daily_overcooling_hours: numeric
  daily_slight_discomfort_hours: numeric
  daily_steady_high_discomfort_hours: numeric
  daily_transient_discomfort_hours: numeric
  median_discomfort_percentage: numeric
  most_frequent_fan_speed: String
  most_frequent_mode: String
  predominant_setpoint: numeric
  total_days: numeric
  total_minutes_guest_chose_to_be_slightly_uncomfortable: numeric
  total_minutes_guest_chose_to_be_very_uncomfortable: numeric
  total_minutes_of_transient_discomfort_caused_by_sensorflow: numeric
  total_minutes_overcooling: numeric
  total_minutes_slightly_uncomfortable: numeric
  total_minutes_very_uncomfortable: numeric
}

input sensorflow_ai_dt_room_comfort_report_order_by {
  average_humidity_when_cooling: order_by
  average_temperature_when_cooling: order_by
  daily_high_discomfort_hours: order_by
  daily_overcooling_hours: order_by
  daily_slight_discomfort_hours: order_by
  daily_steady_high_discomfort_hours: order_by
  daily_transient_discomfort_hours: order_by
  median_discomfort_percentage: order_by
  most_frequent_fan_speed: order_by
  most_frequent_mode: order_by
  predominant_setpoint: order_by
  total_days: order_by
  total_minutes_guest_chose_to_be_slightly_uncomfortable: order_by
  total_minutes_guest_chose_to_be_very_uncomfortable: order_by
  total_minutes_of_transient_discomfort_caused_by_sensorflow: order_by
  total_minutes_overcooling: order_by
  total_minutes_slightly_uncomfortable: order_by
  total_minutes_very_uncomfortable: order_by
}

enum sensorflow_ai_dt_room_comfort_report_select_column {
  average_humidity_when_cooling
  average_temperature_when_cooling
  daily_high_discomfort_hours
  daily_overcooling_hours
  daily_slight_discomfort_hours
  daily_steady_high_discomfort_hours
  daily_transient_discomfort_hours
  median_discomfort_percentage
  most_frequent_fan_speed
  most_frequent_mode
  predominant_setpoint
  total_days
  total_minutes_guest_chose_to_be_slightly_uncomfortable
  total_minutes_guest_chose_to_be_very_uncomfortable
  total_minutes_of_transient_discomfort_caused_by_sensorflow
  total_minutes_overcooling
  total_minutes_slightly_uncomfortable
  total_minutes_very_uncomfortable
}

type sensorflow_ai_dt_room_comfort_report_stddev_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

type sensorflow_ai_dt_room_comfort_report_stddev_pop_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

type sensorflow_ai_dt_room_comfort_report_stddev_samp_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

input sensorflow_ai_dt_room_comfort_report_stream_cursor_input {
  initial_value: sensorflow_ai_dt_room_comfort_report_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_dt_room_comfort_report_stream_cursor_value_input {
  average_humidity_when_cooling: numeric
  average_temperature_when_cooling: numeric
  daily_high_discomfort_hours: numeric
  daily_overcooling_hours: numeric
  daily_slight_discomfort_hours: numeric
  daily_steady_high_discomfort_hours: numeric
  daily_transient_discomfort_hours: numeric
  median_discomfort_percentage: numeric
  most_frequent_fan_speed: String
  most_frequent_mode: String
  predominant_setpoint: numeric
  total_days: numeric
  total_minutes_guest_chose_to_be_slightly_uncomfortable: numeric
  total_minutes_guest_chose_to_be_very_uncomfortable: numeric
  total_minutes_of_transient_discomfort_caused_by_sensorflow: numeric
  total_minutes_overcooling: numeric
  total_minutes_slightly_uncomfortable: numeric
  total_minutes_very_uncomfortable: numeric
}

type sensorflow_ai_dt_room_comfort_report_sum_fields {
  average_humidity_when_cooling: numeric
  average_temperature_when_cooling: numeric
  daily_high_discomfort_hours: numeric
  daily_overcooling_hours: numeric
  daily_slight_discomfort_hours: numeric
  daily_steady_high_discomfort_hours: numeric
  daily_transient_discomfort_hours: numeric
  median_discomfort_percentage: numeric
  predominant_setpoint: numeric
  total_days: numeric
  total_minutes_guest_chose_to_be_slightly_uncomfortable: numeric
  total_minutes_guest_chose_to_be_very_uncomfortable: numeric
  total_minutes_of_transient_discomfort_caused_by_sensorflow: numeric
  total_minutes_overcooling: numeric
  total_minutes_slightly_uncomfortable: numeric
  total_minutes_very_uncomfortable: numeric
}

type sensorflow_ai_dt_room_comfort_report_var_pop_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

type sensorflow_ai_dt_room_comfort_report_var_samp_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

type sensorflow_ai_dt_room_comfort_report_variance_fields {
  average_humidity_when_cooling: Float
  average_temperature_when_cooling: Float
  daily_high_discomfort_hours: Float
  daily_overcooling_hours: Float
  daily_slight_discomfort_hours: Float
  daily_steady_high_discomfort_hours: Float
  daily_transient_discomfort_hours: Float
  median_discomfort_percentage: Float
  predominant_setpoint: Float
  total_days: Float
  total_minutes_guest_chose_to_be_slightly_uncomfortable: Float
  total_minutes_guest_chose_to_be_very_uncomfortable: Float
  total_minutes_of_transient_discomfort_caused_by_sensorflow: Float
  total_minutes_overcooling: Float
  total_minutes_slightly_uncomfortable: Float
  total_minutes_very_uncomfortable: Float
}

type sensorflow_ai_dt_synced_raw_data {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  automation_status: Int
  discomfort_index: numeric
  discomfort_percentage: numeric
  door_state: numeric
  fan_speed: numeric
  heat_index: numeric
  occupancy_state: numeric
  pmv: numeric
  position_id: uuid
  powered: numeric
  ppd: numeric
  room_comfort: String
  temperature_set: numeric
  timestamp: timestamptz
}

type sensorflow_ai_dt_synced_raw_data_aggregate {
  aggregate: sensorflow_ai_dt_synced_raw_data_aggregate_fields
  nodes: [sensorflow_ai_dt_synced_raw_data!]!
}

type sensorflow_ai_dt_synced_raw_data_aggregate_fields {
  avg: sensorflow_ai_dt_synced_raw_data_avg_fields
  count(columns: [sensorflow_ai_dt_synced_raw_data_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_dt_synced_raw_data_max_fields
  min: sensorflow_ai_dt_synced_raw_data_min_fields
  stddev: sensorflow_ai_dt_synced_raw_data_stddev_fields
  stddev_pop: sensorflow_ai_dt_synced_raw_data_stddev_pop_fields
  stddev_samp: sensorflow_ai_dt_synced_raw_data_stddev_samp_fields
  sum: sensorflow_ai_dt_synced_raw_data_sum_fields
  var_pop: sensorflow_ai_dt_synced_raw_data_var_pop_fields
  var_samp: sensorflow_ai_dt_synced_raw_data_var_samp_fields
  variance: sensorflow_ai_dt_synced_raw_data_variance_fields
}

type sensorflow_ai_dt_synced_raw_data_avg_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

input sensorflow_ai_dt_synced_raw_data_bool_exp {
  _and: [sensorflow_ai_dt_synced_raw_data_bool_exp!]
  _not: sensorflow_ai_dt_synced_raw_data_bool_exp
  _or: [sensorflow_ai_dt_synced_raw_data_bool_exp!]
  ac_mode: numeric_comparison_exp
  ambient_humidity: numeric_comparison_exp
  ambient_temperature: numeric_comparison_exp
  automation_status: Int_comparison_exp
  discomfort_index: numeric_comparison_exp
  discomfort_percentage: numeric_comparison_exp
  door_state: numeric_comparison_exp
  fan_speed: numeric_comparison_exp
  heat_index: numeric_comparison_exp
  occupancy_state: numeric_comparison_exp
  pmv: numeric_comparison_exp
  position_id: uuid_comparison_exp
  powered: numeric_comparison_exp
  ppd: numeric_comparison_exp
  room_comfort: String_comparison_exp
  temperature_set: numeric_comparison_exp
  timestamp: timestamptz_comparison_exp
}

type sensorflow_ai_dt_synced_raw_data_max_fields {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  automation_status: Int
  discomfort_index: numeric
  discomfort_percentage: numeric
  door_state: numeric
  fan_speed: numeric
  heat_index: numeric
  occupancy_state: numeric
  pmv: numeric
  position_id: uuid
  powered: numeric
  ppd: numeric
  room_comfort: String
  temperature_set: numeric
  timestamp: timestamptz
}

type sensorflow_ai_dt_synced_raw_data_min_fields {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  automation_status: Int
  discomfort_index: numeric
  discomfort_percentage: numeric
  door_state: numeric
  fan_speed: numeric
  heat_index: numeric
  occupancy_state: numeric
  pmv: numeric
  position_id: uuid
  powered: numeric
  ppd: numeric
  room_comfort: String
  temperature_set: numeric
  timestamp: timestamptz
}

input sensorflow_ai_dt_synced_raw_data_order_by {
  ac_mode: order_by
  ambient_humidity: order_by
  ambient_temperature: order_by
  automation_status: order_by
  discomfort_index: order_by
  discomfort_percentage: order_by
  door_state: order_by
  fan_speed: order_by
  heat_index: order_by
  occupancy_state: order_by
  pmv: order_by
  position_id: order_by
  powered: order_by
  ppd: order_by
  room_comfort: order_by
  temperature_set: order_by
  timestamp: order_by
}

enum sensorflow_ai_dt_synced_raw_data_select_column {
  ac_mode
  ambient_humidity
  ambient_temperature
  automation_status
  discomfort_index
  discomfort_percentage
  door_state
  fan_speed
  heat_index
  occupancy_state
  pmv
  position_id
  powered
  ppd
  room_comfort
  temperature_set
  timestamp
}

type sensorflow_ai_dt_synced_raw_data_stddev_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

type sensorflow_ai_dt_synced_raw_data_stddev_pop_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

type sensorflow_ai_dt_synced_raw_data_stddev_samp_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

input sensorflow_ai_dt_synced_raw_data_stream_cursor_input {
  initial_value: sensorflow_ai_dt_synced_raw_data_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_dt_synced_raw_data_stream_cursor_value_input {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  automation_status: Int
  discomfort_index: numeric
  discomfort_percentage: numeric
  door_state: numeric
  fan_speed: numeric
  heat_index: numeric
  occupancy_state: numeric
  pmv: numeric
  position_id: uuid
  powered: numeric
  ppd: numeric
  room_comfort: String
  temperature_set: numeric
  timestamp: timestamptz
}

type sensorflow_ai_dt_synced_raw_data_sum_fields {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  automation_status: Int
  discomfort_index: numeric
  discomfort_percentage: numeric
  door_state: numeric
  fan_speed: numeric
  heat_index: numeric
  occupancy_state: numeric
  pmv: numeric
  powered: numeric
  ppd: numeric
  temperature_set: numeric
}

type sensorflow_ai_dt_synced_raw_data_var_pop_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

type sensorflow_ai_dt_synced_raw_data_var_samp_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

type sensorflow_ai_dt_synced_raw_data_variance_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  automation_status: Float
  discomfort_index: Float
  discomfort_percentage: Float
  door_state: Float
  fan_speed: Float
  heat_index: Float
  occupancy_state: Float
  pmv: Float
  powered: Float
  ppd: Float
  temperature_set: Float
}

input sensorflow_ai_f_get_ac_command_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_ai_f_get_all_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_ai_f_get_config_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_ai_f_get_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_ai_f_get_id_info_args {
  hotel_name: String
  room_number: String
}

input sensorflow_ai_f_get_synced_raw_data_args {
  from_date: timestamptz
  intervals: String
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_ai_f_room_comfort_analysis_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_ai_f_send_ac_command_args {
  acmode: Int
  acsetpoint: Int
  acstatus: Int
  fanspeed: Int
  positionid: uuid
}

type sensorflow_ai_v_get_precool_command_stream {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamp
  user_email: String
}

type sensorflow_ai_v_get_precool_command_stream_aggregate {
  aggregate: sensorflow_ai_v_get_precool_command_stream_aggregate_fields
  nodes: [sensorflow_ai_v_get_precool_command_stream!]!
}

type sensorflow_ai_v_get_precool_command_stream_aggregate_fields {
  count(columns: [sensorflow_ai_v_get_precool_command_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_v_get_precool_command_stream_max_fields
  min: sensorflow_ai_v_get_precool_command_stream_min_fields
}

input sensorflow_ai_v_get_precool_command_stream_bool_exp {
  _and: [sensorflow_ai_v_get_precool_command_stream_bool_exp!]
  _not: sensorflow_ai_v_get_precool_command_stream_bool_exp
  _or: [sensorflow_ai_v_get_precool_command_stream_bool_exp!]
  ac_settings_type_changed: String_comparison_exp
  event_type: String_comparison_exp
  modified_by_user: String_comparison_exp
  new_ac_settings_value: String_comparison_exp
  position_id: uuid_comparison_exp
  previous_ac_settings_value: String_comparison_exp
  time: timestamp_comparison_exp
  user_email: String_comparison_exp
}

type sensorflow_ai_v_get_precool_command_stream_max_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamp
  user_email: String
}

type sensorflow_ai_v_get_precool_command_stream_min_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamp
  user_email: String
}

input sensorflow_ai_v_get_precool_command_stream_order_by {
  ac_settings_type_changed: order_by
  event_type: order_by
  modified_by_user: order_by
  new_ac_settings_value: order_by
  position_id: order_by
  previous_ac_settings_value: order_by
  time: order_by
  user_email: order_by
}

enum sensorflow_ai_v_get_precool_command_stream_select_column {
  ac_settings_type_changed
  event_type
  modified_by_user
  new_ac_settings_value
  position_id
  previous_ac_settings_value
  time
  user_email
}

input sensorflow_ai_v_get_precool_command_stream_stream_cursor_input {
  initial_value: sensorflow_ai_v_get_precool_command_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_v_get_precool_command_stream_stream_cursor_value_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamp
  user_email: String
}

type sensorflow_ai_v_room_status {
  AC_activity: String
  Fan_speed: String
  ac_data_status: String
  ambient_temperature: String
  automation_status: String
  hotel_name: String
  location_id: uuid
  no_automation_time: String
  occupancy: String
  occupancy_data_status: String
  overall_comfort: String
  room_comfort: String
  room_id: uuid
  room_number: String
  rooms_in_unit: bigint
  unoccupied_behaviour: String
}

type sensorflow_ai_v_room_status_aggregate {
  aggregate: sensorflow_ai_v_room_status_aggregate_fields
  nodes: [sensorflow_ai_v_room_status!]!
}

type sensorflow_ai_v_room_status_aggregate_fields {
  avg: sensorflow_ai_v_room_status_avg_fields
  count(columns: [sensorflow_ai_v_room_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_ai_v_room_status_max_fields
  min: sensorflow_ai_v_room_status_min_fields
  stddev: sensorflow_ai_v_room_status_stddev_fields
  stddev_pop: sensorflow_ai_v_room_status_stddev_pop_fields
  stddev_samp: sensorflow_ai_v_room_status_stddev_samp_fields
  sum: sensorflow_ai_v_room_status_sum_fields
  var_pop: sensorflow_ai_v_room_status_var_pop_fields
  var_samp: sensorflow_ai_v_room_status_var_samp_fields
  variance: sensorflow_ai_v_room_status_variance_fields
}

type sensorflow_ai_v_room_status_avg_fields {
  rooms_in_unit: Float
}

input sensorflow_ai_v_room_status_bool_exp {
  AC_activity: String_comparison_exp
  Fan_speed: String_comparison_exp
  _and: [sensorflow_ai_v_room_status_bool_exp!]
  _not: sensorflow_ai_v_room_status_bool_exp
  _or: [sensorflow_ai_v_room_status_bool_exp!]
  ac_data_status: String_comparison_exp
  ambient_temperature: String_comparison_exp
  automation_status: String_comparison_exp
  hotel_name: String_comparison_exp
  location_id: uuid_comparison_exp
  no_automation_time: String_comparison_exp
  occupancy: String_comparison_exp
  occupancy_data_status: String_comparison_exp
  overall_comfort: String_comparison_exp
  room_comfort: String_comparison_exp
  room_id: uuid_comparison_exp
  room_number: String_comparison_exp
  rooms_in_unit: bigint_comparison_exp
  unoccupied_behaviour: String_comparison_exp
}

type sensorflow_ai_v_room_status_max_fields {
  AC_activity: String
  Fan_speed: String
  ac_data_status: String
  ambient_temperature: String
  automation_status: String
  hotel_name: String
  location_id: uuid
  no_automation_time: String
  occupancy: String
  occupancy_data_status: String
  overall_comfort: String
  room_comfort: String
  room_id: uuid
  room_number: String
  rooms_in_unit: bigint
  unoccupied_behaviour: String
}

type sensorflow_ai_v_room_status_min_fields {
  AC_activity: String
  Fan_speed: String
  ac_data_status: String
  ambient_temperature: String
  automation_status: String
  hotel_name: String
  location_id: uuid
  no_automation_time: String
  occupancy: String
  occupancy_data_status: String
  overall_comfort: String
  room_comfort: String
  room_id: uuid
  room_number: String
  rooms_in_unit: bigint
  unoccupied_behaviour: String
}

input sensorflow_ai_v_room_status_order_by {
  AC_activity: order_by
  Fan_speed: order_by
  ac_data_status: order_by
  ambient_temperature: order_by
  automation_status: order_by
  hotel_name: order_by
  location_id: order_by
  no_automation_time: order_by
  occupancy: order_by
  occupancy_data_status: order_by
  overall_comfort: order_by
  room_comfort: order_by
  room_id: order_by
  room_number: order_by
  rooms_in_unit: order_by
  unoccupied_behaviour: order_by
}

enum sensorflow_ai_v_room_status_select_column {
  AC_activity
  Fan_speed
  ac_data_status
  ambient_temperature
  automation_status
  hotel_name
  location_id
  no_automation_time
  occupancy
  occupancy_data_status
  overall_comfort
  room_comfort
  room_id
  room_number
  rooms_in_unit
  unoccupied_behaviour
}

type sensorflow_ai_v_room_status_stddev_fields {
  rooms_in_unit: Float
}

type sensorflow_ai_v_room_status_stddev_pop_fields {
  rooms_in_unit: Float
}

type sensorflow_ai_v_room_status_stddev_samp_fields {
  rooms_in_unit: Float
}

input sensorflow_ai_v_room_status_stream_cursor_input {
  initial_value: sensorflow_ai_v_room_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ai_v_room_status_stream_cursor_value_input {
  AC_activity: String
  Fan_speed: String
  ac_data_status: String
  ambient_temperature: String
  automation_status: String
  hotel_name: String
  location_id: uuid
  no_automation_time: String
  occupancy: String
  occupancy_data_status: String
  overall_comfort: String
  room_comfort: String
  room_id: uuid
  room_number: String
  rooms_in_unit: bigint
  unoccupied_behaviour: String
}

type sensorflow_ai_v_room_status_sum_fields {
  rooms_in_unit: bigint
}

type sensorflow_ai_v_room_status_var_pop_fields {
  rooms_in_unit: Float
}

type sensorflow_ai_v_room_status_var_samp_fields {
  rooms_in_unit: Float
}

type sensorflow_ai_v_room_status_variance_fields {
  rooms_in_unit: Float
}

type sensorflow_autosets_with_keys {
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  position_id: uuid
  position_name: String
}

type sensorflow_autosets_with_keys_aggregate {
  aggregate: sensorflow_autosets_with_keys_aggregate_fields
  nodes: [sensorflow_autosets_with_keys!]!
}

type sensorflow_autosets_with_keys_aggregate_fields {
  count(columns: [sensorflow_autosets_with_keys_select_column!], distinct: Boolean): Int!
  max: sensorflow_autosets_with_keys_max_fields
  min: sensorflow_autosets_with_keys_min_fields
}

input sensorflow_autosets_with_keys_bool_exp {
  _and: [sensorflow_autosets_with_keys_bool_exp!]
  _not: sensorflow_autosets_with_keys_bool_exp
  _or: [sensorflow_autosets_with_keys_bool_exp!]
  key: String_comparison_exp
  key_id: uuid_comparison_exp
  location_id: uuid_comparison_exp
  location_name: String_comparison_exp
  position_id: uuid_comparison_exp
  position_name: String_comparison_exp
}

type sensorflow_autosets_with_keys_max_fields {
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  position_id: uuid
  position_name: String
}

type sensorflow_autosets_with_keys_min_fields {
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  position_id: uuid
  position_name: String
}

input sensorflow_autosets_with_keys_order_by {
  key: order_by
  key_id: order_by
  location_id: order_by
  location_name: order_by
  position_id: order_by
  position_name: order_by
}

enum sensorflow_autosets_with_keys_select_column {
  key
  key_id
  location_id
  location_name
  position_id
  position_name
}

input sensorflow_autosets_with_keys_stream_cursor_input {
  initial_value: sensorflow_autosets_with_keys_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_autosets_with_keys_stream_cursor_value_input {
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  position_id: uuid
  position_name: String
}

type sensorflow_beacon_slots {
  beaconFreqIndex: Int!
  enableSlotGap: Boolean!
  id: uuid!
  location: sensorflow_locations
  locationId: uuid
  maxSlots: Int!
  startingSlot: Int!
}

type sensorflow_beacon_slots_aggregate {
  aggregate: sensorflow_beacon_slots_aggregate_fields
  nodes: [sensorflow_beacon_slots!]!
}

type sensorflow_beacon_slots_aggregate_fields {
  avg: sensorflow_beacon_slots_avg_fields
  count(columns: [sensorflow_beacon_slots_select_column!], distinct: Boolean): Int!
  max: sensorflow_beacon_slots_max_fields
  min: sensorflow_beacon_slots_min_fields
  stddev: sensorflow_beacon_slots_stddev_fields
  stddev_pop: sensorflow_beacon_slots_stddev_pop_fields
  stddev_samp: sensorflow_beacon_slots_stddev_samp_fields
  sum: sensorflow_beacon_slots_sum_fields
  var_pop: sensorflow_beacon_slots_var_pop_fields
  var_samp: sensorflow_beacon_slots_var_samp_fields
  variance: sensorflow_beacon_slots_variance_fields
}

type sensorflow_beacon_slots_avg_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

input sensorflow_beacon_slots_bool_exp {
  _and: [sensorflow_beacon_slots_bool_exp!]
  _not: sensorflow_beacon_slots_bool_exp
  _or: [sensorflow_beacon_slots_bool_exp!]
  beaconFreqIndex: Int_comparison_exp
  enableSlotGap: Boolean_comparison_exp
  id: uuid_comparison_exp
  location: sensorflow_locations_bool_exp
  locationId: uuid_comparison_exp
  maxSlots: Int_comparison_exp
  startingSlot: Int_comparison_exp
}

enum sensorflow_beacon_slots_constraint {
  beacon_slots_pkey
}

input sensorflow_beacon_slots_inc_input {
  beaconFreqIndex: Int
  maxSlots: Int
  startingSlot: Int
}

input sensorflow_beacon_slots_insert_input {
  beaconFreqIndex: Int
  enableSlotGap: Boolean
  id: uuid
  location: sensorflow_locations_obj_rel_insert_input
  locationId: uuid
  maxSlots: Int
  startingSlot: Int
}

type sensorflow_beacon_slots_max_fields {
  beaconFreqIndex: Int
  id: uuid
  locationId: uuid
  maxSlots: Int
  startingSlot: Int
}

type sensorflow_beacon_slots_min_fields {
  beaconFreqIndex: Int
  id: uuid
  locationId: uuid
  maxSlots: Int
  startingSlot: Int
}

type sensorflow_beacon_slots_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_beacon_slots!]!
}

input sensorflow_beacon_slots_on_conflict {
  constraint: sensorflow_beacon_slots_constraint!
  update_columns: [sensorflow_beacon_slots_update_column!]! = []
  where: sensorflow_beacon_slots_bool_exp
}

input sensorflow_beacon_slots_order_by {
  beaconFreqIndex: order_by
  enableSlotGap: order_by
  id: order_by
  location: sensorflow_locations_order_by
  locationId: order_by
  maxSlots: order_by
  startingSlot: order_by
}

input sensorflow_beacon_slots_pk_columns_input {
  id: uuid!
}

enum sensorflow_beacon_slots_select_column {
  beaconFreqIndex
  enableSlotGap
  id
  locationId
  maxSlots
  startingSlot
}

input sensorflow_beacon_slots_set_input {
  beaconFreqIndex: Int
  enableSlotGap: Boolean
  id: uuid
  locationId: uuid
  maxSlots: Int
  startingSlot: Int
}

type sensorflow_beacon_slots_stddev_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

type sensorflow_beacon_slots_stddev_pop_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

type sensorflow_beacon_slots_stddev_samp_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

input sensorflow_beacon_slots_stream_cursor_input {
  initial_value: sensorflow_beacon_slots_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_beacon_slots_stream_cursor_value_input {
  beaconFreqIndex: Int
  enableSlotGap: Boolean
  id: uuid
  locationId: uuid
  maxSlots: Int
  startingSlot: Int
}

type sensorflow_beacon_slots_sum_fields {
  beaconFreqIndex: Int
  maxSlots: Int
  startingSlot: Int
}

enum sensorflow_beacon_slots_update_column {
  beaconFreqIndex
  enableSlotGap
  id
  locationId
  maxSlots
  startingSlot
}

input sensorflow_beacon_slots_updates {
  _inc: sensorflow_beacon_slots_inc_input
  _set: sensorflow_beacon_slots_set_input
  where: sensorflow_beacon_slots_bool_exp!
}

type sensorflow_beacon_slots_var_pop_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

type sensorflow_beacon_slots_var_samp_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

type sensorflow_beacon_slots_variance_fields {
  beaconFreqIndex: Float
  maxSlots: Float
  startingSlot: Float
}

type sensorflow_checklist_items {
  checklist_id: String!
  clickup_id: String!
  done: Boolean!
  title: String!
}

type sensorflow_checklist_items_aggregate {
  aggregate: sensorflow_checklist_items_aggregate_fields
  nodes: [sensorflow_checklist_items!]!
}

type sensorflow_checklist_items_aggregate_fields {
  count(columns: [sensorflow_checklist_items_select_column!], distinct: Boolean): Int!
  max: sensorflow_checklist_items_max_fields
  min: sensorflow_checklist_items_min_fields
}

input sensorflow_checklist_items_bool_exp {
  _and: [sensorflow_checklist_items_bool_exp!]
  _not: sensorflow_checklist_items_bool_exp
  _or: [sensorflow_checklist_items_bool_exp!]
  checklist_id: String_comparison_exp
  clickup_id: String_comparison_exp
  done: Boolean_comparison_exp
  title: String_comparison_exp
}

input sensorflow_checklist_items_insert_input {
  checklist_id: String
  clickup_id: String
  done: Boolean
  title: String
}

type sensorflow_checklist_items_max_fields {
  checklist_id: String
  clickup_id: String
  title: String
}

type sensorflow_checklist_items_min_fields {
  checklist_id: String
  clickup_id: String
  title: String
}

type sensorflow_checklist_items_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_checklist_items!]!
}

input sensorflow_checklist_items_order_by {
  checklist_id: order_by
  clickup_id: order_by
  done: order_by
  title: order_by
}

enum sensorflow_checklist_items_select_column {
  checklist_id
  clickup_id
  done
  title
}

input sensorflow_checklist_items_set_input {
  checklist_id: String
  clickup_id: String
  done: Boolean
  title: String
}

input sensorflow_checklist_items_stream_cursor_input {
  initial_value: sensorflow_checklist_items_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_checklist_items_stream_cursor_value_input {
  checklist_id: String
  clickup_id: String
  done: Boolean
  title: String
}

input sensorflow_checklist_items_updates {
  _set: sensorflow_checklist_items_set_input
  where: sensorflow_checklist_items_bool_exp!
}

type sensorflow_checklists {
  clickup_id: String!
  id: String!
  name: String!
  task_id: uuid!
}

type sensorflow_checklists_aggregate {
  aggregate: sensorflow_checklists_aggregate_fields
  nodes: [sensorflow_checklists!]!
}

type sensorflow_checklists_aggregate_fields {
  count(columns: [sensorflow_checklists_select_column!], distinct: Boolean): Int!
  max: sensorflow_checklists_max_fields
  min: sensorflow_checklists_min_fields
}

input sensorflow_checklists_bool_exp {
  _and: [sensorflow_checklists_bool_exp!]
  _not: sensorflow_checklists_bool_exp
  _or: [sensorflow_checklists_bool_exp!]
  clickup_id: String_comparison_exp
  id: String_comparison_exp
  name: String_comparison_exp
  task_id: uuid_comparison_exp
}

enum sensorflow_checklists_constraint {
  checklists_pkey
}

input sensorflow_checklists_insert_input {
  clickup_id: String
  id: String
  name: String
  task_id: uuid
}

type sensorflow_checklists_max_fields {
  clickup_id: String
  id: String
  name: String
  task_id: uuid
}

type sensorflow_checklists_min_fields {
  clickup_id: String
  id: String
  name: String
  task_id: uuid
}

type sensorflow_checklists_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_checklists!]!
}

input sensorflow_checklists_on_conflict {
  constraint: sensorflow_checklists_constraint!
  update_columns: [sensorflow_checklists_update_column!]! = []
  where: sensorflow_checklists_bool_exp
}

input sensorflow_checklists_order_by {
  clickup_id: order_by
  id: order_by
  name: order_by
  task_id: order_by
}

input sensorflow_checklists_pk_columns_input {
  id: String!
}

enum sensorflow_checklists_select_column {
  clickup_id
  id
  name
  task_id
}

input sensorflow_checklists_set_input {
  clickup_id: String
  id: String
  name: String
  task_id: uuid
}

input sensorflow_checklists_stream_cursor_input {
  initial_value: sensorflow_checklists_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_checklists_stream_cursor_value_input {
  clickup_id: String
  id: String
  name: String
  task_id: uuid
}

enum sensorflow_checklists_update_column {
  clickup_id
  id
  name
  task_id
}

input sensorflow_checklists_updates {
  _set: sensorflow_checklists_set_input
  where: sensorflow_checklists_bool_exp!
}

type sensorflow_chillers {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid!
  meterPosition: sensorflow_positions
  meterPositionId: uuid
  name: String
  numPhases: Int!
  phaseStreamIndex: Int
  position: sensorflow_positions!
  positionId: uuid!
}

type sensorflow_chillers_aggregate {
  aggregate: sensorflow_chillers_aggregate_fields
  nodes: [sensorflow_chillers!]!
}

type sensorflow_chillers_aggregate_fields {
  avg: sensorflow_chillers_avg_fields
  count(columns: [sensorflow_chillers_select_column!], distinct: Boolean): Int!
  max: sensorflow_chillers_max_fields
  min: sensorflow_chillers_min_fields
  stddev: sensorflow_chillers_stddev_fields
  stddev_pop: sensorflow_chillers_stddev_pop_fields
  stddev_samp: sensorflow_chillers_stddev_samp_fields
  sum: sensorflow_chillers_sum_fields
  var_pop: sensorflow_chillers_var_pop_fields
  var_samp: sensorflow_chillers_var_samp_fields
  variance: sensorflow_chillers_variance_fields
}

type sensorflow_chillers_avg_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_chillers_bool_exp {
  _and: [sensorflow_chillers_bool_exp!]
  _not: sensorflow_chillers_bool_exp
  _or: [sensorflow_chillers_bool_exp!]
  compressorId: uuid_comparison_exp
  ctMultiplier: numeric_comparison_exp
  deleted_at: timestamptz_comparison_exp
  id: uuid_comparison_exp
  meterPosition: sensorflow_positions_bool_exp
  meterPositionId: uuid_comparison_exp
  name: String_comparison_exp
  numPhases: Int_comparison_exp
  phaseStreamIndex: Int_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

enum sensorflow_chillers_constraint {
  chillers_pkey
}

input sensorflow_chillers_inc_input {
  ctMultiplier: numeric
  numPhases: Int
  phaseStreamIndex: Int
}

input sensorflow_chillers_insert_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPosition: sensorflow_positions_obj_rel_insert_input
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_chillers_max_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_chillers_min_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_chillers_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_chillers!]!
}

input sensorflow_chillers_on_conflict {
  constraint: sensorflow_chillers_constraint!
  update_columns: [sensorflow_chillers_update_column!]! = []
  where: sensorflow_chillers_bool_exp
}

input sensorflow_chillers_order_by {
  compressorId: order_by
  ctMultiplier: order_by
  deleted_at: order_by
  id: order_by
  meterPosition: sensorflow_positions_order_by
  meterPositionId: order_by
  name: order_by
  numPhases: order_by
  phaseStreamIndex: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

input sensorflow_chillers_pk_columns_input {
  id: uuid!
}

enum sensorflow_chillers_select_column {
  compressorId
  ctMultiplier
  deleted_at
  id
  meterPositionId
  name
  numPhases
  phaseStreamIndex
  positionId
}

input sensorflow_chillers_set_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_chillers_stddev_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_chillers_stddev_pop_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_chillers_stddev_samp_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_chillers_stream_cursor_input {
  initial_value: sensorflow_chillers_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_chillers_stream_cursor_value_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_chillers_sum_fields {
  ctMultiplier: numeric
  numPhases: Int
  phaseStreamIndex: Int
}

enum sensorflow_chillers_update_column {
  compressorId
  ctMultiplier
  deleted_at
  id
  meterPositionId
  name
  numPhases
  phaseStreamIndex
  positionId
}

input sensorflow_chillers_updates {
  _inc: sensorflow_chillers_inc_input
  _set: sensorflow_chillers_set_input
  where: sensorflow_chillers_bool_exp!
}

type sensorflow_chillers_var_pop_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_chillers_var_samp_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_chillers_variance_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_clickup_webhook_events {
  being_processed: Boolean!
  current_retry_count: Int!
  event_id: bigint
  event_seq_no: Int
  id: uuid!
  list_id: String
  processed_at: timestamptz
  received_at: timestamptz!
  req_payload(path: String): json!
  status: String!
  task_id: String
}

type sensorflow_clickup_webhook_events_aggregate {
  aggregate: sensorflow_clickup_webhook_events_aggregate_fields
  nodes: [sensorflow_clickup_webhook_events!]!
}

type sensorflow_clickup_webhook_events_aggregate_fields {
  avg: sensorflow_clickup_webhook_events_avg_fields
  count(columns: [sensorflow_clickup_webhook_events_select_column!], distinct: Boolean): Int!
  max: sensorflow_clickup_webhook_events_max_fields
  min: sensorflow_clickup_webhook_events_min_fields
  stddev: sensorflow_clickup_webhook_events_stddev_fields
  stddev_pop: sensorflow_clickup_webhook_events_stddev_pop_fields
  stddev_samp: sensorflow_clickup_webhook_events_stddev_samp_fields
  sum: sensorflow_clickup_webhook_events_sum_fields
  var_pop: sensorflow_clickup_webhook_events_var_pop_fields
  var_samp: sensorflow_clickup_webhook_events_var_samp_fields
  variance: sensorflow_clickup_webhook_events_variance_fields
}

type sensorflow_clickup_webhook_events_avg_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

input sensorflow_clickup_webhook_events_bool_exp {
  _and: [sensorflow_clickup_webhook_events_bool_exp!]
  _not: sensorflow_clickup_webhook_events_bool_exp
  _or: [sensorflow_clickup_webhook_events_bool_exp!]
  being_processed: Boolean_comparison_exp
  current_retry_count: Int_comparison_exp
  event_id: bigint_comparison_exp
  event_seq_no: Int_comparison_exp
  id: uuid_comparison_exp
  list_id: String_comparison_exp
  processed_at: timestamptz_comparison_exp
  received_at: timestamptz_comparison_exp
  req_payload: json_comparison_exp
  status: String_comparison_exp
  task_id: String_comparison_exp
}

enum sensorflow_clickup_webhook_events_constraint {
  clickup_webhook_events_event_id_key
  clickup_webhook_events_pkey
}

input sensorflow_clickup_webhook_events_inc_input {
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
}

input sensorflow_clickup_webhook_events_insert_input {
  being_processed: Boolean
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
  id: uuid
  list_id: String
  processed_at: timestamptz
  received_at: timestamptz
  req_payload: json
  status: String
  task_id: String
}

type sensorflow_clickup_webhook_events_max_fields {
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
  id: uuid
  list_id: String
  processed_at: timestamptz
  received_at: timestamptz
  status: String
  task_id: String
}

type sensorflow_clickup_webhook_events_min_fields {
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
  id: uuid
  list_id: String
  processed_at: timestamptz
  received_at: timestamptz
  status: String
  task_id: String
}

type sensorflow_clickup_webhook_events_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_clickup_webhook_events!]!
}

input sensorflow_clickup_webhook_events_on_conflict {
  constraint: sensorflow_clickup_webhook_events_constraint!
  update_columns: [sensorflow_clickup_webhook_events_update_column!]! = []
  where: sensorflow_clickup_webhook_events_bool_exp
}

input sensorflow_clickup_webhook_events_order_by {
  being_processed: order_by
  current_retry_count: order_by
  event_id: order_by
  event_seq_no: order_by
  id: order_by
  list_id: order_by
  processed_at: order_by
  received_at: order_by
  req_payload: order_by
  status: order_by
  task_id: order_by
}

input sensorflow_clickup_webhook_events_pk_columns_input {
  id: uuid!
}

enum sensorflow_clickup_webhook_events_select_column {
  being_processed
  current_retry_count
  event_id
  event_seq_no
  id
  list_id
  processed_at
  received_at
  req_payload
  status
  task_id
}

input sensorflow_clickup_webhook_events_set_input {
  being_processed: Boolean
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
  id: uuid
  list_id: String
  processed_at: timestamptz
  received_at: timestamptz
  req_payload: json
  status: String
  task_id: String
}

type sensorflow_clickup_webhook_events_stddev_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

type sensorflow_clickup_webhook_events_stddev_pop_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

type sensorflow_clickup_webhook_events_stddev_samp_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

input sensorflow_clickup_webhook_events_stream_cursor_input {
  initial_value: sensorflow_clickup_webhook_events_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_clickup_webhook_events_stream_cursor_value_input {
  being_processed: Boolean
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
  id: uuid
  list_id: String
  processed_at: timestamptz
  received_at: timestamptz
  req_payload: json
  status: String
  task_id: String
}

type sensorflow_clickup_webhook_events_sum_fields {
  current_retry_count: Int
  event_id: bigint
  event_seq_no: Int
}

enum sensorflow_clickup_webhook_events_update_column {
  being_processed
  current_retry_count
  event_id
  event_seq_no
  id
  list_id
  processed_at
  received_at
  req_payload
  status
  task_id
}

input sensorflow_clickup_webhook_events_updates {
  _inc: sensorflow_clickup_webhook_events_inc_input
  _set: sensorflow_clickup_webhook_events_set_input
  where: sensorflow_clickup_webhook_events_bool_exp!
}

type sensorflow_clickup_webhook_events_var_pop_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

type sensorflow_clickup_webhook_events_var_samp_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

type sensorflow_clickup_webhook_events_variance_fields {
  current_retry_count: Float
  event_id: Float
  event_seq_no: Float
}

type sensorflow_comfortplus_activations {
  dailyPrecoolLimitResetAt: timestamptz
  id: uuid!
  precoolDailyLimit: numeric!
  reservation: sensorflow_reservations!
  reservationId: bigint!
}

type sensorflow_comfortplus_activations_aggregate {
  aggregate: sensorflow_comfortplus_activations_aggregate_fields
  nodes: [sensorflow_comfortplus_activations!]!
}

input sensorflow_comfortplus_activations_aggregate_bool_exp {
  count: sensorflow_comfortplus_activations_aggregate_bool_exp_count
}

input sensorflow_comfortplus_activations_aggregate_bool_exp_count {
  arguments: [sensorflow_comfortplus_activations_select_column!]
  distinct: Boolean
  filter: sensorflow_comfortplus_activations_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_comfortplus_activations_aggregate_fields {
  avg: sensorflow_comfortplus_activations_avg_fields
  count(columns: [sensorflow_comfortplus_activations_select_column!], distinct: Boolean): Int!
  max: sensorflow_comfortplus_activations_max_fields
  min: sensorflow_comfortplus_activations_min_fields
  stddev: sensorflow_comfortplus_activations_stddev_fields
  stddev_pop: sensorflow_comfortplus_activations_stddev_pop_fields
  stddev_samp: sensorflow_comfortplus_activations_stddev_samp_fields
  sum: sensorflow_comfortplus_activations_sum_fields
  var_pop: sensorflow_comfortplus_activations_var_pop_fields
  var_samp: sensorflow_comfortplus_activations_var_samp_fields
  variance: sensorflow_comfortplus_activations_variance_fields
}

input sensorflow_comfortplus_activations_aggregate_order_by {
  avg: sensorflow_comfortplus_activations_avg_order_by
  count: order_by
  max: sensorflow_comfortplus_activations_max_order_by
  min: sensorflow_comfortplus_activations_min_order_by
  stddev: sensorflow_comfortplus_activations_stddev_order_by
  stddev_pop: sensorflow_comfortplus_activations_stddev_pop_order_by
  stddev_samp: sensorflow_comfortplus_activations_stddev_samp_order_by
  sum: sensorflow_comfortplus_activations_sum_order_by
  var_pop: sensorflow_comfortplus_activations_var_pop_order_by
  var_samp: sensorflow_comfortplus_activations_var_samp_order_by
  variance: sensorflow_comfortplus_activations_variance_order_by
}

input sensorflow_comfortplus_activations_arr_rel_insert_input {
  data: [sensorflow_comfortplus_activations_insert_input!]!
  on_conflict: sensorflow_comfortplus_activations_on_conflict
}

type sensorflow_comfortplus_activations_avg_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_avg_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

input sensorflow_comfortplus_activations_bool_exp {
  _and: [sensorflow_comfortplus_activations_bool_exp!]
  _not: sensorflow_comfortplus_activations_bool_exp
  _or: [sensorflow_comfortplus_activations_bool_exp!]
  dailyPrecoolLimitResetAt: timestamptz_comparison_exp
  id: uuid_comparison_exp
  precoolDailyLimit: numeric_comparison_exp
  reservation: sensorflow_reservations_bool_exp
  reservationId: bigint_comparison_exp
}

enum sensorflow_comfortplus_activations_constraint {
  comfortplus_activations_pkey
  comfortplus_activations_reservation_id_key
}

input sensorflow_comfortplus_activations_inc_input {
  precoolDailyLimit: numeric
  reservationId: bigint
}

input sensorflow_comfortplus_activations_insert_input {
  dailyPrecoolLimitResetAt: timestamptz
  id: uuid
  precoolDailyLimit: numeric
  reservation: sensorflow_reservations_obj_rel_insert_input
  reservationId: bigint
}

type sensorflow_comfortplus_activations_max_fields {
  dailyPrecoolLimitResetAt: timestamptz
  id: uuid
  precoolDailyLimit: numeric
  reservationId: bigint
}

input sensorflow_comfortplus_activations_max_order_by {
  dailyPrecoolLimitResetAt: order_by
  id: order_by
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_comfortplus_activations_min_fields {
  dailyPrecoolLimitResetAt: timestamptz
  id: uuid
  precoolDailyLimit: numeric
  reservationId: bigint
}

input sensorflow_comfortplus_activations_min_order_by {
  dailyPrecoolLimitResetAt: order_by
  id: order_by
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_comfortplus_activations_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_comfortplus_activations!]!
}

input sensorflow_comfortplus_activations_on_conflict {
  constraint: sensorflow_comfortplus_activations_constraint!
  update_columns: [sensorflow_comfortplus_activations_update_column!]! = []
  where: sensorflow_comfortplus_activations_bool_exp
}

input sensorflow_comfortplus_activations_order_by {
  dailyPrecoolLimitResetAt: order_by
  id: order_by
  precoolDailyLimit: order_by
  reservation: sensorflow_reservations_order_by
  reservationId: order_by
}

input sensorflow_comfortplus_activations_pk_columns_input {
  id: uuid!
}

enum sensorflow_comfortplus_activations_select_column {
  dailyPrecoolLimitResetAt
  id
  precoolDailyLimit
  reservationId
}

input sensorflow_comfortplus_activations_set_input {
  dailyPrecoolLimitResetAt: timestamptz
  id: uuid
  precoolDailyLimit: numeric
  reservationId: bigint
}

type sensorflow_comfortplus_activations_stddev_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_stddev_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_comfortplus_activations_stddev_pop_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_stddev_pop_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_comfortplus_activations_stddev_samp_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_stddev_samp_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

input sensorflow_comfortplus_activations_stream_cursor_input {
  initial_value: sensorflow_comfortplus_activations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_comfortplus_activations_stream_cursor_value_input {
  dailyPrecoolLimitResetAt: timestamptz
  id: uuid
  precoolDailyLimit: numeric
  reservationId: bigint
}

type sensorflow_comfortplus_activations_sum_fields {
  precoolDailyLimit: numeric
  reservationId: bigint
}

input sensorflow_comfortplus_activations_sum_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

enum sensorflow_comfortplus_activations_update_column {
  dailyPrecoolLimitResetAt
  id
  precoolDailyLimit
  reservationId
}

input sensorflow_comfortplus_activations_updates {
  _inc: sensorflow_comfortplus_activations_inc_input
  _set: sensorflow_comfortplus_activations_set_input
  where: sensorflow_comfortplus_activations_bool_exp!
}

type sensorflow_comfortplus_activations_var_pop_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_var_pop_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_comfortplus_activations_var_samp_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_var_samp_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_comfortplus_activations_variance_fields {
  precoolDailyLimit: Float
  reservationId: Float
}

input sensorflow_comfortplus_activations_variance_order_by {
  precoolDailyLimit: order_by
  reservationId: order_by
}

type sensorflow_compressor_mapping {
  compressor: sensorflow_compressors!
  compressorId: uuid!
  deleted_at: timestamptz
  position: sensorflow_positions!
  positionId: uuid!
}

type sensorflow_compressor_mapping_aggregate {
  aggregate: sensorflow_compressor_mapping_aggregate_fields
  nodes: [sensorflow_compressor_mapping!]!
}

input sensorflow_compressor_mapping_aggregate_bool_exp {
  count: sensorflow_compressor_mapping_aggregate_bool_exp_count
}

input sensorflow_compressor_mapping_aggregate_bool_exp_count {
  arguments: [sensorflow_compressor_mapping_select_column!]
  distinct: Boolean
  filter: sensorflow_compressor_mapping_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_compressor_mapping_aggregate_fields {
  count(columns: [sensorflow_compressor_mapping_select_column!], distinct: Boolean): Int!
  max: sensorflow_compressor_mapping_max_fields
  min: sensorflow_compressor_mapping_min_fields
}

input sensorflow_compressor_mapping_aggregate_order_by {
  count: order_by
  max: sensorflow_compressor_mapping_max_order_by
  min: sensorflow_compressor_mapping_min_order_by
}

input sensorflow_compressor_mapping_arr_rel_insert_input {
  data: [sensorflow_compressor_mapping_insert_input!]!
  on_conflict: sensorflow_compressor_mapping_on_conflict
}

input sensorflow_compressor_mapping_bool_exp {
  _and: [sensorflow_compressor_mapping_bool_exp!]
  _not: sensorflow_compressor_mapping_bool_exp
  _or: [sensorflow_compressor_mapping_bool_exp!]
  compressor: sensorflow_compressors_bool_exp
  compressorId: uuid_comparison_exp
  deleted_at: timestamptz_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

enum sensorflow_compressor_mapping_constraint {
  compressor_mapping_pkey
}

input sensorflow_compressor_mapping_insert_input {
  compressor: sensorflow_compressors_obj_rel_insert_input
  compressorId: uuid
  deleted_at: timestamptz
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_compressor_mapping_max_fields {
  compressorId: uuid
  deleted_at: timestamptz
  positionId: uuid
}

input sensorflow_compressor_mapping_max_order_by {
  compressorId: order_by
  deleted_at: order_by
  positionId: order_by
}

type sensorflow_compressor_mapping_min_fields {
  compressorId: uuid
  deleted_at: timestamptz
  positionId: uuid
}

input sensorflow_compressor_mapping_min_order_by {
  compressorId: order_by
  deleted_at: order_by
  positionId: order_by
}

type sensorflow_compressor_mapping_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_compressor_mapping!]!
}

input sensorflow_compressor_mapping_on_conflict {
  constraint: sensorflow_compressor_mapping_constraint!
  update_columns: [sensorflow_compressor_mapping_update_column!]! = []
  where: sensorflow_compressor_mapping_bool_exp
}

input sensorflow_compressor_mapping_order_by {
  compressor: sensorflow_compressors_order_by
  compressorId: order_by
  deleted_at: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

input sensorflow_compressor_mapping_pk_columns_input {
  compressorId: uuid!
  positionId: uuid!
}

enum sensorflow_compressor_mapping_select_column {
  compressorId
  deleted_at
  positionId
}

input sensorflow_compressor_mapping_set_input {
  compressorId: uuid
  deleted_at: timestamptz
  positionId: uuid
}

input sensorflow_compressor_mapping_stream_cursor_input {
  initial_value: sensorflow_compressor_mapping_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_compressor_mapping_stream_cursor_value_input {
  compressorId: uuid
  deleted_at: timestamptz
  positionId: uuid
}

enum sensorflow_compressor_mapping_update_column {
  compressorId
  deleted_at
  positionId
}

input sensorflow_compressor_mapping_updates {
  _set: sensorflow_compressor_mapping_set_input
  where: sensorflow_compressor_mapping_bool_exp!
}

type sensorflow_compressors {
  clickupTaskId: String
  compressorId: uuid!
  compressorMeterPositionId: uuid
  compressorName: String!
  compressorPositionId: uuid!
  compressorPositionMappings(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): [sensorflow_compressor_mapping!]!
  compressorPositionMappings_aggregate(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): sensorflow_compressor_mapping_aggregate!
  ctMultiplier: numeric
  deleted_at: timestamptz
  hvacSystemType: String
  meterPosition: sensorflow_positions
  numberOfPhases: Int!
  phaseStreamIndex: Int
  position: sensorflow_positions!
}

type sensorflow_compressors_aggregate {
  aggregate: sensorflow_compressors_aggregate_fields
  nodes: [sensorflow_compressors!]!
}

type sensorflow_compressors_aggregate_fields {
  avg: sensorflow_compressors_avg_fields
  count(columns: [sensorflow_compressors_select_column!], distinct: Boolean): Int!
  max: sensorflow_compressors_max_fields
  min: sensorflow_compressors_min_fields
  stddev: sensorflow_compressors_stddev_fields
  stddev_pop: sensorflow_compressors_stddev_pop_fields
  stddev_samp: sensorflow_compressors_stddev_samp_fields
  sum: sensorflow_compressors_sum_fields
  var_pop: sensorflow_compressors_var_pop_fields
  var_samp: sensorflow_compressors_var_samp_fields
  variance: sensorflow_compressors_variance_fields
}

type sensorflow_compressors_avg_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_compressors_bool_exp {
  _and: [sensorflow_compressors_bool_exp!]
  _not: sensorflow_compressors_bool_exp
  _or: [sensorflow_compressors_bool_exp!]
  clickupTaskId: String_comparison_exp
  compressorId: uuid_comparison_exp
  compressorMeterPositionId: uuid_comparison_exp
  compressorName: String_comparison_exp
  compressorPositionId: uuid_comparison_exp
  compressorPositionMappings: sensorflow_compressor_mapping_bool_exp
  compressorPositionMappings_aggregate: sensorflow_compressor_mapping_aggregate_bool_exp
  ctMultiplier: numeric_comparison_exp
  deleted_at: timestamptz_comparison_exp
  hvacSystemType: String_comparison_exp
  meterPosition: sensorflow_positions_bool_exp
  numberOfPhases: Int_comparison_exp
  phaseStreamIndex: Int_comparison_exp
  position: sensorflow_positions_bool_exp
}

enum sensorflow_compressors_constraint {
  compressors_pkey
}

input sensorflow_compressors_inc_input {
  ctMultiplier: numeric
  numberOfPhases: Int
  phaseStreamIndex: Int
}

input sensorflow_compressors_insert_input {
  clickupTaskId: String
  compressorId: uuid
  compressorMeterPositionId: uuid
  compressorName: String
  compressorPositionId: uuid
  compressorPositionMappings: sensorflow_compressor_mapping_arr_rel_insert_input
  ctMultiplier: numeric
  deleted_at: timestamptz
  hvacSystemType: String
  meterPosition: sensorflow_positions_obj_rel_insert_input
  numberOfPhases: Int
  phaseStreamIndex: Int
  position: sensorflow_positions_obj_rel_insert_input
}

type sensorflow_compressors_max_fields {
  clickupTaskId: String
  compressorId: uuid
  compressorMeterPositionId: uuid
  compressorName: String
  compressorPositionId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  hvacSystemType: String
  numberOfPhases: Int
  phaseStreamIndex: Int
}

type sensorflow_compressors_min_fields {
  clickupTaskId: String
  compressorId: uuid
  compressorMeterPositionId: uuid
  compressorName: String
  compressorPositionId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  hvacSystemType: String
  numberOfPhases: Int
  phaseStreamIndex: Int
}

type sensorflow_compressors_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_compressors!]!
}

input sensorflow_compressors_obj_rel_insert_input {
  data: sensorflow_compressors_insert_input!
  on_conflict: sensorflow_compressors_on_conflict
}

input sensorflow_compressors_on_conflict {
  constraint: sensorflow_compressors_constraint!
  update_columns: [sensorflow_compressors_update_column!]! = []
  where: sensorflow_compressors_bool_exp
}

input sensorflow_compressors_order_by {
  clickupTaskId: order_by
  compressorId: order_by
  compressorMeterPositionId: order_by
  compressorName: order_by
  compressorPositionId: order_by
  compressorPositionMappings_aggregate: sensorflow_compressor_mapping_aggregate_order_by
  ctMultiplier: order_by
  deleted_at: order_by
  hvacSystemType: order_by
  meterPosition: sensorflow_positions_order_by
  numberOfPhases: order_by
  phaseStreamIndex: order_by
  position: sensorflow_positions_order_by
}

input sensorflow_compressors_pk_columns_input {
  compressorId: uuid!
}

enum sensorflow_compressors_select_column {
  clickupTaskId
  compressorId
  compressorMeterPositionId
  compressorName
  compressorPositionId
  ctMultiplier
  deleted_at
  hvacSystemType
  numberOfPhases
  phaseStreamIndex
}

input sensorflow_compressors_set_input {
  clickupTaskId: String
  compressorId: uuid
  compressorMeterPositionId: uuid
  compressorName: String
  compressorPositionId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  hvacSystemType: String
  numberOfPhases: Int
  phaseStreamIndex: Int
}

type sensorflow_compressors_stddev_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_compressors_stddev_pop_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_compressors_stddev_samp_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_compressors_stream_cursor_input {
  initial_value: sensorflow_compressors_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_compressors_stream_cursor_value_input {
  clickupTaskId: String
  compressorId: uuid
  compressorMeterPositionId: uuid
  compressorName: String
  compressorPositionId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  hvacSystemType: String
  numberOfPhases: Int
  phaseStreamIndex: Int
}

type sensorflow_compressors_sum_fields {
  ctMultiplier: numeric
  numberOfPhases: Int
  phaseStreamIndex: Int
}

enum sensorflow_compressors_update_column {
  clickupTaskId
  compressorId
  compressorMeterPositionId
  compressorName
  compressorPositionId
  ctMultiplier
  deleted_at
  hvacSystemType
  numberOfPhases
  phaseStreamIndex
}

input sensorflow_compressors_updates {
  _inc: sensorflow_compressors_inc_input
  _set: sensorflow_compressors_set_input
  where: sensorflow_compressors_bool_exp!
}

type sensorflow_compressors_var_pop_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_compressors_var_samp_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_compressors_variance_fields {
  ctMultiplier: Float
  numberOfPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_config_event_stream {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_config_event_stream_aggregate {
  aggregate: sensorflow_config_event_stream_aggregate_fields
  nodes: [sensorflow_config_event_stream!]!
}

type sensorflow_config_event_stream_aggregate_fields {
  count(columns: [sensorflow_config_event_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_config_event_stream_max_fields
  min: sensorflow_config_event_stream_min_fields
}

input sensorflow_config_event_stream_bool_exp {
  _and: [sensorflow_config_event_stream_bool_exp!]
  _not: sensorflow_config_event_stream_bool_exp
  _or: [sensorflow_config_event_stream_bool_exp!]
  config_type_changed: String_comparison_exp
  event_type: String_comparison_exp
  modified_by_user: String_comparison_exp
  new_config_value: String_comparison_exp
  previous_config_value: String_comparison_exp
  time: timestamptz_comparison_exp
  user_email: String_comparison_exp
}

input sensorflow_config_event_stream_insert_input {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_config_event_stream_max_fields {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_config_event_stream_min_fields {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_config_event_stream_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_config_event_stream!]!
}

input sensorflow_config_event_stream_order_by {
  config_type_changed: order_by
  event_type: order_by
  modified_by_user: order_by
  new_config_value: order_by
  previous_config_value: order_by
  time: order_by
  user_email: order_by
}

enum sensorflow_config_event_stream_select_column {
  config_type_changed
  event_type
  modified_by_user
  new_config_value
  previous_config_value
  time
  user_email
}

input sensorflow_config_event_stream_set_input {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_config_event_stream_stream_cursor_input {
  initial_value: sensorflow_config_event_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_config_event_stream_stream_cursor_value_input {
  config_type_changed: String
  event_type: String
  modified_by_user: String
  new_config_value: String
  previous_config_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_config_event_stream_updates {
  _set: sensorflow_config_event_stream_set_input
  where: sensorflow_config_event_stream_bool_exp!
}

type sensorflow_cooling_towers {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid!
  meterPosition: sensorflow_positions
  meterPositionId: uuid
  name: String
  numPhases: Int!
  phaseStreamIndex: Int
  position: sensorflow_positions!
  positionId: uuid!
}

type sensorflow_cooling_towers_aggregate {
  aggregate: sensorflow_cooling_towers_aggregate_fields
  nodes: [sensorflow_cooling_towers!]!
}

type sensorflow_cooling_towers_aggregate_fields {
  avg: sensorflow_cooling_towers_avg_fields
  count(columns: [sensorflow_cooling_towers_select_column!], distinct: Boolean): Int!
  max: sensorflow_cooling_towers_max_fields
  min: sensorflow_cooling_towers_min_fields
  stddev: sensorflow_cooling_towers_stddev_fields
  stddev_pop: sensorflow_cooling_towers_stddev_pop_fields
  stddev_samp: sensorflow_cooling_towers_stddev_samp_fields
  sum: sensorflow_cooling_towers_sum_fields
  var_pop: sensorflow_cooling_towers_var_pop_fields
  var_samp: sensorflow_cooling_towers_var_samp_fields
  variance: sensorflow_cooling_towers_variance_fields
}

type sensorflow_cooling_towers_avg_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_cooling_towers_bool_exp {
  _and: [sensorflow_cooling_towers_bool_exp!]
  _not: sensorflow_cooling_towers_bool_exp
  _or: [sensorflow_cooling_towers_bool_exp!]
  compressorId: uuid_comparison_exp
  ctMultiplier: numeric_comparison_exp
  deleted_at: timestamptz_comparison_exp
  id: uuid_comparison_exp
  meterPosition: sensorflow_positions_bool_exp
  meterPositionId: uuid_comparison_exp
  name: String_comparison_exp
  numPhases: Int_comparison_exp
  phaseStreamIndex: Int_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

enum sensorflow_cooling_towers_constraint {
  cooling_towers_pkey
}

input sensorflow_cooling_towers_inc_input {
  ctMultiplier: numeric
  numPhases: Int
  phaseStreamIndex: Int
}

input sensorflow_cooling_towers_insert_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPosition: sensorflow_positions_obj_rel_insert_input
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_cooling_towers_max_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_cooling_towers_min_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_cooling_towers_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_cooling_towers!]!
}

input sensorflow_cooling_towers_on_conflict {
  constraint: sensorflow_cooling_towers_constraint!
  update_columns: [sensorflow_cooling_towers_update_column!]! = []
  where: sensorflow_cooling_towers_bool_exp
}

input sensorflow_cooling_towers_order_by {
  compressorId: order_by
  ctMultiplier: order_by
  deleted_at: order_by
  id: order_by
  meterPosition: sensorflow_positions_order_by
  meterPositionId: order_by
  name: order_by
  numPhases: order_by
  phaseStreamIndex: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

input sensorflow_cooling_towers_pk_columns_input {
  id: uuid!
}

enum sensorflow_cooling_towers_select_column {
  compressorId
  ctMultiplier
  deleted_at
  id
  meterPositionId
  name
  numPhases
  phaseStreamIndex
  positionId
}

input sensorflow_cooling_towers_set_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_cooling_towers_stddev_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_cooling_towers_stddev_pop_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_cooling_towers_stddev_samp_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_cooling_towers_stream_cursor_input {
  initial_value: sensorflow_cooling_towers_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_cooling_towers_stream_cursor_value_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_cooling_towers_sum_fields {
  ctMultiplier: numeric
  numPhases: Int
  phaseStreamIndex: Int
}

enum sensorflow_cooling_towers_update_column {
  compressorId
  ctMultiplier
  deleted_at
  id
  meterPositionId
  name
  numPhases
  phaseStreamIndex
  positionId
}

input sensorflow_cooling_towers_updates {
  _inc: sensorflow_cooling_towers_inc_input
  _set: sensorflow_cooling_towers_set_input
  where: sensorflow_cooling_towers_bool_exp!
}

type sensorflow_cooling_towers_var_pop_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_cooling_towers_var_samp_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_cooling_towers_variance_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_currencies {
  id: Int!
  locations(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): [sensorflow_locations!]!
  locations_aggregate(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): sensorflow_locations_aggregate!
  name: String!
  shortCode: String!
}

type sensorflow_currencies_aggregate {
  aggregate: sensorflow_currencies_aggregate_fields
  nodes: [sensorflow_currencies!]!
}

type sensorflow_currencies_aggregate_fields {
  avg: sensorflow_currencies_avg_fields
  count(columns: [sensorflow_currencies_select_column!], distinct: Boolean): Int!
  max: sensorflow_currencies_max_fields
  min: sensorflow_currencies_min_fields
  stddev: sensorflow_currencies_stddev_fields
  stddev_pop: sensorflow_currencies_stddev_pop_fields
  stddev_samp: sensorflow_currencies_stddev_samp_fields
  sum: sensorflow_currencies_sum_fields
  var_pop: sensorflow_currencies_var_pop_fields
  var_samp: sensorflow_currencies_var_samp_fields
  variance: sensorflow_currencies_variance_fields
}

type sensorflow_currencies_avg_fields {
  id: Float
}

input sensorflow_currencies_bool_exp {
  _and: [sensorflow_currencies_bool_exp!]
  _not: sensorflow_currencies_bool_exp
  _or: [sensorflow_currencies_bool_exp!]
  id: Int_comparison_exp
  locations: sensorflow_locations_bool_exp
  locations_aggregate: sensorflow_locations_aggregate_bool_exp
  name: String_comparison_exp
  shortCode: String_comparison_exp
}

enum sensorflow_currencies_constraint {
  currencies_pkey
  unique_short_code
}

input sensorflow_currencies_inc_input {
  id: Int
}

input sensorflow_currencies_insert_input {
  id: Int
  locations: sensorflow_locations_arr_rel_insert_input
  name: String
  shortCode: String
}

type sensorflow_currencies_max_fields {
  id: Int
  name: String
  shortCode: String
}

type sensorflow_currencies_min_fields {
  id: Int
  name: String
  shortCode: String
}

type sensorflow_currencies_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_currencies!]!
}

input sensorflow_currencies_obj_rel_insert_input {
  data: sensorflow_currencies_insert_input!
  on_conflict: sensorflow_currencies_on_conflict
}

input sensorflow_currencies_on_conflict {
  constraint: sensorflow_currencies_constraint!
  update_columns: [sensorflow_currencies_update_column!]! = []
  where: sensorflow_currencies_bool_exp
}

input sensorflow_currencies_order_by {
  id: order_by
  locations_aggregate: sensorflow_locations_aggregate_order_by
  name: order_by
  shortCode: order_by
}

input sensorflow_currencies_pk_columns_input {
  id: Int!
}

enum sensorflow_currencies_select_column {
  id
  name
  shortCode
}

input sensorflow_currencies_set_input {
  id: Int
  name: String
  shortCode: String
}

type sensorflow_currencies_stddev_fields {
  id: Float
}

type sensorflow_currencies_stddev_pop_fields {
  id: Float
}

type sensorflow_currencies_stddev_samp_fields {
  id: Float
}

input sensorflow_currencies_stream_cursor_input {
  initial_value: sensorflow_currencies_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_currencies_stream_cursor_value_input {
  id: Int
  name: String
  shortCode: String
}

type sensorflow_currencies_sum_fields {
  id: Int
}

enum sensorflow_currencies_update_column {
  id
  name
  shortCode
}

input sensorflow_currencies_updates {
  _inc: sensorflow_currencies_inc_input
  _set: sensorflow_currencies_set_input
  where: sensorflow_currencies_bool_exp!
}

type sensorflow_currencies_var_pop_fields {
  id: Float
}

type sensorflow_currencies_var_samp_fields {
  id: Float
}

type sensorflow_currencies_variance_fields {
  id: Float
}

type sensorflow_domo_embeds {
  description: String!
  embed_id: String!
  name: String!
}

type sensorflow_domo_embeds_aggregate {
  aggregate: sensorflow_domo_embeds_aggregate_fields
  nodes: [sensorflow_domo_embeds!]!
}

type sensorflow_domo_embeds_aggregate_fields {
  count(columns: [sensorflow_domo_embeds_select_column!], distinct: Boolean): Int!
  max: sensorflow_domo_embeds_max_fields
  min: sensorflow_domo_embeds_min_fields
}

input sensorflow_domo_embeds_bool_exp {
  _and: [sensorflow_domo_embeds_bool_exp!]
  _not: sensorflow_domo_embeds_bool_exp
  _or: [sensorflow_domo_embeds_bool_exp!]
  description: String_comparison_exp
  embed_id: String_comparison_exp
  name: String_comparison_exp
}

enum sensorflow_domo_embeds_constraint {
  domo_embeds_pkey
}

input sensorflow_domo_embeds_insert_input {
  description: String
  embed_id: String
  name: String
}

type sensorflow_domo_embeds_max_fields {
  description: String
  embed_id: String
  name: String
}

type sensorflow_domo_embeds_min_fields {
  description: String
  embed_id: String
  name: String
}

type sensorflow_domo_embeds_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_domo_embeds!]!
}

input sensorflow_domo_embeds_obj_rel_insert_input {
  data: sensorflow_domo_embeds_insert_input!
  on_conflict: sensorflow_domo_embeds_on_conflict
}

input sensorflow_domo_embeds_on_conflict {
  constraint: sensorflow_domo_embeds_constraint!
  update_columns: [sensorflow_domo_embeds_update_column!]! = []
  where: sensorflow_domo_embeds_bool_exp
}

input sensorflow_domo_embeds_order_by {
  description: order_by
  embed_id: order_by
  name: order_by
}

input sensorflow_domo_embeds_pk_columns_input {
  embed_id: String!
}

enum sensorflow_domo_embeds_select_column {
  description
  embed_id
  name
}

input sensorflow_domo_embeds_set_input {
  description: String
  embed_id: String
  name: String
}

input sensorflow_domo_embeds_stream_cursor_input {
  initial_value: sensorflow_domo_embeds_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_domo_embeds_stream_cursor_value_input {
  description: String
  embed_id: String
  name: String
}

enum sensorflow_domo_embeds_update_column {
  description
  embed_id
  name
}

input sensorflow_domo_embeds_updates {
  _set: sensorflow_domo_embeds_set_input
  where: sensorflow_domo_embeds_bool_exp!
}

type sensorflow_events_stream {
  currentValue: String
  eventType: String
  hasChanged: numeric
  isUserInteraction: numeric
  positionId: uuid
  previousValue: String
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_events_stream_aggregate {
  aggregate: sensorflow_events_stream_aggregate_fields
  nodes: [sensorflow_events_stream!]!
}

input sensorflow_events_stream_aggregate_bool_exp {
  count: sensorflow_events_stream_aggregate_bool_exp_count
}

input sensorflow_events_stream_aggregate_bool_exp_count {
  arguments: [sensorflow_events_stream_select_column!]
  distinct: Boolean
  filter: sensorflow_events_stream_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_events_stream_aggregate_fields {
  avg: sensorflow_events_stream_avg_fields
  count(columns: [sensorflow_events_stream_select_column!], distinct: Boolean): Int!
  max: sensorflow_events_stream_max_fields
  min: sensorflow_events_stream_min_fields
  stddev: sensorflow_events_stream_stddev_fields
  stddev_pop: sensorflow_events_stream_stddev_pop_fields
  stddev_samp: sensorflow_events_stream_stddev_samp_fields
  sum: sensorflow_events_stream_sum_fields
  var_pop: sensorflow_events_stream_var_pop_fields
  var_samp: sensorflow_events_stream_var_samp_fields
  variance: sensorflow_events_stream_variance_fields
}

input sensorflow_events_stream_aggregate_order_by {
  avg: sensorflow_events_stream_avg_order_by
  count: order_by
  max: sensorflow_events_stream_max_order_by
  min: sensorflow_events_stream_min_order_by
  stddev: sensorflow_events_stream_stddev_order_by
  stddev_pop: sensorflow_events_stream_stddev_pop_order_by
  stddev_samp: sensorflow_events_stream_stddev_samp_order_by
  sum: sensorflow_events_stream_sum_order_by
  var_pop: sensorflow_events_stream_var_pop_order_by
  var_samp: sensorflow_events_stream_var_samp_order_by
  variance: sensorflow_events_stream_variance_order_by
}

input sensorflow_events_stream_arr_rel_insert_input {
  data: [sensorflow_events_stream_insert_input!]!
}

type sensorflow_events_stream_avg_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_avg_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

input sensorflow_events_stream_bool_exp {
  _and: [sensorflow_events_stream_bool_exp!]
  _not: sensorflow_events_stream_bool_exp
  _or: [sensorflow_events_stream_bool_exp!]
  currentValue: String_comparison_exp
  eventType: String_comparison_exp
  hasChanged: numeric_comparison_exp
  isUserInteraction: numeric_comparison_exp
  positionId: uuid_comparison_exp
  previousValue: String_comparison_exp
  slotName: String_comparison_exp
  srcMacId: String_comparison_exp
  time: timestamptz_comparison_exp
}

input sensorflow_events_stream_insert_input {
  currentValue: String
  eventType: String
  hasChanged: numeric
  isUserInteraction: numeric
  positionId: uuid
  previousValue: String
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_events_stream_max_fields {
  currentValue: String
  eventType: String
  hasChanged: numeric
  isUserInteraction: numeric
  positionId: uuid
  previousValue: String
  slotName: String
  srcMacId: String
  time: timestamptz
}

input sensorflow_events_stream_max_order_by {
  currentValue: order_by
  eventType: order_by
  hasChanged: order_by
  isUserInteraction: order_by
  positionId: order_by
  previousValue: order_by
  slotName: order_by
  srcMacId: order_by
  time: order_by
}

type sensorflow_events_stream_min_fields {
  currentValue: String
  eventType: String
  hasChanged: numeric
  isUserInteraction: numeric
  positionId: uuid
  previousValue: String
  slotName: String
  srcMacId: String
  time: timestamptz
}

input sensorflow_events_stream_min_order_by {
  currentValue: order_by
  eventType: order_by
  hasChanged: order_by
  isUserInteraction: order_by
  positionId: order_by
  previousValue: order_by
  slotName: order_by
  srcMacId: order_by
  time: order_by
}

input sensorflow_events_stream_order_by {
  currentValue: order_by
  eventType: order_by
  hasChanged: order_by
  isUserInteraction: order_by
  positionId: order_by
  previousValue: order_by
  slotName: order_by
  srcMacId: order_by
  time: order_by
}

enum sensorflow_events_stream_select_column {
  currentValue
  eventType
  hasChanged
  isUserInteraction
  positionId
  previousValue
  slotName
  srcMacId
  time
}

type sensorflow_events_stream_stddev_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_stddev_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

type sensorflow_events_stream_stddev_pop_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_stddev_pop_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

type sensorflow_events_stream_stddev_samp_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_stddev_samp_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

input sensorflow_events_stream_stream_cursor_input {
  initial_value: sensorflow_events_stream_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_events_stream_stream_cursor_value_input {
  currentValue: String
  eventType: String
  hasChanged: numeric
  isUserInteraction: numeric
  positionId: uuid
  previousValue: String
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_events_stream_sum_fields {
  hasChanged: numeric
  isUserInteraction: numeric
}

input sensorflow_events_stream_sum_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

type sensorflow_events_stream_var_pop_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_var_pop_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

type sensorflow_events_stream_var_samp_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_var_samp_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

type sensorflow_events_stream_variance_fields {
  hasChanged: Float
  isUserInteraction: Float
}

input sensorflow_events_stream_variance_order_by {
  hasChanged: order_by
  isUserInteraction: order_by
}

input sensorflow_f_get_ac_command_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_f_get_all_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_f_get_config_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_f_get_event_stream_args {
  from_date: timestamptz
  positionid: uuid
  to_date: timestamptz
}

input sensorflow_f_get_synced_raw_data_args {
  from_date: timestamptz
  intervals: String
  positionid: uuid
  to_date: timestamptz
}

type sensorflow_firmware_releases {
  comment: String
  downloadLink: String!
  id: Int!
  isDeprecated: Boolean
  nodeSubType: String!
  uploadedDate: timestamptz!
  versionName: String!
  versionNumber: Int!
}

type sensorflow_firmware_releases_aggregate {
  aggregate: sensorflow_firmware_releases_aggregate_fields
  nodes: [sensorflow_firmware_releases!]!
}

type sensorflow_firmware_releases_aggregate_fields {
  avg: sensorflow_firmware_releases_avg_fields
  count(columns: [sensorflow_firmware_releases_select_column!], distinct: Boolean): Int!
  max: sensorflow_firmware_releases_max_fields
  min: sensorflow_firmware_releases_min_fields
  stddev: sensorflow_firmware_releases_stddev_fields
  stddev_pop: sensorflow_firmware_releases_stddev_pop_fields
  stddev_samp: sensorflow_firmware_releases_stddev_samp_fields
  sum: sensorflow_firmware_releases_sum_fields
  var_pop: sensorflow_firmware_releases_var_pop_fields
  var_samp: sensorflow_firmware_releases_var_samp_fields
  variance: sensorflow_firmware_releases_variance_fields
}

type sensorflow_firmware_releases_avg_fields {
  id: Float
  versionNumber: Float
}

input sensorflow_firmware_releases_bool_exp {
  _and: [sensorflow_firmware_releases_bool_exp!]
  _not: sensorflow_firmware_releases_bool_exp
  _or: [sensorflow_firmware_releases_bool_exp!]
  comment: String_comparison_exp
  downloadLink: String_comparison_exp
  id: Int_comparison_exp
  isDeprecated: Boolean_comparison_exp
  nodeSubType: String_comparison_exp
  uploadedDate: timestamptz_comparison_exp
  versionName: String_comparison_exp
  versionNumber: Int_comparison_exp
}

enum sensorflow_firmware_releases_constraint {
  firmware_releases_pkey
  firmware_releases_unique_key
}

input sensorflow_firmware_releases_inc_input {
  id: Int
  versionNumber: Int
}

input sensorflow_firmware_releases_insert_input {
  comment: String
  downloadLink: String
  id: Int
  isDeprecated: Boolean
  nodeSubType: String
  uploadedDate: timestamptz
  versionName: String
  versionNumber: Int
}

type sensorflow_firmware_releases_max_fields {
  comment: String
  downloadLink: String
  id: Int
  nodeSubType: String
  uploadedDate: timestamptz
  versionName: String
  versionNumber: Int
}

type sensorflow_firmware_releases_min_fields {
  comment: String
  downloadLink: String
  id: Int
  nodeSubType: String
  uploadedDate: timestamptz
  versionName: String
  versionNumber: Int
}

type sensorflow_firmware_releases_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_firmware_releases!]!
}

input sensorflow_firmware_releases_obj_rel_insert_input {
  data: sensorflow_firmware_releases_insert_input!
  on_conflict: sensorflow_firmware_releases_on_conflict
}

input sensorflow_firmware_releases_on_conflict {
  constraint: sensorflow_firmware_releases_constraint!
  update_columns: [sensorflow_firmware_releases_update_column!]! = []
  where: sensorflow_firmware_releases_bool_exp
}

input sensorflow_firmware_releases_order_by {
  comment: order_by
  downloadLink: order_by
  id: order_by
  isDeprecated: order_by
  nodeSubType: order_by
  uploadedDate: order_by
  versionName: order_by
  versionNumber: order_by
}

input sensorflow_firmware_releases_pk_columns_input {
  id: Int!
}

enum sensorflow_firmware_releases_select_column {
  comment
  downloadLink
  id
  isDeprecated
  nodeSubType
  uploadedDate
  versionName
  versionNumber
}

input sensorflow_firmware_releases_set_input {
  comment: String
  downloadLink: String
  id: Int
  isDeprecated: Boolean
  nodeSubType: String
  uploadedDate: timestamptz
  versionName: String
  versionNumber: Int
}

type sensorflow_firmware_releases_stddev_fields {
  id: Float
  versionNumber: Float
}

type sensorflow_firmware_releases_stddev_pop_fields {
  id: Float
  versionNumber: Float
}

type sensorflow_firmware_releases_stddev_samp_fields {
  id: Float
  versionNumber: Float
}

input sensorflow_firmware_releases_stream_cursor_input {
  initial_value: sensorflow_firmware_releases_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_firmware_releases_stream_cursor_value_input {
  comment: String
  downloadLink: String
  id: Int
  isDeprecated: Boolean
  nodeSubType: String
  uploadedDate: timestamptz
  versionName: String
  versionNumber: Int
}

type sensorflow_firmware_releases_sum_fields {
  id: Int
  versionNumber: Int
}

enum sensorflow_firmware_releases_update_column {
  comment
  downloadLink
  id
  isDeprecated
  nodeSubType
  uploadedDate
  versionName
  versionNumber
}

input sensorflow_firmware_releases_updates {
  _inc: sensorflow_firmware_releases_inc_input
  _set: sensorflow_firmware_releases_set_input
  where: sensorflow_firmware_releases_bool_exp!
}

type sensorflow_firmware_releases_var_pop_fields {
  id: Float
  versionNumber: Float
}

type sensorflow_firmware_releases_var_samp_fields {
  id: Float
  versionNumber: Float
}

type sensorflow_firmware_releases_variance_fields {
  id: Float
  versionNumber: Float
}

type sensorflow_gateway_frequency {
  balena_id: String!
  gateway: sensorflow_gateways!
  is_ota: Boolean!
  location: sensorflow_locations!
  location_id: uuid!
  reserved_frequency: bigint!
}

type sensorflow_gateway_frequency_aggregate {
  aggregate: sensorflow_gateway_frequency_aggregate_fields
  nodes: [sensorflow_gateway_frequency!]!
}

type sensorflow_gateway_frequency_aggregate_fields {
  avg: sensorflow_gateway_frequency_avg_fields
  count(columns: [sensorflow_gateway_frequency_select_column!], distinct: Boolean): Int!
  max: sensorflow_gateway_frequency_max_fields
  min: sensorflow_gateway_frequency_min_fields
  stddev: sensorflow_gateway_frequency_stddev_fields
  stddev_pop: sensorflow_gateway_frequency_stddev_pop_fields
  stddev_samp: sensorflow_gateway_frequency_stddev_samp_fields
  sum: sensorflow_gateway_frequency_sum_fields
  var_pop: sensorflow_gateway_frequency_var_pop_fields
  var_samp: sensorflow_gateway_frequency_var_samp_fields
  variance: sensorflow_gateway_frequency_variance_fields
}

type sensorflow_gateway_frequency_avg_fields {
  reserved_frequency: Float
}

input sensorflow_gateway_frequency_bool_exp {
  _and: [sensorflow_gateway_frequency_bool_exp!]
  _not: sensorflow_gateway_frequency_bool_exp
  _or: [sensorflow_gateway_frequency_bool_exp!]
  balena_id: String_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  is_ota: Boolean_comparison_exp
  location: sensorflow_locations_bool_exp
  location_id: uuid_comparison_exp
  reserved_frequency: bigint_comparison_exp
}

enum sensorflow_gateway_frequency_constraint {
  gateway_frequency_location_id_reserved_frequency_key
  gateway_frequency_pkey
}

input sensorflow_gateway_frequency_inc_input {
  reserved_frequency: bigint
}

input sensorflow_gateway_frequency_insert_input {
  balena_id: String
  gateway: sensorflow_gateways_obj_rel_insert_input
  is_ota: Boolean
  location: sensorflow_locations_obj_rel_insert_input
  location_id: uuid
  reserved_frequency: bigint
}

type sensorflow_gateway_frequency_max_fields {
  balena_id: String
  location_id: uuid
  reserved_frequency: bigint
}

type sensorflow_gateway_frequency_min_fields {
  balena_id: String
  location_id: uuid
  reserved_frequency: bigint
}

type sensorflow_gateway_frequency_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_gateway_frequency!]!
}

input sensorflow_gateway_frequency_on_conflict {
  constraint: sensorflow_gateway_frequency_constraint!
  update_columns: [sensorflow_gateway_frequency_update_column!]! = []
  where: sensorflow_gateway_frequency_bool_exp
}

input sensorflow_gateway_frequency_order_by {
  balena_id: order_by
  gateway: sensorflow_gateways_order_by
  is_ota: order_by
  location: sensorflow_locations_order_by
  location_id: order_by
  reserved_frequency: order_by
}

input sensorflow_gateway_frequency_pk_columns_input {
  balena_id: String!
  reserved_frequency: bigint!
}

enum sensorflow_gateway_frequency_select_column {
  balena_id
  is_ota
  location_id
  reserved_frequency
}

input sensorflow_gateway_frequency_set_input {
  balena_id: String
  is_ota: Boolean
  location_id: uuid
  reserved_frequency: bigint
}

type sensorflow_gateway_frequency_stddev_fields {
  reserved_frequency: Float
}

type sensorflow_gateway_frequency_stddev_pop_fields {
  reserved_frequency: Float
}

type sensorflow_gateway_frequency_stddev_samp_fields {
  reserved_frequency: Float
}

input sensorflow_gateway_frequency_stream_cursor_input {
  initial_value: sensorflow_gateway_frequency_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_gateway_frequency_stream_cursor_value_input {
  balena_id: String
  is_ota: Boolean
  location_id: uuid
  reserved_frequency: bigint
}

type sensorflow_gateway_frequency_sum_fields {
  reserved_frequency: bigint
}

enum sensorflow_gateway_frequency_update_column {
  balena_id
  is_ota
  location_id
  reserved_frequency
}

input sensorflow_gateway_frequency_updates {
  _inc: sensorflow_gateway_frequency_inc_input
  _set: sensorflow_gateway_frequency_set_input
  where: sensorflow_gateway_frequency_bool_exp!
}

type sensorflow_gateway_frequency_var_pop_fields {
  reserved_frequency: Float
}

type sensorflow_gateway_frequency_var_samp_fields {
  reserved_frequency: Float
}

type sensorflow_gateway_frequency_variance_fields {
  reserved_frequency: Float
}

type sensorflow_gateway_health_data {
  balenaId: String!
  gateway: sensorflow_gateways!
  measurementType: String!
  time: timestamptz!
  value: numeric!
}

type sensorflow_gateway_health_data_aggregate {
  aggregate: sensorflow_gateway_health_data_aggregate_fields
  nodes: [sensorflow_gateway_health_data!]!
}

input sensorflow_gateway_health_data_aggregate_bool_exp {
  count: sensorflow_gateway_health_data_aggregate_bool_exp_count
}

input sensorflow_gateway_health_data_aggregate_bool_exp_count {
  arguments: [sensorflow_gateway_health_data_select_column!]
  distinct: Boolean
  filter: sensorflow_gateway_health_data_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_gateway_health_data_aggregate_fields {
  avg: sensorflow_gateway_health_data_avg_fields
  count(columns: [sensorflow_gateway_health_data_select_column!], distinct: Boolean): Int!
  max: sensorflow_gateway_health_data_max_fields
  min: sensorflow_gateway_health_data_min_fields
  stddev: sensorflow_gateway_health_data_stddev_fields
  stddev_pop: sensorflow_gateway_health_data_stddev_pop_fields
  stddev_samp: sensorflow_gateway_health_data_stddev_samp_fields
  sum: sensorflow_gateway_health_data_sum_fields
  var_pop: sensorflow_gateway_health_data_var_pop_fields
  var_samp: sensorflow_gateway_health_data_var_samp_fields
  variance: sensorflow_gateway_health_data_variance_fields
}

input sensorflow_gateway_health_data_aggregate_order_by {
  avg: sensorflow_gateway_health_data_avg_order_by
  count: order_by
  max: sensorflow_gateway_health_data_max_order_by
  min: sensorflow_gateway_health_data_min_order_by
  stddev: sensorflow_gateway_health_data_stddev_order_by
  stddev_pop: sensorflow_gateway_health_data_stddev_pop_order_by
  stddev_samp: sensorflow_gateway_health_data_stddev_samp_order_by
  sum: sensorflow_gateway_health_data_sum_order_by
  var_pop: sensorflow_gateway_health_data_var_pop_order_by
  var_samp: sensorflow_gateway_health_data_var_samp_order_by
  variance: sensorflow_gateway_health_data_variance_order_by
}

input sensorflow_gateway_health_data_arr_rel_insert_input {
  data: [sensorflow_gateway_health_data_insert_input!]!
  on_conflict: sensorflow_gateway_health_data_on_conflict
}

type sensorflow_gateway_health_data_avg_fields {
  value: Float
}

input sensorflow_gateway_health_data_avg_order_by {
  value: order_by
}

input sensorflow_gateway_health_data_bool_exp {
  _and: [sensorflow_gateway_health_data_bool_exp!]
  _not: sensorflow_gateway_health_data_bool_exp
  _or: [sensorflow_gateway_health_data_bool_exp!]
  balenaId: String_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  measurementType: String_comparison_exp
  time: timestamptz_comparison_exp
  value: numeric_comparison_exp
}

enum sensorflow_gateway_health_data_constraint {
  gateway_health_data_unique_ask_idx
}

input sensorflow_gateway_health_data_inc_input {
  value: numeric
}

input sensorflow_gateway_health_data_insert_input {
  balenaId: String
  gateway: sensorflow_gateways_obj_rel_insert_input
  measurementType: String
  time: timestamptz
  value: numeric
}

type sensorflow_gateway_health_data_max_fields {
  balenaId: String
  measurementType: String
  time: timestamptz
  value: numeric
}

input sensorflow_gateway_health_data_max_order_by {
  balenaId: order_by
  measurementType: order_by
  time: order_by
  value: order_by
}

type sensorflow_gateway_health_data_min_fields {
  balenaId: String
  measurementType: String
  time: timestamptz
  value: numeric
}

input sensorflow_gateway_health_data_min_order_by {
  balenaId: order_by
  measurementType: order_by
  time: order_by
  value: order_by
}

type sensorflow_gateway_health_data_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_gateway_health_data!]!
}

input sensorflow_gateway_health_data_on_conflict {
  constraint: sensorflow_gateway_health_data_constraint!
  update_columns: [sensorflow_gateway_health_data_update_column!]! = []
  where: sensorflow_gateway_health_data_bool_exp
}

input sensorflow_gateway_health_data_order_by {
  balenaId: order_by
  gateway: sensorflow_gateways_order_by
  measurementType: order_by
  time: order_by
  value: order_by
}

enum sensorflow_gateway_health_data_select_column {
  balenaId
  measurementType
  time
  value
}

input sensorflow_gateway_health_data_set_input {
  balenaId: String
  measurementType: String
  time: timestamptz
  value: numeric
}

type sensorflow_gateway_health_data_stddev_fields {
  value: Float
}

input sensorflow_gateway_health_data_stddev_order_by {
  value: order_by
}

type sensorflow_gateway_health_data_stddev_pop_fields {
  value: Float
}

input sensorflow_gateway_health_data_stddev_pop_order_by {
  value: order_by
}

type sensorflow_gateway_health_data_stddev_samp_fields {
  value: Float
}

input sensorflow_gateway_health_data_stddev_samp_order_by {
  value: order_by
}

input sensorflow_gateway_health_data_stream_cursor_input {
  initial_value: sensorflow_gateway_health_data_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_gateway_health_data_stream_cursor_value_input {
  balenaId: String
  measurementType: String
  time: timestamptz
  value: numeric
}

type sensorflow_gateway_health_data_sum_fields {
  value: numeric
}

input sensorflow_gateway_health_data_sum_order_by {
  value: order_by
}

enum sensorflow_gateway_health_data_update_column {
  balenaId
  measurementType
  time
  value
}

input sensorflow_gateway_health_data_updates {
  _inc: sensorflow_gateway_health_data_inc_input
  _set: sensorflow_gateway_health_data_set_input
  where: sensorflow_gateway_health_data_bool_exp!
}

type sensorflow_gateway_health_data_var_pop_fields {
  value: Float
}

input sensorflow_gateway_health_data_var_pop_order_by {
  value: order_by
}

type sensorflow_gateway_health_data_var_samp_fields {
  value: Float
}

input sensorflow_gateway_health_data_var_samp_order_by {
  value: order_by
}

type sensorflow_gateway_health_data_variance_fields {
  value: Float
}

input sensorflow_gateway_health_data_variance_order_by {
  value: order_by
}

type sensorflow_gateway_to_position_mapping {
  gateway: sensorflow_gateways
  gatewayId: String
  position: sensorflow_positions!
  positionId: uuid!
  updated_at: timestamptz
}

type sensorflow_gateway_to_position_mapping_aggregate {
  aggregate: sensorflow_gateway_to_position_mapping_aggregate_fields
  nodes: [sensorflow_gateway_to_position_mapping!]!
}

input sensorflow_gateway_to_position_mapping_aggregate_bool_exp {
  count: sensorflow_gateway_to_position_mapping_aggregate_bool_exp_count
}

input sensorflow_gateway_to_position_mapping_aggregate_bool_exp_count {
  arguments: [sensorflow_gateway_to_position_mapping_select_column!]
  distinct: Boolean
  filter: sensorflow_gateway_to_position_mapping_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_gateway_to_position_mapping_aggregate_fields {
  count(columns: [sensorflow_gateway_to_position_mapping_select_column!], distinct: Boolean): Int!
  max: sensorflow_gateway_to_position_mapping_max_fields
  min: sensorflow_gateway_to_position_mapping_min_fields
}

input sensorflow_gateway_to_position_mapping_aggregate_order_by {
  count: order_by
  max: sensorflow_gateway_to_position_mapping_max_order_by
  min: sensorflow_gateway_to_position_mapping_min_order_by
}

input sensorflow_gateway_to_position_mapping_arr_rel_insert_input {
  data: [sensorflow_gateway_to_position_mapping_insert_input!]!
  on_conflict: sensorflow_gateway_to_position_mapping_on_conflict
}

input sensorflow_gateway_to_position_mapping_bool_exp {
  _and: [sensorflow_gateway_to_position_mapping_bool_exp!]
  _not: sensorflow_gateway_to_position_mapping_bool_exp
  _or: [sensorflow_gateway_to_position_mapping_bool_exp!]
  gateway: sensorflow_gateways_bool_exp
  gatewayId: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  updated_at: timestamptz_comparison_exp
}

enum sensorflow_gateway_to_position_mapping_constraint {
  gateway_to_position_mapping_pkey
}

input sensorflow_gateway_to_position_mapping_insert_input {
  gateway: sensorflow_gateways_obj_rel_insert_input
  gatewayId: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  updated_at: timestamptz
}

type sensorflow_gateway_to_position_mapping_max_fields {
  gatewayId: String
  positionId: uuid
  updated_at: timestamptz
}

input sensorflow_gateway_to_position_mapping_max_order_by {
  gatewayId: order_by
  positionId: order_by
  updated_at: order_by
}

type sensorflow_gateway_to_position_mapping_min_fields {
  gatewayId: String
  positionId: uuid
  updated_at: timestamptz
}

input sensorflow_gateway_to_position_mapping_min_order_by {
  gatewayId: order_by
  positionId: order_by
  updated_at: order_by
}

type sensorflow_gateway_to_position_mapping_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_gateway_to_position_mapping!]!
}

input sensorflow_gateway_to_position_mapping_on_conflict {
  constraint: sensorflow_gateway_to_position_mapping_constraint!
  update_columns: [sensorflow_gateway_to_position_mapping_update_column!]! = []
  where: sensorflow_gateway_to_position_mapping_bool_exp
}

input sensorflow_gateway_to_position_mapping_order_by {
  gateway: sensorflow_gateways_order_by
  gatewayId: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  updated_at: order_by
}

input sensorflow_gateway_to_position_mapping_pk_columns_input {
  positionId: uuid!
}

enum sensorflow_gateway_to_position_mapping_select_column {
  gatewayId
  positionId
  updated_at
}

input sensorflow_gateway_to_position_mapping_set_input {
  gatewayId: String
  positionId: uuid
  updated_at: timestamptz
}

input sensorflow_gateway_to_position_mapping_stream_cursor_input {
  initial_value: sensorflow_gateway_to_position_mapping_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_gateway_to_position_mapping_stream_cursor_value_input {
  gatewayId: String
  positionId: uuid
  updated_at: timestamptz
}

enum sensorflow_gateway_to_position_mapping_update_column {
  gatewayId
  positionId
  updated_at
}

input sensorflow_gateway_to_position_mapping_updates {
  _set: sensorflow_gateway_to_position_mapping_set_input
  where: sensorflow_gateway_to_position_mapping_bool_exp!
}

type sensorflow_gateways {
  beaconSlotManagementEnabled: Boolean
  clickupTaskId: String
  deletedAt: timestamptz
  enableNoKeyCardState: Boolean
  ethernetMacAddress: String
  gatewayHealthData(distinct_on: [sensorflow_gateway_health_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_health_data_order_by!], where: sensorflow_gateway_health_data_bool_exp): [sensorflow_gateway_health_data!]!
  gatewayHealthData_aggregate(distinct_on: [sensorflow_gateway_health_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_health_data_order_by!], where: sensorflow_gateway_health_data_bool_exp): sensorflow_gateway_health_data_aggregate!
  gatewayId: String!
  gatewayMac: Int!
  gatewayName: String!
  hardware_type: String!
  mappingCoordinates: sensorflow_mapping_coordinates
  networkConnectionStatus: String
  nodeJoinData(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): [sensorflow_node_join_data!]!
  nodeJoinData_aggregate(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): sensorflow_node_join_data_aggregate!
  nodes(distinct_on: [sensorflow_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_nodes_order_by!], where: sensorflow_nodes_bool_exp): [sensorflow_nodes!]!
  nodes_aggregate(distinct_on: [sensorflow_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_nodes_order_by!], where: sensorflow_nodes_bool_exp): sensorflow_nodes_aggregate!
  position: sensorflow_positions
  positionId: uuid
  positionMappings(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): [sensorflow_gateway_to_position_mapping!]!
  positionMappings_aggregate(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): sensorflow_gateway_to_position_mapping_aggregate!
  promiscuous_mode_enabled: Boolean
  version: String
  warehouse_kitting_process_metadata(distinct_on: [sensorflow_warehouse_kitting_process_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_kitting_process_metadata_order_by!], where: sensorflow_warehouse_kitting_process_metadata_bool_exp): [sensorflow_warehouse_kitting_process_metadata!]!
  warehouse_kitting_process_metadata_aggregate(distinct_on: [sensorflow_warehouse_kitting_process_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_kitting_process_metadata_order_by!], where: sensorflow_warehouse_kitting_process_metadata_bool_exp): sensorflow_warehouse_kitting_process_metadata_aggregate!
  wifiMacAddress: String
}

type sensorflow_gateways_aggregate {
  aggregate: sensorflow_gateways_aggregate_fields
  nodes: [sensorflow_gateways!]!
}

input sensorflow_gateways_aggregate_bool_exp {
  bool_and: sensorflow_gateways_aggregate_bool_exp_bool_and
  bool_or: sensorflow_gateways_aggregate_bool_exp_bool_or
  count: sensorflow_gateways_aggregate_bool_exp_count
}

input sensorflow_gateways_aggregate_bool_exp_bool_and {
  arguments: sensorflow_gateways_select_column_sensorflow_gateways_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: sensorflow_gateways_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_gateways_aggregate_bool_exp_bool_or {
  arguments: sensorflow_gateways_select_column_sensorflow_gateways_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: sensorflow_gateways_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_gateways_aggregate_bool_exp_count {
  arguments: [sensorflow_gateways_select_column!]
  distinct: Boolean
  filter: sensorflow_gateways_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_gateways_aggregate_fields {
  avg: sensorflow_gateways_avg_fields
  count(columns: [sensorflow_gateways_select_column!], distinct: Boolean): Int!
  max: sensorflow_gateways_max_fields
  min: sensorflow_gateways_min_fields
  stddev: sensorflow_gateways_stddev_fields
  stddev_pop: sensorflow_gateways_stddev_pop_fields
  stddev_samp: sensorflow_gateways_stddev_samp_fields
  sum: sensorflow_gateways_sum_fields
  var_pop: sensorflow_gateways_var_pop_fields
  var_samp: sensorflow_gateways_var_samp_fields
  variance: sensorflow_gateways_variance_fields
}

input sensorflow_gateways_aggregate_order_by {
  avg: sensorflow_gateways_avg_order_by
  count: order_by
  max: sensorflow_gateways_max_order_by
  min: sensorflow_gateways_min_order_by
  stddev: sensorflow_gateways_stddev_order_by
  stddev_pop: sensorflow_gateways_stddev_pop_order_by
  stddev_samp: sensorflow_gateways_stddev_samp_order_by
  sum: sensorflow_gateways_sum_order_by
  var_pop: sensorflow_gateways_var_pop_order_by
  var_samp: sensorflow_gateways_var_samp_order_by
  variance: sensorflow_gateways_variance_order_by
}

input sensorflow_gateways_arr_rel_insert_input {
  data: [sensorflow_gateways_insert_input!]!
  on_conflict: sensorflow_gateways_on_conflict
}

type sensorflow_gateways_avg_fields {
  gatewayMac: Float
}

input sensorflow_gateways_avg_order_by {
  gatewayMac: order_by
}

input sensorflow_gateways_bool_exp {
  _and: [sensorflow_gateways_bool_exp!]
  _not: sensorflow_gateways_bool_exp
  _or: [sensorflow_gateways_bool_exp!]
  beaconSlotManagementEnabled: Boolean_comparison_exp
  clickupTaskId: String_comparison_exp
  deletedAt: timestamptz_comparison_exp
  enableNoKeyCardState: Boolean_comparison_exp
  ethernetMacAddress: String_comparison_exp
  gatewayHealthData: sensorflow_gateway_health_data_bool_exp
  gatewayHealthData_aggregate: sensorflow_gateway_health_data_aggregate_bool_exp
  gatewayId: String_comparison_exp
  gatewayMac: Int_comparison_exp
  gatewayName: String_comparison_exp
  hardware_type: String_comparison_exp
  mappingCoordinates: sensorflow_mapping_coordinates_bool_exp
  networkConnectionStatus: String_comparison_exp
  nodeJoinData: sensorflow_node_join_data_bool_exp
  nodeJoinData_aggregate: sensorflow_node_join_data_aggregate_bool_exp
  nodes: sensorflow_nodes_bool_exp
  nodes_aggregate: sensorflow_nodes_aggregate_bool_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  positionMappings: sensorflow_gateway_to_position_mapping_bool_exp
  positionMappings_aggregate: sensorflow_gateway_to_position_mapping_aggregate_bool_exp
  promiscuous_mode_enabled: Boolean_comparison_exp
  version: String_comparison_exp
  warehouse_kitting_process_metadata: sensorflow_warehouse_kitting_process_metadata_bool_exp
  warehouse_kitting_process_metadata_aggregate: sensorflow_warehouse_kitting_process_metadata_aggregate_bool_exp
  wifiMacAddress: String_comparison_exp
}

enum sensorflow_gateways_constraint {
  gateways_pkey
}

input sensorflow_gateways_inc_input {
  gatewayMac: Int
}

input sensorflow_gateways_insert_input {
  beaconSlotManagementEnabled: Boolean
  clickupTaskId: String
  deletedAt: timestamptz
  enableNoKeyCardState: Boolean
  ethernetMacAddress: String
  gatewayHealthData: sensorflow_gateway_health_data_arr_rel_insert_input
  gatewayId: String
  gatewayMac: Int
  gatewayName: String
  hardware_type: String
  mappingCoordinates: sensorflow_mapping_coordinates_obj_rel_insert_input
  networkConnectionStatus: String
  nodeJoinData: sensorflow_node_join_data_arr_rel_insert_input
  nodes: sensorflow_nodes_arr_rel_insert_input
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  positionMappings: sensorflow_gateway_to_position_mapping_arr_rel_insert_input
  promiscuous_mode_enabled: Boolean
  version: String
  warehouse_kitting_process_metadata: sensorflow_warehouse_kitting_process_metadata_arr_rel_insert_input
  wifiMacAddress: String
}

type sensorflow_gateways_max_fields {
  clickupTaskId: String
  deletedAt: timestamptz
  ethernetMacAddress: String
  gatewayId: String
  gatewayMac: Int
  gatewayName: String
  hardware_type: String
  networkConnectionStatus: String
  positionId: uuid
  version: String
  wifiMacAddress: String
}

input sensorflow_gateways_max_order_by {
  clickupTaskId: order_by
  deletedAt: order_by
  ethernetMacAddress: order_by
  gatewayId: order_by
  gatewayMac: order_by
  gatewayName: order_by
  hardware_type: order_by
  networkConnectionStatus: order_by
  positionId: order_by
  version: order_by
  wifiMacAddress: order_by
}

type sensorflow_gateways_min_fields {
  clickupTaskId: String
  deletedAt: timestamptz
  ethernetMacAddress: String
  gatewayId: String
  gatewayMac: Int
  gatewayName: String
  hardware_type: String
  networkConnectionStatus: String
  positionId: uuid
  version: String
  wifiMacAddress: String
}

input sensorflow_gateways_min_order_by {
  clickupTaskId: order_by
  deletedAt: order_by
  ethernetMacAddress: order_by
  gatewayId: order_by
  gatewayMac: order_by
  gatewayName: order_by
  hardware_type: order_by
  networkConnectionStatus: order_by
  positionId: order_by
  version: order_by
  wifiMacAddress: order_by
}

type sensorflow_gateways_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_gateways!]!
}

input sensorflow_gateways_obj_rel_insert_input {
  data: sensorflow_gateways_insert_input!
  on_conflict: sensorflow_gateways_on_conflict
}

input sensorflow_gateways_on_conflict {
  constraint: sensorflow_gateways_constraint!
  update_columns: [sensorflow_gateways_update_column!]! = []
  where: sensorflow_gateways_bool_exp
}

input sensorflow_gateways_order_by {
  beaconSlotManagementEnabled: order_by
  clickupTaskId: order_by
  deletedAt: order_by
  enableNoKeyCardState: order_by
  ethernetMacAddress: order_by
  gatewayHealthData_aggregate: sensorflow_gateway_health_data_aggregate_order_by
  gatewayId: order_by
  gatewayMac: order_by
  gatewayName: order_by
  hardware_type: order_by
  mappingCoordinates: sensorflow_mapping_coordinates_order_by
  networkConnectionStatus: order_by
  nodeJoinData_aggregate: sensorflow_node_join_data_aggregate_order_by
  nodes_aggregate: sensorflow_nodes_aggregate_order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  positionMappings_aggregate: sensorflow_gateway_to_position_mapping_aggregate_order_by
  promiscuous_mode_enabled: order_by
  version: order_by
  warehouse_kitting_process_metadata_aggregate: sensorflow_warehouse_kitting_process_metadata_aggregate_order_by
  wifiMacAddress: order_by
}

input sensorflow_gateways_pk_columns_input {
  gatewayId: String!
}

enum sensorflow_gateways_select_column {
  beaconSlotManagementEnabled
  clickupTaskId
  deletedAt
  enableNoKeyCardState
  ethernetMacAddress
  gatewayId
  gatewayMac
  gatewayName
  hardware_type
  networkConnectionStatus
  positionId
  promiscuous_mode_enabled
  version
  wifiMacAddress
}

enum sensorflow_gateways_select_column_sensorflow_gateways_aggregate_bool_exp_bool_and_arguments_columns {
  beaconSlotManagementEnabled
  enableNoKeyCardState
  promiscuous_mode_enabled
}

enum sensorflow_gateways_select_column_sensorflow_gateways_aggregate_bool_exp_bool_or_arguments_columns {
  beaconSlotManagementEnabled
  enableNoKeyCardState
  promiscuous_mode_enabled
}

input sensorflow_gateways_set_input {
  beaconSlotManagementEnabled: Boolean
  clickupTaskId: String
  deletedAt: timestamptz
  enableNoKeyCardState: Boolean
  ethernetMacAddress: String
  gatewayId: String
  gatewayMac: Int
  gatewayName: String
  hardware_type: String
  networkConnectionStatus: String
  positionId: uuid
  promiscuous_mode_enabled: Boolean
  version: String
  wifiMacAddress: String
}

type sensorflow_gateways_stddev_fields {
  gatewayMac: Float
}

input sensorflow_gateways_stddev_order_by {
  gatewayMac: order_by
}

type sensorflow_gateways_stddev_pop_fields {
  gatewayMac: Float
}

input sensorflow_gateways_stddev_pop_order_by {
  gatewayMac: order_by
}

type sensorflow_gateways_stddev_samp_fields {
  gatewayMac: Float
}

input sensorflow_gateways_stddev_samp_order_by {
  gatewayMac: order_by
}

input sensorflow_gateways_stream_cursor_input {
  initial_value: sensorflow_gateways_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_gateways_stream_cursor_value_input {
  beaconSlotManagementEnabled: Boolean
  clickupTaskId: String
  deletedAt: timestamptz
  enableNoKeyCardState: Boolean
  ethernetMacAddress: String
  gatewayId: String
  gatewayMac: Int
  gatewayName: String
  hardware_type: String
  networkConnectionStatus: String
  positionId: uuid
  promiscuous_mode_enabled: Boolean
  version: String
  wifiMacAddress: String
}

type sensorflow_gateways_sum_fields {
  gatewayMac: Int
}

input sensorflow_gateways_sum_order_by {
  gatewayMac: order_by
}

enum sensorflow_gateways_update_column {
  beaconSlotManagementEnabled
  clickupTaskId
  deletedAt
  enableNoKeyCardState
  ethernetMacAddress
  gatewayId
  gatewayMac
  gatewayName
  hardware_type
  networkConnectionStatus
  positionId
  promiscuous_mode_enabled
  version
  wifiMacAddress
}

input sensorflow_gateways_updates {
  _inc: sensorflow_gateways_inc_input
  _set: sensorflow_gateways_set_input
  where: sensorflow_gateways_bool_exp!
}

type sensorflow_gateways_var_pop_fields {
  gatewayMac: Float
}

input sensorflow_gateways_var_pop_order_by {
  gatewayMac: order_by
}

type sensorflow_gateways_var_samp_fields {
  gatewayMac: Float
}

input sensorflow_gateways_var_samp_order_by {
  gatewayMac: order_by
}

type sensorflow_gateways_variance_fields {
  gatewayMac: Float
}

input sensorflow_gateways_variance_order_by {
  gatewayMac: order_by
}

type sensorflow_get_event_stream_view {
  changedBy: String
  currentValue: numeric
  eventType: String
  hasChanged: numeric
  position: sensorflow_positions
  positionId: uuid
  previousValue: numeric
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_get_event_stream_view_aggregate {
  aggregate: sensorflow_get_event_stream_view_aggregate_fields
  nodes: [sensorflow_get_event_stream_view!]!
}

type sensorflow_get_event_stream_view_aggregate_fields {
  avg: sensorflow_get_event_stream_view_avg_fields
  count(columns: [sensorflow_get_event_stream_view_select_column!], distinct: Boolean): Int!
  max: sensorflow_get_event_stream_view_max_fields
  min: sensorflow_get_event_stream_view_min_fields
  stddev: sensorflow_get_event_stream_view_stddev_fields
  stddev_pop: sensorflow_get_event_stream_view_stddev_pop_fields
  stddev_samp: sensorflow_get_event_stream_view_stddev_samp_fields
  sum: sensorflow_get_event_stream_view_sum_fields
  var_pop: sensorflow_get_event_stream_view_var_pop_fields
  var_samp: sensorflow_get_event_stream_view_var_samp_fields
  variance: sensorflow_get_event_stream_view_variance_fields
}

type sensorflow_get_event_stream_view_avg_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

input sensorflow_get_event_stream_view_bool_exp {
  _and: [sensorflow_get_event_stream_view_bool_exp!]
  _not: sensorflow_get_event_stream_view_bool_exp
  _or: [sensorflow_get_event_stream_view_bool_exp!]
  changedBy: String_comparison_exp
  currentValue: numeric_comparison_exp
  eventType: String_comparison_exp
  hasChanged: numeric_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  previousValue: numeric_comparison_exp
  slotName: String_comparison_exp
  srcMacId: String_comparison_exp
  time: timestamptz_comparison_exp
}

input sensorflow_get_event_stream_view_inc_input {
  currentValue: numeric
  hasChanged: numeric
  previousValue: numeric
}

input sensorflow_get_event_stream_view_insert_input {
  changedBy: String
  currentValue: numeric
  eventType: String
  hasChanged: numeric
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  previousValue: numeric
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_get_event_stream_view_max_fields {
  changedBy: String
  currentValue: numeric
  eventType: String
  hasChanged: numeric
  positionId: uuid
  previousValue: numeric
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_get_event_stream_view_min_fields {
  changedBy: String
  currentValue: numeric
  eventType: String
  hasChanged: numeric
  positionId: uuid
  previousValue: numeric
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_get_event_stream_view_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_get_event_stream_view!]!
}

input sensorflow_get_event_stream_view_order_by {
  changedBy: order_by
  currentValue: order_by
  eventType: order_by
  hasChanged: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  previousValue: order_by
  slotName: order_by
  srcMacId: order_by
  time: order_by
}

enum sensorflow_get_event_stream_view_select_column {
  changedBy
  currentValue
  eventType
  hasChanged
  positionId
  previousValue
  slotName
  srcMacId
  time
}

input sensorflow_get_event_stream_view_set_input {
  changedBy: String
  currentValue: numeric
  eventType: String
  hasChanged: numeric
  positionId: uuid
  previousValue: numeric
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_get_event_stream_view_stddev_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

type sensorflow_get_event_stream_view_stddev_pop_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

type sensorflow_get_event_stream_view_stddev_samp_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

input sensorflow_get_event_stream_view_stream_cursor_input {
  initial_value: sensorflow_get_event_stream_view_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_get_event_stream_view_stream_cursor_value_input {
  changedBy: String
  currentValue: numeric
  eventType: String
  hasChanged: numeric
  positionId: uuid
  previousValue: numeric
  slotName: String
  srcMacId: String
  time: timestamptz
}

type sensorflow_get_event_stream_view_sum_fields {
  currentValue: numeric
  hasChanged: numeric
  previousValue: numeric
}

input sensorflow_get_event_stream_view_updates {
  _inc: sensorflow_get_event_stream_view_inc_input
  _set: sensorflow_get_event_stream_view_set_input
  where: sensorflow_get_event_stream_view_bool_exp!
}

type sensorflow_get_event_stream_view_var_pop_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

type sensorflow_get_event_stream_view_var_samp_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

type sensorflow_get_event_stream_view_variance_fields {
  currentValue: Float
  hasChanged: Float
  previousValue: Float
}

type sensorflow_get_precool_command_streams {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_get_precool_command_streams_aggregate {
  aggregate: sensorflow_get_precool_command_streams_aggregate_fields
  nodes: [sensorflow_get_precool_command_streams!]!
}

type sensorflow_get_precool_command_streams_aggregate_fields {
  count(columns: [sensorflow_get_precool_command_streams_select_column!], distinct: Boolean): Int!
  max: sensorflow_get_precool_command_streams_max_fields
  min: sensorflow_get_precool_command_streams_min_fields
}

input sensorflow_get_precool_command_streams_bool_exp {
  _and: [sensorflow_get_precool_command_streams_bool_exp!]
  _not: sensorflow_get_precool_command_streams_bool_exp
  _or: [sensorflow_get_precool_command_streams_bool_exp!]
  ac_settings_type_changed: String_comparison_exp
  event_type: String_comparison_exp
  modified_by_user: String_comparison_exp
  new_ac_settings_value: String_comparison_exp
  position_id: uuid_comparison_exp
  previous_ac_settings_value: String_comparison_exp
  time: timestamptz_comparison_exp
  user_email: String_comparison_exp
}

type sensorflow_get_precool_command_streams_max_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_get_precool_command_streams_min_fields {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

input sensorflow_get_precool_command_streams_order_by {
  ac_settings_type_changed: order_by
  event_type: order_by
  modified_by_user: order_by
  new_ac_settings_value: order_by
  position_id: order_by
  previous_ac_settings_value: order_by
  time: order_by
  user_email: order_by
}

enum sensorflow_get_precool_command_streams_select_column {
  ac_settings_type_changed
  event_type
  modified_by_user
  new_ac_settings_value
  position_id
  previous_ac_settings_value
  time
  user_email
}

input sensorflow_get_precool_command_streams_stream_cursor_input {
  initial_value: sensorflow_get_precool_command_streams_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_get_precool_command_streams_stream_cursor_value_input {
  ac_settings_type_changed: String
  event_type: String
  modified_by_user: String
  new_ac_settings_value: String
  position_id: uuid
  previous_ac_settings_value: String
  time: timestamptz
  user_email: String
}

type sensorflow_key_categories {
  categoryName: String!
  id: uuid!
  keyCategoryPositionMappings(distinct_on: [sensorflow_key_category_to_key_position_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_to_key_position_order_by!], where: sensorflow_key_category_to_key_position_bool_exp): [sensorflow_key_category_to_key_position!]!
  keyCategoryPositionMappings_aggregate(distinct_on: [sensorflow_key_category_to_key_position_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_to_key_position_order_by!], where: sensorflow_key_category_to_key_position_bool_exp): sensorflow_key_category_to_key_position_aggregate!
  keyCategoryTemplate: sensorflow_key_category_templates
  location: sensorflow_locations!
  locationName: String!
}

type sensorflow_key_categories_aggregate {
  aggregate: sensorflow_key_categories_aggregate_fields
  nodes: [sensorflow_key_categories!]!
}

input sensorflow_key_categories_aggregate_bool_exp {
  count: sensorflow_key_categories_aggregate_bool_exp_count
}

input sensorflow_key_categories_aggregate_bool_exp_count {
  arguments: [sensorflow_key_categories_select_column!]
  distinct: Boolean
  filter: sensorflow_key_categories_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_key_categories_aggregate_fields {
  count(columns: [sensorflow_key_categories_select_column!], distinct: Boolean): Int!
  max: sensorflow_key_categories_max_fields
  min: sensorflow_key_categories_min_fields
}

input sensorflow_key_categories_aggregate_order_by {
  count: order_by
  max: sensorflow_key_categories_max_order_by
  min: sensorflow_key_categories_min_order_by
}

input sensorflow_key_categories_arr_rel_insert_input {
  data: [sensorflow_key_categories_insert_input!]!
  on_conflict: sensorflow_key_categories_on_conflict
}

input sensorflow_key_categories_bool_exp {
  _and: [sensorflow_key_categories_bool_exp!]
  _not: sensorflow_key_categories_bool_exp
  _or: [sensorflow_key_categories_bool_exp!]
  categoryName: String_comparison_exp
  id: uuid_comparison_exp
  keyCategoryPositionMappings: sensorflow_key_category_to_key_position_bool_exp
  keyCategoryPositionMappings_aggregate: sensorflow_key_category_to_key_position_aggregate_bool_exp
  keyCategoryTemplate: sensorflow_key_category_templates_bool_exp
  location: sensorflow_locations_bool_exp
  locationName: String_comparison_exp
}

enum sensorflow_key_categories_constraint {
  key_categories_pkey
  key_categories_unique_idx
}

input sensorflow_key_categories_insert_input {
  categoryName: String
  id: uuid
  keyCategoryPositionMappings: sensorflow_key_category_to_key_position_arr_rel_insert_input
  keyCategoryTemplate: sensorflow_key_category_templates_obj_rel_insert_input
  location: sensorflow_locations_obj_rel_insert_input
  locationName: String
}

type sensorflow_key_categories_max_fields {
  categoryName: String
  id: uuid
  locationName: String
}

input sensorflow_key_categories_max_order_by {
  categoryName: order_by
  id: order_by
  locationName: order_by
}

type sensorflow_key_categories_min_fields {
  categoryName: String
  id: uuid
  locationName: String
}

input sensorflow_key_categories_min_order_by {
  categoryName: order_by
  id: order_by
  locationName: order_by
}

type sensorflow_key_categories_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_key_categories!]!
}

input sensorflow_key_categories_obj_rel_insert_input {
  data: sensorflow_key_categories_insert_input!
  on_conflict: sensorflow_key_categories_on_conflict
}

input sensorflow_key_categories_on_conflict {
  constraint: sensorflow_key_categories_constraint!
  update_columns: [sensorflow_key_categories_update_column!]! = []
  where: sensorflow_key_categories_bool_exp
}

input sensorflow_key_categories_order_by {
  categoryName: order_by
  id: order_by
  keyCategoryPositionMappings_aggregate: sensorflow_key_category_to_key_position_aggregate_order_by
  keyCategoryTemplate: sensorflow_key_category_templates_order_by
  location: sensorflow_locations_order_by
  locationName: order_by
}

input sensorflow_key_categories_pk_columns_input {
  id: uuid!
}

enum sensorflow_key_categories_select_column {
  categoryName
  id
  locationName
}

input sensorflow_key_categories_set_input {
  categoryName: String
  id: uuid
  locationName: String
}

input sensorflow_key_categories_stream_cursor_input {
  initial_value: sensorflow_key_categories_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_key_categories_stream_cursor_value_input {
  categoryName: String
  id: uuid
  locationName: String
}

enum sensorflow_key_categories_update_column {
  categoryName
  id
  locationName
}

input sensorflow_key_categories_updates {
  _set: sensorflow_key_categories_set_input
  where: sensorflow_key_categories_bool_exp!
}

type sensorflow_key_category_room_templates {
  id: uuid!
  keyCategoryTemplate: sensorflow_key_category_templates!
  keyCategoryTemplateId: uuid!
  roomName: String!
  slotTemplates(distinct_on: [sensorflow_key_category_slot_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_slot_templates_order_by!], where: sensorflow_key_category_slot_templates_bool_exp): [sensorflow_key_category_slot_templates!]!
  slotTemplates_aggregate(distinct_on: [sensorflow_key_category_slot_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_slot_templates_order_by!], where: sensorflow_key_category_slot_templates_bool_exp): sensorflow_key_category_slot_templates_aggregate!
}

type sensorflow_key_category_room_templates_aggregate {
  aggregate: sensorflow_key_category_room_templates_aggregate_fields
  nodes: [sensorflow_key_category_room_templates!]!
}

input sensorflow_key_category_room_templates_aggregate_bool_exp {
  count: sensorflow_key_category_room_templates_aggregate_bool_exp_count
}

input sensorflow_key_category_room_templates_aggregate_bool_exp_count {
  arguments: [sensorflow_key_category_room_templates_select_column!]
  distinct: Boolean
  filter: sensorflow_key_category_room_templates_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_key_category_room_templates_aggregate_fields {
  count(columns: [sensorflow_key_category_room_templates_select_column!], distinct: Boolean): Int!
  max: sensorflow_key_category_room_templates_max_fields
  min: sensorflow_key_category_room_templates_min_fields
}

input sensorflow_key_category_room_templates_aggregate_order_by {
  count: order_by
  max: sensorflow_key_category_room_templates_max_order_by
  min: sensorflow_key_category_room_templates_min_order_by
}

input sensorflow_key_category_room_templates_arr_rel_insert_input {
  data: [sensorflow_key_category_room_templates_insert_input!]!
  on_conflict: sensorflow_key_category_room_templates_on_conflict
}

input sensorflow_key_category_room_templates_bool_exp {
  _and: [sensorflow_key_category_room_templates_bool_exp!]
  _not: sensorflow_key_category_room_templates_bool_exp
  _or: [sensorflow_key_category_room_templates_bool_exp!]
  id: uuid_comparison_exp
  keyCategoryTemplate: sensorflow_key_category_templates_bool_exp
  keyCategoryTemplateId: uuid_comparison_exp
  roomName: String_comparison_exp
  slotTemplates: sensorflow_key_category_slot_templates_bool_exp
  slotTemplates_aggregate: sensorflow_key_category_slot_templates_aggregate_bool_exp
}

enum sensorflow_key_category_room_templates_constraint {
  key_category_room_templates_pkey
  unique_key_category_room_template
}

input sensorflow_key_category_room_templates_insert_input {
  id: uuid
  keyCategoryTemplate: sensorflow_key_category_templates_obj_rel_insert_input
  keyCategoryTemplateId: uuid
  roomName: String
  slotTemplates: sensorflow_key_category_slot_templates_arr_rel_insert_input
}

type sensorflow_key_category_room_templates_max_fields {
  id: uuid
  keyCategoryTemplateId: uuid
  roomName: String
}

input sensorflow_key_category_room_templates_max_order_by {
  id: order_by
  keyCategoryTemplateId: order_by
  roomName: order_by
}

type sensorflow_key_category_room_templates_min_fields {
  id: uuid
  keyCategoryTemplateId: uuid
  roomName: String
}

input sensorflow_key_category_room_templates_min_order_by {
  id: order_by
  keyCategoryTemplateId: order_by
  roomName: order_by
}

type sensorflow_key_category_room_templates_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_key_category_room_templates!]!
}

input sensorflow_key_category_room_templates_obj_rel_insert_input {
  data: sensorflow_key_category_room_templates_insert_input!
  on_conflict: sensorflow_key_category_room_templates_on_conflict
}

input sensorflow_key_category_room_templates_on_conflict {
  constraint: sensorflow_key_category_room_templates_constraint!
  update_columns: [sensorflow_key_category_room_templates_update_column!]! = []
  where: sensorflow_key_category_room_templates_bool_exp
}

input sensorflow_key_category_room_templates_order_by {
  id: order_by
  keyCategoryTemplate: sensorflow_key_category_templates_order_by
  keyCategoryTemplateId: order_by
  roomName: order_by
  slotTemplates_aggregate: sensorflow_key_category_slot_templates_aggregate_order_by
}

input sensorflow_key_category_room_templates_pk_columns_input {
  id: uuid!
}

enum sensorflow_key_category_room_templates_select_column {
  id
  keyCategoryTemplateId
  roomName
}

input sensorflow_key_category_room_templates_set_input {
  id: uuid
  keyCategoryTemplateId: uuid
  roomName: String
}

input sensorflow_key_category_room_templates_stream_cursor_input {
  initial_value: sensorflow_key_category_room_templates_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_key_category_room_templates_stream_cursor_value_input {
  id: uuid
  keyCategoryTemplateId: uuid
  roomName: String
}

enum sensorflow_key_category_room_templates_update_column {
  id
  keyCategoryTemplateId
  roomName
}

input sensorflow_key_category_room_templates_updates {
  _set: sensorflow_key_category_room_templates_set_input
  where: sensorflow_key_category_room_templates_bool_exp!
}

type sensorflow_key_category_slot_templates {
  acModelId: uuid
  actingMode: String
  id: uuid!
  nodeSubType: String
  nodeType: String!
  operationalMode: String
  roomTemplate: sensorflow_key_category_room_templates!
  roomTemplateId: uuid!
  slotName: String!
}

type sensorflow_key_category_slot_templates_aggregate {
  aggregate: sensorflow_key_category_slot_templates_aggregate_fields
  nodes: [sensorflow_key_category_slot_templates!]!
}

input sensorflow_key_category_slot_templates_aggregate_bool_exp {
  count: sensorflow_key_category_slot_templates_aggregate_bool_exp_count
}

input sensorflow_key_category_slot_templates_aggregate_bool_exp_count {
  arguments: [sensorflow_key_category_slot_templates_select_column!]
  distinct: Boolean
  filter: sensorflow_key_category_slot_templates_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_key_category_slot_templates_aggregate_fields {
  count(columns: [sensorflow_key_category_slot_templates_select_column!], distinct: Boolean): Int!
  max: sensorflow_key_category_slot_templates_max_fields
  min: sensorflow_key_category_slot_templates_min_fields
}

input sensorflow_key_category_slot_templates_aggregate_order_by {
  count: order_by
  max: sensorflow_key_category_slot_templates_max_order_by
  min: sensorflow_key_category_slot_templates_min_order_by
}

input sensorflow_key_category_slot_templates_arr_rel_insert_input {
  data: [sensorflow_key_category_slot_templates_insert_input!]!
  on_conflict: sensorflow_key_category_slot_templates_on_conflict
}

input sensorflow_key_category_slot_templates_bool_exp {
  _and: [sensorflow_key_category_slot_templates_bool_exp!]
  _not: sensorflow_key_category_slot_templates_bool_exp
  _or: [sensorflow_key_category_slot_templates_bool_exp!]
  acModelId: uuid_comparison_exp
  actingMode: String_comparison_exp
  id: uuid_comparison_exp
  nodeSubType: String_comparison_exp
  nodeType: String_comparison_exp
  operationalMode: String_comparison_exp
  roomTemplate: sensorflow_key_category_room_templates_bool_exp
  roomTemplateId: uuid_comparison_exp
  slotName: String_comparison_exp
}

enum sensorflow_key_category_slot_templates_constraint {
  key_category_slot_templates_pkey
  unique_key_category_room_slot_template
}

input sensorflow_key_category_slot_templates_insert_input {
  acModelId: uuid
  actingMode: String
  id: uuid
  nodeSubType: String
  nodeType: String
  operationalMode: String
  roomTemplate: sensorflow_key_category_room_templates_obj_rel_insert_input
  roomTemplateId: uuid
  slotName: String
}

type sensorflow_key_category_slot_templates_max_fields {
  acModelId: uuid
  actingMode: String
  id: uuid
  nodeSubType: String
  nodeType: String
  operationalMode: String
  roomTemplateId: uuid
  slotName: String
}

input sensorflow_key_category_slot_templates_max_order_by {
  acModelId: order_by
  actingMode: order_by
  id: order_by
  nodeSubType: order_by
  nodeType: order_by
  operationalMode: order_by
  roomTemplateId: order_by
  slotName: order_by
}

type sensorflow_key_category_slot_templates_min_fields {
  acModelId: uuid
  actingMode: String
  id: uuid
  nodeSubType: String
  nodeType: String
  operationalMode: String
  roomTemplateId: uuid
  slotName: String
}

input sensorflow_key_category_slot_templates_min_order_by {
  acModelId: order_by
  actingMode: order_by
  id: order_by
  nodeSubType: order_by
  nodeType: order_by
  operationalMode: order_by
  roomTemplateId: order_by
  slotName: order_by
}

type sensorflow_key_category_slot_templates_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_key_category_slot_templates!]!
}

input sensorflow_key_category_slot_templates_on_conflict {
  constraint: sensorflow_key_category_slot_templates_constraint!
  update_columns: [sensorflow_key_category_slot_templates_update_column!]! = []
  where: sensorflow_key_category_slot_templates_bool_exp
}

input sensorflow_key_category_slot_templates_order_by {
  acModelId: order_by
  actingMode: order_by
  id: order_by
  nodeSubType: order_by
  nodeType: order_by
  operationalMode: order_by
  roomTemplate: sensorflow_key_category_room_templates_order_by
  roomTemplateId: order_by
  slotName: order_by
}

input sensorflow_key_category_slot_templates_pk_columns_input {
  id: uuid!
}

enum sensorflow_key_category_slot_templates_select_column {
  acModelId
  actingMode
  id
  nodeSubType
  nodeType
  operationalMode
  roomTemplateId
  slotName
}

input sensorflow_key_category_slot_templates_set_input {
  acModelId: uuid
  actingMode: String
  id: uuid
  nodeSubType: String
  nodeType: String
  operationalMode: String
  roomTemplateId: uuid
  slotName: String
}

input sensorflow_key_category_slot_templates_stream_cursor_input {
  initial_value: sensorflow_key_category_slot_templates_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_key_category_slot_templates_stream_cursor_value_input {
  acModelId: uuid
  actingMode: String
  id: uuid
  nodeSubType: String
  nodeType: String
  operationalMode: String
  roomTemplateId: uuid
  slotName: String
}

enum sensorflow_key_category_slot_templates_update_column {
  acModelId
  actingMode
  id
  nodeSubType
  nodeType
  operationalMode
  roomTemplateId
  slotName
}

input sensorflow_key_category_slot_templates_updates {
  _set: sensorflow_key_category_slot_templates_set_input
  where: sensorflow_key_category_slot_templates_bool_exp!
}

type sensorflow_key_category_templates {
  categoryName: String!
  id: uuid!
  keyCategory: sensorflow_key_categories
  location: sensorflow_locations
  locationName: String!
  roomTemplates(distinct_on: [sensorflow_key_category_room_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_room_templates_order_by!], where: sensorflow_key_category_room_templates_bool_exp): [sensorflow_key_category_room_templates!]!
  roomTemplates_aggregate(distinct_on: [sensorflow_key_category_room_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_room_templates_order_by!], where: sensorflow_key_category_room_templates_bool_exp): sensorflow_key_category_room_templates_aggregate!
}

type sensorflow_key_category_templates_aggregate {
  aggregate: sensorflow_key_category_templates_aggregate_fields
  nodes: [sensorflow_key_category_templates!]!
}

type sensorflow_key_category_templates_aggregate_fields {
  count(columns: [sensorflow_key_category_templates_select_column!], distinct: Boolean): Int!
  max: sensorflow_key_category_templates_max_fields
  min: sensorflow_key_category_templates_min_fields
}

input sensorflow_key_category_templates_bool_exp {
  _and: [sensorflow_key_category_templates_bool_exp!]
  _not: sensorflow_key_category_templates_bool_exp
  _or: [sensorflow_key_category_templates_bool_exp!]
  categoryName: String_comparison_exp
  id: uuid_comparison_exp
  keyCategory: sensorflow_key_categories_bool_exp
  location: sensorflow_locations_bool_exp
  locationName: String_comparison_exp
  roomTemplates: sensorflow_key_category_room_templates_bool_exp
  roomTemplates_aggregate: sensorflow_key_category_room_templates_aggregate_bool_exp
}

enum sensorflow_key_category_templates_constraint {
  key_category_templates_pkey
  unique_category_name_location_name
}

input sensorflow_key_category_templates_insert_input {
  categoryName: String
  id: uuid
  keyCategory: sensorflow_key_categories_obj_rel_insert_input
  location: sensorflow_locations_obj_rel_insert_input
  locationName: String
  roomTemplates: sensorflow_key_category_room_templates_arr_rel_insert_input
}

type sensorflow_key_category_templates_max_fields {
  categoryName: String
  id: uuid
  locationName: String
}

type sensorflow_key_category_templates_min_fields {
  categoryName: String
  id: uuid
  locationName: String
}

type sensorflow_key_category_templates_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_key_category_templates!]!
}

input sensorflow_key_category_templates_obj_rel_insert_input {
  data: sensorflow_key_category_templates_insert_input!
  on_conflict: sensorflow_key_category_templates_on_conflict
}

input sensorflow_key_category_templates_on_conflict {
  constraint: sensorflow_key_category_templates_constraint!
  update_columns: [sensorflow_key_category_templates_update_column!]! = []
  where: sensorflow_key_category_templates_bool_exp
}

input sensorflow_key_category_templates_order_by {
  categoryName: order_by
  id: order_by
  keyCategory: sensorflow_key_categories_order_by
  location: sensorflow_locations_order_by
  locationName: order_by
  roomTemplates_aggregate: sensorflow_key_category_room_templates_aggregate_order_by
}

input sensorflow_key_category_templates_pk_columns_input {
  id: uuid!
}

enum sensorflow_key_category_templates_select_column {
  categoryName
  id
  locationName
}

input sensorflow_key_category_templates_set_input {
  categoryName: String
  id: uuid
  locationName: String
}

input sensorflow_key_category_templates_stream_cursor_input {
  initial_value: sensorflow_key_category_templates_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_key_category_templates_stream_cursor_value_input {
  categoryName: String
  id: uuid
  locationName: String
}

enum sensorflow_key_category_templates_update_column {
  categoryName
  id
  locationName
}

input sensorflow_key_category_templates_updates {
  _set: sensorflow_key_category_templates_set_input
  where: sensorflow_key_category_templates_bool_exp!
}

type sensorflow_key_category_to_key_position {
  categoryName: String!
  keyCategory: sensorflow_key_categories
  locationName: String!
  position: sensorflow_positions!
  positionId: uuid!
}

type sensorflow_key_category_to_key_position_aggregate {
  aggregate: sensorflow_key_category_to_key_position_aggregate_fields
  nodes: [sensorflow_key_category_to_key_position!]!
}

input sensorflow_key_category_to_key_position_aggregate_bool_exp {
  count: sensorflow_key_category_to_key_position_aggregate_bool_exp_count
}

input sensorflow_key_category_to_key_position_aggregate_bool_exp_count {
  arguments: [sensorflow_key_category_to_key_position_select_column!]
  distinct: Boolean
  filter: sensorflow_key_category_to_key_position_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_key_category_to_key_position_aggregate_fields {
  count(columns: [sensorflow_key_category_to_key_position_select_column!], distinct: Boolean): Int!
  max: sensorflow_key_category_to_key_position_max_fields
  min: sensorflow_key_category_to_key_position_min_fields
}

input sensorflow_key_category_to_key_position_aggregate_order_by {
  count: order_by
  max: sensorflow_key_category_to_key_position_max_order_by
  min: sensorflow_key_category_to_key_position_min_order_by
}

input sensorflow_key_category_to_key_position_arr_rel_insert_input {
  data: [sensorflow_key_category_to_key_position_insert_input!]!
  on_conflict: sensorflow_key_category_to_key_position_on_conflict
}

input sensorflow_key_category_to_key_position_bool_exp {
  _and: [sensorflow_key_category_to_key_position_bool_exp!]
  _not: sensorflow_key_category_to_key_position_bool_exp
  _or: [sensorflow_key_category_to_key_position_bool_exp!]
  categoryName: String_comparison_exp
  keyCategory: sensorflow_key_categories_bool_exp
  locationName: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

enum sensorflow_key_category_to_key_position_constraint {
  key_category_to_key_position_pkey
  unique_position_id
}

input sensorflow_key_category_to_key_position_insert_input {
  categoryName: String
  keyCategory: sensorflow_key_categories_obj_rel_insert_input
  locationName: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_key_category_to_key_position_max_fields {
  categoryName: String
  locationName: String
  positionId: uuid
}

input sensorflow_key_category_to_key_position_max_order_by {
  categoryName: order_by
  locationName: order_by
  positionId: order_by
}

type sensorflow_key_category_to_key_position_min_fields {
  categoryName: String
  locationName: String
  positionId: uuid
}

input sensorflow_key_category_to_key_position_min_order_by {
  categoryName: order_by
  locationName: order_by
  positionId: order_by
}

type sensorflow_key_category_to_key_position_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_key_category_to_key_position!]!
}

input sensorflow_key_category_to_key_position_obj_rel_insert_input {
  data: sensorflow_key_category_to_key_position_insert_input!
  on_conflict: sensorflow_key_category_to_key_position_on_conflict
}

input sensorflow_key_category_to_key_position_on_conflict {
  constraint: sensorflow_key_category_to_key_position_constraint!
  update_columns: [sensorflow_key_category_to_key_position_update_column!]! = []
  where: sensorflow_key_category_to_key_position_bool_exp
}

input sensorflow_key_category_to_key_position_order_by {
  categoryName: order_by
  keyCategory: sensorflow_key_categories_order_by
  locationName: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

input sensorflow_key_category_to_key_position_pk_columns_input {
  categoryName: String!
  locationName: String!
  positionId: uuid!
}

enum sensorflow_key_category_to_key_position_select_column {
  categoryName
  locationName
  positionId
}

input sensorflow_key_category_to_key_position_set_input {
  categoryName: String
  locationName: String
  positionId: uuid
}

input sensorflow_key_category_to_key_position_stream_cursor_input {
  initial_value: sensorflow_key_category_to_key_position_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_key_category_to_key_position_stream_cursor_value_input {
  categoryName: String
  locationName: String
  positionId: uuid
}

enum sensorflow_key_category_to_key_position_update_column {
  categoryName
  locationName
  positionId
}

input sensorflow_key_category_to_key_position_updates {
  _set: sensorflow_key_category_to_key_position_set_input
  where: sensorflow_key_category_to_key_position_bool_exp!
}

type sensorflow_location_domo_embeds {
  domo_embed: sensorflow_domo_embeds!
  embed_id: String!
  location: sensorflow_locations!
  location_id: uuid!
  location_to_user_mappings(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  location_to_user_mappings_aggregate(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): sensorflow_location_to_user_mapping_aggregate!
}

type sensorflow_location_domo_embeds_aggregate {
  aggregate: sensorflow_location_domo_embeds_aggregate_fields
  nodes: [sensorflow_location_domo_embeds!]!
}

type sensorflow_location_domo_embeds_aggregate_fields {
  count(columns: [sensorflow_location_domo_embeds_select_column!], distinct: Boolean): Int!
  max: sensorflow_location_domo_embeds_max_fields
  min: sensorflow_location_domo_embeds_min_fields
}

input sensorflow_location_domo_embeds_bool_exp {
  _and: [sensorflow_location_domo_embeds_bool_exp!]
  _not: sensorflow_location_domo_embeds_bool_exp
  _or: [sensorflow_location_domo_embeds_bool_exp!]
  domo_embed: sensorflow_domo_embeds_bool_exp
  embed_id: String_comparison_exp
  location: sensorflow_locations_bool_exp
  location_id: uuid_comparison_exp
  location_to_user_mappings: sensorflow_location_to_user_mapping_bool_exp
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_bool_exp
}

enum sensorflow_location_domo_embeds_constraint {
  location_domo_embeds_pkey
}

input sensorflow_location_domo_embeds_insert_input {
  domo_embed: sensorflow_domo_embeds_obj_rel_insert_input
  embed_id: String
  location: sensorflow_locations_obj_rel_insert_input
  location_id: uuid
  location_to_user_mappings: sensorflow_location_to_user_mapping_arr_rel_insert_input
}

type sensorflow_location_domo_embeds_max_fields {
  embed_id: String
  location_id: uuid
}

type sensorflow_location_domo_embeds_min_fields {
  embed_id: String
  location_id: uuid
}

type sensorflow_location_domo_embeds_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_location_domo_embeds!]!
}

input sensorflow_location_domo_embeds_on_conflict {
  constraint: sensorflow_location_domo_embeds_constraint!
  update_columns: [sensorflow_location_domo_embeds_update_column!]! = []
  where: sensorflow_location_domo_embeds_bool_exp
}

input sensorflow_location_domo_embeds_order_by {
  domo_embed: sensorflow_domo_embeds_order_by
  embed_id: order_by
  location: sensorflow_locations_order_by
  location_id: order_by
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_order_by
}

input sensorflow_location_domo_embeds_pk_columns_input {
  embed_id: String!
  location_id: uuid!
}

enum sensorflow_location_domo_embeds_select_column {
  embed_id
  location_id
}

input sensorflow_location_domo_embeds_set_input {
  embed_id: String
  location_id: uuid
}

input sensorflow_location_domo_embeds_stream_cursor_input {
  initial_value: sensorflow_location_domo_embeds_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_location_domo_embeds_stream_cursor_value_input {
  embed_id: String
  location_id: uuid
}

enum sensorflow_location_domo_embeds_update_column {
  embed_id
  location_id
}

input sensorflow_location_domo_embeds_updates {
  _set: sensorflow_location_domo_embeds_set_input
  where: sensorflow_location_domo_embeds_bool_exp!
}

type sensorflow_location_metadata {
  activeAutosetCount: Int
  allowDisableAutomationMode: Boolean
  availableOperationalModes: String
  beaconSlotManagement: Boolean
  enableNoKeyCardState: Boolean
  gatewayHardwareType: String
  gatewayPromiscuousModeEnabled: Boolean
  hasKeyCard: Boolean
  isAutomationModeDisabled: Boolean
  location: sensorflow_locations!
  locationId: uuid!
  location_to_user_mappings(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  location_to_user_mappings_aggregate(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): sensorflow_location_to_user_mapping_aggregate!
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  showEnergyConsumption: Boolean
  showHeatingMode: Boolean
  smartRemValidation: Boolean
  smplrspaceId: uuid
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

type sensorflow_location_metadata_aggregate {
  aggregate: sensorflow_location_metadata_aggregate_fields
  nodes: [sensorflow_location_metadata!]!
}

type sensorflow_location_metadata_aggregate_fields {
  avg: sensorflow_location_metadata_avg_fields
  count(columns: [sensorflow_location_metadata_select_column!], distinct: Boolean): Int!
  max: sensorflow_location_metadata_max_fields
  min: sensorflow_location_metadata_min_fields
  stddev: sensorflow_location_metadata_stddev_fields
  stddev_pop: sensorflow_location_metadata_stddev_pop_fields
  stddev_samp: sensorflow_location_metadata_stddev_samp_fields
  sum: sensorflow_location_metadata_sum_fields
  var_pop: sensorflow_location_metadata_var_pop_fields
  var_samp: sensorflow_location_metadata_var_samp_fields
  variance: sensorflow_location_metadata_variance_fields
}

type sensorflow_location_metadata_avg_fields {
  activeAutosetCount: Float
}

input sensorflow_location_metadata_bool_exp {
  _and: [sensorflow_location_metadata_bool_exp!]
  _not: sensorflow_location_metadata_bool_exp
  _or: [sensorflow_location_metadata_bool_exp!]
  activeAutosetCount: Int_comparison_exp
  allowDisableAutomationMode: Boolean_comparison_exp
  availableOperationalModes: String_comparison_exp
  beaconSlotManagement: Boolean_comparison_exp
  enableNoKeyCardState: Boolean_comparison_exp
  gatewayHardwareType: String_comparison_exp
  gatewayPromiscuousModeEnabled: Boolean_comparison_exp
  hasKeyCard: Boolean_comparison_exp
  isAutomationModeDisabled: Boolean_comparison_exp
  location: sensorflow_locations_bool_exp
  locationId: uuid_comparison_exp
  location_to_user_mappings: sensorflow_location_to_user_mapping_bool_exp
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_bool_exp
  secondaryWifiPassword: String_comparison_exp
  secondaryWifiSsid: String_comparison_exp
  showEnergyConsumption: Boolean_comparison_exp
  showHeatingMode: Boolean_comparison_exp
  smartRemValidation: Boolean_comparison_exp
  smplrspaceId: uuid_comparison_exp
  subscription: String_comparison_exp
  wifiPassword: String_comparison_exp
  wifiSsid: String_comparison_exp
}

enum sensorflow_location_metadata_constraint {
  location_metadata_pkey
}

input sensorflow_location_metadata_inc_input {
  activeAutosetCount: Int
}

input sensorflow_location_metadata_insert_input {
  activeAutosetCount: Int
  allowDisableAutomationMode: Boolean
  availableOperationalModes: String
  beaconSlotManagement: Boolean
  enableNoKeyCardState: Boolean
  gatewayHardwareType: String
  gatewayPromiscuousModeEnabled: Boolean
  hasKeyCard: Boolean
  isAutomationModeDisabled: Boolean
  location: sensorflow_locations_obj_rel_insert_input
  locationId: uuid
  location_to_user_mappings: sensorflow_location_to_user_mapping_arr_rel_insert_input
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  showEnergyConsumption: Boolean
  showHeatingMode: Boolean
  smartRemValidation: Boolean
  smplrspaceId: uuid
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

type sensorflow_location_metadata_max_fields {
  activeAutosetCount: Int
  availableOperationalModes: String
  gatewayHardwareType: String
  locationId: uuid
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  smplrspaceId: uuid
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

type sensorflow_location_metadata_min_fields {
  activeAutosetCount: Int
  availableOperationalModes: String
  gatewayHardwareType: String
  locationId: uuid
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  smplrspaceId: uuid
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

type sensorflow_location_metadata_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_location_metadata!]!
}

input sensorflow_location_metadata_on_conflict {
  constraint: sensorflow_location_metadata_constraint!
  update_columns: [sensorflow_location_metadata_update_column!]! = []
  where: sensorflow_location_metadata_bool_exp
}

input sensorflow_location_metadata_order_by {
  activeAutosetCount: order_by
  allowDisableAutomationMode: order_by
  availableOperationalModes: order_by
  beaconSlotManagement: order_by
  enableNoKeyCardState: order_by
  gatewayHardwareType: order_by
  gatewayPromiscuousModeEnabled: order_by
  hasKeyCard: order_by
  isAutomationModeDisabled: order_by
  location: sensorflow_locations_order_by
  locationId: order_by
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_order_by
  secondaryWifiPassword: order_by
  secondaryWifiSsid: order_by
  showEnergyConsumption: order_by
  showHeatingMode: order_by
  smartRemValidation: order_by
  smplrspaceId: order_by
  subscription: order_by
  wifiPassword: order_by
  wifiSsid: order_by
}

input sensorflow_location_metadata_pk_columns_input {
  locationId: uuid!
}

enum sensorflow_location_metadata_select_column {
  activeAutosetCount
  allowDisableAutomationMode
  availableOperationalModes
  beaconSlotManagement
  enableNoKeyCardState
  gatewayHardwareType
  gatewayPromiscuousModeEnabled
  hasKeyCard
  isAutomationModeDisabled
  locationId
  secondaryWifiPassword
  secondaryWifiSsid
  showEnergyConsumption
  showHeatingMode
  smartRemValidation
  smplrspaceId
  subscription
  wifiPassword
  wifiSsid
}

input sensorflow_location_metadata_set_input {
  activeAutosetCount: Int
  allowDisableAutomationMode: Boolean
  availableOperationalModes: String
  beaconSlotManagement: Boolean
  enableNoKeyCardState: Boolean
  gatewayHardwareType: String
  gatewayPromiscuousModeEnabled: Boolean
  hasKeyCard: Boolean
  isAutomationModeDisabled: Boolean
  locationId: uuid
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  showEnergyConsumption: Boolean
  showHeatingMode: Boolean
  smartRemValidation: Boolean
  smplrspaceId: uuid
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

type sensorflow_location_metadata_stddev_fields {
  activeAutosetCount: Float
}

type sensorflow_location_metadata_stddev_pop_fields {
  activeAutosetCount: Float
}

type sensorflow_location_metadata_stddev_samp_fields {
  activeAutosetCount: Float
}

input sensorflow_location_metadata_stream_cursor_input {
  initial_value: sensorflow_location_metadata_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_location_metadata_stream_cursor_value_input {
  activeAutosetCount: Int
  allowDisableAutomationMode: Boolean
  availableOperationalModes: String
  beaconSlotManagement: Boolean
  enableNoKeyCardState: Boolean
  gatewayHardwareType: String
  gatewayPromiscuousModeEnabled: Boolean
  hasKeyCard: Boolean
  isAutomationModeDisabled: Boolean
  locationId: uuid
  secondaryWifiPassword: String
  secondaryWifiSsid: String
  showEnergyConsumption: Boolean
  showHeatingMode: Boolean
  smartRemValidation: Boolean
  smplrspaceId: uuid
  subscription: String
  wifiPassword: String
  wifiSsid: String
}

type sensorflow_location_metadata_sum_fields {
  activeAutosetCount: Int
}

enum sensorflow_location_metadata_update_column {
  activeAutosetCount
  allowDisableAutomationMode
  availableOperationalModes
  beaconSlotManagement
  enableNoKeyCardState
  gatewayHardwareType
  gatewayPromiscuousModeEnabled
  hasKeyCard
  isAutomationModeDisabled
  locationId
  secondaryWifiPassword
  secondaryWifiSsid
  showEnergyConsumption
  showHeatingMode
  smartRemValidation
  smplrspaceId
  subscription
  wifiPassword
  wifiSsid
}

input sensorflow_location_metadata_updates {
  _inc: sensorflow_location_metadata_inc_input
  _set: sensorflow_location_metadata_set_input
  where: sensorflow_location_metadata_bool_exp!
}

type sensorflow_location_metadata_var_pop_fields {
  activeAutosetCount: Float
}

type sensorflow_location_metadata_var_samp_fields {
  activeAutosetCount: Float
}

type sensorflow_location_metadata_variance_fields {
  activeAutosetCount: Float
}

type sensorflow_location_to_user_mapping {
  id: uuid!
  location: sensorflow_locations!
  location_id: uuid!
  user: sensorflow_users!
  user_id: String!
}

type sensorflow_location_to_user_mapping_aggregate {
  aggregate: sensorflow_location_to_user_mapping_aggregate_fields
  nodes: [sensorflow_location_to_user_mapping!]!
}

input sensorflow_location_to_user_mapping_aggregate_bool_exp {
  count: sensorflow_location_to_user_mapping_aggregate_bool_exp_count
}

input sensorflow_location_to_user_mapping_aggregate_bool_exp_count {
  arguments: [sensorflow_location_to_user_mapping_select_column!]
  distinct: Boolean
  filter: sensorflow_location_to_user_mapping_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_location_to_user_mapping_aggregate_fields {
  count(columns: [sensorflow_location_to_user_mapping_select_column!], distinct: Boolean): Int!
  max: sensorflow_location_to_user_mapping_max_fields
  min: sensorflow_location_to_user_mapping_min_fields
}

input sensorflow_location_to_user_mapping_aggregate_order_by {
  count: order_by
  max: sensorflow_location_to_user_mapping_max_order_by
  min: sensorflow_location_to_user_mapping_min_order_by
}

input sensorflow_location_to_user_mapping_arr_rel_insert_input {
  data: [sensorflow_location_to_user_mapping_insert_input!]!
  on_conflict: sensorflow_location_to_user_mapping_on_conflict
}

input sensorflow_location_to_user_mapping_bool_exp {
  _and: [sensorflow_location_to_user_mapping_bool_exp!]
  _not: sensorflow_location_to_user_mapping_bool_exp
  _or: [sensorflow_location_to_user_mapping_bool_exp!]
  id: uuid_comparison_exp
  location: sensorflow_locations_bool_exp
  location_id: uuid_comparison_exp
  user: sensorflow_users_bool_exp
  user_id: String_comparison_exp
}

enum sensorflow_location_to_user_mapping_constraint {
  location_to_user_mapping_pkey
  unique_location_id_user_id
}

input sensorflow_location_to_user_mapping_insert_input {
  id: uuid
  location: sensorflow_locations_obj_rel_insert_input
  location_id: uuid
  user: sensorflow_users_obj_rel_insert_input
  user_id: String
}

type sensorflow_location_to_user_mapping_max_fields {
  id: uuid
  location_id: uuid
  user_id: String
}

input sensorflow_location_to_user_mapping_max_order_by {
  id: order_by
  location_id: order_by
  user_id: order_by
}

type sensorflow_location_to_user_mapping_min_fields {
  id: uuid
  location_id: uuid
  user_id: String
}

input sensorflow_location_to_user_mapping_min_order_by {
  id: order_by
  location_id: order_by
  user_id: order_by
}

type sensorflow_location_to_user_mapping_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_location_to_user_mapping!]!
}

input sensorflow_location_to_user_mapping_on_conflict {
  constraint: sensorflow_location_to_user_mapping_constraint!
  update_columns: [sensorflow_location_to_user_mapping_update_column!]! = []
  where: sensorflow_location_to_user_mapping_bool_exp
}

input sensorflow_location_to_user_mapping_order_by {
  id: order_by
  location: sensorflow_locations_order_by
  location_id: order_by
  user: sensorflow_users_order_by
  user_id: order_by
}

input sensorflow_location_to_user_mapping_pk_columns_input {
  id: uuid!
}

enum sensorflow_location_to_user_mapping_select_column {
  id
  location_id
  user_id
}

input sensorflow_location_to_user_mapping_set_input {
  id: uuid
  location_id: uuid
  user_id: String
}

input sensorflow_location_to_user_mapping_stream_cursor_input {
  initial_value: sensorflow_location_to_user_mapping_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_location_to_user_mapping_stream_cursor_value_input {
  id: uuid
  location_id: uuid
  user_id: String
}

enum sensorflow_location_to_user_mapping_update_column {
  id
  location_id
  user_id
}

input sensorflow_location_to_user_mapping_updates {
  _set: sensorflow_location_to_user_mapping_set_input
  where: sensorflow_location_to_user_mapping_bool_exp!
}

type sensorflow_locations {
  activePropertyAlerts(distinct_on: [sf_support_active_property_alerts_select_column!], limit: Int, offset: Int, order_by: [sf_support_active_property_alerts_order_by!], where: sf_support_active_property_alerts_bool_exp): [sf_support_active_property_alerts!]!
  activePropertyAlerts_aggregate(distinct_on: [sf_support_active_property_alerts_select_column!], limit: Int, offset: Int, order_by: [sf_support_active_property_alerts_order_by!], where: sf_support_active_property_alerts_bool_exp): sf_support_active_property_alerts_aggregate!
  being_exported_to_clickup: Boolean
  clickupExportDisabled: Boolean!
  clickupExportProgress: numeric
  clickupListId: String
  clickup_assignee_id: String
  clickup_webhook_id: String
  clickup_webhook_secret: String
  currency: String
  emon_clickup_task_id: String
  expected_number_of_keys: String
  keyCategories(distinct_on: [sensorflow_key_categories_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_categories_order_by!], where: sensorflow_key_categories_bool_exp): [sensorflow_key_categories!]!
  keyCategories_aggregate(distinct_on: [sensorflow_key_categories_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_categories_order_by!], where: sensorflow_key_categories_bool_exp): sensorflow_key_categories_aggregate!
  language: String
  locationCurrency: sensorflow_currencies
  locationId: uuid!
  locationName: String!
  location_address: String
  location_mode: String!
  location_to_pms_property_mapping: sf_pms_pms_properties
  location_to_user_mappings(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  location_to_user_mappings_aggregate(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): sensorflow_location_to_user_mapping_aggregate!
  logo_url: String
  organisation: sensorflow_organisations
  organisation_id: uuid
  origin_latitude: float8
  origin_longitude: float8
  otaIsEnabled: Boolean
  otaMaxRetries: Int
  otaWindowEndTime: String
  otaWindowStartTime: String
  positions(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): [sensorflow_positions!]!
  positions_aggregate(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): sensorflow_positions_aggregate!
  searchPositions(args: searchPositions_sensorflow_locations_args!, distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): [sensorflow_positions!]
  timezone: String!
}

type sensorflow_locations_aggregate {
  aggregate: sensorflow_locations_aggregate_fields
  nodes: [sensorflow_locations!]!
}

input sensorflow_locations_aggregate_bool_exp {
  avg: sensorflow_locations_aggregate_bool_exp_avg
  bool_and: sensorflow_locations_aggregate_bool_exp_bool_and
  bool_or: sensorflow_locations_aggregate_bool_exp_bool_or
  corr: sensorflow_locations_aggregate_bool_exp_corr
  count: sensorflow_locations_aggregate_bool_exp_count
  covar_samp: sensorflow_locations_aggregate_bool_exp_covar_samp
  max: sensorflow_locations_aggregate_bool_exp_max
  min: sensorflow_locations_aggregate_bool_exp_min
  stddev_samp: sensorflow_locations_aggregate_bool_exp_stddev_samp
  sum: sensorflow_locations_aggregate_bool_exp_sum
  var_samp: sensorflow_locations_aggregate_bool_exp_var_samp
}

input sensorflow_locations_aggregate_bool_exp_avg {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_avg_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_bool_and {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_bool_or {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_corr {
  arguments: sensorflow_locations_aggregate_bool_exp_corr_arguments!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_corr_arguments {
  X: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_corr_arguments_columns!
  Y: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_corr_arguments_columns!
}

input sensorflow_locations_aggregate_bool_exp_count {
  arguments: [sensorflow_locations_select_column!]
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: Int_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_covar_samp {
  arguments: sensorflow_locations_aggregate_bool_exp_covar_samp_arguments!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_covar_samp_arguments {
  X: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_covar_samp_arguments_columns!
  Y: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_covar_samp_arguments_columns!
}

input sensorflow_locations_aggregate_bool_exp_max {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_max_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_min {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_min_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_stddev_samp {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_stddev_samp_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_sum {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_sum_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

input sensorflow_locations_aggregate_bool_exp_var_samp {
  arguments: sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_var_samp_arguments_columns!
  distinct: Boolean
  filter: sensorflow_locations_bool_exp
  predicate: float8_comparison_exp!
}

type sensorflow_locations_aggregate_fields {
  avg: sensorflow_locations_avg_fields
  count(columns: [sensorflow_locations_select_column!], distinct: Boolean): Int!
  max: sensorflow_locations_max_fields
  min: sensorflow_locations_min_fields
  stddev: sensorflow_locations_stddev_fields
  stddev_pop: sensorflow_locations_stddev_pop_fields
  stddev_samp: sensorflow_locations_stddev_samp_fields
  sum: sensorflow_locations_sum_fields
  var_pop: sensorflow_locations_var_pop_fields
  var_samp: sensorflow_locations_var_samp_fields
  variance: sensorflow_locations_variance_fields
}

input sensorflow_locations_aggregate_order_by {
  avg: sensorflow_locations_avg_order_by
  count: order_by
  max: sensorflow_locations_max_order_by
  min: sensorflow_locations_min_order_by
  stddev: sensorflow_locations_stddev_order_by
  stddev_pop: sensorflow_locations_stddev_pop_order_by
  stddev_samp: sensorflow_locations_stddev_samp_order_by
  sum: sensorflow_locations_sum_order_by
  var_pop: sensorflow_locations_var_pop_order_by
  var_samp: sensorflow_locations_var_samp_order_by
  variance: sensorflow_locations_variance_order_by
}

input sensorflow_locations_arr_rel_insert_input {
  data: [sensorflow_locations_insert_input!]!
  on_conflict: sensorflow_locations_on_conflict
}

type sensorflow_locations_avg_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_avg_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

input sensorflow_locations_bool_exp {
  _and: [sensorflow_locations_bool_exp!]
  _not: sensorflow_locations_bool_exp
  _or: [sensorflow_locations_bool_exp!]
  activePropertyAlerts: sf_support_active_property_alerts_bool_exp
  activePropertyAlerts_aggregate: sf_support_active_property_alerts_aggregate_bool_exp
  being_exported_to_clickup: Boolean_comparison_exp
  clickupExportDisabled: Boolean_comparison_exp
  clickupExportProgress: numeric_comparison_exp
  clickupListId: String_comparison_exp
  clickup_assignee_id: String_comparison_exp
  clickup_webhook_id: String_comparison_exp
  clickup_webhook_secret: String_comparison_exp
  currency: String_comparison_exp
  emon_clickup_task_id: String_comparison_exp
  expected_number_of_keys: String_comparison_exp
  keyCategories: sensorflow_key_categories_bool_exp
  keyCategories_aggregate: sensorflow_key_categories_aggregate_bool_exp
  language: String_comparison_exp
  locationCurrency: sensorflow_currencies_bool_exp
  locationId: uuid_comparison_exp
  locationName: String_comparison_exp
  location_address: String_comparison_exp
  location_mode: String_comparison_exp
  location_to_pms_property_mapping: sf_pms_pms_properties_bool_exp
  location_to_user_mappings: sensorflow_location_to_user_mapping_bool_exp
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_bool_exp
  logo_url: String_comparison_exp
  organisation: sensorflow_organisations_bool_exp
  organisation_id: uuid_comparison_exp
  origin_latitude: float8_comparison_exp
  origin_longitude: float8_comparison_exp
  otaIsEnabled: Boolean_comparison_exp
  otaMaxRetries: Int_comparison_exp
  otaWindowEndTime: String_comparison_exp
  otaWindowStartTime: String_comparison_exp
  positions: sensorflow_positions_bool_exp
  positions_aggregate: sensorflow_positions_aggregate_bool_exp
  timezone: String_comparison_exp
}

enum sensorflow_locations_constraint {
  locations_name_k
  locations_pkey
}

input sensorflow_locations_inc_input {
  clickupExportProgress: numeric
  origin_latitude: float8
  origin_longitude: float8
  otaMaxRetries: Int
}

input sensorflow_locations_insert_input {
  activePropertyAlerts: sf_support_active_property_alerts_arr_rel_insert_input
  being_exported_to_clickup: Boolean
  clickupExportDisabled: Boolean
  clickupExportProgress: numeric
  clickupListId: String
  clickup_assignee_id: String
  clickup_webhook_id: String
  clickup_webhook_secret: String
  currency: String
  emon_clickup_task_id: String
  expected_number_of_keys: String
  keyCategories: sensorflow_key_categories_arr_rel_insert_input
  language: String
  locationCurrency: sensorflow_currencies_obj_rel_insert_input
  locationId: uuid
  locationName: String
  location_address: String
  location_mode: String
  location_to_pms_property_mapping: sf_pms_pms_properties_obj_rel_insert_input
  location_to_user_mappings: sensorflow_location_to_user_mapping_arr_rel_insert_input
  logo_url: String
  organisation: sensorflow_organisations_obj_rel_insert_input
  organisation_id: uuid
  origin_latitude: float8
  origin_longitude: float8
  otaIsEnabled: Boolean
  otaMaxRetries: Int
  otaWindowEndTime: String
  otaWindowStartTime: String
  positions: sensorflow_positions_arr_rel_insert_input
  timezone: String
}

type sensorflow_locations_max_fields {
  clickupExportProgress: numeric
  clickupListId: String
  clickup_assignee_id: String
  clickup_webhook_id: String
  clickup_webhook_secret: String
  currency: String
  emon_clickup_task_id: String
  expected_number_of_keys: String
  language: String
  locationId: uuid
  locationName: String
  location_address: String
  location_mode: String
  logo_url: String
  organisation_id: uuid
  origin_latitude: float8
  origin_longitude: float8
  otaMaxRetries: Int
  otaWindowEndTime: String
  otaWindowStartTime: String
  timezone: String
}

input sensorflow_locations_max_order_by {
  clickupExportProgress: order_by
  clickupListId: order_by
  clickup_assignee_id: order_by
  clickup_webhook_id: order_by
  clickup_webhook_secret: order_by
  currency: order_by
  emon_clickup_task_id: order_by
  expected_number_of_keys: order_by
  language: order_by
  locationId: order_by
  locationName: order_by
  location_address: order_by
  location_mode: order_by
  logo_url: order_by
  organisation_id: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
  otaWindowEndTime: order_by
  otaWindowStartTime: order_by
  timezone: order_by
}

type sensorflow_locations_min_fields {
  clickupExportProgress: numeric
  clickupListId: String
  clickup_assignee_id: String
  clickup_webhook_id: String
  clickup_webhook_secret: String
  currency: String
  emon_clickup_task_id: String
  expected_number_of_keys: String
  language: String
  locationId: uuid
  locationName: String
  location_address: String
  location_mode: String
  logo_url: String
  organisation_id: uuid
  origin_latitude: float8
  origin_longitude: float8
  otaMaxRetries: Int
  otaWindowEndTime: String
  otaWindowStartTime: String
  timezone: String
}

input sensorflow_locations_min_order_by {
  clickupExportProgress: order_by
  clickupListId: order_by
  clickup_assignee_id: order_by
  clickup_webhook_id: order_by
  clickup_webhook_secret: order_by
  currency: order_by
  emon_clickup_task_id: order_by
  expected_number_of_keys: order_by
  language: order_by
  locationId: order_by
  locationName: order_by
  location_address: order_by
  location_mode: order_by
  logo_url: order_by
  organisation_id: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
  otaWindowEndTime: order_by
  otaWindowStartTime: order_by
  timezone: order_by
}

type sensorflow_locations_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_locations!]!
}

input sensorflow_locations_obj_rel_insert_input {
  data: sensorflow_locations_insert_input!
  on_conflict: sensorflow_locations_on_conflict
}

input sensorflow_locations_on_conflict {
  constraint: sensorflow_locations_constraint!
  update_columns: [sensorflow_locations_update_column!]! = []
  where: sensorflow_locations_bool_exp
}

input sensorflow_locations_order_by {
  activePropertyAlerts_aggregate: sf_support_active_property_alerts_aggregate_order_by
  being_exported_to_clickup: order_by
  clickupExportDisabled: order_by
  clickupExportProgress: order_by
  clickupListId: order_by
  clickup_assignee_id: order_by
  clickup_webhook_id: order_by
  clickup_webhook_secret: order_by
  currency: order_by
  emon_clickup_task_id: order_by
  expected_number_of_keys: order_by
  keyCategories_aggregate: sensorflow_key_categories_aggregate_order_by
  language: order_by
  locationCurrency: sensorflow_currencies_order_by
  locationId: order_by
  locationName: order_by
  location_address: order_by
  location_mode: order_by
  location_to_pms_property_mapping: sf_pms_pms_properties_order_by
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_order_by
  logo_url: order_by
  organisation: sensorflow_organisations_order_by
  organisation_id: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaIsEnabled: order_by
  otaMaxRetries: order_by
  otaWindowEndTime: order_by
  otaWindowStartTime: order_by
  positions_aggregate: sensorflow_positions_aggregate_order_by
  timezone: order_by
}

input sensorflow_locations_pk_columns_input {
  locationId: uuid!
}

enum sensorflow_locations_select_column {
  being_exported_to_clickup
  clickupExportDisabled
  clickupExportProgress
  clickupListId
  clickup_assignee_id
  clickup_webhook_id
  clickup_webhook_secret
  currency
  emon_clickup_task_id
  expected_number_of_keys
  language
  locationId
  locationName
  location_address
  location_mode
  logo_url
  organisation_id
  origin_latitude
  origin_longitude
  otaIsEnabled
  otaMaxRetries
  otaWindowEndTime
  otaWindowStartTime
  timezone
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_avg_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_bool_and_arguments_columns {
  being_exported_to_clickup
  clickupExportDisabled
  otaIsEnabled
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_bool_or_arguments_columns {
  being_exported_to_clickup
  clickupExportDisabled
  otaIsEnabled
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_corr_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_covar_samp_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_max_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_min_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_stddev_samp_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_sum_arguments_columns {
  origin_latitude
  origin_longitude
}

enum sensorflow_locations_select_column_sensorflow_locations_aggregate_bool_exp_var_samp_arguments_columns {
  origin_latitude
  origin_longitude
}

input sensorflow_locations_set_input {
  being_exported_to_clickup: Boolean
  clickupExportDisabled: Boolean
  clickupExportProgress: numeric
  clickupListId: String
  clickup_assignee_id: String
  clickup_webhook_id: String
  clickup_webhook_secret: String
  currency: String
  emon_clickup_task_id: String
  expected_number_of_keys: String
  language: String
  locationId: uuid
  locationName: String
  location_address: String
  location_mode: String
  logo_url: String
  organisation_id: uuid
  origin_latitude: float8
  origin_longitude: float8
  otaIsEnabled: Boolean
  otaMaxRetries: Int
  otaWindowEndTime: String
  otaWindowStartTime: String
  timezone: String
}

type sensorflow_locations_stddev_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_stddev_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

type sensorflow_locations_stddev_pop_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_stddev_pop_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

type sensorflow_locations_stddev_samp_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_stddev_samp_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

input sensorflow_locations_stream_cursor_input {
  initial_value: sensorflow_locations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_locations_stream_cursor_value_input {
  being_exported_to_clickup: Boolean
  clickupExportDisabled: Boolean
  clickupExportProgress: numeric
  clickupListId: String
  clickup_assignee_id: String
  clickup_webhook_id: String
  clickup_webhook_secret: String
  currency: String
  emon_clickup_task_id: String
  expected_number_of_keys: String
  language: String
  locationId: uuid
  locationName: String
  location_address: String
  location_mode: String
  logo_url: String
  organisation_id: uuid
  origin_latitude: float8
  origin_longitude: float8
  otaIsEnabled: Boolean
  otaMaxRetries: Int
  otaWindowEndTime: String
  otaWindowStartTime: String
  timezone: String
}

type sensorflow_locations_sum_fields {
  clickupExportProgress: numeric
  origin_latitude: float8
  origin_longitude: float8
  otaMaxRetries: Int
}

input sensorflow_locations_sum_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

enum sensorflow_locations_update_column {
  being_exported_to_clickup
  clickupExportDisabled
  clickupExportProgress
  clickupListId
  clickup_assignee_id
  clickup_webhook_id
  clickup_webhook_secret
  currency
  emon_clickup_task_id
  expected_number_of_keys
  language
  locationId
  locationName
  location_address
  location_mode
  logo_url
  organisation_id
  origin_latitude
  origin_longitude
  otaIsEnabled
  otaMaxRetries
  otaWindowEndTime
  otaWindowStartTime
  timezone
}

input sensorflow_locations_updates {
  _inc: sensorflow_locations_inc_input
  _set: sensorflow_locations_set_input
  where: sensorflow_locations_bool_exp!
}

type sensorflow_locations_var_pop_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_var_pop_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

type sensorflow_locations_var_samp_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_var_samp_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

type sensorflow_locations_variance_fields {
  clickupExportProgress: Float
  origin_latitude: Float
  origin_longitude: Float
  otaMaxRetries: Float
}

input sensorflow_locations_variance_order_by {
  clickupExportProgress: order_by
  origin_latitude: order_by
  origin_longitude: order_by
  otaMaxRetries: order_by
}

type sensorflow_mapping_coordinates {
  coordinates: [json!]!
  gateway: sensorflow_gateways
  gatewayId: String
  nodeSlot: sensorflow_node_slots
  position: sensorflow_positions
  positionId: uuid
  slotId: uuid
}

type sensorflow_mapping_coordinates_aggregate {
  aggregate: sensorflow_mapping_coordinates_aggregate_fields
  nodes: [sensorflow_mapping_coordinates!]!
}

input sensorflow_mapping_coordinates_aggregate_bool_exp {
  count: sensorflow_mapping_coordinates_aggregate_bool_exp_count
}

input sensorflow_mapping_coordinates_aggregate_bool_exp_count {
  arguments: [sensorflow_mapping_coordinates_select_column!]
  distinct: Boolean
  filter: sensorflow_mapping_coordinates_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_mapping_coordinates_aggregate_fields {
  count(columns: [sensorflow_mapping_coordinates_select_column!], distinct: Boolean): Int!
  max: sensorflow_mapping_coordinates_max_fields
  min: sensorflow_mapping_coordinates_min_fields
}

input sensorflow_mapping_coordinates_aggregate_order_by {
  count: order_by
  max: sensorflow_mapping_coordinates_max_order_by
  min: sensorflow_mapping_coordinates_min_order_by
}

input sensorflow_mapping_coordinates_arr_rel_insert_input {
  data: [sensorflow_mapping_coordinates_insert_input!]!
  on_conflict: sensorflow_mapping_coordinates_on_conflict
}

input sensorflow_mapping_coordinates_bool_exp {
  _and: [sensorflow_mapping_coordinates_bool_exp!]
  _not: sensorflow_mapping_coordinates_bool_exp
  _or: [sensorflow_mapping_coordinates_bool_exp!]
  coordinates: json_array_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  gatewayId: String_comparison_exp
  nodeSlot: sensorflow_node_slots_bool_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  slotId: uuid_comparison_exp
}

enum sensorflow_mapping_coordinates_constraint {
  mapping_coordinates_gateway_id_key
  mapping_coordinates_position_id_key
  mapping_coordinates_slot_id_key
}

input sensorflow_mapping_coordinates_insert_input {
  coordinates: [json!]
  gateway: sensorflow_gateways_obj_rel_insert_input
  gatewayId: String
  nodeSlot: sensorflow_node_slots_obj_rel_insert_input
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  slotId: uuid
}

type sensorflow_mapping_coordinates_max_fields {
  coordinates: [json!]
  gatewayId: String
  positionId: uuid
  slotId: uuid
}

input sensorflow_mapping_coordinates_max_order_by {
  coordinates: order_by
  gatewayId: order_by
  positionId: order_by
  slotId: order_by
}

type sensorflow_mapping_coordinates_min_fields {
  coordinates: [json!]
  gatewayId: String
  positionId: uuid
  slotId: uuid
}

input sensorflow_mapping_coordinates_min_order_by {
  coordinates: order_by
  gatewayId: order_by
  positionId: order_by
  slotId: order_by
}

type sensorflow_mapping_coordinates_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_mapping_coordinates!]!
}

input sensorflow_mapping_coordinates_obj_rel_insert_input {
  data: sensorflow_mapping_coordinates_insert_input!
  on_conflict: sensorflow_mapping_coordinates_on_conflict
}

input sensorflow_mapping_coordinates_on_conflict {
  constraint: sensorflow_mapping_coordinates_constraint!
  update_columns: [sensorflow_mapping_coordinates_update_column!]! = []
  where: sensorflow_mapping_coordinates_bool_exp
}

input sensorflow_mapping_coordinates_order_by {
  coordinates: order_by
  gateway: sensorflow_gateways_order_by
  gatewayId: order_by
  nodeSlot: sensorflow_node_slots_order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  slotId: order_by
}

enum sensorflow_mapping_coordinates_select_column {
  coordinates
  gatewayId
  positionId
  slotId
}

input sensorflow_mapping_coordinates_set_input {
  coordinates: [json!]
  gatewayId: String
  positionId: uuid
  slotId: uuid
}

input sensorflow_mapping_coordinates_stream_cursor_input {
  initial_value: sensorflow_mapping_coordinates_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_mapping_coordinates_stream_cursor_value_input {
  coordinates: [json!]
  gatewayId: String
  positionId: uuid
  slotId: uuid
}

enum sensorflow_mapping_coordinates_update_column {
  coordinates
  gatewayId
  positionId
  slotId
}

input sensorflow_mapping_coordinates_updates {
  _set: sensorflow_mapping_coordinates_set_input
  where: sensorflow_mapping_coordinates_bool_exp!
}

type sensorflow_node_exception {
  description: String
  id: Int!
  name: String
}

type sensorflow_node_exception_aggregate {
  aggregate: sensorflow_node_exception_aggregate_fields
  nodes: [sensorflow_node_exception!]!
}

type sensorflow_node_exception_aggregate_fields {
  avg: sensorflow_node_exception_avg_fields
  count(columns: [sensorflow_node_exception_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_exception_max_fields
  min: sensorflow_node_exception_min_fields
  stddev: sensorflow_node_exception_stddev_fields
  stddev_pop: sensorflow_node_exception_stddev_pop_fields
  stddev_samp: sensorflow_node_exception_stddev_samp_fields
  sum: sensorflow_node_exception_sum_fields
  var_pop: sensorflow_node_exception_var_pop_fields
  var_samp: sensorflow_node_exception_var_samp_fields
  variance: sensorflow_node_exception_variance_fields
}

type sensorflow_node_exception_avg_fields {
  id: Float
}

input sensorflow_node_exception_bool_exp {
  _and: [sensorflow_node_exception_bool_exp!]
  _not: sensorflow_node_exception_bool_exp
  _or: [sensorflow_node_exception_bool_exp!]
  description: String_comparison_exp
  id: Int_comparison_exp
  name: String_comparison_exp
}

enum sensorflow_node_exception_constraint {
  node_exception_pkey
}

input sensorflow_node_exception_inc_input {
  id: Int
}

input sensorflow_node_exception_insert_input {
  description: String
  id: Int
  name: String
}

type sensorflow_node_exception_max_fields {
  description: String
  id: Int
  name: String
}

type sensorflow_node_exception_min_fields {
  description: String
  id: Int
  name: String
}

type sensorflow_node_exception_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_exception!]!
}

input sensorflow_node_exception_on_conflict {
  constraint: sensorflow_node_exception_constraint!
  update_columns: [sensorflow_node_exception_update_column!]! = []
  where: sensorflow_node_exception_bool_exp
}

input sensorflow_node_exception_order_by {
  description: order_by
  id: order_by
  name: order_by
}

input sensorflow_node_exception_pk_columns_input {
  id: Int!
}

enum sensorflow_node_exception_select_column {
  description
  id
  name
}

input sensorflow_node_exception_set_input {
  description: String
  id: Int
  name: String
}

type sensorflow_node_exception_stddev_fields {
  id: Float
}

type sensorflow_node_exception_stddev_pop_fields {
  id: Float
}

type sensorflow_node_exception_stddev_samp_fields {
  id: Float
}

input sensorflow_node_exception_stream_cursor_input {
  initial_value: sensorflow_node_exception_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_exception_stream_cursor_value_input {
  description: String
  id: Int
  name: String
}

type sensorflow_node_exception_sum_fields {
  id: Int
}

enum sensorflow_node_exception_update_column {
  description
  id
  name
}

input sensorflow_node_exception_updates {
  _inc: sensorflow_node_exception_inc_input
  _set: sensorflow_node_exception_set_input
  where: sensorflow_node_exception_bool_exp!
}

type sensorflow_node_exception_var_pop_fields {
  id: Float
}

type sensorflow_node_exception_var_samp_fields {
  id: Float
}

type sensorflow_node_exception_variance_fields {
  id: Float
}

type sensorflow_node_exceptions {
  exceptionId: Int
  fileId: Int
  id: Int!
  lineNumber: Int
  nodeMacId: String!
  rtosTaskId: Int
  time: timestamptz!
}

type sensorflow_node_exceptions_aggregate {
  aggregate: sensorflow_node_exceptions_aggregate_fields
  nodes: [sensorflow_node_exceptions!]!
}

type sensorflow_node_exceptions_aggregate_fields {
  avg: sensorflow_node_exceptions_avg_fields
  count(columns: [sensorflow_node_exceptions_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_exceptions_max_fields
  min: sensorflow_node_exceptions_min_fields
  stddev: sensorflow_node_exceptions_stddev_fields
  stddev_pop: sensorflow_node_exceptions_stddev_pop_fields
  stddev_samp: sensorflow_node_exceptions_stddev_samp_fields
  sum: sensorflow_node_exceptions_sum_fields
  var_pop: sensorflow_node_exceptions_var_pop_fields
  var_samp: sensorflow_node_exceptions_var_samp_fields
  variance: sensorflow_node_exceptions_variance_fields
}

type sensorflow_node_exceptions_avg_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

input sensorflow_node_exceptions_bool_exp {
  _and: [sensorflow_node_exceptions_bool_exp!]
  _not: sensorflow_node_exceptions_bool_exp
  _or: [sensorflow_node_exceptions_bool_exp!]
  exceptionId: Int_comparison_exp
  fileId: Int_comparison_exp
  id: Int_comparison_exp
  lineNumber: Int_comparison_exp
  nodeMacId: String_comparison_exp
  rtosTaskId: Int_comparison_exp
  time: timestamptz_comparison_exp
}

enum sensorflow_node_exceptions_constraint {
  node_exceptions_pkey
}

input sensorflow_node_exceptions_inc_input {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  rtosTaskId: Int
}

input sensorflow_node_exceptions_insert_input {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  nodeMacId: String
  rtosTaskId: Int
  time: timestamptz
}

type sensorflow_node_exceptions_max_fields {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  nodeMacId: String
  rtosTaskId: Int
  time: timestamptz
}

type sensorflow_node_exceptions_min_fields {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  nodeMacId: String
  rtosTaskId: Int
  time: timestamptz
}

type sensorflow_node_exceptions_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_exceptions!]!
}

input sensorflow_node_exceptions_on_conflict {
  constraint: sensorflow_node_exceptions_constraint!
  update_columns: [sensorflow_node_exceptions_update_column!]! = []
  where: sensorflow_node_exceptions_bool_exp
}

input sensorflow_node_exceptions_order_by {
  exceptionId: order_by
  fileId: order_by
  id: order_by
  lineNumber: order_by
  nodeMacId: order_by
  rtosTaskId: order_by
  time: order_by
}

input sensorflow_node_exceptions_pk_columns_input {
  id: Int!
}

enum sensorflow_node_exceptions_select_column {
  exceptionId
  fileId
  id
  lineNumber
  nodeMacId
  rtosTaskId
  time
}

input sensorflow_node_exceptions_set_input {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  nodeMacId: String
  rtosTaskId: Int
  time: timestamptz
}

type sensorflow_node_exceptions_stddev_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

type sensorflow_node_exceptions_stddev_pop_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

type sensorflow_node_exceptions_stddev_samp_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

input sensorflow_node_exceptions_stream_cursor_input {
  initial_value: sensorflow_node_exceptions_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_exceptions_stream_cursor_value_input {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  nodeMacId: String
  rtosTaskId: Int
  time: timestamptz
}

type sensorflow_node_exceptions_sum_fields {
  exceptionId: Int
  fileId: Int
  id: Int
  lineNumber: Int
  rtosTaskId: Int
}

enum sensorflow_node_exceptions_update_column {
  exceptionId
  fileId
  id
  lineNumber
  nodeMacId
  rtosTaskId
  time
}

input sensorflow_node_exceptions_updates {
  _inc: sensorflow_node_exceptions_inc_input
  _set: sensorflow_node_exceptions_set_input
  where: sensorflow_node_exceptions_bool_exp!
}

type sensorflow_node_exceptions_var_pop_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

type sensorflow_node_exceptions_var_samp_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

type sensorflow_node_exceptions_variance_fields {
  exceptionId: Float
  fileId: Float
  id: Float
  lineNumber: Float
  rtosTaskId: Float
}

type sensorflow_node_firmware {
  lockable: Boolean
  nodeMacId: String
  upgradeStatus: String
}

type sensorflow_node_firmware_aggregate {
  aggregate: sensorflow_node_firmware_aggregate_fields
  nodes: [sensorflow_node_firmware!]!
}

type sensorflow_node_firmware_aggregate_fields {
  count(columns: [sensorflow_node_firmware_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_firmware_max_fields
  min: sensorflow_node_firmware_min_fields
}

input sensorflow_node_firmware_bool_exp {
  _and: [sensorflow_node_firmware_bool_exp!]
  _not: sensorflow_node_firmware_bool_exp
  _or: [sensorflow_node_firmware_bool_exp!]
  lockable: Boolean_comparison_exp
  nodeMacId: String_comparison_exp
  upgradeStatus: String_comparison_exp
}

input sensorflow_node_firmware_insert_input {
  lockable: Boolean
  nodeMacId: String
  upgradeStatus: String
}

type sensorflow_node_firmware_max_fields {
  nodeMacId: String
  upgradeStatus: String
}

type sensorflow_node_firmware_min_fields {
  nodeMacId: String
  upgradeStatus: String
}

input sensorflow_node_firmware_obj_rel_insert_input {
  data: sensorflow_node_firmware_insert_input!
}

input sensorflow_node_firmware_order_by {
  lockable: order_by
  nodeMacId: order_by
  upgradeStatus: order_by
}

enum sensorflow_node_firmware_select_column {
  lockable
  nodeMacId
  upgradeStatus
}

input sensorflow_node_firmware_stream_cursor_input {
  initial_value: sensorflow_node_firmware_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_firmware_stream_cursor_value_input {
  lockable: Boolean
  nodeMacId: String
  upgradeStatus: String
}

type sensorflow_node_join_data {
  balena_id: String!
  gateway: sensorflow_gateways!
  node: sensorflow_nodes!
  node_mac_id: String!
  rssi: numeric
  snr: numeric
  time: timestamptz!
}

type sensorflow_node_join_data_aggregate {
  aggregate: sensorflow_node_join_data_aggregate_fields
  nodes: [sensorflow_node_join_data!]!
}

input sensorflow_node_join_data_aggregate_bool_exp {
  count: sensorflow_node_join_data_aggregate_bool_exp_count
}

input sensorflow_node_join_data_aggregate_bool_exp_count {
  arguments: [sensorflow_node_join_data_select_column!]
  distinct: Boolean
  filter: sensorflow_node_join_data_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_join_data_aggregate_fields {
  avg: sensorflow_node_join_data_avg_fields
  count(columns: [sensorflow_node_join_data_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_join_data_max_fields
  min: sensorflow_node_join_data_min_fields
  stddev: sensorflow_node_join_data_stddev_fields
  stddev_pop: sensorflow_node_join_data_stddev_pop_fields
  stddev_samp: sensorflow_node_join_data_stddev_samp_fields
  sum: sensorflow_node_join_data_sum_fields
  var_pop: sensorflow_node_join_data_var_pop_fields
  var_samp: sensorflow_node_join_data_var_samp_fields
  variance: sensorflow_node_join_data_variance_fields
}

input sensorflow_node_join_data_aggregate_order_by {
  avg: sensorflow_node_join_data_avg_order_by
  count: order_by
  max: sensorflow_node_join_data_max_order_by
  min: sensorflow_node_join_data_min_order_by
  stddev: sensorflow_node_join_data_stddev_order_by
  stddev_pop: sensorflow_node_join_data_stddev_pop_order_by
  stddev_samp: sensorflow_node_join_data_stddev_samp_order_by
  sum: sensorflow_node_join_data_sum_order_by
  var_pop: sensorflow_node_join_data_var_pop_order_by
  var_samp: sensorflow_node_join_data_var_samp_order_by
  variance: sensorflow_node_join_data_variance_order_by
}

input sensorflow_node_join_data_arr_rel_insert_input {
  data: [sensorflow_node_join_data_insert_input!]!
  on_conflict: sensorflow_node_join_data_on_conflict
}

type sensorflow_node_join_data_avg_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_avg_order_by {
  rssi: order_by
  snr: order_by
}

input sensorflow_node_join_data_bool_exp {
  _and: [sensorflow_node_join_data_bool_exp!]
  _not: sensorflow_node_join_data_bool_exp
  _or: [sensorflow_node_join_data_bool_exp!]
  balena_id: String_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  node: sensorflow_nodes_bool_exp
  node_mac_id: String_comparison_exp
  rssi: numeric_comparison_exp
  snr: numeric_comparison_exp
  time: timestamptz_comparison_exp
}

enum sensorflow_node_join_data_constraint {
  node_join_data_unique_asc_idx
}

input sensorflow_node_join_data_inc_input {
  rssi: numeric
  snr: numeric
}

input sensorflow_node_join_data_insert_input {
  balena_id: String
  gateway: sensorflow_gateways_obj_rel_insert_input
  node: sensorflow_nodes_obj_rel_insert_input
  node_mac_id: String
  rssi: numeric
  snr: numeric
  time: timestamptz
}

type sensorflow_node_join_data_max_fields {
  balena_id: String
  node_mac_id: String
  rssi: numeric
  snr: numeric
  time: timestamptz
}

input sensorflow_node_join_data_max_order_by {
  balena_id: order_by
  node_mac_id: order_by
  rssi: order_by
  snr: order_by
  time: order_by
}

type sensorflow_node_join_data_min_fields {
  balena_id: String
  node_mac_id: String
  rssi: numeric
  snr: numeric
  time: timestamptz
}

input sensorflow_node_join_data_min_order_by {
  balena_id: order_by
  node_mac_id: order_by
  rssi: order_by
  snr: order_by
  time: order_by
}

type sensorflow_node_join_data_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_join_data!]!
}

input sensorflow_node_join_data_on_conflict {
  constraint: sensorflow_node_join_data_constraint!
  update_columns: [sensorflow_node_join_data_update_column!]! = []
  where: sensorflow_node_join_data_bool_exp
}

input sensorflow_node_join_data_order_by {
  balena_id: order_by
  gateway: sensorflow_gateways_order_by
  node: sensorflow_nodes_order_by
  node_mac_id: order_by
  rssi: order_by
  snr: order_by
  time: order_by
}

enum sensorflow_node_join_data_select_column {
  balena_id
  node_mac_id
  rssi
  snr
  time
}

input sensorflow_node_join_data_set_input {
  balena_id: String
  node_mac_id: String
  rssi: numeric
  snr: numeric
  time: timestamptz
}

type sensorflow_node_join_data_stddev_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_stddev_order_by {
  rssi: order_by
  snr: order_by
}

type sensorflow_node_join_data_stddev_pop_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_stddev_pop_order_by {
  rssi: order_by
  snr: order_by
}

type sensorflow_node_join_data_stddev_samp_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_stddev_samp_order_by {
  rssi: order_by
  snr: order_by
}

input sensorflow_node_join_data_stream_cursor_input {
  initial_value: sensorflow_node_join_data_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_join_data_stream_cursor_value_input {
  balena_id: String
  node_mac_id: String
  rssi: numeric
  snr: numeric
  time: timestamptz
}

type sensorflow_node_join_data_sum_fields {
  rssi: numeric
  snr: numeric
}

input sensorflow_node_join_data_sum_order_by {
  rssi: order_by
  snr: order_by
}

enum sensorflow_node_join_data_update_column {
  balena_id
  node_mac_id
  rssi
  snr
  time
}

input sensorflow_node_join_data_updates {
  _inc: sensorflow_node_join_data_inc_input
  _set: sensorflow_node_join_data_set_input
  where: sensorflow_node_join_data_bool_exp!
}

type sensorflow_node_join_data_var_pop_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_var_pop_order_by {
  rssi: order_by
  snr: order_by
}

type sensorflow_node_join_data_var_samp_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_var_samp_order_by {
  rssi: order_by
  snr: order_by
}

type sensorflow_node_join_data_variance_fields {
  rssi: Float
  snr: Float
}

input sensorflow_node_join_data_variance_order_by {
  rssi: order_by
  snr: order_by
}

type sensorflow_node_join_status {
  RSSI: numeric
  SNR: numeric
  bootTime: numeric
  currentGatewayId: String
  firmwareVersion: String
  gatewayId: String
  joinedGateway: sensorflow_gateways
  lastSeen: timestamptz
  mappedGateway: sensorflow_gateways
  mappedTime: timestamptz
  node: sensorflow_nodes
  nodeMacId: String
  nodeStatusInInstallationMode: String
  nodeType: String
  position: sensorflow_positions
  positionId: uuid
  recentJoinCount: bigint
  signalStrength: numeric
  slotName: String
  status: String
}

type sensorflow_node_join_status_aggregate {
  aggregate: sensorflow_node_join_status_aggregate_fields
  nodes: [sensorflow_node_join_status!]!
}

input sensorflow_node_join_status_aggregate_bool_exp {
  count: sensorflow_node_join_status_aggregate_bool_exp_count
}

input sensorflow_node_join_status_aggregate_bool_exp_count {
  arguments: [sensorflow_node_join_status_select_column!]
  distinct: Boolean
  filter: sensorflow_node_join_status_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_join_status_aggregate_fields {
  avg: sensorflow_node_join_status_avg_fields
  count(columns: [sensorflow_node_join_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_join_status_max_fields
  min: sensorflow_node_join_status_min_fields
  stddev: sensorflow_node_join_status_stddev_fields
  stddev_pop: sensorflow_node_join_status_stddev_pop_fields
  stddev_samp: sensorflow_node_join_status_stddev_samp_fields
  sum: sensorflow_node_join_status_sum_fields
  var_pop: sensorflow_node_join_status_var_pop_fields
  var_samp: sensorflow_node_join_status_var_samp_fields
  variance: sensorflow_node_join_status_variance_fields
}

input sensorflow_node_join_status_aggregate_order_by {
  avg: sensorflow_node_join_status_avg_order_by
  count: order_by
  max: sensorflow_node_join_status_max_order_by
  min: sensorflow_node_join_status_min_order_by
  stddev: sensorflow_node_join_status_stddev_order_by
  stddev_pop: sensorflow_node_join_status_stddev_pop_order_by
  stddev_samp: sensorflow_node_join_status_stddev_samp_order_by
  sum: sensorflow_node_join_status_sum_order_by
  var_pop: sensorflow_node_join_status_var_pop_order_by
  var_samp: sensorflow_node_join_status_var_samp_order_by
  variance: sensorflow_node_join_status_variance_order_by
}

input sensorflow_node_join_status_arr_rel_insert_input {
  data: [sensorflow_node_join_status_insert_input!]!
}

type sensorflow_node_join_status_avg_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_avg_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

input sensorflow_node_join_status_bool_exp {
  RSSI: numeric_comparison_exp
  SNR: numeric_comparison_exp
  _and: [sensorflow_node_join_status_bool_exp!]
  _not: sensorflow_node_join_status_bool_exp
  _or: [sensorflow_node_join_status_bool_exp!]
  bootTime: numeric_comparison_exp
  currentGatewayId: String_comparison_exp
  firmwareVersion: String_comparison_exp
  gatewayId: String_comparison_exp
  joinedGateway: sensorflow_gateways_bool_exp
  lastSeen: timestamptz_comparison_exp
  mappedGateway: sensorflow_gateways_bool_exp
  mappedTime: timestamptz_comparison_exp
  node: sensorflow_nodes_bool_exp
  nodeMacId: String_comparison_exp
  nodeStatusInInstallationMode: String_comparison_exp
  nodeType: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  recentJoinCount: bigint_comparison_exp
  signalStrength: numeric_comparison_exp
  slotName: String_comparison_exp
  status: String_comparison_exp
}

input sensorflow_node_join_status_insert_input {
  RSSI: numeric
  SNR: numeric
  bootTime: numeric
  currentGatewayId: String
  firmwareVersion: String
  gatewayId: String
  joinedGateway: sensorflow_gateways_obj_rel_insert_input
  lastSeen: timestamptz
  mappedGateway: sensorflow_gateways_obj_rel_insert_input
  mappedTime: timestamptz
  node: sensorflow_nodes_obj_rel_insert_input
  nodeMacId: String
  nodeStatusInInstallationMode: String
  nodeType: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  recentJoinCount: bigint
  signalStrength: numeric
  slotName: String
  status: String
}

type sensorflow_node_join_status_max_fields {
  RSSI: numeric
  SNR: numeric
  bootTime: numeric
  currentGatewayId: String
  firmwareVersion: String
  gatewayId: String
  lastSeen: timestamptz
  mappedTime: timestamptz
  nodeMacId: String
  nodeStatusInInstallationMode: String
  nodeType: String
  positionId: uuid
  recentJoinCount: bigint
  signalStrength: numeric
  slotName: String
  status: String
}

input sensorflow_node_join_status_max_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  currentGatewayId: order_by
  firmwareVersion: order_by
  gatewayId: order_by
  lastSeen: order_by
  mappedTime: order_by
  nodeMacId: order_by
  nodeStatusInInstallationMode: order_by
  nodeType: order_by
  positionId: order_by
  recentJoinCount: order_by
  signalStrength: order_by
  slotName: order_by
  status: order_by
}

type sensorflow_node_join_status_min_fields {
  RSSI: numeric
  SNR: numeric
  bootTime: numeric
  currentGatewayId: String
  firmwareVersion: String
  gatewayId: String
  lastSeen: timestamptz
  mappedTime: timestamptz
  nodeMacId: String
  nodeStatusInInstallationMode: String
  nodeType: String
  positionId: uuid
  recentJoinCount: bigint
  signalStrength: numeric
  slotName: String
  status: String
}

input sensorflow_node_join_status_min_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  currentGatewayId: order_by
  firmwareVersion: order_by
  gatewayId: order_by
  lastSeen: order_by
  mappedTime: order_by
  nodeMacId: order_by
  nodeStatusInInstallationMode: order_by
  nodeType: order_by
  positionId: order_by
  recentJoinCount: order_by
  signalStrength: order_by
  slotName: order_by
  status: order_by
}

input sensorflow_node_join_status_obj_rel_insert_input {
  data: sensorflow_node_join_status_insert_input!
}

input sensorflow_node_join_status_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  currentGatewayId: order_by
  firmwareVersion: order_by
  gatewayId: order_by
  joinedGateway: sensorflow_gateways_order_by
  lastSeen: order_by
  mappedGateway: sensorflow_gateways_order_by
  mappedTime: order_by
  node: sensorflow_nodes_order_by
  nodeMacId: order_by
  nodeStatusInInstallationMode: order_by
  nodeType: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  recentJoinCount: order_by
  signalStrength: order_by
  slotName: order_by
  status: order_by
}

enum sensorflow_node_join_status_select_column {
  RSSI
  SNR
  bootTime
  currentGatewayId
  firmwareVersion
  gatewayId
  lastSeen
  mappedTime
  nodeMacId
  nodeStatusInInstallationMode
  nodeType
  positionId
  recentJoinCount
  signalStrength
  slotName
  status
}

type sensorflow_node_join_status_stddev_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_stddev_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

type sensorflow_node_join_status_stddev_pop_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_stddev_pop_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

type sensorflow_node_join_status_stddev_samp_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_stddev_samp_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

input sensorflow_node_join_status_stream_cursor_input {
  initial_value: sensorflow_node_join_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_join_status_stream_cursor_value_input {
  RSSI: numeric
  SNR: numeric
  bootTime: numeric
  currentGatewayId: String
  firmwareVersion: String
  gatewayId: String
  lastSeen: timestamptz
  mappedTime: timestamptz
  nodeMacId: String
  nodeStatusInInstallationMode: String
  nodeType: String
  positionId: uuid
  recentJoinCount: bigint
  signalStrength: numeric
  slotName: String
  status: String
}

type sensorflow_node_join_status_sum_fields {
  RSSI: numeric
  SNR: numeric
  bootTime: numeric
  recentJoinCount: bigint
  signalStrength: numeric
}

input sensorflow_node_join_status_sum_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

type sensorflow_node_join_status_var_pop_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_var_pop_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

type sensorflow_node_join_status_var_samp_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_var_samp_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

type sensorflow_node_join_status_variance_fields {
  RSSI: Float
  SNR: Float
  bootTime: Float
  recentJoinCount: Float
  signalStrength: Float
}

input sensorflow_node_join_status_variance_order_by {
  RSSI: order_by
  SNR: order_by
  bootTime: order_by
  recentJoinCount: order_by
  signalStrength: order_by
}

type sensorflow_node_join_strength_live {
  current_gateway_id: String
  gateway_id: String
  mapped_time: timestamptz
  node_mac_id: String
  node_type: String
  position: sensorflow_positions
  position_id: uuid
  signalStrength: numeric
  slot_name: String
}

type sensorflow_node_join_strength_live_aggregate {
  aggregate: sensorflow_node_join_strength_live_aggregate_fields
  nodes: [sensorflow_node_join_strength_live!]!
}

type sensorflow_node_join_strength_live_aggregate_fields {
  avg: sensorflow_node_join_strength_live_avg_fields
  count(columns: [sensorflow_node_join_strength_live_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_join_strength_live_max_fields
  min: sensorflow_node_join_strength_live_min_fields
  stddev: sensorflow_node_join_strength_live_stddev_fields
  stddev_pop: sensorflow_node_join_strength_live_stddev_pop_fields
  stddev_samp: sensorflow_node_join_strength_live_stddev_samp_fields
  sum: sensorflow_node_join_strength_live_sum_fields
  var_pop: sensorflow_node_join_strength_live_var_pop_fields
  var_samp: sensorflow_node_join_strength_live_var_samp_fields
  variance: sensorflow_node_join_strength_live_variance_fields
}

type sensorflow_node_join_strength_live_avg_fields {
  signalStrength: Float
}

input sensorflow_node_join_strength_live_bool_exp {
  _and: [sensorflow_node_join_strength_live_bool_exp!]
  _not: sensorflow_node_join_strength_live_bool_exp
  _or: [sensorflow_node_join_strength_live_bool_exp!]
  current_gateway_id: String_comparison_exp
  gateway_id: String_comparison_exp
  mapped_time: timestamptz_comparison_exp
  node_mac_id: String_comparison_exp
  node_type: String_comparison_exp
  position: sensorflow_positions_bool_exp
  position_id: uuid_comparison_exp
  signalStrength: numeric_comparison_exp
  slot_name: String_comparison_exp
}

input sensorflow_node_join_strength_live_insert_input {
  current_gateway_id: String
  gateway_id: String
  mapped_time: timestamptz
  node_mac_id: String
  node_type: String
  position: sensorflow_positions_obj_rel_insert_input
  position_id: uuid
  signalStrength: numeric
  slot_name: String
}

type sensorflow_node_join_strength_live_max_fields {
  current_gateway_id: String
  gateway_id: String
  mapped_time: timestamptz
  node_mac_id: String
  node_type: String
  position_id: uuid
  signalStrength: numeric
  slot_name: String
}

type sensorflow_node_join_strength_live_min_fields {
  current_gateway_id: String
  gateway_id: String
  mapped_time: timestamptz
  node_mac_id: String
  node_type: String
  position_id: uuid
  signalStrength: numeric
  slot_name: String
}

input sensorflow_node_join_strength_live_obj_rel_insert_input {
  data: sensorflow_node_join_strength_live_insert_input!
}

input sensorflow_node_join_strength_live_order_by {
  current_gateway_id: order_by
  gateway_id: order_by
  mapped_time: order_by
  node_mac_id: order_by
  node_type: order_by
  position: sensorflow_positions_order_by
  position_id: order_by
  signalStrength: order_by
  slot_name: order_by
}

enum sensorflow_node_join_strength_live_select_column {
  current_gateway_id
  gateway_id
  mapped_time
  node_mac_id
  node_type
  position_id
  signalStrength
  slot_name
}

type sensorflow_node_join_strength_live_stddev_fields {
  signalStrength: Float
}

type sensorflow_node_join_strength_live_stddev_pop_fields {
  signalStrength: Float
}

type sensorflow_node_join_strength_live_stddev_samp_fields {
  signalStrength: Float
}

input sensorflow_node_join_strength_live_stream_cursor_input {
  initial_value: sensorflow_node_join_strength_live_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_join_strength_live_stream_cursor_value_input {
  current_gateway_id: String
  gateway_id: String
  mapped_time: timestamptz
  node_mac_id: String
  node_type: String
  position_id: uuid
  signalStrength: numeric
  slot_name: String
}

type sensorflow_node_join_strength_live_sum_fields {
  signalStrength: numeric
}

type sensorflow_node_join_strength_live_var_pop_fields {
  signalStrength: Float
}

type sensorflow_node_join_strength_live_var_samp_fields {
  signalStrength: Float
}

type sensorflow_node_join_strength_live_variance_fields {
  signalStrength: Float
}

type sensorflow_node_life_cycle_event_types {
  canTrackManually: Boolean!
  id: Int!
  isDefault: Boolean!
  name: String!
}

type sensorflow_node_life_cycle_event_types_aggregate {
  aggregate: sensorflow_node_life_cycle_event_types_aggregate_fields
  nodes: [sensorflow_node_life_cycle_event_types!]!
}

type sensorflow_node_life_cycle_event_types_aggregate_fields {
  avg: sensorflow_node_life_cycle_event_types_avg_fields
  count(columns: [sensorflow_node_life_cycle_event_types_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_life_cycle_event_types_max_fields
  min: sensorflow_node_life_cycle_event_types_min_fields
  stddev: sensorflow_node_life_cycle_event_types_stddev_fields
  stddev_pop: sensorflow_node_life_cycle_event_types_stddev_pop_fields
  stddev_samp: sensorflow_node_life_cycle_event_types_stddev_samp_fields
  sum: sensorflow_node_life_cycle_event_types_sum_fields
  var_pop: sensorflow_node_life_cycle_event_types_var_pop_fields
  var_samp: sensorflow_node_life_cycle_event_types_var_samp_fields
  variance: sensorflow_node_life_cycle_event_types_variance_fields
}

type sensorflow_node_life_cycle_event_types_avg_fields {
  id: Float
}

input sensorflow_node_life_cycle_event_types_bool_exp {
  _and: [sensorflow_node_life_cycle_event_types_bool_exp!]
  _not: sensorflow_node_life_cycle_event_types_bool_exp
  _or: [sensorflow_node_life_cycle_event_types_bool_exp!]
  canTrackManually: Boolean_comparison_exp
  id: Int_comparison_exp
  isDefault: Boolean_comparison_exp
  name: String_comparison_exp
}

enum sensorflow_node_life_cycle_event_types_constraint {
  node_life_cycle_event_types_pkey
}

input sensorflow_node_life_cycle_event_types_inc_input {
  id: Int
}

input sensorflow_node_life_cycle_event_types_insert_input {
  canTrackManually: Boolean
  id: Int
  isDefault: Boolean
  name: String
}

type sensorflow_node_life_cycle_event_types_max_fields {
  id: Int
  name: String
}

type sensorflow_node_life_cycle_event_types_min_fields {
  id: Int
  name: String
}

type sensorflow_node_life_cycle_event_types_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_life_cycle_event_types!]!
}

input sensorflow_node_life_cycle_event_types_obj_rel_insert_input {
  data: sensorflow_node_life_cycle_event_types_insert_input!
  on_conflict: sensorflow_node_life_cycle_event_types_on_conflict
}

input sensorflow_node_life_cycle_event_types_on_conflict {
  constraint: sensorflow_node_life_cycle_event_types_constraint!
  update_columns: [sensorflow_node_life_cycle_event_types_update_column!]! = []
  where: sensorflow_node_life_cycle_event_types_bool_exp
}

input sensorflow_node_life_cycle_event_types_order_by {
  canTrackManually: order_by
  id: order_by
  isDefault: order_by
  name: order_by
}

input sensorflow_node_life_cycle_event_types_pk_columns_input {
  id: Int!
}

enum sensorflow_node_life_cycle_event_types_select_column {
  canTrackManually
  id
  isDefault
  name
}

input sensorflow_node_life_cycle_event_types_set_input {
  canTrackManually: Boolean
  id: Int
  isDefault: Boolean
  name: String
}

type sensorflow_node_life_cycle_event_types_stddev_fields {
  id: Float
}

type sensorflow_node_life_cycle_event_types_stddev_pop_fields {
  id: Float
}

type sensorflow_node_life_cycle_event_types_stddev_samp_fields {
  id: Float
}

input sensorflow_node_life_cycle_event_types_stream_cursor_input {
  initial_value: sensorflow_node_life_cycle_event_types_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_life_cycle_event_types_stream_cursor_value_input {
  canTrackManually: Boolean
  id: Int
  isDefault: Boolean
  name: String
}

type sensorflow_node_life_cycle_event_types_sum_fields {
  id: Int
}

enum sensorflow_node_life_cycle_event_types_update_column {
  canTrackManually
  id
  isDefault
  name
}

input sensorflow_node_life_cycle_event_types_updates {
  _inc: sensorflow_node_life_cycle_event_types_inc_input
  _set: sensorflow_node_life_cycle_event_types_set_input
  where: sensorflow_node_life_cycle_event_types_bool_exp!
}

type sensorflow_node_life_cycle_event_types_var_pop_fields {
  id: Float
}

type sensorflow_node_life_cycle_event_types_var_samp_fields {
  id: Float
}

type sensorflow_node_life_cycle_event_types_variance_fields {
  id: Float
}

type sensorflow_node_life_cycle_events {
  comment: String
  creationDate: timestamptz!
  eventType: Int!
  id: Int!
  nodeMacId: String!
  node_life_cycle_event_type: sensorflow_node_life_cycle_event_types!
}

type sensorflow_node_life_cycle_events_aggregate {
  aggregate: sensorflow_node_life_cycle_events_aggregate_fields
  nodes: [sensorflow_node_life_cycle_events!]!
}

type sensorflow_node_life_cycle_events_aggregate_fields {
  avg: sensorflow_node_life_cycle_events_avg_fields
  count(columns: [sensorflow_node_life_cycle_events_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_life_cycle_events_max_fields
  min: sensorflow_node_life_cycle_events_min_fields
  stddev: sensorflow_node_life_cycle_events_stddev_fields
  stddev_pop: sensorflow_node_life_cycle_events_stddev_pop_fields
  stddev_samp: sensorflow_node_life_cycle_events_stddev_samp_fields
  sum: sensorflow_node_life_cycle_events_sum_fields
  var_pop: sensorflow_node_life_cycle_events_var_pop_fields
  var_samp: sensorflow_node_life_cycle_events_var_samp_fields
  variance: sensorflow_node_life_cycle_events_variance_fields
}

type sensorflow_node_life_cycle_events_avg_fields {
  eventType: Float
  id: Float
}

input sensorflow_node_life_cycle_events_bool_exp {
  _and: [sensorflow_node_life_cycle_events_bool_exp!]
  _not: sensorflow_node_life_cycle_events_bool_exp
  _or: [sensorflow_node_life_cycle_events_bool_exp!]
  comment: String_comparison_exp
  creationDate: timestamptz_comparison_exp
  eventType: Int_comparison_exp
  id: Int_comparison_exp
  nodeMacId: String_comparison_exp
  node_life_cycle_event_type: sensorflow_node_life_cycle_event_types_bool_exp
}

enum sensorflow_node_life_cycle_events_constraint {
  node_life_cycle_events_pkey
}

input sensorflow_node_life_cycle_events_inc_input {
  eventType: Int
  id: Int
}

input sensorflow_node_life_cycle_events_insert_input {
  comment: String
  creationDate: timestamptz
  eventType: Int
  id: Int
  nodeMacId: String
  node_life_cycle_event_type: sensorflow_node_life_cycle_event_types_obj_rel_insert_input
}

type sensorflow_node_life_cycle_events_max_fields {
  comment: String
  creationDate: timestamptz
  eventType: Int
  id: Int
  nodeMacId: String
}

type sensorflow_node_life_cycle_events_min_fields {
  comment: String
  creationDate: timestamptz
  eventType: Int
  id: Int
  nodeMacId: String
}

type sensorflow_node_life_cycle_events_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_life_cycle_events!]!
}

input sensorflow_node_life_cycle_events_obj_rel_insert_input {
  data: sensorflow_node_life_cycle_events_insert_input!
  on_conflict: sensorflow_node_life_cycle_events_on_conflict
}

input sensorflow_node_life_cycle_events_on_conflict {
  constraint: sensorflow_node_life_cycle_events_constraint!
  update_columns: [sensorflow_node_life_cycle_events_update_column!]! = []
  where: sensorflow_node_life_cycle_events_bool_exp
}

input sensorflow_node_life_cycle_events_order_by {
  comment: order_by
  creationDate: order_by
  eventType: order_by
  id: order_by
  nodeMacId: order_by
  node_life_cycle_event_type: sensorflow_node_life_cycle_event_types_order_by
}

input sensorflow_node_life_cycle_events_pk_columns_input {
  id: Int!
}

enum sensorflow_node_life_cycle_events_select_column {
  comment
  creationDate
  eventType
  id
  nodeMacId
}

input sensorflow_node_life_cycle_events_set_input {
  comment: String
  creationDate: timestamptz
  eventType: Int
  id: Int
  nodeMacId: String
}

type sensorflow_node_life_cycle_events_stddev_fields {
  eventType: Float
  id: Float
}

type sensorflow_node_life_cycle_events_stddev_pop_fields {
  eventType: Float
  id: Float
}

type sensorflow_node_life_cycle_events_stddev_samp_fields {
  eventType: Float
  id: Float
}

input sensorflow_node_life_cycle_events_stream_cursor_input {
  initial_value: sensorflow_node_life_cycle_events_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_life_cycle_events_stream_cursor_value_input {
  comment: String
  creationDate: timestamptz
  eventType: Int
  id: Int
  nodeMacId: String
}

type sensorflow_node_life_cycle_events_sum_fields {
  eventType: Int
  id: Int
}

enum sensorflow_node_life_cycle_events_update_column {
  comment
  creationDate
  eventType
  id
  nodeMacId
}

input sensorflow_node_life_cycle_events_updates {
  _inc: sensorflow_node_life_cycle_events_inc_input
  _set: sensorflow_node_life_cycle_events_set_input
  where: sensorflow_node_life_cycle_events_bool_exp!
}

type sensorflow_node_life_cycle_events_var_pop_fields {
  eventType: Float
  id: Float
}

type sensorflow_node_life_cycle_events_var_samp_fields {
  eventType: Float
  id: Float
}

type sensorflow_node_life_cycle_events_variance_fields {
  eventType: Float
  id: Float
}

type sensorflow_node_measurement_view {
  acMode: numeric
  acSetPoint: numeric
  acStatus: numeric
  ambientHumidity: numeric
  ambientTemperature: numeric
  door: numeric
  fanSpeed: numeric
  occupancyState: numeric
  position: sensorflow_positions
  positionId: uuid
  positionName: String
}

type sensorflow_node_measurement_view_aggregate {
  aggregate: sensorflow_node_measurement_view_aggregate_fields
  nodes: [sensorflow_node_measurement_view!]!
}

input sensorflow_node_measurement_view_aggregate_bool_exp {
  count: sensorflow_node_measurement_view_aggregate_bool_exp_count
}

input sensorflow_node_measurement_view_aggregate_bool_exp_count {
  arguments: [sensorflow_node_measurement_view_select_column!]
  distinct: Boolean
  filter: sensorflow_node_measurement_view_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_measurement_view_aggregate_fields {
  avg: sensorflow_node_measurement_view_avg_fields
  count(columns: [sensorflow_node_measurement_view_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_measurement_view_max_fields
  min: sensorflow_node_measurement_view_min_fields
  stddev: sensorflow_node_measurement_view_stddev_fields
  stddev_pop: sensorflow_node_measurement_view_stddev_pop_fields
  stddev_samp: sensorflow_node_measurement_view_stddev_samp_fields
  sum: sensorflow_node_measurement_view_sum_fields
  var_pop: sensorflow_node_measurement_view_var_pop_fields
  var_samp: sensorflow_node_measurement_view_var_samp_fields
  variance: sensorflow_node_measurement_view_variance_fields
}

input sensorflow_node_measurement_view_aggregate_order_by {
  avg: sensorflow_node_measurement_view_avg_order_by
  count: order_by
  max: sensorflow_node_measurement_view_max_order_by
  min: sensorflow_node_measurement_view_min_order_by
  stddev: sensorflow_node_measurement_view_stddev_order_by
  stddev_pop: sensorflow_node_measurement_view_stddev_pop_order_by
  stddev_samp: sensorflow_node_measurement_view_stddev_samp_order_by
  sum: sensorflow_node_measurement_view_sum_order_by
  var_pop: sensorflow_node_measurement_view_var_pop_order_by
  var_samp: sensorflow_node_measurement_view_var_samp_order_by
  variance: sensorflow_node_measurement_view_variance_order_by
}

input sensorflow_node_measurement_view_arr_rel_insert_input {
  data: [sensorflow_node_measurement_view_insert_input!]!
}

type sensorflow_node_measurement_view_avg_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_avg_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

input sensorflow_node_measurement_view_bool_exp {
  _and: [sensorflow_node_measurement_view_bool_exp!]
  _not: sensorflow_node_measurement_view_bool_exp
  _or: [sensorflow_node_measurement_view_bool_exp!]
  acMode: numeric_comparison_exp
  acSetPoint: numeric_comparison_exp
  acStatus: numeric_comparison_exp
  ambientHumidity: numeric_comparison_exp
  ambientTemperature: numeric_comparison_exp
  door: numeric_comparison_exp
  fanSpeed: numeric_comparison_exp
  occupancyState: numeric_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  positionName: String_comparison_exp
}

input sensorflow_node_measurement_view_insert_input {
  acMode: numeric
  acSetPoint: numeric
  acStatus: numeric
  ambientHumidity: numeric
  ambientTemperature: numeric
  door: numeric
  fanSpeed: numeric
  occupancyState: numeric
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  positionName: String
}

type sensorflow_node_measurement_view_max_fields {
  acMode: numeric
  acSetPoint: numeric
  acStatus: numeric
  ambientHumidity: numeric
  ambientTemperature: numeric
  door: numeric
  fanSpeed: numeric
  occupancyState: numeric
  positionId: uuid
  positionName: String
}

input sensorflow_node_measurement_view_max_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
  positionId: order_by
  positionName: order_by
}

type sensorflow_node_measurement_view_min_fields {
  acMode: numeric
  acSetPoint: numeric
  acStatus: numeric
  ambientHumidity: numeric
  ambientTemperature: numeric
  door: numeric
  fanSpeed: numeric
  occupancyState: numeric
  positionId: uuid
  positionName: String
}

input sensorflow_node_measurement_view_min_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
  positionId: order_by
  positionName: order_by
}

input sensorflow_node_measurement_view_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  positionName: order_by
}

enum sensorflow_node_measurement_view_select_column {
  acMode
  acSetPoint
  acStatus
  ambientHumidity
  ambientTemperature
  door
  fanSpeed
  occupancyState
  positionId
  positionName
}

type sensorflow_node_measurement_view_stddev_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_stddev_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

type sensorflow_node_measurement_view_stddev_pop_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_stddev_pop_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

type sensorflow_node_measurement_view_stddev_samp_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_stddev_samp_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

input sensorflow_node_measurement_view_stream_cursor_input {
  initial_value: sensorflow_node_measurement_view_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_measurement_view_stream_cursor_value_input {
  acMode: numeric
  acSetPoint: numeric
  acStatus: numeric
  ambientHumidity: numeric
  ambientTemperature: numeric
  door: numeric
  fanSpeed: numeric
  occupancyState: numeric
  positionId: uuid
  positionName: String
}

type sensorflow_node_measurement_view_sum_fields {
  acMode: numeric
  acSetPoint: numeric
  acStatus: numeric
  ambientHumidity: numeric
  ambientTemperature: numeric
  door: numeric
  fanSpeed: numeric
  occupancyState: numeric
}

input sensorflow_node_measurement_view_sum_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

type sensorflow_node_measurement_view_var_pop_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_var_pop_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

type sensorflow_node_measurement_view_var_samp_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_var_samp_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

type sensorflow_node_measurement_view_variance_fields {
  acMode: Float
  acSetPoint: Float
  acStatus: Float
  ambientHumidity: Float
  ambientTemperature: Float
  door: Float
  fanSpeed: Float
  occupancyState: Float
}

input sensorflow_node_measurement_view_variance_order_by {
  acMode: order_by
  acSetPoint: order_by
  acStatus: order_by
  ambientHumidity: order_by
  ambientTemperature: order_by
  door: order_by
  fanSpeed: order_by
  occupancyState: order_by
}

type sensorflow_node_measurements {
  measurementType: String!
  measurementValue: numeric!
  node: sensorflow_nodes!
  position: sensorflow_positions!
  positionId: uuid!
  srcMacId: String!
  streamIndex: Int!
  time: timestamptz!
}

type sensorflow_node_measurements_aggregate {
  aggregate: sensorflow_node_measurements_aggregate_fields
  nodes: [sensorflow_node_measurements!]!
}

input sensorflow_node_measurements_aggregate_bool_exp {
  count: sensorflow_node_measurements_aggregate_bool_exp_count
}

input sensorflow_node_measurements_aggregate_bool_exp_count {
  arguments: [sensorflow_node_measurements_select_column!]
  distinct: Boolean
  filter: sensorflow_node_measurements_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_measurements_aggregate_fields {
  avg: sensorflow_node_measurements_avg_fields
  count(columns: [sensorflow_node_measurements_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_measurements_max_fields
  min: sensorflow_node_measurements_min_fields
  stddev: sensorflow_node_measurements_stddev_fields
  stddev_pop: sensorflow_node_measurements_stddev_pop_fields
  stddev_samp: sensorflow_node_measurements_stddev_samp_fields
  sum: sensorflow_node_measurements_sum_fields
  var_pop: sensorflow_node_measurements_var_pop_fields
  var_samp: sensorflow_node_measurements_var_samp_fields
  variance: sensorflow_node_measurements_variance_fields
}

input sensorflow_node_measurements_aggregate_order_by {
  avg: sensorflow_node_measurements_avg_order_by
  count: order_by
  max: sensorflow_node_measurements_max_order_by
  min: sensorflow_node_measurements_min_order_by
  stddev: sensorflow_node_measurements_stddev_order_by
  stddev_pop: sensorflow_node_measurements_stddev_pop_order_by
  stddev_samp: sensorflow_node_measurements_stddev_samp_order_by
  sum: sensorflow_node_measurements_sum_order_by
  var_pop: sensorflow_node_measurements_var_pop_order_by
  var_samp: sensorflow_node_measurements_var_samp_order_by
  variance: sensorflow_node_measurements_variance_order_by
}

input sensorflow_node_measurements_arr_rel_insert_input {
  data: [sensorflow_node_measurements_insert_input!]!
  on_conflict: sensorflow_node_measurements_on_conflict
}

type sensorflow_node_measurements_avg_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_avg_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

input sensorflow_node_measurements_bool_exp {
  _and: [sensorflow_node_measurements_bool_exp!]
  _not: sensorflow_node_measurements_bool_exp
  _or: [sensorflow_node_measurements_bool_exp!]
  measurementType: String_comparison_exp
  measurementValue: numeric_comparison_exp
  node: sensorflow_nodes_bool_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  srcMacId: String_comparison_exp
  streamIndex: Int_comparison_exp
  time: timestamptz_comparison_exp
}

enum sensorflow_node_measurements_constraint {
  node_measurements_unique_idx
}

input sensorflow_node_measurements_inc_input {
  measurementValue: numeric
  streamIndex: Int
}

input sensorflow_node_measurements_insert_input {
  measurementType: String
  measurementValue: numeric
  node: sensorflow_nodes_obj_rel_insert_input
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  srcMacId: String
  streamIndex: Int
  time: timestamptz
}

type sensorflow_node_measurements_max_fields {
  measurementType: String
  measurementValue: numeric
  positionId: uuid
  srcMacId: String
  streamIndex: Int
  time: timestamptz
}

input sensorflow_node_measurements_max_order_by {
  measurementType: order_by
  measurementValue: order_by
  positionId: order_by
  srcMacId: order_by
  streamIndex: order_by
  time: order_by
}

type sensorflow_node_measurements_min_fields {
  measurementType: String
  measurementValue: numeric
  positionId: uuid
  srcMacId: String
  streamIndex: Int
  time: timestamptz
}

input sensorflow_node_measurements_min_order_by {
  measurementType: order_by
  measurementValue: order_by
  positionId: order_by
  srcMacId: order_by
  streamIndex: order_by
  time: order_by
}

type sensorflow_node_measurements_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_measurements!]!
}

input sensorflow_node_measurements_on_conflict {
  constraint: sensorflow_node_measurements_constraint!
  update_columns: [sensorflow_node_measurements_update_column!]! = []
  where: sensorflow_node_measurements_bool_exp
}

input sensorflow_node_measurements_order_by {
  measurementType: order_by
  measurementValue: order_by
  node: sensorflow_nodes_order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  srcMacId: order_by
  streamIndex: order_by
  time: order_by
}

enum sensorflow_node_measurements_select_column {
  measurementType
  measurementValue
  positionId
  srcMacId
  streamIndex
  time
}

input sensorflow_node_measurements_set_input {
  measurementType: String
  measurementValue: numeric
  positionId: uuid
  srcMacId: String
  streamIndex: Int
  time: timestamptz
}

type sensorflow_node_measurements_stddev_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_stddev_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

type sensorflow_node_measurements_stddev_pop_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_stddev_pop_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

type sensorflow_node_measurements_stddev_samp_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_stddev_samp_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

input sensorflow_node_measurements_stream_cursor_input {
  initial_value: sensorflow_node_measurements_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_measurements_stream_cursor_value_input {
  measurementType: String
  measurementValue: numeric
  positionId: uuid
  srcMacId: String
  streamIndex: Int
  time: timestamptz
}

type sensorflow_node_measurements_sum_fields {
  measurementValue: numeric
  streamIndex: Int
}

input sensorflow_node_measurements_sum_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

enum sensorflow_node_measurements_update_column {
  measurementType
  measurementValue
  positionId
  srcMacId
  streamIndex
  time
}

input sensorflow_node_measurements_updates {
  _inc: sensorflow_node_measurements_inc_input
  _set: sensorflow_node_measurements_set_input
  where: sensorflow_node_measurements_bool_exp!
}

type sensorflow_node_measurements_var_pop_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_var_pop_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

type sensorflow_node_measurements_var_samp_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_var_samp_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

type sensorflow_node_measurements_variance_fields {
  measurementValue: Float
  streamIndex: Float
}

input sensorflow_node_measurements_variance_order_by {
  measurementValue: order_by
  streamIndex: order_by
}

type sensorflow_node_meta_data {
  measurementType: String!
  measurementValue: numeric!
  node: sensorflow_nodes!
  nodeMacId: String!
  time: timestamptz!
}

type sensorflow_node_meta_data_aggregate {
  aggregate: sensorflow_node_meta_data_aggregate_fields
  nodes: [sensorflow_node_meta_data!]!
}

input sensorflow_node_meta_data_aggregate_bool_exp {
  count: sensorflow_node_meta_data_aggregate_bool_exp_count
}

input sensorflow_node_meta_data_aggregate_bool_exp_count {
  arguments: [sensorflow_node_meta_data_select_column!]
  distinct: Boolean
  filter: sensorflow_node_meta_data_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_meta_data_aggregate_fields {
  avg: sensorflow_node_meta_data_avg_fields
  count(columns: [sensorflow_node_meta_data_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_meta_data_max_fields
  min: sensorflow_node_meta_data_min_fields
  stddev: sensorflow_node_meta_data_stddev_fields
  stddev_pop: sensorflow_node_meta_data_stddev_pop_fields
  stddev_samp: sensorflow_node_meta_data_stddev_samp_fields
  sum: sensorflow_node_meta_data_sum_fields
  var_pop: sensorflow_node_meta_data_var_pop_fields
  var_samp: sensorflow_node_meta_data_var_samp_fields
  variance: sensorflow_node_meta_data_variance_fields
}

input sensorflow_node_meta_data_aggregate_order_by {
  avg: sensorflow_node_meta_data_avg_order_by
  count: order_by
  max: sensorflow_node_meta_data_max_order_by
  min: sensorflow_node_meta_data_min_order_by
  stddev: sensorflow_node_meta_data_stddev_order_by
  stddev_pop: sensorflow_node_meta_data_stddev_pop_order_by
  stddev_samp: sensorflow_node_meta_data_stddev_samp_order_by
  sum: sensorflow_node_meta_data_sum_order_by
  var_pop: sensorflow_node_meta_data_var_pop_order_by
  var_samp: sensorflow_node_meta_data_var_samp_order_by
  variance: sensorflow_node_meta_data_variance_order_by
}

input sensorflow_node_meta_data_arr_rel_insert_input {
  data: [sensorflow_node_meta_data_insert_input!]!
  on_conflict: sensorflow_node_meta_data_on_conflict
}

type sensorflow_node_meta_data_avg_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_avg_order_by {
  measurementValue: order_by
}

input sensorflow_node_meta_data_bool_exp {
  _and: [sensorflow_node_meta_data_bool_exp!]
  _not: sensorflow_node_meta_data_bool_exp
  _or: [sensorflow_node_meta_data_bool_exp!]
  measurementType: String_comparison_exp
  measurementValue: numeric_comparison_exp
  node: sensorflow_nodes_bool_exp
  nodeMacId: String_comparison_exp
  time: timestamptz_comparison_exp
}

enum sensorflow_node_meta_data_constraint {
  node_meta_data_pk
  node_meta_data_unique_idx
}

input sensorflow_node_meta_data_inc_input {
  measurementValue: numeric
}

input sensorflow_node_meta_data_insert_input {
  measurementType: String
  measurementValue: numeric
  node: sensorflow_nodes_obj_rel_insert_input
  nodeMacId: String
  time: timestamptz
}

type sensorflow_node_meta_data_max_fields {
  measurementType: String
  measurementValue: numeric
  nodeMacId: String
  time: timestamptz
}

input sensorflow_node_meta_data_max_order_by {
  measurementType: order_by
  measurementValue: order_by
  nodeMacId: order_by
  time: order_by
}

type sensorflow_node_meta_data_min_fields {
  measurementType: String
  measurementValue: numeric
  nodeMacId: String
  time: timestamptz
}

input sensorflow_node_meta_data_min_order_by {
  measurementType: order_by
  measurementValue: order_by
  nodeMacId: order_by
  time: order_by
}

type sensorflow_node_meta_data_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_meta_data!]!
}

input sensorflow_node_meta_data_on_conflict {
  constraint: sensorflow_node_meta_data_constraint!
  update_columns: [sensorflow_node_meta_data_update_column!]! = []
  where: sensorflow_node_meta_data_bool_exp
}

input sensorflow_node_meta_data_order_by {
  measurementType: order_by
  measurementValue: order_by
  node: sensorflow_nodes_order_by
  nodeMacId: order_by
  time: order_by
}

input sensorflow_node_meta_data_pk_columns_input {
  measurementType: String!
  nodeMacId: String!
  time: timestamptz!
}

enum sensorflow_node_meta_data_select_column {
  measurementType
  measurementValue
  nodeMacId
  time
}

input sensorflow_node_meta_data_set_input {
  measurementType: String
  measurementValue: numeric
  nodeMacId: String
  time: timestamptz
}

type sensorflow_node_meta_data_stddev_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_stddev_order_by {
  measurementValue: order_by
}

type sensorflow_node_meta_data_stddev_pop_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_stddev_pop_order_by {
  measurementValue: order_by
}

type sensorflow_node_meta_data_stddev_samp_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_stddev_samp_order_by {
  measurementValue: order_by
}

input sensorflow_node_meta_data_stream_cursor_input {
  initial_value: sensorflow_node_meta_data_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_meta_data_stream_cursor_value_input {
  measurementType: String
  measurementValue: numeric
  nodeMacId: String
  time: timestamptz
}

type sensorflow_node_meta_data_sum_fields {
  measurementValue: numeric
}

input sensorflow_node_meta_data_sum_order_by {
  measurementValue: order_by
}

enum sensorflow_node_meta_data_update_column {
  measurementType
  measurementValue
  nodeMacId
  time
}

input sensorflow_node_meta_data_updates {
  _inc: sensorflow_node_meta_data_inc_input
  _set: sensorflow_node_meta_data_set_input
  where: sensorflow_node_meta_data_bool_exp!
}

type sensorflow_node_meta_data_var_pop_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_var_pop_order_by {
  measurementValue: order_by
}

type sensorflow_node_meta_data_var_samp_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_var_samp_order_by {
  measurementValue: order_by
}

type sensorflow_node_meta_data_variance_fields {
  measurementValue: Float
}

input sensorflow_node_meta_data_variance_order_by {
  measurementValue: order_by
}

type sensorflow_node_meta_status {
  bootTime: numeric
  firmwareVersion: String
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  position: sensorflow_positions
  positionId: uuid
  recentJoinCount: bigint
  slotName: String
}

type sensorflow_node_meta_status_aggregate {
  aggregate: sensorflow_node_meta_status_aggregate_fields
  nodes: [sensorflow_node_meta_status!]!
}

type sensorflow_node_meta_status_aggregate_fields {
  avg: sensorflow_node_meta_status_avg_fields
  count(columns: [sensorflow_node_meta_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_meta_status_max_fields
  min: sensorflow_node_meta_status_min_fields
  stddev: sensorflow_node_meta_status_stddev_fields
  stddev_pop: sensorflow_node_meta_status_stddev_pop_fields
  stddev_samp: sensorflow_node_meta_status_stddev_samp_fields
  sum: sensorflow_node_meta_status_sum_fields
  var_pop: sensorflow_node_meta_status_var_pop_fields
  var_samp: sensorflow_node_meta_status_var_samp_fields
  variance: sensorflow_node_meta_status_variance_fields
}

type sensorflow_node_meta_status_avg_fields {
  bootTime: Float
  recentJoinCount: Float
}

input sensorflow_node_meta_status_bool_exp {
  _and: [sensorflow_node_meta_status_bool_exp!]
  _not: sensorflow_node_meta_status_bool_exp
  _or: [sensorflow_node_meta_status_bool_exp!]
  bootTime: numeric_comparison_exp
  firmwareVersion: String_comparison_exp
  mappedTime: timestamptz_comparison_exp
  nodeMacId: String_comparison_exp
  nodeType: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  recentJoinCount: bigint_comparison_exp
  slotName: String_comparison_exp
}

input sensorflow_node_meta_status_insert_input {
  bootTime: numeric
  firmwareVersion: String
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  recentJoinCount: bigint
  slotName: String
}

type sensorflow_node_meta_status_max_fields {
  bootTime: numeric
  firmwareVersion: String
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  positionId: uuid
  recentJoinCount: bigint
  slotName: String
}

type sensorflow_node_meta_status_min_fields {
  bootTime: numeric
  firmwareVersion: String
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  positionId: uuid
  recentJoinCount: bigint
  slotName: String
}

input sensorflow_node_meta_status_obj_rel_insert_input {
  data: sensorflow_node_meta_status_insert_input!
}

input sensorflow_node_meta_status_order_by {
  bootTime: order_by
  firmwareVersion: order_by
  mappedTime: order_by
  nodeMacId: order_by
  nodeType: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  recentJoinCount: order_by
  slotName: order_by
}

enum sensorflow_node_meta_status_select_column {
  bootTime
  firmwareVersion
  mappedTime
  nodeMacId
  nodeType
  positionId
  recentJoinCount
  slotName
}

type sensorflow_node_meta_status_stddev_fields {
  bootTime: Float
  recentJoinCount: Float
}

type sensorflow_node_meta_status_stddev_pop_fields {
  bootTime: Float
  recentJoinCount: Float
}

type sensorflow_node_meta_status_stddev_samp_fields {
  bootTime: Float
  recentJoinCount: Float
}

input sensorflow_node_meta_status_stream_cursor_input {
  initial_value: sensorflow_node_meta_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_meta_status_stream_cursor_value_input {
  bootTime: numeric
  firmwareVersion: String
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  positionId: uuid
  recentJoinCount: bigint
  slotName: String
}

type sensorflow_node_meta_status_sum_fields {
  bootTime: numeric
  recentJoinCount: bigint
}

type sensorflow_node_meta_status_var_pop_fields {
  bootTime: Float
  recentJoinCount: Float
}

type sensorflow_node_meta_status_var_samp_fields {
  bootTime: Float
  recentJoinCount: Float
}

type sensorflow_node_meta_status_variance_fields {
  bootTime: Float
  recentJoinCount: Float
}

type sensorflow_node_online_status {
  lastJoinRequest: timestamptz
  lastJoinSuccess: timestamptz
  lastMeasurement: timestamptz
  lastTransmission: timestamptz
  locationId: uuid
  nodeMacId: String
  nodeStatus: String
  nodeStatusInInstallationMode: String
  nodeType: String
  position: sensorflow_positions
  positionId: uuid
}

type sensorflow_node_online_status_aggregate {
  aggregate: sensorflow_node_online_status_aggregate_fields
  nodes: [sensorflow_node_online_status!]!
}

type sensorflow_node_online_status_aggregate_fields {
  count(columns: [sensorflow_node_online_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_online_status_max_fields
  min: sensorflow_node_online_status_min_fields
}

input sensorflow_node_online_status_bool_exp {
  _and: [sensorflow_node_online_status_bool_exp!]
  _not: sensorflow_node_online_status_bool_exp
  _or: [sensorflow_node_online_status_bool_exp!]
  lastJoinRequest: timestamptz_comparison_exp
  lastJoinSuccess: timestamptz_comparison_exp
  lastMeasurement: timestamptz_comparison_exp
  lastTransmission: timestamptz_comparison_exp
  locationId: uuid_comparison_exp
  nodeMacId: String_comparison_exp
  nodeStatus: String_comparison_exp
  nodeStatusInInstallationMode: String_comparison_exp
  nodeType: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

input sensorflow_node_online_status_insert_input {
  lastJoinRequest: timestamptz
  lastJoinSuccess: timestamptz
  lastMeasurement: timestamptz
  lastTransmission: timestamptz
  locationId: uuid
  nodeMacId: String
  nodeStatus: String
  nodeStatusInInstallationMode: String
  nodeType: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_node_online_status_max_fields {
  lastJoinRequest: timestamptz
  lastJoinSuccess: timestamptz
  lastMeasurement: timestamptz
  lastTransmission: timestamptz
  locationId: uuid
  nodeMacId: String
  nodeStatus: String
  nodeStatusInInstallationMode: String
  nodeType: String
  positionId: uuid
}

type sensorflow_node_online_status_min_fields {
  lastJoinRequest: timestamptz
  lastJoinSuccess: timestamptz
  lastMeasurement: timestamptz
  lastTransmission: timestamptz
  locationId: uuid
  nodeMacId: String
  nodeStatus: String
  nodeStatusInInstallationMode: String
  nodeType: String
  positionId: uuid
}

input sensorflow_node_online_status_obj_rel_insert_input {
  data: sensorflow_node_online_status_insert_input!
}

input sensorflow_node_online_status_order_by {
  lastJoinRequest: order_by
  lastJoinSuccess: order_by
  lastMeasurement: order_by
  lastTransmission: order_by
  locationId: order_by
  nodeMacId: order_by
  nodeStatus: order_by
  nodeStatusInInstallationMode: order_by
  nodeType: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

enum sensorflow_node_online_status_select_column {
  lastJoinRequest
  lastJoinSuccess
  lastMeasurement
  lastTransmission
  locationId
  nodeMacId
  nodeStatus
  nodeStatusInInstallationMode
  nodeType
  positionId
}

input sensorflow_node_online_status_stream_cursor_input {
  initial_value: sensorflow_node_online_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_online_status_stream_cursor_value_input {
  lastJoinRequest: timestamptz
  lastJoinSuccess: timestamptz
  lastMeasurement: timestamptz
  lastTransmission: timestamptz
  locationId: uuid
  nodeMacId: String
  nodeStatus: String
  nodeStatusInInstallationMode: String
  nodeType: String
  positionId: uuid
}

type sensorflow_node_slots {
  id: uuid!
  mappingCoordinates: sensorflow_mapping_coordinates
  nodeSubType: String
  nodeType: String!
  parentPositionId: uuid!
  position: sensorflow_positions!
  slotCreationDate: timestamptz!
  slotMappings(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): [sensorflow_node_to_slot_mapping!]!
  slotMappings_aggregate(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): sensorflow_node_to_slot_mapping_aggregate!
  slotName: String!
  slotRemoveDate: timestamptz
}

type sensorflow_node_slots_aggregate {
  aggregate: sensorflow_node_slots_aggregate_fields
  nodes: [sensorflow_node_slots!]!
}

input sensorflow_node_slots_aggregate_bool_exp {
  count: sensorflow_node_slots_aggregate_bool_exp_count
}

input sensorflow_node_slots_aggregate_bool_exp_count {
  arguments: [sensorflow_node_slots_select_column!]
  distinct: Boolean
  filter: sensorflow_node_slots_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_slots_aggregate_fields {
  count(columns: [sensorflow_node_slots_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_slots_max_fields
  min: sensorflow_node_slots_min_fields
}

input sensorflow_node_slots_aggregate_order_by {
  count: order_by
  max: sensorflow_node_slots_max_order_by
  min: sensorflow_node_slots_min_order_by
}

input sensorflow_node_slots_arr_rel_insert_input {
  data: [sensorflow_node_slots_insert_input!]!
  on_conflict: sensorflow_node_slots_on_conflict
}

input sensorflow_node_slots_bool_exp {
  _and: [sensorflow_node_slots_bool_exp!]
  _not: sensorflow_node_slots_bool_exp
  _or: [sensorflow_node_slots_bool_exp!]
  id: uuid_comparison_exp
  mappingCoordinates: sensorflow_mapping_coordinates_bool_exp
  nodeSubType: String_comparison_exp
  nodeType: String_comparison_exp
  parentPositionId: uuid_comparison_exp
  position: sensorflow_positions_bool_exp
  slotCreationDate: timestamptz_comparison_exp
  slotMappings: sensorflow_node_to_slot_mapping_bool_exp
  slotMappings_aggregate: sensorflow_node_to_slot_mapping_aggregate_bool_exp
  slotName: String_comparison_exp
  slotRemoveDate: timestamptz_comparison_exp
}

enum sensorflow_node_slots_constraint {
  node_slots_pkey
  unique_parent_node_type_slot_name
}

input sensorflow_node_slots_insert_input {
  id: uuid
  mappingCoordinates: sensorflow_mapping_coordinates_obj_rel_insert_input
  nodeSubType: String
  nodeType: String
  parentPositionId: uuid
  position: sensorflow_positions_obj_rel_insert_input
  slotCreationDate: timestamptz
  slotMappings: sensorflow_node_to_slot_mapping_arr_rel_insert_input
  slotName: String
  slotRemoveDate: timestamptz
}

type sensorflow_node_slots_max_fields {
  id: uuid
  nodeSubType: String
  nodeType: String
  parentPositionId: uuid
  slotCreationDate: timestamptz
  slotName: String
  slotRemoveDate: timestamptz
}

input sensorflow_node_slots_max_order_by {
  id: order_by
  nodeSubType: order_by
  nodeType: order_by
  parentPositionId: order_by
  slotCreationDate: order_by
  slotName: order_by
  slotRemoveDate: order_by
}

type sensorflow_node_slots_min_fields {
  id: uuid
  nodeSubType: String
  nodeType: String
  parentPositionId: uuid
  slotCreationDate: timestamptz
  slotName: String
  slotRemoveDate: timestamptz
}

input sensorflow_node_slots_min_order_by {
  id: order_by
  nodeSubType: order_by
  nodeType: order_by
  parentPositionId: order_by
  slotCreationDate: order_by
  slotName: order_by
  slotRemoveDate: order_by
}

type sensorflow_node_slots_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_slots!]!
}

input sensorflow_node_slots_obj_rel_insert_input {
  data: sensorflow_node_slots_insert_input!
  on_conflict: sensorflow_node_slots_on_conflict
}

input sensorflow_node_slots_on_conflict {
  constraint: sensorflow_node_slots_constraint!
  update_columns: [sensorflow_node_slots_update_column!]! = []
  where: sensorflow_node_slots_bool_exp
}

input sensorflow_node_slots_order_by {
  id: order_by
  mappingCoordinates: sensorflow_mapping_coordinates_order_by
  nodeSubType: order_by
  nodeType: order_by
  parentPositionId: order_by
  position: sensorflow_positions_order_by
  slotCreationDate: order_by
  slotMappings_aggregate: sensorflow_node_to_slot_mapping_aggregate_order_by
  slotName: order_by
  slotRemoveDate: order_by
}

input sensorflow_node_slots_pk_columns_input {
  id: uuid!
}

enum sensorflow_node_slots_select_column {
  id
  nodeSubType
  nodeType
  parentPositionId
  slotCreationDate
  slotName
  slotRemoveDate
}

input sensorflow_node_slots_set_input {
  id: uuid
  nodeSubType: String
  nodeType: String
  parentPositionId: uuid
  slotCreationDate: timestamptz
  slotName: String
  slotRemoveDate: timestamptz
}

input sensorflow_node_slots_stream_cursor_input {
  initial_value: sensorflow_node_slots_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_slots_stream_cursor_value_input {
  id: uuid
  nodeSubType: String
  nodeType: String
  parentPositionId: uuid
  slotCreationDate: timestamptz
  slotName: String
  slotRemoveDate: timestamptz
}

enum sensorflow_node_slots_update_column {
  id
  nodeSubType
  nodeType
  parentPositionId
  slotCreationDate
  slotName
  slotRemoveDate
}

input sensorflow_node_slots_updates {
  _set: sensorflow_node_slots_set_input
  where: sensorflow_node_slots_bool_exp!
}

type sensorflow_node_spare_set {
  clickupTaskId: String
  consumedAt: timestamptz
  locationId: uuid!
  mappedAt: timestamptz!
  node: sensorflow_nodes!
  nodeMacId: String!
}

type sensorflow_node_spare_set_aggregate {
  aggregate: sensorflow_node_spare_set_aggregate_fields
  nodes: [sensorflow_node_spare_set!]!
}

type sensorflow_node_spare_set_aggregate_fields {
  count(columns: [sensorflow_node_spare_set_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_spare_set_max_fields
  min: sensorflow_node_spare_set_min_fields
}

input sensorflow_node_spare_set_bool_exp {
  _and: [sensorflow_node_spare_set_bool_exp!]
  _not: sensorflow_node_spare_set_bool_exp
  _or: [sensorflow_node_spare_set_bool_exp!]
  clickupTaskId: String_comparison_exp
  consumedAt: timestamptz_comparison_exp
  locationId: uuid_comparison_exp
  mappedAt: timestamptz_comparison_exp
  node: sensorflow_nodes_bool_exp
  nodeMacId: String_comparison_exp
}

enum sensorflow_node_spare_set_constraint {
  pk_node_spare_set
}

input sensorflow_node_spare_set_insert_input {
  clickupTaskId: String
  consumedAt: timestamptz
  locationId: uuid
  mappedAt: timestamptz
  node: sensorflow_nodes_obj_rel_insert_input
  nodeMacId: String
}

type sensorflow_node_spare_set_max_fields {
  clickupTaskId: String
  consumedAt: timestamptz
  locationId: uuid
  mappedAt: timestamptz
  nodeMacId: String
}

type sensorflow_node_spare_set_min_fields {
  clickupTaskId: String
  consumedAt: timestamptz
  locationId: uuid
  mappedAt: timestamptz
  nodeMacId: String
}

type sensorflow_node_spare_set_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_spare_set!]!
}

input sensorflow_node_spare_set_on_conflict {
  constraint: sensorflow_node_spare_set_constraint!
  update_columns: [sensorflow_node_spare_set_update_column!]! = []
  where: sensorflow_node_spare_set_bool_exp
}

input sensorflow_node_spare_set_order_by {
  clickupTaskId: order_by
  consumedAt: order_by
  locationId: order_by
  mappedAt: order_by
  node: sensorflow_nodes_order_by
  nodeMacId: order_by
}

input sensorflow_node_spare_set_pk_columns_input {
  locationId: uuid!
  nodeMacId: String!
}

enum sensorflow_node_spare_set_select_column {
  clickupTaskId
  consumedAt
  locationId
  mappedAt
  nodeMacId
}

input sensorflow_node_spare_set_set_input {
  clickupTaskId: String
  consumedAt: timestamptz
  locationId: uuid
  mappedAt: timestamptz
  nodeMacId: String
}

input sensorflow_node_spare_set_stream_cursor_input {
  initial_value: sensorflow_node_spare_set_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_spare_set_stream_cursor_value_input {
  clickupTaskId: String
  consumedAt: timestamptz
  locationId: uuid
  mappedAt: timestamptz
  nodeMacId: String
}

enum sensorflow_node_spare_set_update_column {
  clickupTaskId
  consumedAt
  locationId
  mappedAt
  nodeMacId
}

input sensorflow_node_spare_set_updates {
  _set: sensorflow_node_spare_set_set_input
  where: sensorflow_node_spare_set_bool_exp!
}

type sensorflow_node_status_history {
  entered_at: timestamptz!
  id: Int!
  node_mac_id: String!
  status_id: Int
}

type sensorflow_node_status_history_aggregate {
  aggregate: sensorflow_node_status_history_aggregate_fields
  nodes: [sensorflow_node_status_history!]!
}

type sensorflow_node_status_history_aggregate_fields {
  avg: sensorflow_node_status_history_avg_fields
  count(columns: [sensorflow_node_status_history_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_status_history_max_fields
  min: sensorflow_node_status_history_min_fields
  stddev: sensorflow_node_status_history_stddev_fields
  stddev_pop: sensorflow_node_status_history_stddev_pop_fields
  stddev_samp: sensorflow_node_status_history_stddev_samp_fields
  sum: sensorflow_node_status_history_sum_fields
  var_pop: sensorflow_node_status_history_var_pop_fields
  var_samp: sensorflow_node_status_history_var_samp_fields
  variance: sensorflow_node_status_history_variance_fields
}

type sensorflow_node_status_history_avg_fields {
  id: Float
  status_id: Float
}

input sensorflow_node_status_history_bool_exp {
  _and: [sensorflow_node_status_history_bool_exp!]
  _not: sensorflow_node_status_history_bool_exp
  _or: [sensorflow_node_status_history_bool_exp!]
  entered_at: timestamptz_comparison_exp
  id: Int_comparison_exp
  node_mac_id: String_comparison_exp
  status_id: Int_comparison_exp
}

enum sensorflow_node_status_history_constraint {
  node_status_history_pkey
}

input sensorflow_node_status_history_inc_input {
  id: Int
  status_id: Int
}

input sensorflow_node_status_history_insert_input {
  entered_at: timestamptz
  id: Int
  node_mac_id: String
  status_id: Int
}

type sensorflow_node_status_history_max_fields {
  entered_at: timestamptz
  id: Int
  node_mac_id: String
  status_id: Int
}

type sensorflow_node_status_history_min_fields {
  entered_at: timestamptz
  id: Int
  node_mac_id: String
  status_id: Int
}

type sensorflow_node_status_history_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_status_history!]!
}

input sensorflow_node_status_history_on_conflict {
  constraint: sensorflow_node_status_history_constraint!
  update_columns: [sensorflow_node_status_history_update_column!]! = []
  where: sensorflow_node_status_history_bool_exp
}

input sensorflow_node_status_history_order_by {
  entered_at: order_by
  id: order_by
  node_mac_id: order_by
  status_id: order_by
}

input sensorflow_node_status_history_pk_columns_input {
  id: Int!
}

enum sensorflow_node_status_history_select_column {
  entered_at
  id
  node_mac_id
  status_id
}

input sensorflow_node_status_history_set_input {
  entered_at: timestamptz
  id: Int
  node_mac_id: String
  status_id: Int
}

type sensorflow_node_status_history_stddev_fields {
  id: Float
  status_id: Float
}

type sensorflow_node_status_history_stddev_pop_fields {
  id: Float
  status_id: Float
}

type sensorflow_node_status_history_stddev_samp_fields {
  id: Float
  status_id: Float
}

input sensorflow_node_status_history_stream_cursor_input {
  initial_value: sensorflow_node_status_history_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_status_history_stream_cursor_value_input {
  entered_at: timestamptz
  id: Int
  node_mac_id: String
  status_id: Int
}

type sensorflow_node_status_history_sum_fields {
  id: Int
  status_id: Int
}

enum sensorflow_node_status_history_update_column {
  entered_at
  id
  node_mac_id
  status_id
}

input sensorflow_node_status_history_updates {
  _inc: sensorflow_node_status_history_inc_input
  _set: sensorflow_node_status_history_set_input
  where: sensorflow_node_status_history_bool_exp!
}

type sensorflow_node_status_history_var_pop_fields {
  id: Float
  status_id: Float
}

type sensorflow_node_status_history_var_samp_fields {
  id: Float
  status_id: Float
}

type sensorflow_node_status_history_variance_fields {
  id: Float
  status_id: Float
}

type sensorflow_node_statuses {
  id: Int!
  name: String!
}

type sensorflow_node_statuses_aggregate {
  aggregate: sensorflow_node_statuses_aggregate_fields
  nodes: [sensorflow_node_statuses!]!
}

type sensorflow_node_statuses_aggregate_fields {
  avg: sensorflow_node_statuses_avg_fields
  count(columns: [sensorflow_node_statuses_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_statuses_max_fields
  min: sensorflow_node_statuses_min_fields
  stddev: sensorflow_node_statuses_stddev_fields
  stddev_pop: sensorflow_node_statuses_stddev_pop_fields
  stddev_samp: sensorflow_node_statuses_stddev_samp_fields
  sum: sensorflow_node_statuses_sum_fields
  var_pop: sensorflow_node_statuses_var_pop_fields
  var_samp: sensorflow_node_statuses_var_samp_fields
  variance: sensorflow_node_statuses_variance_fields
}

type sensorflow_node_statuses_avg_fields {
  id: Float
}

input sensorflow_node_statuses_bool_exp {
  _and: [sensorflow_node_statuses_bool_exp!]
  _not: sensorflow_node_statuses_bool_exp
  _or: [sensorflow_node_statuses_bool_exp!]
  id: Int_comparison_exp
  name: String_comparison_exp
}

enum sensorflow_node_statuses_constraint {
  node_statuses_pkey
}

input sensorflow_node_statuses_inc_input {
  id: Int
}

input sensorflow_node_statuses_insert_input {
  id: Int
  name: String
}

type sensorflow_node_statuses_max_fields {
  id: Int
  name: String
}

type sensorflow_node_statuses_min_fields {
  id: Int
  name: String
}

type sensorflow_node_statuses_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_statuses!]!
}

input sensorflow_node_statuses_on_conflict {
  constraint: sensorflow_node_statuses_constraint!
  update_columns: [sensorflow_node_statuses_update_column!]! = []
  where: sensorflow_node_statuses_bool_exp
}

input sensorflow_node_statuses_order_by {
  id: order_by
  name: order_by
}

input sensorflow_node_statuses_pk_columns_input {
  id: Int!
}

enum sensorflow_node_statuses_select_column {
  id
  name
}

input sensorflow_node_statuses_set_input {
  id: Int
  name: String
}

type sensorflow_node_statuses_stddev_fields {
  id: Float
}

type sensorflow_node_statuses_stddev_pop_fields {
  id: Float
}

type sensorflow_node_statuses_stddev_samp_fields {
  id: Float
}

input sensorflow_node_statuses_stream_cursor_input {
  initial_value: sensorflow_node_statuses_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_statuses_stream_cursor_value_input {
  id: Int
  name: String
}

type sensorflow_node_statuses_sum_fields {
  id: Int
}

enum sensorflow_node_statuses_update_column {
  id
  name
}

input sensorflow_node_statuses_updates {
  _inc: sensorflow_node_statuses_inc_input
  _set: sensorflow_node_statuses_set_input
  where: sensorflow_node_statuses_bool_exp!
}

type sensorflow_node_statuses_var_pop_fields {
  id: Float
}

type sensorflow_node_statuses_var_samp_fields {
  id: Float
}

type sensorflow_node_statuses_variance_fields {
  id: Float
}

type sensorflow_node_system_states {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  nodeMacId: String!
  radioRxS: bigint
  radioTxS: bigint
  time: timestamptz!
}

type sensorflow_node_system_states_aggregate {
  aggregate: sensorflow_node_system_states_aggregate_fields
  nodes: [sensorflow_node_system_states!]!
}

type sensorflow_node_system_states_aggregate_fields {
  avg: sensorflow_node_system_states_avg_fields
  count(columns: [sensorflow_node_system_states_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_system_states_max_fields
  min: sensorflow_node_system_states_min_fields
  stddev: sensorflow_node_system_states_stddev_fields
  stddev_pop: sensorflow_node_system_states_stddev_pop_fields
  stddev_samp: sensorflow_node_system_states_stddev_samp_fields
  sum: sensorflow_node_system_states_sum_fields
  var_pop: sensorflow_node_system_states_var_pop_fields
  var_samp: sensorflow_node_system_states_var_samp_fields
  variance: sensorflow_node_system_states_variance_fields
}

type sensorflow_node_system_states_avg_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

input sensorflow_node_system_states_bool_exp {
  _and: [sensorflow_node_system_states_bool_exp!]
  _not: sensorflow_node_system_states_bool_exp
  _or: [sensorflow_node_system_states_bool_exp!]
  asleepS: bigint_comparison_exp
  awakeS: bigint_comparison_exp
  extra1S: bigint_comparison_exp
  extra2S: bigint_comparison_exp
  nodeMacId: String_comparison_exp
  radioRxS: bigint_comparison_exp
  radioTxS: bigint_comparison_exp
  time: timestamptz_comparison_exp
}

enum sensorflow_node_system_states_constraint {
  node_system_states_pkey
}

input sensorflow_node_system_states_inc_input {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  radioRxS: bigint
  radioTxS: bigint
}

input sensorflow_node_system_states_insert_input {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  nodeMacId: String
  radioRxS: bigint
  radioTxS: bigint
  time: timestamptz
}

type sensorflow_node_system_states_max_fields {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  nodeMacId: String
  radioRxS: bigint
  radioTxS: bigint
  time: timestamptz
}

type sensorflow_node_system_states_min_fields {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  nodeMacId: String
  radioRxS: bigint
  radioTxS: bigint
  time: timestamptz
}

type sensorflow_node_system_states_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_system_states!]!
}

input sensorflow_node_system_states_on_conflict {
  constraint: sensorflow_node_system_states_constraint!
  update_columns: [sensorflow_node_system_states_update_column!]! = []
  where: sensorflow_node_system_states_bool_exp
}

input sensorflow_node_system_states_order_by {
  asleepS: order_by
  awakeS: order_by
  extra1S: order_by
  extra2S: order_by
  nodeMacId: order_by
  radioRxS: order_by
  radioTxS: order_by
  time: order_by
}

input sensorflow_node_system_states_pk_columns_input {
  nodeMacId: String!
  time: timestamptz!
}

enum sensorflow_node_system_states_select_column {
  asleepS
  awakeS
  extra1S
  extra2S
  nodeMacId
  radioRxS
  radioTxS
  time
}

input sensorflow_node_system_states_set_input {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  nodeMacId: String
  radioRxS: bigint
  radioTxS: bigint
  time: timestamptz
}

type sensorflow_node_system_states_stddev_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

type sensorflow_node_system_states_stddev_pop_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

type sensorflow_node_system_states_stddev_samp_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

input sensorflow_node_system_states_stream_cursor_input {
  initial_value: sensorflow_node_system_states_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_system_states_stream_cursor_value_input {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  nodeMacId: String
  radioRxS: bigint
  radioTxS: bigint
  time: timestamptz
}

type sensorflow_node_system_states_sum_fields {
  asleepS: bigint
  awakeS: bigint
  extra1S: bigint
  extra2S: bigint
  radioRxS: bigint
  radioTxS: bigint
}

enum sensorflow_node_system_states_update_column {
  asleepS
  awakeS
  extra1S
  extra2S
  nodeMacId
  radioRxS
  radioTxS
  time
}

input sensorflow_node_system_states_updates {
  _inc: sensorflow_node_system_states_inc_input
  _set: sensorflow_node_system_states_set_input
  where: sensorflow_node_system_states_bool_exp!
}

type sensorflow_node_system_states_var_pop_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

type sensorflow_node_system_states_var_samp_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

type sensorflow_node_system_states_variance_fields {
  asleepS: Float
  awakeS: Float
  extra1S: Float
  extra2S: Float
  radioRxS: Float
  radioTxS: Float
}

type sensorflow_node_to_position_mapping {
  decomissionedTime: timestamptz
  installedTime: timestamptz
  nodeMacId: String
  positionId: uuid
}

type sensorflow_node_to_position_mapping_aggregate {
  aggregate: sensorflow_node_to_position_mapping_aggregate_fields
  nodes: [sensorflow_node_to_position_mapping!]!
}

type sensorflow_node_to_position_mapping_aggregate_fields {
  count(columns: [sensorflow_node_to_position_mapping_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_to_position_mapping_max_fields
  min: sensorflow_node_to_position_mapping_min_fields
}

input sensorflow_node_to_position_mapping_bool_exp {
  _and: [sensorflow_node_to_position_mapping_bool_exp!]
  _not: sensorflow_node_to_position_mapping_bool_exp
  _or: [sensorflow_node_to_position_mapping_bool_exp!]
  decomissionedTime: timestamptz_comparison_exp
  installedTime: timestamptz_comparison_exp
  nodeMacId: String_comparison_exp
  positionId: uuid_comparison_exp
}

input sensorflow_node_to_position_mapping_insert_input {
  decomissionedTime: timestamptz
  installedTime: timestamptz
  nodeMacId: String
  positionId: uuid
}

type sensorflow_node_to_position_mapping_max_fields {
  decomissionedTime: timestamptz
  installedTime: timestamptz
  nodeMacId: String
  positionId: uuid
}

type sensorflow_node_to_position_mapping_min_fields {
  decomissionedTime: timestamptz
  installedTime: timestamptz
  nodeMacId: String
  positionId: uuid
}

type sensorflow_node_to_position_mapping_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_to_position_mapping!]!
}

input sensorflow_node_to_position_mapping_order_by {
  decomissionedTime: order_by
  installedTime: order_by
  nodeMacId: order_by
  positionId: order_by
}

enum sensorflow_node_to_position_mapping_select_column {
  decomissionedTime
  installedTime
  nodeMacId
  positionId
}

input sensorflow_node_to_position_mapping_set_input {
  decomissionedTime: timestamptz
  installedTime: timestamptz
  nodeMacId: String
  positionId: uuid
}

input sensorflow_node_to_position_mapping_stream_cursor_input {
  initial_value: sensorflow_node_to_position_mapping_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_to_position_mapping_stream_cursor_value_input {
  decomissionedTime: timestamptz
  installedTime: timestamptz
  nodeMacId: String
  positionId: uuid
}

input sensorflow_node_to_position_mapping_updates {
  _set: sensorflow_node_to_position_mapping_set_input
  where: sensorflow_node_to_position_mapping_bool_exp!
}

type sensorflow_node_to_slot_mapping {
  clickup_task_id: String
  comment: String
  decomissionedTime: timestamptz
  deleted_at: timestamptz
  id: Int!
  isPrimaryNode: Boolean
  mappedTime: timestamptz!
  node: sensorflow_nodes!
  nodeMacId: String!
  nodeType: String!
  parentPositionId: uuid!
  position: sensorflow_positions!
  rootCauseId: Int
  scannedMacId: String
  slot: sensorflow_node_slots
  slotName: String!
  updated_at: timestamptz
}

type sensorflow_node_to_slot_mapping_aggregate {
  aggregate: sensorflow_node_to_slot_mapping_aggregate_fields
  nodes: [sensorflow_node_to_slot_mapping!]!
}

input sensorflow_node_to_slot_mapping_aggregate_bool_exp {
  bool_and: sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_and
  bool_or: sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_or
  count: sensorflow_node_to_slot_mapping_aggregate_bool_exp_count
}

input sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_and {
  arguments: sensorflow_node_to_slot_mapping_select_column_sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: sensorflow_node_to_slot_mapping_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_or {
  arguments: sensorflow_node_to_slot_mapping_select_column_sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: sensorflow_node_to_slot_mapping_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_node_to_slot_mapping_aggregate_bool_exp_count {
  arguments: [sensorflow_node_to_slot_mapping_select_column!]
  distinct: Boolean
  filter: sensorflow_node_to_slot_mapping_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_node_to_slot_mapping_aggregate_fields {
  avg: sensorflow_node_to_slot_mapping_avg_fields
  count(columns: [sensorflow_node_to_slot_mapping_select_column!], distinct: Boolean): Int!
  max: sensorflow_node_to_slot_mapping_max_fields
  min: sensorflow_node_to_slot_mapping_min_fields
  stddev: sensorflow_node_to_slot_mapping_stddev_fields
  stddev_pop: sensorflow_node_to_slot_mapping_stddev_pop_fields
  stddev_samp: sensorflow_node_to_slot_mapping_stddev_samp_fields
  sum: sensorflow_node_to_slot_mapping_sum_fields
  var_pop: sensorflow_node_to_slot_mapping_var_pop_fields
  var_samp: sensorflow_node_to_slot_mapping_var_samp_fields
  variance: sensorflow_node_to_slot_mapping_variance_fields
}

input sensorflow_node_to_slot_mapping_aggregate_order_by {
  avg: sensorflow_node_to_slot_mapping_avg_order_by
  count: order_by
  max: sensorflow_node_to_slot_mapping_max_order_by
  min: sensorflow_node_to_slot_mapping_min_order_by
  stddev: sensorflow_node_to_slot_mapping_stddev_order_by
  stddev_pop: sensorflow_node_to_slot_mapping_stddev_pop_order_by
  stddev_samp: sensorflow_node_to_slot_mapping_stddev_samp_order_by
  sum: sensorflow_node_to_slot_mapping_sum_order_by
  var_pop: sensorflow_node_to_slot_mapping_var_pop_order_by
  var_samp: sensorflow_node_to_slot_mapping_var_samp_order_by
  variance: sensorflow_node_to_slot_mapping_variance_order_by
}

input sensorflow_node_to_slot_mapping_arr_rel_insert_input {
  data: [sensorflow_node_to_slot_mapping_insert_input!]!
  on_conflict: sensorflow_node_to_slot_mapping_on_conflict
}

type sensorflow_node_to_slot_mapping_avg_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_avg_order_by {
  id: order_by
  rootCauseId: order_by
}

input sensorflow_node_to_slot_mapping_bool_exp {
  _and: [sensorflow_node_to_slot_mapping_bool_exp!]
  _not: sensorflow_node_to_slot_mapping_bool_exp
  _or: [sensorflow_node_to_slot_mapping_bool_exp!]
  clickup_task_id: String_comparison_exp
  comment: String_comparison_exp
  decomissionedTime: timestamptz_comparison_exp
  deleted_at: timestamptz_comparison_exp
  id: Int_comparison_exp
  isPrimaryNode: Boolean_comparison_exp
  mappedTime: timestamptz_comparison_exp
  node: sensorflow_nodes_bool_exp
  nodeMacId: String_comparison_exp
  nodeType: String_comparison_exp
  parentPositionId: uuid_comparison_exp
  position: sensorflow_positions_bool_exp
  rootCauseId: Int_comparison_exp
  scannedMacId: String_comparison_exp
  slot: sensorflow_node_slots_bool_exp
  slotName: String_comparison_exp
  updated_at: timestamptz_comparison_exp
}

enum sensorflow_node_to_slot_mapping_constraint {
  node_to_slot_mapping_pkey
  node_to_slot_mapping_uindex
}

input sensorflow_node_to_slot_mapping_inc_input {
  id: Int
  rootCauseId: Int
}

input sensorflow_node_to_slot_mapping_insert_input {
  clickup_task_id: String
  comment: String
  decomissionedTime: timestamptz
  deleted_at: timestamptz
  id: Int
  isPrimaryNode: Boolean
  mappedTime: timestamptz
  node: sensorflow_nodes_obj_rel_insert_input
  nodeMacId: String
  nodeType: String
  parentPositionId: uuid
  position: sensorflow_positions_obj_rel_insert_input
  rootCauseId: Int
  scannedMacId: String
  slot: sensorflow_node_slots_obj_rel_insert_input
  slotName: String
  updated_at: timestamptz
}

type sensorflow_node_to_slot_mapping_max_fields {
  clickup_task_id: String
  comment: String
  decomissionedTime: timestamptz
  deleted_at: timestamptz
  id: Int
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  parentPositionId: uuid
  rootCauseId: Int
  scannedMacId: String
  slotName: String
  updated_at: timestamptz
}

input sensorflow_node_to_slot_mapping_max_order_by {
  clickup_task_id: order_by
  comment: order_by
  decomissionedTime: order_by
  deleted_at: order_by
  id: order_by
  mappedTime: order_by
  nodeMacId: order_by
  nodeType: order_by
  parentPositionId: order_by
  rootCauseId: order_by
  scannedMacId: order_by
  slotName: order_by
  updated_at: order_by
}

type sensorflow_node_to_slot_mapping_min_fields {
  clickup_task_id: String
  comment: String
  decomissionedTime: timestamptz
  deleted_at: timestamptz
  id: Int
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  parentPositionId: uuid
  rootCauseId: Int
  scannedMacId: String
  slotName: String
  updated_at: timestamptz
}

input sensorflow_node_to_slot_mapping_min_order_by {
  clickup_task_id: order_by
  comment: order_by
  decomissionedTime: order_by
  deleted_at: order_by
  id: order_by
  mappedTime: order_by
  nodeMacId: order_by
  nodeType: order_by
  parentPositionId: order_by
  rootCauseId: order_by
  scannedMacId: order_by
  slotName: order_by
  updated_at: order_by
}

type sensorflow_node_to_slot_mapping_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_node_to_slot_mapping!]!
}

input sensorflow_node_to_slot_mapping_on_conflict {
  constraint: sensorflow_node_to_slot_mapping_constraint!
  update_columns: [sensorflow_node_to_slot_mapping_update_column!]! = []
  where: sensorflow_node_to_slot_mapping_bool_exp
}

input sensorflow_node_to_slot_mapping_order_by {
  clickup_task_id: order_by
  comment: order_by
  decomissionedTime: order_by
  deleted_at: order_by
  id: order_by
  isPrimaryNode: order_by
  mappedTime: order_by
  node: sensorflow_nodes_order_by
  nodeMacId: order_by
  nodeType: order_by
  parentPositionId: order_by
  position: sensorflow_positions_order_by
  rootCauseId: order_by
  scannedMacId: order_by
  slot: sensorflow_node_slots_order_by
  slotName: order_by
  updated_at: order_by
}

input sensorflow_node_to_slot_mapping_pk_columns_input {
  id: Int!
}

enum sensorflow_node_to_slot_mapping_select_column {
  clickup_task_id
  comment
  decomissionedTime
  deleted_at
  id
  isPrimaryNode
  mappedTime
  nodeMacId
  nodeType
  parentPositionId
  rootCauseId
  scannedMacId
  slotName
  updated_at
}

enum sensorflow_node_to_slot_mapping_select_column_sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_and_arguments_columns {
  isPrimaryNode
}

enum sensorflow_node_to_slot_mapping_select_column_sensorflow_node_to_slot_mapping_aggregate_bool_exp_bool_or_arguments_columns {
  isPrimaryNode
}

input sensorflow_node_to_slot_mapping_set_input {
  clickup_task_id: String
  comment: String
  decomissionedTime: timestamptz
  deleted_at: timestamptz
  id: Int
  isPrimaryNode: Boolean
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  parentPositionId: uuid
  rootCauseId: Int
  scannedMacId: String
  slotName: String
  updated_at: timestamptz
}

type sensorflow_node_to_slot_mapping_stddev_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_stddev_order_by {
  id: order_by
  rootCauseId: order_by
}

type sensorflow_node_to_slot_mapping_stddev_pop_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_stddev_pop_order_by {
  id: order_by
  rootCauseId: order_by
}

type sensorflow_node_to_slot_mapping_stddev_samp_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_stddev_samp_order_by {
  id: order_by
  rootCauseId: order_by
}

input sensorflow_node_to_slot_mapping_stream_cursor_input {
  initial_value: sensorflow_node_to_slot_mapping_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_node_to_slot_mapping_stream_cursor_value_input {
  clickup_task_id: String
  comment: String
  decomissionedTime: timestamptz
  deleted_at: timestamptz
  id: Int
  isPrimaryNode: Boolean
  mappedTime: timestamptz
  nodeMacId: String
  nodeType: String
  parentPositionId: uuid
  rootCauseId: Int
  scannedMacId: String
  slotName: String
  updated_at: timestamptz
}

type sensorflow_node_to_slot_mapping_sum_fields {
  id: Int
  rootCauseId: Int
}

input sensorflow_node_to_slot_mapping_sum_order_by {
  id: order_by
  rootCauseId: order_by
}

enum sensorflow_node_to_slot_mapping_update_column {
  clickup_task_id
  comment
  decomissionedTime
  deleted_at
  id
  isPrimaryNode
  mappedTime
  nodeMacId
  nodeType
  parentPositionId
  rootCauseId
  scannedMacId
  slotName
  updated_at
}

input sensorflow_node_to_slot_mapping_updates {
  _inc: sensorflow_node_to_slot_mapping_inc_input
  _set: sensorflow_node_to_slot_mapping_set_input
  where: sensorflow_node_to_slot_mapping_bool_exp!
}

type sensorflow_node_to_slot_mapping_var_pop_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_var_pop_order_by {
  id: order_by
  rootCauseId: order_by
}

type sensorflow_node_to_slot_mapping_var_samp_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_var_samp_order_by {
  id: order_by
  rootCauseId: order_by
}

type sensorflow_node_to_slot_mapping_variance_fields {
  id: Float
  rootCauseId: Float
}

input sensorflow_node_to_slot_mapping_variance_order_by {
  id: order_by
  rootCauseId: order_by
}

type sensorflow_nodes {
  associated_gateway: String
  climate_sensor: Boolean
  currentFirmwareRelease: sensorflow_firmware_releases
  currentFirmwareVersionNumber: Int
  firmwareVersionLocked: Boolean
  gateway: sensorflow_gateways
  last_seen: timestamptz
  nodeFirmware: sensorflow_node_firmware
  nodeJoinData(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): [sensorflow_node_join_data!]!
  nodeJoinData_aggregate(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): sensorflow_node_join_data_aggregate!
  nodeJoinStatus: sensorflow_node_join_status
  nodeJoinStrengthLive: sensorflow_node_join_strength_live
  nodeMetaData(distinct_on: [sensorflow_node_meta_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_data_order_by!], where: sensorflow_node_meta_data_bool_exp): [sensorflow_node_meta_data!]!
  nodeMetaData_aggregate(distinct_on: [sensorflow_node_meta_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_data_order_by!], where: sensorflow_node_meta_data_bool_exp): sensorflow_node_meta_data_aggregate!
  nodeMetaStatus: sensorflow_node_meta_status
  nodeOnlineStatus: sensorflow_node_online_status
  nodeSubType: String
  node_mac_id: String!
  node_type: String!
  otaComment: String
  otaFailedUpgradeAttempts: Int
  slotMappings(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): [sensorflow_node_to_slot_mapping!]!
  slotMappings_aggregate(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): sensorflow_node_to_slot_mapping_aggregate!
  status_id: Int
  targetFirmwareRelease: sensorflow_firmware_releases
  targetFirmwareReleaseId: Int
}

type sensorflow_nodes_aggregate {
  aggregate: sensorflow_nodes_aggregate_fields
  nodes: [sensorflow_nodes!]!
}

input sensorflow_nodes_aggregate_bool_exp {
  bool_and: sensorflow_nodes_aggregate_bool_exp_bool_and
  bool_or: sensorflow_nodes_aggregate_bool_exp_bool_or
  count: sensorflow_nodes_aggregate_bool_exp_count
}

input sensorflow_nodes_aggregate_bool_exp_bool_and {
  arguments: sensorflow_nodes_select_column_sensorflow_nodes_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: sensorflow_nodes_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_nodes_aggregate_bool_exp_bool_or {
  arguments: sensorflow_nodes_select_column_sensorflow_nodes_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: sensorflow_nodes_bool_exp
  predicate: Boolean_comparison_exp!
}

input sensorflow_nodes_aggregate_bool_exp_count {
  arguments: [sensorflow_nodes_select_column!]
  distinct: Boolean
  filter: sensorflow_nodes_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_nodes_aggregate_fields {
  avg: sensorflow_nodes_avg_fields
  count(columns: [sensorflow_nodes_select_column!], distinct: Boolean): Int!
  max: sensorflow_nodes_max_fields
  min: sensorflow_nodes_min_fields
  stddev: sensorflow_nodes_stddev_fields
  stddev_pop: sensorflow_nodes_stddev_pop_fields
  stddev_samp: sensorflow_nodes_stddev_samp_fields
  sum: sensorflow_nodes_sum_fields
  var_pop: sensorflow_nodes_var_pop_fields
  var_samp: sensorflow_nodes_var_samp_fields
  variance: sensorflow_nodes_variance_fields
}

input sensorflow_nodes_aggregate_order_by {
  avg: sensorflow_nodes_avg_order_by
  count: order_by
  max: sensorflow_nodes_max_order_by
  min: sensorflow_nodes_min_order_by
  stddev: sensorflow_nodes_stddev_order_by
  stddev_pop: sensorflow_nodes_stddev_pop_order_by
  stddev_samp: sensorflow_nodes_stddev_samp_order_by
  sum: sensorflow_nodes_sum_order_by
  var_pop: sensorflow_nodes_var_pop_order_by
  var_samp: sensorflow_nodes_var_samp_order_by
  variance: sensorflow_nodes_variance_order_by
}

input sensorflow_nodes_arr_rel_insert_input {
  data: [sensorflow_nodes_insert_input!]!
  on_conflict: sensorflow_nodes_on_conflict
}

type sensorflow_nodes_avg_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_avg_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

input sensorflow_nodes_bool_exp {
  _and: [sensorflow_nodes_bool_exp!]
  _not: sensorflow_nodes_bool_exp
  _or: [sensorflow_nodes_bool_exp!]
  associated_gateway: String_comparison_exp
  climate_sensor: Boolean_comparison_exp
  currentFirmwareRelease: sensorflow_firmware_releases_bool_exp
  currentFirmwareVersionNumber: Int_comparison_exp
  firmwareVersionLocked: Boolean_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  last_seen: timestamptz_comparison_exp
  nodeFirmware: sensorflow_node_firmware_bool_exp
  nodeJoinData: sensorflow_node_join_data_bool_exp
  nodeJoinData_aggregate: sensorflow_node_join_data_aggregate_bool_exp
  nodeJoinStatus: sensorflow_node_join_status_bool_exp
  nodeJoinStrengthLive: sensorflow_node_join_strength_live_bool_exp
  nodeMetaData: sensorflow_node_meta_data_bool_exp
  nodeMetaData_aggregate: sensorflow_node_meta_data_aggregate_bool_exp
  nodeMetaStatus: sensorflow_node_meta_status_bool_exp
  nodeOnlineStatus: sensorflow_node_online_status_bool_exp
  nodeSubType: String_comparison_exp
  node_mac_id: String_comparison_exp
  node_type: String_comparison_exp
  otaComment: String_comparison_exp
  otaFailedUpgradeAttempts: Int_comparison_exp
  slotMappings: sensorflow_node_to_slot_mapping_bool_exp
  slotMappings_aggregate: sensorflow_node_to_slot_mapping_aggregate_bool_exp
  status_id: Int_comparison_exp
  targetFirmwareRelease: sensorflow_firmware_releases_bool_exp
  targetFirmwareReleaseId: Int_comparison_exp
}

enum sensorflow_nodes_constraint {
  nodes_pkey
}

input sensorflow_nodes_inc_input {
  currentFirmwareVersionNumber: Int
  otaFailedUpgradeAttempts: Int
  status_id: Int
  targetFirmwareReleaseId: Int
}

input sensorflow_nodes_insert_input {
  associated_gateway: String
  climate_sensor: Boolean
  currentFirmwareRelease: sensorflow_firmware_releases_obj_rel_insert_input
  currentFirmwareVersionNumber: Int
  firmwareVersionLocked: Boolean
  gateway: sensorflow_gateways_obj_rel_insert_input
  last_seen: timestamptz
  nodeFirmware: sensorflow_node_firmware_obj_rel_insert_input
  nodeJoinData: sensorflow_node_join_data_arr_rel_insert_input
  nodeJoinStatus: sensorflow_node_join_status_obj_rel_insert_input
  nodeJoinStrengthLive: sensorflow_node_join_strength_live_obj_rel_insert_input
  nodeMetaData: sensorflow_node_meta_data_arr_rel_insert_input
  nodeMetaStatus: sensorflow_node_meta_status_obj_rel_insert_input
  nodeOnlineStatus: sensorflow_node_online_status_obj_rel_insert_input
  nodeSubType: String
  node_mac_id: String
  node_type: String
  otaComment: String
  otaFailedUpgradeAttempts: Int
  slotMappings: sensorflow_node_to_slot_mapping_arr_rel_insert_input
  status_id: Int
  targetFirmwareRelease: sensorflow_firmware_releases_obj_rel_insert_input
  targetFirmwareReleaseId: Int
}

type sensorflow_nodes_max_fields {
  associated_gateway: String
  currentFirmwareVersionNumber: Int
  last_seen: timestamptz
  nodeSubType: String
  node_mac_id: String
  node_type: String
  otaComment: String
  otaFailedUpgradeAttempts: Int
  status_id: Int
  targetFirmwareReleaseId: Int
}

input sensorflow_nodes_max_order_by {
  associated_gateway: order_by
  currentFirmwareVersionNumber: order_by
  last_seen: order_by
  nodeSubType: order_by
  node_mac_id: order_by
  node_type: order_by
  otaComment: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_nodes_min_fields {
  associated_gateway: String
  currentFirmwareVersionNumber: Int
  last_seen: timestamptz
  nodeSubType: String
  node_mac_id: String
  node_type: String
  otaComment: String
  otaFailedUpgradeAttempts: Int
  status_id: Int
  targetFirmwareReleaseId: Int
}

input sensorflow_nodes_min_order_by {
  associated_gateway: order_by
  currentFirmwareVersionNumber: order_by
  last_seen: order_by
  nodeSubType: order_by
  node_mac_id: order_by
  node_type: order_by
  otaComment: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_nodes_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_nodes!]!
}

input sensorflow_nodes_obj_rel_insert_input {
  data: sensorflow_nodes_insert_input!
  on_conflict: sensorflow_nodes_on_conflict
}

input sensorflow_nodes_on_conflict {
  constraint: sensorflow_nodes_constraint!
  update_columns: [sensorflow_nodes_update_column!]! = []
  where: sensorflow_nodes_bool_exp
}

input sensorflow_nodes_order_by {
  associated_gateway: order_by
  climate_sensor: order_by
  currentFirmwareRelease: sensorflow_firmware_releases_order_by
  currentFirmwareVersionNumber: order_by
  firmwareVersionLocked: order_by
  gateway: sensorflow_gateways_order_by
  last_seen: order_by
  nodeFirmware: sensorflow_node_firmware_order_by
  nodeJoinData_aggregate: sensorflow_node_join_data_aggregate_order_by
  nodeJoinStatus: sensorflow_node_join_status_order_by
  nodeJoinStrengthLive: sensorflow_node_join_strength_live_order_by
  nodeMetaData_aggregate: sensorflow_node_meta_data_aggregate_order_by
  nodeMetaStatus: sensorflow_node_meta_status_order_by
  nodeOnlineStatus: sensorflow_node_online_status_order_by
  nodeSubType: order_by
  node_mac_id: order_by
  node_type: order_by
  otaComment: order_by
  otaFailedUpgradeAttempts: order_by
  slotMappings_aggregate: sensorflow_node_to_slot_mapping_aggregate_order_by
  status_id: order_by
  targetFirmwareRelease: sensorflow_firmware_releases_order_by
  targetFirmwareReleaseId: order_by
}

input sensorflow_nodes_pk_columns_input {
  node_mac_id: String!
}

enum sensorflow_nodes_select_column {
  associated_gateway
  climate_sensor
  currentFirmwareVersionNumber
  firmwareVersionLocked
  last_seen
  nodeSubType
  node_mac_id
  node_type
  otaComment
  otaFailedUpgradeAttempts
  status_id
  targetFirmwareReleaseId
}

enum sensorflow_nodes_select_column_sensorflow_nodes_aggregate_bool_exp_bool_and_arguments_columns {
  climate_sensor
  firmwareVersionLocked
}

enum sensorflow_nodes_select_column_sensorflow_nodes_aggregate_bool_exp_bool_or_arguments_columns {
  climate_sensor
  firmwareVersionLocked
}

input sensorflow_nodes_set_input {
  associated_gateway: String
  climate_sensor: Boolean
  currentFirmwareVersionNumber: Int
  firmwareVersionLocked: Boolean
  last_seen: timestamptz
  nodeSubType: String
  node_mac_id: String
  node_type: String
  otaComment: String
  otaFailedUpgradeAttempts: Int
  status_id: Int
  targetFirmwareReleaseId: Int
}

type sensorflow_nodes_stddev_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_stddev_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_nodes_stddev_pop_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_stddev_pop_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_nodes_stddev_samp_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_stddev_samp_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

input sensorflow_nodes_stream_cursor_input {
  initial_value: sensorflow_nodes_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_nodes_stream_cursor_value_input {
  associated_gateway: String
  climate_sensor: Boolean
  currentFirmwareVersionNumber: Int
  firmwareVersionLocked: Boolean
  last_seen: timestamptz
  nodeSubType: String
  node_mac_id: String
  node_type: String
  otaComment: String
  otaFailedUpgradeAttempts: Int
  status_id: Int
  targetFirmwareReleaseId: Int
}

type sensorflow_nodes_sum_fields {
  currentFirmwareVersionNumber: Int
  otaFailedUpgradeAttempts: Int
  status_id: Int
  targetFirmwareReleaseId: Int
}

input sensorflow_nodes_sum_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

enum sensorflow_nodes_update_column {
  associated_gateway
  climate_sensor
  currentFirmwareVersionNumber
  firmwareVersionLocked
  last_seen
  nodeSubType
  node_mac_id
  node_type
  otaComment
  otaFailedUpgradeAttempts
  status_id
  targetFirmwareReleaseId
}

input sensorflow_nodes_updates {
  _inc: sensorflow_nodes_inc_input
  _set: sensorflow_nodes_set_input
  where: sensorflow_nodes_bool_exp!
}

type sensorflow_nodes_var_pop_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_var_pop_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_nodes_var_samp_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_var_samp_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_nodes_variance_fields {
  currentFirmwareVersionNumber: Float
  otaFailedUpgradeAttempts: Float
  status_id: Float
  targetFirmwareReleaseId: Float
}

input sensorflow_nodes_variance_order_by {
  currentFirmwareVersionNumber: order_by
  otaFailedUpgradeAttempts: order_by
  status_id: order_by
  targetFirmwareReleaseId: order_by
}

type sensorflow_organisations {
  id: uuid!
  locations(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): [sensorflow_locations!]!
  locations_aggregate(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): sensorflow_locations_aggregate!
  name: String!
  status: String!
}

type sensorflow_organisations_aggregate {
  aggregate: sensorflow_organisations_aggregate_fields
  nodes: [sensorflow_organisations!]!
}

type sensorflow_organisations_aggregate_fields {
  count(columns: [sensorflow_organisations_select_column!], distinct: Boolean): Int!
  max: sensorflow_organisations_max_fields
  min: sensorflow_organisations_min_fields
}

input sensorflow_organisations_bool_exp {
  _and: [sensorflow_organisations_bool_exp!]
  _not: sensorflow_organisations_bool_exp
  _or: [sensorflow_organisations_bool_exp!]
  id: uuid_comparison_exp
  locations: sensorflow_locations_bool_exp
  locations_aggregate: sensorflow_locations_aggregate_bool_exp
  name: String_comparison_exp
  status: String_comparison_exp
}

enum sensorflow_organisations_constraint {
  organisations_pkey
  unique_name
}

input sensorflow_organisations_insert_input {
  id: uuid
  locations: sensorflow_locations_arr_rel_insert_input
  name: String
  status: String
}

type sensorflow_organisations_max_fields {
  id: uuid
  name: String
  status: String
}

type sensorflow_organisations_min_fields {
  id: uuid
  name: String
  status: String
}

type sensorflow_organisations_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_organisations!]!
}

input sensorflow_organisations_obj_rel_insert_input {
  data: sensorflow_organisations_insert_input!
  on_conflict: sensorflow_organisations_on_conflict
}

input sensorflow_organisations_on_conflict {
  constraint: sensorflow_organisations_constraint!
  update_columns: [sensorflow_organisations_update_column!]! = []
  where: sensorflow_organisations_bool_exp
}

input sensorflow_organisations_order_by {
  id: order_by
  locations_aggregate: sensorflow_locations_aggregate_order_by
  name: order_by
  status: order_by
}

input sensorflow_organisations_pk_columns_input {
  id: uuid!
}

enum sensorflow_organisations_select_column {
  id
  name
  status
}

input sensorflow_organisations_set_input {
  id: uuid
  name: String
  status: String
}

input sensorflow_organisations_stream_cursor_input {
  initial_value: sensorflow_organisations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_organisations_stream_cursor_value_input {
  id: uuid
  name: String
  status: String
}

enum sensorflow_organisations_update_column {
  id
  name
  status
}

input sensorflow_organisations_updates {
  _set: sensorflow_organisations_set_input
  where: sensorflow_organisations_bool_exp!
}

type sensorflow_ota_queues {
  balena_id: String!
  created_time: timestamptz!
  end_time: timestamptz
  gateway: sensorflow_gateways!
  location: sensorflow_locations!
  location_id: uuid!
  ota_queue_id: Int!
  start_time: timestamptz
  status: String!
  target_firmare_release_id: Int!
}

type sensorflow_ota_queues_aggregate {
  aggregate: sensorflow_ota_queues_aggregate_fields
  nodes: [sensorflow_ota_queues!]!
}

type sensorflow_ota_queues_aggregate_fields {
  avg: sensorflow_ota_queues_avg_fields
  count(columns: [sensorflow_ota_queues_select_column!], distinct: Boolean): Int!
  max: sensorflow_ota_queues_max_fields
  min: sensorflow_ota_queues_min_fields
  stddev: sensorflow_ota_queues_stddev_fields
  stddev_pop: sensorflow_ota_queues_stddev_pop_fields
  stddev_samp: sensorflow_ota_queues_stddev_samp_fields
  sum: sensorflow_ota_queues_sum_fields
  var_pop: sensorflow_ota_queues_var_pop_fields
  var_samp: sensorflow_ota_queues_var_samp_fields
  variance: sensorflow_ota_queues_variance_fields
}

type sensorflow_ota_queues_avg_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

input sensorflow_ota_queues_bool_exp {
  _and: [sensorflow_ota_queues_bool_exp!]
  _not: sensorflow_ota_queues_bool_exp
  _or: [sensorflow_ota_queues_bool_exp!]
  balena_id: String_comparison_exp
  created_time: timestamptz_comparison_exp
  end_time: timestamptz_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  location: sensorflow_locations_bool_exp
  location_id: uuid_comparison_exp
  ota_queue_id: Int_comparison_exp
  start_time: timestamptz_comparison_exp
  status: String_comparison_exp
  target_firmare_release_id: Int_comparison_exp
}

enum sensorflow_ota_queues_constraint {
  ota_queues_pkey
}

input sensorflow_ota_queues_inc_input {
  ota_queue_id: Int
  target_firmare_release_id: Int
}

input sensorflow_ota_queues_insert_input {
  balena_id: String
  created_time: timestamptz
  end_time: timestamptz
  gateway: sensorflow_gateways_obj_rel_insert_input
  location: sensorflow_locations_obj_rel_insert_input
  location_id: uuid
  ota_queue_id: Int
  start_time: timestamptz
  status: String
  target_firmare_release_id: Int
}

type sensorflow_ota_queues_max_fields {
  balena_id: String
  created_time: timestamptz
  end_time: timestamptz
  location_id: uuid
  ota_queue_id: Int
  start_time: timestamptz
  status: String
  target_firmare_release_id: Int
}

type sensorflow_ota_queues_min_fields {
  balena_id: String
  created_time: timestamptz
  end_time: timestamptz
  location_id: uuid
  ota_queue_id: Int
  start_time: timestamptz
  status: String
  target_firmare_release_id: Int
}

type sensorflow_ota_queues_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ota_queues!]!
}

input sensorflow_ota_queues_on_conflict {
  constraint: sensorflow_ota_queues_constraint!
  update_columns: [sensorflow_ota_queues_update_column!]! = []
  where: sensorflow_ota_queues_bool_exp
}

input sensorflow_ota_queues_order_by {
  balena_id: order_by
  created_time: order_by
  end_time: order_by
  gateway: sensorflow_gateways_order_by
  location: sensorflow_locations_order_by
  location_id: order_by
  ota_queue_id: order_by
  start_time: order_by
  status: order_by
  target_firmare_release_id: order_by
}

input sensorflow_ota_queues_pk_columns_input {
  ota_queue_id: Int!
}

enum sensorflow_ota_queues_select_column {
  balena_id
  created_time
  end_time
  location_id
  ota_queue_id
  start_time
  status
  target_firmare_release_id
}

input sensorflow_ota_queues_set_input {
  balena_id: String
  created_time: timestamptz
  end_time: timestamptz
  location_id: uuid
  ota_queue_id: Int
  start_time: timestamptz
  status: String
  target_firmare_release_id: Int
}

type sensorflow_ota_queues_stddev_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

type sensorflow_ota_queues_stddev_pop_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

type sensorflow_ota_queues_stddev_samp_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

input sensorflow_ota_queues_stream_cursor_input {
  initial_value: sensorflow_ota_queues_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ota_queues_stream_cursor_value_input {
  balena_id: String
  created_time: timestamptz
  end_time: timestamptz
  location_id: uuid
  ota_queue_id: Int
  start_time: timestamptz
  status: String
  target_firmare_release_id: Int
}

type sensorflow_ota_queues_sum_fields {
  ota_queue_id: Int
  target_firmare_release_id: Int
}

enum sensorflow_ota_queues_update_column {
  balena_id
  created_time
  end_time
  location_id
  ota_queue_id
  start_time
  status
  target_firmare_release_id
}

input sensorflow_ota_queues_updates {
  _inc: sensorflow_ota_queues_inc_input
  _set: sensorflow_ota_queues_set_input
  where: sensorflow_ota_queues_bool_exp!
}

type sensorflow_ota_queues_var_pop_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

type sensorflow_ota_queues_var_samp_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

type sensorflow_ota_queues_variance_fields {
  ota_queue_id: Float
  target_firmare_release_id: Float
}

type sensorflow_ota_runs {
  current_firmware_release_id: Int!
  node_mac_id: String!
  ota_queue_id: Int!
  result: String!
  target_firmware_release_id: Int!
}

type sensorflow_ota_runs_aggregate {
  aggregate: sensorflow_ota_runs_aggregate_fields
  nodes: [sensorflow_ota_runs!]!
}

type sensorflow_ota_runs_aggregate_fields {
  avg: sensorflow_ota_runs_avg_fields
  count(columns: [sensorflow_ota_runs_select_column!], distinct: Boolean): Int!
  max: sensorflow_ota_runs_max_fields
  min: sensorflow_ota_runs_min_fields
  stddev: sensorflow_ota_runs_stddev_fields
  stddev_pop: sensorflow_ota_runs_stddev_pop_fields
  stddev_samp: sensorflow_ota_runs_stddev_samp_fields
  sum: sensorflow_ota_runs_sum_fields
  var_pop: sensorflow_ota_runs_var_pop_fields
  var_samp: sensorflow_ota_runs_var_samp_fields
  variance: sensorflow_ota_runs_variance_fields
}

type sensorflow_ota_runs_avg_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

input sensorflow_ota_runs_bool_exp {
  _and: [sensorflow_ota_runs_bool_exp!]
  _not: sensorflow_ota_runs_bool_exp
  _or: [sensorflow_ota_runs_bool_exp!]
  current_firmware_release_id: Int_comparison_exp
  node_mac_id: String_comparison_exp
  ota_queue_id: Int_comparison_exp
  result: String_comparison_exp
  target_firmware_release_id: Int_comparison_exp
}

enum sensorflow_ota_runs_constraint {
  ota_runs_pkey
}

input sensorflow_ota_runs_inc_input {
  current_firmware_release_id: Int
  ota_queue_id: Int
  target_firmware_release_id: Int
}

input sensorflow_ota_runs_insert_input {
  current_firmware_release_id: Int
  node_mac_id: String
  ota_queue_id: Int
  result: String
  target_firmware_release_id: Int
}

type sensorflow_ota_runs_max_fields {
  current_firmware_release_id: Int
  node_mac_id: String
  ota_queue_id: Int
  result: String
  target_firmware_release_id: Int
}

type sensorflow_ota_runs_min_fields {
  current_firmware_release_id: Int
  node_mac_id: String
  ota_queue_id: Int
  result: String
  target_firmware_release_id: Int
}

type sensorflow_ota_runs_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_ota_runs!]!
}

input sensorflow_ota_runs_on_conflict {
  constraint: sensorflow_ota_runs_constraint!
  update_columns: [sensorflow_ota_runs_update_column!]! = []
  where: sensorflow_ota_runs_bool_exp
}

input sensorflow_ota_runs_order_by {
  current_firmware_release_id: order_by
  node_mac_id: order_by
  ota_queue_id: order_by
  result: order_by
  target_firmware_release_id: order_by
}

input sensorflow_ota_runs_pk_columns_input {
  current_firmware_release_id: Int!
  node_mac_id: String!
  ota_queue_id: Int!
  target_firmware_release_id: Int!
}

enum sensorflow_ota_runs_select_column {
  current_firmware_release_id
  node_mac_id
  ota_queue_id
  result
  target_firmware_release_id
}

input sensorflow_ota_runs_set_input {
  current_firmware_release_id: Int
  node_mac_id: String
  ota_queue_id: Int
  result: String
  target_firmware_release_id: Int
}

type sensorflow_ota_runs_stddev_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

type sensorflow_ota_runs_stddev_pop_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

type sensorflow_ota_runs_stddev_samp_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

input sensorflow_ota_runs_stream_cursor_input {
  initial_value: sensorflow_ota_runs_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_ota_runs_stream_cursor_value_input {
  current_firmware_release_id: Int
  node_mac_id: String
  ota_queue_id: Int
  result: String
  target_firmware_release_id: Int
}

type sensorflow_ota_runs_sum_fields {
  current_firmware_release_id: Int
  ota_queue_id: Int
  target_firmware_release_id: Int
}

enum sensorflow_ota_runs_update_column {
  current_firmware_release_id
  node_mac_id
  ota_queue_id
  result
  target_firmware_release_id
}

input sensorflow_ota_runs_updates {
  _inc: sensorflow_ota_runs_inc_input
  _set: sensorflow_ota_runs_set_input
  where: sensorflow_ota_runs_bool_exp!
}

type sensorflow_ota_runs_var_pop_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

type sensorflow_ota_runs_var_samp_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

type sensorflow_ota_runs_variance_fields {
  current_firmware_release_id: Float
  ota_queue_id: Float
  target_firmware_release_id: Float
}

type sensorflow_pending_ac_settings_commands_for_gateway {
  balenaId: String
  creationDate: timestamptz
  id: uuid
  position: sensorflow_positions
  positionId: uuid
  source: String
  toAcMode: Int
  toAcSetPoint: Int
  toAcStatus: Int
  toFanSpeed: Int
}

type sensorflow_pending_ac_settings_commands_for_gateway_aggregate {
  aggregate: sensorflow_pending_ac_settings_commands_for_gateway_aggregate_fields
  nodes: [sensorflow_pending_ac_settings_commands_for_gateway!]!
}

type sensorflow_pending_ac_settings_commands_for_gateway_aggregate_fields {
  avg: sensorflow_pending_ac_settings_commands_for_gateway_avg_fields
  count(columns: [sensorflow_pending_ac_settings_commands_for_gateway_select_column!], distinct: Boolean): Int!
  max: sensorflow_pending_ac_settings_commands_for_gateway_max_fields
  min: sensorflow_pending_ac_settings_commands_for_gateway_min_fields
  stddev: sensorflow_pending_ac_settings_commands_for_gateway_stddev_fields
  stddev_pop: sensorflow_pending_ac_settings_commands_for_gateway_stddev_pop_fields
  stddev_samp: sensorflow_pending_ac_settings_commands_for_gateway_stddev_samp_fields
  sum: sensorflow_pending_ac_settings_commands_for_gateway_sum_fields
  var_pop: sensorflow_pending_ac_settings_commands_for_gateway_var_pop_fields
  var_samp: sensorflow_pending_ac_settings_commands_for_gateway_var_samp_fields
  variance: sensorflow_pending_ac_settings_commands_for_gateway_variance_fields
}

type sensorflow_pending_ac_settings_commands_for_gateway_avg_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

input sensorflow_pending_ac_settings_commands_for_gateway_bool_exp {
  _and: [sensorflow_pending_ac_settings_commands_for_gateway_bool_exp!]
  _not: sensorflow_pending_ac_settings_commands_for_gateway_bool_exp
  _or: [sensorflow_pending_ac_settings_commands_for_gateway_bool_exp!]
  balenaId: String_comparison_exp
  creationDate: timestamptz_comparison_exp
  id: uuid_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  source: String_comparison_exp
  toAcMode: Int_comparison_exp
  toAcSetPoint: Int_comparison_exp
  toAcStatus: Int_comparison_exp
  toFanSpeed: Int_comparison_exp
}

type sensorflow_pending_ac_settings_commands_for_gateway_max_fields {
  balenaId: String
  creationDate: timestamptz
  id: uuid
  positionId: uuid
  source: String
  toAcMode: Int
  toAcSetPoint: Int
  toAcStatus: Int
  toFanSpeed: Int
}

type sensorflow_pending_ac_settings_commands_for_gateway_min_fields {
  balenaId: String
  creationDate: timestamptz
  id: uuid
  positionId: uuid
  source: String
  toAcMode: Int
  toAcSetPoint: Int
  toAcStatus: Int
  toFanSpeed: Int
}

input sensorflow_pending_ac_settings_commands_for_gateway_order_by {
  balenaId: order_by
  creationDate: order_by
  id: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  source: order_by
  toAcMode: order_by
  toAcSetPoint: order_by
  toAcStatus: order_by
  toFanSpeed: order_by
}

enum sensorflow_pending_ac_settings_commands_for_gateway_select_column {
  balenaId
  creationDate
  id
  positionId
  source
  toAcMode
  toAcSetPoint
  toAcStatus
  toFanSpeed
}

type sensorflow_pending_ac_settings_commands_for_gateway_stddev_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

type sensorflow_pending_ac_settings_commands_for_gateway_stddev_pop_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

type sensorflow_pending_ac_settings_commands_for_gateway_stddev_samp_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

input sensorflow_pending_ac_settings_commands_for_gateway_stream_cursor_input {
  initial_value: sensorflow_pending_ac_settings_commands_for_gateway_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_pending_ac_settings_commands_for_gateway_stream_cursor_value_input {
  balenaId: String
  creationDate: timestamptz
  id: uuid
  positionId: uuid
  source: String
  toAcMode: Int
  toAcSetPoint: Int
  toAcStatus: Int
  toFanSpeed: Int
}

type sensorflow_pending_ac_settings_commands_for_gateway_sum_fields {
  toAcMode: Int
  toAcSetPoint: Int
  toAcStatus: Int
  toFanSpeed: Int
}

type sensorflow_pending_ac_settings_commands_for_gateway_var_pop_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

type sensorflow_pending_ac_settings_commands_for_gateway_var_samp_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

type sensorflow_pending_ac_settings_commands_for_gateway_variance_fields {
  toAcMode: Float
  toAcSetPoint: Float
  toAcStatus: Float
  toFanSpeed: Float
}

type sensorflow_pipes {
  deleted_at: timestamptz
  id: uuid!
  meterPosition: sensorflow_positions
  meterPositionId: uuid
  name: String
  position: sensorflow_positions!
  positionId: uuid!
}

type sensorflow_pipes_aggregate {
  aggregate: sensorflow_pipes_aggregate_fields
  nodes: [sensorflow_pipes!]!
}

type sensorflow_pipes_aggregate_fields {
  count(columns: [sensorflow_pipes_select_column!], distinct: Boolean): Int!
  max: sensorflow_pipes_max_fields
  min: sensorflow_pipes_min_fields
}

input sensorflow_pipes_bool_exp {
  _and: [sensorflow_pipes_bool_exp!]
  _not: sensorflow_pipes_bool_exp
  _or: [sensorflow_pipes_bool_exp!]
  deleted_at: timestamptz_comparison_exp
  id: uuid_comparison_exp
  meterPosition: sensorflow_positions_bool_exp
  meterPositionId: uuid_comparison_exp
  name: String_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

enum sensorflow_pipes_constraint {
  pipes_pkey
}

input sensorflow_pipes_insert_input {
  deleted_at: timestamptz
  id: uuid
  meterPosition: sensorflow_positions_obj_rel_insert_input
  meterPositionId: uuid
  name: String
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_pipes_max_fields {
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  positionId: uuid
}

type sensorflow_pipes_min_fields {
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  positionId: uuid
}

type sensorflow_pipes_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_pipes!]!
}

input sensorflow_pipes_on_conflict {
  constraint: sensorflow_pipes_constraint!
  update_columns: [sensorflow_pipes_update_column!]! = []
  where: sensorflow_pipes_bool_exp
}

input sensorflow_pipes_order_by {
  deleted_at: order_by
  id: order_by
  meterPosition: sensorflow_positions_order_by
  meterPositionId: order_by
  name: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

input sensorflow_pipes_pk_columns_input {
  id: uuid!
}

enum sensorflow_pipes_select_column {
  deleted_at
  id
  meterPositionId
  name
  positionId
}

input sensorflow_pipes_set_input {
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  positionId: uuid
}

input sensorflow_pipes_stream_cursor_input {
  initial_value: sensorflow_pipes_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_pipes_stream_cursor_value_input {
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  positionId: uuid
}

enum sensorflow_pipes_update_column {
  deleted_at
  id
  meterPositionId
  name
  positionId
}

input sensorflow_pipes_updates {
  _set: sensorflow_pipes_set_input
  where: sensorflow_pipes_bool_exp!
}

type sensorflow_position_precool_status {
  createdBy: String
  creationDate: timestamptz
  locationId: uuid
  mode: Int
  parentPositionId: uuid
  positionId: uuid
  precoolCommandId: uuid
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
}

type sensorflow_position_precool_status_aggregate {
  aggregate: sensorflow_position_precool_status_aggregate_fields
  nodes: [sensorflow_position_precool_status!]!
}

type sensorflow_position_precool_status_aggregate_fields {
  avg: sensorflow_position_precool_status_avg_fields
  count(columns: [sensorflow_position_precool_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_position_precool_status_max_fields
  min: sensorflow_position_precool_status_min_fields
  stddev: sensorflow_position_precool_status_stddev_fields
  stddev_pop: sensorflow_position_precool_status_stddev_pop_fields
  stddev_samp: sensorflow_position_precool_status_stddev_samp_fields
  sum: sensorflow_position_precool_status_sum_fields
  var_pop: sensorflow_position_precool_status_var_pop_fields
  var_samp: sensorflow_position_precool_status_var_samp_fields
  variance: sensorflow_position_precool_status_variance_fields
}

type sensorflow_position_precool_status_avg_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_position_precool_status_bool_exp {
  _and: [sensorflow_position_precool_status_bool_exp!]
  _not: sensorflow_position_precool_status_bool_exp
  _or: [sensorflow_position_precool_status_bool_exp!]
  createdBy: String_comparison_exp
  creationDate: timestamptz_comparison_exp
  locationId: uuid_comparison_exp
  mode: Int_comparison_exp
  parentPositionId: uuid_comparison_exp
  positionId: uuid_comparison_exp
  precoolCommandId: uuid_comparison_exp
  startedAt: timestamptz_comparison_exp
  status: String_comparison_exp
  temperature: Int_comparison_exp
  totalMinutes: Int_comparison_exp
}

type sensorflow_position_precool_status_max_fields {
  createdBy: String
  creationDate: timestamptz
  locationId: uuid
  mode: Int
  parentPositionId: uuid
  positionId: uuid
  precoolCommandId: uuid
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
}

type sensorflow_position_precool_status_min_fields {
  createdBy: String
  creationDate: timestamptz
  locationId: uuid
  mode: Int
  parentPositionId: uuid
  positionId: uuid
  precoolCommandId: uuid
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
}

input sensorflow_position_precool_status_order_by {
  createdBy: order_by
  creationDate: order_by
  locationId: order_by
  mode: order_by
  parentPositionId: order_by
  positionId: order_by
  precoolCommandId: order_by
  startedAt: order_by
  status: order_by
  temperature: order_by
  totalMinutes: order_by
}

enum sensorflow_position_precool_status_select_column {
  createdBy
  creationDate
  locationId
  mode
  parentPositionId
  positionId
  precoolCommandId
  startedAt
  status
  temperature
  totalMinutes
}

type sensorflow_position_precool_status_stddev_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

type sensorflow_position_precool_status_stddev_pop_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

type sensorflow_position_precool_status_stddev_samp_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_position_precool_status_stream_cursor_input {
  initial_value: sensorflow_position_precool_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_position_precool_status_stream_cursor_value_input {
  createdBy: String
  creationDate: timestamptz
  locationId: uuid
  mode: Int
  parentPositionId: uuid
  positionId: uuid
  precoolCommandId: uuid
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
}

type sensorflow_position_precool_status_sum_fields {
  mode: Int
  temperature: Int
  totalMinutes: Int
}

type sensorflow_position_precool_status_var_pop_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

type sensorflow_position_precool_status_var_samp_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

type sensorflow_position_precool_status_variance_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

type sensorflow_position_signal_stats {
  balena_id: String!
  last_updated: timestamptz!
  lowest_signal: numeric
  position_id: uuid!
}

type sensorflow_position_signal_stats_aggregate {
  aggregate: sensorflow_position_signal_stats_aggregate_fields
  nodes: [sensorflow_position_signal_stats!]!
}

type sensorflow_position_signal_stats_aggregate_fields {
  avg: sensorflow_position_signal_stats_avg_fields
  count(columns: [sensorflow_position_signal_stats_select_column!], distinct: Boolean): Int!
  max: sensorflow_position_signal_stats_max_fields
  min: sensorflow_position_signal_stats_min_fields
  stddev: sensorflow_position_signal_stats_stddev_fields
  stddev_pop: sensorflow_position_signal_stats_stddev_pop_fields
  stddev_samp: sensorflow_position_signal_stats_stddev_samp_fields
  sum: sensorflow_position_signal_stats_sum_fields
  var_pop: sensorflow_position_signal_stats_var_pop_fields
  var_samp: sensorflow_position_signal_stats_var_samp_fields
  variance: sensorflow_position_signal_stats_variance_fields
}

type sensorflow_position_signal_stats_avg_fields {
  lowest_signal: Float
}

input sensorflow_position_signal_stats_bool_exp {
  _and: [sensorflow_position_signal_stats_bool_exp!]
  _not: sensorflow_position_signal_stats_bool_exp
  _or: [sensorflow_position_signal_stats_bool_exp!]
  balena_id: String_comparison_exp
  last_updated: timestamptz_comparison_exp
  lowest_signal: numeric_comparison_exp
  position_id: uuid_comparison_exp
}

enum sensorflow_position_signal_stats_constraint {
  position_signal_stats_pkey
}

input sensorflow_position_signal_stats_inc_input {
  lowest_signal: numeric
}

input sensorflow_position_signal_stats_insert_input {
  balena_id: String
  last_updated: timestamptz
  lowest_signal: numeric
  position_id: uuid
}

type sensorflow_position_signal_stats_max_fields {
  balena_id: String
  last_updated: timestamptz
  lowest_signal: numeric
  position_id: uuid
}

type sensorflow_position_signal_stats_min_fields {
  balena_id: String
  last_updated: timestamptz
  lowest_signal: numeric
  position_id: uuid
}

type sensorflow_position_signal_stats_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_position_signal_stats!]!
}

input sensorflow_position_signal_stats_on_conflict {
  constraint: sensorflow_position_signal_stats_constraint!
  update_columns: [sensorflow_position_signal_stats_update_column!]! = []
  where: sensorflow_position_signal_stats_bool_exp
}

input sensorflow_position_signal_stats_order_by {
  balena_id: order_by
  last_updated: order_by
  lowest_signal: order_by
  position_id: order_by
}

input sensorflow_position_signal_stats_pk_columns_input {
  balena_id: String!
  position_id: uuid!
}

enum sensorflow_position_signal_stats_select_column {
  balena_id
  last_updated
  lowest_signal
  position_id
}

input sensorflow_position_signal_stats_set_input {
  balena_id: String
  last_updated: timestamptz
  lowest_signal: numeric
  position_id: uuid
}

type sensorflow_position_signal_stats_stddev_fields {
  lowest_signal: Float
}

type sensorflow_position_signal_stats_stddev_pop_fields {
  lowest_signal: Float
}

type sensorflow_position_signal_stats_stddev_samp_fields {
  lowest_signal: Float
}

input sensorflow_position_signal_stats_stream_cursor_input {
  initial_value: sensorflow_position_signal_stats_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_position_signal_stats_stream_cursor_value_input {
  balena_id: String
  last_updated: timestamptz
  lowest_signal: numeric
  position_id: uuid
}

type sensorflow_position_signal_stats_sum_fields {
  lowest_signal: numeric
}

enum sensorflow_position_signal_stats_update_column {
  balena_id
  last_updated
  lowest_signal
  position_id
}

input sensorflow_position_signal_stats_updates {
  _inc: sensorflow_position_signal_stats_inc_input
  _set: sensorflow_position_signal_stats_set_input
  where: sensorflow_position_signal_stats_bool_exp!
}

type sensorflow_position_signal_stats_var_pop_fields {
  lowest_signal: Float
}

type sensorflow_position_signal_stats_var_samp_fields {
  lowest_signal: Float
}

type sensorflow_position_signal_stats_variance_fields {
  lowest_signal: Float
}

type sensorflow_positions {
  acSettingCommands(distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): [sensorflow_ac_setting_commands!]!
  acSettingCommands_aggregate(distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): sensorflow_ac_setting_commands_aggregate!
  clickup_task_id: String
  compressor: sensorflow_compressors
  deletedAt: timestamptz
  eventsStream(distinct_on: [sensorflow_events_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_events_stream_order_by!], where: sensorflow_events_stream_bool_exp): [sensorflow_events_stream!]!
  eventsStream_aggregate(distinct_on: [sensorflow_events_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_events_stream_order_by!], where: sensorflow_events_stream_bool_exp): sensorflow_events_stream_aggregate!
  gatewayMapping(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): [sensorflow_gateway_to_position_mapping!]!
  gatewayMapping_aggregate(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): sensorflow_gateway_to_position_mapping_aggregate!
  gateways(distinct_on: [sensorflow_gateways_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateways_order_by!], where: sensorflow_gateways_bool_exp): [sensorflow_gateways!]!
  gateways_aggregate(distinct_on: [sensorflow_gateways_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateways_order_by!], where: sensorflow_gateways_bool_exp): sensorflow_gateways_aggregate!
  infrastructure: sensorflow_v_infrastructures
  keyCategoryPositionMapping: sensorflow_key_category_to_key_position
  location: sensorflow_locations
  locationId: uuid!
  mappingCoordinates(distinct_on: [sensorflow_mapping_coordinates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_mapping_coordinates_order_by!], where: sensorflow_mapping_coordinates_bool_exp): [sensorflow_mapping_coordinates!]!
  mappingCoordinates_aggregate(distinct_on: [sensorflow_mapping_coordinates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_mapping_coordinates_order_by!], where: sensorflow_mapping_coordinates_bool_exp): sensorflow_mapping_coordinates_aggregate!
  nodeJoinStatuses(distinct_on: [sensorflow_node_join_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_status_order_by!], where: sensorflow_node_join_status_bool_exp): [sensorflow_node_join_status!]!
  nodeJoinStatuses_aggregate(distinct_on: [sensorflow_node_join_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_status_order_by!], where: sensorflow_node_join_status_bool_exp): sensorflow_node_join_status_aggregate!
  nodeMeasurements(distinct_on: [sensorflow_node_measurement_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurement_view_order_by!], where: sensorflow_node_measurement_view_bool_exp): [sensorflow_node_measurement_view!]!
  nodeMeasurements_aggregate(distinct_on: [sensorflow_node_measurement_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurement_view_order_by!], where: sensorflow_node_measurement_view_bool_exp): sensorflow_node_measurement_view_aggregate!
  nodeSlots(distinct_on: [sensorflow_node_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_slots_order_by!], where: sensorflow_node_slots_bool_exp): [sensorflow_node_slots!]!
  nodeSlots_aggregate(distinct_on: [sensorflow_node_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_slots_order_by!], where: sensorflow_node_slots_bool_exp): sensorflow_node_slots_aggregate!
  node_measurements(distinct_on: [sensorflow_node_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurements_order_by!], where: sensorflow_node_measurements_bool_exp): [sensorflow_node_measurements!]!
  node_measurements_aggregate(distinct_on: [sensorflow_node_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurements_order_by!], where: sensorflow_node_measurements_bool_exp): sensorflow_node_measurements_aggregate!
  parentPosition: sensorflow_positions
  parentPositionId: uuid
  positionConfiguration(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): [positionConfigurations!]!
  positionConfiguration_aggregate(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): positionConfigurations_aggregate!
  positionConfigurationsForActivityLog(distinct_on: [positionConfigurationsForActivityLog_select_column!], limit: Int, offset: Int, order_by: [positionConfigurationsForActivityLog_order_by!], where: positionConfigurationsForActivityLog_bool_exp): [positionConfigurationsForActivityLog!]!
  positionConfigurationsForActivityLog_aggregate(distinct_on: [positionConfigurationsForActivityLog_select_column!], limit: Int, offset: Int, order_by: [positionConfigurationsForActivityLog_order_by!], where: positionConfigurationsForActivityLog_bool_exp): positionConfigurationsForActivityLog_aggregate!
  positionFunction: String!
  positionId: uuid!
  positionName: String!
  positionType: String!
  precoolCommands(distinct_on: [sensorflow_precool_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_commands_order_by!], where: sensorflow_precool_commands_bool_exp): [sensorflow_precool_commands!]!
  precoolCommands_aggregate(distinct_on: [sensorflow_precool_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_commands_order_by!], where: sensorflow_precool_commands_bool_exp): sensorflow_precool_commands_aggregate!
  reservation(distinct_on: [sensorflow_reservations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservations_order_by!], where: sensorflow_reservations_bool_exp): [sensorflow_reservations!]!
  reservation_aggregate(distinct_on: [sensorflow_reservations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservations_order_by!], where: sensorflow_reservations_bool_exp): sensorflow_reservations_aggregate!
  rooms(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): [sensorflow_positions!]!
  rooms_aggregate(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): sensorflow_positions_aggregate!
  searchRooms(args: searchRooms_sensorflow_positions_args!, distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): [sensorflow_positions!]
  slotMappings(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): [sensorflow_node_to_slot_mapping!]!
  slotMappings_aggregate(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): sensorflow_node_to_slot_mapping_aggregate!
}

type sensorflow_positions_aggregate {
  aggregate: sensorflow_positions_aggregate_fields
  nodes: [sensorflow_positions!]!
}

input sensorflow_positions_aggregate_bool_exp {
  count: sensorflow_positions_aggregate_bool_exp_count
}

input sensorflow_positions_aggregate_bool_exp_count {
  arguments: [sensorflow_positions_select_column!]
  distinct: Boolean
  filter: sensorflow_positions_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_positions_aggregate_fields {
  count(columns: [sensorflow_positions_select_column!], distinct: Boolean): Int!
  max: sensorflow_positions_max_fields
  min: sensorflow_positions_min_fields
}

input sensorflow_positions_aggregate_order_by {
  count: order_by
  max: sensorflow_positions_max_order_by
  min: sensorflow_positions_min_order_by
}

input sensorflow_positions_arr_rel_insert_input {
  data: [sensorflow_positions_insert_input!]!
  on_conflict: sensorflow_positions_on_conflict
}

input sensorflow_positions_bool_exp {
  _and: [sensorflow_positions_bool_exp!]
  _not: sensorflow_positions_bool_exp
  _or: [sensorflow_positions_bool_exp!]
  acSettingCommands: sensorflow_ac_setting_commands_bool_exp
  acSettingCommands_aggregate: sensorflow_ac_setting_commands_aggregate_bool_exp
  clickup_task_id: String_comparison_exp
  compressor: sensorflow_compressors_bool_exp
  deletedAt: timestamptz_comparison_exp
  eventsStream: sensorflow_events_stream_bool_exp
  eventsStream_aggregate: sensorflow_events_stream_aggregate_bool_exp
  gatewayMapping: sensorflow_gateway_to_position_mapping_bool_exp
  gatewayMapping_aggregate: sensorflow_gateway_to_position_mapping_aggregate_bool_exp
  gateways: sensorflow_gateways_bool_exp
  gateways_aggregate: sensorflow_gateways_aggregate_bool_exp
  infrastructure: sensorflow_v_infrastructures_bool_exp
  keyCategoryPositionMapping: sensorflow_key_category_to_key_position_bool_exp
  location: sensorflow_locations_bool_exp
  locationId: uuid_comparison_exp
  mappingCoordinates: sensorflow_mapping_coordinates_bool_exp
  mappingCoordinates_aggregate: sensorflow_mapping_coordinates_aggregate_bool_exp
  nodeJoinStatuses: sensorflow_node_join_status_bool_exp
  nodeJoinStatuses_aggregate: sensorflow_node_join_status_aggregate_bool_exp
  nodeMeasurements: sensorflow_node_measurement_view_bool_exp
  nodeMeasurements_aggregate: sensorflow_node_measurement_view_aggregate_bool_exp
  nodeSlots: sensorflow_node_slots_bool_exp
  nodeSlots_aggregate: sensorflow_node_slots_aggregate_bool_exp
  node_measurements: sensorflow_node_measurements_bool_exp
  node_measurements_aggregate: sensorflow_node_measurements_aggregate_bool_exp
  parentPosition: sensorflow_positions_bool_exp
  parentPositionId: uuid_comparison_exp
  positionConfiguration: positionConfigurations_bool_exp
  positionConfiguration_aggregate: positionConfigurations_aggregate_bool_exp
  positionConfigurationsForActivityLog: positionConfigurationsForActivityLog_bool_exp
  positionConfigurationsForActivityLog_aggregate: positionConfigurationsForActivityLog_aggregate_bool_exp
  positionFunction: String_comparison_exp
  positionId: uuid_comparison_exp
  positionName: String_comparison_exp
  positionType: String_comparison_exp
  precoolCommands: sensorflow_precool_commands_bool_exp
  precoolCommands_aggregate: sensorflow_precool_commands_aggregate_bool_exp
  reservation: sensorflow_reservations_bool_exp
  reservation_aggregate: sensorflow_reservations_aggregate_bool_exp
  rooms: sensorflow_positions_bool_exp
  rooms_aggregate: sensorflow_positions_aggregate_bool_exp
  slotMappings: sensorflow_node_to_slot_mapping_bool_exp
  slotMappings_aggregate: sensorflow_node_to_slot_mapping_aggregate_bool_exp
}

enum sensorflow_positions_constraint {
  positions_pkey
  positions_unique_index
}

input sensorflow_positions_insert_input {
  acSettingCommands: sensorflow_ac_setting_commands_arr_rel_insert_input
  clickup_task_id: String
  compressor: sensorflow_compressors_obj_rel_insert_input
  deletedAt: timestamptz
  eventsStream: sensorflow_events_stream_arr_rel_insert_input
  gatewayMapping: sensorflow_gateway_to_position_mapping_arr_rel_insert_input
  gateways: sensorflow_gateways_arr_rel_insert_input
  infrastructure: sensorflow_v_infrastructures_obj_rel_insert_input
  keyCategoryPositionMapping: sensorflow_key_category_to_key_position_obj_rel_insert_input
  location: sensorflow_locations_obj_rel_insert_input
  locationId: uuid
  mappingCoordinates: sensorflow_mapping_coordinates_arr_rel_insert_input
  nodeJoinStatuses: sensorflow_node_join_status_arr_rel_insert_input
  nodeMeasurements: sensorflow_node_measurement_view_arr_rel_insert_input
  nodeSlots: sensorflow_node_slots_arr_rel_insert_input
  node_measurements: sensorflow_node_measurements_arr_rel_insert_input
  parentPosition: sensorflow_positions_obj_rel_insert_input
  parentPositionId: uuid
  positionConfiguration: positionConfigurations_arr_rel_insert_input
  positionConfigurationsForActivityLog: positionConfigurationsForActivityLog_arr_rel_insert_input
  positionFunction: String
  positionId: uuid
  positionName: String
  positionType: String
  precoolCommands: sensorflow_precool_commands_arr_rel_insert_input
  reservation: sensorflow_reservations_arr_rel_insert_input
  rooms: sensorflow_positions_arr_rel_insert_input
  slotMappings: sensorflow_node_to_slot_mapping_arr_rel_insert_input
}

type sensorflow_positions_max_fields {
  clickup_task_id: String
  deletedAt: timestamptz
  locationId: uuid
  parentPositionId: uuid
  positionFunction: String
  positionId: uuid
  positionName: String
  positionType: String
}

input sensorflow_positions_max_order_by {
  clickup_task_id: order_by
  deletedAt: order_by
  locationId: order_by
  parentPositionId: order_by
  positionFunction: order_by
  positionId: order_by
  positionName: order_by
  positionType: order_by
}

type sensorflow_positions_min_fields {
  clickup_task_id: String
  deletedAt: timestamptz
  locationId: uuid
  parentPositionId: uuid
  positionFunction: String
  positionId: uuid
  positionName: String
  positionType: String
}

input sensorflow_positions_min_order_by {
  clickup_task_id: order_by
  deletedAt: order_by
  locationId: order_by
  parentPositionId: order_by
  positionFunction: order_by
  positionId: order_by
  positionName: order_by
  positionType: order_by
}

type sensorflow_positions_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_positions!]!
}

input sensorflow_positions_obj_rel_insert_input {
  data: sensorflow_positions_insert_input!
  on_conflict: sensorflow_positions_on_conflict
}

input sensorflow_positions_on_conflict {
  constraint: sensorflow_positions_constraint!
  update_columns: [sensorflow_positions_update_column!]! = []
  where: sensorflow_positions_bool_exp
}

input sensorflow_positions_order_by {
  acSettingCommands_aggregate: sensorflow_ac_setting_commands_aggregate_order_by
  clickup_task_id: order_by
  compressor: sensorflow_compressors_order_by
  deletedAt: order_by
  eventsStream_aggregate: sensorflow_events_stream_aggregate_order_by
  gatewayMapping_aggregate: sensorflow_gateway_to_position_mapping_aggregate_order_by
  gateways_aggregate: sensorflow_gateways_aggregate_order_by
  infrastructure: sensorflow_v_infrastructures_order_by
  keyCategoryPositionMapping: sensorflow_key_category_to_key_position_order_by
  location: sensorflow_locations_order_by
  locationId: order_by
  mappingCoordinates_aggregate: sensorflow_mapping_coordinates_aggregate_order_by
  nodeJoinStatuses_aggregate: sensorflow_node_join_status_aggregate_order_by
  nodeMeasurements_aggregate: sensorflow_node_measurement_view_aggregate_order_by
  nodeSlots_aggregate: sensorflow_node_slots_aggregate_order_by
  node_measurements_aggregate: sensorflow_node_measurements_aggregate_order_by
  parentPosition: sensorflow_positions_order_by
  parentPositionId: order_by
  positionConfiguration_aggregate: positionConfigurations_aggregate_order_by
  positionConfigurationsForActivityLog_aggregate: positionConfigurationsForActivityLog_aggregate_order_by
  positionFunction: order_by
  positionId: order_by
  positionName: order_by
  positionType: order_by
  precoolCommands_aggregate: sensorflow_precool_commands_aggregate_order_by
  reservation_aggregate: sensorflow_reservations_aggregate_order_by
  rooms_aggregate: sensorflow_positions_aggregate_order_by
  slotMappings_aggregate: sensorflow_node_to_slot_mapping_aggregate_order_by
}

input sensorflow_positions_pk_columns_input {
  positionId: uuid!
}

enum sensorflow_positions_select_column {
  clickup_task_id
  deletedAt
  locationId
  parentPositionId
  positionFunction
  positionId
  positionName
  positionType
}

input sensorflow_positions_set_input {
  clickup_task_id: String
  deletedAt: timestamptz
  locationId: uuid
  parentPositionId: uuid
  positionFunction: String
  positionId: uuid
  positionName: String
  positionType: String
}

input sensorflow_positions_stream_cursor_input {
  initial_value: sensorflow_positions_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_positions_stream_cursor_value_input {
  clickup_task_id: String
  deletedAt: timestamptz
  locationId: uuid
  parentPositionId: uuid
  positionFunction: String
  positionId: uuid
  positionName: String
  positionType: String
}

enum sensorflow_positions_update_column {
  clickup_task_id
  deletedAt
  locationId
  parentPositionId
  positionFunction
  positionId
  positionName
  positionType
}

input sensorflow_positions_updates {
  _set: sensorflow_positions_set_input
  where: sensorflow_positions_bool_exp!
}

type sensorflow_precool_commands {
  createdBy: String
  creationDate: timestamptz!
  failMessage: String
  id: uuid!
  mode: Int
  position: sensorflow_positions!
  positionId: uuid!
  schedule_id: String
  source: String!
  startedAt: timestamptz
  status: String!
  temperature: Int
  totalMinutes: Int!
  updatedAt: timestamptz
  user: sensorflow_users
}

type sensorflow_precool_commands_aggregate {
  aggregate: sensorflow_precool_commands_aggregate_fields
  nodes: [sensorflow_precool_commands!]!
}

input sensorflow_precool_commands_aggregate_bool_exp {
  count: sensorflow_precool_commands_aggregate_bool_exp_count
}

input sensorflow_precool_commands_aggregate_bool_exp_count {
  arguments: [sensorflow_precool_commands_select_column!]
  distinct: Boolean
  filter: sensorflow_precool_commands_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_precool_commands_aggregate_fields {
  avg: sensorflow_precool_commands_avg_fields
  count(columns: [sensorflow_precool_commands_select_column!], distinct: Boolean): Int!
  max: sensorflow_precool_commands_max_fields
  min: sensorflow_precool_commands_min_fields
  stddev: sensorflow_precool_commands_stddev_fields
  stddev_pop: sensorflow_precool_commands_stddev_pop_fields
  stddev_samp: sensorflow_precool_commands_stddev_samp_fields
  sum: sensorflow_precool_commands_sum_fields
  var_pop: sensorflow_precool_commands_var_pop_fields
  var_samp: sensorflow_precool_commands_var_samp_fields
  variance: sensorflow_precool_commands_variance_fields
}

input sensorflow_precool_commands_aggregate_order_by {
  avg: sensorflow_precool_commands_avg_order_by
  count: order_by
  max: sensorflow_precool_commands_max_order_by
  min: sensorflow_precool_commands_min_order_by
  stddev: sensorflow_precool_commands_stddev_order_by
  stddev_pop: sensorflow_precool_commands_stddev_pop_order_by
  stddev_samp: sensorflow_precool_commands_stddev_samp_order_by
  sum: sensorflow_precool_commands_sum_order_by
  var_pop: sensorflow_precool_commands_var_pop_order_by
  var_samp: sensorflow_precool_commands_var_samp_order_by
  variance: sensorflow_precool_commands_variance_order_by
}

input sensorflow_precool_commands_arr_rel_insert_input {
  data: [sensorflow_precool_commands_insert_input!]!
  on_conflict: sensorflow_precool_commands_on_conflict
}

type sensorflow_precool_commands_avg_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_avg_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

input sensorflow_precool_commands_bool_exp {
  _and: [sensorflow_precool_commands_bool_exp!]
  _not: sensorflow_precool_commands_bool_exp
  _or: [sensorflow_precool_commands_bool_exp!]
  createdBy: String_comparison_exp
  creationDate: timestamptz_comparison_exp
  failMessage: String_comparison_exp
  id: uuid_comparison_exp
  mode: Int_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  schedule_id: String_comparison_exp
  source: String_comparison_exp
  startedAt: timestamptz_comparison_exp
  status: String_comparison_exp
  temperature: Int_comparison_exp
  totalMinutes: Int_comparison_exp
  updatedAt: timestamptz_comparison_exp
  user: sensorflow_users_bool_exp
}

enum sensorflow_precool_commands_constraint {
  precool_commands_pkey
}

input sensorflow_precool_commands_inc_input {
  mode: Int
  temperature: Int
  totalMinutes: Int
}

input sensorflow_precool_commands_insert_input {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  id: uuid
  mode: Int
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  schedule_id: String
  source: String
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
  updatedAt: timestamptz
  user: sensorflow_users_obj_rel_insert_input
}

type sensorflow_precool_commands_max_fields {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  id: uuid
  mode: Int
  positionId: uuid
  schedule_id: String
  source: String
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
  updatedAt: timestamptz
}

input sensorflow_precool_commands_max_order_by {
  createdBy: order_by
  creationDate: order_by
  failMessage: order_by
  id: order_by
  mode: order_by
  positionId: order_by
  schedule_id: order_by
  source: order_by
  startedAt: order_by
  status: order_by
  temperature: order_by
  totalMinutes: order_by
  updatedAt: order_by
}

type sensorflow_precool_commands_min_fields {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  id: uuid
  mode: Int
  positionId: uuid
  schedule_id: String
  source: String
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
  updatedAt: timestamptz
}

input sensorflow_precool_commands_min_order_by {
  createdBy: order_by
  creationDate: order_by
  failMessage: order_by
  id: order_by
  mode: order_by
  positionId: order_by
  schedule_id: order_by
  source: order_by
  startedAt: order_by
  status: order_by
  temperature: order_by
  totalMinutes: order_by
  updatedAt: order_by
}

type sensorflow_precool_commands_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_precool_commands!]!
}

input sensorflow_precool_commands_on_conflict {
  constraint: sensorflow_precool_commands_constraint!
  update_columns: [sensorflow_precool_commands_update_column!]! = []
  where: sensorflow_precool_commands_bool_exp
}

input sensorflow_precool_commands_order_by {
  createdBy: order_by
  creationDate: order_by
  failMessage: order_by
  id: order_by
  mode: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  schedule_id: order_by
  source: order_by
  startedAt: order_by
  status: order_by
  temperature: order_by
  totalMinutes: order_by
  updatedAt: order_by
  user: sensorflow_users_order_by
}

input sensorflow_precool_commands_pk_columns_input {
  id: uuid!
}

enum sensorflow_precool_commands_select_column {
  createdBy
  creationDate
  failMessage
  id
  mode
  positionId
  schedule_id
  source
  startedAt
  status
  temperature
  totalMinutes
  updatedAt
}

input sensorflow_precool_commands_set_input {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  id: uuid
  mode: Int
  positionId: uuid
  schedule_id: String
  source: String
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
  updatedAt: timestamptz
}

type sensorflow_precool_commands_stddev_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_stddev_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

type sensorflow_precool_commands_stddev_pop_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_stddev_pop_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

type sensorflow_precool_commands_stddev_samp_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_stddev_samp_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

input sensorflow_precool_commands_stream_cursor_input {
  initial_value: sensorflow_precool_commands_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_precool_commands_stream_cursor_value_input {
  createdBy: String
  creationDate: timestamptz
  failMessage: String
  id: uuid
  mode: Int
  positionId: uuid
  schedule_id: String
  source: String
  startedAt: timestamptz
  status: String
  temperature: Int
  totalMinutes: Int
  updatedAt: timestamptz
}

type sensorflow_precool_commands_sum_fields {
  mode: Int
  temperature: Int
  totalMinutes: Int
}

input sensorflow_precool_commands_sum_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

enum sensorflow_precool_commands_update_column {
  createdBy
  creationDate
  failMessage
  id
  mode
  positionId
  schedule_id
  source
  startedAt
  status
  temperature
  totalMinutes
  updatedAt
}

input sensorflow_precool_commands_updates {
  _inc: sensorflow_precool_commands_inc_input
  _set: sensorflow_precool_commands_set_input
  where: sensorflow_precool_commands_bool_exp!
}

type sensorflow_precool_commands_var_pop_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_var_pop_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

type sensorflow_precool_commands_var_samp_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_var_samp_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

type sensorflow_precool_commands_variance_fields {
  mode: Float
  temperature: Float
  totalMinutes: Float
}

input sensorflow_precool_commands_variance_order_by {
  mode: order_by
  temperature: order_by
  totalMinutes: order_by
}

type sensorflow_precool_schedules {
  endedAt: timestamptz
  id: bigint!
  reservation: sensorflow_reservations!
  reservationId: bigint!
  startedAt: timestamptz
  time: smallint
  type: String
  userId: String!
  weekDay: String
}

type sensorflow_precool_schedules_aggregate {
  aggregate: sensorflow_precool_schedules_aggregate_fields
  nodes: [sensorflow_precool_schedules!]!
}

type sensorflow_precool_schedules_aggregate_fields {
  avg: sensorflow_precool_schedules_avg_fields
  count(columns: [sensorflow_precool_schedules_select_column!], distinct: Boolean): Int!
  max: sensorflow_precool_schedules_max_fields
  min: sensorflow_precool_schedules_min_fields
  stddev: sensorflow_precool_schedules_stddev_fields
  stddev_pop: sensorflow_precool_schedules_stddev_pop_fields
  stddev_samp: sensorflow_precool_schedules_stddev_samp_fields
  sum: sensorflow_precool_schedules_sum_fields
  var_pop: sensorflow_precool_schedules_var_pop_fields
  var_samp: sensorflow_precool_schedules_var_samp_fields
  variance: sensorflow_precool_schedules_variance_fields
}

type sensorflow_precool_schedules_avg_fields {
  id: Float
  reservationId: Float
  time: Float
}

input sensorflow_precool_schedules_bool_exp {
  _and: [sensorflow_precool_schedules_bool_exp!]
  _not: sensorflow_precool_schedules_bool_exp
  _or: [sensorflow_precool_schedules_bool_exp!]
  endedAt: timestamptz_comparison_exp
  id: bigint_comparison_exp
  reservation: sensorflow_reservations_bool_exp
  reservationId: bigint_comparison_exp
  startedAt: timestamptz_comparison_exp
  time: smallint_comparison_exp
  type: String_comparison_exp
  userId: String_comparison_exp
  weekDay: String_comparison_exp
}

enum sensorflow_precool_schedules_constraint {
  precool_schedules_pk
}

input sensorflow_precool_schedules_inc_input {
  id: bigint
  reservationId: bigint
  time: smallint
}

input sensorflow_precool_schedules_insert_input {
  endedAt: timestamptz
  id: bigint
  reservation: sensorflow_reservations_obj_rel_insert_input
  reservationId: bigint
  startedAt: timestamptz
  time: smallint
  type: String
  userId: String
  weekDay: String
}

type sensorflow_precool_schedules_max_fields {
  endedAt: timestamptz
  id: bigint
  reservationId: bigint
  startedAt: timestamptz
  time: smallint
  type: String
  userId: String
  weekDay: String
}

type sensorflow_precool_schedules_min_fields {
  endedAt: timestamptz
  id: bigint
  reservationId: bigint
  startedAt: timestamptz
  time: smallint
  type: String
  userId: String
  weekDay: String
}

type sensorflow_precool_schedules_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_precool_schedules!]!
}

input sensorflow_precool_schedules_on_conflict {
  constraint: sensorflow_precool_schedules_constraint!
  update_columns: [sensorflow_precool_schedules_update_column!]! = []
  where: sensorflow_precool_schedules_bool_exp
}

input sensorflow_precool_schedules_order_by {
  endedAt: order_by
  id: order_by
  reservation: sensorflow_reservations_order_by
  reservationId: order_by
  startedAt: order_by
  time: order_by
  type: order_by
  userId: order_by
  weekDay: order_by
}

input sensorflow_precool_schedules_pk_columns_input {
  id: bigint!
}

enum sensorflow_precool_schedules_select_column {
  endedAt
  id
  reservationId
  startedAt
  time
  type
  userId
  weekDay
}

input sensorflow_precool_schedules_set_input {
  endedAt: timestamptz
  id: bigint
  reservationId: bigint
  startedAt: timestamptz
  time: smallint
  type: String
  userId: String
  weekDay: String
}

type sensorflow_precool_schedules_stddev_fields {
  id: Float
  reservationId: Float
  time: Float
}

type sensorflow_precool_schedules_stddev_pop_fields {
  id: Float
  reservationId: Float
  time: Float
}

type sensorflow_precool_schedules_stddev_samp_fields {
  id: Float
  reservationId: Float
  time: Float
}

input sensorflow_precool_schedules_stream_cursor_input {
  initial_value: sensorflow_precool_schedules_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_precool_schedules_stream_cursor_value_input {
  endedAt: timestamptz
  id: bigint
  reservationId: bigint
  startedAt: timestamptz
  time: smallint
  type: String
  userId: String
  weekDay: String
}

type sensorflow_precool_schedules_sum_fields {
  id: bigint
  reservationId: bigint
  time: smallint
}

enum sensorflow_precool_schedules_update_column {
  endedAt
  id
  reservationId
  startedAt
  time
  type
  userId
  weekDay
}

input sensorflow_precool_schedules_updates {
  _inc: sensorflow_precool_schedules_inc_input
  _set: sensorflow_precool_schedules_set_input
  where: sensorflow_precool_schedules_bool_exp!
}

type sensorflow_precool_schedules_var_pop_fields {
  id: Float
  reservationId: Float
  time: Float
}

type sensorflow_precool_schedules_var_samp_fields {
  id: Float
  reservationId: Float
  time: Float
}

type sensorflow_precool_schedules_variance_fields {
  id: Float
  reservationId: Float
  time: Float
}

type sensorflow_pumps {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid!
  meterPosition: sensorflow_positions
  meterPositionId: uuid
  name: String
  numPhases: Int!
  phaseStreamIndex: Int
  position: sensorflow_positions!
  positionId: uuid!
}

type sensorflow_pumps_aggregate {
  aggregate: sensorflow_pumps_aggregate_fields
  nodes: [sensorflow_pumps!]!
}

type sensorflow_pumps_aggregate_fields {
  avg: sensorflow_pumps_avg_fields
  count(columns: [sensorflow_pumps_select_column!], distinct: Boolean): Int!
  max: sensorflow_pumps_max_fields
  min: sensorflow_pumps_min_fields
  stddev: sensorflow_pumps_stddev_fields
  stddev_pop: sensorflow_pumps_stddev_pop_fields
  stddev_samp: sensorflow_pumps_stddev_samp_fields
  sum: sensorflow_pumps_sum_fields
  var_pop: sensorflow_pumps_var_pop_fields
  var_samp: sensorflow_pumps_var_samp_fields
  variance: sensorflow_pumps_variance_fields
}

type sensorflow_pumps_avg_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_pumps_bool_exp {
  _and: [sensorflow_pumps_bool_exp!]
  _not: sensorflow_pumps_bool_exp
  _or: [sensorflow_pumps_bool_exp!]
  compressorId: uuid_comparison_exp
  ctMultiplier: numeric_comparison_exp
  deleted_at: timestamptz_comparison_exp
  id: uuid_comparison_exp
  meterPosition: sensorflow_positions_bool_exp
  meterPositionId: uuid_comparison_exp
  name: String_comparison_exp
  numPhases: Int_comparison_exp
  phaseStreamIndex: Int_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
}

enum sensorflow_pumps_constraint {
  pumps_pkey
}

input sensorflow_pumps_inc_input {
  ctMultiplier: numeric
  numPhases: Int
  phaseStreamIndex: Int
}

input sensorflow_pumps_insert_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPosition: sensorflow_positions_obj_rel_insert_input
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
}

type sensorflow_pumps_max_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_pumps_min_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_pumps_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_pumps!]!
}

input sensorflow_pumps_on_conflict {
  constraint: sensorflow_pumps_constraint!
  update_columns: [sensorflow_pumps_update_column!]! = []
  where: sensorflow_pumps_bool_exp
}

input sensorflow_pumps_order_by {
  compressorId: order_by
  ctMultiplier: order_by
  deleted_at: order_by
  id: order_by
  meterPosition: sensorflow_positions_order_by
  meterPositionId: order_by
  name: order_by
  numPhases: order_by
  phaseStreamIndex: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
}

input sensorflow_pumps_pk_columns_input {
  id: uuid!
}

enum sensorflow_pumps_select_column {
  compressorId
  ctMultiplier
  deleted_at
  id
  meterPositionId
  name
  numPhases
  phaseStreamIndex
  positionId
}

input sensorflow_pumps_set_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_pumps_stddev_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_pumps_stddev_pop_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_pumps_stddev_samp_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

input sensorflow_pumps_stream_cursor_input {
  initial_value: sensorflow_pumps_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_pumps_stream_cursor_value_input {
  compressorId: uuid
  ctMultiplier: numeric
  deleted_at: timestamptz
  id: uuid
  meterPositionId: uuid
  name: String
  numPhases: Int
  phaseStreamIndex: Int
  positionId: uuid
}

type sensorflow_pumps_sum_fields {
  ctMultiplier: numeric
  numPhases: Int
  phaseStreamIndex: Int
}

enum sensorflow_pumps_update_column {
  compressorId
  ctMultiplier
  deleted_at
  id
  meterPositionId
  name
  numPhases
  phaseStreamIndex
  positionId
}

input sensorflow_pumps_updates {
  _inc: sensorflow_pumps_inc_input
  _set: sensorflow_pumps_set_input
  where: sensorflow_pumps_bool_exp!
}

type sensorflow_pumps_var_pop_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_pumps_var_samp_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_pumps_variance_fields {
  ctMultiplier: Float
  numPhases: Float
  phaseStreamIndex: Float
}

type sensorflow_reservation_guests {
  comfortplusAccessToken: String
  reservation: sensorflow_reservations!
  reservationId: bigint!
  user: sensorflow_users!
  userId: String!
}

type sensorflow_reservation_guests_aggregate {
  aggregate: sensorflow_reservation_guests_aggregate_fields
  nodes: [sensorflow_reservation_guests!]!
}

input sensorflow_reservation_guests_aggregate_bool_exp {
  count: sensorflow_reservation_guests_aggregate_bool_exp_count
}

input sensorflow_reservation_guests_aggregate_bool_exp_count {
  arguments: [sensorflow_reservation_guests_select_column!]
  distinct: Boolean
  filter: sensorflow_reservation_guests_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_reservation_guests_aggregate_fields {
  avg: sensorflow_reservation_guests_avg_fields
  count(columns: [sensorflow_reservation_guests_select_column!], distinct: Boolean): Int!
  max: sensorflow_reservation_guests_max_fields
  min: sensorflow_reservation_guests_min_fields
  stddev: sensorflow_reservation_guests_stddev_fields
  stddev_pop: sensorflow_reservation_guests_stddev_pop_fields
  stddev_samp: sensorflow_reservation_guests_stddev_samp_fields
  sum: sensorflow_reservation_guests_sum_fields
  var_pop: sensorflow_reservation_guests_var_pop_fields
  var_samp: sensorflow_reservation_guests_var_samp_fields
  variance: sensorflow_reservation_guests_variance_fields
}

input sensorflow_reservation_guests_aggregate_order_by {
  avg: sensorflow_reservation_guests_avg_order_by
  count: order_by
  max: sensorflow_reservation_guests_max_order_by
  min: sensorflow_reservation_guests_min_order_by
  stddev: sensorflow_reservation_guests_stddev_order_by
  stddev_pop: sensorflow_reservation_guests_stddev_pop_order_by
  stddev_samp: sensorflow_reservation_guests_stddev_samp_order_by
  sum: sensorflow_reservation_guests_sum_order_by
  var_pop: sensorflow_reservation_guests_var_pop_order_by
  var_samp: sensorflow_reservation_guests_var_samp_order_by
  variance: sensorflow_reservation_guests_variance_order_by
}

input sensorflow_reservation_guests_arr_rel_insert_input {
  data: [sensorflow_reservation_guests_insert_input!]!
  on_conflict: sensorflow_reservation_guests_on_conflict
}

type sensorflow_reservation_guests_avg_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_avg_order_by {
  reservationId: order_by
}

input sensorflow_reservation_guests_bool_exp {
  _and: [sensorflow_reservation_guests_bool_exp!]
  _not: sensorflow_reservation_guests_bool_exp
  _or: [sensorflow_reservation_guests_bool_exp!]
  comfortplusAccessToken: String_comparison_exp
  reservation: sensorflow_reservations_bool_exp
  reservationId: bigint_comparison_exp
  user: sensorflow_users_bool_exp
  userId: String_comparison_exp
}

enum sensorflow_reservation_guests_constraint {
  reservation_guests_pkey
}

input sensorflow_reservation_guests_inc_input {
  reservationId: bigint
}

input sensorflow_reservation_guests_insert_input {
  comfortplusAccessToken: String
  reservation: sensorflow_reservations_obj_rel_insert_input
  reservationId: bigint
  user: sensorflow_users_obj_rel_insert_input
  userId: String
}

type sensorflow_reservation_guests_max_fields {
  comfortplusAccessToken: String
  reservationId: bigint
  userId: String
}

input sensorflow_reservation_guests_max_order_by {
  comfortplusAccessToken: order_by
  reservationId: order_by
  userId: order_by
}

type sensorflow_reservation_guests_min_fields {
  comfortplusAccessToken: String
  reservationId: bigint
  userId: String
}

input sensorflow_reservation_guests_min_order_by {
  comfortplusAccessToken: order_by
  reservationId: order_by
  userId: order_by
}

type sensorflow_reservation_guests_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_reservation_guests!]!
}

input sensorflow_reservation_guests_on_conflict {
  constraint: sensorflow_reservation_guests_constraint!
  update_columns: [sensorflow_reservation_guests_update_column!]! = []
  where: sensorflow_reservation_guests_bool_exp
}

input sensorflow_reservation_guests_order_by {
  comfortplusAccessToken: order_by
  reservation: sensorflow_reservations_order_by
  reservationId: order_by
  user: sensorflow_users_order_by
  userId: order_by
}

input sensorflow_reservation_guests_pk_columns_input {
  reservationId: bigint!
  userId: String!
}

enum sensorflow_reservation_guests_select_column {
  comfortplusAccessToken
  reservationId
  userId
}

input sensorflow_reservation_guests_set_input {
  comfortplusAccessToken: String
  reservationId: bigint
  userId: String
}

type sensorflow_reservation_guests_stddev_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_stddev_order_by {
  reservationId: order_by
}

type sensorflow_reservation_guests_stddev_pop_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_stddev_pop_order_by {
  reservationId: order_by
}

type sensorflow_reservation_guests_stddev_samp_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_stddev_samp_order_by {
  reservationId: order_by
}

input sensorflow_reservation_guests_stream_cursor_input {
  initial_value: sensorflow_reservation_guests_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_reservation_guests_stream_cursor_value_input {
  comfortplusAccessToken: String
  reservationId: bigint
  userId: String
}

type sensorflow_reservation_guests_sum_fields {
  reservationId: bigint
}

input sensorflow_reservation_guests_sum_order_by {
  reservationId: order_by
}

enum sensorflow_reservation_guests_update_column {
  comfortplusAccessToken
  reservationId
  userId
}

input sensorflow_reservation_guests_updates {
  _inc: sensorflow_reservation_guests_inc_input
  _set: sensorflow_reservation_guests_set_input
  where: sensorflow_reservation_guests_bool_exp!
}

type sensorflow_reservation_guests_var_pop_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_var_pop_order_by {
  reservationId: order_by
}

type sensorflow_reservation_guests_var_samp_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_var_samp_order_by {
  reservationId: order_by
}

type sensorflow_reservation_guests_variance_fields {
  reservationId: Float
}

input sensorflow_reservation_guests_variance_order_by {
  reservationId: order_by
}

type sensorflow_reservations {
  checkInAt: timestamptz
  checkOutAt: timestamptz
  comfortplusActivations(distinct_on: [sensorflow_comfortplus_activations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_comfortplus_activations_order_by!], where: sensorflow_comfortplus_activations_bool_exp): [sensorflow_comfortplus_activations!]!
  comfortplusActivations_aggregate(distinct_on: [sensorflow_comfortplus_activations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_comfortplus_activations_order_by!], where: sensorflow_comfortplus_activations_bool_exp): sensorflow_comfortplus_activations_aggregate!
  comment: String
  id: bigint!
  keyPosition: sensorflow_positions!
  keyPositionId: uuid!
  reservationGuests(distinct_on: [sensorflow_reservation_guests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservation_guests_order_by!], where: sensorflow_reservation_guests_bool_exp): [sensorflow_reservation_guests!]!
  reservationGuests_aggregate(distinct_on: [sensorflow_reservation_guests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservation_guests_order_by!], where: sensorflow_reservation_guests_bool_exp): sensorflow_reservation_guests_aggregate!
  status: String
}

type sensorflow_reservations_aggregate {
  aggregate: sensorflow_reservations_aggregate_fields
  nodes: [sensorflow_reservations!]!
}

input sensorflow_reservations_aggregate_bool_exp {
  count: sensorflow_reservations_aggregate_bool_exp_count
}

input sensorflow_reservations_aggregate_bool_exp_count {
  arguments: [sensorflow_reservations_select_column!]
  distinct: Boolean
  filter: sensorflow_reservations_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_reservations_aggregate_fields {
  avg: sensorflow_reservations_avg_fields
  count(columns: [sensorflow_reservations_select_column!], distinct: Boolean): Int!
  max: sensorflow_reservations_max_fields
  min: sensorflow_reservations_min_fields
  stddev: sensorflow_reservations_stddev_fields
  stddev_pop: sensorflow_reservations_stddev_pop_fields
  stddev_samp: sensorflow_reservations_stddev_samp_fields
  sum: sensorflow_reservations_sum_fields
  var_pop: sensorflow_reservations_var_pop_fields
  var_samp: sensorflow_reservations_var_samp_fields
  variance: sensorflow_reservations_variance_fields
}

input sensorflow_reservations_aggregate_order_by {
  avg: sensorflow_reservations_avg_order_by
  count: order_by
  max: sensorflow_reservations_max_order_by
  min: sensorflow_reservations_min_order_by
  stddev: sensorflow_reservations_stddev_order_by
  stddev_pop: sensorflow_reservations_stddev_pop_order_by
  stddev_samp: sensorflow_reservations_stddev_samp_order_by
  sum: sensorflow_reservations_sum_order_by
  var_pop: sensorflow_reservations_var_pop_order_by
  var_samp: sensorflow_reservations_var_samp_order_by
  variance: sensorflow_reservations_variance_order_by
}

input sensorflow_reservations_arr_rel_insert_input {
  data: [sensorflow_reservations_insert_input!]!
  on_conflict: sensorflow_reservations_on_conflict
}

type sensorflow_reservations_avg_fields {
  id: Float
}

input sensorflow_reservations_avg_order_by {
  id: order_by
}

input sensorflow_reservations_bool_exp {
  _and: [sensorflow_reservations_bool_exp!]
  _not: sensorflow_reservations_bool_exp
  _or: [sensorflow_reservations_bool_exp!]
  checkInAt: timestamptz_comparison_exp
  checkOutAt: timestamptz_comparison_exp
  comfortplusActivations: sensorflow_comfortplus_activations_bool_exp
  comfortplusActivations_aggregate: sensorflow_comfortplus_activations_aggregate_bool_exp
  comment: String_comparison_exp
  id: bigint_comparison_exp
  keyPosition: sensorflow_positions_bool_exp
  keyPositionId: uuid_comparison_exp
  reservationGuests: sensorflow_reservation_guests_bool_exp
  reservationGuests_aggregate: sensorflow_reservation_guests_aggregate_bool_exp
  status: String_comparison_exp
}

enum sensorflow_reservations_constraint {
  reservations_pkey
}

input sensorflow_reservations_inc_input {
  id: bigint
}

input sensorflow_reservations_insert_input {
  checkInAt: timestamptz
  checkOutAt: timestamptz
  comfortplusActivations: sensorflow_comfortplus_activations_arr_rel_insert_input
  comment: String
  id: bigint
  keyPosition: sensorflow_positions_obj_rel_insert_input
  keyPositionId: uuid
  reservationGuests: sensorflow_reservation_guests_arr_rel_insert_input
  status: String
}

type sensorflow_reservations_max_fields {
  checkInAt: timestamptz
  checkOutAt: timestamptz
  comment: String
  id: bigint
  keyPositionId: uuid
  status: String
}

input sensorflow_reservations_max_order_by {
  checkInAt: order_by
  checkOutAt: order_by
  comment: order_by
  id: order_by
  keyPositionId: order_by
  status: order_by
}

type sensorflow_reservations_min_fields {
  checkInAt: timestamptz
  checkOutAt: timestamptz
  comment: String
  id: bigint
  keyPositionId: uuid
  status: String
}

input sensorflow_reservations_min_order_by {
  checkInAt: order_by
  checkOutAt: order_by
  comment: order_by
  id: order_by
  keyPositionId: order_by
  status: order_by
}

type sensorflow_reservations_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_reservations!]!
}

input sensorflow_reservations_obj_rel_insert_input {
  data: sensorflow_reservations_insert_input!
  on_conflict: sensorflow_reservations_on_conflict
}

input sensorflow_reservations_on_conflict {
  constraint: sensorflow_reservations_constraint!
  update_columns: [sensorflow_reservations_update_column!]! = []
  where: sensorflow_reservations_bool_exp
}

input sensorflow_reservations_order_by {
  checkInAt: order_by
  checkOutAt: order_by
  comfortplusActivations_aggregate: sensorflow_comfortplus_activations_aggregate_order_by
  comment: order_by
  id: order_by
  keyPosition: sensorflow_positions_order_by
  keyPositionId: order_by
  reservationGuests_aggregate: sensorflow_reservation_guests_aggregate_order_by
  status: order_by
}

input sensorflow_reservations_pk_columns_input {
  id: bigint!
}

enum sensorflow_reservations_select_column {
  checkInAt
  checkOutAt
  comment
  id
  keyPositionId
  status
}

input sensorflow_reservations_set_input {
  checkInAt: timestamptz
  checkOutAt: timestamptz
  comment: String
  id: bigint
  keyPositionId: uuid
  status: String
}

type sensorflow_reservations_stddev_fields {
  id: Float
}

input sensorflow_reservations_stddev_order_by {
  id: order_by
}

type sensorflow_reservations_stddev_pop_fields {
  id: Float
}

input sensorflow_reservations_stddev_pop_order_by {
  id: order_by
}

type sensorflow_reservations_stddev_samp_fields {
  id: Float
}

input sensorflow_reservations_stddev_samp_order_by {
  id: order_by
}

input sensorflow_reservations_stream_cursor_input {
  initial_value: sensorflow_reservations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_reservations_stream_cursor_value_input {
  checkInAt: timestamptz
  checkOutAt: timestamptz
  comment: String
  id: bigint
  keyPositionId: uuid
  status: String
}

type sensorflow_reservations_sum_fields {
  id: bigint
}

input sensorflow_reservations_sum_order_by {
  id: order_by
}

enum sensorflow_reservations_update_column {
  checkInAt
  checkOutAt
  comment
  id
  keyPositionId
  status
}

input sensorflow_reservations_updates {
  _inc: sensorflow_reservations_inc_input
  _set: sensorflow_reservations_set_input
  where: sensorflow_reservations_bool_exp!
}

type sensorflow_reservations_var_pop_fields {
  id: Float
}

input sensorflow_reservations_var_pop_order_by {
  id: order_by
}

type sensorflow_reservations_var_samp_fields {
  id: Float
}

input sensorflow_reservations_var_samp_order_by {
  id: order_by
}

type sensorflow_reservations_variance_fields {
  id: Float
}

input sensorflow_reservations_variance_order_by {
  id: order_by
}

type sensorflow_roles {
  default_route: String
  hidden_in_dashboard: Boolean
  id: uuid!
  name: String!
}

type sensorflow_roles_aggregate {
  aggregate: sensorflow_roles_aggregate_fields
  nodes: [sensorflow_roles!]!
}

type sensorflow_roles_aggregate_fields {
  count(columns: [sensorflow_roles_select_column!], distinct: Boolean): Int!
  max: sensorflow_roles_max_fields
  min: sensorflow_roles_min_fields
}

input sensorflow_roles_bool_exp {
  _and: [sensorflow_roles_bool_exp!]
  _not: sensorflow_roles_bool_exp
  _or: [sensorflow_roles_bool_exp!]
  default_route: String_comparison_exp
  hidden_in_dashboard: Boolean_comparison_exp
  id: uuid_comparison_exp
  name: String_comparison_exp
}

enum sensorflow_roles_constraint {
  roles_pkey
}

input sensorflow_roles_insert_input {
  default_route: String
  hidden_in_dashboard: Boolean
  id: uuid
  name: String
}

type sensorflow_roles_max_fields {
  default_route: String
  id: uuid
  name: String
}

type sensorflow_roles_min_fields {
  default_route: String
  id: uuid
  name: String
}

type sensorflow_roles_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_roles!]!
}

input sensorflow_roles_on_conflict {
  constraint: sensorflow_roles_constraint!
  update_columns: [sensorflow_roles_update_column!]! = []
  where: sensorflow_roles_bool_exp
}

input sensorflow_roles_order_by {
  default_route: order_by
  hidden_in_dashboard: order_by
  id: order_by
  name: order_by
}

input sensorflow_roles_pk_columns_input {
  id: uuid!
}

enum sensorflow_roles_select_column {
  default_route
  hidden_in_dashboard
  id
  name
}

input sensorflow_roles_set_input {
  default_route: String
  hidden_in_dashboard: Boolean
  id: uuid
  name: String
}

input sensorflow_roles_stream_cursor_input {
  initial_value: sensorflow_roles_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_roles_stream_cursor_value_input {
  default_route: String
  hidden_in_dashboard: Boolean
  id: uuid
  name: String
}

enum sensorflow_roles_update_column {
  default_route
  hidden_in_dashboard
  id
  name
}

input sensorflow_roles_updates {
  _set: sensorflow_roles_set_input
  where: sensorflow_roles_bool_exp!
}

type sensorflow_smart_rem_validation_history {
  automationMode: String
  currentToggleState: String
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  id: uuid!
  lastToggleTimestamp: timestamptz
  locationId: uuid!
  smartsaveSetpoint: Int
  validationEndDate: timestamptz
  validationStartDate: timestamptz!
}

type sensorflow_smart_rem_validation_history_aggregate {
  aggregate: sensorflow_smart_rem_validation_history_aggregate_fields
  nodes: [sensorflow_smart_rem_validation_history!]!
}

type sensorflow_smart_rem_validation_history_aggregate_fields {
  avg: sensorflow_smart_rem_validation_history_avg_fields
  count(columns: [sensorflow_smart_rem_validation_history_select_column!], distinct: Boolean): Int!
  max: sensorflow_smart_rem_validation_history_max_fields
  min: sensorflow_smart_rem_validation_history_min_fields
  stddev: sensorflow_smart_rem_validation_history_stddev_fields
  stddev_pop: sensorflow_smart_rem_validation_history_stddev_pop_fields
  stddev_samp: sensorflow_smart_rem_validation_history_stddev_samp_fields
  sum: sensorflow_smart_rem_validation_history_sum_fields
  var_pop: sensorflow_smart_rem_validation_history_var_pop_fields
  var_samp: sensorflow_smart_rem_validation_history_var_samp_fields
  variance: sensorflow_smart_rem_validation_history_variance_fields
}

type sensorflow_smart_rem_validation_history_avg_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

input sensorflow_smart_rem_validation_history_bool_exp {
  _and: [sensorflow_smart_rem_validation_history_bool_exp!]
  _not: sensorflow_smart_rem_validation_history_bool_exp
  _or: [sensorflow_smart_rem_validation_history_bool_exp!]
  automationMode: String_comparison_exp
  currentToggleState: String_comparison_exp
  desiredToggleHour: Int_comparison_exp
  desiredToggleTimeoutHours: Int_comparison_exp
  id: uuid_comparison_exp
  lastToggleTimestamp: timestamptz_comparison_exp
  locationId: uuid_comparison_exp
  smartsaveSetpoint: Int_comparison_exp
  validationEndDate: timestamptz_comparison_exp
  validationStartDate: timestamptz_comparison_exp
}

enum sensorflow_smart_rem_validation_history_constraint {
  smart_rem_validation_history_pkey
}

input sensorflow_smart_rem_validation_history_inc_input {
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  smartsaveSetpoint: Int
}

input sensorflow_smart_rem_validation_history_insert_input {
  automationMode: String
  currentToggleState: String
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  id: uuid
  lastToggleTimestamp: timestamptz
  locationId: uuid
  smartsaveSetpoint: Int
  validationEndDate: timestamptz
  validationStartDate: timestamptz
}

type sensorflow_smart_rem_validation_history_max_fields {
  automationMode: String
  currentToggleState: String
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  id: uuid
  lastToggleTimestamp: timestamptz
  locationId: uuid
  smartsaveSetpoint: Int
  validationEndDate: timestamptz
  validationStartDate: timestamptz
}

type sensorflow_smart_rem_validation_history_min_fields {
  automationMode: String
  currentToggleState: String
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  id: uuid
  lastToggleTimestamp: timestamptz
  locationId: uuid
  smartsaveSetpoint: Int
  validationEndDate: timestamptz
  validationStartDate: timestamptz
}

type sensorflow_smart_rem_validation_history_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_smart_rem_validation_history!]!
}

input sensorflow_smart_rem_validation_history_on_conflict {
  constraint: sensorflow_smart_rem_validation_history_constraint!
  update_columns: [sensorflow_smart_rem_validation_history_update_column!]! = []
  where: sensorflow_smart_rem_validation_history_bool_exp
}

input sensorflow_smart_rem_validation_history_order_by {
  automationMode: order_by
  currentToggleState: order_by
  desiredToggleHour: order_by
  desiredToggleTimeoutHours: order_by
  id: order_by
  lastToggleTimestamp: order_by
  locationId: order_by
  smartsaveSetpoint: order_by
  validationEndDate: order_by
  validationStartDate: order_by
}

input sensorflow_smart_rem_validation_history_pk_columns_input {
  id: uuid!
}

enum sensorflow_smart_rem_validation_history_select_column {
  automationMode
  currentToggleState
  desiredToggleHour
  desiredToggleTimeoutHours
  id
  lastToggleTimestamp
  locationId
  smartsaveSetpoint
  validationEndDate
  validationStartDate
}

input sensorflow_smart_rem_validation_history_set_input {
  automationMode: String
  currentToggleState: String
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  id: uuid
  lastToggleTimestamp: timestamptz
  locationId: uuid
  smartsaveSetpoint: Int
  validationEndDate: timestamptz
  validationStartDate: timestamptz
}

type sensorflow_smart_rem_validation_history_stddev_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

type sensorflow_smart_rem_validation_history_stddev_pop_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

type sensorflow_smart_rem_validation_history_stddev_samp_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

input sensorflow_smart_rem_validation_history_stream_cursor_input {
  initial_value: sensorflow_smart_rem_validation_history_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_smart_rem_validation_history_stream_cursor_value_input {
  automationMode: String
  currentToggleState: String
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  id: uuid
  lastToggleTimestamp: timestamptz
  locationId: uuid
  smartsaveSetpoint: Int
  validationEndDate: timestamptz
  validationStartDate: timestamptz
}

type sensorflow_smart_rem_validation_history_sum_fields {
  desiredToggleHour: Int
  desiredToggleTimeoutHours: Int
  smartsaveSetpoint: Int
}

enum sensorflow_smart_rem_validation_history_update_column {
  automationMode
  currentToggleState
  desiredToggleHour
  desiredToggleTimeoutHours
  id
  lastToggleTimestamp
  locationId
  smartsaveSetpoint
  validationEndDate
  validationStartDate
}

input sensorflow_smart_rem_validation_history_updates {
  _inc: sensorflow_smart_rem_validation_history_inc_input
  _set: sensorflow_smart_rem_validation_history_set_input
  where: sensorflow_smart_rem_validation_history_bool_exp!
}

type sensorflow_smart_rem_validation_history_var_pop_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

type sensorflow_smart_rem_validation_history_var_samp_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

type sensorflow_smart_rem_validation_history_variance_fields {
  desiredToggleHour: Float
  desiredToggleTimeoutHours: Float
  smartsaveSetpoint: Float
}

type sensorflow_synced_raw_data {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  door_state: numeric
  fan_speed: numeric
  occupancy_state: numeric
  position_id: uuid
  powered: numeric
  temperature_set: numeric
  timestamp: timestamptz
}

type sensorflow_synced_raw_data_aggregate {
  aggregate: sensorflow_synced_raw_data_aggregate_fields
  nodes: [sensorflow_synced_raw_data!]!
}

type sensorflow_synced_raw_data_aggregate_fields {
  avg: sensorflow_synced_raw_data_avg_fields
  count(columns: [sensorflow_synced_raw_data_select_column!], distinct: Boolean): Int!
  max: sensorflow_synced_raw_data_max_fields
  min: sensorflow_synced_raw_data_min_fields
  stddev: sensorflow_synced_raw_data_stddev_fields
  stddev_pop: sensorflow_synced_raw_data_stddev_pop_fields
  stddev_samp: sensorflow_synced_raw_data_stddev_samp_fields
  sum: sensorflow_synced_raw_data_sum_fields
  var_pop: sensorflow_synced_raw_data_var_pop_fields
  var_samp: sensorflow_synced_raw_data_var_samp_fields
  variance: sensorflow_synced_raw_data_variance_fields
}

type sensorflow_synced_raw_data_avg_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

input sensorflow_synced_raw_data_bool_exp {
  _and: [sensorflow_synced_raw_data_bool_exp!]
  _not: sensorflow_synced_raw_data_bool_exp
  _or: [sensorflow_synced_raw_data_bool_exp!]
  ac_mode: numeric_comparison_exp
  ambient_humidity: numeric_comparison_exp
  ambient_temperature: numeric_comparison_exp
  door_state: numeric_comparison_exp
  fan_speed: numeric_comparison_exp
  occupancy_state: numeric_comparison_exp
  position_id: uuid_comparison_exp
  powered: numeric_comparison_exp
  temperature_set: numeric_comparison_exp
  timestamp: timestamptz_comparison_exp
}

type sensorflow_synced_raw_data_max_fields {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  door_state: numeric
  fan_speed: numeric
  occupancy_state: numeric
  position_id: uuid
  powered: numeric
  temperature_set: numeric
  timestamp: timestamptz
}

type sensorflow_synced_raw_data_min_fields {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  door_state: numeric
  fan_speed: numeric
  occupancy_state: numeric
  position_id: uuid
  powered: numeric
  temperature_set: numeric
  timestamp: timestamptz
}

input sensorflow_synced_raw_data_order_by {
  ac_mode: order_by
  ambient_humidity: order_by
  ambient_temperature: order_by
  door_state: order_by
  fan_speed: order_by
  occupancy_state: order_by
  position_id: order_by
  powered: order_by
  temperature_set: order_by
  timestamp: order_by
}

enum sensorflow_synced_raw_data_select_column {
  ac_mode
  ambient_humidity
  ambient_temperature
  door_state
  fan_speed
  occupancy_state
  position_id
  powered
  temperature_set
  timestamp
}

type sensorflow_synced_raw_data_stddev_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

type sensorflow_synced_raw_data_stddev_pop_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

type sensorflow_synced_raw_data_stddev_samp_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

input sensorflow_synced_raw_data_stream_cursor_input {
  initial_value: sensorflow_synced_raw_data_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_synced_raw_data_stream_cursor_value_input {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  door_state: numeric
  fan_speed: numeric
  occupancy_state: numeric
  position_id: uuid
  powered: numeric
  temperature_set: numeric
  timestamp: timestamptz
}

type sensorflow_synced_raw_data_sum_fields {
  ac_mode: numeric
  ambient_humidity: numeric
  ambient_temperature: numeric
  door_state: numeric
  fan_speed: numeric
  occupancy_state: numeric
  powered: numeric
  temperature_set: numeric
}

type sensorflow_synced_raw_data_var_pop_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

type sensorflow_synced_raw_data_var_samp_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

type sensorflow_synced_raw_data_variance_fields {
  ac_mode: Float
  ambient_humidity: Float
  ambient_temperature: Float
  door_state: Float
  fan_speed: Float
  occupancy_state: Float
  powered: Float
  temperature_set: Float
}

type sensorflow_task_status_history {
  createdAt: timestamptz!
  id: Int!
  status: String!
  taskId: uuid!
}

type sensorflow_task_status_history_aggregate {
  aggregate: sensorflow_task_status_history_aggregate_fields
  nodes: [sensorflow_task_status_history!]!
}

type sensorflow_task_status_history_aggregate_fields {
  avg: sensorflow_task_status_history_avg_fields
  count(columns: [sensorflow_task_status_history_select_column!], distinct: Boolean): Int!
  max: sensorflow_task_status_history_max_fields
  min: sensorflow_task_status_history_min_fields
  stddev: sensorflow_task_status_history_stddev_fields
  stddev_pop: sensorflow_task_status_history_stddev_pop_fields
  stddev_samp: sensorflow_task_status_history_stddev_samp_fields
  sum: sensorflow_task_status_history_sum_fields
  var_pop: sensorflow_task_status_history_var_pop_fields
  var_samp: sensorflow_task_status_history_var_samp_fields
  variance: sensorflow_task_status_history_variance_fields
}

type sensorflow_task_status_history_avg_fields {
  id: Float
}

input sensorflow_task_status_history_bool_exp {
  _and: [sensorflow_task_status_history_bool_exp!]
  _not: sensorflow_task_status_history_bool_exp
  _or: [sensorflow_task_status_history_bool_exp!]
  createdAt: timestamptz_comparison_exp
  id: Int_comparison_exp
  status: String_comparison_exp
  taskId: uuid_comparison_exp
}

enum sensorflow_task_status_history_constraint {
  task_status_history_pkey
}

input sensorflow_task_status_history_inc_input {
  id: Int
}

input sensorflow_task_status_history_insert_input {
  createdAt: timestamptz
  id: Int
  status: String
  taskId: uuid
}

type sensorflow_task_status_history_max_fields {
  createdAt: timestamptz
  id: Int
  status: String
  taskId: uuid
}

type sensorflow_task_status_history_min_fields {
  createdAt: timestamptz
  id: Int
  status: String
  taskId: uuid
}

type sensorflow_task_status_history_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_task_status_history!]!
}

input sensorflow_task_status_history_on_conflict {
  constraint: sensorflow_task_status_history_constraint!
  update_columns: [sensorflow_task_status_history_update_column!]! = []
  where: sensorflow_task_status_history_bool_exp
}

input sensorflow_task_status_history_order_by {
  createdAt: order_by
  id: order_by
  status: order_by
  taskId: order_by
}

input sensorflow_task_status_history_pk_columns_input {
  id: Int!
}

enum sensorflow_task_status_history_select_column {
  createdAt
  id
  status
  taskId
}

input sensorflow_task_status_history_set_input {
  createdAt: timestamptz
  id: Int
  status: String
  taskId: uuid
}

type sensorflow_task_status_history_stddev_fields {
  id: Float
}

type sensorflow_task_status_history_stddev_pop_fields {
  id: Float
}

type sensorflow_task_status_history_stddev_samp_fields {
  id: Float
}

input sensorflow_task_status_history_stream_cursor_input {
  initial_value: sensorflow_task_status_history_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_task_status_history_stream_cursor_value_input {
  createdAt: timestamptz
  id: Int
  status: String
  taskId: uuid
}

type sensorflow_task_status_history_sum_fields {
  id: Int
}

enum sensorflow_task_status_history_update_column {
  createdAt
  id
  status
  taskId
}

input sensorflow_task_status_history_updates {
  _inc: sensorflow_task_status_history_inc_input
  _set: sensorflow_task_status_history_set_input
  where: sensorflow_task_status_history_bool_exp!
}

type sensorflow_task_status_history_var_pop_fields {
  id: Float
}

type sensorflow_task_status_history_var_samp_fields {
  id: Float
}

type sensorflow_task_status_history_variance_fields {
  id: Float
}

type sensorflow_tasks {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String!
  completedAt: timestamptz
  createdAt: timestamptz
  description: String
  due_date: timestamptz
  executor: String
  id: uuid!
  keyEntryId: Int
  parentEntityId: String!
  parentEntityType: String!
  status: String!
  support_ticket_id: Int
  title: String!
  type: String!
}

type sensorflow_tasks_aggregate {
  aggregate: sensorflow_tasks_aggregate_fields
  nodes: [sensorflow_tasks!]!
}

type sensorflow_tasks_aggregate_fields {
  avg: sensorflow_tasks_avg_fields
  count(columns: [sensorflow_tasks_select_column!], distinct: Boolean): Int!
  max: sensorflow_tasks_max_fields
  min: sensorflow_tasks_min_fields
  stddev: sensorflow_tasks_stddev_fields
  stddev_pop: sensorflow_tasks_stddev_pop_fields
  stddev_samp: sensorflow_tasks_stddev_samp_fields
  sum: sensorflow_tasks_sum_fields
  var_pop: sensorflow_tasks_var_pop_fields
  var_samp: sensorflow_tasks_var_samp_fields
  variance: sensorflow_tasks_variance_fields
}

type sensorflow_tasks_avg_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

input sensorflow_tasks_bool_exp {
  _and: [sensorflow_tasks_bool_exp!]
  _not: sensorflow_tasks_bool_exp
  _or: [sensorflow_tasks_bool_exp!]
  action_id: Int_comparison_exp
  associatedSlotId: uuid_comparison_exp
  claimerEmail: String_comparison_exp
  clickupTaskId: String_comparison_exp
  completedAt: timestamptz_comparison_exp
  createdAt: timestamptz_comparison_exp
  description: String_comparison_exp
  due_date: timestamptz_comparison_exp
  executor: String_comparison_exp
  id: uuid_comparison_exp
  keyEntryId: Int_comparison_exp
  parentEntityId: String_comparison_exp
  parentEntityType: String_comparison_exp
  status: String_comparison_exp
  support_ticket_id: Int_comparison_exp
  title: String_comparison_exp
  type: String_comparison_exp
}

enum sensorflow_tasks_constraint {
  tasks_clickup_task_id_key
  tasks_pkey
  test_task_unique
}

input sensorflow_tasks_inc_input {
  action_id: Int
  keyEntryId: Int
  support_ticket_id: Int
}

input sensorflow_tasks_insert_input {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  completedAt: timestamptz
  createdAt: timestamptz
  description: String
  due_date: timestamptz
  executor: String
  id: uuid
  keyEntryId: Int
  parentEntityId: String
  parentEntityType: String
  status: String
  support_ticket_id: Int
  title: String
  type: String
}

type sensorflow_tasks_max_fields {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  completedAt: timestamptz
  createdAt: timestamptz
  description: String
  due_date: timestamptz
  executor: String
  id: uuid
  keyEntryId: Int
  parentEntityId: String
  parentEntityType: String
  status: String
  support_ticket_id: Int
  title: String
  type: String
}

type sensorflow_tasks_min_fields {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  completedAt: timestamptz
  createdAt: timestamptz
  description: String
  due_date: timestamptz
  executor: String
  id: uuid
  keyEntryId: Int
  parentEntityId: String
  parentEntityType: String
  status: String
  support_ticket_id: Int
  title: String
  type: String
}

type sensorflow_tasks_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_tasks!]!
}

input sensorflow_tasks_on_conflict {
  constraint: sensorflow_tasks_constraint!
  update_columns: [sensorflow_tasks_update_column!]! = []
  where: sensorflow_tasks_bool_exp
}

input sensorflow_tasks_order_by {
  action_id: order_by
  associatedSlotId: order_by
  claimerEmail: order_by
  clickupTaskId: order_by
  completedAt: order_by
  createdAt: order_by
  description: order_by
  due_date: order_by
  executor: order_by
  id: order_by
  keyEntryId: order_by
  parentEntityId: order_by
  parentEntityType: order_by
  status: order_by
  support_ticket_id: order_by
  title: order_by
  type: order_by
}

input sensorflow_tasks_pk_columns_input {
  id: uuid!
}

enum sensorflow_tasks_select_column {
  action_id
  associatedSlotId
  claimerEmail
  clickupTaskId
  completedAt
  createdAt
  description
  due_date
  executor
  id
  keyEntryId
  parentEntityId
  parentEntityType
  status
  support_ticket_id
  title
  type
}

input sensorflow_tasks_set_input {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  completedAt: timestamptz
  createdAt: timestamptz
  description: String
  due_date: timestamptz
  executor: String
  id: uuid
  keyEntryId: Int
  parentEntityId: String
  parentEntityType: String
  status: String
  support_ticket_id: Int
  title: String
  type: String
}

type sensorflow_tasks_stddev_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

type sensorflow_tasks_stddev_pop_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

type sensorflow_tasks_stddev_samp_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

input sensorflow_tasks_stream_cursor_input {
  initial_value: sensorflow_tasks_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_tasks_stream_cursor_value_input {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  completedAt: timestamptz
  createdAt: timestamptz
  description: String
  due_date: timestamptz
  executor: String
  id: uuid
  keyEntryId: Int
  parentEntityId: String
  parentEntityType: String
  status: String
  support_ticket_id: Int
  title: String
  type: String
}

type sensorflow_tasks_sum_fields {
  action_id: Int
  keyEntryId: Int
  support_ticket_id: Int
}

enum sensorflow_tasks_update_column {
  action_id
  associatedSlotId
  claimerEmail
  clickupTaskId
  completedAt
  createdAt
  description
  due_date
  executor
  id
  keyEntryId
  parentEntityId
  parentEntityType
  status
  support_ticket_id
  title
  type
}

input sensorflow_tasks_updates {
  _inc: sensorflow_tasks_inc_input
  _set: sensorflow_tasks_set_input
  where: sensorflow_tasks_bool_exp!
}

type sensorflow_tasks_var_pop_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

type sensorflow_tasks_var_samp_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

type sensorflow_tasks_variance_fields {
  action_id: Float
  keyEntryId: Float
  support_ticket_id: Float
}

type sensorflow_tests {
  description: String!
  isActive: Boolean!
  isManual: Boolean!
  name: String!
  positionFunction: String!
  query: String
  testId: Int!
  test_runs(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): [testRuns!]!
  test_runs_aggregate(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): testRuns_aggregate!
  troubleshootingLink: String!
}

type sensorflow_tests_aggregate {
  aggregate: sensorflow_tests_aggregate_fields
  nodes: [sensorflow_tests!]!
}

type sensorflow_tests_aggregate_fields {
  avg: sensorflow_tests_avg_fields
  count(columns: [sensorflow_tests_select_column!], distinct: Boolean): Int!
  max: sensorflow_tests_max_fields
  min: sensorflow_tests_min_fields
  stddev: sensorflow_tests_stddev_fields
  stddev_pop: sensorflow_tests_stddev_pop_fields
  stddev_samp: sensorflow_tests_stddev_samp_fields
  sum: sensorflow_tests_sum_fields
  var_pop: sensorflow_tests_var_pop_fields
  var_samp: sensorflow_tests_var_samp_fields
  variance: sensorflow_tests_variance_fields
}

type sensorflow_tests_avg_fields {
  testId: Float
}

input sensorflow_tests_bool_exp {
  _and: [sensorflow_tests_bool_exp!]
  _not: sensorflow_tests_bool_exp
  _or: [sensorflow_tests_bool_exp!]
  description: String_comparison_exp
  isActive: Boolean_comparison_exp
  isManual: Boolean_comparison_exp
  name: String_comparison_exp
  positionFunction: String_comparison_exp
  query: String_comparison_exp
  testId: Int_comparison_exp
  test_runs: testRuns_bool_exp
  test_runs_aggregate: testRuns_aggregate_bool_exp
  troubleshootingLink: String_comparison_exp
}

enum sensorflow_tests_constraint {
  tests_pkey
}

input sensorflow_tests_inc_input {
  testId: Int
}

input sensorflow_tests_insert_input {
  description: String
  isActive: Boolean
  isManual: Boolean
  name: String
  positionFunction: String
  query: String
  testId: Int
  test_runs: testRuns_arr_rel_insert_input
  troubleshootingLink: String
}

type sensorflow_tests_max_fields {
  description: String
  name: String
  positionFunction: String
  query: String
  testId: Int
  troubleshootingLink: String
}

type sensorflow_tests_min_fields {
  description: String
  name: String
  positionFunction: String
  query: String
  testId: Int
  troubleshootingLink: String
}

type sensorflow_tests_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_tests!]!
}

input sensorflow_tests_obj_rel_insert_input {
  data: sensorflow_tests_insert_input!
  on_conflict: sensorflow_tests_on_conflict
}

input sensorflow_tests_on_conflict {
  constraint: sensorflow_tests_constraint!
  update_columns: [sensorflow_tests_update_column!]! = []
  where: sensorflow_tests_bool_exp
}

input sensorflow_tests_order_by {
  description: order_by
  isActive: order_by
  isManual: order_by
  name: order_by
  positionFunction: order_by
  query: order_by
  testId: order_by
  test_runs_aggregate: testRuns_aggregate_order_by
  troubleshootingLink: order_by
}

input sensorflow_tests_pk_columns_input {
  testId: Int!
}

enum sensorflow_tests_select_column {
  description
  isActive
  isManual
  name
  positionFunction
  query
  testId
  troubleshootingLink
}

input sensorflow_tests_set_input {
  description: String
  isActive: Boolean
  isManual: Boolean
  name: String
  positionFunction: String
  query: String
  testId: Int
  troubleshootingLink: String
}

type sensorflow_tests_stddev_fields {
  testId: Float
}

type sensorflow_tests_stddev_pop_fields {
  testId: Float
}

type sensorflow_tests_stddev_samp_fields {
  testId: Float
}

input sensorflow_tests_stream_cursor_input {
  initial_value: sensorflow_tests_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_tests_stream_cursor_value_input {
  description: String
  isActive: Boolean
  isManual: Boolean
  name: String
  positionFunction: String
  query: String
  testId: Int
  troubleshootingLink: String
}

type sensorflow_tests_sum_fields {
  testId: Int
}

enum sensorflow_tests_update_column {
  description
  isActive
  isManual
  name
  positionFunction
  query
  testId
  troubleshootingLink
}

input sensorflow_tests_updates {
  _inc: sensorflow_tests_inc_input
  _set: sensorflow_tests_set_input
  where: sensorflow_tests_bool_exp!
}

type sensorflow_tests_var_pop_fields {
  testId: Float
}

type sensorflow_tests_var_samp_fields {
  testId: Float
}

type sensorflow_tests_variance_fields {
  testId: Float
}

type sensorflow_timezones {
  id: Int!
  timezone: String!
}

type sensorflow_timezones_aggregate {
  aggregate: sensorflow_timezones_aggregate_fields
  nodes: [sensorflow_timezones!]!
}

type sensorflow_timezones_aggregate_fields {
  avg: sensorflow_timezones_avg_fields
  count(columns: [sensorflow_timezones_select_column!], distinct: Boolean): Int!
  max: sensorflow_timezones_max_fields
  min: sensorflow_timezones_min_fields
  stddev: sensorflow_timezones_stddev_fields
  stddev_pop: sensorflow_timezones_stddev_pop_fields
  stddev_samp: sensorflow_timezones_stddev_samp_fields
  sum: sensorflow_timezones_sum_fields
  var_pop: sensorflow_timezones_var_pop_fields
  var_samp: sensorflow_timezones_var_samp_fields
  variance: sensorflow_timezones_variance_fields
}

type sensorflow_timezones_avg_fields {
  id: Float
}

input sensorflow_timezones_bool_exp {
  _and: [sensorflow_timezones_bool_exp!]
  _not: sensorflow_timezones_bool_exp
  _or: [sensorflow_timezones_bool_exp!]
  id: Int_comparison_exp
  timezone: String_comparison_exp
}

enum sensorflow_timezones_constraint {
  timezones_pkey
  timezones_timezone_udx
}

input sensorflow_timezones_inc_input {
  id: Int
}

input sensorflow_timezones_insert_input {
  id: Int
  timezone: String
}

type sensorflow_timezones_max_fields {
  id: Int
  timezone: String
}

type sensorflow_timezones_min_fields {
  id: Int
  timezone: String
}

type sensorflow_timezones_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_timezones!]!
}

input sensorflow_timezones_on_conflict {
  constraint: sensorflow_timezones_constraint!
  update_columns: [sensorflow_timezones_update_column!]! = []
  where: sensorflow_timezones_bool_exp
}

input sensorflow_timezones_order_by {
  id: order_by
  timezone: order_by
}

input sensorflow_timezones_pk_columns_input {
  id: Int!
}

enum sensorflow_timezones_select_column {
  id
  timezone
}

input sensorflow_timezones_set_input {
  id: Int
  timezone: String
}

type sensorflow_timezones_stddev_fields {
  id: Float
}

type sensorflow_timezones_stddev_pop_fields {
  id: Float
}

type sensorflow_timezones_stddev_samp_fields {
  id: Float
}

input sensorflow_timezones_stream_cursor_input {
  initial_value: sensorflow_timezones_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_timezones_stream_cursor_value_input {
  id: Int
  timezone: String
}

type sensorflow_timezones_sum_fields {
  id: Int
}

enum sensorflow_timezones_update_column {
  id
  timezone
}

input sensorflow_timezones_updates {
  _inc: sensorflow_timezones_inc_input
  _set: sensorflow_timezones_set_input
  where: sensorflow_timezones_bool_exp!
}

type sensorflow_timezones_var_pop_fields {
  id: Float
}

type sensorflow_timezones_var_samp_fields {
  id: Float
}

type sensorflow_timezones_variance_fields {
  id: Float
}

type sensorflow_updatable_nodes {
  current_firmware_version_number: Int
  download_link: String
  firmware_release_id: Int
  location_id: uuid
  node_mac_id: String
  node_sub_type: String
  ota_failed_upgrade_attempts: Int
  target_firmware_release_id: Int
  version_number: Int
}

type sensorflow_updatable_nodes_aggregate {
  aggregate: sensorflow_updatable_nodes_aggregate_fields
  nodes: [sensorflow_updatable_nodes!]!
}

type sensorflow_updatable_nodes_aggregate_fields {
  avg: sensorflow_updatable_nodes_avg_fields
  count(columns: [sensorflow_updatable_nodes_select_column!], distinct: Boolean): Int!
  max: sensorflow_updatable_nodes_max_fields
  min: sensorflow_updatable_nodes_min_fields
  stddev: sensorflow_updatable_nodes_stddev_fields
  stddev_pop: sensorflow_updatable_nodes_stddev_pop_fields
  stddev_samp: sensorflow_updatable_nodes_stddev_samp_fields
  sum: sensorflow_updatable_nodes_sum_fields
  var_pop: sensorflow_updatable_nodes_var_pop_fields
  var_samp: sensorflow_updatable_nodes_var_samp_fields
  variance: sensorflow_updatable_nodes_variance_fields
}

type sensorflow_updatable_nodes_avg_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

input sensorflow_updatable_nodes_bool_exp {
  _and: [sensorflow_updatable_nodes_bool_exp!]
  _not: sensorflow_updatable_nodes_bool_exp
  _or: [sensorflow_updatable_nodes_bool_exp!]
  current_firmware_version_number: Int_comparison_exp
  download_link: String_comparison_exp
  firmware_release_id: Int_comparison_exp
  location_id: uuid_comparison_exp
  node_mac_id: String_comparison_exp
  node_sub_type: String_comparison_exp
  ota_failed_upgrade_attempts: Int_comparison_exp
  target_firmware_release_id: Int_comparison_exp
  version_number: Int_comparison_exp
}

type sensorflow_updatable_nodes_max_fields {
  current_firmware_version_number: Int
  download_link: String
  firmware_release_id: Int
  location_id: uuid
  node_mac_id: String
  node_sub_type: String
  ota_failed_upgrade_attempts: Int
  target_firmware_release_id: Int
  version_number: Int
}

type sensorflow_updatable_nodes_min_fields {
  current_firmware_version_number: Int
  download_link: String
  firmware_release_id: Int
  location_id: uuid
  node_mac_id: String
  node_sub_type: String
  ota_failed_upgrade_attempts: Int
  target_firmware_release_id: Int
  version_number: Int
}

input sensorflow_updatable_nodes_order_by {
  current_firmware_version_number: order_by
  download_link: order_by
  firmware_release_id: order_by
  location_id: order_by
  node_mac_id: order_by
  node_sub_type: order_by
  ota_failed_upgrade_attempts: order_by
  target_firmware_release_id: order_by
  version_number: order_by
}

enum sensorflow_updatable_nodes_select_column {
  current_firmware_version_number
  download_link
  firmware_release_id
  location_id
  node_mac_id
  node_sub_type
  ota_failed_upgrade_attempts
  target_firmware_release_id
  version_number
}

type sensorflow_updatable_nodes_stddev_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

type sensorflow_updatable_nodes_stddev_pop_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

type sensorflow_updatable_nodes_stddev_samp_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

input sensorflow_updatable_nodes_stream_cursor_input {
  initial_value: sensorflow_updatable_nodes_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_updatable_nodes_stream_cursor_value_input {
  current_firmware_version_number: Int
  download_link: String
  firmware_release_id: Int
  location_id: uuid
  node_mac_id: String
  node_sub_type: String
  ota_failed_upgrade_attempts: Int
  target_firmware_release_id: Int
  version_number: Int
}

type sensorflow_updatable_nodes_sum_fields {
  current_firmware_version_number: Int
  firmware_release_id: Int
  ota_failed_upgrade_attempts: Int
  target_firmware_release_id: Int
  version_number: Int
}

type sensorflow_updatable_nodes_var_pop_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

type sensorflow_updatable_nodes_var_samp_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

type sensorflow_updatable_nodes_variance_fields {
  current_firmware_version_number: Float
  firmware_release_id: Float
  ota_failed_upgrade_attempts: Float
  target_firmware_release_id: Float
  version_number: Float
}

type sensorflow_user_to_permission_mapping {
  is_granted: Boolean!
  permission_id: uuid
  user_id: String
}

type sensorflow_user_to_permission_mapping_aggregate {
  aggregate: sensorflow_user_to_permission_mapping_aggregate_fields
  nodes: [sensorflow_user_to_permission_mapping!]!
}

type sensorflow_user_to_permission_mapping_aggregate_fields {
  count(columns: [sensorflow_user_to_permission_mapping_select_column!], distinct: Boolean): Int!
  max: sensorflow_user_to_permission_mapping_max_fields
  min: sensorflow_user_to_permission_mapping_min_fields
}

input sensorflow_user_to_permission_mapping_bool_exp {
  _and: [sensorflow_user_to_permission_mapping_bool_exp!]
  _not: sensorflow_user_to_permission_mapping_bool_exp
  _or: [sensorflow_user_to_permission_mapping_bool_exp!]
  is_granted: Boolean_comparison_exp
  permission_id: uuid_comparison_exp
  user_id: String_comparison_exp
}

input sensorflow_user_to_permission_mapping_insert_input {
  is_granted: Boolean
  permission_id: uuid
  user_id: String
}

type sensorflow_user_to_permission_mapping_max_fields {
  permission_id: uuid
  user_id: String
}

type sensorflow_user_to_permission_mapping_min_fields {
  permission_id: uuid
  user_id: String
}

type sensorflow_user_to_permission_mapping_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_user_to_permission_mapping!]!
}

input sensorflow_user_to_permission_mapping_order_by {
  is_granted: order_by
  permission_id: order_by
  user_id: order_by
}

enum sensorflow_user_to_permission_mapping_select_column {
  is_granted
  permission_id
  user_id
}

input sensorflow_user_to_permission_mapping_set_input {
  is_granted: Boolean
  permission_id: uuid
  user_id: String
}

input sensorflow_user_to_permission_mapping_stream_cursor_input {
  initial_value: sensorflow_user_to_permission_mapping_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_user_to_permission_mapping_stream_cursor_value_input {
  is_granted: Boolean
  permission_id: uuid
  user_id: String
}

input sensorflow_user_to_permission_mapping_updates {
  _set: sensorflow_user_to_permission_mapping_set_input
  where: sensorflow_user_to_permission_mapping_bool_exp!
}

type sensorflow_users {
  comfortplus_onboard_shown: Boolean
  email: String!
  email_verified: String!
  isContractor: Boolean
  isDev: Boolean
  isManager: Boolean
  last_login_timestamp: String
  location_to_user_mappings(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  location_to_user_mappings_aggregate(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): sensorflow_location_to_user_mapping_aggregate!
  name: String!
  role_id: uuid
  status: String!
  userId: String!
}

type sensorflow_users_aggregate {
  aggregate: sensorflow_users_aggregate_fields
  nodes: [sensorflow_users!]!
}

type sensorflow_users_aggregate_fields {
  count(columns: [sensorflow_users_select_column!], distinct: Boolean): Int!
  max: sensorflow_users_max_fields
  min: sensorflow_users_min_fields
}

input sensorflow_users_bool_exp {
  _and: [sensorflow_users_bool_exp!]
  _not: sensorflow_users_bool_exp
  _or: [sensorflow_users_bool_exp!]
  comfortplus_onboard_shown: Boolean_comparison_exp
  email: String_comparison_exp
  email_verified: String_comparison_exp
  isContractor: Boolean_comparison_exp
  isDev: Boolean_comparison_exp
  isManager: Boolean_comparison_exp
  last_login_timestamp: String_comparison_exp
  location_to_user_mappings: sensorflow_location_to_user_mapping_bool_exp
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_bool_exp
  name: String_comparison_exp
  role_id: uuid_comparison_exp
  status: String_comparison_exp
  userId: String_comparison_exp
}

enum sensorflow_users_constraint {
  users_pkey
}

input sensorflow_users_insert_input {
  comfortplus_onboard_shown: Boolean
  email: String
  email_verified: String
  isContractor: Boolean
  isDev: Boolean
  isManager: Boolean
  last_login_timestamp: String
  location_to_user_mappings: sensorflow_location_to_user_mapping_arr_rel_insert_input
  name: String
  role_id: uuid
  status: String
  userId: String
}

type sensorflow_users_max_fields {
  email: String
  email_verified: String
  last_login_timestamp: String
  name: String
  role_id: uuid
  status: String
  userId: String
}

type sensorflow_users_min_fields {
  email: String
  email_verified: String
  last_login_timestamp: String
  name: String
  role_id: uuid
  status: String
  userId: String
}

type sensorflow_users_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_users!]!
}

input sensorflow_users_obj_rel_insert_input {
  data: sensorflow_users_insert_input!
  on_conflict: sensorflow_users_on_conflict
}

input sensorflow_users_on_conflict {
  constraint: sensorflow_users_constraint!
  update_columns: [sensorflow_users_update_column!]! = []
  where: sensorflow_users_bool_exp
}

input sensorflow_users_order_by {
  comfortplus_onboard_shown: order_by
  email: order_by
  email_verified: order_by
  isContractor: order_by
  isDev: order_by
  isManager: order_by
  last_login_timestamp: order_by
  location_to_user_mappings_aggregate: sensorflow_location_to_user_mapping_aggregate_order_by
  name: order_by
  role_id: order_by
  status: order_by
  userId: order_by
}

input sensorflow_users_pk_columns_input {
  userId: String!
}

enum sensorflow_users_select_column {
  comfortplus_onboard_shown
  email
  email_verified
  isContractor
  isDev
  isManager
  last_login_timestamp
  name
  role_id
  status
  userId
}

input sensorflow_users_set_input {
  comfortplus_onboard_shown: Boolean
  email: String
  email_verified: String
  isContractor: Boolean
  isDev: Boolean
  isManager: Boolean
  last_login_timestamp: String
  name: String
  role_id: uuid
  status: String
  userId: String
}

input sensorflow_users_stream_cursor_input {
  initial_value: sensorflow_users_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_users_stream_cursor_value_input {
  comfortplus_onboard_shown: Boolean
  email: String
  email_verified: String
  isContractor: Boolean
  isDev: Boolean
  isManager: Boolean
  last_login_timestamp: String
  name: String
  role_id: uuid
  status: String
  userId: String
}

enum sensorflow_users_update_column {
  comfortplus_onboard_shown
  email
  email_verified
  isContractor
  isDev
  isManager
  last_login_timestamp
  name
  role_id
  status
  userId
}

input sensorflow_users_updates {
  _set: sensorflow_users_set_input
  where: sensorflow_users_bool_exp!
}

type sensorflow_v_all_latest_node_configurations {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  balenaId: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  decommissionedTime: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  dot_enabled: Boolean
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  gatewayMac: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  identifier: Int
  isDeviatedFromDefaultConfig: Boolean
  isPrimaryNode: Boolean
  keyId: uuid
  lastUpdated: timestamptz
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nighttime_setpoint_delay: Int
  nighttime_setpoint_dow: smallint
  nighttime_setpoint_enabled: Boolean
  nighttime_setpoint_end: String
  nighttime_setpoint_max_setpoint: Int
  nighttime_setpoint_offset: Int
  nighttime_setpoint_start: String
  nodeMacId: String
  nodeType: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
}

type sensorflow_v_all_latest_node_configurations_aggregate {
  aggregate: sensorflow_v_all_latest_node_configurations_aggregate_fields
  nodes: [sensorflow_v_all_latest_node_configurations!]!
}

type sensorflow_v_all_latest_node_configurations_aggregate_fields {
  avg: sensorflow_v_all_latest_node_configurations_avg_fields
  count(columns: [sensorflow_v_all_latest_node_configurations_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_all_latest_node_configurations_max_fields
  min: sensorflow_v_all_latest_node_configurations_min_fields
  stddev: sensorflow_v_all_latest_node_configurations_stddev_fields
  stddev_pop: sensorflow_v_all_latest_node_configurations_stddev_pop_fields
  stddev_samp: sensorflow_v_all_latest_node_configurations_stddev_samp_fields
  sum: sensorflow_v_all_latest_node_configurations_sum_fields
  var_pop: sensorflow_v_all_latest_node_configurations_var_pop_fields
  var_samp: sensorflow_v_all_latest_node_configurations_var_samp_fields
  variance: sensorflow_v_all_latest_node_configurations_variance_fields
}

type sensorflow_v_all_latest_node_configurations_avg_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input sensorflow_v_all_latest_node_configurations_bool_exp {
  _and: [sensorflow_v_all_latest_node_configurations_bool_exp!]
  _not: sensorflow_v_all_latest_node_configurations_bool_exp
  _or: [sensorflow_v_all_latest_node_configurations_bool_exp!]
  acModelId: uuid_comparison_exp
  acNightEnd: String_comparison_exp
  acNightStart: String_comparison_exp
  actingMode: String_comparison_exp
  actuationTimeoutSeconds: Int_comparison_exp
  automationMode: String_comparison_exp
  balenaId: String_comparison_exp
  bridgeAdaptorSetting: Int_comparison_exp
  creationDate: timestamptz_comparison_exp
  decommissionedTime: timestamptz_comparison_exp
  desiredEmonSwitchPosition: Int_comparison_exp
  doorSensorTimeoutMins: Int_comparison_exp
  dot_enabled: Boolean_comparison_exp
  enableDoorSensor: Boolean_comparison_exp
  expiredAt: timestamptz_comparison_exp
  forceOccupancyAutomation: Boolean_comparison_exp
  freezeProtectionTemp: Int_comparison_exp
  gatewayMac: Int_comparison_exp
  heatingHideSetpoint: Boolean_comparison_exp
  heatingMaxTemp: Int_comparison_exp
  heatingMinTemp: Int_comparison_exp
  heatingUnoccupiedTemp: Int_comparison_exp
  hideSetpoint: Boolean_comparison_exp
  hysteresisOffDegrees: float8_comparison_exp
  hysteresisOnDegrees: float8_comparison_exp
  id: uuid_comparison_exp
  identifier: Int_comparison_exp
  isDeviatedFromDefaultConfig: Boolean_comparison_exp
  isPrimaryNode: Boolean_comparison_exp
  keyId: uuid_comparison_exp
  lastUpdated: timestamptz_comparison_exp
  locationId: uuid_comparison_exp
  maxTemp: Int_comparison_exp
  minTemp: Int_comparison_exp
  modifiedByUserId: String_comparison_exp
  nighttime_setpoint_delay: Int_comparison_exp
  nighttime_setpoint_dow: smallint_comparison_exp
  nighttime_setpoint_enabled: Boolean_comparison_exp
  nighttime_setpoint_end: String_comparison_exp
  nighttime_setpoint_max_setpoint: Int_comparison_exp
  nighttime_setpoint_offset: Int_comparison_exp
  nighttime_setpoint_start: String_comparison_exp
  nodeMacId: String_comparison_exp
  nodeType: String_comparison_exp
  occNightEnd: String_comparison_exp
  occNightStart: String_comparison_exp
  occTimeoutDayMins: Int_comparison_exp
  occTimeoutNightMins: Int_comparison_exp
  occupancySensitivity: Int_comparison_exp
  operationalMode: String_comparison_exp
  positionId: uuid_comparison_exp
  recordType: String_comparison_exp
  softstartWaitTime: Int_comparison_exp
  specialInstallationMode: Boolean_comparison_exp
  unoccupiedTemp: Int_comparison_exp
}

type sensorflow_v_all_latest_node_configurations_max_fields {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  balenaId: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  decommissionedTime: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  gatewayMac: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  identifier: Int
  keyId: uuid
  lastUpdated: timestamptz
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nighttime_setpoint_delay: Int
  nighttime_setpoint_dow: smallint
  nighttime_setpoint_end: String
  nighttime_setpoint_max_setpoint: Int
  nighttime_setpoint_offset: Int
  nighttime_setpoint_start: String
  nodeMacId: String
  nodeType: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

type sensorflow_v_all_latest_node_configurations_min_fields {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  balenaId: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  decommissionedTime: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  gatewayMac: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  identifier: Int
  keyId: uuid
  lastUpdated: timestamptz
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nighttime_setpoint_delay: Int
  nighttime_setpoint_dow: smallint
  nighttime_setpoint_end: String
  nighttime_setpoint_max_setpoint: Int
  nighttime_setpoint_offset: Int
  nighttime_setpoint_start: String
  nodeMacId: String
  nodeType: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

input sensorflow_v_all_latest_node_configurations_order_by {
  acModelId: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  balenaId: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  decommissionedTime: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  dot_enabled: order_by
  enableDoorSensor: order_by
  expiredAt: order_by
  forceOccupancyAutomation: order_by
  freezeProtectionTemp: order_by
  gatewayMac: order_by
  heatingHideSetpoint: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hideSetpoint: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  identifier: order_by
  isDeviatedFromDefaultConfig: order_by
  isPrimaryNode: order_by
  keyId: order_by
  lastUpdated: order_by
  locationId: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUserId: order_by
  nighttime_setpoint_delay: order_by
  nighttime_setpoint_dow: order_by
  nighttime_setpoint_enabled: order_by
  nighttime_setpoint_end: order_by
  nighttime_setpoint_max_setpoint: order_by
  nighttime_setpoint_offset: order_by
  nighttime_setpoint_start: order_by
  nodeMacId: order_by
  nodeType: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  specialInstallationMode: order_by
  unoccupiedTemp: order_by
}

enum sensorflow_v_all_latest_node_configurations_select_column {
  acModelId
  acNightEnd
  acNightStart
  actingMode
  actuationTimeoutSeconds
  automationMode
  balenaId
  bridgeAdaptorSetting
  creationDate
  decommissionedTime
  desiredEmonSwitchPosition
  doorSensorTimeoutMins
  dot_enabled
  enableDoorSensor
  expiredAt
  forceOccupancyAutomation
  freezeProtectionTemp
  gatewayMac
  heatingHideSetpoint
  heatingMaxTemp
  heatingMinTemp
  heatingUnoccupiedTemp
  hideSetpoint
  hysteresisOffDegrees
  hysteresisOnDegrees
  id
  identifier
  isDeviatedFromDefaultConfig
  isPrimaryNode
  keyId
  lastUpdated
  locationId
  maxTemp
  minTemp
  modifiedByUserId
  nighttime_setpoint_delay
  nighttime_setpoint_dow
  nighttime_setpoint_enabled
  nighttime_setpoint_end
  nighttime_setpoint_max_setpoint
  nighttime_setpoint_offset
  nighttime_setpoint_start
  nodeMacId
  nodeType
  occNightEnd
  occNightStart
  occTimeoutDayMins
  occTimeoutNightMins
  occupancySensitivity
  operationalMode
  positionId
  recordType
  softstartWaitTime
  specialInstallationMode
  unoccupiedTemp
}

type sensorflow_v_all_latest_node_configurations_stddev_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_all_latest_node_configurations_stddev_pop_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_all_latest_node_configurations_stddev_samp_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input sensorflow_v_all_latest_node_configurations_stream_cursor_input {
  initial_value: sensorflow_v_all_latest_node_configurations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_all_latest_node_configurations_stream_cursor_value_input {
  acModelId: uuid
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  balenaId: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  decommissionedTime: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  dot_enabled: Boolean
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  gatewayMac: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  identifier: Int
  isDeviatedFromDefaultConfig: Boolean
  isPrimaryNode: Boolean
  keyId: uuid
  lastUpdated: timestamptz
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  nighttime_setpoint_delay: Int
  nighttime_setpoint_dow: smallint
  nighttime_setpoint_enabled: Boolean
  nighttime_setpoint_end: String
  nighttime_setpoint_max_setpoint: Int
  nighttime_setpoint_offset: Int
  nighttime_setpoint_start: String
  nodeMacId: String
  nodeType: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
}

type sensorflow_v_all_latest_node_configurations_sum_fields {
  actuationTimeoutSeconds: Int
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  freezeProtectionTemp: Int
  gatewayMac: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  identifier: Int
  maxTemp: Int
  minTemp: Int
  nighttime_setpoint_delay: Int
  nighttime_setpoint_dow: smallint
  nighttime_setpoint_max_setpoint: Int
  nighttime_setpoint_offset: Int
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

type sensorflow_v_all_latest_node_configurations_var_pop_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_all_latest_node_configurations_var_samp_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_all_latest_node_configurations_variance_fields {
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  gatewayMac: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  identifier: Float
  maxTemp: Float
  minTemp: Float
  nighttime_setpoint_delay: Float
  nighttime_setpoint_dow: Float
  nighttime_setpoint_max_setpoint: Float
  nighttime_setpoint_offset: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_combined_user_roles {
  display_name: String
  inherited_from: String
  permission_key: String
  user_id: String
}

type sensorflow_v_combined_user_roles_aggregate {
  aggregate: sensorflow_v_combined_user_roles_aggregate_fields
  nodes: [sensorflow_v_combined_user_roles!]!
}

type sensorflow_v_combined_user_roles_aggregate_fields {
  count(columns: [sensorflow_v_combined_user_roles_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_combined_user_roles_max_fields
  min: sensorflow_v_combined_user_roles_min_fields
}

input sensorflow_v_combined_user_roles_bool_exp {
  _and: [sensorflow_v_combined_user_roles_bool_exp!]
  _not: sensorflow_v_combined_user_roles_bool_exp
  _or: [sensorflow_v_combined_user_roles_bool_exp!]
  display_name: String_comparison_exp
  inherited_from: String_comparison_exp
  permission_key: String_comparison_exp
  user_id: String_comparison_exp
}

type sensorflow_v_combined_user_roles_max_fields {
  display_name: String
  inherited_from: String
  permission_key: String
  user_id: String
}

type sensorflow_v_combined_user_roles_min_fields {
  display_name: String
  inherited_from: String
  permission_key: String
  user_id: String
}

input sensorflow_v_combined_user_roles_order_by {
  display_name: order_by
  inherited_from: order_by
  permission_key: order_by
  user_id: order_by
}

enum sensorflow_v_combined_user_roles_select_column {
  display_name
  inherited_from
  permission_key
  user_id
}

input sensorflow_v_combined_user_roles_stream_cursor_input {
  initial_value: sensorflow_v_combined_user_roles_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_combined_user_roles_stream_cursor_value_input {
  display_name: String
  inherited_from: String
  permission_key: String
  user_id: String
}

type sensorflow_v_comfortplus_key_details {
  activation_id: uuid
  checkInDate: timestamptz
  checkOutDate: timestamptz
  locationName: String
  location_id: uuid
  logoUrl: String
  onboarded: Boolean
  positionId: uuid
  positionName: String
  reservationId: bigint
  timezone: String
  userId: String
}

type sensorflow_v_comfortplus_key_details_aggregate {
  aggregate: sensorflow_v_comfortplus_key_details_aggregate_fields
  nodes: [sensorflow_v_comfortplus_key_details!]!
}

type sensorflow_v_comfortplus_key_details_aggregate_fields {
  avg: sensorflow_v_comfortplus_key_details_avg_fields
  count(columns: [sensorflow_v_comfortplus_key_details_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_comfortplus_key_details_max_fields
  min: sensorflow_v_comfortplus_key_details_min_fields
  stddev: sensorflow_v_comfortplus_key_details_stddev_fields
  stddev_pop: sensorflow_v_comfortplus_key_details_stddev_pop_fields
  stddev_samp: sensorflow_v_comfortplus_key_details_stddev_samp_fields
  sum: sensorflow_v_comfortplus_key_details_sum_fields
  var_pop: sensorflow_v_comfortplus_key_details_var_pop_fields
  var_samp: sensorflow_v_comfortplus_key_details_var_samp_fields
  variance: sensorflow_v_comfortplus_key_details_variance_fields
}

type sensorflow_v_comfortplus_key_details_avg_fields {
  reservationId: Float
}

input sensorflow_v_comfortplus_key_details_bool_exp {
  _and: [sensorflow_v_comfortplus_key_details_bool_exp!]
  _not: sensorflow_v_comfortplus_key_details_bool_exp
  _or: [sensorflow_v_comfortplus_key_details_bool_exp!]
  activation_id: uuid_comparison_exp
  checkInDate: timestamptz_comparison_exp
  checkOutDate: timestamptz_comparison_exp
  locationName: String_comparison_exp
  location_id: uuid_comparison_exp
  logoUrl: String_comparison_exp
  onboarded: Boolean_comparison_exp
  positionId: uuid_comparison_exp
  positionName: String_comparison_exp
  reservationId: bigint_comparison_exp
  timezone: String_comparison_exp
  userId: String_comparison_exp
}

type sensorflow_v_comfortplus_key_details_max_fields {
  activation_id: uuid
  checkInDate: timestamptz
  checkOutDate: timestamptz
  locationName: String
  location_id: uuid
  logoUrl: String
  positionId: uuid
  positionName: String
  reservationId: bigint
  timezone: String
  userId: String
}

type sensorflow_v_comfortplus_key_details_min_fields {
  activation_id: uuid
  checkInDate: timestamptz
  checkOutDate: timestamptz
  locationName: String
  location_id: uuid
  logoUrl: String
  positionId: uuid
  positionName: String
  reservationId: bigint
  timezone: String
  userId: String
}

input sensorflow_v_comfortplus_key_details_order_by {
  activation_id: order_by
  checkInDate: order_by
  checkOutDate: order_by
  locationName: order_by
  location_id: order_by
  logoUrl: order_by
  onboarded: order_by
  positionId: order_by
  positionName: order_by
  reservationId: order_by
  timezone: order_by
  userId: order_by
}

enum sensorflow_v_comfortplus_key_details_select_column {
  activation_id
  checkInDate
  checkOutDate
  locationName
  location_id
  logoUrl
  onboarded
  positionId
  positionName
  reservationId
  timezone
  userId
}

type sensorflow_v_comfortplus_key_details_stddev_fields {
  reservationId: Float
}

type sensorflow_v_comfortplus_key_details_stddev_pop_fields {
  reservationId: Float
}

type sensorflow_v_comfortplus_key_details_stddev_samp_fields {
  reservationId: Float
}

input sensorflow_v_comfortplus_key_details_stream_cursor_input {
  initial_value: sensorflow_v_comfortplus_key_details_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_comfortplus_key_details_stream_cursor_value_input {
  activation_id: uuid
  checkInDate: timestamptz
  checkOutDate: timestamptz
  locationName: String
  location_id: uuid
  logoUrl: String
  onboarded: Boolean
  positionId: uuid
  positionName: String
  reservationId: bigint
  timezone: String
  userId: String
}

type sensorflow_v_comfortplus_key_details_sum_fields {
  reservationId: bigint
}

type sensorflow_v_comfortplus_key_details_var_pop_fields {
  reservationId: Float
}

type sensorflow_v_comfortplus_key_details_var_samp_fields {
  reservationId: Float
}

type sensorflow_v_comfortplus_key_details_variance_fields {
  reservationId: Float
}

type sensorflow_v_comfortplus_key_measurements {
  acMode: numeric
  activationId: uuid
  ambientTemperature: numeric
  fanSpeed: numeric
  keyPositionId: uuid
  lastPrecoolSuccessTime: timestamptz
  precoolPendingCount: bigint
  roomId: uuid
  roomName: String
  scheduledCount: bigint
  userId: String
}

type sensorflow_v_comfortplus_key_measurements_aggregate {
  aggregate: sensorflow_v_comfortplus_key_measurements_aggregate_fields
  nodes: [sensorflow_v_comfortplus_key_measurements!]!
}

type sensorflow_v_comfortplus_key_measurements_aggregate_fields {
  avg: sensorflow_v_comfortplus_key_measurements_avg_fields
  count(columns: [sensorflow_v_comfortplus_key_measurements_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_comfortplus_key_measurements_max_fields
  min: sensorflow_v_comfortplus_key_measurements_min_fields
  stddev: sensorflow_v_comfortplus_key_measurements_stddev_fields
  stddev_pop: sensorflow_v_comfortplus_key_measurements_stddev_pop_fields
  stddev_samp: sensorflow_v_comfortplus_key_measurements_stddev_samp_fields
  sum: sensorflow_v_comfortplus_key_measurements_sum_fields
  var_pop: sensorflow_v_comfortplus_key_measurements_var_pop_fields
  var_samp: sensorflow_v_comfortplus_key_measurements_var_samp_fields
  variance: sensorflow_v_comfortplus_key_measurements_variance_fields
}

type sensorflow_v_comfortplus_key_measurements_avg_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

input sensorflow_v_comfortplus_key_measurements_bool_exp {
  _and: [sensorflow_v_comfortplus_key_measurements_bool_exp!]
  _not: sensorflow_v_comfortplus_key_measurements_bool_exp
  _or: [sensorflow_v_comfortplus_key_measurements_bool_exp!]
  acMode: numeric_comparison_exp
  activationId: uuid_comparison_exp
  ambientTemperature: numeric_comparison_exp
  fanSpeed: numeric_comparison_exp
  keyPositionId: uuid_comparison_exp
  lastPrecoolSuccessTime: timestamptz_comparison_exp
  precoolPendingCount: bigint_comparison_exp
  roomId: uuid_comparison_exp
  roomName: String_comparison_exp
  scheduledCount: bigint_comparison_exp
  userId: String_comparison_exp
}

type sensorflow_v_comfortplus_key_measurements_max_fields {
  acMode: numeric
  activationId: uuid
  ambientTemperature: numeric
  fanSpeed: numeric
  keyPositionId: uuid
  lastPrecoolSuccessTime: timestamptz
  precoolPendingCount: bigint
  roomId: uuid
  roomName: String
  scheduledCount: bigint
  userId: String
}

type sensorflow_v_comfortplus_key_measurements_min_fields {
  acMode: numeric
  activationId: uuid
  ambientTemperature: numeric
  fanSpeed: numeric
  keyPositionId: uuid
  lastPrecoolSuccessTime: timestamptz
  precoolPendingCount: bigint
  roomId: uuid
  roomName: String
  scheduledCount: bigint
  userId: String
}

input sensorflow_v_comfortplus_key_measurements_order_by {
  acMode: order_by
  activationId: order_by
  ambientTemperature: order_by
  fanSpeed: order_by
  keyPositionId: order_by
  lastPrecoolSuccessTime: order_by
  precoolPendingCount: order_by
  roomId: order_by
  roomName: order_by
  scheduledCount: order_by
  userId: order_by
}

enum sensorflow_v_comfortplus_key_measurements_select_column {
  acMode
  activationId
  ambientTemperature
  fanSpeed
  keyPositionId
  lastPrecoolSuccessTime
  precoolPendingCount
  roomId
  roomName
  scheduledCount
  userId
}

type sensorflow_v_comfortplus_key_measurements_stddev_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

type sensorflow_v_comfortplus_key_measurements_stddev_pop_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

type sensorflow_v_comfortplus_key_measurements_stddev_samp_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

input sensorflow_v_comfortplus_key_measurements_stream_cursor_input {
  initial_value: sensorflow_v_comfortplus_key_measurements_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_comfortplus_key_measurements_stream_cursor_value_input {
  acMode: numeric
  activationId: uuid
  ambientTemperature: numeric
  fanSpeed: numeric
  keyPositionId: uuid
  lastPrecoolSuccessTime: timestamptz
  precoolPendingCount: bigint
  roomId: uuid
  roomName: String
  scheduledCount: bigint
  userId: String
}

type sensorflow_v_comfortplus_key_measurements_sum_fields {
  acMode: numeric
  ambientTemperature: numeric
  fanSpeed: numeric
  precoolPendingCount: bigint
  scheduledCount: bigint
}

type sensorflow_v_comfortplus_key_measurements_var_pop_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

type sensorflow_v_comfortplus_key_measurements_var_samp_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

type sensorflow_v_comfortplus_key_measurements_variance_fields {
  acMode: Float
  ambientTemperature: Float
  fanSpeed: Float
  precoolPendingCount: Float
  scheduledCount: Float
}

type sensorflow_v_comfortplus_precool_status {
  activation_id: uuid
  failMessage: String
  mode: Int
  positionName: String
  startedAt: timestamptz
  status: String
  totalMinutes: Int
  user_id: String
}

type sensorflow_v_comfortplus_precool_status_aggregate {
  aggregate: sensorflow_v_comfortplus_precool_status_aggregate_fields
  nodes: [sensorflow_v_comfortplus_precool_status!]!
}

type sensorflow_v_comfortplus_precool_status_aggregate_fields {
  avg: sensorflow_v_comfortplus_precool_status_avg_fields
  count(columns: [sensorflow_v_comfortplus_precool_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_comfortplus_precool_status_max_fields
  min: sensorflow_v_comfortplus_precool_status_min_fields
  stddev: sensorflow_v_comfortplus_precool_status_stddev_fields
  stddev_pop: sensorflow_v_comfortplus_precool_status_stddev_pop_fields
  stddev_samp: sensorflow_v_comfortplus_precool_status_stddev_samp_fields
  sum: sensorflow_v_comfortplus_precool_status_sum_fields
  var_pop: sensorflow_v_comfortplus_precool_status_var_pop_fields
  var_samp: sensorflow_v_comfortplus_precool_status_var_samp_fields
  variance: sensorflow_v_comfortplus_precool_status_variance_fields
}

type sensorflow_v_comfortplus_precool_status_avg_fields {
  mode: Float
  totalMinutes: Float
}

input sensorflow_v_comfortplus_precool_status_bool_exp {
  _and: [sensorflow_v_comfortplus_precool_status_bool_exp!]
  _not: sensorflow_v_comfortplus_precool_status_bool_exp
  _or: [sensorflow_v_comfortplus_precool_status_bool_exp!]
  activation_id: uuid_comparison_exp
  failMessage: String_comparison_exp
  mode: Int_comparison_exp
  positionName: String_comparison_exp
  startedAt: timestamptz_comparison_exp
  status: String_comparison_exp
  totalMinutes: Int_comparison_exp
  user_id: String_comparison_exp
}

type sensorflow_v_comfortplus_precool_status_max_fields {
  activation_id: uuid
  failMessage: String
  mode: Int
  positionName: String
  startedAt: timestamptz
  status: String
  totalMinutes: Int
  user_id: String
}

type sensorflow_v_comfortplus_precool_status_min_fields {
  activation_id: uuid
  failMessage: String
  mode: Int
  positionName: String
  startedAt: timestamptz
  status: String
  totalMinutes: Int
  user_id: String
}

input sensorflow_v_comfortplus_precool_status_order_by {
  activation_id: order_by
  failMessage: order_by
  mode: order_by
  positionName: order_by
  startedAt: order_by
  status: order_by
  totalMinutes: order_by
  user_id: order_by
}

enum sensorflow_v_comfortplus_precool_status_select_column {
  activation_id
  failMessage
  mode
  positionName
  startedAt
  status
  totalMinutes
  user_id
}

type sensorflow_v_comfortplus_precool_status_stddev_fields {
  mode: Float
  totalMinutes: Float
}

type sensorflow_v_comfortplus_precool_status_stddev_pop_fields {
  mode: Float
  totalMinutes: Float
}

type sensorflow_v_comfortplus_precool_status_stddev_samp_fields {
  mode: Float
  totalMinutes: Float
}

input sensorflow_v_comfortplus_precool_status_stream_cursor_input {
  initial_value: sensorflow_v_comfortplus_precool_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_comfortplus_precool_status_stream_cursor_value_input {
  activation_id: uuid
  failMessage: String
  mode: Int
  positionName: String
  startedAt: timestamptz
  status: String
  totalMinutes: Int
  user_id: String
}

type sensorflow_v_comfortplus_precool_status_sum_fields {
  mode: Int
  totalMinutes: Int
}

type sensorflow_v_comfortplus_precool_status_var_pop_fields {
  mode: Float
  totalMinutes: Float
}

type sensorflow_v_comfortplus_precool_status_var_samp_fields {
  mode: Float
  totalMinutes: Float
}

type sensorflow_v_comfortplus_precool_status_variance_fields {
  mode: Float
  totalMinutes: Float
}

type sensorflow_v_current_position_configurations {
  acModelId: uuid
  acModelIdentifier: Int
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
}

type sensorflow_v_current_position_configurations_aggregate {
  aggregate: sensorflow_v_current_position_configurations_aggregate_fields
  nodes: [sensorflow_v_current_position_configurations!]!
}

type sensorflow_v_current_position_configurations_aggregate_fields {
  avg: sensorflow_v_current_position_configurations_avg_fields
  count(columns: [sensorflow_v_current_position_configurations_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_current_position_configurations_max_fields
  min: sensorflow_v_current_position_configurations_min_fields
  stddev: sensorflow_v_current_position_configurations_stddev_fields
  stddev_pop: sensorflow_v_current_position_configurations_stddev_pop_fields
  stddev_samp: sensorflow_v_current_position_configurations_stddev_samp_fields
  sum: sensorflow_v_current_position_configurations_sum_fields
  var_pop: sensorflow_v_current_position_configurations_var_pop_fields
  var_samp: sensorflow_v_current_position_configurations_var_samp_fields
  variance: sensorflow_v_current_position_configurations_variance_fields
}

type sensorflow_v_current_position_configurations_avg_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input sensorflow_v_current_position_configurations_bool_exp {
  _and: [sensorflow_v_current_position_configurations_bool_exp!]
  _not: sensorflow_v_current_position_configurations_bool_exp
  _or: [sensorflow_v_current_position_configurations_bool_exp!]
  acModelId: uuid_comparison_exp
  acModelIdentifier: Int_comparison_exp
  acNightEnd: String_comparison_exp
  acNightStart: String_comparison_exp
  actingMode: String_comparison_exp
  actuationTimeoutSeconds: Int_comparison_exp
  automationMode: String_comparison_exp
  bridgeAdaptorSetting: Int_comparison_exp
  creationDate: timestamptz_comparison_exp
  desiredEmonSwitchPosition: Int_comparison_exp
  doorSensorTimeoutMins: Int_comparison_exp
  enableDoorSensor: Boolean_comparison_exp
  expiredAt: timestamptz_comparison_exp
  forceOccupancyAutomation: Boolean_comparison_exp
  freezeProtectionTemp: Int_comparison_exp
  heatingHideSetpoint: Boolean_comparison_exp
  heatingMaxTemp: Int_comparison_exp
  heatingMinTemp: Int_comparison_exp
  heatingUnoccupiedTemp: Int_comparison_exp
  hideSetpoint: Boolean_comparison_exp
  hysteresisOffDegrees: float8_comparison_exp
  hysteresisOnDegrees: float8_comparison_exp
  id: uuid_comparison_exp
  isDeviatedFromDefaultConfig: Boolean_comparison_exp
  locationId: uuid_comparison_exp
  maxTemp: Int_comparison_exp
  minTemp: Int_comparison_exp
  modifiedByUserId: String_comparison_exp
  occNightEnd: String_comparison_exp
  occNightStart: String_comparison_exp
  occTimeoutDayMins: Int_comparison_exp
  occTimeoutNightMins: Int_comparison_exp
  occupancySensitivity: Int_comparison_exp
  operationalMode: String_comparison_exp
  positionId: uuid_comparison_exp
  recordType: String_comparison_exp
  softstartWaitTime: Int_comparison_exp
  specialInstallationMode: Boolean_comparison_exp
  unoccupiedTemp: Int_comparison_exp
}

type sensorflow_v_current_position_configurations_max_fields {
  acModelId: uuid
  acModelIdentifier: Int
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

type sensorflow_v_current_position_configurations_min_fields {
  acModelId: uuid
  acModelIdentifier: Int
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  expiredAt: timestamptz
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

input sensorflow_v_current_position_configurations_order_by {
  acModelId: order_by
  acModelIdentifier: order_by
  acNightEnd: order_by
  acNightStart: order_by
  actingMode: order_by
  actuationTimeoutSeconds: order_by
  automationMode: order_by
  bridgeAdaptorSetting: order_by
  creationDate: order_by
  desiredEmonSwitchPosition: order_by
  doorSensorTimeoutMins: order_by
  enableDoorSensor: order_by
  expiredAt: order_by
  forceOccupancyAutomation: order_by
  freezeProtectionTemp: order_by
  heatingHideSetpoint: order_by
  heatingMaxTemp: order_by
  heatingMinTemp: order_by
  heatingUnoccupiedTemp: order_by
  hideSetpoint: order_by
  hysteresisOffDegrees: order_by
  hysteresisOnDegrees: order_by
  id: order_by
  isDeviatedFromDefaultConfig: order_by
  locationId: order_by
  maxTemp: order_by
  minTemp: order_by
  modifiedByUserId: order_by
  occNightEnd: order_by
  occNightStart: order_by
  occTimeoutDayMins: order_by
  occTimeoutNightMins: order_by
  occupancySensitivity: order_by
  operationalMode: order_by
  positionId: order_by
  recordType: order_by
  softstartWaitTime: order_by
  specialInstallationMode: order_by
  unoccupiedTemp: order_by
}

enum sensorflow_v_current_position_configurations_select_column {
  acModelId
  acModelIdentifier
  acNightEnd
  acNightStart
  actingMode
  actuationTimeoutSeconds
  automationMode
  bridgeAdaptorSetting
  creationDate
  desiredEmonSwitchPosition
  doorSensorTimeoutMins
  enableDoorSensor
  expiredAt
  forceOccupancyAutomation
  freezeProtectionTemp
  heatingHideSetpoint
  heatingMaxTemp
  heatingMinTemp
  heatingUnoccupiedTemp
  hideSetpoint
  hysteresisOffDegrees
  hysteresisOnDegrees
  id
  isDeviatedFromDefaultConfig
  locationId
  maxTemp
  minTemp
  modifiedByUserId
  occNightEnd
  occNightStart
  occTimeoutDayMins
  occTimeoutNightMins
  occupancySensitivity
  operationalMode
  positionId
  recordType
  softstartWaitTime
  specialInstallationMode
  unoccupiedTemp
}

type sensorflow_v_current_position_configurations_stddev_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_current_position_configurations_stddev_pop_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_current_position_configurations_stddev_samp_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

input sensorflow_v_current_position_configurations_stream_cursor_input {
  initial_value: sensorflow_v_current_position_configurations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_current_position_configurations_stream_cursor_value_input {
  acModelId: uuid
  acModelIdentifier: Int
  acNightEnd: String
  acNightStart: String
  actingMode: String
  actuationTimeoutSeconds: Int
  automationMode: String
  bridgeAdaptorSetting: Int
  creationDate: timestamptz
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  enableDoorSensor: Boolean
  expiredAt: timestamptz
  forceOccupancyAutomation: Boolean
  freezeProtectionTemp: Int
  heatingHideSetpoint: Boolean
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hideSetpoint: Boolean
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  id: uuid
  isDeviatedFromDefaultConfig: Boolean
  locationId: uuid
  maxTemp: Int
  minTemp: Int
  modifiedByUserId: String
  occNightEnd: String
  occNightStart: String
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  operationalMode: String
  positionId: uuid
  recordType: String
  softstartWaitTime: Int
  specialInstallationMode: Boolean
  unoccupiedTemp: Int
}

type sensorflow_v_current_position_configurations_sum_fields {
  acModelIdentifier: Int
  actuationTimeoutSeconds: Int
  bridgeAdaptorSetting: Int
  desiredEmonSwitchPosition: Int
  doorSensorTimeoutMins: Int
  freezeProtectionTemp: Int
  heatingMaxTemp: Int
  heatingMinTemp: Int
  heatingUnoccupiedTemp: Int
  hysteresisOffDegrees: float8
  hysteresisOnDegrees: float8
  maxTemp: Int
  minTemp: Int
  occTimeoutDayMins: Int
  occTimeoutNightMins: Int
  occupancySensitivity: Int
  softstartWaitTime: Int
  unoccupiedTemp: Int
}

type sensorflow_v_current_position_configurations_var_pop_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_current_position_configurations_var_samp_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_current_position_configurations_variance_fields {
  acModelIdentifier: Float
  actuationTimeoutSeconds: Float
  bridgeAdaptorSetting: Float
  desiredEmonSwitchPosition: Float
  doorSensorTimeoutMins: Float
  freezeProtectionTemp: Float
  heatingMaxTemp: Float
  heatingMinTemp: Float
  heatingUnoccupiedTemp: Float
  hysteresisOffDegrees: Float
  hysteresisOnDegrees: Float
  maxTemp: Float
  minTemp: Float
  occTimeoutDayMins: Float
  occTimeoutNightMins: Float
  occupancySensitivity: Float
  softstartWaitTime: Float
  unoccupiedTemp: Float
}

type sensorflow_v_group_tasks {
  keysCount: bigint
  locationId: uuid
  openJobEntryCount: bigint
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

type sensorflow_v_group_tasks_aggregate {
  aggregate: sensorflow_v_group_tasks_aggregate_fields
  nodes: [sensorflow_v_group_tasks!]!
}

type sensorflow_v_group_tasks_aggregate_fields {
  avg: sensorflow_v_group_tasks_avg_fields
  count(columns: [sensorflow_v_group_tasks_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_group_tasks_max_fields
  min: sensorflow_v_group_tasks_min_fields
  stddev: sensorflow_v_group_tasks_stddev_fields
  stddev_pop: sensorflow_v_group_tasks_stddev_pop_fields
  stddev_samp: sensorflow_v_group_tasks_stddev_samp_fields
  sum: sensorflow_v_group_tasks_sum_fields
  var_pop: sensorflow_v_group_tasks_var_pop_fields
  var_samp: sensorflow_v_group_tasks_var_samp_fields
  variance: sensorflow_v_group_tasks_variance_fields
}

type sensorflow_v_group_tasks_avg_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

input sensorflow_v_group_tasks_bool_exp {
  _and: [sensorflow_v_group_tasks_bool_exp!]
  _not: sensorflow_v_group_tasks_bool_exp
  _or: [sensorflow_v_group_tasks_bool_exp!]
  keysCount: bigint_comparison_exp
  locationId: uuid_comparison_exp
  openJobEntryCount: bigint_comparison_exp
  positionId: uuid_comparison_exp
  positionName: String_comparison_exp
  positionType: String_comparison_exp
  roomsCount: bigint_comparison_exp
}

type sensorflow_v_group_tasks_max_fields {
  keysCount: bigint
  locationId: uuid
  openJobEntryCount: bigint
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

type sensorflow_v_group_tasks_min_fields {
  keysCount: bigint
  locationId: uuid
  openJobEntryCount: bigint
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

input sensorflow_v_group_tasks_order_by {
  keysCount: order_by
  locationId: order_by
  openJobEntryCount: order_by
  positionId: order_by
  positionName: order_by
  positionType: order_by
  roomsCount: order_by
}

enum sensorflow_v_group_tasks_select_column {
  keysCount
  locationId
  openJobEntryCount
  positionId
  positionName
  positionType
  roomsCount
}

type sensorflow_v_group_tasks_stddev_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

type sensorflow_v_group_tasks_stddev_pop_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

type sensorflow_v_group_tasks_stddev_samp_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

input sensorflow_v_group_tasks_stream_cursor_input {
  initial_value: sensorflow_v_group_tasks_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_group_tasks_stream_cursor_value_input {
  keysCount: bigint
  locationId: uuid
  openJobEntryCount: bigint
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

type sensorflow_v_group_tasks_sum_fields {
  keysCount: bigint
  openJobEntryCount: bigint
  roomsCount: bigint
}

type sensorflow_v_group_tasks_var_pop_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

type sensorflow_v_group_tasks_var_samp_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

type sensorflow_v_group_tasks_variance_fields {
  keysCount: Float
  openJobEntryCount: Float
  roomsCount: Float
}

type sensorflow_v_group_with_stats {
  compressorsCount: bigint
  gatewaysCount: bigint
  keysCount: bigint
  locationId: uuid
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

type sensorflow_v_group_with_stats_aggregate {
  aggregate: sensorflow_v_group_with_stats_aggregate_fields
  nodes: [sensorflow_v_group_with_stats!]!
}

type sensorflow_v_group_with_stats_aggregate_fields {
  avg: sensorflow_v_group_with_stats_avg_fields
  count(columns: [sensorflow_v_group_with_stats_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_group_with_stats_max_fields
  min: sensorflow_v_group_with_stats_min_fields
  stddev: sensorflow_v_group_with_stats_stddev_fields
  stddev_pop: sensorflow_v_group_with_stats_stddev_pop_fields
  stddev_samp: sensorflow_v_group_with_stats_stddev_samp_fields
  sum: sensorflow_v_group_with_stats_sum_fields
  var_pop: sensorflow_v_group_with_stats_var_pop_fields
  var_samp: sensorflow_v_group_with_stats_var_samp_fields
  variance: sensorflow_v_group_with_stats_variance_fields
}

type sensorflow_v_group_with_stats_avg_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

input sensorflow_v_group_with_stats_bool_exp {
  _and: [sensorflow_v_group_with_stats_bool_exp!]
  _not: sensorflow_v_group_with_stats_bool_exp
  _or: [sensorflow_v_group_with_stats_bool_exp!]
  compressorsCount: bigint_comparison_exp
  gatewaysCount: bigint_comparison_exp
  keysCount: bigint_comparison_exp
  locationId: uuid_comparison_exp
  positionId: uuid_comparison_exp
  positionName: String_comparison_exp
  positionType: String_comparison_exp
  roomsCount: bigint_comparison_exp
}

type sensorflow_v_group_with_stats_max_fields {
  compressorsCount: bigint
  gatewaysCount: bigint
  keysCount: bigint
  locationId: uuid
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

type sensorflow_v_group_with_stats_min_fields {
  compressorsCount: bigint
  gatewaysCount: bigint
  keysCount: bigint
  locationId: uuid
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

input sensorflow_v_group_with_stats_order_by {
  compressorsCount: order_by
  gatewaysCount: order_by
  keysCount: order_by
  locationId: order_by
  positionId: order_by
  positionName: order_by
  positionType: order_by
  roomsCount: order_by
}

enum sensorflow_v_group_with_stats_select_column {
  compressorsCount
  gatewaysCount
  keysCount
  locationId
  positionId
  positionName
  positionType
  roomsCount
}

type sensorflow_v_group_with_stats_stddev_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

type sensorflow_v_group_with_stats_stddev_pop_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

type sensorflow_v_group_with_stats_stddev_samp_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

input sensorflow_v_group_with_stats_stream_cursor_input {
  initial_value: sensorflow_v_group_with_stats_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_group_with_stats_stream_cursor_value_input {
  compressorsCount: bigint
  gatewaysCount: bigint
  keysCount: bigint
  locationId: uuid
  positionId: uuid
  positionName: String
  positionType: String
  roomsCount: bigint
}

type sensorflow_v_group_with_stats_sum_fields {
  compressorsCount: bigint
  gatewaysCount: bigint
  keysCount: bigint
  roomsCount: bigint
}

type sensorflow_v_group_with_stats_var_pop_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

type sensorflow_v_group_with_stats_var_samp_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

type sensorflow_v_group_with_stats_variance_fields {
  compressorsCount: Float
  gatewaysCount: Float
  keysCount: Float
  roomsCount: Float
}

type sensorflow_v_infrastructure_emon_power_status {
  channel: Int
  id: uuid
  isMyChannel: Boolean
  lastPowerReading: numeric
  nodeMacId: String
  numPhases: Int
}

type sensorflow_v_infrastructure_emon_power_status_aggregate {
  aggregate: sensorflow_v_infrastructure_emon_power_status_aggregate_fields
  nodes: [sensorflow_v_infrastructure_emon_power_status!]!
}

type sensorflow_v_infrastructure_emon_power_status_aggregate_fields {
  avg: sensorflow_v_infrastructure_emon_power_status_avg_fields
  count(columns: [sensorflow_v_infrastructure_emon_power_status_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_infrastructure_emon_power_status_max_fields
  min: sensorflow_v_infrastructure_emon_power_status_min_fields
  stddev: sensorflow_v_infrastructure_emon_power_status_stddev_fields
  stddev_pop: sensorflow_v_infrastructure_emon_power_status_stddev_pop_fields
  stddev_samp: sensorflow_v_infrastructure_emon_power_status_stddev_samp_fields
  sum: sensorflow_v_infrastructure_emon_power_status_sum_fields
  var_pop: sensorflow_v_infrastructure_emon_power_status_var_pop_fields
  var_samp: sensorflow_v_infrastructure_emon_power_status_var_samp_fields
  variance: sensorflow_v_infrastructure_emon_power_status_variance_fields
}

type sensorflow_v_infrastructure_emon_power_status_avg_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

input sensorflow_v_infrastructure_emon_power_status_bool_exp {
  _and: [sensorflow_v_infrastructure_emon_power_status_bool_exp!]
  _not: sensorflow_v_infrastructure_emon_power_status_bool_exp
  _or: [sensorflow_v_infrastructure_emon_power_status_bool_exp!]
  channel: Int_comparison_exp
  id: uuid_comparison_exp
  isMyChannel: Boolean_comparison_exp
  lastPowerReading: numeric_comparison_exp
  nodeMacId: String_comparison_exp
  numPhases: Int_comparison_exp
}

type sensorflow_v_infrastructure_emon_power_status_max_fields {
  channel: Int
  id: uuid
  lastPowerReading: numeric
  nodeMacId: String
  numPhases: Int
}

type sensorflow_v_infrastructure_emon_power_status_min_fields {
  channel: Int
  id: uuid
  lastPowerReading: numeric
  nodeMacId: String
  numPhases: Int
}

input sensorflow_v_infrastructure_emon_power_status_order_by {
  channel: order_by
  id: order_by
  isMyChannel: order_by
  lastPowerReading: order_by
  nodeMacId: order_by
  numPhases: order_by
}

enum sensorflow_v_infrastructure_emon_power_status_select_column {
  channel
  id
  isMyChannel
  lastPowerReading
  nodeMacId
  numPhases
}

type sensorflow_v_infrastructure_emon_power_status_stddev_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

type sensorflow_v_infrastructure_emon_power_status_stddev_pop_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

type sensorflow_v_infrastructure_emon_power_status_stddev_samp_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

input sensorflow_v_infrastructure_emon_power_status_stream_cursor_input {
  initial_value: sensorflow_v_infrastructure_emon_power_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_infrastructure_emon_power_status_stream_cursor_value_input {
  channel: Int
  id: uuid
  isMyChannel: Boolean
  lastPowerReading: numeric
  nodeMacId: String
  numPhases: Int
}

type sensorflow_v_infrastructure_emon_power_status_sum_fields {
  channel: Int
  lastPowerReading: numeric
  numPhases: Int
}

type sensorflow_v_infrastructure_emon_power_status_var_pop_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

type sensorflow_v_infrastructure_emon_power_status_var_samp_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

type sensorflow_v_infrastructure_emon_power_status_variance_fields {
  channel: Float
  lastPowerReading: Float
  numPhases: Float
}

type sensorflow_v_infrastructures {
  compressorId: uuid
  compressorPositionMappings(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): [sensorflow_compressor_mapping!]!
  compressorPositionMappings_aggregate(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): sensorflow_compressor_mapping_aggregate!
  coolingSystem: sensorflow_compressors
  ctMultiplier: numeric
  deletedAt: timestamptz
  hvacSystemType: String
  id: uuid
  meterPosition: sensorflow_positions
  meterPositionId: uuid
  name: String
  phaseStreamIndex: Int
  position: sensorflow_positions
  positionId: uuid
  type: String
}

type sensorflow_v_infrastructures_aggregate {
  aggregate: sensorflow_v_infrastructures_aggregate_fields
  nodes: [sensorflow_v_infrastructures!]!
}

type sensorflow_v_infrastructures_aggregate_fields {
  avg: sensorflow_v_infrastructures_avg_fields
  count(columns: [sensorflow_v_infrastructures_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_infrastructures_max_fields
  min: sensorflow_v_infrastructures_min_fields
  stddev: sensorflow_v_infrastructures_stddev_fields
  stddev_pop: sensorflow_v_infrastructures_stddev_pop_fields
  stddev_samp: sensorflow_v_infrastructures_stddev_samp_fields
  sum: sensorflow_v_infrastructures_sum_fields
  var_pop: sensorflow_v_infrastructures_var_pop_fields
  var_samp: sensorflow_v_infrastructures_var_samp_fields
  variance: sensorflow_v_infrastructures_variance_fields
}

type sensorflow_v_infrastructures_avg_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

input sensorflow_v_infrastructures_bool_exp {
  _and: [sensorflow_v_infrastructures_bool_exp!]
  _not: sensorflow_v_infrastructures_bool_exp
  _or: [sensorflow_v_infrastructures_bool_exp!]
  compressorId: uuid_comparison_exp
  compressorPositionMappings: sensorflow_compressor_mapping_bool_exp
  compressorPositionMappings_aggregate: sensorflow_compressor_mapping_aggregate_bool_exp
  coolingSystem: sensorflow_compressors_bool_exp
  ctMultiplier: numeric_comparison_exp
  deletedAt: timestamptz_comparison_exp
  hvacSystemType: String_comparison_exp
  id: uuid_comparison_exp
  meterPosition: sensorflow_positions_bool_exp
  meterPositionId: uuid_comparison_exp
  name: String_comparison_exp
  phaseStreamIndex: Int_comparison_exp
  position: sensorflow_positions_bool_exp
  positionId: uuid_comparison_exp
  type: String_comparison_exp
}

input sensorflow_v_infrastructures_insert_input {
  compressorId: uuid
  compressorPositionMappings: sensorflow_compressor_mapping_arr_rel_insert_input
  coolingSystem: sensorflow_compressors_obj_rel_insert_input
  ctMultiplier: numeric
  deletedAt: timestamptz
  hvacSystemType: String
  id: uuid
  meterPosition: sensorflow_positions_obj_rel_insert_input
  meterPositionId: uuid
  name: String
  phaseStreamIndex: Int
  position: sensorflow_positions_obj_rel_insert_input
  positionId: uuid
  type: String
}

type sensorflow_v_infrastructures_max_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deletedAt: timestamptz
  hvacSystemType: String
  id: uuid
  meterPositionId: uuid
  name: String
  phaseStreamIndex: Int
  positionId: uuid
  type: String
}

type sensorflow_v_infrastructures_min_fields {
  compressorId: uuid
  ctMultiplier: numeric
  deletedAt: timestamptz
  hvacSystemType: String
  id: uuid
  meterPositionId: uuid
  name: String
  phaseStreamIndex: Int
  positionId: uuid
  type: String
}

input sensorflow_v_infrastructures_obj_rel_insert_input {
  data: sensorflow_v_infrastructures_insert_input!
}

input sensorflow_v_infrastructures_order_by {
  compressorId: order_by
  compressorPositionMappings_aggregate: sensorflow_compressor_mapping_aggregate_order_by
  coolingSystem: sensorflow_compressors_order_by
  ctMultiplier: order_by
  deletedAt: order_by
  hvacSystemType: order_by
  id: order_by
  meterPosition: sensorflow_positions_order_by
  meterPositionId: order_by
  name: order_by
  phaseStreamIndex: order_by
  position: sensorflow_positions_order_by
  positionId: order_by
  type: order_by
}

enum sensorflow_v_infrastructures_select_column {
  compressorId
  ctMultiplier
  deletedAt
  hvacSystemType
  id
  meterPositionId
  name
  phaseStreamIndex
  positionId
  type
}

type sensorflow_v_infrastructures_stddev_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

type sensorflow_v_infrastructures_stddev_pop_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

type sensorflow_v_infrastructures_stddev_samp_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

input sensorflow_v_infrastructures_stream_cursor_input {
  initial_value: sensorflow_v_infrastructures_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_infrastructures_stream_cursor_value_input {
  compressorId: uuid
  ctMultiplier: numeric
  deletedAt: timestamptz
  hvacSystemType: String
  id: uuid
  meterPositionId: uuid
  name: String
  phaseStreamIndex: Int
  positionId: uuid
  type: String
}

type sensorflow_v_infrastructures_sum_fields {
  ctMultiplier: numeric
  phaseStreamIndex: Int
}

type sensorflow_v_infrastructures_var_pop_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

type sensorflow_v_infrastructures_var_samp_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

type sensorflow_v_infrastructures_variance_fields {
  ctMultiplier: Float
  phaseStreamIndex: Float
}

type sensorflow_v_key_emon_power_reading {
  associated_gateway: String
  autoset_id: uuid
  compressor_id: uuid
  compressor_name: String
  compressor_node_position_id: uuid
  key_id: uuid
  location_id: uuid
  node_mac_id: String
  node_status: String
  parent_position_id: uuid
  power: numeric
}

type sensorflow_v_key_emon_power_reading_aggregate {
  aggregate: sensorflow_v_key_emon_power_reading_aggregate_fields
  nodes: [sensorflow_v_key_emon_power_reading!]!
}

type sensorflow_v_key_emon_power_reading_aggregate_fields {
  avg: sensorflow_v_key_emon_power_reading_avg_fields
  count(columns: [sensorflow_v_key_emon_power_reading_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_key_emon_power_reading_max_fields
  min: sensorflow_v_key_emon_power_reading_min_fields
  stddev: sensorflow_v_key_emon_power_reading_stddev_fields
  stddev_pop: sensorflow_v_key_emon_power_reading_stddev_pop_fields
  stddev_samp: sensorflow_v_key_emon_power_reading_stddev_samp_fields
  sum: sensorflow_v_key_emon_power_reading_sum_fields
  var_pop: sensorflow_v_key_emon_power_reading_var_pop_fields
  var_samp: sensorflow_v_key_emon_power_reading_var_samp_fields
  variance: sensorflow_v_key_emon_power_reading_variance_fields
}

type sensorflow_v_key_emon_power_reading_avg_fields {
  power: Float
}

input sensorflow_v_key_emon_power_reading_bool_exp {
  _and: [sensorflow_v_key_emon_power_reading_bool_exp!]
  _not: sensorflow_v_key_emon_power_reading_bool_exp
  _or: [sensorflow_v_key_emon_power_reading_bool_exp!]
  associated_gateway: String_comparison_exp
  autoset_id: uuid_comparison_exp
  compressor_id: uuid_comparison_exp
  compressor_name: String_comparison_exp
  compressor_node_position_id: uuid_comparison_exp
  key_id: uuid_comparison_exp
  location_id: uuid_comparison_exp
  node_mac_id: String_comparison_exp
  node_status: String_comparison_exp
  parent_position_id: uuid_comparison_exp
  power: numeric_comparison_exp
}

type sensorflow_v_key_emon_power_reading_max_fields {
  associated_gateway: String
  autoset_id: uuid
  compressor_id: uuid
  compressor_name: String
  compressor_node_position_id: uuid
  key_id: uuid
  location_id: uuid
  node_mac_id: String
  node_status: String
  parent_position_id: uuid
  power: numeric
}

type sensorflow_v_key_emon_power_reading_min_fields {
  associated_gateway: String
  autoset_id: uuid
  compressor_id: uuid
  compressor_name: String
  compressor_node_position_id: uuid
  key_id: uuid
  location_id: uuid
  node_mac_id: String
  node_status: String
  parent_position_id: uuid
  power: numeric
}

input sensorflow_v_key_emon_power_reading_order_by {
  associated_gateway: order_by
  autoset_id: order_by
  compressor_id: order_by
  compressor_name: order_by
  compressor_node_position_id: order_by
  key_id: order_by
  location_id: order_by
  node_mac_id: order_by
  node_status: order_by
  parent_position_id: order_by
  power: order_by
}

enum sensorflow_v_key_emon_power_reading_select_column {
  associated_gateway
  autoset_id
  compressor_id
  compressor_name
  compressor_node_position_id
  key_id
  location_id
  node_mac_id
  node_status
  parent_position_id
  power
}

type sensorflow_v_key_emon_power_reading_stddev_fields {
  power: Float
}

type sensorflow_v_key_emon_power_reading_stddev_pop_fields {
  power: Float
}

type sensorflow_v_key_emon_power_reading_stddev_samp_fields {
  power: Float
}

input sensorflow_v_key_emon_power_reading_stream_cursor_input {
  initial_value: sensorflow_v_key_emon_power_reading_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_key_emon_power_reading_stream_cursor_value_input {
  associated_gateway: String
  autoset_id: uuid
  compressor_id: uuid
  compressor_name: String
  compressor_node_position_id: uuid
  key_id: uuid
  location_id: uuid
  node_mac_id: String
  node_status: String
  parent_position_id: uuid
  power: numeric
}

type sensorflow_v_key_emon_power_reading_sum_fields {
  power: numeric
}

type sensorflow_v_key_emon_power_reading_var_pop_fields {
  power: Float
}

type sensorflow_v_key_emon_power_reading_var_samp_fields {
  power: Float
}

type sensorflow_v_key_emon_power_reading_variance_fields {
  power: Float
}

type sensorflow_v_key_position_latest_entry {
  comment: String
  commentStatus: String
  endedAt: timestamptz
  keyEntryId: Int
  keyPositionId: String
  positionFunction: String
  startedAt: timestamptz
  status: String
  user: sensorflow_users
  userId: String
}

type sensorflow_v_key_position_latest_entry_aggregate {
  aggregate: sensorflow_v_key_position_latest_entry_aggregate_fields
  nodes: [sensorflow_v_key_position_latest_entry!]!
}

type sensorflow_v_key_position_latest_entry_aggregate_fields {
  avg: sensorflow_v_key_position_latest_entry_avg_fields
  count(columns: [sensorflow_v_key_position_latest_entry_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_key_position_latest_entry_max_fields
  min: sensorflow_v_key_position_latest_entry_min_fields
  stddev: sensorflow_v_key_position_latest_entry_stddev_fields
  stddev_pop: sensorflow_v_key_position_latest_entry_stddev_pop_fields
  stddev_samp: sensorflow_v_key_position_latest_entry_stddev_samp_fields
  sum: sensorflow_v_key_position_latest_entry_sum_fields
  var_pop: sensorflow_v_key_position_latest_entry_var_pop_fields
  var_samp: sensorflow_v_key_position_latest_entry_var_samp_fields
  variance: sensorflow_v_key_position_latest_entry_variance_fields
}

type sensorflow_v_key_position_latest_entry_avg_fields {
  keyEntryId: Float
}

input sensorflow_v_key_position_latest_entry_bool_exp {
  _and: [sensorflow_v_key_position_latest_entry_bool_exp!]
  _not: sensorflow_v_key_position_latest_entry_bool_exp
  _or: [sensorflow_v_key_position_latest_entry_bool_exp!]
  comment: String_comparison_exp
  commentStatus: String_comparison_exp
  endedAt: timestamptz_comparison_exp
  keyEntryId: Int_comparison_exp
  keyPositionId: String_comparison_exp
  positionFunction: String_comparison_exp
  startedAt: timestamptz_comparison_exp
  status: String_comparison_exp
  user: sensorflow_users_bool_exp
  userId: String_comparison_exp
}

type sensorflow_v_key_position_latest_entry_max_fields {
  comment: String
  commentStatus: String
  endedAt: timestamptz
  keyEntryId: Int
  keyPositionId: String
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

type sensorflow_v_key_position_latest_entry_min_fields {
  comment: String
  commentStatus: String
  endedAt: timestamptz
  keyEntryId: Int
  keyPositionId: String
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

input sensorflow_v_key_position_latest_entry_order_by {
  comment: order_by
  commentStatus: order_by
  endedAt: order_by
  keyEntryId: order_by
  keyPositionId: order_by
  positionFunction: order_by
  startedAt: order_by
  status: order_by
  user: sensorflow_users_order_by
  userId: order_by
}

enum sensorflow_v_key_position_latest_entry_select_column {
  comment
  commentStatus
  endedAt
  keyEntryId
  keyPositionId
  positionFunction
  startedAt
  status
  userId
}

type sensorflow_v_key_position_latest_entry_stddev_fields {
  keyEntryId: Float
}

type sensorflow_v_key_position_latest_entry_stddev_pop_fields {
  keyEntryId: Float
}

type sensorflow_v_key_position_latest_entry_stddev_samp_fields {
  keyEntryId: Float
}

input sensorflow_v_key_position_latest_entry_stream_cursor_input {
  initial_value: sensorflow_v_key_position_latest_entry_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_key_position_latest_entry_stream_cursor_value_input {
  comment: String
  commentStatus: String
  endedAt: timestamptz
  keyEntryId: Int
  keyPositionId: String
  positionFunction: String
  startedAt: timestamptz
  status: String
  userId: String
}

type sensorflow_v_key_position_latest_entry_sum_fields {
  keyEntryId: Int
}

type sensorflow_v_key_position_latest_entry_var_pop_fields {
  keyEntryId: Float
}

type sensorflow_v_key_position_latest_entry_var_samp_fields {
  keyEntryId: Float
}

type sensorflow_v_key_position_latest_entry_variance_fields {
  keyEntryId: Float
}

type sensorflow_v_key_task_min_duedate {
  keyId: String
  keyTaskDuedate: timestamptz
}

type sensorflow_v_key_task_min_duedate_aggregate {
  aggregate: sensorflow_v_key_task_min_duedate_aggregate_fields
  nodes: [sensorflow_v_key_task_min_duedate!]!
}

type sensorflow_v_key_task_min_duedate_aggregate_fields {
  count(columns: [sensorflow_v_key_task_min_duedate_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_key_task_min_duedate_max_fields
  min: sensorflow_v_key_task_min_duedate_min_fields
}

input sensorflow_v_key_task_min_duedate_bool_exp {
  _and: [sensorflow_v_key_task_min_duedate_bool_exp!]
  _not: sensorflow_v_key_task_min_duedate_bool_exp
  _or: [sensorflow_v_key_task_min_duedate_bool_exp!]
  keyId: String_comparison_exp
  keyTaskDuedate: timestamptz_comparison_exp
}

type sensorflow_v_key_task_min_duedate_max_fields {
  keyId: String
  keyTaskDuedate: timestamptz
}

type sensorflow_v_key_task_min_duedate_min_fields {
  keyId: String
  keyTaskDuedate: timestamptz
}

input sensorflow_v_key_task_min_duedate_order_by {
  keyId: order_by
  keyTaskDuedate: order_by
}

enum sensorflow_v_key_task_min_duedate_select_column {
  keyId
  keyTaskDuedate
}

input sensorflow_v_key_task_min_duedate_stream_cursor_input {
  initial_value: sensorflow_v_key_task_min_duedate_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_key_task_min_duedate_stream_cursor_value_input {
  keyId: String
  keyTaskDuedate: timestamptz
}

type sensorflow_v_location_last_update {
  lastConfigUpdated: timestamptz
  locationId: uuid
}

type sensorflow_v_location_last_update_aggregate {
  aggregate: sensorflow_v_location_last_update_aggregate_fields
  nodes: [sensorflow_v_location_last_update!]!
}

type sensorflow_v_location_last_update_aggregate_fields {
  count(columns: [sensorflow_v_location_last_update_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_location_last_update_max_fields
  min: sensorflow_v_location_last_update_min_fields
}

input sensorflow_v_location_last_update_bool_exp {
  _and: [sensorflow_v_location_last_update_bool_exp!]
  _not: sensorflow_v_location_last_update_bool_exp
  _or: [sensorflow_v_location_last_update_bool_exp!]
  lastConfigUpdated: timestamptz_comparison_exp
  locationId: uuid_comparison_exp
}

type sensorflow_v_location_last_update_max_fields {
  lastConfigUpdated: timestamptz
  locationId: uuid
}

type sensorflow_v_location_last_update_min_fields {
  lastConfigUpdated: timestamptz
  locationId: uuid
}

input sensorflow_v_location_last_update_order_by {
  lastConfigUpdated: order_by
  locationId: order_by
}

enum sensorflow_v_location_last_update_select_column {
  lastConfigUpdated
  locationId
}

input sensorflow_v_location_last_update_stream_cursor_input {
  initial_value: sensorflow_v_location_last_update_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_location_last_update_stream_cursor_value_input {
  lastConfigUpdated: timestamptz
  locationId: uuid
}

type sensorflow_v_node_life_cycle_events {
  changedAt: timestamptz
  comment: String
  eventType: String
  nodeMacId: String
  nodeSubType: String
  nodeType: String
}

type sensorflow_v_node_life_cycle_events_aggregate {
  aggregate: sensorflow_v_node_life_cycle_events_aggregate_fields
  nodes: [sensorflow_v_node_life_cycle_events!]!
}

type sensorflow_v_node_life_cycle_events_aggregate_fields {
  count(columns: [sensorflow_v_node_life_cycle_events_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_node_life_cycle_events_max_fields
  min: sensorflow_v_node_life_cycle_events_min_fields
}

input sensorflow_v_node_life_cycle_events_bool_exp {
  _and: [sensorflow_v_node_life_cycle_events_bool_exp!]
  _not: sensorflow_v_node_life_cycle_events_bool_exp
  _or: [sensorflow_v_node_life_cycle_events_bool_exp!]
  changedAt: timestamptz_comparison_exp
  comment: String_comparison_exp
  eventType: String_comparison_exp
  nodeMacId: String_comparison_exp
  nodeSubType: String_comparison_exp
  nodeType: String_comparison_exp
}

type sensorflow_v_node_life_cycle_events_max_fields {
  changedAt: timestamptz
  comment: String
  eventType: String
  nodeMacId: String
  nodeSubType: String
  nodeType: String
}

type sensorflow_v_node_life_cycle_events_min_fields {
  changedAt: timestamptz
  comment: String
  eventType: String
  nodeMacId: String
  nodeSubType: String
  nodeType: String
}

input sensorflow_v_node_life_cycle_events_order_by {
  changedAt: order_by
  comment: order_by
  eventType: order_by
  nodeMacId: order_by
  nodeSubType: order_by
  nodeType: order_by
}

enum sensorflow_v_node_life_cycle_events_select_column {
  changedAt
  comment
  eventType
  nodeMacId
  nodeSubType
  nodeType
}

input sensorflow_v_node_life_cycle_events_stream_cursor_input {
  initial_value: sensorflow_v_node_life_cycle_events_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_node_life_cycle_events_stream_cursor_value_input {
  changedAt: timestamptz
  comment: String
  eventType: String
  nodeMacId: String
  nodeSubType: String
  nodeType: String
}

type sensorflow_v_task_with_key_status_history {
  categoryName: String
  createdAt: timestamptz
  keyId: String
  keyName: String
  keyPositionType: String
  locationId: uuid
  roomsCount: bigint
  status: String
  taskId: uuid
}

type sensorflow_v_task_with_key_status_history_aggregate {
  aggregate: sensorflow_v_task_with_key_status_history_aggregate_fields
  nodes: [sensorflow_v_task_with_key_status_history!]!
}

type sensorflow_v_task_with_key_status_history_aggregate_fields {
  avg: sensorflow_v_task_with_key_status_history_avg_fields
  count(columns: [sensorflow_v_task_with_key_status_history_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_task_with_key_status_history_max_fields
  min: sensorflow_v_task_with_key_status_history_min_fields
  stddev: sensorflow_v_task_with_key_status_history_stddev_fields
  stddev_pop: sensorflow_v_task_with_key_status_history_stddev_pop_fields
  stddev_samp: sensorflow_v_task_with_key_status_history_stddev_samp_fields
  sum: sensorflow_v_task_with_key_status_history_sum_fields
  var_pop: sensorflow_v_task_with_key_status_history_var_pop_fields
  var_samp: sensorflow_v_task_with_key_status_history_var_samp_fields
  variance: sensorflow_v_task_with_key_status_history_variance_fields
}

type sensorflow_v_task_with_key_status_history_avg_fields {
  roomsCount: Float
}

input sensorflow_v_task_with_key_status_history_bool_exp {
  _and: [sensorflow_v_task_with_key_status_history_bool_exp!]
  _not: sensorflow_v_task_with_key_status_history_bool_exp
  _or: [sensorflow_v_task_with_key_status_history_bool_exp!]
  categoryName: String_comparison_exp
  createdAt: timestamptz_comparison_exp
  keyId: String_comparison_exp
  keyName: String_comparison_exp
  keyPositionType: String_comparison_exp
  locationId: uuid_comparison_exp
  roomsCount: bigint_comparison_exp
  status: String_comparison_exp
  taskId: uuid_comparison_exp
}

type sensorflow_v_task_with_key_status_history_max_fields {
  categoryName: String
  createdAt: timestamptz
  keyId: String
  keyName: String
  keyPositionType: String
  locationId: uuid
  roomsCount: bigint
  status: String
  taskId: uuid
}

type sensorflow_v_task_with_key_status_history_min_fields {
  categoryName: String
  createdAt: timestamptz
  keyId: String
  keyName: String
  keyPositionType: String
  locationId: uuid
  roomsCount: bigint
  status: String
  taskId: uuid
}

input sensorflow_v_task_with_key_status_history_order_by {
  categoryName: order_by
  createdAt: order_by
  keyId: order_by
  keyName: order_by
  keyPositionType: order_by
  locationId: order_by
  roomsCount: order_by
  status: order_by
  taskId: order_by
}

enum sensorflow_v_task_with_key_status_history_select_column {
  categoryName
  createdAt
  keyId
  keyName
  keyPositionType
  locationId
  roomsCount
  status
  taskId
}

type sensorflow_v_task_with_key_status_history_stddev_fields {
  roomsCount: Float
}

type sensorflow_v_task_with_key_status_history_stddev_pop_fields {
  roomsCount: Float
}

type sensorflow_v_task_with_key_status_history_stddev_samp_fields {
  roomsCount: Float
}

input sensorflow_v_task_with_key_status_history_stream_cursor_input {
  initial_value: sensorflow_v_task_with_key_status_history_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_task_with_key_status_history_stream_cursor_value_input {
  categoryName: String
  createdAt: timestamptz
  keyId: String
  keyName: String
  keyPositionType: String
  locationId: uuid
  roomsCount: bigint
  status: String
  taskId: uuid
}

type sensorflow_v_task_with_key_status_history_sum_fields {
  roomsCount: bigint
}

type sensorflow_v_task_with_key_status_history_var_pop_fields {
  roomsCount: Float
}

type sensorflow_v_task_with_key_status_history_var_samp_fields {
  roomsCount: Float
}

type sensorflow_v_task_with_key_status_history_variance_fields {
  roomsCount: Float
}

type sensorflow_v_tasks_with_keys {
  action: sf_support_actions
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  createdAt: timestamptz
  description: String
  dueDate: timestamptz
  executor: String
  group_id: uuid
  keyEntryId: Int
  keyId: String
  keyName: String
  key_position_type: String
  location_id: uuid
  nodeSubType: String
  nodeType: String
  parentEntityType: String
  roomId: uuid
  roomName: String
  slotName: String
  status: String
  taskId: uuid
  taskType: String
  title: String
}

type sensorflow_v_tasks_with_keys_aggregate {
  aggregate: sensorflow_v_tasks_with_keys_aggregate_fields
  nodes: [sensorflow_v_tasks_with_keys!]!
}

type sensorflow_v_tasks_with_keys_aggregate_fields {
  avg: sensorflow_v_tasks_with_keys_avg_fields
  count(columns: [sensorflow_v_tasks_with_keys_select_column!], distinct: Boolean): Int!
  max: sensorflow_v_tasks_with_keys_max_fields
  min: sensorflow_v_tasks_with_keys_min_fields
  stddev: sensorflow_v_tasks_with_keys_stddev_fields
  stddev_pop: sensorflow_v_tasks_with_keys_stddev_pop_fields
  stddev_samp: sensorflow_v_tasks_with_keys_stddev_samp_fields
  sum: sensorflow_v_tasks_with_keys_sum_fields
  var_pop: sensorflow_v_tasks_with_keys_var_pop_fields
  var_samp: sensorflow_v_tasks_with_keys_var_samp_fields
  variance: sensorflow_v_tasks_with_keys_variance_fields
}

type sensorflow_v_tasks_with_keys_avg_fields {
  action_id: Float
  keyEntryId: Float
}

input sensorflow_v_tasks_with_keys_bool_exp {
  _and: [sensorflow_v_tasks_with_keys_bool_exp!]
  _not: sensorflow_v_tasks_with_keys_bool_exp
  _or: [sensorflow_v_tasks_with_keys_bool_exp!]
  action: sf_support_actions_bool_exp
  action_id: Int_comparison_exp
  associatedSlotId: uuid_comparison_exp
  claimerEmail: String_comparison_exp
  clickupTaskId: String_comparison_exp
  createdAt: timestamptz_comparison_exp
  description: String_comparison_exp
  dueDate: timestamptz_comparison_exp
  executor: String_comparison_exp
  group_id: uuid_comparison_exp
  keyEntryId: Int_comparison_exp
  keyId: String_comparison_exp
  keyName: String_comparison_exp
  key_position_type: String_comparison_exp
  location_id: uuid_comparison_exp
  nodeSubType: String_comparison_exp
  nodeType: String_comparison_exp
  parentEntityType: String_comparison_exp
  roomId: uuid_comparison_exp
  roomName: String_comparison_exp
  slotName: String_comparison_exp
  status: String_comparison_exp
  taskId: uuid_comparison_exp
  taskType: String_comparison_exp
  title: String_comparison_exp
}

type sensorflow_v_tasks_with_keys_max_fields {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  createdAt: timestamptz
  description: String
  dueDate: timestamptz
  executor: String
  group_id: uuid
  keyEntryId: Int
  keyId: String
  keyName: String
  key_position_type: String
  location_id: uuid
  nodeSubType: String
  nodeType: String
  parentEntityType: String
  roomId: uuid
  roomName: String
  slotName: String
  status: String
  taskId: uuid
  taskType: String
  title: String
}

type sensorflow_v_tasks_with_keys_min_fields {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  createdAt: timestamptz
  description: String
  dueDate: timestamptz
  executor: String
  group_id: uuid
  keyEntryId: Int
  keyId: String
  keyName: String
  key_position_type: String
  location_id: uuid
  nodeSubType: String
  nodeType: String
  parentEntityType: String
  roomId: uuid
  roomName: String
  slotName: String
  status: String
  taskId: uuid
  taskType: String
  title: String
}

input sensorflow_v_tasks_with_keys_order_by {
  action: sf_support_actions_order_by
  action_id: order_by
  associatedSlotId: order_by
  claimerEmail: order_by
  clickupTaskId: order_by
  createdAt: order_by
  description: order_by
  dueDate: order_by
  executor: order_by
  group_id: order_by
  keyEntryId: order_by
  keyId: order_by
  keyName: order_by
  key_position_type: order_by
  location_id: order_by
  nodeSubType: order_by
  nodeType: order_by
  parentEntityType: order_by
  roomId: order_by
  roomName: order_by
  slotName: order_by
  status: order_by
  taskId: order_by
  taskType: order_by
  title: order_by
}

enum sensorflow_v_tasks_with_keys_select_column {
  action_id
  associatedSlotId
  claimerEmail
  clickupTaskId
  createdAt
  description
  dueDate
  executor
  group_id
  keyEntryId
  keyId
  keyName
  key_position_type
  location_id
  nodeSubType
  nodeType
  parentEntityType
  roomId
  roomName
  slotName
  status
  taskId
  taskType
  title
}

type sensorflow_v_tasks_with_keys_stddev_fields {
  action_id: Float
  keyEntryId: Float
}

type sensorflow_v_tasks_with_keys_stddev_pop_fields {
  action_id: Float
  keyEntryId: Float
}

type sensorflow_v_tasks_with_keys_stddev_samp_fields {
  action_id: Float
  keyEntryId: Float
}

input sensorflow_v_tasks_with_keys_stream_cursor_input {
  initial_value: sensorflow_v_tasks_with_keys_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_v_tasks_with_keys_stream_cursor_value_input {
  action_id: Int
  associatedSlotId: uuid
  claimerEmail: String
  clickupTaskId: String
  createdAt: timestamptz
  description: String
  dueDate: timestamptz
  executor: String
  group_id: uuid
  keyEntryId: Int
  keyId: String
  keyName: String
  key_position_type: String
  location_id: uuid
  nodeSubType: String
  nodeType: String
  parentEntityType: String
  roomId: uuid
  roomName: String
  slotName: String
  status: String
  taskId: uuid
  taskType: String
  title: String
}

type sensorflow_v_tasks_with_keys_sum_fields {
  action_id: Int
  keyEntryId: Int
}

type sensorflow_v_tasks_with_keys_var_pop_fields {
  action_id: Float
  keyEntryId: Float
}

type sensorflow_v_tasks_with_keys_var_samp_fields {
  action_id: Float
  keyEntryId: Float
}

type sensorflow_v_tasks_with_keys_variance_fields {
  action_id: Float
  keyEntryId: Float
}

type sensorflow_warehouse_kitting_process_metadata {
  balenaId: String!
  gateway: sensorflow_gateways!
  location: sensorflow_locations!
  locationId: uuid!
  status: String!
  updated_at: timestamptz!
}

type sensorflow_warehouse_kitting_process_metadata_aggregate {
  aggregate: sensorflow_warehouse_kitting_process_metadata_aggregate_fields
  nodes: [sensorflow_warehouse_kitting_process_metadata!]!
}

input sensorflow_warehouse_kitting_process_metadata_aggregate_bool_exp {
  count: sensorflow_warehouse_kitting_process_metadata_aggregate_bool_exp_count
}

input sensorflow_warehouse_kitting_process_metadata_aggregate_bool_exp_count {
  arguments: [sensorflow_warehouse_kitting_process_metadata_select_column!]
  distinct: Boolean
  filter: sensorflow_warehouse_kitting_process_metadata_bool_exp
  predicate: Int_comparison_exp!
}

type sensorflow_warehouse_kitting_process_metadata_aggregate_fields {
  count(columns: [sensorflow_warehouse_kitting_process_metadata_select_column!], distinct: Boolean): Int!
  max: sensorflow_warehouse_kitting_process_metadata_max_fields
  min: sensorflow_warehouse_kitting_process_metadata_min_fields
}

input sensorflow_warehouse_kitting_process_metadata_aggregate_order_by {
  count: order_by
  max: sensorflow_warehouse_kitting_process_metadata_max_order_by
  min: sensorflow_warehouse_kitting_process_metadata_min_order_by
}

input sensorflow_warehouse_kitting_process_metadata_arr_rel_insert_input {
  data: [sensorflow_warehouse_kitting_process_metadata_insert_input!]!
  on_conflict: sensorflow_warehouse_kitting_process_metadata_on_conflict
}

input sensorflow_warehouse_kitting_process_metadata_bool_exp {
  _and: [sensorflow_warehouse_kitting_process_metadata_bool_exp!]
  _not: sensorflow_warehouse_kitting_process_metadata_bool_exp
  _or: [sensorflow_warehouse_kitting_process_metadata_bool_exp!]
  balenaId: String_comparison_exp
  gateway: sensorflow_gateways_bool_exp
  location: sensorflow_locations_bool_exp
  locationId: uuid_comparison_exp
  status: String_comparison_exp
  updated_at: timestamptz_comparison_exp
}

enum sensorflow_warehouse_kitting_process_metadata_constraint {
  unique_balena_id
  warehouse_kitting_process_metadata_pkey
}

input sensorflow_warehouse_kitting_process_metadata_insert_input {
  balenaId: String
  gateway: sensorflow_gateways_obj_rel_insert_input
  location: sensorflow_locations_obj_rel_insert_input
  locationId: uuid
  status: String
  updated_at: timestamptz
}

type sensorflow_warehouse_kitting_process_metadata_max_fields {
  balenaId: String
  locationId: uuid
  status: String
  updated_at: timestamptz
}

input sensorflow_warehouse_kitting_process_metadata_max_order_by {
  balenaId: order_by
  locationId: order_by
  status: order_by
  updated_at: order_by
}

type sensorflow_warehouse_kitting_process_metadata_min_fields {
  balenaId: String
  locationId: uuid
  status: String
  updated_at: timestamptz
}

input sensorflow_warehouse_kitting_process_metadata_min_order_by {
  balenaId: order_by
  locationId: order_by
  status: order_by
  updated_at: order_by
}

type sensorflow_warehouse_kitting_process_metadata_mutation_response {
  affected_rows: Int!
  returning: [sensorflow_warehouse_kitting_process_metadata!]!
}

input sensorflow_warehouse_kitting_process_metadata_on_conflict {
  constraint: sensorflow_warehouse_kitting_process_metadata_constraint!
  update_columns: [sensorflow_warehouse_kitting_process_metadata_update_column!]! = []
  where: sensorflow_warehouse_kitting_process_metadata_bool_exp
}

input sensorflow_warehouse_kitting_process_metadata_order_by {
  balenaId: order_by
  gateway: sensorflow_gateways_order_by
  location: sensorflow_locations_order_by
  locationId: order_by
  status: order_by
  updated_at: order_by
}

input sensorflow_warehouse_kitting_process_metadata_pk_columns_input {
  balenaId: String!
}

enum sensorflow_warehouse_kitting_process_metadata_select_column {
  balenaId
  locationId
  status
  updated_at
}

input sensorflow_warehouse_kitting_process_metadata_set_input {
  balenaId: String
  locationId: uuid
  status: String
  updated_at: timestamptz
}

input sensorflow_warehouse_kitting_process_metadata_stream_cursor_input {
  initial_value: sensorflow_warehouse_kitting_process_metadata_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_warehouse_kitting_process_metadata_stream_cursor_value_input {
  balenaId: String
  locationId: uuid
  status: String
  updated_at: timestamptz
}

enum sensorflow_warehouse_kitting_process_metadata_update_column {
  balenaId
  locationId
  status
  updated_at
}

input sensorflow_warehouse_kitting_process_metadata_updates {
  _set: sensorflow_warehouse_kitting_process_metadata_set_input
  where: sensorflow_warehouse_kitting_process_metadata_bool_exp!
}

type sensorflow_warehouse_mapping_status_of_slots {
  id: uuid
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  node_mac_id: String
  node_sub_type: String
  node_type: String
  room_id: uuid
  room_name: String
  slot_mapping_status: String
  slot_name: String
}

type sensorflow_warehouse_mapping_status_of_slots_aggregate {
  aggregate: sensorflow_warehouse_mapping_status_of_slots_aggregate_fields
  nodes: [sensorflow_warehouse_mapping_status_of_slots!]!
}

type sensorflow_warehouse_mapping_status_of_slots_aggregate_fields {
  count(columns: [sensorflow_warehouse_mapping_status_of_slots_select_column!], distinct: Boolean): Int!
  max: sensorflow_warehouse_mapping_status_of_slots_max_fields
  min: sensorflow_warehouse_mapping_status_of_slots_min_fields
}

input sensorflow_warehouse_mapping_status_of_slots_bool_exp {
  _and: [sensorflow_warehouse_mapping_status_of_slots_bool_exp!]
  _not: sensorflow_warehouse_mapping_status_of_slots_bool_exp
  _or: [sensorflow_warehouse_mapping_status_of_slots_bool_exp!]
  id: uuid_comparison_exp
  key: String_comparison_exp
  key_id: uuid_comparison_exp
  location_id: uuid_comparison_exp
  location_name: String_comparison_exp
  node_mac_id: String_comparison_exp
  node_sub_type: String_comparison_exp
  node_type: String_comparison_exp
  room_id: uuid_comparison_exp
  room_name: String_comparison_exp
  slot_mapping_status: String_comparison_exp
  slot_name: String_comparison_exp
}

type sensorflow_warehouse_mapping_status_of_slots_max_fields {
  id: uuid
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  node_mac_id: String
  node_sub_type: String
  node_type: String
  room_id: uuid
  room_name: String
  slot_mapping_status: String
  slot_name: String
}

type sensorflow_warehouse_mapping_status_of_slots_min_fields {
  id: uuid
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  node_mac_id: String
  node_sub_type: String
  node_type: String
  room_id: uuid
  room_name: String
  slot_mapping_status: String
  slot_name: String
}

input sensorflow_warehouse_mapping_status_of_slots_order_by {
  id: order_by
  key: order_by
  key_id: order_by
  location_id: order_by
  location_name: order_by
  node_mac_id: order_by
  node_sub_type: order_by
  node_type: order_by
  room_id: order_by
  room_name: order_by
  slot_mapping_status: order_by
  slot_name: order_by
}

enum sensorflow_warehouse_mapping_status_of_slots_select_column {
  id
  key
  key_id
  location_id
  location_name
  node_mac_id
  node_sub_type
  node_type
  room_id
  room_name
  slot_mapping_status
  slot_name
}

input sensorflow_warehouse_mapping_status_of_slots_stream_cursor_input {
  initial_value: sensorflow_warehouse_mapping_status_of_slots_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sensorflow_warehouse_mapping_status_of_slots_stream_cursor_value_input {
  id: uuid
  key: String
  key_id: uuid
  location_id: uuid
  location_name: String
  node_mac_id: String
  node_sub_type: String
  node_type: String
  room_id: uuid
  room_name: String
  slot_mapping_status: String
  slot_name: String
}

type sf_firmware_dist_pipeline_firmware_build {
  buildId: Int!
  comment: String
  deprecated: Boolean!
  downloadLink: String!
  isTest: Boolean!
  packageName: String!
  releaseType: Int!
  uploadTime: timestamptz
}

type sf_firmware_dist_pipeline_firmware_build_aggregate {
  aggregate: sf_firmware_dist_pipeline_firmware_build_aggregate_fields
  nodes: [sf_firmware_dist_pipeline_firmware_build!]!
}

type sf_firmware_dist_pipeline_firmware_build_aggregate_fields {
  avg: sf_firmware_dist_pipeline_firmware_build_avg_fields
  count(columns: [sf_firmware_dist_pipeline_firmware_build_select_column!], distinct: Boolean): Int!
  max: sf_firmware_dist_pipeline_firmware_build_max_fields
  min: sf_firmware_dist_pipeline_firmware_build_min_fields
  stddev: sf_firmware_dist_pipeline_firmware_build_stddev_fields
  stddev_pop: sf_firmware_dist_pipeline_firmware_build_stddev_pop_fields
  stddev_samp: sf_firmware_dist_pipeline_firmware_build_stddev_samp_fields
  sum: sf_firmware_dist_pipeline_firmware_build_sum_fields
  var_pop: sf_firmware_dist_pipeline_firmware_build_var_pop_fields
  var_samp: sf_firmware_dist_pipeline_firmware_build_var_samp_fields
  variance: sf_firmware_dist_pipeline_firmware_build_variance_fields
}

type sf_firmware_dist_pipeline_firmware_build_avg_fields {
  buildId: Float
  releaseType: Float
}

input sf_firmware_dist_pipeline_firmware_build_bool_exp {
  _and: [sf_firmware_dist_pipeline_firmware_build_bool_exp!]
  _not: sf_firmware_dist_pipeline_firmware_build_bool_exp
  _or: [sf_firmware_dist_pipeline_firmware_build_bool_exp!]
  buildId: Int_comparison_exp
  comment: String_comparison_exp
  deprecated: Boolean_comparison_exp
  downloadLink: String_comparison_exp
  isTest: Boolean_comparison_exp
  packageName: String_comparison_exp
  releaseType: Int_comparison_exp
  uploadTime: timestamptz_comparison_exp
}

enum sf_firmware_dist_pipeline_firmware_build_constraint {
  pipeline_firmware_build_pkey
}

input sf_firmware_dist_pipeline_firmware_build_inc_input {
  buildId: Int
  releaseType: Int
}

input sf_firmware_dist_pipeline_firmware_build_insert_input {
  buildId: Int
  comment: String
  deprecated: Boolean
  downloadLink: String
  isTest: Boolean
  packageName: String
  releaseType: Int
  uploadTime: timestamptz
}

type sf_firmware_dist_pipeline_firmware_build_max_fields {
  buildId: Int
  comment: String
  downloadLink: String
  packageName: String
  releaseType: Int
  uploadTime: timestamptz
}

type sf_firmware_dist_pipeline_firmware_build_min_fields {
  buildId: Int
  comment: String
  downloadLink: String
  packageName: String
  releaseType: Int
  uploadTime: timestamptz
}

type sf_firmware_dist_pipeline_firmware_build_mutation_response {
  affected_rows: Int!
  returning: [sf_firmware_dist_pipeline_firmware_build!]!
}

input sf_firmware_dist_pipeline_firmware_build_obj_rel_insert_input {
  data: sf_firmware_dist_pipeline_firmware_build_insert_input!
  on_conflict: sf_firmware_dist_pipeline_firmware_build_on_conflict
}

input sf_firmware_dist_pipeline_firmware_build_on_conflict {
  constraint: sf_firmware_dist_pipeline_firmware_build_constraint!
  update_columns: [sf_firmware_dist_pipeline_firmware_build_update_column!]! = []
  where: sf_firmware_dist_pipeline_firmware_build_bool_exp
}

input sf_firmware_dist_pipeline_firmware_build_order_by {
  buildId: order_by
  comment: order_by
  deprecated: order_by
  downloadLink: order_by
  isTest: order_by
  packageName: order_by
  releaseType: order_by
  uploadTime: order_by
}

input sf_firmware_dist_pipeline_firmware_build_pk_columns_input {
  buildId: Int!
}

enum sf_firmware_dist_pipeline_firmware_build_select_column {
  buildId
  comment
  deprecated
  downloadLink
  isTest
  packageName
  releaseType
  uploadTime
}

input sf_firmware_dist_pipeline_firmware_build_set_input {
  buildId: Int
  comment: String
  deprecated: Boolean
  downloadLink: String
  isTest: Boolean
  packageName: String
  releaseType: Int
  uploadTime: timestamptz
}

type sf_firmware_dist_pipeline_firmware_build_stddev_fields {
  buildId: Float
  releaseType: Float
}

type sf_firmware_dist_pipeline_firmware_build_stddev_pop_fields {
  buildId: Float
  releaseType: Float
}

type sf_firmware_dist_pipeline_firmware_build_stddev_samp_fields {
  buildId: Float
  releaseType: Float
}

input sf_firmware_dist_pipeline_firmware_build_stream_cursor_input {
  initial_value: sf_firmware_dist_pipeline_firmware_build_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_firmware_dist_pipeline_firmware_build_stream_cursor_value_input {
  buildId: Int
  comment: String
  deprecated: Boolean
  downloadLink: String
  isTest: Boolean
  packageName: String
  releaseType: Int
  uploadTime: timestamptz
}

type sf_firmware_dist_pipeline_firmware_build_sum_fields {
  buildId: Int
  releaseType: Int
}

enum sf_firmware_dist_pipeline_firmware_build_update_column {
  buildId
  comment
  deprecated
  downloadLink
  isTest
  packageName
  releaseType
  uploadTime
}

input sf_firmware_dist_pipeline_firmware_build_updates {
  _inc: sf_firmware_dist_pipeline_firmware_build_inc_input
  _set: sf_firmware_dist_pipeline_firmware_build_set_input
  where: sf_firmware_dist_pipeline_firmware_build_bool_exp!
}

type sf_firmware_dist_pipeline_firmware_build_var_pop_fields {
  buildId: Float
  releaseType: Float
}

type sf_firmware_dist_pipeline_firmware_build_var_samp_fields {
  buildId: Float
  releaseType: Float
}

type sf_firmware_dist_pipeline_firmware_build_variance_fields {
  buildId: Float
  releaseType: Float
}

type sf_firmware_dist_pipeline_firmware_numbers {
  buildId: Int!
  comment: String
  deprecated: Boolean!
  firmwareReleaseId: Int
  firmwareVersionNum: Int!
  id: Int!
  nodeSubType: String!
  pipeline_firmware_build: sf_firmware_dist_pipeline_firmware_build!
}

type sf_firmware_dist_pipeline_firmware_numbers_aggregate {
  aggregate: sf_firmware_dist_pipeline_firmware_numbers_aggregate_fields
  nodes: [sf_firmware_dist_pipeline_firmware_numbers!]!
}

type sf_firmware_dist_pipeline_firmware_numbers_aggregate_fields {
  avg: sf_firmware_dist_pipeline_firmware_numbers_avg_fields
  count(columns: [sf_firmware_dist_pipeline_firmware_numbers_select_column!], distinct: Boolean): Int!
  max: sf_firmware_dist_pipeline_firmware_numbers_max_fields
  min: sf_firmware_dist_pipeline_firmware_numbers_min_fields
  stddev: sf_firmware_dist_pipeline_firmware_numbers_stddev_fields
  stddev_pop: sf_firmware_dist_pipeline_firmware_numbers_stddev_pop_fields
  stddev_samp: sf_firmware_dist_pipeline_firmware_numbers_stddev_samp_fields
  sum: sf_firmware_dist_pipeline_firmware_numbers_sum_fields
  var_pop: sf_firmware_dist_pipeline_firmware_numbers_var_pop_fields
  var_samp: sf_firmware_dist_pipeline_firmware_numbers_var_samp_fields
  variance: sf_firmware_dist_pipeline_firmware_numbers_variance_fields
}

type sf_firmware_dist_pipeline_firmware_numbers_avg_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

input sf_firmware_dist_pipeline_firmware_numbers_bool_exp {
  _and: [sf_firmware_dist_pipeline_firmware_numbers_bool_exp!]
  _not: sf_firmware_dist_pipeline_firmware_numbers_bool_exp
  _or: [sf_firmware_dist_pipeline_firmware_numbers_bool_exp!]
  buildId: Int_comparison_exp
  comment: String_comparison_exp
  deprecated: Boolean_comparison_exp
  firmwareReleaseId: Int_comparison_exp
  firmwareVersionNum: Int_comparison_exp
  id: Int_comparison_exp
  nodeSubType: String_comparison_exp
  pipeline_firmware_build: sf_firmware_dist_pipeline_firmware_build_bool_exp
}

enum sf_firmware_dist_pipeline_firmware_numbers_constraint {
  pipeline_firmware_numbers_pkey
}

input sf_firmware_dist_pipeline_firmware_numbers_inc_input {
  buildId: Int
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
}

input sf_firmware_dist_pipeline_firmware_numbers_insert_input {
  buildId: Int
  comment: String
  deprecated: Boolean
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
  nodeSubType: String
  pipeline_firmware_build: sf_firmware_dist_pipeline_firmware_build_obj_rel_insert_input
}

type sf_firmware_dist_pipeline_firmware_numbers_max_fields {
  buildId: Int
  comment: String
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
  nodeSubType: String
}

type sf_firmware_dist_pipeline_firmware_numbers_min_fields {
  buildId: Int
  comment: String
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
  nodeSubType: String
}

type sf_firmware_dist_pipeline_firmware_numbers_mutation_response {
  affected_rows: Int!
  returning: [sf_firmware_dist_pipeline_firmware_numbers!]!
}

input sf_firmware_dist_pipeline_firmware_numbers_on_conflict {
  constraint: sf_firmware_dist_pipeline_firmware_numbers_constraint!
  update_columns: [sf_firmware_dist_pipeline_firmware_numbers_update_column!]! = []
  where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp
}

input sf_firmware_dist_pipeline_firmware_numbers_order_by {
  buildId: order_by
  comment: order_by
  deprecated: order_by
  firmwareReleaseId: order_by
  firmwareVersionNum: order_by
  id: order_by
  nodeSubType: order_by
  pipeline_firmware_build: sf_firmware_dist_pipeline_firmware_build_order_by
}

input sf_firmware_dist_pipeline_firmware_numbers_pk_columns_input {
  id: Int!
}

enum sf_firmware_dist_pipeline_firmware_numbers_select_column {
  buildId
  comment
  deprecated
  firmwareReleaseId
  firmwareVersionNum
  id
  nodeSubType
}

input sf_firmware_dist_pipeline_firmware_numbers_set_input {
  buildId: Int
  comment: String
  deprecated: Boolean
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
  nodeSubType: String
}

type sf_firmware_dist_pipeline_firmware_numbers_stddev_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

type sf_firmware_dist_pipeline_firmware_numbers_stddev_pop_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

type sf_firmware_dist_pipeline_firmware_numbers_stddev_samp_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

input sf_firmware_dist_pipeline_firmware_numbers_stream_cursor_input {
  initial_value: sf_firmware_dist_pipeline_firmware_numbers_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_firmware_dist_pipeline_firmware_numbers_stream_cursor_value_input {
  buildId: Int
  comment: String
  deprecated: Boolean
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
  nodeSubType: String
}

type sf_firmware_dist_pipeline_firmware_numbers_sum_fields {
  buildId: Int
  firmwareReleaseId: Int
  firmwareVersionNum: Int
  id: Int
}

enum sf_firmware_dist_pipeline_firmware_numbers_update_column {
  buildId
  comment
  deprecated
  firmwareReleaseId
  firmwareVersionNum
  id
  nodeSubType
}

input sf_firmware_dist_pipeline_firmware_numbers_updates {
  _inc: sf_firmware_dist_pipeline_firmware_numbers_inc_input
  _set: sf_firmware_dist_pipeline_firmware_numbers_set_input
  where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp!
}

type sf_firmware_dist_pipeline_firmware_numbers_var_pop_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

type sf_firmware_dist_pipeline_firmware_numbers_var_samp_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

type sf_firmware_dist_pipeline_firmware_numbers_variance_fields {
  buildId: Float
  firmwareReleaseId: Float
  firmwareVersionNum: Float
  id: Float
}

type sf_pms_pms_pmslist {
  pms_id: Int!
  pms_name: String!
}

type sf_pms_pms_pmslist_aggregate {
  aggregate: sf_pms_pms_pmslist_aggregate_fields
  nodes: [sf_pms_pms_pmslist!]!
}

type sf_pms_pms_pmslist_aggregate_fields {
  avg: sf_pms_pms_pmslist_avg_fields
  count(columns: [sf_pms_pms_pmslist_select_column!], distinct: Boolean): Int!
  max: sf_pms_pms_pmslist_max_fields
  min: sf_pms_pms_pmslist_min_fields
  stddev: sf_pms_pms_pmslist_stddev_fields
  stddev_pop: sf_pms_pms_pmslist_stddev_pop_fields
  stddev_samp: sf_pms_pms_pmslist_stddev_samp_fields
  sum: sf_pms_pms_pmslist_sum_fields
  var_pop: sf_pms_pms_pmslist_var_pop_fields
  var_samp: sf_pms_pms_pmslist_var_samp_fields
  variance: sf_pms_pms_pmslist_variance_fields
}

type sf_pms_pms_pmslist_avg_fields {
  pms_id: Float
}

input sf_pms_pms_pmslist_bool_exp {
  _and: [sf_pms_pms_pmslist_bool_exp!]
  _not: sf_pms_pms_pmslist_bool_exp
  _or: [sf_pms_pms_pmslist_bool_exp!]
  pms_id: Int_comparison_exp
  pms_name: String_comparison_exp
}

enum sf_pms_pms_pmslist_constraint {
  pms_pmslist_pk
}

input sf_pms_pms_pmslist_inc_input {
  pms_id: Int
}

input sf_pms_pms_pmslist_insert_input {
  pms_id: Int
  pms_name: String
}

type sf_pms_pms_pmslist_max_fields {
  pms_id: Int
  pms_name: String
}

type sf_pms_pms_pmslist_min_fields {
  pms_id: Int
  pms_name: String
}

type sf_pms_pms_pmslist_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_pms_pmslist!]!
}

input sf_pms_pms_pmslist_on_conflict {
  constraint: sf_pms_pms_pmslist_constraint!
  update_columns: [sf_pms_pms_pmslist_update_column!]! = []
  where: sf_pms_pms_pmslist_bool_exp
}

input sf_pms_pms_pmslist_order_by {
  pms_id: order_by
  pms_name: order_by
}

input sf_pms_pms_pmslist_pk_columns_input {
  pms_id: Int!
}

enum sf_pms_pms_pmslist_select_column {
  pms_id
  pms_name
}

input sf_pms_pms_pmslist_set_input {
  pms_id: Int
  pms_name: String
}

type sf_pms_pms_pmslist_stddev_fields {
  pms_id: Float
}

type sf_pms_pms_pmslist_stddev_pop_fields {
  pms_id: Float
}

type sf_pms_pms_pmslist_stddev_samp_fields {
  pms_id: Float
}

input sf_pms_pms_pmslist_stream_cursor_input {
  initial_value: sf_pms_pms_pmslist_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_pms_pmslist_stream_cursor_value_input {
  pms_id: Int
  pms_name: String
}

type sf_pms_pms_pmslist_sum_fields {
  pms_id: Int
}

enum sf_pms_pms_pmslist_update_column {
  pms_id
  pms_name
}

input sf_pms_pms_pmslist_updates {
  _inc: sf_pms_pms_pmslist_inc_input
  _set: sf_pms_pms_pmslist_set_input
  where: sf_pms_pms_pmslist_bool_exp!
}

type sf_pms_pms_pmslist_var_pop_fields {
  pms_id: Float
}

type sf_pms_pms_pmslist_var_samp_fields {
  pms_id: Float
}

type sf_pms_pms_pmslist_variance_fields {
  pms_id: Float
}

type sf_pms_pms_properties {
  is_ready: Boolean!
  location: sensorflow_locations!
  location_id: uuid!
  pms_id: Int!
  pms_integration_creds(path: String): json
  property_id: String!
  property_name: String!
  smartalloc_enable: Boolean!
  smartalloc_version: String
}

type sf_pms_pms_properties_aggregate {
  aggregate: sf_pms_pms_properties_aggregate_fields
  nodes: [sf_pms_pms_properties!]!
}

type sf_pms_pms_properties_aggregate_fields {
  avg: sf_pms_pms_properties_avg_fields
  count(columns: [sf_pms_pms_properties_select_column!], distinct: Boolean): Int!
  max: sf_pms_pms_properties_max_fields
  min: sf_pms_pms_properties_min_fields
  stddev: sf_pms_pms_properties_stddev_fields
  stddev_pop: sf_pms_pms_properties_stddev_pop_fields
  stddev_samp: sf_pms_pms_properties_stddev_samp_fields
  sum: sf_pms_pms_properties_sum_fields
  var_pop: sf_pms_pms_properties_var_pop_fields
  var_samp: sf_pms_pms_properties_var_samp_fields
  variance: sf_pms_pms_properties_variance_fields
}

type sf_pms_pms_properties_avg_fields {
  pms_id: Float
}

input sf_pms_pms_properties_bool_exp {
  _and: [sf_pms_pms_properties_bool_exp!]
  _not: sf_pms_pms_properties_bool_exp
  _or: [sf_pms_pms_properties_bool_exp!]
  is_ready: Boolean_comparison_exp
  location: sensorflow_locations_bool_exp
  location_id: uuid_comparison_exp
  pms_id: Int_comparison_exp
  pms_integration_creds: json_comparison_exp
  property_id: String_comparison_exp
  property_name: String_comparison_exp
  smartalloc_enable: Boolean_comparison_exp
  smartalloc_version: String_comparison_exp
}

enum sf_pms_pms_properties_constraint {
  pms_property_to_location_mapping_pk
}

input sf_pms_pms_properties_inc_input {
  pms_id: Int
}

input sf_pms_pms_properties_insert_input {
  is_ready: Boolean
  location: sensorflow_locations_obj_rel_insert_input
  location_id: uuid
  pms_id: Int
  pms_integration_creds: json
  property_id: String
  property_name: String
  smartalloc_enable: Boolean
  smartalloc_version: String
}

type sf_pms_pms_properties_max_fields {
  location_id: uuid
  pms_id: Int
  property_id: String
  property_name: String
  smartalloc_version: String
}

type sf_pms_pms_properties_min_fields {
  location_id: uuid
  pms_id: Int
  property_id: String
  property_name: String
  smartalloc_version: String
}

type sf_pms_pms_properties_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_pms_properties!]!
}

input sf_pms_pms_properties_obj_rel_insert_input {
  data: sf_pms_pms_properties_insert_input!
  on_conflict: sf_pms_pms_properties_on_conflict
}

input sf_pms_pms_properties_on_conflict {
  constraint: sf_pms_pms_properties_constraint!
  update_columns: [sf_pms_pms_properties_update_column!]! = []
  where: sf_pms_pms_properties_bool_exp
}

input sf_pms_pms_properties_order_by {
  is_ready: order_by
  location: sensorflow_locations_order_by
  location_id: order_by
  pms_id: order_by
  pms_integration_creds: order_by
  property_id: order_by
  property_name: order_by
  smartalloc_enable: order_by
  smartalloc_version: order_by
}

input sf_pms_pms_properties_pk_columns_input {
  pms_id: Int!
  property_id: String!
}

enum sf_pms_pms_properties_select_column {
  is_ready
  location_id
  pms_id
  pms_integration_creds
  property_id
  property_name
  smartalloc_enable
  smartalloc_version
}

input sf_pms_pms_properties_set_input {
  is_ready: Boolean
  location_id: uuid
  pms_id: Int
  pms_integration_creds: json
  property_id: String
  property_name: String
  smartalloc_enable: Boolean
  smartalloc_version: String
}

type sf_pms_pms_properties_stddev_fields {
  pms_id: Float
}

type sf_pms_pms_properties_stddev_pop_fields {
  pms_id: Float
}

type sf_pms_pms_properties_stddev_samp_fields {
  pms_id: Float
}

input sf_pms_pms_properties_stream_cursor_input {
  initial_value: sf_pms_pms_properties_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_pms_properties_stream_cursor_value_input {
  is_ready: Boolean
  location_id: uuid
  pms_id: Int
  pms_integration_creds: json
  property_id: String
  property_name: String
  smartalloc_enable: Boolean
  smartalloc_version: String
}

type sf_pms_pms_properties_sum_fields {
  pms_id: Int
}

enum sf_pms_pms_properties_update_column {
  is_ready
  location_id
  pms_id
  pms_integration_creds
  property_id
  property_name
  smartalloc_enable
  smartalloc_version
}

input sf_pms_pms_properties_updates {
  _inc: sf_pms_pms_properties_inc_input
  _set: sf_pms_pms_properties_set_input
  where: sf_pms_pms_properties_bool_exp!
}

type sf_pms_pms_properties_var_pop_fields {
  pms_id: Float
}

type sf_pms_pms_properties_var_samp_fields {
  pms_id: Float
}

type sf_pms_pms_properties_variance_fields {
  pms_id: Float
}

type sf_pms_pms_property_eod_surveys {
  created_at: timestamptz!
  data(path: String): json!
  pms_id: Int!
  pms_properties_mapping: sf_pms_pms_properties
  property_id: String!
  status: String!
  survey_monkey_id: String!
}

type sf_pms_pms_property_eod_surveys_aggregate {
  aggregate: sf_pms_pms_property_eod_surveys_aggregate_fields
  nodes: [sf_pms_pms_property_eod_surveys!]!
}

type sf_pms_pms_property_eod_surveys_aggregate_fields {
  avg: sf_pms_pms_property_eod_surveys_avg_fields
  count(columns: [sf_pms_pms_property_eod_surveys_select_column!], distinct: Boolean): Int!
  max: sf_pms_pms_property_eod_surveys_max_fields
  min: sf_pms_pms_property_eod_surveys_min_fields
  stddev: sf_pms_pms_property_eod_surveys_stddev_fields
  stddev_pop: sf_pms_pms_property_eod_surveys_stddev_pop_fields
  stddev_samp: sf_pms_pms_property_eod_surveys_stddev_samp_fields
  sum: sf_pms_pms_property_eod_surveys_sum_fields
  var_pop: sf_pms_pms_property_eod_surveys_var_pop_fields
  var_samp: sf_pms_pms_property_eod_surveys_var_samp_fields
  variance: sf_pms_pms_property_eod_surveys_variance_fields
}

type sf_pms_pms_property_eod_surveys_avg_fields {
  pms_id: Float
}

input sf_pms_pms_property_eod_surveys_bool_exp {
  _and: [sf_pms_pms_property_eod_surveys_bool_exp!]
  _not: sf_pms_pms_property_eod_surveys_bool_exp
  _or: [sf_pms_pms_property_eod_surveys_bool_exp!]
  created_at: timestamptz_comparison_exp
  data: json_comparison_exp
  pms_id: Int_comparison_exp
  pms_properties_mapping: sf_pms_pms_properties_bool_exp
  property_id: String_comparison_exp
  status: String_comparison_exp
  survey_monkey_id: String_comparison_exp
}

enum sf_pms_pms_property_eod_surveys_constraint {
  pms_property_eod_surveys_pkey
}

input sf_pms_pms_property_eod_surveys_inc_input {
  pms_id: Int
}

input sf_pms_pms_property_eod_surveys_insert_input {
  created_at: timestamptz
  data: json
  pms_id: Int
  pms_properties_mapping: sf_pms_pms_properties_obj_rel_insert_input
  property_id: String
  status: String
  survey_monkey_id: String
}

type sf_pms_pms_property_eod_surveys_max_fields {
  created_at: timestamptz
  pms_id: Int
  property_id: String
  status: String
  survey_monkey_id: String
}

type sf_pms_pms_property_eod_surveys_min_fields {
  created_at: timestamptz
  pms_id: Int
  property_id: String
  status: String
  survey_monkey_id: String
}

type sf_pms_pms_property_eod_surveys_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_pms_property_eod_surveys!]!
}

input sf_pms_pms_property_eod_surveys_on_conflict {
  constraint: sf_pms_pms_property_eod_surveys_constraint!
  update_columns: [sf_pms_pms_property_eod_surveys_update_column!]! = []
  where: sf_pms_pms_property_eod_surveys_bool_exp
}

input sf_pms_pms_property_eod_surveys_order_by {
  created_at: order_by
  data: order_by
  pms_id: order_by
  pms_properties_mapping: sf_pms_pms_properties_order_by
  property_id: order_by
  status: order_by
  survey_monkey_id: order_by
}

input sf_pms_pms_property_eod_surveys_pk_columns_input {
  pms_id: Int!
  property_id: String!
  survey_monkey_id: String!
}

enum sf_pms_pms_property_eod_surveys_select_column {
  created_at
  data
  pms_id
  property_id
  status
  survey_monkey_id
}

input sf_pms_pms_property_eod_surveys_set_input {
  created_at: timestamptz
  data: json
  pms_id: Int
  property_id: String
  status: String
  survey_monkey_id: String
}

type sf_pms_pms_property_eod_surveys_stddev_fields {
  pms_id: Float
}

type sf_pms_pms_property_eod_surveys_stddev_pop_fields {
  pms_id: Float
}

type sf_pms_pms_property_eod_surveys_stddev_samp_fields {
  pms_id: Float
}

input sf_pms_pms_property_eod_surveys_stream_cursor_input {
  initial_value: sf_pms_pms_property_eod_surveys_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_pms_property_eod_surveys_stream_cursor_value_input {
  created_at: timestamptz
  data: json
  pms_id: Int
  property_id: String
  status: String
  survey_monkey_id: String
}

type sf_pms_pms_property_eod_surveys_sum_fields {
  pms_id: Int
}

enum sf_pms_pms_property_eod_surveys_update_column {
  created_at
  data
  pms_id
  property_id
  status
  survey_monkey_id
}

input sf_pms_pms_property_eod_surveys_updates {
  _inc: sf_pms_pms_property_eod_surveys_inc_input
  _set: sf_pms_pms_property_eod_surveys_set_input
  where: sf_pms_pms_property_eod_surveys_bool_exp!
}

type sf_pms_pms_property_eod_surveys_var_pop_fields {
  pms_id: Float
}

type sf_pms_pms_property_eod_surveys_var_samp_fields {
  pms_id: Float
}

type sf_pms_pms_property_eod_surveys_variance_fields {
  pms_id: Float
}

type sf_pms_pms_reservations {
  category_id: String
  check_in_date: timestamp!
  check_out_date: timestamp!
  created_date: timestamp
  guest_id: String
  is_fixed: Boolean!
  modified_date: timestamp
  notes: String
  number_of_rooms: Int!
  pms_id: Int!
  pms_reservation_recommended_room_mapping: sf_pms_pms_rooms
  pms_room_category: sf_pms_pms_room_categories
  pms_room_to_reservation_mapping: sf_pms_pms_rooms
  property_id: String!
  recommended_room_id: String
  reservation_id: String!
  reservation_ref_id: String
  room_id: String
  shared_with_reservation_id: [String!]
  status: String!
}

type sf_pms_pms_reservations_aggregate {
  aggregate: sf_pms_pms_reservations_aggregate_fields
  nodes: [sf_pms_pms_reservations!]!
}

type sf_pms_pms_reservations_aggregate_fields {
  avg: sf_pms_pms_reservations_avg_fields
  count(columns: [sf_pms_pms_reservations_select_column!], distinct: Boolean): Int!
  max: sf_pms_pms_reservations_max_fields
  min: sf_pms_pms_reservations_min_fields
  stddev: sf_pms_pms_reservations_stddev_fields
  stddev_pop: sf_pms_pms_reservations_stddev_pop_fields
  stddev_samp: sf_pms_pms_reservations_stddev_samp_fields
  sum: sf_pms_pms_reservations_sum_fields
  var_pop: sf_pms_pms_reservations_var_pop_fields
  var_samp: sf_pms_pms_reservations_var_samp_fields
  variance: sf_pms_pms_reservations_variance_fields
}

type sf_pms_pms_reservations_avg_fields {
  number_of_rooms: Float
  pms_id: Float
}

input sf_pms_pms_reservations_bool_exp {
  _and: [sf_pms_pms_reservations_bool_exp!]
  _not: sf_pms_pms_reservations_bool_exp
  _or: [sf_pms_pms_reservations_bool_exp!]
  category_id: String_comparison_exp
  check_in_date: timestamp_comparison_exp
  check_out_date: timestamp_comparison_exp
  created_date: timestamp_comparison_exp
  guest_id: String_comparison_exp
  is_fixed: Boolean_comparison_exp
  modified_date: timestamp_comparison_exp
  notes: String_comparison_exp
  number_of_rooms: Int_comparison_exp
  pms_id: Int_comparison_exp
  pms_reservation_recommended_room_mapping: sf_pms_pms_rooms_bool_exp
  pms_room_category: sf_pms_pms_room_categories_bool_exp
  pms_room_to_reservation_mapping: sf_pms_pms_rooms_bool_exp
  property_id: String_comparison_exp
  recommended_room_id: String_comparison_exp
  reservation_id: String_comparison_exp
  reservation_ref_id: String_comparison_exp
  room_id: String_comparison_exp
  shared_with_reservation_id: String_array_comparison_exp
  status: String_comparison_exp
}

enum sf_pms_pms_reservations_constraint {
  pms_reservations_pk
}

input sf_pms_pms_reservations_inc_input {
  number_of_rooms: Int
  pms_id: Int
}

input sf_pms_pms_reservations_insert_input {
  category_id: String
  check_in_date: timestamp
  check_out_date: timestamp
  created_date: timestamp
  guest_id: String
  is_fixed: Boolean
  modified_date: timestamp
  notes: String
  number_of_rooms: Int
  pms_id: Int
  pms_reservation_recommended_room_mapping: sf_pms_pms_rooms_obj_rel_insert_input
  pms_room_category: sf_pms_pms_room_categories_obj_rel_insert_input
  pms_room_to_reservation_mapping: sf_pms_pms_rooms_obj_rel_insert_input
  property_id: String
  recommended_room_id: String
  reservation_id: String
  reservation_ref_id: String
  room_id: String
  shared_with_reservation_id: [String!]
  status: String
}

type sf_pms_pms_reservations_max_fields {
  category_id: String
  check_in_date: timestamp
  check_out_date: timestamp
  created_date: timestamp
  guest_id: String
  modified_date: timestamp
  notes: String
  number_of_rooms: Int
  pms_id: Int
  property_id: String
  recommended_room_id: String
  reservation_id: String
  reservation_ref_id: String
  room_id: String
  shared_with_reservation_id: [String!]
  status: String
}

type sf_pms_pms_reservations_min_fields {
  category_id: String
  check_in_date: timestamp
  check_out_date: timestamp
  created_date: timestamp
  guest_id: String
  modified_date: timestamp
  notes: String
  number_of_rooms: Int
  pms_id: Int
  property_id: String
  recommended_room_id: String
  reservation_id: String
  reservation_ref_id: String
  room_id: String
  shared_with_reservation_id: [String!]
  status: String
}

type sf_pms_pms_reservations_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_pms_reservations!]!
}

input sf_pms_pms_reservations_on_conflict {
  constraint: sf_pms_pms_reservations_constraint!
  update_columns: [sf_pms_pms_reservations_update_column!]! = []
  where: sf_pms_pms_reservations_bool_exp
}

input sf_pms_pms_reservations_order_by {
  category_id: order_by
  check_in_date: order_by
  check_out_date: order_by
  created_date: order_by
  guest_id: order_by
  is_fixed: order_by
  modified_date: order_by
  notes: order_by
  number_of_rooms: order_by
  pms_id: order_by
  pms_reservation_recommended_room_mapping: sf_pms_pms_rooms_order_by
  pms_room_category: sf_pms_pms_room_categories_order_by
  pms_room_to_reservation_mapping: sf_pms_pms_rooms_order_by
  property_id: order_by
  recommended_room_id: order_by
  reservation_id: order_by
  reservation_ref_id: order_by
  room_id: order_by
  shared_with_reservation_id: order_by
  status: order_by
}

enum sf_pms_pms_reservations_select_column {
  category_id
  check_in_date
  check_out_date
  created_date
  guest_id
  is_fixed
  modified_date
  notes
  number_of_rooms
  pms_id
  property_id
  recommended_room_id
  reservation_id
  reservation_ref_id
  room_id
  shared_with_reservation_id
  status
}

input sf_pms_pms_reservations_set_input {
  category_id: String
  check_in_date: timestamp
  check_out_date: timestamp
  created_date: timestamp
  guest_id: String
  is_fixed: Boolean
  modified_date: timestamp
  notes: String
  number_of_rooms: Int
  pms_id: Int
  property_id: String
  recommended_room_id: String
  reservation_id: String
  reservation_ref_id: String
  room_id: String
  shared_with_reservation_id: [String!]
  status: String
}

type sf_pms_pms_reservations_stddev_fields {
  number_of_rooms: Float
  pms_id: Float
}

type sf_pms_pms_reservations_stddev_pop_fields {
  number_of_rooms: Float
  pms_id: Float
}

type sf_pms_pms_reservations_stddev_samp_fields {
  number_of_rooms: Float
  pms_id: Float
}

input sf_pms_pms_reservations_stream_cursor_input {
  initial_value: sf_pms_pms_reservations_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_pms_reservations_stream_cursor_value_input {
  category_id: String
  check_in_date: timestamp
  check_out_date: timestamp
  created_date: timestamp
  guest_id: String
  is_fixed: Boolean
  modified_date: timestamp
  notes: String
  number_of_rooms: Int
  pms_id: Int
  property_id: String
  recommended_room_id: String
  reservation_id: String
  reservation_ref_id: String
  room_id: String
  shared_with_reservation_id: [String!]
  status: String
}

type sf_pms_pms_reservations_sum_fields {
  number_of_rooms: Int
  pms_id: Int
}

enum sf_pms_pms_reservations_update_column {
  category_id
  check_in_date
  check_out_date
  created_date
  guest_id
  is_fixed
  modified_date
  notes
  number_of_rooms
  pms_id
  property_id
  recommended_room_id
  reservation_id
  reservation_ref_id
  room_id
  shared_with_reservation_id
  status
}

input sf_pms_pms_reservations_updates {
  _inc: sf_pms_pms_reservations_inc_input
  _set: sf_pms_pms_reservations_set_input
  where: sf_pms_pms_reservations_bool_exp!
}

type sf_pms_pms_reservations_var_pop_fields {
  number_of_rooms: Float
  pms_id: Float
}

type sf_pms_pms_reservations_var_samp_fields {
  number_of_rooms: Float
  pms_id: Float
}

type sf_pms_pms_reservations_variance_fields {
  number_of_rooms: Float
  pms_id: Float
}

type sf_pms_pms_room_categories {
  allow_book_by_category: Boolean!
  is_inactive: Boolean
  pms_id: Int
  pms_property: sf_pms_pms_properties
  property_id: String
  room_category_id: String
  room_category_name: String!
  sf_category_id: uuid
}

type sf_pms_pms_room_categories_aggregate {
  aggregate: sf_pms_pms_room_categories_aggregate_fields
  nodes: [sf_pms_pms_room_categories!]!
}

type sf_pms_pms_room_categories_aggregate_fields {
  avg: sf_pms_pms_room_categories_avg_fields
  count(columns: [sf_pms_pms_room_categories_select_column!], distinct: Boolean): Int!
  max: sf_pms_pms_room_categories_max_fields
  min: sf_pms_pms_room_categories_min_fields
  stddev: sf_pms_pms_room_categories_stddev_fields
  stddev_pop: sf_pms_pms_room_categories_stddev_pop_fields
  stddev_samp: sf_pms_pms_room_categories_stddev_samp_fields
  sum: sf_pms_pms_room_categories_sum_fields
  var_pop: sf_pms_pms_room_categories_var_pop_fields
  var_samp: sf_pms_pms_room_categories_var_samp_fields
  variance: sf_pms_pms_room_categories_variance_fields
}

type sf_pms_pms_room_categories_avg_fields {
  pms_id: Float
}

input sf_pms_pms_room_categories_bool_exp {
  _and: [sf_pms_pms_room_categories_bool_exp!]
  _not: sf_pms_pms_room_categories_bool_exp
  _or: [sf_pms_pms_room_categories_bool_exp!]
  allow_book_by_category: Boolean_comparison_exp
  is_inactive: Boolean_comparison_exp
  pms_id: Int_comparison_exp
  pms_property: sf_pms_pms_properties_bool_exp
  property_id: String_comparison_exp
  room_category_id: String_comparison_exp
  room_category_name: String_comparison_exp
  sf_category_id: uuid_comparison_exp
}

enum sf_pms_pms_room_categories_constraint {
  pms_room_categories_pk
  pms_room_categories_sf_category_id_unique_key
}

input sf_pms_pms_room_categories_inc_input {
  pms_id: Int
}

input sf_pms_pms_room_categories_insert_input {
  allow_book_by_category: Boolean
  is_inactive: Boolean
  pms_id: Int
  pms_property: sf_pms_pms_properties_obj_rel_insert_input
  property_id: String
  room_category_id: String
  room_category_name: String
  sf_category_id: uuid
}

type sf_pms_pms_room_categories_max_fields {
  pms_id: Int
  property_id: String
  room_category_id: String
  room_category_name: String
  sf_category_id: uuid
}

type sf_pms_pms_room_categories_min_fields {
  pms_id: Int
  property_id: String
  room_category_id: String
  room_category_name: String
  sf_category_id: uuid
}

type sf_pms_pms_room_categories_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_pms_room_categories!]!
}

input sf_pms_pms_room_categories_obj_rel_insert_input {
  data: sf_pms_pms_room_categories_insert_input!
  on_conflict: sf_pms_pms_room_categories_on_conflict
}

input sf_pms_pms_room_categories_on_conflict {
  constraint: sf_pms_pms_room_categories_constraint!
  update_columns: [sf_pms_pms_room_categories_update_column!]! = []
  where: sf_pms_pms_room_categories_bool_exp
}

input sf_pms_pms_room_categories_order_by {
  allow_book_by_category: order_by
  is_inactive: order_by
  pms_id: order_by
  pms_property: sf_pms_pms_properties_order_by
  property_id: order_by
  room_category_id: order_by
  room_category_name: order_by
  sf_category_id: order_by
}

enum sf_pms_pms_room_categories_select_column {
  allow_book_by_category
  is_inactive
  pms_id
  property_id
  room_category_id
  room_category_name
  sf_category_id
}

input sf_pms_pms_room_categories_set_input {
  allow_book_by_category: Boolean
  is_inactive: Boolean
  pms_id: Int
  property_id: String
  room_category_id: String
  room_category_name: String
  sf_category_id: uuid
}

type sf_pms_pms_room_categories_stddev_fields {
  pms_id: Float
}

type sf_pms_pms_room_categories_stddev_pop_fields {
  pms_id: Float
}

type sf_pms_pms_room_categories_stddev_samp_fields {
  pms_id: Float
}

input sf_pms_pms_room_categories_stream_cursor_input {
  initial_value: sf_pms_pms_room_categories_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_pms_room_categories_stream_cursor_value_input {
  allow_book_by_category: Boolean
  is_inactive: Boolean
  pms_id: Int
  property_id: String
  room_category_id: String
  room_category_name: String
  sf_category_id: uuid
}

type sf_pms_pms_room_categories_sum_fields {
  pms_id: Int
}

enum sf_pms_pms_room_categories_update_column {
  allow_book_by_category
  is_inactive
  pms_id
  property_id
  room_category_id
  room_category_name
  sf_category_id
}

input sf_pms_pms_room_categories_updates {
  _inc: sf_pms_pms_room_categories_inc_input
  _set: sf_pms_pms_room_categories_set_input
  where: sf_pms_pms_room_categories_bool_exp!
}

type sf_pms_pms_room_categories_var_pop_fields {
  pms_id: Float
}

type sf_pms_pms_room_categories_var_samp_fields {
  pms_id: Float
}

type sf_pms_pms_room_categories_variance_fields {
  pms_id: Float
}

type sf_pms_pms_rooms {
  interconnecting: Boolean!
  interconnecting_room_id: String
  is_inactive: Boolean
  max_occupants: Int
  modified_date: timestamp
  pms_id: Int
  position: sensorflow_positions
  position_id: uuid
  property_id: String
  room_category_id: String
  room_id: String
  room_name: String!
  room_size_square_meters: Float
  room_weight_ratio: Float
  status: String!
}

type sf_pms_pms_rooms_aggregate {
  aggregate: sf_pms_pms_rooms_aggregate_fields
  nodes: [sf_pms_pms_rooms!]!
}

type sf_pms_pms_rooms_aggregate_fields {
  avg: sf_pms_pms_rooms_avg_fields
  count(columns: [sf_pms_pms_rooms_select_column!], distinct: Boolean): Int!
  max: sf_pms_pms_rooms_max_fields
  min: sf_pms_pms_rooms_min_fields
  stddev: sf_pms_pms_rooms_stddev_fields
  stddev_pop: sf_pms_pms_rooms_stddev_pop_fields
  stddev_samp: sf_pms_pms_rooms_stddev_samp_fields
  sum: sf_pms_pms_rooms_sum_fields
  var_pop: sf_pms_pms_rooms_var_pop_fields
  var_samp: sf_pms_pms_rooms_var_samp_fields
  variance: sf_pms_pms_rooms_variance_fields
}

type sf_pms_pms_rooms_avg_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

input sf_pms_pms_rooms_bool_exp {
  _and: [sf_pms_pms_rooms_bool_exp!]
  _not: sf_pms_pms_rooms_bool_exp
  _or: [sf_pms_pms_rooms_bool_exp!]
  interconnecting: Boolean_comparison_exp
  interconnecting_room_id: String_comparison_exp
  is_inactive: Boolean_comparison_exp
  max_occupants: Int_comparison_exp
  modified_date: timestamp_comparison_exp
  pms_id: Int_comparison_exp
  position: sensorflow_positions_bool_exp
  position_id: uuid_comparison_exp
  property_id: String_comparison_exp
  room_category_id: String_comparison_exp
  room_id: String_comparison_exp
  room_name: String_comparison_exp
  room_size_square_meters: Float_comparison_exp
  room_weight_ratio: Float_comparison_exp
  status: String_comparison_exp
}

enum sf_pms_pms_rooms_constraint {
  pms_rooms_pk
  pms_rooms_position_id_unique_idx
}

input sf_pms_pms_rooms_inc_input {
  max_occupants: Int
  pms_id: Int
  room_size_square_meters: Float
  room_weight_ratio: Float
}

input sf_pms_pms_rooms_insert_input {
  interconnecting: Boolean
  interconnecting_room_id: String
  is_inactive: Boolean
  max_occupants: Int
  modified_date: timestamp
  pms_id: Int
  position: sensorflow_positions_obj_rel_insert_input
  position_id: uuid
  property_id: String
  room_category_id: String
  room_id: String
  room_name: String
  room_size_square_meters: Float
  room_weight_ratio: Float
  status: String
}

type sf_pms_pms_rooms_max_fields {
  interconnecting_room_id: String
  max_occupants: Int
  modified_date: timestamp
  pms_id: Int
  position_id: uuid
  property_id: String
  room_category_id: String
  room_id: String
  room_name: String
  room_size_square_meters: Float
  room_weight_ratio: Float
  status: String
}

type sf_pms_pms_rooms_min_fields {
  interconnecting_room_id: String
  max_occupants: Int
  modified_date: timestamp
  pms_id: Int
  position_id: uuid
  property_id: String
  room_category_id: String
  room_id: String
  room_name: String
  room_size_square_meters: Float
  room_weight_ratio: Float
  status: String
}

type sf_pms_pms_rooms_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_pms_rooms!]!
}

input sf_pms_pms_rooms_obj_rel_insert_input {
  data: sf_pms_pms_rooms_insert_input!
  on_conflict: sf_pms_pms_rooms_on_conflict
}

input sf_pms_pms_rooms_on_conflict {
  constraint: sf_pms_pms_rooms_constraint!
  update_columns: [sf_pms_pms_rooms_update_column!]! = []
  where: sf_pms_pms_rooms_bool_exp
}

input sf_pms_pms_rooms_order_by {
  interconnecting: order_by
  interconnecting_room_id: order_by
  is_inactive: order_by
  max_occupants: order_by
  modified_date: order_by
  pms_id: order_by
  position: sensorflow_positions_order_by
  position_id: order_by
  property_id: order_by
  room_category_id: order_by
  room_id: order_by
  room_name: order_by
  room_size_square_meters: order_by
  room_weight_ratio: order_by
  status: order_by
}

enum sf_pms_pms_rooms_select_column {
  interconnecting
  interconnecting_room_id
  is_inactive
  max_occupants
  modified_date
  pms_id
  position_id
  property_id
  room_category_id
  room_id
  room_name
  room_size_square_meters
  room_weight_ratio
  status
}

input sf_pms_pms_rooms_set_input {
  interconnecting: Boolean
  interconnecting_room_id: String
  is_inactive: Boolean
  max_occupants: Int
  modified_date: timestamp
  pms_id: Int
  position_id: uuid
  property_id: String
  room_category_id: String
  room_id: String
  room_name: String
  room_size_square_meters: Float
  room_weight_ratio: Float
  status: String
}

type sf_pms_pms_rooms_stddev_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

type sf_pms_pms_rooms_stddev_pop_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

type sf_pms_pms_rooms_stddev_samp_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

input sf_pms_pms_rooms_stream_cursor_input {
  initial_value: sf_pms_pms_rooms_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_pms_rooms_stream_cursor_value_input {
  interconnecting: Boolean
  interconnecting_room_id: String
  is_inactive: Boolean
  max_occupants: Int
  modified_date: timestamp
  pms_id: Int
  position_id: uuid
  property_id: String
  room_category_id: String
  room_id: String
  room_name: String
  room_size_square_meters: Float
  room_weight_ratio: Float
  status: String
}

type sf_pms_pms_rooms_sum_fields {
  max_occupants: Int
  pms_id: Int
  room_size_square_meters: Float
  room_weight_ratio: Float
}

enum sf_pms_pms_rooms_update_column {
  interconnecting
  interconnecting_room_id
  is_inactive
  max_occupants
  modified_date
  pms_id
  position_id
  property_id
  room_category_id
  room_id
  room_name
  room_size_square_meters
  room_weight_ratio
  status
}

input sf_pms_pms_rooms_updates {
  _inc: sf_pms_pms_rooms_inc_input
  _set: sf_pms_pms_rooms_set_input
  where: sf_pms_pms_rooms_bool_exp!
}

type sf_pms_pms_rooms_var_pop_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

type sf_pms_pms_rooms_var_samp_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

type sf_pms_pms_rooms_variance_fields {
  max_occupants: Float
  pms_id: Float
  room_size_square_meters: Float
  room_weight_ratio: Float
}

type sf_pms_smartalloc_report {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  billingCurrency: String!
  date: date!
  followedRecommendationRatio: Float
  lastModified: timestamp
  location: sensorflow_locations
  locationId: uuid!
  ninetyAdherenceSavingsKwh: Float!
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float!
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float!
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
  type: String!
}

type sf_pms_smartalloc_report_aggregate {
  aggregate: sf_pms_smartalloc_report_aggregate_fields
  nodes: [sf_pms_smartalloc_report!]!
}

type sf_pms_smartalloc_report_aggregate_fields {
  avg: sf_pms_smartalloc_report_avg_fields
  count(columns: [sf_pms_smartalloc_report_select_column!], distinct: Boolean): Int!
  max: sf_pms_smartalloc_report_max_fields
  min: sf_pms_smartalloc_report_min_fields
  stddev: sf_pms_smartalloc_report_stddev_fields
  stddev_pop: sf_pms_smartalloc_report_stddev_pop_fields
  stddev_samp: sf_pms_smartalloc_report_stddev_samp_fields
  sum: sf_pms_smartalloc_report_sum_fields
  var_pop: sf_pms_smartalloc_report_var_pop_fields
  var_samp: sf_pms_smartalloc_report_var_samp_fields
  variance: sf_pms_smartalloc_report_variance_fields
}

type sf_pms_smartalloc_report_avg_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

input sf_pms_smartalloc_report_bool_exp {
  _and: [sf_pms_smartalloc_report_bool_exp!]
  _not: sf_pms_smartalloc_report_bool_exp
  _or: [sf_pms_smartalloc_report_bool_exp!]
  averageDailyRunningCompressors: Float_comparison_exp
  averageOccupancy: Float_comparison_exp
  billingCurrency: String_comparison_exp
  date: date_comparison_exp
  followedRecommendationRatio: Float_comparison_exp
  lastModified: timestamp_comparison_exp
  location: sensorflow_locations_bool_exp
  locationId: uuid_comparison_exp
  ninetyAdherenceSavingsKwh: Float_comparison_exp
  ninetyAdherenceSavingsLocalCurrency: Float_comparison_exp
  ninetyAdherenceSavingsSgd: Float_comparison_exp
  numberOfFollowedRecommendation: Int_comparison_exp
  numberOfRecommendation: Int_comparison_exp
  numberOfReservations: Int_comparison_exp
  savingsKwh: Float_comparison_exp
  savingsLocalCurrency: Float_comparison_exp
  savingsPercentage: Float_comparison_exp
  savingsSgd: Float_comparison_exp
  simulatedNoSmartallocConsumptionKwh: Float_comparison_exp
  simulatedNoSmartallocDailyCompressors: Float_comparison_exp
  totalConsumptionKwh: Float_comparison_exp
  type: String_comparison_exp
}

enum sf_pms_smartalloc_report_constraint {
  smartalloc_report_pkey
}

input sf_pms_smartalloc_report_inc_input {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

input sf_pms_smartalloc_report_insert_input {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  billingCurrency: String
  date: date
  followedRecommendationRatio: Float
  lastModified: timestamp
  location: sensorflow_locations_obj_rel_insert_input
  locationId: uuid
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
  type: String
}

type sf_pms_smartalloc_report_max_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  billingCurrency: String
  date: date
  followedRecommendationRatio: Float
  lastModified: timestamp
  locationId: uuid
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
  type: String
}

type sf_pms_smartalloc_report_min_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  billingCurrency: String
  date: date
  followedRecommendationRatio: Float
  lastModified: timestamp
  locationId: uuid
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
  type: String
}

type sf_pms_smartalloc_report_mutation_response {
  affected_rows: Int!
  returning: [sf_pms_smartalloc_report!]!
}

input sf_pms_smartalloc_report_on_conflict {
  constraint: sf_pms_smartalloc_report_constraint!
  update_columns: [sf_pms_smartalloc_report_update_column!]! = []
  where: sf_pms_smartalloc_report_bool_exp
}

input sf_pms_smartalloc_report_order_by {
  averageDailyRunningCompressors: order_by
  averageOccupancy: order_by
  billingCurrency: order_by
  date: order_by
  followedRecommendationRatio: order_by
  lastModified: order_by
  location: sensorflow_locations_order_by
  locationId: order_by
  ninetyAdherenceSavingsKwh: order_by
  ninetyAdherenceSavingsLocalCurrency: order_by
  ninetyAdherenceSavingsSgd: order_by
  numberOfFollowedRecommendation: order_by
  numberOfRecommendation: order_by
  numberOfReservations: order_by
  savingsKwh: order_by
  savingsLocalCurrency: order_by
  savingsPercentage: order_by
  savingsSgd: order_by
  simulatedNoSmartallocConsumptionKwh: order_by
  simulatedNoSmartallocDailyCompressors: order_by
  totalConsumptionKwh: order_by
  type: order_by
}

input sf_pms_smartalloc_report_pk_columns_input {
  date: date!
  locationId: uuid!
  type: String!
}

enum sf_pms_smartalloc_report_select_column {
  averageDailyRunningCompressors
  averageOccupancy
  billingCurrency
  date
  followedRecommendationRatio
  lastModified
  locationId
  ninetyAdherenceSavingsKwh
  ninetyAdherenceSavingsLocalCurrency
  ninetyAdherenceSavingsSgd
  numberOfFollowedRecommendation
  numberOfRecommendation
  numberOfReservations
  savingsKwh
  savingsLocalCurrency
  savingsPercentage
  savingsSgd
  simulatedNoSmartallocConsumptionKwh
  simulatedNoSmartallocDailyCompressors
  totalConsumptionKwh
  type
}

input sf_pms_smartalloc_report_set_input {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  billingCurrency: String
  date: date
  followedRecommendationRatio: Float
  lastModified: timestamp
  locationId: uuid
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
  type: String
}

type sf_pms_smartalloc_report_stddev_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

type sf_pms_smartalloc_report_stddev_pop_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

type sf_pms_smartalloc_report_stddev_samp_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

input sf_pms_smartalloc_report_stream_cursor_input {
  initial_value: sf_pms_smartalloc_report_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_pms_smartalloc_report_stream_cursor_value_input {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  billingCurrency: String
  date: date
  followedRecommendationRatio: Float
  lastModified: timestamp
  locationId: uuid
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
  type: String
}

type sf_pms_smartalloc_report_sum_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Int
  numberOfRecommendation: Int
  numberOfReservations: Int
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

enum sf_pms_smartalloc_report_update_column {
  averageDailyRunningCompressors
  averageOccupancy
  billingCurrency
  date
  followedRecommendationRatio
  lastModified
  locationId
  ninetyAdherenceSavingsKwh
  ninetyAdherenceSavingsLocalCurrency
  ninetyAdherenceSavingsSgd
  numberOfFollowedRecommendation
  numberOfRecommendation
  numberOfReservations
  savingsKwh
  savingsLocalCurrency
  savingsPercentage
  savingsSgd
  simulatedNoSmartallocConsumptionKwh
  simulatedNoSmartallocDailyCompressors
  totalConsumptionKwh
  type
}

input sf_pms_smartalloc_report_updates {
  _inc: sf_pms_smartalloc_report_inc_input
  _set: sf_pms_smartalloc_report_set_input
  where: sf_pms_smartalloc_report_bool_exp!
}

type sf_pms_smartalloc_report_var_pop_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

type sf_pms_smartalloc_report_var_samp_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

type sf_pms_smartalloc_report_variance_fields {
  averageDailyRunningCompressors: Float
  averageOccupancy: Float
  followedRecommendationRatio: Float
  ninetyAdherenceSavingsKwh: Float
  ninetyAdherenceSavingsLocalCurrency: Float
  ninetyAdherenceSavingsSgd: Float
  numberOfFollowedRecommendation: Float
  numberOfRecommendation: Float
  numberOfReservations: Float
  savingsKwh: Float
  savingsLocalCurrency: Float
  savingsPercentage: Float
  savingsSgd: Float
  simulatedNoSmartallocConsumptionKwh: Float
  simulatedNoSmartallocDailyCompressors: Float
  totalConsumptionKwh: Float
}

type sf_simulation_instrumentation_commands {
  commands(path: String): json!
  data(path: String): json!
  id: Int!
  location: sensorflow_locations!
  locationId: uuid!
  node: sensorflow_nodes!
  nodeMacId: String!
  updatedAt: timestamptz!
}

type sf_simulation_instrumentation_commands_aggregate {
  aggregate: sf_simulation_instrumentation_commands_aggregate_fields
  nodes: [sf_simulation_instrumentation_commands!]!
}

type sf_simulation_instrumentation_commands_aggregate_fields {
  avg: sf_simulation_instrumentation_commands_avg_fields
  count(columns: [sf_simulation_instrumentation_commands_select_column!], distinct: Boolean): Int!
  max: sf_simulation_instrumentation_commands_max_fields
  min: sf_simulation_instrumentation_commands_min_fields
  stddev: sf_simulation_instrumentation_commands_stddev_fields
  stddev_pop: sf_simulation_instrumentation_commands_stddev_pop_fields
  stddev_samp: sf_simulation_instrumentation_commands_stddev_samp_fields
  sum: sf_simulation_instrumentation_commands_sum_fields
  var_pop: sf_simulation_instrumentation_commands_var_pop_fields
  var_samp: sf_simulation_instrumentation_commands_var_samp_fields
  variance: sf_simulation_instrumentation_commands_variance_fields
}

type sf_simulation_instrumentation_commands_avg_fields {
  id: Float
}

input sf_simulation_instrumentation_commands_bool_exp {
  _and: [sf_simulation_instrumentation_commands_bool_exp!]
  _not: sf_simulation_instrumentation_commands_bool_exp
  _or: [sf_simulation_instrumentation_commands_bool_exp!]
  commands: json_comparison_exp
  data: json_comparison_exp
  id: Int_comparison_exp
  location: sensorflow_locations_bool_exp
  locationId: uuid_comparison_exp
  node: sensorflow_nodes_bool_exp
  nodeMacId: String_comparison_exp
  updatedAt: timestamptz_comparison_exp
}

enum sf_simulation_instrumentation_commands_constraint {
  instrumentation_commands_pkey
}

input sf_simulation_instrumentation_commands_inc_input {
  id: Int
}

input sf_simulation_instrumentation_commands_insert_input {
  commands: json
  data: json
  id: Int
  location: sensorflow_locations_obj_rel_insert_input
  locationId: uuid
  node: sensorflow_nodes_obj_rel_insert_input
  nodeMacId: String
  updatedAt: timestamptz
}

type sf_simulation_instrumentation_commands_max_fields {
  id: Int
  locationId: uuid
  nodeMacId: String
  updatedAt: timestamptz
}

type sf_simulation_instrumentation_commands_min_fields {
  id: Int
  locationId: uuid
  nodeMacId: String
  updatedAt: timestamptz
}

type sf_simulation_instrumentation_commands_mutation_response {
  affected_rows: Int!
  returning: [sf_simulation_instrumentation_commands!]!
}

input sf_simulation_instrumentation_commands_on_conflict {
  constraint: sf_simulation_instrumentation_commands_constraint!
  update_columns: [sf_simulation_instrumentation_commands_update_column!]! = []
  where: sf_simulation_instrumentation_commands_bool_exp
}

input sf_simulation_instrumentation_commands_order_by {
  commands: order_by
  data: order_by
  id: order_by
  location: sensorflow_locations_order_by
  locationId: order_by
  node: sensorflow_nodes_order_by
  nodeMacId: order_by
  updatedAt: order_by
}

input sf_simulation_instrumentation_commands_pk_columns_input {
  id: Int!
}

enum sf_simulation_instrumentation_commands_select_column {
  commands
  data
  id
  locationId
  nodeMacId
  updatedAt
}

input sf_simulation_instrumentation_commands_set_input {
  commands: json
  data: json
  id: Int
  locationId: uuid
  nodeMacId: String
  updatedAt: timestamptz
}

type sf_simulation_instrumentation_commands_stddev_fields {
  id: Float
}

type sf_simulation_instrumentation_commands_stddev_pop_fields {
  id: Float
}

type sf_simulation_instrumentation_commands_stddev_samp_fields {
  id: Float
}

input sf_simulation_instrumentation_commands_stream_cursor_input {
  initial_value: sf_simulation_instrumentation_commands_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_simulation_instrumentation_commands_stream_cursor_value_input {
  commands: json
  data: json
  id: Int
  locationId: uuid
  nodeMacId: String
  updatedAt: timestamptz
}

type sf_simulation_instrumentation_commands_sum_fields {
  id: Int
}

enum sf_simulation_instrumentation_commands_update_column {
  commands
  data
  id
  locationId
  nodeMacId
  updatedAt
}

input sf_simulation_instrumentation_commands_updates {
  _inc: sf_simulation_instrumentation_commands_inc_input
  _set: sf_simulation_instrumentation_commands_set_input
  where: sf_simulation_instrumentation_commands_bool_exp!
}

type sf_simulation_instrumentation_commands_var_pop_fields {
  id: Float
}

type sf_simulation_instrumentation_commands_var_samp_fields {
  id: Float
}

type sf_simulation_instrumentation_commands_variance_fields {
  id: Float
}

type sf_support_actions {
  action: String!
  id: Int!
  instructionLink: String
}

type sf_support_actions_aggregate {
  aggregate: sf_support_actions_aggregate_fields
  nodes: [sf_support_actions!]!
}

type sf_support_actions_aggregate_fields {
  avg: sf_support_actions_avg_fields
  count(columns: [sf_support_actions_select_column!], distinct: Boolean): Int!
  max: sf_support_actions_max_fields
  min: sf_support_actions_min_fields
  stddev: sf_support_actions_stddev_fields
  stddev_pop: sf_support_actions_stddev_pop_fields
  stddev_samp: sf_support_actions_stddev_samp_fields
  sum: sf_support_actions_sum_fields
  var_pop: sf_support_actions_var_pop_fields
  var_samp: sf_support_actions_var_samp_fields
  variance: sf_support_actions_variance_fields
}

type sf_support_actions_avg_fields {
  id: Float
}

input sf_support_actions_bool_exp {
  _and: [sf_support_actions_bool_exp!]
  _not: sf_support_actions_bool_exp
  _or: [sf_support_actions_bool_exp!]
  action: String_comparison_exp
  id: Int_comparison_exp
  instructionLink: String_comparison_exp
}

enum sf_support_actions_constraint {
  actions_pkey
}

input sf_support_actions_inc_input {
  id: Int
}

input sf_support_actions_insert_input {
  action: String
  id: Int
  instructionLink: String
}

type sf_support_actions_max_fields {
  action: String
  id: Int
  instructionLink: String
}

type sf_support_actions_min_fields {
  action: String
  id: Int
  instructionLink: String
}

type sf_support_actions_mutation_response {
  affected_rows: Int!
  returning: [sf_support_actions!]!
}

input sf_support_actions_obj_rel_insert_input {
  data: sf_support_actions_insert_input!
  on_conflict: sf_support_actions_on_conflict
}

input sf_support_actions_on_conflict {
  constraint: sf_support_actions_constraint!
  update_columns: [sf_support_actions_update_column!]! = []
  where: sf_support_actions_bool_exp
}

input sf_support_actions_order_by {
  action: order_by
  id: order_by
  instructionLink: order_by
}

input sf_support_actions_pk_columns_input {
  id: Int!
}

enum sf_support_actions_select_column {
  action
  id
  instructionLink
}

input sf_support_actions_set_input {
  action: String
  id: Int
  instructionLink: String
}

type sf_support_actions_stddev_fields {
  id: Float
}

type sf_support_actions_stddev_pop_fields {
  id: Float
}

type sf_support_actions_stddev_samp_fields {
  id: Float
}

input sf_support_actions_stream_cursor_input {
  initial_value: sf_support_actions_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_actions_stream_cursor_value_input {
  action: String
  id: Int
  instructionLink: String
}

type sf_support_actions_sum_fields {
  id: Int
}

enum sf_support_actions_update_column {
  action
  id
  instructionLink
}

input sf_support_actions_updates {
  _inc: sf_support_actions_inc_input
  _set: sf_support_actions_set_input
  where: sf_support_actions_bool_exp!
}

type sf_support_actions_var_pop_fields {
  id: Float
}

type sf_support_actions_var_samp_fields {
  id: Float
}

type sf_support_actions_variance_fields {
  id: Float
}

type sf_support_active_property_alerts {
  active: Boolean
  locationId: uuid!
  ticketClassId: Int!
}

type sf_support_active_property_alerts_aggregate {
  aggregate: sf_support_active_property_alerts_aggregate_fields
  nodes: [sf_support_active_property_alerts!]!
}

input sf_support_active_property_alerts_aggregate_bool_exp {
  bool_and: sf_support_active_property_alerts_aggregate_bool_exp_bool_and
  bool_or: sf_support_active_property_alerts_aggregate_bool_exp_bool_or
  count: sf_support_active_property_alerts_aggregate_bool_exp_count
}

input sf_support_active_property_alerts_aggregate_bool_exp_bool_and {
  arguments: sf_support_active_property_alerts_select_column_sf_support_active_property_alerts_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: sf_support_active_property_alerts_bool_exp
  predicate: Boolean_comparison_exp!
}

input sf_support_active_property_alerts_aggregate_bool_exp_bool_or {
  arguments: sf_support_active_property_alerts_select_column_sf_support_active_property_alerts_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: sf_support_active_property_alerts_bool_exp
  predicate: Boolean_comparison_exp!
}

input sf_support_active_property_alerts_aggregate_bool_exp_count {
  arguments: [sf_support_active_property_alerts_select_column!]
  distinct: Boolean
  filter: sf_support_active_property_alerts_bool_exp
  predicate: Int_comparison_exp!
}

type sf_support_active_property_alerts_aggregate_fields {
  avg: sf_support_active_property_alerts_avg_fields
  count(columns: [sf_support_active_property_alerts_select_column!], distinct: Boolean): Int!
  max: sf_support_active_property_alerts_max_fields
  min: sf_support_active_property_alerts_min_fields
  stddev: sf_support_active_property_alerts_stddev_fields
  stddev_pop: sf_support_active_property_alerts_stddev_pop_fields
  stddev_samp: sf_support_active_property_alerts_stddev_samp_fields
  sum: sf_support_active_property_alerts_sum_fields
  var_pop: sf_support_active_property_alerts_var_pop_fields
  var_samp: sf_support_active_property_alerts_var_samp_fields
  variance: sf_support_active_property_alerts_variance_fields
}

input sf_support_active_property_alerts_aggregate_order_by {
  avg: sf_support_active_property_alerts_avg_order_by
  count: order_by
  max: sf_support_active_property_alerts_max_order_by
  min: sf_support_active_property_alerts_min_order_by
  stddev: sf_support_active_property_alerts_stddev_order_by
  stddev_pop: sf_support_active_property_alerts_stddev_pop_order_by
  stddev_samp: sf_support_active_property_alerts_stddev_samp_order_by
  sum: sf_support_active_property_alerts_sum_order_by
  var_pop: sf_support_active_property_alerts_var_pop_order_by
  var_samp: sf_support_active_property_alerts_var_samp_order_by
  variance: sf_support_active_property_alerts_variance_order_by
}

input sf_support_active_property_alerts_arr_rel_insert_input {
  data: [sf_support_active_property_alerts_insert_input!]!
  on_conflict: sf_support_active_property_alerts_on_conflict
}

type sf_support_active_property_alerts_avg_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_avg_order_by {
  ticketClassId: order_by
}

input sf_support_active_property_alerts_bool_exp {
  _and: [sf_support_active_property_alerts_bool_exp!]
  _not: sf_support_active_property_alerts_bool_exp
  _or: [sf_support_active_property_alerts_bool_exp!]
  active: Boolean_comparison_exp
  locationId: uuid_comparison_exp
  ticketClassId: Int_comparison_exp
}

enum sf_support_active_property_alerts_constraint {
  active_property_alerts_pkey
}

input sf_support_active_property_alerts_inc_input {
  ticketClassId: Int
}

input sf_support_active_property_alerts_insert_input {
  active: Boolean
  locationId: uuid
  ticketClassId: Int
}

type sf_support_active_property_alerts_max_fields {
  locationId: uuid
  ticketClassId: Int
}

input sf_support_active_property_alerts_max_order_by {
  locationId: order_by
  ticketClassId: order_by
}

type sf_support_active_property_alerts_min_fields {
  locationId: uuid
  ticketClassId: Int
}

input sf_support_active_property_alerts_min_order_by {
  locationId: order_by
  ticketClassId: order_by
}

type sf_support_active_property_alerts_mutation_response {
  affected_rows: Int!
  returning: [sf_support_active_property_alerts!]!
}

input sf_support_active_property_alerts_on_conflict {
  constraint: sf_support_active_property_alerts_constraint!
  update_columns: [sf_support_active_property_alerts_update_column!]! = []
  where: sf_support_active_property_alerts_bool_exp
}

input sf_support_active_property_alerts_order_by {
  active: order_by
  locationId: order_by
  ticketClassId: order_by
}

input sf_support_active_property_alerts_pk_columns_input {
  locationId: uuid!
  ticketClassId: Int!
}

enum sf_support_active_property_alerts_select_column {
  active
  locationId
  ticketClassId
}

enum sf_support_active_property_alerts_select_column_sf_support_active_property_alerts_aggregate_bool_exp_bool_and_arguments_columns {
  active
}

enum sf_support_active_property_alerts_select_column_sf_support_active_property_alerts_aggregate_bool_exp_bool_or_arguments_columns {
  active
}

input sf_support_active_property_alerts_set_input {
  active: Boolean
  locationId: uuid
  ticketClassId: Int
}

type sf_support_active_property_alerts_stddev_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_stddev_order_by {
  ticketClassId: order_by
}

type sf_support_active_property_alerts_stddev_pop_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_stddev_pop_order_by {
  ticketClassId: order_by
}

type sf_support_active_property_alerts_stddev_samp_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_stddev_samp_order_by {
  ticketClassId: order_by
}

input sf_support_active_property_alerts_stream_cursor_input {
  initial_value: sf_support_active_property_alerts_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_active_property_alerts_stream_cursor_value_input {
  active: Boolean
  locationId: uuid
  ticketClassId: Int
}

type sf_support_active_property_alerts_sum_fields {
  ticketClassId: Int
}

input sf_support_active_property_alerts_sum_order_by {
  ticketClassId: order_by
}

enum sf_support_active_property_alerts_update_column {
  active
  locationId
  ticketClassId
}

input sf_support_active_property_alerts_updates {
  _inc: sf_support_active_property_alerts_inc_input
  _set: sf_support_active_property_alerts_set_input
  where: sf_support_active_property_alerts_bool_exp!
}

type sf_support_active_property_alerts_var_pop_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_var_pop_order_by {
  ticketClassId: order_by
}

type sf_support_active_property_alerts_var_samp_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_var_samp_order_by {
  ticketClassId: order_by
}

type sf_support_active_property_alerts_variance_fields {
  ticketClassId: Float
}

input sf_support_active_property_alerts_variance_order_by {
  ticketClassId: order_by
}

type sf_support_analytics_ticket_jobs_running_histories {
  id: Int!
  latest_analytics_run: timestamptz
  latest_daily_run: timestamptz
  run_at: timestamptz!
}

type sf_support_analytics_ticket_jobs_running_histories_aggregate {
  aggregate: sf_support_analytics_ticket_jobs_running_histories_aggregate_fields
  nodes: [sf_support_analytics_ticket_jobs_running_histories!]!
}

type sf_support_analytics_ticket_jobs_running_histories_aggregate_fields {
  avg: sf_support_analytics_ticket_jobs_running_histories_avg_fields
  count(columns: [sf_support_analytics_ticket_jobs_running_histories_select_column!], distinct: Boolean): Int!
  max: sf_support_analytics_ticket_jobs_running_histories_max_fields
  min: sf_support_analytics_ticket_jobs_running_histories_min_fields
  stddev: sf_support_analytics_ticket_jobs_running_histories_stddev_fields
  stddev_pop: sf_support_analytics_ticket_jobs_running_histories_stddev_pop_fields
  stddev_samp: sf_support_analytics_ticket_jobs_running_histories_stddev_samp_fields
  sum: sf_support_analytics_ticket_jobs_running_histories_sum_fields
  var_pop: sf_support_analytics_ticket_jobs_running_histories_var_pop_fields
  var_samp: sf_support_analytics_ticket_jobs_running_histories_var_samp_fields
  variance: sf_support_analytics_ticket_jobs_running_histories_variance_fields
}

type sf_support_analytics_ticket_jobs_running_histories_avg_fields {
  id: Float
}

input sf_support_analytics_ticket_jobs_running_histories_bool_exp {
  _and: [sf_support_analytics_ticket_jobs_running_histories_bool_exp!]
  _not: sf_support_analytics_ticket_jobs_running_histories_bool_exp
  _or: [sf_support_analytics_ticket_jobs_running_histories_bool_exp!]
  id: Int_comparison_exp
  latest_analytics_run: timestamptz_comparison_exp
  latest_daily_run: timestamptz_comparison_exp
  run_at: timestamptz_comparison_exp
}

enum sf_support_analytics_ticket_jobs_running_histories_constraint {
  analytics_ticket_jobs_running_histories_pkey
}

input sf_support_analytics_ticket_jobs_running_histories_inc_input {
  id: Int
}

input sf_support_analytics_ticket_jobs_running_histories_insert_input {
  id: Int
  latest_analytics_run: timestamptz
  latest_daily_run: timestamptz
  run_at: timestamptz
}

type sf_support_analytics_ticket_jobs_running_histories_max_fields {
  id: Int
  latest_analytics_run: timestamptz
  latest_daily_run: timestamptz
  run_at: timestamptz
}

type sf_support_analytics_ticket_jobs_running_histories_min_fields {
  id: Int
  latest_analytics_run: timestamptz
  latest_daily_run: timestamptz
  run_at: timestamptz
}

type sf_support_analytics_ticket_jobs_running_histories_mutation_response {
  affected_rows: Int!
  returning: [sf_support_analytics_ticket_jobs_running_histories!]!
}

input sf_support_analytics_ticket_jobs_running_histories_on_conflict {
  constraint: sf_support_analytics_ticket_jobs_running_histories_constraint!
  update_columns: [sf_support_analytics_ticket_jobs_running_histories_update_column!]! = []
  where: sf_support_analytics_ticket_jobs_running_histories_bool_exp
}

input sf_support_analytics_ticket_jobs_running_histories_order_by {
  id: order_by
  latest_analytics_run: order_by
  latest_daily_run: order_by
  run_at: order_by
}

input sf_support_analytics_ticket_jobs_running_histories_pk_columns_input {
  id: Int!
}

enum sf_support_analytics_ticket_jobs_running_histories_select_column {
  id
  latest_analytics_run
  latest_daily_run
  run_at
}

input sf_support_analytics_ticket_jobs_running_histories_set_input {
  id: Int
  latest_analytics_run: timestamptz
  latest_daily_run: timestamptz
  run_at: timestamptz
}

type sf_support_analytics_ticket_jobs_running_histories_stddev_fields {
  id: Float
}

type sf_support_analytics_ticket_jobs_running_histories_stddev_pop_fields {
  id: Float
}

type sf_support_analytics_ticket_jobs_running_histories_stddev_samp_fields {
  id: Float
}

input sf_support_analytics_ticket_jobs_running_histories_stream_cursor_input {
  initial_value: sf_support_analytics_ticket_jobs_running_histories_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_analytics_ticket_jobs_running_histories_stream_cursor_value_input {
  id: Int
  latest_analytics_run: timestamptz
  latest_daily_run: timestamptz
  run_at: timestamptz
}

type sf_support_analytics_ticket_jobs_running_histories_sum_fields {
  id: Int
}

enum sf_support_analytics_ticket_jobs_running_histories_update_column {
  id
  latest_analytics_run
  latest_daily_run
  run_at
}

input sf_support_analytics_ticket_jobs_running_histories_updates {
  _inc: sf_support_analytics_ticket_jobs_running_histories_inc_input
  _set: sf_support_analytics_ticket_jobs_running_histories_set_input
  where: sf_support_analytics_ticket_jobs_running_histories_bool_exp!
}

type sf_support_analytics_ticket_jobs_running_histories_var_pop_fields {
  id: Float
}

type sf_support_analytics_ticket_jobs_running_histories_var_samp_fields {
  id: Float
}

type sf_support_analytics_ticket_jobs_running_histories_variance_fields {
  id: Float
}

type sf_support_root_causes {
  id: Int!
  root_cause: String!
}

type sf_support_root_causes_aggregate {
  aggregate: sf_support_root_causes_aggregate_fields
  nodes: [sf_support_root_causes!]!
}

type sf_support_root_causes_aggregate_fields {
  avg: sf_support_root_causes_avg_fields
  count(columns: [sf_support_root_causes_select_column!], distinct: Boolean): Int!
  max: sf_support_root_causes_max_fields
  min: sf_support_root_causes_min_fields
  stddev: sf_support_root_causes_stddev_fields
  stddev_pop: sf_support_root_causes_stddev_pop_fields
  stddev_samp: sf_support_root_causes_stddev_samp_fields
  sum: sf_support_root_causes_sum_fields
  var_pop: sf_support_root_causes_var_pop_fields
  var_samp: sf_support_root_causes_var_samp_fields
  variance: sf_support_root_causes_variance_fields
}

type sf_support_root_causes_avg_fields {
  id: Float
}

input sf_support_root_causes_bool_exp {
  _and: [sf_support_root_causes_bool_exp!]
  _not: sf_support_root_causes_bool_exp
  _or: [sf_support_root_causes_bool_exp!]
  id: Int_comparison_exp
  root_cause: String_comparison_exp
}

enum sf_support_root_causes_constraint {
  root_causes_pkey
  rootcause_unique_idx
}

input sf_support_root_causes_inc_input {
  id: Int
}

input sf_support_root_causes_insert_input {
  id: Int
  root_cause: String
}

type sf_support_root_causes_max_fields {
  id: Int
  root_cause: String
}

type sf_support_root_causes_min_fields {
  id: Int
  root_cause: String
}

type sf_support_root_causes_mutation_response {
  affected_rows: Int!
  returning: [sf_support_root_causes!]!
}

input sf_support_root_causes_obj_rel_insert_input {
  data: sf_support_root_causes_insert_input!
  on_conflict: sf_support_root_causes_on_conflict
}

input sf_support_root_causes_on_conflict {
  constraint: sf_support_root_causes_constraint!
  update_columns: [sf_support_root_causes_update_column!]! = []
  where: sf_support_root_causes_bool_exp
}

input sf_support_root_causes_order_by {
  id: order_by
  root_cause: order_by
}

input sf_support_root_causes_pk_columns_input {
  id: Int!
}

enum sf_support_root_causes_select_column {
  id
  root_cause
}

input sf_support_root_causes_set_input {
  id: Int
  root_cause: String
}

type sf_support_root_causes_stddev_fields {
  id: Float
}

type sf_support_root_causes_stddev_pop_fields {
  id: Float
}

type sf_support_root_causes_stddev_samp_fields {
  id: Float
}

input sf_support_root_causes_stream_cursor_input {
  initial_value: sf_support_root_causes_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_root_causes_stream_cursor_value_input {
  id: Int
  root_cause: String
}

type sf_support_root_causes_sum_fields {
  id: Int
}

enum sf_support_root_causes_update_column {
  id
  root_cause
}

input sf_support_root_causes_updates {
  _inc: sf_support_root_causes_inc_input
  _set: sf_support_root_causes_set_input
  where: sf_support_root_causes_bool_exp!
}

type sf_support_root_causes_var_pop_fields {
  id: Float
}

type sf_support_root_causes_var_samp_fields {
  id: Float
}

type sf_support_root_causes_variance_fields {
  id: Float
}

type sf_support_ticket_classes {
  accelo_class_id: Int
  active: Boolean
  auto_closeable: Boolean!
  description: String
  id: Int!
  straight_to_jrf: Boolean
  suggestedActionId: Int
  suggestedRootCauseId: Int
  supportManualLink: String
  ticketClass: String!
}

type sf_support_ticket_classes_aggregate {
  aggregate: sf_support_ticket_classes_aggregate_fields
  nodes: [sf_support_ticket_classes!]!
}

type sf_support_ticket_classes_aggregate_fields {
  avg: sf_support_ticket_classes_avg_fields
  count(columns: [sf_support_ticket_classes_select_column!], distinct: Boolean): Int!
  max: sf_support_ticket_classes_max_fields
  min: sf_support_ticket_classes_min_fields
  stddev: sf_support_ticket_classes_stddev_fields
  stddev_pop: sf_support_ticket_classes_stddev_pop_fields
  stddev_samp: sf_support_ticket_classes_stddev_samp_fields
  sum: sf_support_ticket_classes_sum_fields
  var_pop: sf_support_ticket_classes_var_pop_fields
  var_samp: sf_support_ticket_classes_var_samp_fields
  variance: sf_support_ticket_classes_variance_fields
}

type sf_support_ticket_classes_avg_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

input sf_support_ticket_classes_bool_exp {
  _and: [sf_support_ticket_classes_bool_exp!]
  _not: sf_support_ticket_classes_bool_exp
  _or: [sf_support_ticket_classes_bool_exp!]
  accelo_class_id: Int_comparison_exp
  active: Boolean_comparison_exp
  auto_closeable: Boolean_comparison_exp
  description: String_comparison_exp
  id: Int_comparison_exp
  straight_to_jrf: Boolean_comparison_exp
  suggestedActionId: Int_comparison_exp
  suggestedRootCauseId: Int_comparison_exp
  supportManualLink: String_comparison_exp
  ticketClass: String_comparison_exp
}

enum sf_support_ticket_classes_constraint {
  ticket_classes_pkey
}

input sf_support_ticket_classes_inc_input {
  accelo_class_id: Int
  id: Int
  suggestedActionId: Int
  suggestedRootCauseId: Int
}

input sf_support_ticket_classes_insert_input {
  accelo_class_id: Int
  active: Boolean
  auto_closeable: Boolean
  description: String
  id: Int
  straight_to_jrf: Boolean
  suggestedActionId: Int
  suggestedRootCauseId: Int
  supportManualLink: String
  ticketClass: String
}

type sf_support_ticket_classes_max_fields {
  accelo_class_id: Int
  description: String
  id: Int
  suggestedActionId: Int
  suggestedRootCauseId: Int
  supportManualLink: String
  ticketClass: String
}

type sf_support_ticket_classes_min_fields {
  accelo_class_id: Int
  description: String
  id: Int
  suggestedActionId: Int
  suggestedRootCauseId: Int
  supportManualLink: String
  ticketClass: String
}

type sf_support_ticket_classes_mutation_response {
  affected_rows: Int!
  returning: [sf_support_ticket_classes!]!
}

input sf_support_ticket_classes_on_conflict {
  constraint: sf_support_ticket_classes_constraint!
  update_columns: [sf_support_ticket_classes_update_column!]! = []
  where: sf_support_ticket_classes_bool_exp
}

input sf_support_ticket_classes_order_by {
  accelo_class_id: order_by
  active: order_by
  auto_closeable: order_by
  description: order_by
  id: order_by
  straight_to_jrf: order_by
  suggestedActionId: order_by
  suggestedRootCauseId: order_by
  supportManualLink: order_by
  ticketClass: order_by
}

input sf_support_ticket_classes_pk_columns_input {
  id: Int!
}

enum sf_support_ticket_classes_select_column {
  accelo_class_id
  active
  auto_closeable
  description
  id
  straight_to_jrf
  suggestedActionId
  suggestedRootCauseId
  supportManualLink
  ticketClass
}

input sf_support_ticket_classes_set_input {
  accelo_class_id: Int
  active: Boolean
  auto_closeable: Boolean
  description: String
  id: Int
  straight_to_jrf: Boolean
  suggestedActionId: Int
  suggestedRootCauseId: Int
  supportManualLink: String
  ticketClass: String
}

type sf_support_ticket_classes_stddev_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

type sf_support_ticket_classes_stddev_pop_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

type sf_support_ticket_classes_stddev_samp_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

input sf_support_ticket_classes_stream_cursor_input {
  initial_value: sf_support_ticket_classes_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_ticket_classes_stream_cursor_value_input {
  accelo_class_id: Int
  active: Boolean
  auto_closeable: Boolean
  description: String
  id: Int
  straight_to_jrf: Boolean
  suggestedActionId: Int
  suggestedRootCauseId: Int
  supportManualLink: String
  ticketClass: String
}

type sf_support_ticket_classes_sum_fields {
  accelo_class_id: Int
  id: Int
  suggestedActionId: Int
  suggestedRootCauseId: Int
}

enum sf_support_ticket_classes_update_column {
  accelo_class_id
  active
  auto_closeable
  description
  id
  straight_to_jrf
  suggestedActionId
  suggestedRootCauseId
  supportManualLink
  ticketClass
}

input sf_support_ticket_classes_updates {
  _inc: sf_support_ticket_classes_inc_input
  _set: sf_support_ticket_classes_set_input
  where: sf_support_ticket_classes_bool_exp!
}

type sf_support_ticket_classes_var_pop_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

type sf_support_ticket_classes_var_samp_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

type sf_support_ticket_classes_variance_fields {
  accelo_class_id: Float
  id: Float
  suggestedActionId: Float
  suggestedRootCauseId: Float
}

type sf_support_ticket_status {
  accelo_id: Int!
  clickup_status_name: String
  id: Int!
  name: String
}

type sf_support_ticket_status_aggregate {
  aggregate: sf_support_ticket_status_aggregate_fields
  nodes: [sf_support_ticket_status!]!
}

type sf_support_ticket_status_aggregate_fields {
  avg: sf_support_ticket_status_avg_fields
  count(columns: [sf_support_ticket_status_select_column!], distinct: Boolean): Int!
  max: sf_support_ticket_status_max_fields
  min: sf_support_ticket_status_min_fields
  stddev: sf_support_ticket_status_stddev_fields
  stddev_pop: sf_support_ticket_status_stddev_pop_fields
  stddev_samp: sf_support_ticket_status_stddev_samp_fields
  sum: sf_support_ticket_status_sum_fields
  var_pop: sf_support_ticket_status_var_pop_fields
  var_samp: sf_support_ticket_status_var_samp_fields
  variance: sf_support_ticket_status_variance_fields
}

type sf_support_ticket_status_avg_fields {
  accelo_id: Float
  id: Float
}

input sf_support_ticket_status_bool_exp {
  _and: [sf_support_ticket_status_bool_exp!]
  _not: sf_support_ticket_status_bool_exp
  _or: [sf_support_ticket_status_bool_exp!]
  accelo_id: Int_comparison_exp
  clickup_status_name: String_comparison_exp
  id: Int_comparison_exp
  name: String_comparison_exp
}

enum sf_support_ticket_status_constraint {
  ticket_status_pkey
}

input sf_support_ticket_status_inc_input {
  accelo_id: Int
  id: Int
}

input sf_support_ticket_status_insert_input {
  accelo_id: Int
  clickup_status_name: String
  id: Int
  name: String
}

type sf_support_ticket_status_max_fields {
  accelo_id: Int
  clickup_status_name: String
  id: Int
  name: String
}

type sf_support_ticket_status_min_fields {
  accelo_id: Int
  clickup_status_name: String
  id: Int
  name: String
}

type sf_support_ticket_status_mutation_response {
  affected_rows: Int!
  returning: [sf_support_ticket_status!]!
}

input sf_support_ticket_status_on_conflict {
  constraint: sf_support_ticket_status_constraint!
  update_columns: [sf_support_ticket_status_update_column!]! = []
  where: sf_support_ticket_status_bool_exp
}

input sf_support_ticket_status_order_by {
  accelo_id: order_by
  clickup_status_name: order_by
  id: order_by
  name: order_by
}

input sf_support_ticket_status_pk_columns_input {
  id: Int!
}

enum sf_support_ticket_status_select_column {
  accelo_id
  clickup_status_name
  id
  name
}

input sf_support_ticket_status_set_input {
  accelo_id: Int
  clickup_status_name: String
  id: Int
  name: String
}

type sf_support_ticket_status_stddev_fields {
  accelo_id: Float
  id: Float
}

type sf_support_ticket_status_stddev_pop_fields {
  accelo_id: Float
  id: Float
}

type sf_support_ticket_status_stddev_samp_fields {
  accelo_id: Float
  id: Float
}

input sf_support_ticket_status_stream_cursor_input {
  initial_value: sf_support_ticket_status_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_ticket_status_stream_cursor_value_input {
  accelo_id: Int
  clickup_status_name: String
  id: Int
  name: String
}

type sf_support_ticket_status_sum_fields {
  accelo_id: Int
  id: Int
}

enum sf_support_ticket_status_update_column {
  accelo_id
  clickup_status_name
  id
  name
}

input sf_support_ticket_status_updates {
  _inc: sf_support_ticket_status_inc_input
  _set: sf_support_ticket_status_set_input
  where: sf_support_ticket_status_bool_exp!
}

type sf_support_ticket_status_var_pop_fields {
  accelo_id: Float
  id: Float
}

type sf_support_ticket_status_var_samp_fields {
  accelo_id: Float
  id: Float
}

type sf_support_ticket_status_variance_fields {
  accelo_id: Float
  id: Float
}

type sf_support_tickets {
  accelo_id: Int
  action: sf_support_actions
  action_id: Int
  claimed_at: timestamptz
  clickup_task_id: String
  comment: String
  created_at: timestamptz
  deleted_at: timestamp
  excludeFromVHS: Boolean!
  is_claimed: Boolean
  last_detected: timestamptz
  location_id: uuid!
  observable_id: String
  observable_type: String
  priority: Int
  root_cause: sf_support_root_causes
  root_cause_id: Int
  ticket_class_id: Int
  ticket_created: timestamp
  ticket_id: Int!
  ticket_status_id: Int
  title: String
  type: String!
  updated_at: timestamptz
}

type sf_support_tickets_aggregate {
  aggregate: sf_support_tickets_aggregate_fields
  nodes: [sf_support_tickets!]!
}

type sf_support_tickets_aggregate_fields {
  avg: sf_support_tickets_avg_fields
  count(columns: [sf_support_tickets_select_column!], distinct: Boolean): Int!
  max: sf_support_tickets_max_fields
  min: sf_support_tickets_min_fields
  stddev: sf_support_tickets_stddev_fields
  stddev_pop: sf_support_tickets_stddev_pop_fields
  stddev_samp: sf_support_tickets_stddev_samp_fields
  sum: sf_support_tickets_sum_fields
  var_pop: sf_support_tickets_var_pop_fields
  var_samp: sf_support_tickets_var_samp_fields
  variance: sf_support_tickets_variance_fields
}

type sf_support_tickets_avg_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

input sf_support_tickets_bool_exp {
  _and: [sf_support_tickets_bool_exp!]
  _not: sf_support_tickets_bool_exp
  _or: [sf_support_tickets_bool_exp!]
  accelo_id: Int_comparison_exp
  action: sf_support_actions_bool_exp
  action_id: Int_comparison_exp
  claimed_at: timestamptz_comparison_exp
  clickup_task_id: String_comparison_exp
  comment: String_comparison_exp
  created_at: timestamptz_comparison_exp
  deleted_at: timestamp_comparison_exp
  excludeFromVHS: Boolean_comparison_exp
  is_claimed: Boolean_comparison_exp
  last_detected: timestamptz_comparison_exp
  location_id: uuid_comparison_exp
  observable_id: String_comparison_exp
  observable_type: String_comparison_exp
  priority: Int_comparison_exp
  root_cause: sf_support_root_causes_bool_exp
  root_cause_id: Int_comparison_exp
  ticket_class_id: Int_comparison_exp
  ticket_created: timestamp_comparison_exp
  ticket_id: Int_comparison_exp
  ticket_status_id: Int_comparison_exp
  title: String_comparison_exp
  type: String_comparison_exp
  updated_at: timestamptz_comparison_exp
}

enum sf_support_tickets_constraint {
  tickets_pkey
  tickets_unique
}

input sf_support_tickets_inc_input {
  accelo_id: Int
  action_id: Int
  priority: Int
  root_cause_id: Int
  ticket_class_id: Int
  ticket_id: Int
  ticket_status_id: Int
}

input sf_support_tickets_insert_input {
  accelo_id: Int
  action: sf_support_actions_obj_rel_insert_input
  action_id: Int
  claimed_at: timestamptz
  clickup_task_id: String
  comment: String
  created_at: timestamptz
  deleted_at: timestamp
  excludeFromVHS: Boolean
  is_claimed: Boolean
  last_detected: timestamptz
  location_id: uuid
  observable_id: String
  observable_type: String
  priority: Int
  root_cause: sf_support_root_causes_obj_rel_insert_input
  root_cause_id: Int
  ticket_class_id: Int
  ticket_created: timestamp
  ticket_id: Int
  ticket_status_id: Int
  title: String
  type: String
  updated_at: timestamptz
}

type sf_support_tickets_max_fields {
  accelo_id: Int
  action_id: Int
  claimed_at: timestamptz
  clickup_task_id: String
  comment: String
  created_at: timestamptz
  deleted_at: timestamp
  last_detected: timestamptz
  location_id: uuid
  observable_id: String
  observable_type: String
  priority: Int
  root_cause_id: Int
  ticket_class_id: Int
  ticket_created: timestamp
  ticket_id: Int
  ticket_status_id: Int
  title: String
  type: String
  updated_at: timestamptz
}

type sf_support_tickets_min_fields {
  accelo_id: Int
  action_id: Int
  claimed_at: timestamptz
  clickup_task_id: String
  comment: String
  created_at: timestamptz
  deleted_at: timestamp
  last_detected: timestamptz
  location_id: uuid
  observable_id: String
  observable_type: String
  priority: Int
  root_cause_id: Int
  ticket_class_id: Int
  ticket_created: timestamp
  ticket_id: Int
  ticket_status_id: Int
  title: String
  type: String
  updated_at: timestamptz
}

type sf_support_tickets_mutation_response {
  affected_rows: Int!
  returning: [sf_support_tickets!]!
}

input sf_support_tickets_on_conflict {
  constraint: sf_support_tickets_constraint!
  update_columns: [sf_support_tickets_update_column!]! = []
  where: sf_support_tickets_bool_exp
}

input sf_support_tickets_order_by {
  accelo_id: order_by
  action: sf_support_actions_order_by
  action_id: order_by
  claimed_at: order_by
  clickup_task_id: order_by
  comment: order_by
  created_at: order_by
  deleted_at: order_by
  excludeFromVHS: order_by
  is_claimed: order_by
  last_detected: order_by
  location_id: order_by
  observable_id: order_by
  observable_type: order_by
  priority: order_by
  root_cause: sf_support_root_causes_order_by
  root_cause_id: order_by
  ticket_class_id: order_by
  ticket_created: order_by
  ticket_id: order_by
  ticket_status_id: order_by
  title: order_by
  type: order_by
  updated_at: order_by
}

input sf_support_tickets_pk_columns_input {
  ticket_id: Int!
}

enum sf_support_tickets_select_column {
  accelo_id
  action_id
  claimed_at
  clickup_task_id
  comment
  created_at
  deleted_at
  excludeFromVHS
  is_claimed
  last_detected
  location_id
  observable_id
  observable_type
  priority
  root_cause_id
  ticket_class_id
  ticket_created
  ticket_id
  ticket_status_id
  title
  type
  updated_at
}

input sf_support_tickets_set_input {
  accelo_id: Int
  action_id: Int
  claimed_at: timestamptz
  clickup_task_id: String
  comment: String
  created_at: timestamptz
  deleted_at: timestamp
  excludeFromVHS: Boolean
  is_claimed: Boolean
  last_detected: timestamptz
  location_id: uuid
  observable_id: String
  observable_type: String
  priority: Int
  root_cause_id: Int
  ticket_class_id: Int
  ticket_created: timestamp
  ticket_id: Int
  ticket_status_id: Int
  title: String
  type: String
  updated_at: timestamptz
}

type sf_support_tickets_stddev_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

type sf_support_tickets_stddev_pop_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

type sf_support_tickets_stddev_samp_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

input sf_support_tickets_stream_cursor_input {
  initial_value: sf_support_tickets_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_support_tickets_stream_cursor_value_input {
  accelo_id: Int
  action_id: Int
  claimed_at: timestamptz
  clickup_task_id: String
  comment: String
  created_at: timestamptz
  deleted_at: timestamp
  excludeFromVHS: Boolean
  is_claimed: Boolean
  last_detected: timestamptz
  location_id: uuid
  observable_id: String
  observable_type: String
  priority: Int
  root_cause_id: Int
  ticket_class_id: Int
  ticket_created: timestamp
  ticket_id: Int
  ticket_status_id: Int
  title: String
  type: String
  updated_at: timestamptz
}

type sf_support_tickets_sum_fields {
  accelo_id: Int
  action_id: Int
  priority: Int
  root_cause_id: Int
  ticket_class_id: Int
  ticket_id: Int
  ticket_status_id: Int
}

enum sf_support_tickets_update_column {
  accelo_id
  action_id
  claimed_at
  clickup_task_id
  comment
  created_at
  deleted_at
  excludeFromVHS
  is_claimed
  last_detected
  location_id
  observable_id
  observable_type
  priority
  root_cause_id
  ticket_class_id
  ticket_created
  ticket_id
  ticket_status_id
  title
  type
  updated_at
}

input sf_support_tickets_updates {
  _inc: sf_support_tickets_inc_input
  _set: sf_support_tickets_set_input
  where: sf_support_tickets_bool_exp!
}

type sf_support_tickets_var_pop_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

type sf_support_tickets_var_samp_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

type sf_support_tickets_variance_fields {
  accelo_id: Float
  action_id: Float
  priority: Float
  root_cause_id: Float
  ticket_class_id: Float
  ticket_id: Float
  ticket_status_id: Float
}

type sf_testjig_test_jig {
  description: String
  location: String
  testJigId: String!
  testJigType: Int!
}

type sf_testjig_test_jig_aggregate {
  aggregate: sf_testjig_test_jig_aggregate_fields
  nodes: [sf_testjig_test_jig!]!
}

type sf_testjig_test_jig_aggregate_fields {
  avg: sf_testjig_test_jig_avg_fields
  count(columns: [sf_testjig_test_jig_select_column!], distinct: Boolean): Int!
  max: sf_testjig_test_jig_max_fields
  min: sf_testjig_test_jig_min_fields
  stddev: sf_testjig_test_jig_stddev_fields
  stddev_pop: sf_testjig_test_jig_stddev_pop_fields
  stddev_samp: sf_testjig_test_jig_stddev_samp_fields
  sum: sf_testjig_test_jig_sum_fields
  var_pop: sf_testjig_test_jig_var_pop_fields
  var_samp: sf_testjig_test_jig_var_samp_fields
  variance: sf_testjig_test_jig_variance_fields
}

type sf_testjig_test_jig_avg_fields {
  testJigType: Float
}

input sf_testjig_test_jig_bool_exp {
  _and: [sf_testjig_test_jig_bool_exp!]
  _not: sf_testjig_test_jig_bool_exp
  _or: [sf_testjig_test_jig_bool_exp!]
  description: String_comparison_exp
  location: String_comparison_exp
  testJigId: String_comparison_exp
  testJigType: Int_comparison_exp
}

enum sf_testjig_test_jig_constraint {
  test_jig_pkey
}

input sf_testjig_test_jig_inc_input {
  testJigType: Int
}

input sf_testjig_test_jig_insert_input {
  description: String
  location: String
  testJigId: String
  testJigType: Int
}

type sf_testjig_test_jig_maintenance_report {
  id: uuid!
  reportLink: String
  testJig: sf_testjig_test_jig!
  testJigId: String!
  uploadTime: timestamptz
}

type sf_testjig_test_jig_maintenance_report_aggregate {
  aggregate: sf_testjig_test_jig_maintenance_report_aggregate_fields
  nodes: [sf_testjig_test_jig_maintenance_report!]!
}

type sf_testjig_test_jig_maintenance_report_aggregate_fields {
  count(columns: [sf_testjig_test_jig_maintenance_report_select_column!], distinct: Boolean): Int!
  max: sf_testjig_test_jig_maintenance_report_max_fields
  min: sf_testjig_test_jig_maintenance_report_min_fields
}

input sf_testjig_test_jig_maintenance_report_bool_exp {
  _and: [sf_testjig_test_jig_maintenance_report_bool_exp!]
  _not: sf_testjig_test_jig_maintenance_report_bool_exp
  _or: [sf_testjig_test_jig_maintenance_report_bool_exp!]
  id: uuid_comparison_exp
  reportLink: String_comparison_exp
  testJig: sf_testjig_test_jig_bool_exp
  testJigId: String_comparison_exp
  uploadTime: timestamptz_comparison_exp
}

enum sf_testjig_test_jig_maintenance_report_constraint {
  test_jig_maintenance_report_pkey
}

input sf_testjig_test_jig_maintenance_report_insert_input {
  id: uuid
  reportLink: String
  testJig: sf_testjig_test_jig_obj_rel_insert_input
  testJigId: String
  uploadTime: timestamptz
}

type sf_testjig_test_jig_maintenance_report_max_fields {
  id: uuid
  reportLink: String
  testJigId: String
  uploadTime: timestamptz
}

type sf_testjig_test_jig_maintenance_report_min_fields {
  id: uuid
  reportLink: String
  testJigId: String
  uploadTime: timestamptz
}

type sf_testjig_test_jig_maintenance_report_mutation_response {
  affected_rows: Int!
  returning: [sf_testjig_test_jig_maintenance_report!]!
}

input sf_testjig_test_jig_maintenance_report_on_conflict {
  constraint: sf_testjig_test_jig_maintenance_report_constraint!
  update_columns: [sf_testjig_test_jig_maintenance_report_update_column!]! = []
  where: sf_testjig_test_jig_maintenance_report_bool_exp
}

input sf_testjig_test_jig_maintenance_report_order_by {
  id: order_by
  reportLink: order_by
  testJig: sf_testjig_test_jig_order_by
  testJigId: order_by
  uploadTime: order_by
}

input sf_testjig_test_jig_maintenance_report_pk_columns_input {
  id: uuid!
}

enum sf_testjig_test_jig_maintenance_report_select_column {
  id
  reportLink
  testJigId
  uploadTime
}

input sf_testjig_test_jig_maintenance_report_set_input {
  id: uuid
  reportLink: String
  testJigId: String
  uploadTime: timestamptz
}

input sf_testjig_test_jig_maintenance_report_stream_cursor_input {
  initial_value: sf_testjig_test_jig_maintenance_report_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_test_jig_maintenance_report_stream_cursor_value_input {
  id: uuid
  reportLink: String
  testJigId: String
  uploadTime: timestamptz
}

enum sf_testjig_test_jig_maintenance_report_update_column {
  id
  reportLink
  testJigId
  uploadTime
}

input sf_testjig_test_jig_maintenance_report_updates {
  _set: sf_testjig_test_jig_maintenance_report_set_input
  where: sf_testjig_test_jig_maintenance_report_bool_exp!
}

type sf_testjig_test_jig_max_fields {
  description: String
  location: String
  testJigId: String
  testJigType: Int
}

type sf_testjig_test_jig_min_fields {
  description: String
  location: String
  testJigId: String
  testJigType: Int
}

type sf_testjig_test_jig_mutation_response {
  affected_rows: Int!
  returning: [sf_testjig_test_jig!]!
}

input sf_testjig_test_jig_obj_rel_insert_input {
  data: sf_testjig_test_jig_insert_input!
  on_conflict: sf_testjig_test_jig_on_conflict
}

input sf_testjig_test_jig_on_conflict {
  constraint: sf_testjig_test_jig_constraint!
  update_columns: [sf_testjig_test_jig_update_column!]! = []
  where: sf_testjig_test_jig_bool_exp
}

input sf_testjig_test_jig_order_by {
  description: order_by
  location: order_by
  testJigId: order_by
  testJigType: order_by
}

input sf_testjig_test_jig_pk_columns_input {
  testJigId: String!
}

type sf_testjig_test_jig_report_metadata {
  comment: String
  id: uuid!
  isPassed: Boolean!
  metadataId: Int!
  testJigReportId: uuid!
  testJigReportMetadata: sf_testjig_test_jig_report_metadatas!
  testJigTestReport: sf_testjig_test_jig_test_report!
  value: String!
}

type sf_testjig_test_jig_report_metadata_aggregate {
  aggregate: sf_testjig_test_jig_report_metadata_aggregate_fields
  nodes: [sf_testjig_test_jig_report_metadata!]!
}

type sf_testjig_test_jig_report_metadata_aggregate_fields {
  avg: sf_testjig_test_jig_report_metadata_avg_fields
  count(columns: [sf_testjig_test_jig_report_metadata_select_column!], distinct: Boolean): Int!
  max: sf_testjig_test_jig_report_metadata_max_fields
  min: sf_testjig_test_jig_report_metadata_min_fields
  stddev: sf_testjig_test_jig_report_metadata_stddev_fields
  stddev_pop: sf_testjig_test_jig_report_metadata_stddev_pop_fields
  stddev_samp: sf_testjig_test_jig_report_metadata_stddev_samp_fields
  sum: sf_testjig_test_jig_report_metadata_sum_fields
  var_pop: sf_testjig_test_jig_report_metadata_var_pop_fields
  var_samp: sf_testjig_test_jig_report_metadata_var_samp_fields
  variance: sf_testjig_test_jig_report_metadata_variance_fields
}

type sf_testjig_test_jig_report_metadata_avg_fields {
  metadataId: Float
}

input sf_testjig_test_jig_report_metadata_bool_exp {
  _and: [sf_testjig_test_jig_report_metadata_bool_exp!]
  _not: sf_testjig_test_jig_report_metadata_bool_exp
  _or: [sf_testjig_test_jig_report_metadata_bool_exp!]
  comment: String_comparison_exp
  id: uuid_comparison_exp
  isPassed: Boolean_comparison_exp
  metadataId: Int_comparison_exp
  testJigReportId: uuid_comparison_exp
  testJigReportMetadata: sf_testjig_test_jig_report_metadatas_bool_exp
  testJigTestReport: sf_testjig_test_jig_test_report_bool_exp
  value: String_comparison_exp
}

enum sf_testjig_test_jig_report_metadata_constraint {
  test_jig_report_metadata_pkey
}

input sf_testjig_test_jig_report_metadata_inc_input {
  metadataId: Int
}

input sf_testjig_test_jig_report_metadata_insert_input {
  comment: String
  id: uuid
  isPassed: Boolean
  metadataId: Int
  testJigReportId: uuid
  testJigReportMetadata: sf_testjig_test_jig_report_metadatas_obj_rel_insert_input
  testJigTestReport: sf_testjig_test_jig_test_report_obj_rel_insert_input
  value: String
}

type sf_testjig_test_jig_report_metadata_max_fields {
  comment: String
  id: uuid
  metadataId: Int
  testJigReportId: uuid
  value: String
}

type sf_testjig_test_jig_report_metadata_min_fields {
  comment: String
  id: uuid
  metadataId: Int
  testJigReportId: uuid
  value: String
}

type sf_testjig_test_jig_report_metadata_mutation_response {
  affected_rows: Int!
  returning: [sf_testjig_test_jig_report_metadata!]!
}

input sf_testjig_test_jig_report_metadata_on_conflict {
  constraint: sf_testjig_test_jig_report_metadata_constraint!
  update_columns: [sf_testjig_test_jig_report_metadata_update_column!]! = []
  where: sf_testjig_test_jig_report_metadata_bool_exp
}

input sf_testjig_test_jig_report_metadata_order_by {
  comment: order_by
  id: order_by
  isPassed: order_by
  metadataId: order_by
  testJigReportId: order_by
  testJigReportMetadata: sf_testjig_test_jig_report_metadatas_order_by
  testJigTestReport: sf_testjig_test_jig_test_report_order_by
  value: order_by
}

input sf_testjig_test_jig_report_metadata_pk_columns_input {
  id: uuid!
}

enum sf_testjig_test_jig_report_metadata_select_column {
  comment
  id
  isPassed
  metadataId
  testJigReportId
  value
}

input sf_testjig_test_jig_report_metadata_set_input {
  comment: String
  id: uuid
  isPassed: Boolean
  metadataId: Int
  testJigReportId: uuid
  value: String
}

type sf_testjig_test_jig_report_metadata_stddev_fields {
  metadataId: Float
}

type sf_testjig_test_jig_report_metadata_stddev_pop_fields {
  metadataId: Float
}

type sf_testjig_test_jig_report_metadata_stddev_samp_fields {
  metadataId: Float
}

input sf_testjig_test_jig_report_metadata_stream_cursor_input {
  initial_value: sf_testjig_test_jig_report_metadata_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_test_jig_report_metadata_stream_cursor_value_input {
  comment: String
  id: uuid
  isPassed: Boolean
  metadataId: Int
  testJigReportId: uuid
  value: String
}

type sf_testjig_test_jig_report_metadata_sum_fields {
  metadataId: Int
}

enum sf_testjig_test_jig_report_metadata_update_column {
  comment
  id
  isPassed
  metadataId
  testJigReportId
  value
}

input sf_testjig_test_jig_report_metadata_updates {
  _inc: sf_testjig_test_jig_report_metadata_inc_input
  _set: sf_testjig_test_jig_report_metadata_set_input
  where: sf_testjig_test_jig_report_metadata_bool_exp!
}

type sf_testjig_test_jig_report_metadata_var_pop_fields {
  metadataId: Float
}

type sf_testjig_test_jig_report_metadata_var_samp_fields {
  metadataId: Float
}

type sf_testjig_test_jig_report_metadata_variance_fields {
  metadataId: Float
}

type sf_testjig_test_jig_report_metadatas {
  description: String
  id: Int!
  isString: Boolean!
  metadata: String!
  thresholdMax: String
  thresholdMin: String
  unit: String
}

type sf_testjig_test_jig_report_metadatas_aggregate {
  aggregate: sf_testjig_test_jig_report_metadatas_aggregate_fields
  nodes: [sf_testjig_test_jig_report_metadatas!]!
}

type sf_testjig_test_jig_report_metadatas_aggregate_fields {
  avg: sf_testjig_test_jig_report_metadatas_avg_fields
  count(columns: [sf_testjig_test_jig_report_metadatas_select_column!], distinct: Boolean): Int!
  max: sf_testjig_test_jig_report_metadatas_max_fields
  min: sf_testjig_test_jig_report_metadatas_min_fields
  stddev: sf_testjig_test_jig_report_metadatas_stddev_fields
  stddev_pop: sf_testjig_test_jig_report_metadatas_stddev_pop_fields
  stddev_samp: sf_testjig_test_jig_report_metadatas_stddev_samp_fields
  sum: sf_testjig_test_jig_report_metadatas_sum_fields
  var_pop: sf_testjig_test_jig_report_metadatas_var_pop_fields
  var_samp: sf_testjig_test_jig_report_metadatas_var_samp_fields
  variance: sf_testjig_test_jig_report_metadatas_variance_fields
}

type sf_testjig_test_jig_report_metadatas_avg_fields {
  id: Float
}

input sf_testjig_test_jig_report_metadatas_bool_exp {
  _and: [sf_testjig_test_jig_report_metadatas_bool_exp!]
  _not: sf_testjig_test_jig_report_metadatas_bool_exp
  _or: [sf_testjig_test_jig_report_metadatas_bool_exp!]
  description: String_comparison_exp
  id: Int_comparison_exp
  isString: Boolean_comparison_exp
  metadata: String_comparison_exp
  thresholdMax: String_comparison_exp
  thresholdMin: String_comparison_exp
  unit: String_comparison_exp
}

enum sf_testjig_test_jig_report_metadatas_constraint {
  test_jig_report_metadatas_metadata_unique_idx
  test_jig_report_metadatas_pkey
}

input sf_testjig_test_jig_report_metadatas_inc_input {
  id: Int
}

input sf_testjig_test_jig_report_metadatas_insert_input {
  description: String
  id: Int
  isString: Boolean
  metadata: String
  thresholdMax: String
  thresholdMin: String
  unit: String
}

type sf_testjig_test_jig_report_metadatas_max_fields {
  description: String
  id: Int
  metadata: String
  thresholdMax: String
  thresholdMin: String
  unit: String
}

type sf_testjig_test_jig_report_metadatas_min_fields {
  description: String
  id: Int
  metadata: String
  thresholdMax: String
  thresholdMin: String
  unit: String
}

type sf_testjig_test_jig_report_metadatas_mutation_response {
  affected_rows: Int!
  returning: [sf_testjig_test_jig_report_metadatas!]!
}

input sf_testjig_test_jig_report_metadatas_obj_rel_insert_input {
  data: sf_testjig_test_jig_report_metadatas_insert_input!
  on_conflict: sf_testjig_test_jig_report_metadatas_on_conflict
}

input sf_testjig_test_jig_report_metadatas_on_conflict {
  constraint: sf_testjig_test_jig_report_metadatas_constraint!
  update_columns: [sf_testjig_test_jig_report_metadatas_update_column!]! = []
  where: sf_testjig_test_jig_report_metadatas_bool_exp
}

input sf_testjig_test_jig_report_metadatas_order_by {
  description: order_by
  id: order_by
  isString: order_by
  metadata: order_by
  thresholdMax: order_by
  thresholdMin: order_by
  unit: order_by
}

input sf_testjig_test_jig_report_metadatas_pk_columns_input {
  id: Int!
}

enum sf_testjig_test_jig_report_metadatas_select_column {
  description
  id
  isString
  metadata
  thresholdMax
  thresholdMin
  unit
}

input sf_testjig_test_jig_report_metadatas_set_input {
  description: String
  id: Int
  isString: Boolean
  metadata: String
  thresholdMax: String
  thresholdMin: String
  unit: String
}

type sf_testjig_test_jig_report_metadatas_stddev_fields {
  id: Float
}

type sf_testjig_test_jig_report_metadatas_stddev_pop_fields {
  id: Float
}

type sf_testjig_test_jig_report_metadatas_stddev_samp_fields {
  id: Float
}

input sf_testjig_test_jig_report_metadatas_stream_cursor_input {
  initial_value: sf_testjig_test_jig_report_metadatas_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_test_jig_report_metadatas_stream_cursor_value_input {
  description: String
  id: Int
  isString: Boolean
  metadata: String
  thresholdMax: String
  thresholdMin: String
  unit: String
}

type sf_testjig_test_jig_report_metadatas_sum_fields {
  id: Int
}

enum sf_testjig_test_jig_report_metadatas_update_column {
  description
  id
  isString
  metadata
  thresholdMax
  thresholdMin
  unit
}

input sf_testjig_test_jig_report_metadatas_updates {
  _inc: sf_testjig_test_jig_report_metadatas_inc_input
  _set: sf_testjig_test_jig_report_metadatas_set_input
  where: sf_testjig_test_jig_report_metadatas_bool_exp!
}

type sf_testjig_test_jig_report_metadatas_var_pop_fields {
  id: Float
}

type sf_testjig_test_jig_report_metadatas_var_samp_fields {
  id: Float
}

type sf_testjig_test_jig_report_metadatas_variance_fields {
  id: Float
}

enum sf_testjig_test_jig_select_column {
  description
  location
  testJigId
  testJigType
}

input sf_testjig_test_jig_set_input {
  description: String
  location: String
  testJigId: String
  testJigType: Int
}

type sf_testjig_test_jig_stddev_fields {
  testJigType: Float
}

type sf_testjig_test_jig_stddev_pop_fields {
  testJigType: Float
}

type sf_testjig_test_jig_stddev_samp_fields {
  testJigType: Float
}

input sf_testjig_test_jig_stream_cursor_input {
  initial_value: sf_testjig_test_jig_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_test_jig_stream_cursor_value_input {
  description: String
  location: String
  testJigId: String
  testJigType: Int
}

type sf_testjig_test_jig_sum_fields {
  testJigType: Int
}

type sf_testjig_test_jig_test_report {
  comment: String
  entryTime: timestamptz!
  id: uuid!
  nodeLifeCycleEvent: sensorflow_node_life_cycle_events
  nodeLifeCycleEventId: Int
  nodeMacId: String!
  testJig: sf_testjig_test_jig!
  testJigId: String!
  testResult: String!
}

type sf_testjig_test_jig_test_report_aggregate {
  aggregate: sf_testjig_test_jig_test_report_aggregate_fields
  nodes: [sf_testjig_test_jig_test_report!]!
}

type sf_testjig_test_jig_test_report_aggregate_fields {
  avg: sf_testjig_test_jig_test_report_avg_fields
  count(columns: [sf_testjig_test_jig_test_report_select_column!], distinct: Boolean): Int!
  max: sf_testjig_test_jig_test_report_max_fields
  min: sf_testjig_test_jig_test_report_min_fields
  stddev: sf_testjig_test_jig_test_report_stddev_fields
  stddev_pop: sf_testjig_test_jig_test_report_stddev_pop_fields
  stddev_samp: sf_testjig_test_jig_test_report_stddev_samp_fields
  sum: sf_testjig_test_jig_test_report_sum_fields
  var_pop: sf_testjig_test_jig_test_report_var_pop_fields
  var_samp: sf_testjig_test_jig_test_report_var_samp_fields
  variance: sf_testjig_test_jig_test_report_variance_fields
}

type sf_testjig_test_jig_test_report_avg_fields {
  nodeLifeCycleEventId: Float
}

input sf_testjig_test_jig_test_report_bool_exp {
  _and: [sf_testjig_test_jig_test_report_bool_exp!]
  _not: sf_testjig_test_jig_test_report_bool_exp
  _or: [sf_testjig_test_jig_test_report_bool_exp!]
  comment: String_comparison_exp
  entryTime: timestamptz_comparison_exp
  id: uuid_comparison_exp
  nodeLifeCycleEvent: sensorflow_node_life_cycle_events_bool_exp
  nodeLifeCycleEventId: Int_comparison_exp
  nodeMacId: String_comparison_exp
  testJig: sf_testjig_test_jig_bool_exp
  testJigId: String_comparison_exp
  testResult: String_comparison_exp
}

enum sf_testjig_test_jig_test_report_constraint {
  test_jig_test_report_pkey
}

input sf_testjig_test_jig_test_report_inc_input {
  nodeLifeCycleEventId: Int
}

input sf_testjig_test_jig_test_report_insert_input {
  comment: String
  entryTime: timestamptz
  id: uuid
  nodeLifeCycleEvent: sensorflow_node_life_cycle_events_obj_rel_insert_input
  nodeLifeCycleEventId: Int
  nodeMacId: String
  testJig: sf_testjig_test_jig_obj_rel_insert_input
  testJigId: String
  testResult: String
}

type sf_testjig_test_jig_test_report_max_fields {
  comment: String
  entryTime: timestamptz
  id: uuid
  nodeLifeCycleEventId: Int
  nodeMacId: String
  testJigId: String
  testResult: String
}

type sf_testjig_test_jig_test_report_min_fields {
  comment: String
  entryTime: timestamptz
  id: uuid
  nodeLifeCycleEventId: Int
  nodeMacId: String
  testJigId: String
  testResult: String
}

type sf_testjig_test_jig_test_report_mutation_response {
  affected_rows: Int!
  returning: [sf_testjig_test_jig_test_report!]!
}

input sf_testjig_test_jig_test_report_obj_rel_insert_input {
  data: sf_testjig_test_jig_test_report_insert_input!
  on_conflict: sf_testjig_test_jig_test_report_on_conflict
}

input sf_testjig_test_jig_test_report_on_conflict {
  constraint: sf_testjig_test_jig_test_report_constraint!
  update_columns: [sf_testjig_test_jig_test_report_update_column!]! = []
  where: sf_testjig_test_jig_test_report_bool_exp
}

input sf_testjig_test_jig_test_report_order_by {
  comment: order_by
  entryTime: order_by
  id: order_by
  nodeLifeCycleEvent: sensorflow_node_life_cycle_events_order_by
  nodeLifeCycleEventId: order_by
  nodeMacId: order_by
  testJig: sf_testjig_test_jig_order_by
  testJigId: order_by
  testResult: order_by
}

input sf_testjig_test_jig_test_report_pk_columns_input {
  id: uuid!
}

enum sf_testjig_test_jig_test_report_select_column {
  comment
  entryTime
  id
  nodeLifeCycleEventId
  nodeMacId
  testJigId
  testResult
}

input sf_testjig_test_jig_test_report_set_input {
  comment: String
  entryTime: timestamptz
  id: uuid
  nodeLifeCycleEventId: Int
  nodeMacId: String
  testJigId: String
  testResult: String
}

type sf_testjig_test_jig_test_report_stddev_fields {
  nodeLifeCycleEventId: Float
}

type sf_testjig_test_jig_test_report_stddev_pop_fields {
  nodeLifeCycleEventId: Float
}

type sf_testjig_test_jig_test_report_stddev_samp_fields {
  nodeLifeCycleEventId: Float
}

input sf_testjig_test_jig_test_report_stream_cursor_input {
  initial_value: sf_testjig_test_jig_test_report_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_test_jig_test_report_stream_cursor_value_input {
  comment: String
  entryTime: timestamptz
  id: uuid
  nodeLifeCycleEventId: Int
  nodeMacId: String
  testJigId: String
  testResult: String
}

type sf_testjig_test_jig_test_report_sum_fields {
  nodeLifeCycleEventId: Int
}

enum sf_testjig_test_jig_test_report_update_column {
  comment
  entryTime
  id
  nodeLifeCycleEventId
  nodeMacId
  testJigId
  testResult
}

input sf_testjig_test_jig_test_report_updates {
  _inc: sf_testjig_test_jig_test_report_inc_input
  _set: sf_testjig_test_jig_test_report_set_input
  where: sf_testjig_test_jig_test_report_bool_exp!
}

type sf_testjig_test_jig_test_report_var_pop_fields {
  nodeLifeCycleEventId: Float
}

type sf_testjig_test_jig_test_report_var_samp_fields {
  nodeLifeCycleEventId: Float
}

type sf_testjig_test_jig_test_report_variance_fields {
  nodeLifeCycleEventId: Float
}

type sf_testjig_test_jig_type {
  description: String
  id: Int!
  name: String!
}

type sf_testjig_test_jig_type_aggregate {
  aggregate: sf_testjig_test_jig_type_aggregate_fields
  nodes: [sf_testjig_test_jig_type!]!
}

type sf_testjig_test_jig_type_aggregate_fields {
  avg: sf_testjig_test_jig_type_avg_fields
  count(columns: [sf_testjig_test_jig_type_select_column!], distinct: Boolean): Int!
  max: sf_testjig_test_jig_type_max_fields
  min: sf_testjig_test_jig_type_min_fields
  stddev: sf_testjig_test_jig_type_stddev_fields
  stddev_pop: sf_testjig_test_jig_type_stddev_pop_fields
  stddev_samp: sf_testjig_test_jig_type_stddev_samp_fields
  sum: sf_testjig_test_jig_type_sum_fields
  var_pop: sf_testjig_test_jig_type_var_pop_fields
  var_samp: sf_testjig_test_jig_type_var_samp_fields
  variance: sf_testjig_test_jig_type_variance_fields
}

type sf_testjig_test_jig_type_avg_fields {
  id: Float
}

input sf_testjig_test_jig_type_bool_exp {
  _and: [sf_testjig_test_jig_type_bool_exp!]
  _not: sf_testjig_test_jig_type_bool_exp
  _or: [sf_testjig_test_jig_type_bool_exp!]
  description: String_comparison_exp
  id: Int_comparison_exp
  name: String_comparison_exp
}

enum sf_testjig_test_jig_type_constraint {
  test_jig_type_pkey
}

input sf_testjig_test_jig_type_inc_input {
  id: Int
}

input sf_testjig_test_jig_type_insert_input {
  description: String
  id: Int
  name: String
}

type sf_testjig_test_jig_type_max_fields {
  description: String
  id: Int
  name: String
}

type sf_testjig_test_jig_type_min_fields {
  description: String
  id: Int
  name: String
}

type sf_testjig_test_jig_type_mutation_response {
  affected_rows: Int!
  returning: [sf_testjig_test_jig_type!]!
}

input sf_testjig_test_jig_type_on_conflict {
  constraint: sf_testjig_test_jig_type_constraint!
  update_columns: [sf_testjig_test_jig_type_update_column!]! = []
  where: sf_testjig_test_jig_type_bool_exp
}

input sf_testjig_test_jig_type_order_by {
  description: order_by
  id: order_by
  name: order_by
}

input sf_testjig_test_jig_type_pk_columns_input {
  id: Int!
}

enum sf_testjig_test_jig_type_select_column {
  description
  id
  name
}

input sf_testjig_test_jig_type_set_input {
  description: String
  id: Int
  name: String
}

type sf_testjig_test_jig_type_stddev_fields {
  id: Float
}

type sf_testjig_test_jig_type_stddev_pop_fields {
  id: Float
}

type sf_testjig_test_jig_type_stddev_samp_fields {
  id: Float
}

input sf_testjig_test_jig_type_stream_cursor_input {
  initial_value: sf_testjig_test_jig_type_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_test_jig_type_stream_cursor_value_input {
  description: String
  id: Int
  name: String
}

type sf_testjig_test_jig_type_sum_fields {
  id: Int
}

enum sf_testjig_test_jig_type_update_column {
  description
  id
  name
}

input sf_testjig_test_jig_type_updates {
  _inc: sf_testjig_test_jig_type_inc_input
  _set: sf_testjig_test_jig_type_set_input
  where: sf_testjig_test_jig_type_bool_exp!
}

type sf_testjig_test_jig_type_var_pop_fields {
  id: Float
}

type sf_testjig_test_jig_type_var_samp_fields {
  id: Float
}

type sf_testjig_test_jig_type_variance_fields {
  id: Float
}

enum sf_testjig_test_jig_update_column {
  description
  location
  testJigId
  testJigType
}

input sf_testjig_test_jig_updates {
  _inc: sf_testjig_test_jig_inc_input
  _set: sf_testjig_test_jig_set_input
  where: sf_testjig_test_jig_bool_exp!
}

type sf_testjig_test_jig_var_pop_fields {
  testJigType: Float
}

type sf_testjig_test_jig_var_samp_fields {
  testJigType: Float
}

type sf_testjig_test_jig_variance_fields {
  testJigType: Float
}

type sf_testjig_v_latest_report_rma_nodes {
  entry_time: timestamptz
  node_mac_id: String
  report_id: uuid
  rma: Boolean
}

type sf_testjig_v_latest_report_rma_nodes_aggregate {
  aggregate: sf_testjig_v_latest_report_rma_nodes_aggregate_fields
  nodes: [sf_testjig_v_latest_report_rma_nodes!]!
}

type sf_testjig_v_latest_report_rma_nodes_aggregate_fields {
  count(columns: [sf_testjig_v_latest_report_rma_nodes_select_column!], distinct: Boolean): Int!
  max: sf_testjig_v_latest_report_rma_nodes_max_fields
  min: sf_testjig_v_latest_report_rma_nodes_min_fields
}

input sf_testjig_v_latest_report_rma_nodes_bool_exp {
  _and: [sf_testjig_v_latest_report_rma_nodes_bool_exp!]
  _not: sf_testjig_v_latest_report_rma_nodes_bool_exp
  _or: [sf_testjig_v_latest_report_rma_nodes_bool_exp!]
  entry_time: timestamptz_comparison_exp
  node_mac_id: String_comparison_exp
  report_id: uuid_comparison_exp
  rma: Boolean_comparison_exp
}

type sf_testjig_v_latest_report_rma_nodes_max_fields {
  entry_time: timestamptz
  node_mac_id: String
  report_id: uuid
}

type sf_testjig_v_latest_report_rma_nodes_min_fields {
  entry_time: timestamptz
  node_mac_id: String
  report_id: uuid
}

input sf_testjig_v_latest_report_rma_nodes_order_by {
  entry_time: order_by
  node_mac_id: order_by
  report_id: order_by
  rma: order_by
}

enum sf_testjig_v_latest_report_rma_nodes_select_column {
  entry_time
  node_mac_id
  report_id
  rma
}

input sf_testjig_v_latest_report_rma_nodes_stream_cursor_input {
  initial_value: sf_testjig_v_latest_report_rma_nodes_stream_cursor_value_input!
  ordering: cursor_ordering
}

input sf_testjig_v_latest_report_rma_nodes_stream_cursor_value_input {
  entry_time: timestamptz
  node_mac_id: String
  report_id: uuid
  rma: Boolean
}

type Slot {
  creationDate: String!
  currentMappedNode: MappedNode
  id: ID!
  isCurrentRapidMappingSlot: Boolean!
  mappingStatus: SlotRapidMappingStatus!
  nodeSubType: NodeSubType
  nodeType: NodeType!
  nodeTypeCodeIdentifier: NodeTypeCodeIdentifier!
  removedDate: String
  roomId: ID!
  skippedRapidMappingSlot: Slot
  slotName: String!
}

input SlotCreateInput {
  nodeType: String!
  position: PositionInput!
  slotName: String!
}

input SlotCreateRoomInput {
  nodeSubType: String
  nodeType: NodeType!
  slotName: String
}

enum SlotRapidMappingStatus {
  MAPPED
  NOT_STARTED
  SKIPPED
}

scalar smallint

input smallint_comparison_exp {
  _eq: smallint
  _gt: smallint
  _gte: smallint
  _in: [smallint!]
  _is_null: Boolean
  _lt: smallint
  _lte: smallint
  _neq: smallint
  _nin: [smallint!]
}

enum SortOptions {
  ASC
  DESC
}

input String_array_comparison_exp {
  _contained_in: [String!]
  _contains: [String!]
  _eq: [String!]
  _gt: [String!]
  _gte: [String!]
  _in: [[String!]!]
  _is_null: Boolean
  _lt: [String!]
  _lte: [String!]
  _neq: [String!]
  _nin: [[String!]!]
}

input String_comparison_exp {
  _eq: String
  _gt: String
  _gte: String
  _ilike: String
  _in: [String!]
  _iregex: String
  _is_null: Boolean
  _like: String
  _lt: String
  _lte: String
  _neq: String
  _nilike: String
  _nin: [String!]
  _niregex: String
  _nlike: String
  _nregex: String
  _nsimilar: String
  _regex: String
  _similar: String
}

type subscription_root {
  TestJig(testJigId: String!): sf_testjig_test_jig
  TestJigMaintenanceReport(id: uuid!): sf_testjig_test_jig_maintenance_report
  TestJigMaintenanceReports(distinct_on: [sf_testjig_test_jig_maintenance_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_maintenance_report_order_by!], where: sf_testjig_test_jig_maintenance_report_bool_exp): [sf_testjig_test_jig_maintenance_report!]!
  TestJigReportMetadata(distinct_on: [sf_testjig_test_jig_report_metadata_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadata_order_by!], where: sf_testjig_test_jig_report_metadata_bool_exp): [sf_testjig_test_jig_report_metadata!]!
  TestJigReportMetadatas(distinct_on: [sf_testjig_test_jig_report_metadatas_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadatas_order_by!], where: sf_testjig_test_jig_report_metadatas_bool_exp): [sf_testjig_test_jig_report_metadatas!]!
  TestJigTestReport(id: uuid!): sf_testjig_test_jig_test_report
  TestJigTestReports(distinct_on: [sf_testjig_test_jig_test_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_test_report_order_by!], where: sf_testjig_test_jig_test_report_bool_exp): [sf_testjig_test_jig_test_report!]!
  TestJigType(id: Int!): sf_testjig_test_jig_type
  TestJigTypes(distinct_on: [sf_testjig_test_jig_type_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_type_order_by!], where: sf_testjig_test_jig_type_bool_exp): [sf_testjig_test_jig_type!]!
  TestJigs(distinct_on: [sf_testjig_test_jig_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_order_by!], where: sf_testjig_test_jig_bool_exp): [sf_testjig_test_jig!]!
  currentPositionConfiguration(distinct_on: [sensorflow_v_current_position_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_current_position_configurations_order_by!], where: sensorflow_v_current_position_configurations_bool_exp): [sensorflow_v_current_position_configurations!]!
  domoEmbed(embed_id: String!): sensorflow_domo_embeds
  domoEmbeds(distinct_on: [sensorflow_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_domo_embeds_order_by!], where: sensorflow_domo_embeds_bool_exp): [sensorflow_domo_embeds!]!
  firmwareReleases(distinct_on: [sensorflow_firmware_releases_select_column!], limit: Int, offset: Int, order_by: [sensorflow_firmware_releases_order_by!], where: sensorflow_firmware_releases_bool_exp): [sensorflow_firmware_releases!]!
  gatewayKittingProcessMetadata(distinct_on: [sensorflow_warehouse_kitting_process_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_kitting_process_metadata_order_by!], where: sensorflow_warehouse_kitting_process_metadata_bool_exp): [sensorflow_warehouse_kitting_process_metadata!]!
  gatewayToPositionMappings(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): [sensorflow_gateway_to_position_mapping!]!
  gateways(distinct_on: [sensorflow_gateways_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateways_order_by!], where: sensorflow_gateways_bool_exp): [sensorflow_gateways!]!
  getSmartRemValidationHistory(distinct_on: [sensorflow_smart_rem_validation_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_smart_rem_validation_history_order_by!], where: sensorflow_smart_rem_validation_history_bool_exp): [sensorflow_smart_rem_validation_history!]!
  infrastructureEmonPowerStatus(distinct_on: [sensorflow_v_infrastructure_emon_power_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructure_emon_power_status_order_by!], where: sensorflow_v_infrastructure_emon_power_status_bool_exp): [sensorflow_v_infrastructure_emon_power_status!]!
  infrastructures(distinct_on: [sensorflow_v_infrastructures_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructures_order_by!], where: sensorflow_v_infrastructures_bool_exp): [sensorflow_v_infrastructures!]!
  keyCategories(distinct_on: [sensorflow_key_categories_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_categories_order_by!], where: sensorflow_key_categories_bool_exp): [sensorflow_key_categories!]!
  keyEntries(distinct_on: [keyEntries_select_column!], limit: Int, offset: Int, order_by: [keyEntries_order_by!], where: keyEntries_bool_exp): [keyEntries!]!
  keyEntries_aggregate(distinct_on: [keyEntries_select_column!], limit: Int, offset: Int, order_by: [keyEntries_order_by!], where: keyEntries_bool_exp): keyEntries_aggregate!
  keyEntries_by_pk(keyEntryId: Int!): keyEntries
  keyEntries_stream(batch_size: Int!, cursor: [keyEntries_stream_cursor_input]!, where: keyEntries_bool_exp): [keyEntries!]!
  locationDomoEmbed(embed_id: String!, location_id: uuid!): sensorflow_location_domo_embeds
  locationDomoEmbeds(distinct_on: [sensorflow_location_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_domo_embeds_order_by!], where: sensorflow_location_domo_embeds_bool_exp): [sensorflow_location_domo_embeds!]!
  locationList(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): [sensorflow_locations!]!
  locationMetadata(locationId: uuid!): sensorflow_location_metadata
  locationOne(locationId: uuid!): sensorflow_locations
  nodeException(distinct_on: [sensorflow_node_exception_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exception_order_by!], where: sensorflow_node_exception_bool_exp): [sensorflow_node_exception!]!
  nodeExceptionAggregate(distinct_on: [sensorflow_node_exception_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exception_order_by!], where: sensorflow_node_exception_bool_exp): sensorflow_node_exception_aggregate!
  nodeExceptionByPk(id: Int!): sensorflow_node_exception
  nodeExceptions(distinct_on: [sensorflow_node_exceptions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exceptions_order_by!], where: sensorflow_node_exceptions_bool_exp): [sensorflow_node_exceptions!]!
  nodeExceptionsAggregate(distinct_on: [sensorflow_node_exceptions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_exceptions_order_by!], where: sensorflow_node_exceptions_bool_exp): sensorflow_node_exceptions_aggregate!
  nodeExceptionsByPk(id: Int!): sensorflow_node_exceptions
  nodeJoinStatuses(distinct_on: [sensorflow_node_join_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_status_order_by!], where: sensorflow_node_join_status_bool_exp): [sensorflow_node_join_status!]!
  nodeOnlineStatuses(distinct_on: [sensorflow_node_online_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_online_status_order_by!], where: sensorflow_node_online_status_bool_exp): [sensorflow_node_online_status!]!
  position(positionId: uuid!): sensorflow_positions
  positionConfigurations(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): [positionConfigurations!]!
  positionConfigurationsForActivityLog(distinct_on: [positionConfigurationsForActivityLog_select_column!], limit: Int, offset: Int, order_by: [positionConfigurationsForActivityLog_order_by!], where: positionConfigurationsForActivityLog_bool_exp): [positionConfigurationsForActivityLog!]!
  positionConfigurationsForActivityLog_aggregate(distinct_on: [positionConfigurationsForActivityLog_select_column!], limit: Int, offset: Int, order_by: [positionConfigurationsForActivityLog_order_by!], where: positionConfigurationsForActivityLog_bool_exp): positionConfigurationsForActivityLog_aggregate!
  positionConfigurationsForActivityLog_stream(batch_size: Int!, cursor: [positionConfigurationsForActivityLog_stream_cursor_input]!, where: positionConfigurationsForActivityLog_bool_exp): [positionConfigurationsForActivityLog!]!
  positionConfigurations_aggregate(distinct_on: [positionConfigurations_select_column!], limit: Int, offset: Int, order_by: [positionConfigurations_order_by!], where: positionConfigurations_bool_exp): positionConfigurations_aggregate!
  positionConfigurations_by_pk(creationDate: timestamptz!, id: uuid!): positionConfigurations
  positionConfigurations_stream(batch_size: Int!, cursor: [positionConfigurations_stream_cursor_input]!, where: positionConfigurations_bool_exp): [positionConfigurations!]!
  positionPrecoolStatus(distinct_on: [sensorflow_position_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_precool_status_order_by!], where: sensorflow_position_precool_status_bool_exp): [sensorflow_position_precool_status!]!
  positions(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): [sensorflow_positions!]!
  positionsAggregate(distinct_on: [sensorflow_positions_select_column!], limit: Int, offset: Int, order_by: [sensorflow_positions_order_by!], where: sensorflow_positions_bool_exp): sensorflow_positions_aggregate!
  root: String
  sensorflow_ac_command_event_stream(distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_ac_command_event_stream_aggregate(distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): sensorflow_ac_command_event_stream_aggregate!
  sensorflow_ac_command_event_stream_stream(batch_size: Int!, cursor: [sensorflow_ac_command_event_stream_stream_cursor_input]!, where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_ac_models(distinct_on: [sensorflow_ac_models_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_models_order_by!], where: sensorflow_ac_models_bool_exp): [sensorflow_ac_models!]!
  sensorflow_ac_models_aggregate(distinct_on: [sensorflow_ac_models_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_models_order_by!], where: sensorflow_ac_models_bool_exp): sensorflow_ac_models_aggregate!
  sensorflow_ac_models_by_pk(id: uuid!): sensorflow_ac_models
  sensorflow_ac_models_stream(batch_size: Int!, cursor: [sensorflow_ac_models_stream_cursor_input]!, where: sensorflow_ac_models_bool_exp): [sensorflow_ac_models!]!
  sensorflow_ac_setting_commands(distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): [sensorflow_ac_setting_commands!]!
  sensorflow_ac_setting_commands_aggregate(distinct_on: [sensorflow_ac_setting_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_setting_commands_order_by!], where: sensorflow_ac_setting_commands_bool_exp): sensorflow_ac_setting_commands_aggregate!
  sensorflow_ac_setting_commands_by_pk(id: uuid!): sensorflow_ac_setting_commands
  sensorflow_ac_setting_commands_stream(batch_size: Int!, cursor: [sensorflow_ac_setting_commands_stream_cursor_input]!, where: sensorflow_ac_setting_commands_bool_exp): [sensorflow_ac_setting_commands!]!
  sensorflow_ai_dt_ac_event_stream(distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_dt_ac_event_stream_aggregate(distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): sensorflow_ai_dt_ac_event_stream_aggregate!
  sensorflow_ai_dt_ac_event_stream_stream(batch_size: Int!, cursor: [sensorflow_ai_dt_ac_event_stream_stream_cursor_input]!, where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_dt_config_event_stream(distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): [sensorflow_ai_dt_config_event_stream!]!
  sensorflow_ai_dt_config_event_stream_aggregate(distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): sensorflow_ai_dt_config_event_stream_aggregate!
  sensorflow_ai_dt_config_event_stream_stream(batch_size: Int!, cursor: [sensorflow_ai_dt_config_event_stream_stream_cursor_input]!, where: sensorflow_ai_dt_config_event_stream_bool_exp): [sensorflow_ai_dt_config_event_stream!]!
  sensorflow_ai_dt_event_stream(distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): [sensorflow_ai_dt_event_stream!]!
  sensorflow_ai_dt_event_stream_aggregate(distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): sensorflow_ai_dt_event_stream_aggregate!
  sensorflow_ai_dt_event_stream_stream(batch_size: Int!, cursor: [sensorflow_ai_dt_event_stream_stream_cursor_input]!, where: sensorflow_ai_dt_event_stream_bool_exp): [sensorflow_ai_dt_event_stream!]!
  sensorflow_ai_dt_room_comfort_report(distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): [sensorflow_ai_dt_room_comfort_report!]!
  sensorflow_ai_dt_room_comfort_report_aggregate(distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): sensorflow_ai_dt_room_comfort_report_aggregate!
  sensorflow_ai_dt_room_comfort_report_stream(batch_size: Int!, cursor: [sensorflow_ai_dt_room_comfort_report_stream_cursor_input]!, where: sensorflow_ai_dt_room_comfort_report_bool_exp): [sensorflow_ai_dt_room_comfort_report!]!
  sensorflow_ai_dt_synced_raw_data(distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): [sensorflow_ai_dt_synced_raw_data!]!
  sensorflow_ai_dt_synced_raw_data_aggregate(distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): sensorflow_ai_dt_synced_raw_data_aggregate!
  sensorflow_ai_dt_synced_raw_data_stream(batch_size: Int!, cursor: [sensorflow_ai_dt_synced_raw_data_stream_cursor_input]!, where: sensorflow_ai_dt_synced_raw_data_bool_exp): [sensorflow_ai_dt_synced_raw_data!]!
  sensorflow_ai_f_get_ac_command_event_stream(args: sensorflow_ai_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_f_get_ac_command_event_stream_aggregate(args: sensorflow_ai_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): sensorflow_ai_dt_ac_event_stream_aggregate!
  sensorflow_ai_f_get_all_event_stream(args: sensorflow_ai_f_get_all_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): [sensorflow_ai_dt_ac_event_stream!]!
  sensorflow_ai_f_get_all_event_stream_aggregate(args: sensorflow_ai_f_get_all_event_stream_args!, distinct_on: [sensorflow_ai_dt_ac_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_ac_event_stream_order_by!], where: sensorflow_ai_dt_ac_event_stream_bool_exp): sensorflow_ai_dt_ac_event_stream_aggregate!
  sensorflow_ai_f_get_config_event_stream(args: sensorflow_ai_f_get_config_event_stream_args!, distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): [sensorflow_ai_dt_config_event_stream!]!
  sensorflow_ai_f_get_config_event_stream_aggregate(args: sensorflow_ai_f_get_config_event_stream_args!, distinct_on: [sensorflow_ai_dt_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_config_event_stream_order_by!], where: sensorflow_ai_dt_config_event_stream_bool_exp): sensorflow_ai_dt_config_event_stream_aggregate!
  sensorflow_ai_f_get_event_stream(args: sensorflow_ai_f_get_event_stream_args!, distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): [sensorflow_ai_dt_event_stream!]!
  sensorflow_ai_f_get_event_stream_aggregate(args: sensorflow_ai_f_get_event_stream_args!, distinct_on: [sensorflow_ai_dt_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_event_stream_order_by!], where: sensorflow_ai_dt_event_stream_bool_exp): sensorflow_ai_dt_event_stream_aggregate!
  sensorflow_ai_f_get_id_info(args: sensorflow_ai_f_get_id_info_args!, distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): [sensorflow_autosets_with_keys!]!
  sensorflow_ai_f_get_id_info_aggregate(args: sensorflow_ai_f_get_id_info_args!, distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): sensorflow_autosets_with_keys_aggregate!
  sensorflow_ai_f_get_synced_raw_data(args: sensorflow_ai_f_get_synced_raw_data_args!, distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): [sensorflow_ai_dt_synced_raw_data!]!
  sensorflow_ai_f_get_synced_raw_data_aggregate(args: sensorflow_ai_f_get_synced_raw_data_args!, distinct_on: [sensorflow_ai_dt_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_synced_raw_data_order_by!], where: sensorflow_ai_dt_synced_raw_data_bool_exp): sensorflow_ai_dt_synced_raw_data_aggregate!
  sensorflow_ai_f_room_comfort_analysis(args: sensorflow_ai_f_room_comfort_analysis_args!, distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): [sensorflow_ai_dt_room_comfort_report!]!
  sensorflow_ai_f_room_comfort_analysis_aggregate(args: sensorflow_ai_f_room_comfort_analysis_args!, distinct_on: [sensorflow_ai_dt_room_comfort_report_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_dt_room_comfort_report_order_by!], where: sensorflow_ai_dt_room_comfort_report_bool_exp): sensorflow_ai_dt_room_comfort_report_aggregate!
  sensorflow_ai_v_get_precool_command_stream(distinct_on: [sensorflow_ai_v_get_precool_command_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_get_precool_command_stream_order_by!], where: sensorflow_ai_v_get_precool_command_stream_bool_exp): [sensorflow_ai_v_get_precool_command_stream!]!
  sensorflow_ai_v_get_precool_command_stream_aggregate(distinct_on: [sensorflow_ai_v_get_precool_command_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_get_precool_command_stream_order_by!], where: sensorflow_ai_v_get_precool_command_stream_bool_exp): sensorflow_ai_v_get_precool_command_stream_aggregate!
  sensorflow_ai_v_get_precool_command_stream_stream(batch_size: Int!, cursor: [sensorflow_ai_v_get_precool_command_stream_stream_cursor_input]!, where: sensorflow_ai_v_get_precool_command_stream_bool_exp): [sensorflow_ai_v_get_precool_command_stream!]!
  sensorflow_ai_v_room_status(distinct_on: [sensorflow_ai_v_room_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_room_status_order_by!], where: sensorflow_ai_v_room_status_bool_exp): [sensorflow_ai_v_room_status!]!
  sensorflow_ai_v_room_status_aggregate(distinct_on: [sensorflow_ai_v_room_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ai_v_room_status_order_by!], where: sensorflow_ai_v_room_status_bool_exp): sensorflow_ai_v_room_status_aggregate!
  sensorflow_ai_v_room_status_stream(batch_size: Int!, cursor: [sensorflow_ai_v_room_status_stream_cursor_input]!, where: sensorflow_ai_v_room_status_bool_exp): [sensorflow_ai_v_room_status!]!
  sensorflow_autosets_with_keys(distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): [sensorflow_autosets_with_keys!]!
  sensorflow_autosets_with_keys_aggregate(distinct_on: [sensorflow_autosets_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_autosets_with_keys_order_by!], where: sensorflow_autosets_with_keys_bool_exp): sensorflow_autosets_with_keys_aggregate!
  sensorflow_autosets_with_keys_stream(batch_size: Int!, cursor: [sensorflow_autosets_with_keys_stream_cursor_input]!, where: sensorflow_autosets_with_keys_bool_exp): [sensorflow_autosets_with_keys!]!
  sensorflow_beacon_slots(distinct_on: [sensorflow_beacon_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_beacon_slots_order_by!], where: sensorflow_beacon_slots_bool_exp): [sensorflow_beacon_slots!]!
  sensorflow_beacon_slots_aggregate(distinct_on: [sensorflow_beacon_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_beacon_slots_order_by!], where: sensorflow_beacon_slots_bool_exp): sensorflow_beacon_slots_aggregate!
  sensorflow_beacon_slots_by_pk(id: uuid!): sensorflow_beacon_slots
  sensorflow_beacon_slots_stream(batch_size: Int!, cursor: [sensorflow_beacon_slots_stream_cursor_input]!, where: sensorflow_beacon_slots_bool_exp): [sensorflow_beacon_slots!]!
  sensorflow_checklist_items(distinct_on: [sensorflow_checklist_items_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklist_items_order_by!], where: sensorflow_checklist_items_bool_exp): [sensorflow_checklist_items!]!
  sensorflow_checklist_items_aggregate(distinct_on: [sensorflow_checklist_items_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklist_items_order_by!], where: sensorflow_checklist_items_bool_exp): sensorflow_checklist_items_aggregate!
  sensorflow_checklist_items_stream(batch_size: Int!, cursor: [sensorflow_checklist_items_stream_cursor_input]!, where: sensorflow_checklist_items_bool_exp): [sensorflow_checklist_items!]!
  sensorflow_checklists(distinct_on: [sensorflow_checklists_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklists_order_by!], where: sensorflow_checklists_bool_exp): [sensorflow_checklists!]!
  sensorflow_checklists_aggregate(distinct_on: [sensorflow_checklists_select_column!], limit: Int, offset: Int, order_by: [sensorflow_checklists_order_by!], where: sensorflow_checklists_bool_exp): sensorflow_checklists_aggregate!
  sensorflow_checklists_by_pk(id: String!): sensorflow_checklists
  sensorflow_checklists_stream(batch_size: Int!, cursor: [sensorflow_checklists_stream_cursor_input]!, where: sensorflow_checklists_bool_exp): [sensorflow_checklists!]!
  sensorflow_chillers(distinct_on: [sensorflow_chillers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_chillers_order_by!], where: sensorflow_chillers_bool_exp): [sensorflow_chillers!]!
  sensorflow_chillers_aggregate(distinct_on: [sensorflow_chillers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_chillers_order_by!], where: sensorflow_chillers_bool_exp): sensorflow_chillers_aggregate!
  sensorflow_chillers_by_pk(id: uuid!): sensorflow_chillers
  sensorflow_chillers_stream(batch_size: Int!, cursor: [sensorflow_chillers_stream_cursor_input]!, where: sensorflow_chillers_bool_exp): [sensorflow_chillers!]!
  sensorflow_clickup_webhook_events(distinct_on: [sensorflow_clickup_webhook_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_clickup_webhook_events_order_by!], where: sensorflow_clickup_webhook_events_bool_exp): [sensorflow_clickup_webhook_events!]!
  sensorflow_clickup_webhook_events_aggregate(distinct_on: [sensorflow_clickup_webhook_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_clickup_webhook_events_order_by!], where: sensorflow_clickup_webhook_events_bool_exp): sensorflow_clickup_webhook_events_aggregate!
  sensorflow_clickup_webhook_events_by_pk(id: uuid!): sensorflow_clickup_webhook_events
  sensorflow_clickup_webhook_events_stream(batch_size: Int!, cursor: [sensorflow_clickup_webhook_events_stream_cursor_input]!, where: sensorflow_clickup_webhook_events_bool_exp): [sensorflow_clickup_webhook_events!]!
  sensorflow_comfortplus_activations(distinct_on: [sensorflow_comfortplus_activations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_comfortplus_activations_order_by!], where: sensorflow_comfortplus_activations_bool_exp): [sensorflow_comfortplus_activations!]!
  sensorflow_comfortplus_activations_aggregate(distinct_on: [sensorflow_comfortplus_activations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_comfortplus_activations_order_by!], where: sensorflow_comfortplus_activations_bool_exp): sensorflow_comfortplus_activations_aggregate!
  sensorflow_comfortplus_activations_by_pk(id: uuid!): sensorflow_comfortplus_activations
  sensorflow_comfortplus_activations_stream(batch_size: Int!, cursor: [sensorflow_comfortplus_activations_stream_cursor_input]!, where: sensorflow_comfortplus_activations_bool_exp): [sensorflow_comfortplus_activations!]!
  sensorflow_compressor_mapping(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): [sensorflow_compressor_mapping!]!
  sensorflow_compressor_mapping_aggregate(distinct_on: [sensorflow_compressor_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressor_mapping_order_by!], where: sensorflow_compressor_mapping_bool_exp): sensorflow_compressor_mapping_aggregate!
  sensorflow_compressor_mapping_by_pk(compressorId: uuid!, positionId: uuid!): sensorflow_compressor_mapping
  sensorflow_compressor_mapping_stream(batch_size: Int!, cursor: [sensorflow_compressor_mapping_stream_cursor_input]!, where: sensorflow_compressor_mapping_bool_exp): [sensorflow_compressor_mapping!]!
  sensorflow_compressors(distinct_on: [sensorflow_compressors_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressors_order_by!], where: sensorflow_compressors_bool_exp): [sensorflow_compressors!]!
  sensorflow_compressors_aggregate(distinct_on: [sensorflow_compressors_select_column!], limit: Int, offset: Int, order_by: [sensorflow_compressors_order_by!], where: sensorflow_compressors_bool_exp): sensorflow_compressors_aggregate!
  sensorflow_compressors_by_pk(compressorId: uuid!): sensorflow_compressors
  sensorflow_compressors_stream(batch_size: Int!, cursor: [sensorflow_compressors_stream_cursor_input]!, where: sensorflow_compressors_bool_exp): [sensorflow_compressors!]!
  sensorflow_config_event_stream(distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): [sensorflow_config_event_stream!]!
  sensorflow_config_event_stream_aggregate(distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): sensorflow_config_event_stream_aggregate!
  sensorflow_config_event_stream_stream(batch_size: Int!, cursor: [sensorflow_config_event_stream_stream_cursor_input]!, where: sensorflow_config_event_stream_bool_exp): [sensorflow_config_event_stream!]!
  sensorflow_cooling_towers(distinct_on: [sensorflow_cooling_towers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_cooling_towers_order_by!], where: sensorflow_cooling_towers_bool_exp): [sensorflow_cooling_towers!]!
  sensorflow_cooling_towers_aggregate(distinct_on: [sensorflow_cooling_towers_select_column!], limit: Int, offset: Int, order_by: [sensorflow_cooling_towers_order_by!], where: sensorflow_cooling_towers_bool_exp): sensorflow_cooling_towers_aggregate!
  sensorflow_cooling_towers_by_pk(id: uuid!): sensorflow_cooling_towers
  sensorflow_cooling_towers_stream(batch_size: Int!, cursor: [sensorflow_cooling_towers_stream_cursor_input]!, where: sensorflow_cooling_towers_bool_exp): [sensorflow_cooling_towers!]!
  sensorflow_currencies(distinct_on: [sensorflow_currencies_select_column!], limit: Int, offset: Int, order_by: [sensorflow_currencies_order_by!], where: sensorflow_currencies_bool_exp): [sensorflow_currencies!]!
  sensorflow_currencies_aggregate(distinct_on: [sensorflow_currencies_select_column!], limit: Int, offset: Int, order_by: [sensorflow_currencies_order_by!], where: sensorflow_currencies_bool_exp): sensorflow_currencies_aggregate!
  sensorflow_currencies_by_pk(id: Int!): sensorflow_currencies
  sensorflow_currencies_stream(batch_size: Int!, cursor: [sensorflow_currencies_stream_cursor_input]!, where: sensorflow_currencies_bool_exp): [sensorflow_currencies!]!
  sensorflow_domo_embeds_aggregate(distinct_on: [sensorflow_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_domo_embeds_order_by!], where: sensorflow_domo_embeds_bool_exp): sensorflow_domo_embeds_aggregate!
  sensorflow_domo_embeds_stream(batch_size: Int!, cursor: [sensorflow_domo_embeds_stream_cursor_input]!, where: sensorflow_domo_embeds_bool_exp): [sensorflow_domo_embeds!]!
  sensorflow_events_stream(distinct_on: [sensorflow_events_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_events_stream_order_by!], where: sensorflow_events_stream_bool_exp): [sensorflow_events_stream!]!
  sensorflow_events_stream_aggregate(distinct_on: [sensorflow_events_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_events_stream_order_by!], where: sensorflow_events_stream_bool_exp): sensorflow_events_stream_aggregate!
  sensorflow_events_stream_stream(batch_size: Int!, cursor: [sensorflow_events_stream_stream_cursor_input]!, where: sensorflow_events_stream_bool_exp): [sensorflow_events_stream!]!
  sensorflow_f_get_ac_command_event_stream(args: sensorflow_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_f_get_ac_command_event_stream_aggregate(args: sensorflow_f_get_ac_command_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): sensorflow_ac_command_event_stream_aggregate!
  sensorflow_f_get_all_event_stream(args: sensorflow_f_get_all_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): [sensorflow_ac_command_event_stream!]!
  sensorflow_f_get_all_event_stream_aggregate(args: sensorflow_f_get_all_event_stream_args!, distinct_on: [sensorflow_ac_command_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ac_command_event_stream_order_by!], where: sensorflow_ac_command_event_stream_bool_exp): sensorflow_ac_command_event_stream_aggregate!
  sensorflow_f_get_config_event_stream(args: sensorflow_f_get_config_event_stream_args!, distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): [sensorflow_config_event_stream!]!
  sensorflow_f_get_config_event_stream_aggregate(args: sensorflow_f_get_config_event_stream_args!, distinct_on: [sensorflow_config_event_stream_select_column!], limit: Int, offset: Int, order_by: [sensorflow_config_event_stream_order_by!], where: sensorflow_config_event_stream_bool_exp): sensorflow_config_event_stream_aggregate!
  sensorflow_f_get_event_stream(args: sensorflow_f_get_event_stream_args!, distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): [sensorflow_get_event_stream_view!]!
  sensorflow_f_get_event_stream_aggregate(args: sensorflow_f_get_event_stream_args!, distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): sensorflow_get_event_stream_view_aggregate!
  sensorflow_f_get_synced_raw_data(args: sensorflow_f_get_synced_raw_data_args!, distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): [sensorflow_synced_raw_data!]!
  sensorflow_f_get_synced_raw_data_aggregate(args: sensorflow_f_get_synced_raw_data_args!, distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): sensorflow_synced_raw_data_aggregate!
  sensorflow_firmware_releases_aggregate(distinct_on: [sensorflow_firmware_releases_select_column!], limit: Int, offset: Int, order_by: [sensorflow_firmware_releases_order_by!], where: sensorflow_firmware_releases_bool_exp): sensorflow_firmware_releases_aggregate!
  sensorflow_firmware_releases_by_pk(id: Int!): sensorflow_firmware_releases
  sensorflow_firmware_releases_stream(batch_size: Int!, cursor: [sensorflow_firmware_releases_stream_cursor_input]!, where: sensorflow_firmware_releases_bool_exp): [sensorflow_firmware_releases!]!
  sensorflow_gateway_frequency(distinct_on: [sensorflow_gateway_frequency_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_frequency_order_by!], where: sensorflow_gateway_frequency_bool_exp): [sensorflow_gateway_frequency!]!
  sensorflow_gateway_frequency_aggregate(distinct_on: [sensorflow_gateway_frequency_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_frequency_order_by!], where: sensorflow_gateway_frequency_bool_exp): sensorflow_gateway_frequency_aggregate!
  sensorflow_gateway_frequency_by_pk(balena_id: String!, reserved_frequency: bigint!): sensorflow_gateway_frequency
  sensorflow_gateway_frequency_stream(batch_size: Int!, cursor: [sensorflow_gateway_frequency_stream_cursor_input]!, where: sensorflow_gateway_frequency_bool_exp): [sensorflow_gateway_frequency!]!
  sensorflow_gateway_health_data(distinct_on: [sensorflow_gateway_health_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_health_data_order_by!], where: sensorflow_gateway_health_data_bool_exp): [sensorflow_gateway_health_data!]!
  sensorflow_gateway_health_data_aggregate(distinct_on: [sensorflow_gateway_health_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_health_data_order_by!], where: sensorflow_gateway_health_data_bool_exp): sensorflow_gateway_health_data_aggregate!
  sensorflow_gateway_health_data_stream(batch_size: Int!, cursor: [sensorflow_gateway_health_data_stream_cursor_input]!, where: sensorflow_gateway_health_data_bool_exp): [sensorflow_gateway_health_data!]!
  sensorflow_gateway_to_position_mapping_aggregate(distinct_on: [sensorflow_gateway_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateway_to_position_mapping_order_by!], where: sensorflow_gateway_to_position_mapping_bool_exp): sensorflow_gateway_to_position_mapping_aggregate!
  sensorflow_gateway_to_position_mapping_by_pk(positionId: uuid!): sensorflow_gateway_to_position_mapping
  sensorflow_gateway_to_position_mapping_stream(batch_size: Int!, cursor: [sensorflow_gateway_to_position_mapping_stream_cursor_input]!, where: sensorflow_gateway_to_position_mapping_bool_exp): [sensorflow_gateway_to_position_mapping!]!
  sensorflow_gateways_aggregate(distinct_on: [sensorflow_gateways_select_column!], limit: Int, offset: Int, order_by: [sensorflow_gateways_order_by!], where: sensorflow_gateways_bool_exp): sensorflow_gateways_aggregate!
  sensorflow_gateways_by_pk(gatewayId: String!): sensorflow_gateways
  sensorflow_gateways_stream(batch_size: Int!, cursor: [sensorflow_gateways_stream_cursor_input]!, where: sensorflow_gateways_bool_exp): [sensorflow_gateways!]!
  sensorflow_get_event_stream_view(distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): [sensorflow_get_event_stream_view!]!
  sensorflow_get_event_stream_view_aggregate(distinct_on: [sensorflow_get_event_stream_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_event_stream_view_order_by!], where: sensorflow_get_event_stream_view_bool_exp): sensorflow_get_event_stream_view_aggregate!
  sensorflow_get_event_stream_view_stream(batch_size: Int!, cursor: [sensorflow_get_event_stream_view_stream_cursor_input]!, where: sensorflow_get_event_stream_view_bool_exp): [sensorflow_get_event_stream_view!]!
  sensorflow_get_precool_command_streams(distinct_on: [sensorflow_get_precool_command_streams_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_precool_command_streams_order_by!], where: sensorflow_get_precool_command_streams_bool_exp): [sensorflow_get_precool_command_streams!]!
  sensorflow_get_precool_command_streams_aggregate(distinct_on: [sensorflow_get_precool_command_streams_select_column!], limit: Int, offset: Int, order_by: [sensorflow_get_precool_command_streams_order_by!], where: sensorflow_get_precool_command_streams_bool_exp): sensorflow_get_precool_command_streams_aggregate!
  sensorflow_get_precool_command_streams_stream(batch_size: Int!, cursor: [sensorflow_get_precool_command_streams_stream_cursor_input]!, where: sensorflow_get_precool_command_streams_bool_exp): [sensorflow_get_precool_command_streams!]!
  sensorflow_key_categories_aggregate(distinct_on: [sensorflow_key_categories_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_categories_order_by!], where: sensorflow_key_categories_bool_exp): sensorflow_key_categories_aggregate!
  sensorflow_key_categories_by_pk(id: uuid!): sensorflow_key_categories
  sensorflow_key_categories_stream(batch_size: Int!, cursor: [sensorflow_key_categories_stream_cursor_input]!, where: sensorflow_key_categories_bool_exp): [sensorflow_key_categories!]!
  sensorflow_key_category_room_templates(distinct_on: [sensorflow_key_category_room_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_room_templates_order_by!], where: sensorflow_key_category_room_templates_bool_exp): [sensorflow_key_category_room_templates!]!
  sensorflow_key_category_room_templates_aggregate(distinct_on: [sensorflow_key_category_room_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_room_templates_order_by!], where: sensorflow_key_category_room_templates_bool_exp): sensorflow_key_category_room_templates_aggregate!
  sensorflow_key_category_room_templates_by_pk(id: uuid!): sensorflow_key_category_room_templates
  sensorflow_key_category_room_templates_stream(batch_size: Int!, cursor: [sensorflow_key_category_room_templates_stream_cursor_input]!, where: sensorflow_key_category_room_templates_bool_exp): [sensorflow_key_category_room_templates!]!
  sensorflow_key_category_slot_templates(distinct_on: [sensorflow_key_category_slot_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_slot_templates_order_by!], where: sensorflow_key_category_slot_templates_bool_exp): [sensorflow_key_category_slot_templates!]!
  sensorflow_key_category_slot_templates_aggregate(distinct_on: [sensorflow_key_category_slot_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_slot_templates_order_by!], where: sensorflow_key_category_slot_templates_bool_exp): sensorflow_key_category_slot_templates_aggregate!
  sensorflow_key_category_slot_templates_by_pk(id: uuid!): sensorflow_key_category_slot_templates
  sensorflow_key_category_slot_templates_stream(batch_size: Int!, cursor: [sensorflow_key_category_slot_templates_stream_cursor_input]!, where: sensorflow_key_category_slot_templates_bool_exp): [sensorflow_key_category_slot_templates!]!
  sensorflow_key_category_templates(distinct_on: [sensorflow_key_category_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_templates_order_by!], where: sensorflow_key_category_templates_bool_exp): [sensorflow_key_category_templates!]!
  sensorflow_key_category_templates_aggregate(distinct_on: [sensorflow_key_category_templates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_templates_order_by!], where: sensorflow_key_category_templates_bool_exp): sensorflow_key_category_templates_aggregate!
  sensorflow_key_category_templates_by_pk(id: uuid!): sensorflow_key_category_templates
  sensorflow_key_category_templates_stream(batch_size: Int!, cursor: [sensorflow_key_category_templates_stream_cursor_input]!, where: sensorflow_key_category_templates_bool_exp): [sensorflow_key_category_templates!]!
  sensorflow_key_category_to_key_position(distinct_on: [sensorflow_key_category_to_key_position_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_to_key_position_order_by!], where: sensorflow_key_category_to_key_position_bool_exp): [sensorflow_key_category_to_key_position!]!
  sensorflow_key_category_to_key_position_aggregate(distinct_on: [sensorflow_key_category_to_key_position_select_column!], limit: Int, offset: Int, order_by: [sensorflow_key_category_to_key_position_order_by!], where: sensorflow_key_category_to_key_position_bool_exp): sensorflow_key_category_to_key_position_aggregate!
  sensorflow_key_category_to_key_position_by_pk(categoryName: String!, locationName: String!, positionId: uuid!): sensorflow_key_category_to_key_position
  sensorflow_key_category_to_key_position_stream(batch_size: Int!, cursor: [sensorflow_key_category_to_key_position_stream_cursor_input]!, where: sensorflow_key_category_to_key_position_bool_exp): [sensorflow_key_category_to_key_position!]!
  sensorflow_location_domo_embeds_aggregate(distinct_on: [sensorflow_location_domo_embeds_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_domo_embeds_order_by!], where: sensorflow_location_domo_embeds_bool_exp): sensorflow_location_domo_embeds_aggregate!
  sensorflow_location_domo_embeds_stream(batch_size: Int!, cursor: [sensorflow_location_domo_embeds_stream_cursor_input]!, where: sensorflow_location_domo_embeds_bool_exp): [sensorflow_location_domo_embeds!]!
  sensorflow_location_metadata(distinct_on: [sensorflow_location_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_metadata_order_by!], where: sensorflow_location_metadata_bool_exp): [sensorflow_location_metadata!]!
  sensorflow_location_metadata_aggregate(distinct_on: [sensorflow_location_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_metadata_order_by!], where: sensorflow_location_metadata_bool_exp): sensorflow_location_metadata_aggregate!
  sensorflow_location_metadata_stream(batch_size: Int!, cursor: [sensorflow_location_metadata_stream_cursor_input]!, where: sensorflow_location_metadata_bool_exp): [sensorflow_location_metadata!]!
  sensorflow_location_to_user_mapping(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  sensorflow_location_to_user_mapping_aggregate(distinct_on: [sensorflow_location_to_user_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_location_to_user_mapping_order_by!], where: sensorflow_location_to_user_mapping_bool_exp): sensorflow_location_to_user_mapping_aggregate!
  sensorflow_location_to_user_mapping_by_pk(id: uuid!): sensorflow_location_to_user_mapping
  sensorflow_location_to_user_mapping_stream(batch_size: Int!, cursor: [sensorflow_location_to_user_mapping_stream_cursor_input]!, where: sensorflow_location_to_user_mapping_bool_exp): [sensorflow_location_to_user_mapping!]!
  sensorflow_locations_aggregate(distinct_on: [sensorflow_locations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_locations_order_by!], where: sensorflow_locations_bool_exp): sensorflow_locations_aggregate!
  sensorflow_locations_stream(batch_size: Int!, cursor: [sensorflow_locations_stream_cursor_input]!, where: sensorflow_locations_bool_exp): [sensorflow_locations!]!
  sensorflow_mapping_coordinates(distinct_on: [sensorflow_mapping_coordinates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_mapping_coordinates_order_by!], where: sensorflow_mapping_coordinates_bool_exp): [sensorflow_mapping_coordinates!]!
  sensorflow_mapping_coordinates_aggregate(distinct_on: [sensorflow_mapping_coordinates_select_column!], limit: Int, offset: Int, order_by: [sensorflow_mapping_coordinates_order_by!], where: sensorflow_mapping_coordinates_bool_exp): sensorflow_mapping_coordinates_aggregate!
  sensorflow_mapping_coordinates_stream(batch_size: Int!, cursor: [sensorflow_mapping_coordinates_stream_cursor_input]!, where: sensorflow_mapping_coordinates_bool_exp): [sensorflow_mapping_coordinates!]!
  sensorflow_node_exception_stream(batch_size: Int!, cursor: [sensorflow_node_exception_stream_cursor_input]!, where: sensorflow_node_exception_bool_exp): [sensorflow_node_exception!]!
  sensorflow_node_exceptions_stream(batch_size: Int!, cursor: [sensorflow_node_exceptions_stream_cursor_input]!, where: sensorflow_node_exceptions_bool_exp): [sensorflow_node_exceptions!]!
  sensorflow_node_firmware(distinct_on: [sensorflow_node_firmware_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_firmware_order_by!], where: sensorflow_node_firmware_bool_exp): [sensorflow_node_firmware!]!
  sensorflow_node_firmware_aggregate(distinct_on: [sensorflow_node_firmware_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_firmware_order_by!], where: sensorflow_node_firmware_bool_exp): sensorflow_node_firmware_aggregate!
  sensorflow_node_firmware_stream(batch_size: Int!, cursor: [sensorflow_node_firmware_stream_cursor_input]!, where: sensorflow_node_firmware_bool_exp): [sensorflow_node_firmware!]!
  sensorflow_node_join_data(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): [sensorflow_node_join_data!]!
  sensorflow_node_join_data_aggregate(distinct_on: [sensorflow_node_join_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_data_order_by!], where: sensorflow_node_join_data_bool_exp): sensorflow_node_join_data_aggregate!
  sensorflow_node_join_data_stream(batch_size: Int!, cursor: [sensorflow_node_join_data_stream_cursor_input]!, where: sensorflow_node_join_data_bool_exp): [sensorflow_node_join_data!]!
  sensorflow_node_join_status_aggregate(distinct_on: [sensorflow_node_join_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_status_order_by!], where: sensorflow_node_join_status_bool_exp): sensorflow_node_join_status_aggregate!
  sensorflow_node_join_status_stream(batch_size: Int!, cursor: [sensorflow_node_join_status_stream_cursor_input]!, where: sensorflow_node_join_status_bool_exp): [sensorflow_node_join_status!]!
  sensorflow_node_join_strength_live(distinct_on: [sensorflow_node_join_strength_live_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_strength_live_order_by!], where: sensorflow_node_join_strength_live_bool_exp): [sensorflow_node_join_strength_live!]!
  sensorflow_node_join_strength_live_aggregate(distinct_on: [sensorflow_node_join_strength_live_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_join_strength_live_order_by!], where: sensorflow_node_join_strength_live_bool_exp): sensorflow_node_join_strength_live_aggregate!
  sensorflow_node_join_strength_live_stream(batch_size: Int!, cursor: [sensorflow_node_join_strength_live_stream_cursor_input]!, where: sensorflow_node_join_strength_live_bool_exp): [sensorflow_node_join_strength_live!]!
  sensorflow_node_life_cycle_event_types(distinct_on: [sensorflow_node_life_cycle_event_types_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_event_types_order_by!], where: sensorflow_node_life_cycle_event_types_bool_exp): [sensorflow_node_life_cycle_event_types!]!
  sensorflow_node_life_cycle_event_types_aggregate(distinct_on: [sensorflow_node_life_cycle_event_types_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_event_types_order_by!], where: sensorflow_node_life_cycle_event_types_bool_exp): sensorflow_node_life_cycle_event_types_aggregate!
  sensorflow_node_life_cycle_event_types_by_pk(id: Int!): sensorflow_node_life_cycle_event_types
  sensorflow_node_life_cycle_event_types_stream(batch_size: Int!, cursor: [sensorflow_node_life_cycle_event_types_stream_cursor_input]!, where: sensorflow_node_life_cycle_event_types_bool_exp): [sensorflow_node_life_cycle_event_types!]!
  sensorflow_node_life_cycle_events(distinct_on: [sensorflow_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_events_order_by!], where: sensorflow_node_life_cycle_events_bool_exp): [sensorflow_node_life_cycle_events!]!
  sensorflow_node_life_cycle_events_aggregate(distinct_on: [sensorflow_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_life_cycle_events_order_by!], where: sensorflow_node_life_cycle_events_bool_exp): sensorflow_node_life_cycle_events_aggregate!
  sensorflow_node_life_cycle_events_by_pk(id: Int!): sensorflow_node_life_cycle_events
  sensorflow_node_life_cycle_events_stream(batch_size: Int!, cursor: [sensorflow_node_life_cycle_events_stream_cursor_input]!, where: sensorflow_node_life_cycle_events_bool_exp): [sensorflow_node_life_cycle_events!]!
  sensorflow_node_measurement_view(distinct_on: [sensorflow_node_measurement_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurement_view_order_by!], where: sensorflow_node_measurement_view_bool_exp): [sensorflow_node_measurement_view!]!
  sensorflow_node_measurement_view_aggregate(distinct_on: [sensorflow_node_measurement_view_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurement_view_order_by!], where: sensorflow_node_measurement_view_bool_exp): sensorflow_node_measurement_view_aggregate!
  sensorflow_node_measurement_view_stream(batch_size: Int!, cursor: [sensorflow_node_measurement_view_stream_cursor_input]!, where: sensorflow_node_measurement_view_bool_exp): [sensorflow_node_measurement_view!]!
  sensorflow_node_measurements(distinct_on: [sensorflow_node_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurements_order_by!], where: sensorflow_node_measurements_bool_exp): [sensorflow_node_measurements!]!
  sensorflow_node_measurements_aggregate(distinct_on: [sensorflow_node_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_measurements_order_by!], where: sensorflow_node_measurements_bool_exp): sensorflow_node_measurements_aggregate!
  sensorflow_node_measurements_stream(batch_size: Int!, cursor: [sensorflow_node_measurements_stream_cursor_input]!, where: sensorflow_node_measurements_bool_exp): [sensorflow_node_measurements!]!
  sensorflow_node_meta_data(distinct_on: [sensorflow_node_meta_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_data_order_by!], where: sensorflow_node_meta_data_bool_exp): [sensorflow_node_meta_data!]!
  sensorflow_node_meta_data_aggregate(distinct_on: [sensorflow_node_meta_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_data_order_by!], where: sensorflow_node_meta_data_bool_exp): sensorflow_node_meta_data_aggregate!
  sensorflow_node_meta_data_by_pk(measurementType: String!, nodeMacId: String!, time: timestamptz!): sensorflow_node_meta_data
  sensorflow_node_meta_data_stream(batch_size: Int!, cursor: [sensorflow_node_meta_data_stream_cursor_input]!, where: sensorflow_node_meta_data_bool_exp): [sensorflow_node_meta_data!]!
  sensorflow_node_meta_status(distinct_on: [sensorflow_node_meta_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_status_order_by!], where: sensorflow_node_meta_status_bool_exp): [sensorflow_node_meta_status!]!
  sensorflow_node_meta_status_aggregate(distinct_on: [sensorflow_node_meta_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_meta_status_order_by!], where: sensorflow_node_meta_status_bool_exp): sensorflow_node_meta_status_aggregate!
  sensorflow_node_meta_status_stream(batch_size: Int!, cursor: [sensorflow_node_meta_status_stream_cursor_input]!, where: sensorflow_node_meta_status_bool_exp): [sensorflow_node_meta_status!]!
  sensorflow_node_online_status_aggregate(distinct_on: [sensorflow_node_online_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_online_status_order_by!], where: sensorflow_node_online_status_bool_exp): sensorflow_node_online_status_aggregate!
  sensorflow_node_online_status_stream(batch_size: Int!, cursor: [sensorflow_node_online_status_stream_cursor_input]!, where: sensorflow_node_online_status_bool_exp): [sensorflow_node_online_status!]!
  sensorflow_node_slots(distinct_on: [sensorflow_node_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_slots_order_by!], where: sensorflow_node_slots_bool_exp): [sensorflow_node_slots!]!
  sensorflow_node_slots_aggregate(distinct_on: [sensorflow_node_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_slots_order_by!], where: sensorflow_node_slots_bool_exp): sensorflow_node_slots_aggregate!
  sensorflow_node_slots_by_pk(id: uuid!): sensorflow_node_slots
  sensorflow_node_slots_stream(batch_size: Int!, cursor: [sensorflow_node_slots_stream_cursor_input]!, where: sensorflow_node_slots_bool_exp): [sensorflow_node_slots!]!
  sensorflow_node_spare_set(distinct_on: [sensorflow_node_spare_set_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_spare_set_order_by!], where: sensorflow_node_spare_set_bool_exp): [sensorflow_node_spare_set!]!
  sensorflow_node_spare_set_aggregate(distinct_on: [sensorflow_node_spare_set_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_spare_set_order_by!], where: sensorflow_node_spare_set_bool_exp): sensorflow_node_spare_set_aggregate!
  sensorflow_node_spare_set_by_pk(locationId: uuid!, nodeMacId: String!): sensorflow_node_spare_set
  sensorflow_node_spare_set_stream(batch_size: Int!, cursor: [sensorflow_node_spare_set_stream_cursor_input]!, where: sensorflow_node_spare_set_bool_exp): [sensorflow_node_spare_set!]!
  sensorflow_node_status_history(distinct_on: [sensorflow_node_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_status_history_order_by!], where: sensorflow_node_status_history_bool_exp): [sensorflow_node_status_history!]!
  sensorflow_node_status_history_aggregate(distinct_on: [sensorflow_node_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_status_history_order_by!], where: sensorflow_node_status_history_bool_exp): sensorflow_node_status_history_aggregate!
  sensorflow_node_status_history_by_pk(id: Int!): sensorflow_node_status_history
  sensorflow_node_status_history_stream(batch_size: Int!, cursor: [sensorflow_node_status_history_stream_cursor_input]!, where: sensorflow_node_status_history_bool_exp): [sensorflow_node_status_history!]!
  sensorflow_node_statuses(distinct_on: [sensorflow_node_statuses_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_statuses_order_by!], where: sensorflow_node_statuses_bool_exp): [sensorflow_node_statuses!]!
  sensorflow_node_statuses_aggregate(distinct_on: [sensorflow_node_statuses_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_statuses_order_by!], where: sensorflow_node_statuses_bool_exp): sensorflow_node_statuses_aggregate!
  sensorflow_node_statuses_by_pk(id: Int!): sensorflow_node_statuses
  sensorflow_node_statuses_stream(batch_size: Int!, cursor: [sensorflow_node_statuses_stream_cursor_input]!, where: sensorflow_node_statuses_bool_exp): [sensorflow_node_statuses!]!
  sensorflow_node_system_states(distinct_on: [sensorflow_node_system_states_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_system_states_order_by!], where: sensorflow_node_system_states_bool_exp): [sensorflow_node_system_states!]!
  sensorflow_node_system_states_aggregate(distinct_on: [sensorflow_node_system_states_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_system_states_order_by!], where: sensorflow_node_system_states_bool_exp): sensorflow_node_system_states_aggregate!
  sensorflow_node_system_states_by_pk(nodeMacId: String!, time: timestamptz!): sensorflow_node_system_states
  sensorflow_node_system_states_stream(batch_size: Int!, cursor: [sensorflow_node_system_states_stream_cursor_input]!, where: sensorflow_node_system_states_bool_exp): [sensorflow_node_system_states!]!
  sensorflow_node_to_position_mapping(distinct_on: [sensorflow_node_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_position_mapping_order_by!], where: sensorflow_node_to_position_mapping_bool_exp): [sensorflow_node_to_position_mapping!]!
  sensorflow_node_to_position_mapping_aggregate(distinct_on: [sensorflow_node_to_position_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_position_mapping_order_by!], where: sensorflow_node_to_position_mapping_bool_exp): sensorflow_node_to_position_mapping_aggregate!
  sensorflow_node_to_position_mapping_stream(batch_size: Int!, cursor: [sensorflow_node_to_position_mapping_stream_cursor_input]!, where: sensorflow_node_to_position_mapping_bool_exp): [sensorflow_node_to_position_mapping!]!
  sensorflow_node_to_slot_mapping(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): [sensorflow_node_to_slot_mapping!]!
  sensorflow_node_to_slot_mapping_aggregate(distinct_on: [sensorflow_node_to_slot_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_node_to_slot_mapping_order_by!], where: sensorflow_node_to_slot_mapping_bool_exp): sensorflow_node_to_slot_mapping_aggregate!
  sensorflow_node_to_slot_mapping_by_pk(id: Int!): sensorflow_node_to_slot_mapping
  sensorflow_node_to_slot_mapping_stream(batch_size: Int!, cursor: [sensorflow_node_to_slot_mapping_stream_cursor_input]!, where: sensorflow_node_to_slot_mapping_bool_exp): [sensorflow_node_to_slot_mapping!]!
  sensorflow_nodes(distinct_on: [sensorflow_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_nodes_order_by!], where: sensorflow_nodes_bool_exp): [sensorflow_nodes!]!
  sensorflow_nodes_aggregate(distinct_on: [sensorflow_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_nodes_order_by!], where: sensorflow_nodes_bool_exp): sensorflow_nodes_aggregate!
  sensorflow_nodes_by_pk(node_mac_id: String!): sensorflow_nodes
  sensorflow_nodes_stream(batch_size: Int!, cursor: [sensorflow_nodes_stream_cursor_input]!, where: sensorflow_nodes_bool_exp): [sensorflow_nodes!]!
  sensorflow_organisations(distinct_on: [sensorflow_organisations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_organisations_order_by!], where: sensorflow_organisations_bool_exp): [sensorflow_organisations!]!
  sensorflow_organisations_aggregate(distinct_on: [sensorflow_organisations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_organisations_order_by!], where: sensorflow_organisations_bool_exp): sensorflow_organisations_aggregate!
  sensorflow_organisations_by_pk(id: uuid!): sensorflow_organisations
  sensorflow_organisations_stream(batch_size: Int!, cursor: [sensorflow_organisations_stream_cursor_input]!, where: sensorflow_organisations_bool_exp): [sensorflow_organisations!]!
  sensorflow_ota_queues(distinct_on: [sensorflow_ota_queues_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_queues_order_by!], where: sensorflow_ota_queues_bool_exp): [sensorflow_ota_queues!]!
  sensorflow_ota_queues_aggregate(distinct_on: [sensorflow_ota_queues_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_queues_order_by!], where: sensorflow_ota_queues_bool_exp): sensorflow_ota_queues_aggregate!
  sensorflow_ota_queues_by_pk(ota_queue_id: Int!): sensorflow_ota_queues
  sensorflow_ota_queues_stream(batch_size: Int!, cursor: [sensorflow_ota_queues_stream_cursor_input]!, where: sensorflow_ota_queues_bool_exp): [sensorflow_ota_queues!]!
  sensorflow_ota_runs(distinct_on: [sensorflow_ota_runs_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_runs_order_by!], where: sensorflow_ota_runs_bool_exp): [sensorflow_ota_runs!]!
  sensorflow_ota_runs_aggregate(distinct_on: [sensorflow_ota_runs_select_column!], limit: Int, offset: Int, order_by: [sensorflow_ota_runs_order_by!], where: sensorflow_ota_runs_bool_exp): sensorflow_ota_runs_aggregate!
  sensorflow_ota_runs_by_pk(current_firmware_release_id: Int!, node_mac_id: String!, ota_queue_id: Int!, target_firmware_release_id: Int!): sensorflow_ota_runs
  sensorflow_ota_runs_stream(batch_size: Int!, cursor: [sensorflow_ota_runs_stream_cursor_input]!, where: sensorflow_ota_runs_bool_exp): [sensorflow_ota_runs!]!
  sensorflow_pending_ac_settings_commands_for_gateway(distinct_on: [sensorflow_pending_ac_settings_commands_for_gateway_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pending_ac_settings_commands_for_gateway_order_by!], where: sensorflow_pending_ac_settings_commands_for_gateway_bool_exp): [sensorflow_pending_ac_settings_commands_for_gateway!]!
  sensorflow_pending_ac_settings_commands_for_gateway_aggregate(distinct_on: [sensorflow_pending_ac_settings_commands_for_gateway_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pending_ac_settings_commands_for_gateway_order_by!], where: sensorflow_pending_ac_settings_commands_for_gateway_bool_exp): sensorflow_pending_ac_settings_commands_for_gateway_aggregate!
  sensorflow_pending_ac_settings_commands_for_gateway_stream(batch_size: Int!, cursor: [sensorflow_pending_ac_settings_commands_for_gateway_stream_cursor_input]!, where: sensorflow_pending_ac_settings_commands_for_gateway_bool_exp): [sensorflow_pending_ac_settings_commands_for_gateway!]!
  sensorflow_pipes(distinct_on: [sensorflow_pipes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pipes_order_by!], where: sensorflow_pipes_bool_exp): [sensorflow_pipes!]!
  sensorflow_pipes_aggregate(distinct_on: [sensorflow_pipes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pipes_order_by!], where: sensorflow_pipes_bool_exp): sensorflow_pipes_aggregate!
  sensorflow_pipes_by_pk(id: uuid!): sensorflow_pipes
  sensorflow_pipes_stream(batch_size: Int!, cursor: [sensorflow_pipes_stream_cursor_input]!, where: sensorflow_pipes_bool_exp): [sensorflow_pipes!]!
  sensorflow_position_precool_status_aggregate(distinct_on: [sensorflow_position_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_precool_status_order_by!], where: sensorflow_position_precool_status_bool_exp): sensorflow_position_precool_status_aggregate!
  sensorflow_position_precool_status_stream(batch_size: Int!, cursor: [sensorflow_position_precool_status_stream_cursor_input]!, where: sensorflow_position_precool_status_bool_exp): [sensorflow_position_precool_status!]!
  sensorflow_position_signal_stats(distinct_on: [sensorflow_position_signal_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_signal_stats_order_by!], where: sensorflow_position_signal_stats_bool_exp): [sensorflow_position_signal_stats!]!
  sensorflow_position_signal_stats_aggregate(distinct_on: [sensorflow_position_signal_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_position_signal_stats_order_by!], where: sensorflow_position_signal_stats_bool_exp): sensorflow_position_signal_stats_aggregate!
  sensorflow_position_signal_stats_by_pk(balena_id: String!, position_id: uuid!): sensorflow_position_signal_stats
  sensorflow_position_signal_stats_stream(batch_size: Int!, cursor: [sensorflow_position_signal_stats_stream_cursor_input]!, where: sensorflow_position_signal_stats_bool_exp): [sensorflow_position_signal_stats!]!
  sensorflow_positions_stream(batch_size: Int!, cursor: [sensorflow_positions_stream_cursor_input]!, where: sensorflow_positions_bool_exp): [sensorflow_positions!]!
  sensorflow_precool_commands(distinct_on: [sensorflow_precool_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_commands_order_by!], where: sensorflow_precool_commands_bool_exp): [sensorflow_precool_commands!]!
  sensorflow_precool_commands_aggregate(distinct_on: [sensorflow_precool_commands_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_commands_order_by!], where: sensorflow_precool_commands_bool_exp): sensorflow_precool_commands_aggregate!
  sensorflow_precool_commands_by_pk(id: uuid!): sensorflow_precool_commands
  sensorflow_precool_commands_stream(batch_size: Int!, cursor: [sensorflow_precool_commands_stream_cursor_input]!, where: sensorflow_precool_commands_bool_exp): [sensorflow_precool_commands!]!
  sensorflow_precool_schedules(distinct_on: [sensorflow_precool_schedules_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_schedules_order_by!], where: sensorflow_precool_schedules_bool_exp): [sensorflow_precool_schedules!]!
  sensorflow_precool_schedules_aggregate(distinct_on: [sensorflow_precool_schedules_select_column!], limit: Int, offset: Int, order_by: [sensorflow_precool_schedules_order_by!], where: sensorflow_precool_schedules_bool_exp): sensorflow_precool_schedules_aggregate!
  sensorflow_precool_schedules_by_pk(id: bigint!): sensorflow_precool_schedules
  sensorflow_precool_schedules_stream(batch_size: Int!, cursor: [sensorflow_precool_schedules_stream_cursor_input]!, where: sensorflow_precool_schedules_bool_exp): [sensorflow_precool_schedules!]!
  sensorflow_pumps(distinct_on: [sensorflow_pumps_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pumps_order_by!], where: sensorflow_pumps_bool_exp): [sensorflow_pumps!]!
  sensorflow_pumps_aggregate(distinct_on: [sensorflow_pumps_select_column!], limit: Int, offset: Int, order_by: [sensorflow_pumps_order_by!], where: sensorflow_pumps_bool_exp): sensorflow_pumps_aggregate!
  sensorflow_pumps_by_pk(id: uuid!): sensorflow_pumps
  sensorflow_pumps_stream(batch_size: Int!, cursor: [sensorflow_pumps_stream_cursor_input]!, where: sensorflow_pumps_bool_exp): [sensorflow_pumps!]!
  sensorflow_reservation_guests(distinct_on: [sensorflow_reservation_guests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservation_guests_order_by!], where: sensorflow_reservation_guests_bool_exp): [sensorflow_reservation_guests!]!
  sensorflow_reservation_guests_aggregate(distinct_on: [sensorflow_reservation_guests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservation_guests_order_by!], where: sensorflow_reservation_guests_bool_exp): sensorflow_reservation_guests_aggregate!
  sensorflow_reservation_guests_by_pk(reservationId: bigint!, userId: String!): sensorflow_reservation_guests
  sensorflow_reservation_guests_stream(batch_size: Int!, cursor: [sensorflow_reservation_guests_stream_cursor_input]!, where: sensorflow_reservation_guests_bool_exp): [sensorflow_reservation_guests!]!
  sensorflow_reservations(distinct_on: [sensorflow_reservations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservations_order_by!], where: sensorflow_reservations_bool_exp): [sensorflow_reservations!]!
  sensorflow_reservations_aggregate(distinct_on: [sensorflow_reservations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_reservations_order_by!], where: sensorflow_reservations_bool_exp): sensorflow_reservations_aggregate!
  sensorflow_reservations_by_pk(id: bigint!): sensorflow_reservations
  sensorflow_reservations_stream(batch_size: Int!, cursor: [sensorflow_reservations_stream_cursor_input]!, where: sensorflow_reservations_bool_exp): [sensorflow_reservations!]!
  sensorflow_roles(distinct_on: [sensorflow_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_roles_order_by!], where: sensorflow_roles_bool_exp): [sensorflow_roles!]!
  sensorflow_roles_aggregate(distinct_on: [sensorflow_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_roles_order_by!], where: sensorflow_roles_bool_exp): sensorflow_roles_aggregate!
  sensorflow_roles_by_pk(id: uuid!): sensorflow_roles
  sensorflow_roles_stream(batch_size: Int!, cursor: [sensorflow_roles_stream_cursor_input]!, where: sensorflow_roles_bool_exp): [sensorflow_roles!]!
  sensorflow_smart_rem_validation_history_aggregate(distinct_on: [sensorflow_smart_rem_validation_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_smart_rem_validation_history_order_by!], where: sensorflow_smart_rem_validation_history_bool_exp): sensorflow_smart_rem_validation_history_aggregate!
  sensorflow_smart_rem_validation_history_by_pk(id: uuid!): sensorflow_smart_rem_validation_history
  sensorflow_smart_rem_validation_history_stream(batch_size: Int!, cursor: [sensorflow_smart_rem_validation_history_stream_cursor_input]!, where: sensorflow_smart_rem_validation_history_bool_exp): [sensorflow_smart_rem_validation_history!]!
  sensorflow_synced_raw_data(distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): [sensorflow_synced_raw_data!]!
  sensorflow_synced_raw_data_aggregate(distinct_on: [sensorflow_synced_raw_data_select_column!], limit: Int, offset: Int, order_by: [sensorflow_synced_raw_data_order_by!], where: sensorflow_synced_raw_data_bool_exp): sensorflow_synced_raw_data_aggregate!
  sensorflow_synced_raw_data_stream(batch_size: Int!, cursor: [sensorflow_synced_raw_data_stream_cursor_input]!, where: sensorflow_synced_raw_data_bool_exp): [sensorflow_synced_raw_data!]!
  sensorflow_task_status_history(distinct_on: [sensorflow_task_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_task_status_history_order_by!], where: sensorflow_task_status_history_bool_exp): [sensorflow_task_status_history!]!
  sensorflow_task_status_history_aggregate(distinct_on: [sensorflow_task_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_task_status_history_order_by!], where: sensorflow_task_status_history_bool_exp): sensorflow_task_status_history_aggregate!
  sensorflow_task_status_history_by_pk(id: Int!): sensorflow_task_status_history
  sensorflow_task_status_history_stream(batch_size: Int!, cursor: [sensorflow_task_status_history_stream_cursor_input]!, where: sensorflow_task_status_history_bool_exp): [sensorflow_task_status_history!]!
  sensorflow_tasks(distinct_on: [sensorflow_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tasks_order_by!], where: sensorflow_tasks_bool_exp): [sensorflow_tasks!]!
  sensorflow_tasks_aggregate(distinct_on: [sensorflow_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tasks_order_by!], where: sensorflow_tasks_bool_exp): sensorflow_tasks_aggregate!
  sensorflow_tasks_by_pk(id: uuid!): sensorflow_tasks
  sensorflow_tasks_stream(batch_size: Int!, cursor: [sensorflow_tasks_stream_cursor_input]!, where: sensorflow_tasks_bool_exp): [sensorflow_tasks!]!
  sensorflow_tests(distinct_on: [sensorflow_tests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tests_order_by!], where: sensorflow_tests_bool_exp): [sensorflow_tests!]!
  sensorflow_tests_aggregate(distinct_on: [sensorflow_tests_select_column!], limit: Int, offset: Int, order_by: [sensorflow_tests_order_by!], where: sensorflow_tests_bool_exp): sensorflow_tests_aggregate!
  sensorflow_tests_by_pk(testId: Int!): sensorflow_tests
  sensorflow_tests_stream(batch_size: Int!, cursor: [sensorflow_tests_stream_cursor_input]!, where: sensorflow_tests_bool_exp): [sensorflow_tests!]!
  sensorflow_timezones(distinct_on: [sensorflow_timezones_select_column!], limit: Int, offset: Int, order_by: [sensorflow_timezones_order_by!], where: sensorflow_timezones_bool_exp): [sensorflow_timezones!]!
  sensorflow_timezones_aggregate(distinct_on: [sensorflow_timezones_select_column!], limit: Int, offset: Int, order_by: [sensorflow_timezones_order_by!], where: sensorflow_timezones_bool_exp): sensorflow_timezones_aggregate!
  sensorflow_timezones_by_pk(id: Int!): sensorflow_timezones
  sensorflow_timezones_stream(batch_size: Int!, cursor: [sensorflow_timezones_stream_cursor_input]!, where: sensorflow_timezones_bool_exp): [sensorflow_timezones!]!
  sensorflow_updatable_nodes(distinct_on: [sensorflow_updatable_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_updatable_nodes_order_by!], where: sensorflow_updatable_nodes_bool_exp): [sensorflow_updatable_nodes!]!
  sensorflow_updatable_nodes_aggregate(distinct_on: [sensorflow_updatable_nodes_select_column!], limit: Int, offset: Int, order_by: [sensorflow_updatable_nodes_order_by!], where: sensorflow_updatable_nodes_bool_exp): sensorflow_updatable_nodes_aggregate!
  sensorflow_updatable_nodes_stream(batch_size: Int!, cursor: [sensorflow_updatable_nodes_stream_cursor_input]!, where: sensorflow_updatable_nodes_bool_exp): [sensorflow_updatable_nodes!]!
  sensorflow_user_to_permission_mapping(distinct_on: [sensorflow_user_to_permission_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_user_to_permission_mapping_order_by!], where: sensorflow_user_to_permission_mapping_bool_exp): [sensorflow_user_to_permission_mapping!]!
  sensorflow_user_to_permission_mapping_aggregate(distinct_on: [sensorflow_user_to_permission_mapping_select_column!], limit: Int, offset: Int, order_by: [sensorflow_user_to_permission_mapping_order_by!], where: sensorflow_user_to_permission_mapping_bool_exp): sensorflow_user_to_permission_mapping_aggregate!
  sensorflow_user_to_permission_mapping_stream(batch_size: Int!, cursor: [sensorflow_user_to_permission_mapping_stream_cursor_input]!, where: sensorflow_user_to_permission_mapping_bool_exp): [sensorflow_user_to_permission_mapping!]!
  sensorflow_users(distinct_on: [sensorflow_users_select_column!], limit: Int, offset: Int, order_by: [sensorflow_users_order_by!], where: sensorflow_users_bool_exp): [sensorflow_users!]!
  sensorflow_users_aggregate(distinct_on: [sensorflow_users_select_column!], limit: Int, offset: Int, order_by: [sensorflow_users_order_by!], where: sensorflow_users_bool_exp): sensorflow_users_aggregate!
  sensorflow_users_by_pk(userId: String!): sensorflow_users
  sensorflow_users_stream(batch_size: Int!, cursor: [sensorflow_users_stream_cursor_input]!, where: sensorflow_users_bool_exp): [sensorflow_users!]!
  sensorflow_v_all_latest_node_configurations(distinct_on: [sensorflow_v_all_latest_node_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_all_latest_node_configurations_order_by!], where: sensorflow_v_all_latest_node_configurations_bool_exp): [sensorflow_v_all_latest_node_configurations!]!
  sensorflow_v_all_latest_node_configurations_aggregate(distinct_on: [sensorflow_v_all_latest_node_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_all_latest_node_configurations_order_by!], where: sensorflow_v_all_latest_node_configurations_bool_exp): sensorflow_v_all_latest_node_configurations_aggregate!
  sensorflow_v_all_latest_node_configurations_stream(batch_size: Int!, cursor: [sensorflow_v_all_latest_node_configurations_stream_cursor_input]!, where: sensorflow_v_all_latest_node_configurations_bool_exp): [sensorflow_v_all_latest_node_configurations!]!
  sensorflow_v_combined_user_roles(distinct_on: [sensorflow_v_combined_user_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_combined_user_roles_order_by!], where: sensorflow_v_combined_user_roles_bool_exp): [sensorflow_v_combined_user_roles!]!
  sensorflow_v_combined_user_roles_aggregate(distinct_on: [sensorflow_v_combined_user_roles_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_combined_user_roles_order_by!], where: sensorflow_v_combined_user_roles_bool_exp): sensorflow_v_combined_user_roles_aggregate!
  sensorflow_v_combined_user_roles_stream(batch_size: Int!, cursor: [sensorflow_v_combined_user_roles_stream_cursor_input]!, where: sensorflow_v_combined_user_roles_bool_exp): [sensorflow_v_combined_user_roles!]!
  sensorflow_v_comfortplus_key_details(distinct_on: [sensorflow_v_comfortplus_key_details_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_details_order_by!], where: sensorflow_v_comfortplus_key_details_bool_exp): [sensorflow_v_comfortplus_key_details!]!
  sensorflow_v_comfortplus_key_details_aggregate(distinct_on: [sensorflow_v_comfortplus_key_details_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_details_order_by!], where: sensorflow_v_comfortplus_key_details_bool_exp): sensorflow_v_comfortplus_key_details_aggregate!
  sensorflow_v_comfortplus_key_details_stream(batch_size: Int!, cursor: [sensorflow_v_comfortplus_key_details_stream_cursor_input]!, where: sensorflow_v_comfortplus_key_details_bool_exp): [sensorflow_v_comfortplus_key_details!]!
  sensorflow_v_comfortplus_key_measurements(distinct_on: [sensorflow_v_comfortplus_key_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_measurements_order_by!], where: sensorflow_v_comfortplus_key_measurements_bool_exp): [sensorflow_v_comfortplus_key_measurements!]!
  sensorflow_v_comfortplus_key_measurements_aggregate(distinct_on: [sensorflow_v_comfortplus_key_measurements_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_key_measurements_order_by!], where: sensorflow_v_comfortplus_key_measurements_bool_exp): sensorflow_v_comfortplus_key_measurements_aggregate!
  sensorflow_v_comfortplus_key_measurements_stream(batch_size: Int!, cursor: [sensorflow_v_comfortplus_key_measurements_stream_cursor_input]!, where: sensorflow_v_comfortplus_key_measurements_bool_exp): [sensorflow_v_comfortplus_key_measurements!]!
  sensorflow_v_comfortplus_precool_status(distinct_on: [sensorflow_v_comfortplus_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_precool_status_order_by!], where: sensorflow_v_comfortplus_precool_status_bool_exp): [sensorflow_v_comfortplus_precool_status!]!
  sensorflow_v_comfortplus_precool_status_aggregate(distinct_on: [sensorflow_v_comfortplus_precool_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_comfortplus_precool_status_order_by!], where: sensorflow_v_comfortplus_precool_status_bool_exp): sensorflow_v_comfortplus_precool_status_aggregate!
  sensorflow_v_comfortplus_precool_status_stream(batch_size: Int!, cursor: [sensorflow_v_comfortplus_precool_status_stream_cursor_input]!, where: sensorflow_v_comfortplus_precool_status_bool_exp): [sensorflow_v_comfortplus_precool_status!]!
  sensorflow_v_current_position_configurations_aggregate(distinct_on: [sensorflow_v_current_position_configurations_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_current_position_configurations_order_by!], where: sensorflow_v_current_position_configurations_bool_exp): sensorflow_v_current_position_configurations_aggregate!
  sensorflow_v_current_position_configurations_stream(batch_size: Int!, cursor: [sensorflow_v_current_position_configurations_stream_cursor_input]!, where: sensorflow_v_current_position_configurations_bool_exp): [sensorflow_v_current_position_configurations!]!
  sensorflow_v_group_tasks(distinct_on: [sensorflow_v_group_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_tasks_order_by!], where: sensorflow_v_group_tasks_bool_exp): [sensorflow_v_group_tasks!]!
  sensorflow_v_group_tasks_aggregate(distinct_on: [sensorflow_v_group_tasks_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_tasks_order_by!], where: sensorflow_v_group_tasks_bool_exp): sensorflow_v_group_tasks_aggregate!
  sensorflow_v_group_tasks_stream(batch_size: Int!, cursor: [sensorflow_v_group_tasks_stream_cursor_input]!, where: sensorflow_v_group_tasks_bool_exp): [sensorflow_v_group_tasks!]!
  sensorflow_v_group_with_stats(distinct_on: [sensorflow_v_group_with_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_with_stats_order_by!], where: sensorflow_v_group_with_stats_bool_exp): [sensorflow_v_group_with_stats!]!
  sensorflow_v_group_with_stats_aggregate(distinct_on: [sensorflow_v_group_with_stats_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_group_with_stats_order_by!], where: sensorflow_v_group_with_stats_bool_exp): sensorflow_v_group_with_stats_aggregate!
  sensorflow_v_group_with_stats_stream(batch_size: Int!, cursor: [sensorflow_v_group_with_stats_stream_cursor_input]!, where: sensorflow_v_group_with_stats_bool_exp): [sensorflow_v_group_with_stats!]!
  sensorflow_v_infrastructure_emon_power_status_aggregate(distinct_on: [sensorflow_v_infrastructure_emon_power_status_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructure_emon_power_status_order_by!], where: sensorflow_v_infrastructure_emon_power_status_bool_exp): sensorflow_v_infrastructure_emon_power_status_aggregate!
  sensorflow_v_infrastructure_emon_power_status_stream(batch_size: Int!, cursor: [sensorflow_v_infrastructure_emon_power_status_stream_cursor_input]!, where: sensorflow_v_infrastructure_emon_power_status_bool_exp): [sensorflow_v_infrastructure_emon_power_status!]!
  sensorflow_v_infrastructures_aggregate(distinct_on: [sensorflow_v_infrastructures_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_infrastructures_order_by!], where: sensorflow_v_infrastructures_bool_exp): sensorflow_v_infrastructures_aggregate!
  sensorflow_v_infrastructures_stream(batch_size: Int!, cursor: [sensorflow_v_infrastructures_stream_cursor_input]!, where: sensorflow_v_infrastructures_bool_exp): [sensorflow_v_infrastructures!]!
  sensorflow_v_key_emon_power_reading(distinct_on: [sensorflow_v_key_emon_power_reading_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_emon_power_reading_order_by!], where: sensorflow_v_key_emon_power_reading_bool_exp): [sensorflow_v_key_emon_power_reading!]!
  sensorflow_v_key_emon_power_reading_aggregate(distinct_on: [sensorflow_v_key_emon_power_reading_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_emon_power_reading_order_by!], where: sensorflow_v_key_emon_power_reading_bool_exp): sensorflow_v_key_emon_power_reading_aggregate!
  sensorflow_v_key_emon_power_reading_stream(batch_size: Int!, cursor: [sensorflow_v_key_emon_power_reading_stream_cursor_input]!, where: sensorflow_v_key_emon_power_reading_bool_exp): [sensorflow_v_key_emon_power_reading!]!
  sensorflow_v_key_position_latest_entry(distinct_on: [sensorflow_v_key_position_latest_entry_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_position_latest_entry_order_by!], where: sensorflow_v_key_position_latest_entry_bool_exp): [sensorflow_v_key_position_latest_entry!]!
  sensorflow_v_key_position_latest_entry_aggregate(distinct_on: [sensorflow_v_key_position_latest_entry_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_position_latest_entry_order_by!], where: sensorflow_v_key_position_latest_entry_bool_exp): sensorflow_v_key_position_latest_entry_aggregate!
  sensorflow_v_key_position_latest_entry_stream(batch_size: Int!, cursor: [sensorflow_v_key_position_latest_entry_stream_cursor_input]!, where: sensorflow_v_key_position_latest_entry_bool_exp): [sensorflow_v_key_position_latest_entry!]!
  sensorflow_v_key_task_min_duedate(distinct_on: [sensorflow_v_key_task_min_duedate_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_task_min_duedate_order_by!], where: sensorflow_v_key_task_min_duedate_bool_exp): [sensorflow_v_key_task_min_duedate!]!
  sensorflow_v_key_task_min_duedate_aggregate(distinct_on: [sensorflow_v_key_task_min_duedate_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_key_task_min_duedate_order_by!], where: sensorflow_v_key_task_min_duedate_bool_exp): sensorflow_v_key_task_min_duedate_aggregate!
  sensorflow_v_key_task_min_duedate_stream(batch_size: Int!, cursor: [sensorflow_v_key_task_min_duedate_stream_cursor_input]!, where: sensorflow_v_key_task_min_duedate_bool_exp): [sensorflow_v_key_task_min_duedate!]!
  sensorflow_v_location_last_update(distinct_on: [sensorflow_v_location_last_update_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_location_last_update_order_by!], where: sensorflow_v_location_last_update_bool_exp): [sensorflow_v_location_last_update!]!
  sensorflow_v_location_last_update_aggregate(distinct_on: [sensorflow_v_location_last_update_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_location_last_update_order_by!], where: sensorflow_v_location_last_update_bool_exp): sensorflow_v_location_last_update_aggregate!
  sensorflow_v_location_last_update_stream(batch_size: Int!, cursor: [sensorflow_v_location_last_update_stream_cursor_input]!, where: sensorflow_v_location_last_update_bool_exp): [sensorflow_v_location_last_update!]!
  sensorflow_v_node_life_cycle_events(distinct_on: [sensorflow_v_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_node_life_cycle_events_order_by!], where: sensorflow_v_node_life_cycle_events_bool_exp): [sensorflow_v_node_life_cycle_events!]!
  sensorflow_v_node_life_cycle_events_aggregate(distinct_on: [sensorflow_v_node_life_cycle_events_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_node_life_cycle_events_order_by!], where: sensorflow_v_node_life_cycle_events_bool_exp): sensorflow_v_node_life_cycle_events_aggregate!
  sensorflow_v_node_life_cycle_events_stream(batch_size: Int!, cursor: [sensorflow_v_node_life_cycle_events_stream_cursor_input]!, where: sensorflow_v_node_life_cycle_events_bool_exp): [sensorflow_v_node_life_cycle_events!]!
  sensorflow_v_task_with_key_status_history(distinct_on: [sensorflow_v_task_with_key_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_task_with_key_status_history_order_by!], where: sensorflow_v_task_with_key_status_history_bool_exp): [sensorflow_v_task_with_key_status_history!]!
  sensorflow_v_task_with_key_status_history_aggregate(distinct_on: [sensorflow_v_task_with_key_status_history_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_task_with_key_status_history_order_by!], where: sensorflow_v_task_with_key_status_history_bool_exp): sensorflow_v_task_with_key_status_history_aggregate!
  sensorflow_v_task_with_key_status_history_stream(batch_size: Int!, cursor: [sensorflow_v_task_with_key_status_history_stream_cursor_input]!, where: sensorflow_v_task_with_key_status_history_bool_exp): [sensorflow_v_task_with_key_status_history!]!
  sensorflow_v_tasks_with_keys_aggregate(distinct_on: [sensorflow_v_tasks_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_tasks_with_keys_order_by!], where: sensorflow_v_tasks_with_keys_bool_exp): sensorflow_v_tasks_with_keys_aggregate!
  sensorflow_v_tasks_with_keys_stream(batch_size: Int!, cursor: [sensorflow_v_tasks_with_keys_stream_cursor_input]!, where: sensorflow_v_tasks_with_keys_bool_exp): [sensorflow_v_tasks_with_keys!]!
  sensorflow_warehouse_kitting_process_metadata_aggregate(distinct_on: [sensorflow_warehouse_kitting_process_metadata_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_kitting_process_metadata_order_by!], where: sensorflow_warehouse_kitting_process_metadata_bool_exp): sensorflow_warehouse_kitting_process_metadata_aggregate!
  sensorflow_warehouse_kitting_process_metadata_by_pk(balenaId: String!): sensorflow_warehouse_kitting_process_metadata
  sensorflow_warehouse_kitting_process_metadata_stream(batch_size: Int!, cursor: [sensorflow_warehouse_kitting_process_metadata_stream_cursor_input]!, where: sensorflow_warehouse_kitting_process_metadata_bool_exp): [sensorflow_warehouse_kitting_process_metadata!]!
  sensorflow_warehouse_mapping_status_of_slots(distinct_on: [sensorflow_warehouse_mapping_status_of_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_mapping_status_of_slots_order_by!], where: sensorflow_warehouse_mapping_status_of_slots_bool_exp): [sensorflow_warehouse_mapping_status_of_slots!]!
  sensorflow_warehouse_mapping_status_of_slots_aggregate(distinct_on: [sensorflow_warehouse_mapping_status_of_slots_select_column!], limit: Int, offset: Int, order_by: [sensorflow_warehouse_mapping_status_of_slots_order_by!], where: sensorflow_warehouse_mapping_status_of_slots_bool_exp): sensorflow_warehouse_mapping_status_of_slots_aggregate!
  sensorflow_warehouse_mapping_status_of_slots_stream(batch_size: Int!, cursor: [sensorflow_warehouse_mapping_status_of_slots_stream_cursor_input]!, where: sensorflow_warehouse_mapping_status_of_slots_bool_exp): [sensorflow_warehouse_mapping_status_of_slots!]!
  sf_firmware_dist_pipeline_firmware_build(distinct_on: [sf_firmware_dist_pipeline_firmware_build_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_build_order_by!], where: sf_firmware_dist_pipeline_firmware_build_bool_exp): [sf_firmware_dist_pipeline_firmware_build!]!
  sf_firmware_dist_pipeline_firmware_build_aggregate(distinct_on: [sf_firmware_dist_pipeline_firmware_build_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_build_order_by!], where: sf_firmware_dist_pipeline_firmware_build_bool_exp): sf_firmware_dist_pipeline_firmware_build_aggregate!
  sf_firmware_dist_pipeline_firmware_build_by_pk(buildId: Int!): sf_firmware_dist_pipeline_firmware_build
  sf_firmware_dist_pipeline_firmware_build_stream(batch_size: Int!, cursor: [sf_firmware_dist_pipeline_firmware_build_stream_cursor_input]!, where: sf_firmware_dist_pipeline_firmware_build_bool_exp): [sf_firmware_dist_pipeline_firmware_build!]!
  sf_firmware_dist_pipeline_firmware_numbers(distinct_on: [sf_firmware_dist_pipeline_firmware_numbers_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_numbers_order_by!], where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp): [sf_firmware_dist_pipeline_firmware_numbers!]!
  sf_firmware_dist_pipeline_firmware_numbers_aggregate(distinct_on: [sf_firmware_dist_pipeline_firmware_numbers_select_column!], limit: Int, offset: Int, order_by: [sf_firmware_dist_pipeline_firmware_numbers_order_by!], where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp): sf_firmware_dist_pipeline_firmware_numbers_aggregate!
  sf_firmware_dist_pipeline_firmware_numbers_by_pk(id: Int!): sf_firmware_dist_pipeline_firmware_numbers
  sf_firmware_dist_pipeline_firmware_numbers_stream(batch_size: Int!, cursor: [sf_firmware_dist_pipeline_firmware_numbers_stream_cursor_input]!, where: sf_firmware_dist_pipeline_firmware_numbers_bool_exp): [sf_firmware_dist_pipeline_firmware_numbers!]!
  sf_pms_pms_pmslist(distinct_on: [sf_pms_pms_pmslist_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_pmslist_order_by!], where: sf_pms_pms_pmslist_bool_exp): [sf_pms_pms_pmslist!]!
  sf_pms_pms_pmslist_aggregate(distinct_on: [sf_pms_pms_pmslist_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_pmslist_order_by!], where: sf_pms_pms_pmslist_bool_exp): sf_pms_pms_pmslist_aggregate!
  sf_pms_pms_pmslist_by_pk(pms_id: Int!): sf_pms_pms_pmslist
  sf_pms_pms_pmslist_stream(batch_size: Int!, cursor: [sf_pms_pms_pmslist_stream_cursor_input]!, where: sf_pms_pms_pmslist_bool_exp): [sf_pms_pms_pmslist!]!
  sf_pms_pms_properties(distinct_on: [sf_pms_pms_properties_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_properties_order_by!], where: sf_pms_pms_properties_bool_exp): [sf_pms_pms_properties!]!
  sf_pms_pms_properties_aggregate(distinct_on: [sf_pms_pms_properties_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_properties_order_by!], where: sf_pms_pms_properties_bool_exp): sf_pms_pms_properties_aggregate!
  sf_pms_pms_properties_by_pk(pms_id: Int!, property_id: String!): sf_pms_pms_properties
  sf_pms_pms_properties_stream(batch_size: Int!, cursor: [sf_pms_pms_properties_stream_cursor_input]!, where: sf_pms_pms_properties_bool_exp): [sf_pms_pms_properties!]!
  sf_pms_pms_property_eod_surveys(distinct_on: [sf_pms_pms_property_eod_surveys_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_property_eod_surveys_order_by!], where: sf_pms_pms_property_eod_surveys_bool_exp): [sf_pms_pms_property_eod_surveys!]!
  sf_pms_pms_property_eod_surveys_aggregate(distinct_on: [sf_pms_pms_property_eod_surveys_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_property_eod_surveys_order_by!], where: sf_pms_pms_property_eod_surveys_bool_exp): sf_pms_pms_property_eod_surveys_aggregate!
  sf_pms_pms_property_eod_surveys_by_pk(pms_id: Int!, property_id: String!, survey_monkey_id: String!): sf_pms_pms_property_eod_surveys
  sf_pms_pms_property_eod_surveys_stream(batch_size: Int!, cursor: [sf_pms_pms_property_eod_surveys_stream_cursor_input]!, where: sf_pms_pms_property_eod_surveys_bool_exp): [sf_pms_pms_property_eod_surveys!]!
  sf_pms_pms_reservations(distinct_on: [sf_pms_pms_reservations_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_reservations_order_by!], where: sf_pms_pms_reservations_bool_exp): [sf_pms_pms_reservations!]!
  sf_pms_pms_reservations_aggregate(distinct_on: [sf_pms_pms_reservations_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_reservations_order_by!], where: sf_pms_pms_reservations_bool_exp): sf_pms_pms_reservations_aggregate!
  sf_pms_pms_reservations_stream(batch_size: Int!, cursor: [sf_pms_pms_reservations_stream_cursor_input]!, where: sf_pms_pms_reservations_bool_exp): [sf_pms_pms_reservations!]!
  sf_pms_pms_room_categories(distinct_on: [sf_pms_pms_room_categories_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_room_categories_order_by!], where: sf_pms_pms_room_categories_bool_exp): [sf_pms_pms_room_categories!]!
  sf_pms_pms_room_categories_aggregate(distinct_on: [sf_pms_pms_room_categories_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_room_categories_order_by!], where: sf_pms_pms_room_categories_bool_exp): sf_pms_pms_room_categories_aggregate!
  sf_pms_pms_room_categories_stream(batch_size: Int!, cursor: [sf_pms_pms_room_categories_stream_cursor_input]!, where: sf_pms_pms_room_categories_bool_exp): [sf_pms_pms_room_categories!]!
  sf_pms_pms_rooms(distinct_on: [sf_pms_pms_rooms_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_rooms_order_by!], where: sf_pms_pms_rooms_bool_exp): [sf_pms_pms_rooms!]!
  sf_pms_pms_rooms_aggregate(distinct_on: [sf_pms_pms_rooms_select_column!], limit: Int, offset: Int, order_by: [sf_pms_pms_rooms_order_by!], where: sf_pms_pms_rooms_bool_exp): sf_pms_pms_rooms_aggregate!
  sf_pms_pms_rooms_stream(batch_size: Int!, cursor: [sf_pms_pms_rooms_stream_cursor_input]!, where: sf_pms_pms_rooms_bool_exp): [sf_pms_pms_rooms!]!
  sf_pms_smartalloc_report(distinct_on: [sf_pms_smartalloc_report_select_column!], limit: Int, offset: Int, order_by: [sf_pms_smartalloc_report_order_by!], where: sf_pms_smartalloc_report_bool_exp): [sf_pms_smartalloc_report!]!
  sf_pms_smartalloc_report_aggregate(distinct_on: [sf_pms_smartalloc_report_select_column!], limit: Int, offset: Int, order_by: [sf_pms_smartalloc_report_order_by!], where: sf_pms_smartalloc_report_bool_exp): sf_pms_smartalloc_report_aggregate!
  sf_pms_smartalloc_report_by_pk(date: date!, locationId: uuid!, type: String!): sf_pms_smartalloc_report
  sf_pms_smartalloc_report_stream(batch_size: Int!, cursor: [sf_pms_smartalloc_report_stream_cursor_input]!, where: sf_pms_smartalloc_report_bool_exp): [sf_pms_smartalloc_report!]!
  sf_simulation_instrumentation_commands(distinct_on: [sf_simulation_instrumentation_commands_select_column!], limit: Int, offset: Int, order_by: [sf_simulation_instrumentation_commands_order_by!], where: sf_simulation_instrumentation_commands_bool_exp): [sf_simulation_instrumentation_commands!]!
  sf_simulation_instrumentation_commands_aggregate(distinct_on: [sf_simulation_instrumentation_commands_select_column!], limit: Int, offset: Int, order_by: [sf_simulation_instrumentation_commands_order_by!], where: sf_simulation_instrumentation_commands_bool_exp): sf_simulation_instrumentation_commands_aggregate!
  sf_simulation_instrumentation_commands_by_pk(id: Int!): sf_simulation_instrumentation_commands
  sf_simulation_instrumentation_commands_stream(batch_size: Int!, cursor: [sf_simulation_instrumentation_commands_stream_cursor_input]!, where: sf_simulation_instrumentation_commands_bool_exp): [sf_simulation_instrumentation_commands!]!
  sf_support_actions(distinct_on: [sf_support_actions_select_column!], limit: Int, offset: Int, order_by: [sf_support_actions_order_by!], where: sf_support_actions_bool_exp): [sf_support_actions!]!
  sf_support_actions_aggregate(distinct_on: [sf_support_actions_select_column!], limit: Int, offset: Int, order_by: [sf_support_actions_order_by!], where: sf_support_actions_bool_exp): sf_support_actions_aggregate!
  sf_support_actions_by_pk(id: Int!): sf_support_actions
  sf_support_actions_stream(batch_size: Int!, cursor: [sf_support_actions_stream_cursor_input]!, where: sf_support_actions_bool_exp): [sf_support_actions!]!
  sf_support_active_property_alerts(distinct_on: [sf_support_active_property_alerts_select_column!], limit: Int, offset: Int, order_by: [sf_support_active_property_alerts_order_by!], where: sf_support_active_property_alerts_bool_exp): [sf_support_active_property_alerts!]!
  sf_support_active_property_alerts_aggregate(distinct_on: [sf_support_active_property_alerts_select_column!], limit: Int, offset: Int, order_by: [sf_support_active_property_alerts_order_by!], where: sf_support_active_property_alerts_bool_exp): sf_support_active_property_alerts_aggregate!
  sf_support_active_property_alerts_by_pk(locationId: uuid!, ticketClassId: Int!): sf_support_active_property_alerts
  sf_support_active_property_alerts_stream(batch_size: Int!, cursor: [sf_support_active_property_alerts_stream_cursor_input]!, where: sf_support_active_property_alerts_bool_exp): [sf_support_active_property_alerts!]!
  sf_support_analytics_ticket_jobs_running_histories(distinct_on: [sf_support_analytics_ticket_jobs_running_histories_select_column!], limit: Int, offset: Int, order_by: [sf_support_analytics_ticket_jobs_running_histories_order_by!], where: sf_support_analytics_ticket_jobs_running_histories_bool_exp): [sf_support_analytics_ticket_jobs_running_histories!]!
  sf_support_analytics_ticket_jobs_running_histories_aggregate(distinct_on: [sf_support_analytics_ticket_jobs_running_histories_select_column!], limit: Int, offset: Int, order_by: [sf_support_analytics_ticket_jobs_running_histories_order_by!], where: sf_support_analytics_ticket_jobs_running_histories_bool_exp): sf_support_analytics_ticket_jobs_running_histories_aggregate!
  sf_support_analytics_ticket_jobs_running_histories_by_pk(id: Int!): sf_support_analytics_ticket_jobs_running_histories
  sf_support_analytics_ticket_jobs_running_histories_stream(batch_size: Int!, cursor: [sf_support_analytics_ticket_jobs_running_histories_stream_cursor_input]!, where: sf_support_analytics_ticket_jobs_running_histories_bool_exp): [sf_support_analytics_ticket_jobs_running_histories!]!
  sf_support_root_causes(distinct_on: [sf_support_root_causes_select_column!], limit: Int, offset: Int, order_by: [sf_support_root_causes_order_by!], where: sf_support_root_causes_bool_exp): [sf_support_root_causes!]!
  sf_support_root_causes_aggregate(distinct_on: [sf_support_root_causes_select_column!], limit: Int, offset: Int, order_by: [sf_support_root_causes_order_by!], where: sf_support_root_causes_bool_exp): sf_support_root_causes_aggregate!
  sf_support_root_causes_by_pk(id: Int!): sf_support_root_causes
  sf_support_root_causes_stream(batch_size: Int!, cursor: [sf_support_root_causes_stream_cursor_input]!, where: sf_support_root_causes_bool_exp): [sf_support_root_causes!]!
  sf_support_ticket_classes_aggregate(distinct_on: [sf_support_ticket_classes_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_classes_order_by!], where: sf_support_ticket_classes_bool_exp): sf_support_ticket_classes_aggregate!
  sf_support_ticket_classes_by_pk(id: Int!): sf_support_ticket_classes
  sf_support_ticket_classes_stream(batch_size: Int!, cursor: [sf_support_ticket_classes_stream_cursor_input]!, where: sf_support_ticket_classes_bool_exp): [sf_support_ticket_classes!]!
  sf_support_ticket_status(distinct_on: [sf_support_ticket_status_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_status_order_by!], where: sf_support_ticket_status_bool_exp): [sf_support_ticket_status!]!
  sf_support_ticket_status_aggregate(distinct_on: [sf_support_ticket_status_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_status_order_by!], where: sf_support_ticket_status_bool_exp): sf_support_ticket_status_aggregate!
  sf_support_ticket_status_by_pk(id: Int!): sf_support_ticket_status
  sf_support_ticket_status_stream(batch_size: Int!, cursor: [sf_support_ticket_status_stream_cursor_input]!, where: sf_support_ticket_status_bool_exp): [sf_support_ticket_status!]!
  sf_support_tickets(distinct_on: [sf_support_tickets_select_column!], limit: Int, offset: Int, order_by: [sf_support_tickets_order_by!], where: sf_support_tickets_bool_exp): [sf_support_tickets!]!
  sf_support_tickets_aggregate(distinct_on: [sf_support_tickets_select_column!], limit: Int, offset: Int, order_by: [sf_support_tickets_order_by!], where: sf_support_tickets_bool_exp): sf_support_tickets_aggregate!
  sf_support_tickets_by_pk(ticket_id: Int!): sf_support_tickets
  sf_support_tickets_stream(batch_size: Int!, cursor: [sf_support_tickets_stream_cursor_input]!, where: sf_support_tickets_bool_exp): [sf_support_tickets!]!
  sf_testjig_test_jig_aggregate(distinct_on: [sf_testjig_test_jig_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_order_by!], where: sf_testjig_test_jig_bool_exp): sf_testjig_test_jig_aggregate!
  sf_testjig_test_jig_maintenance_report_aggregate(distinct_on: [sf_testjig_test_jig_maintenance_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_maintenance_report_order_by!], where: sf_testjig_test_jig_maintenance_report_bool_exp): sf_testjig_test_jig_maintenance_report_aggregate!
  sf_testjig_test_jig_maintenance_report_stream(batch_size: Int!, cursor: [sf_testjig_test_jig_maintenance_report_stream_cursor_input]!, where: sf_testjig_test_jig_maintenance_report_bool_exp): [sf_testjig_test_jig_maintenance_report!]!
  sf_testjig_test_jig_report_metadata_aggregate(distinct_on: [sf_testjig_test_jig_report_metadata_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadata_order_by!], where: sf_testjig_test_jig_report_metadata_bool_exp): sf_testjig_test_jig_report_metadata_aggregate!
  sf_testjig_test_jig_report_metadata_by_pk(id: uuid!): sf_testjig_test_jig_report_metadata
  sf_testjig_test_jig_report_metadata_stream(batch_size: Int!, cursor: [sf_testjig_test_jig_report_metadata_stream_cursor_input]!, where: sf_testjig_test_jig_report_metadata_bool_exp): [sf_testjig_test_jig_report_metadata!]!
  sf_testjig_test_jig_report_metadatas_aggregate(distinct_on: [sf_testjig_test_jig_report_metadatas_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_report_metadatas_order_by!], where: sf_testjig_test_jig_report_metadatas_bool_exp): sf_testjig_test_jig_report_metadatas_aggregate!
  sf_testjig_test_jig_report_metadatas_by_pk(id: Int!): sf_testjig_test_jig_report_metadatas
  sf_testjig_test_jig_report_metadatas_stream(batch_size: Int!, cursor: [sf_testjig_test_jig_report_metadatas_stream_cursor_input]!, where: sf_testjig_test_jig_report_metadatas_bool_exp): [sf_testjig_test_jig_report_metadatas!]!
  sf_testjig_test_jig_stream(batch_size: Int!, cursor: [sf_testjig_test_jig_stream_cursor_input]!, where: sf_testjig_test_jig_bool_exp): [sf_testjig_test_jig!]!
  sf_testjig_test_jig_test_report_aggregate(distinct_on: [sf_testjig_test_jig_test_report_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_test_report_order_by!], where: sf_testjig_test_jig_test_report_bool_exp): sf_testjig_test_jig_test_report_aggregate!
  sf_testjig_test_jig_test_report_stream(batch_size: Int!, cursor: [sf_testjig_test_jig_test_report_stream_cursor_input]!, where: sf_testjig_test_jig_test_report_bool_exp): [sf_testjig_test_jig_test_report!]!
  sf_testjig_test_jig_type_aggregate(distinct_on: [sf_testjig_test_jig_type_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_test_jig_type_order_by!], where: sf_testjig_test_jig_type_bool_exp): sf_testjig_test_jig_type_aggregate!
  sf_testjig_test_jig_type_stream(batch_size: Int!, cursor: [sf_testjig_test_jig_type_stream_cursor_input]!, where: sf_testjig_test_jig_type_bool_exp): [sf_testjig_test_jig_type!]!
  sf_testjig_v_latest_report_rma_nodes(distinct_on: [sf_testjig_v_latest_report_rma_nodes_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_v_latest_report_rma_nodes_order_by!], where: sf_testjig_v_latest_report_rma_nodes_bool_exp): [sf_testjig_v_latest_report_rma_nodes!]!
  sf_testjig_v_latest_report_rma_nodes_aggregate(distinct_on: [sf_testjig_v_latest_report_rma_nodes_select_column!], limit: Int, offset: Int, order_by: [sf_testjig_v_latest_report_rma_nodes_order_by!], where: sf_testjig_v_latest_report_rma_nodes_bool_exp): sf_testjig_v_latest_report_rma_nodes_aggregate!
  sf_testjig_v_latest_report_rma_nodes_stream(batch_size: Int!, cursor: [sf_testjig_v_latest_report_rma_nodes_stream_cursor_input]!, where: sf_testjig_v_latest_report_rma_nodes_bool_exp): [sf_testjig_v_latest_report_rma_nodes!]!
  tasksWithKeys(distinct_on: [sensorflow_v_tasks_with_keys_select_column!], limit: Int, offset: Int, order_by: [sensorflow_v_tasks_with_keys_order_by!], where: sensorflow_v_tasks_with_keys_bool_exp): [sensorflow_v_tasks_with_keys!]!
  testRuns(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): [testRuns!]!
  testRuns_aggregate(distinct_on: [testRuns_select_column!], limit: Int, offset: Int, order_by: [testRuns_order_by!], where: testRuns_bool_exp): testRuns_aggregate!
  testRuns_by_pk(testRunId: Int!): testRuns
  testRuns_stream(batch_size: Int!, cursor: [testRuns_stream_cursor_input]!, where: testRuns_bool_exp): [testRuns!]!
  ticketClasses(distinct_on: [sf_support_ticket_classes_select_column!], limit: Int, offset: Int, order_by: [sf_support_ticket_classes_order_by!], where: sf_support_ticket_classes_bool_exp): [sf_support_ticket_classes!]!
}

type TasksStats {
  doneCount: Int!
  inProgressCount: Int!
  pendingCount: Int!
  totalCount: Int!
}

enum TaskStatus {
  DONE
  IN_PROGRESS
  PENDING
}

type testRuns {
  autosetPositionId: String!
  details: String
  first_passed_at: timestamptz
  isIgnored: Boolean!
  keyEntryId: Int!
  key_entry: keyEntries!
  lastPassedAt: timestamptz
  lastTestedAt: timestamptz
  note: String
  position: sensorflow_positions
  startedAt: timestamptz
  status: String!
  test: sensorflow_tests!
  testId: Int!
  testRunId: Int!
}

type testRuns_aggregate {
  aggregate: testRuns_aggregate_fields
  nodes: [testRuns!]!
}

input testRuns_aggregate_bool_exp {
  bool_and: testRuns_aggregate_bool_exp_bool_and
  bool_or: testRuns_aggregate_bool_exp_bool_or
  count: testRuns_aggregate_bool_exp_count
}

input testRuns_aggregate_bool_exp_bool_and {
  arguments: testRuns_select_column_testRuns_aggregate_bool_exp_bool_and_arguments_columns!
  distinct: Boolean
  filter: testRuns_bool_exp
  predicate: Boolean_comparison_exp!
}

input testRuns_aggregate_bool_exp_bool_or {
  arguments: testRuns_select_column_testRuns_aggregate_bool_exp_bool_or_arguments_columns!
  distinct: Boolean
  filter: testRuns_bool_exp
  predicate: Boolean_comparison_exp!
}

input testRuns_aggregate_bool_exp_count {
  arguments: [testRuns_select_column!]
  distinct: Boolean
  filter: testRuns_bool_exp
  predicate: Int_comparison_exp!
}

type testRuns_aggregate_fields {
  avg: testRuns_avg_fields
  count(columns: [testRuns_select_column!], distinct: Boolean): Int!
  max: testRuns_max_fields
  min: testRuns_min_fields
  stddev: testRuns_stddev_fields
  stddev_pop: testRuns_stddev_pop_fields
  stddev_samp: testRuns_stddev_samp_fields
  sum: testRuns_sum_fields
  var_pop: testRuns_var_pop_fields
  var_samp: testRuns_var_samp_fields
  variance: testRuns_variance_fields
}

input testRuns_aggregate_order_by {
  avg: testRuns_avg_order_by
  count: order_by
  max: testRuns_max_order_by
  min: testRuns_min_order_by
  stddev: testRuns_stddev_order_by
  stddev_pop: testRuns_stddev_pop_order_by
  stddev_samp: testRuns_stddev_samp_order_by
  sum: testRuns_sum_order_by
  var_pop: testRuns_var_pop_order_by
  var_samp: testRuns_var_samp_order_by
  variance: testRuns_variance_order_by
}

input testRuns_arr_rel_insert_input {
  data: [testRuns_insert_input!]!
  on_conflict: testRuns_on_conflict
}

type testRuns_avg_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_avg_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

input testRuns_bool_exp {
  _and: [testRuns_bool_exp!]
  _not: testRuns_bool_exp
  _or: [testRuns_bool_exp!]
  autosetPositionId: String_comparison_exp
  details: String_comparison_exp
  first_passed_at: timestamptz_comparison_exp
  isIgnored: Boolean_comparison_exp
  keyEntryId: Int_comparison_exp
  key_entry: keyEntries_bool_exp
  lastPassedAt: timestamptz_comparison_exp
  lastTestedAt: timestamptz_comparison_exp
  note: String_comparison_exp
  position: sensorflow_positions_bool_exp
  startedAt: timestamptz_comparison_exp
  status: String_comparison_exp
  test: sensorflow_tests_bool_exp
  testId: Int_comparison_exp
  testRunId: Int_comparison_exp
}

enum testRuns_constraint {
  test_runs_pkey
  test_runs_test_id_key_entry_id_autoset_position_id_key
}

input testRuns_inc_input {
  keyEntryId: Int
  testId: Int
  testRunId: Int
}

input testRuns_insert_input {
  autosetPositionId: String
  details: String
  first_passed_at: timestamptz
  isIgnored: Boolean
  keyEntryId: Int
  key_entry: keyEntries_obj_rel_insert_input
  lastPassedAt: timestamptz
  lastTestedAt: timestamptz
  note: String
  position: sensorflow_positions_obj_rel_insert_input
  startedAt: timestamptz
  status: String
  test: sensorflow_tests_obj_rel_insert_input
  testId: Int
  testRunId: Int
}

type testRuns_max_fields {
  autosetPositionId: String
  details: String
  first_passed_at: timestamptz
  keyEntryId: Int
  lastPassedAt: timestamptz
  lastTestedAt: timestamptz
  note: String
  startedAt: timestamptz
  status: String
  testId: Int
  testRunId: Int
}

input testRuns_max_order_by {
  autosetPositionId: order_by
  details: order_by
  first_passed_at: order_by
  keyEntryId: order_by
  lastPassedAt: order_by
  lastTestedAt: order_by
  note: order_by
  startedAt: order_by
  status: order_by
  testId: order_by
  testRunId: order_by
}

type testRuns_min_fields {
  autosetPositionId: String
  details: String
  first_passed_at: timestamptz
  keyEntryId: Int
  lastPassedAt: timestamptz
  lastTestedAt: timestamptz
  note: String
  startedAt: timestamptz
  status: String
  testId: Int
  testRunId: Int
}

input testRuns_min_order_by {
  autosetPositionId: order_by
  details: order_by
  first_passed_at: order_by
  keyEntryId: order_by
  lastPassedAt: order_by
  lastTestedAt: order_by
  note: order_by
  startedAt: order_by
  status: order_by
  testId: order_by
  testRunId: order_by
}

type testRuns_mutation_response {
  affected_rows: Int!
  returning: [testRuns!]!
}

input testRuns_on_conflict {
  constraint: testRuns_constraint!
  update_columns: [testRuns_update_column!]! = []
  where: testRuns_bool_exp
}

input testRuns_order_by {
  autosetPositionId: order_by
  details: order_by
  first_passed_at: order_by
  isIgnored: order_by
  keyEntryId: order_by
  key_entry: keyEntries_order_by
  lastPassedAt: order_by
  lastTestedAt: order_by
  note: order_by
  position: sensorflow_positions_order_by
  startedAt: order_by
  status: order_by
  test: sensorflow_tests_order_by
  testId: order_by
  testRunId: order_by
}

input testRuns_pk_columns_input {
  testRunId: Int!
}

enum testRuns_select_column {
  autosetPositionId
  details
  first_passed_at
  isIgnored
  keyEntryId
  lastPassedAt
  lastTestedAt
  note
  startedAt
  status
  testId
  testRunId
}

enum testRuns_select_column_testRuns_aggregate_bool_exp_bool_and_arguments_columns {
  isIgnored
}

enum testRuns_select_column_testRuns_aggregate_bool_exp_bool_or_arguments_columns {
  isIgnored
}

input testRuns_set_input {
  autosetPositionId: String
  details: String
  first_passed_at: timestamptz
  isIgnored: Boolean
  keyEntryId: Int
  lastPassedAt: timestamptz
  lastTestedAt: timestamptz
  note: String
  startedAt: timestamptz
  status: String
  testId: Int
  testRunId: Int
}

type testRuns_stddev_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_stddev_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

type testRuns_stddev_pop_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_stddev_pop_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

type testRuns_stddev_samp_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_stddev_samp_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

input testRuns_stream_cursor_input {
  initial_value: testRuns_stream_cursor_value_input!
  ordering: cursor_ordering
}

input testRuns_stream_cursor_value_input {
  autosetPositionId: String
  details: String
  first_passed_at: timestamptz
  isIgnored: Boolean
  keyEntryId: Int
  lastPassedAt: timestamptz
  lastTestedAt: timestamptz
  note: String
  startedAt: timestamptz
  status: String
  testId: Int
  testRunId: Int
}

type testRuns_sum_fields {
  keyEntryId: Int
  testId: Int
  testRunId: Int
}

input testRuns_sum_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

enum testRuns_update_column {
  autosetPositionId
  details
  first_passed_at
  isIgnored
  keyEntryId
  lastPassedAt
  lastTestedAt
  note
  startedAt
  status
  testId
  testRunId
}

input testRuns_updates {
  _inc: testRuns_inc_input
  _set: testRuns_set_input
  where: testRuns_bool_exp!
}

type testRuns_var_pop_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_var_pop_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

type testRuns_var_samp_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_var_samp_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

type testRuns_variance_fields {
  keyEntryId: Float
  testId: Float
  testRunId: Float
}

input testRuns_variance_order_by {
  keyEntryId: order_by
  testId: order_by
  testRunId: order_by
}

scalar timestamp

input timestamp_comparison_exp {
  _eq: timestamp
  _gt: timestamp
  _gte: timestamp
  _in: [timestamp!]
  _is_null: Boolean
  _lt: timestamp
  _lte: timestamp
  _neq: timestamp
  _nin: [timestamp!]
}

scalar timestamptz

input timestamptz_comparison_exp {
  _eq: timestamptz
  _gt: timestamptz
  _gte: timestamptz
  _in: [timestamptz!]
  _is_null: Boolean
  _lt: timestamptz
  _lte: timestamptz
  _neq: timestamptz
  _nin: [timestamptz!]
}

type UncancelablePrecoolRooms {
  roomId: String!
  roomName: String!
}

type User {
  email: String!
  emailVerified: UserEmailVerified!
  isContractor: Boolean
  isDev: Boolean
  isManager: Boolean
  lastLogin: String
  locations: [Location!]!
  name: String!
  organisation: Organisation!
  role: Role!
  status: UserStatus!
  userId: String!
}

enum UserEmailVerified {
  UNVERIFIED
  VERIFIED
}

input UserFilterInput {
  roleIds: [String!]
  search: String
}

input UserInput {
  isContractor: Boolean!
  isManager: Boolean!
  locationIds: [String]!
  name: String!
  organisationId: String!
  roleId: String!
}

input UserSortInput {
  email: SortOptions
  name: SortOptions
}

type UsersResponse {
  pagination: GenericPagination!
  users: [User!]!
}

enum UserStatus {
  ACTIVE
  DISABLED
}

scalar uuid

input uuid_comparison_exp {
  _eq: uuid
  _gt: uuid
  _gte: uuid
  _in: [uuid!]
  _is_null: Boolean
  _lt: uuid
  _lte: uuid
  _neq: uuid
  _nin: [uuid!]
}

`,
];
export default userSchema;
